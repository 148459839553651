import {} from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { GraphProps } from "./type";
const Graph: FC<GraphProps> = ({ simulationId }) => {
	const { weatherRef, swellRef, swRef, cfRef, testRef } =
		useIndex(simulationId);
	return (
		<>
			<div className={styles.graph} ref={weatherRef}></div>
			<div className={styles.graph} ref={swellRef}></div>
			<div className={styles.graph} ref={swRef}></div>
			<div className={styles.graph} ref={cfRef}></div>
			<div className={styles.graph} ref={testRef}></div>
		</>
	);
};

export default Graph;
