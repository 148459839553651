// src/WeatherComponent.tsx

import React from "react";
import style from "./index.module.less";
import { RreportDescTagProps } from "./type";
import { timestampToUtcMdhm } from "@/tools/date";

const RreportDescTag: React.FC<RreportDescTagProps> = ({
	phase,
	phaseParty,
	appliedCp
}) => {
	return (
		<div className={style["report-desc-tag"]}>
			<div className="phase-name">{phase}</div>
			<div className="phase-info">
				{phaseParty.map((item, index) => {
					return (
						<div className="phase-party-box" key={index}>
							<div className="phase-party-start">
								<div className="start-port">{item.startPort}</div>
								<div className="start-time">
									{timestampToUtcMdhm(item.startDateTime)}
								</div>
							</div>
							<div className="phase-party-end">
								<div className="end-port">{item.endPort}</div>
								<div className="end-time">
									{timestampToUtcMdhm(item.endDateTime)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className="applied-cp">
				<div className="applied-cp-label">Applied</div>
				<div className="applied-cp-value">{appliedCp}</div>
			</div>
		</div>
	);
};

export default RreportDescTag;
