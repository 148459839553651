import { CommonOptionType } from "@/types/common";
import { VoyGwClauseItemType } from "./type";

export const overallGWOptions: CommonOptionType<
	string,
	VoyGwClauseItemType["overallGw"]
>[] = [
	{ label: "NO", value: 0 },
	{ label: "YES", value: 1 }
];

export const proportionUnitOptions: CommonOptionType<
	VoyGwClauseItemType["proportionUnit"],
	VoyGwClauseItemType["proportionUnit"]
>[] = [
	{ label: "%", value: "%" },
	{ label: "hrs", value: "hrs" }
];

export const signWaveAppliedOptions: CommonOptionType<
	string,
	VoyGwClauseItemType["signWaveApplied"]
>[] = [
	{ label: "Not Applied", value: 0 },
	{ label: "Applied", value: 1 }
];

export const swellWaveAppliedOptions: CommonOptionType<
	string,
	VoyGwClauseItemType["swellWaveApplied"]
>[] = [
	{ label: "Not Applied", value: 0 },
	{ label: "Applied", value: 1 }
];

export const windWaveAppliedOptions: CommonOptionType<
	string,
	VoyGwClauseItemType["windWaveApplied"]
>[] = [
	{ label: "Not Applied", value: 0 },
	{ label: "Applied", value: 1 }
];

export const noAdverseCurrentOptions: CommonOptionType<
	string,
	VoyGwClauseItemType["noAdverseCurrent"]
>[] = [
	{ label: "NO", value: 0 },
	{ label: "YES", value: 1 }
];

export const noFavourableCurrentOptions: CommonOptionType<
	string,
	VoyGwClauseItemType["noFavourableCurrent"]
>[] = [
	{ label: "NO", value: 0 },
	{ label: "YES", value: 1 }
];

export const noExtrapolationOptions: CommonOptionType<
	string,
	VoyGwClauseItemType["noExtrapolation"]
>[] = [
	{ label: "NO", value: 0 },
	{ label: "YES", value: 1 }
];

export const aboutSpeedUnitOptions: CommonOptionType<
	VoyGwClauseItemType["aboutSpeedUnit"],
	VoyGwClauseItemType["aboutSpeedUnit"]
>[] = [
	{ label: "%", value: "%" },
	{ label: "kts", value: "kts" }
];

// export const aboutConsUnitOptions: CommonOptionType<
// 	VoyGwClauseItemType["aboutConsUnit"],
// 	VoyGwClauseItemType["aboutConsUnit"]
// >[] = [
// 	{ label: "%", value: "%" },
// 	{ label: "hrs", value: "hrs" }
// ];

export const initialData = {
	overallGw: 0,
	dayHours: 1,
	proportion: 50,
	proportionUnit: "%",
	consecutive: 1,
	signWaveValue: 1.25,
	signWaveApplied: 1,
	swellWaveValue: 1.25,
	swellWaveApplied: 0,
	windWaveValue: 1.25,
	windWaveApplied: 0,
	bf: 4,
	dss: 3,
	noAdverseCurrent: 1,
	noFavourableCurrent: 0,
	noExtrapolation: 0,
	aboutSpeedFrom: 0,
	aboutSpeedTo: 0.5,
	aboutSpeedUnit: "kts",
	aboutConsFrom: -5,
	aboutConsTo: 5
};
