import { Button, Dialog, Upload, Tabs, Icon } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { RouteImportDialogProps } from "./type";
import { tabList } from "./source";
import classNames from "classnames";
const RouteImportDialog: FC<RouteImportDialogProps> = ({
	visible,
	onClose,
	onFinish,
	routeInfo
}) => {
	const {
		loading,
		hanleCloseDialog,
		onConfirm,
		handleClickTab,
		routeImportDialogState,
		handleAddFile,
		referList,
		handleSelectRefer,
		handleCancelCollectPlan
	} = useIndex(onClose, onFinish, routeInfo);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={() => {
				hanleCloseDialog(onClose);
			}}
			loading={loading}
		>
			<div className={styles["routeImportDialog"]}>
				<div className="fleet-header">
					<div className="routeImportDialog-header">
						<div>Route</div>
						<Icon
							type="icon-close2"
							className="close-btn"
							onClick={() => {
								hanleCloseDialog(onClose);
							}}
						/>
					</div>
				</div>
				<div className="routeImportDialog-main">
					<Tabs
						list={tabList}
						onClick={(activeKey) => {
							handleClickTab(activeKey);
						}}
						value={routeImportDialogState.importType}
					/>
					<div className="main-title">
						{routeInfo?.portName?.length > 0 && `* Route${" "}`}
						{routeInfo?.portName?.map((item, index) => {
							const length = routeInfo?.portName?.length;
							return index < length - 1
								? `[${index + 1}] ${item} ~`
								: ` [${index + 1}] ${item}`;
						})}
					</div>
					{routeImportDialogState.importType === "algorithm" && (
						<div className="main-algorithm"></div>
					)}
					{routeImportDialogState.importType === "custom" && (
						<div className="main-custom">
							<Upload
								type="dragger"
								onUpload={(file) => {
									handleAddFile(file);
								}}
							/>
						</div>
					)}
					{routeImportDialogState.importType === "refer" && (
						<div className="main-refer">
							{referList?.map((item, index) => {
								return (
									//"refer-item"
									<div
										className={classNames("refer-item", {
											"refer-item-active":
												item?.id === routeImportDialogState.referId
										})}
										key={item?.id}
										onClick={() => {
											handleSelectRefer(item?.id);
										}}
									>
										<div className="refer-item-info">
											<div className="refer-item-info-title">
												{item?.portName?.map((portNameItem, portNameIndex) => {
													const length = item?.portName?.length;
													return (
														<span>
															{portNameItem}
															{portNameIndex < length - 1
																? " → "
																: ` (${item?.name || `Route ${index + 1}`})`}
														</span>
													);
												})}
											</div>
											<div className="refer-item-info-distance">
												{item?.distance}nm
											</div>
										</div>
										<div className="refer-item-opreations">
											<span>
												<Icon type="icon-show"></Icon>
											</span>
											<span
												onClick={() => {
													handleCancelCollectPlan(item?.id);
												}}
											>
												<Icon type="icon-liked"></Icon>
											</span>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<div className="routeImportDialog-footer">
					<Button
						type="secondary"
						onClick={() => {
							hanleCloseDialog(onClose);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							onConfirm();
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default RouteImportDialog;
