import React from "react";
import { ColumnsType } from "antd/es/table";
import { FleetInput, Select } from "@/components";
import classNames from "classnames";
import { CommonColumnChangeEvent } from "@/types/event";
import { FuelTableDataItemType, NoonFuelFormProps } from "../type";
import { cospIsoClassTypeOptions } from "../../../components/noonCospRF/source";
import { CospIsoClassType } from "../../../components/noonCospRF/type";

const useIndex = (props: NoonFuelFormProps) => {
	const { dataSource, onCommit } = props;

	const opereteColumns: ColumnsType = [
		{
			title: "(MT)",
			dataIndex: "mt",
			key: "mt",
			align: "left",
			render: (value, record, index) => {
				return (
					<div className={classNames({ necessary: record.isNecessary })}>
						{value}
					</div>
				);
			}
		},
		{
			title: "HFO",
			dataIndex: "hfo",
			key: "hfo",
			align: "left",
			render: (value, record, index) => {
				const isIsoClass = record.mt === "ISO CLASS";
				const element = isIsoClass ? (
					<Select
						allowClear
						value={value}
						options={cospIsoClassTypeOptions}
						onChange={(val: any) => {
							handleChangeFuelTableItem(index, "hfo", val);
						}}
						onClear={() => {
							handleChangeFuelTableItem(index, "hfo", "");
						}}
					/>
				) : (
					<FleetInput
						type="number"
						disabled={record.disabled}
						value={value}
						onChange={(val: number) => {
							handleChangeFuelTableItem(index, "hfo", val);
						}}
					/>
				);
				return <div>{element}</div>;
			}
		},
		{
			title: "LSFO",
			dataIndex: "lsfo",
			key: "lsfo",
			align: "left",
			render: (value, record, index) => {
				const isIsoClass = record.mt === "ISO CLASS";
				const element = isIsoClass ? (
					<Select
						allowClear
						value={value}
						options={cospIsoClassTypeOptions}
						onChange={(val: any) => {
							handleChangeFuelTableItem(index, "lsfo", val);
						}}
						onClear={() => {
							handleChangeFuelTableItem(index, "lsfo", "");
						}}
					/>
				) : (
					<FleetInput
						type="number"
						disabled={record.disabled}
						value={value}
						onChange={(val: number) => {
							handleChangeFuelTableItem(index, "lsfo", val);
						}}
					/>
				);

				return <div>{element}</div>;
			}
		},
		{
			title: "MDO",
			dataIndex: "mdo",
			key: "mdo",
			align: "left",
			render: (value, record, index) => {
				const isIsoClass = record.mt === "ISO CLASS";
				const element = isIsoClass ? (
					<Select
						allowClear
						value={value}
						options={cospIsoClassTypeOptions}
						onChange={(val: any) => {
							handleChangeFuelTableItem(index, "mdo", val);
						}}
						onClear={() => {
							handleChangeFuelTableItem(index, "mdo", "");
						}}
					/>
				) : (
					<FleetInput
						type="number"
						disabled={record.disabled}
						value={value}
						onChange={(val: number) => {
							handleChangeFuelTableItem(index, "mdo", val);
						}}
					/>
				);
				return <div>{element}</div>;
			}
		},
		{
			title: "MGO",
			dataIndex: "mgo",
			key: "mgo",
			align: "left",
			render: (value, record, index) => {
				const isIsoClass = record.mt === "ISO CLASS";
				const element = isIsoClass ? (
					<Select
						allowClear
						value={value}
						options={cospIsoClassTypeOptions}
						onChange={(val: any) => {
							handleChangeFuelTableItem(index, "mgo", val);
						}}
						onClear={() => {
							handleChangeFuelTableItem(index, "mgo", "");
						}}
					/>
				) : (
					<FleetInput
						type="number"
						disabled={record.disabled}
						value={value}
						onChange={(val: number) => {
							handleChangeFuelTableItem(index, "mgo", val);
						}}
					/>
				);
				return <div>{element}</div>;
			}
		}
	];

	const handleChangeFuelTableItem: CommonColumnChangeEvent<
		FuelTableDataItemType
	> = (currentIndex, key, value) => {
		const curItem = dataSource[currentIndex];
		onCommit(currentIndex, { ...curItem, [key]: value });
	};
	return { opereteColumns, handleChangeFuelTableItem };
};

export default useIndex;
