import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Icon, Popover, Toast } from "@/components";
import { htmlToPdf, htmlToA4Pdf, htmlToMultiPagePdf } from "@/tools/htmlToPdf";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { RoutePlanDialogProps } from "./type";
import style from "./index.module.less";
import { VoyPlanReport } from "..";
import { VoyPlanReportDataType } from "../voyPlanReport/type";

const RoutePlanDialog: FC<RoutePlanDialogProps> = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();
	const { hide, onClose, id } = props;

	// 报告数据
	const [voyPlanReportData, setVoyPlanReportData] =
		useState<VoyPlanReportDataType>(null);

	const formatTimestampToDateString = (timestamp: number): string => {
		const date = new Date(timestamp);
		const year = date.getFullYear().toString().slice(-2); // 获取年份的后两位
		const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份从 0 开始，需要加 1
		const day = date.getDate().toString().padStart(2, "0");
		return `${year}${month}${day}`;
	};

	const handleHtmlToPdf = async () => {
		console.log("html to pdf");
		htmlToPdf(
			document.querySelector("#voy-plan-report"),
			`Route Plan for MV ${voyPlanReportData.reportDetail.vesselName} ${formatTimestampToDateString(voyPlanReportData.reportDetail.createTime)}`,
			"flag-no-break"
		);
		// htmlToMultiPagePdf(
		// 	document.querySelector("#voy-plan-report"),
		// 	`Route Plan for MV ${voyPlanReportData.reportDetail.vesselName} ${formatTimestampToDateString(voyPlanReportData.reportDetail.createTime)}`
		// );
	};

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleGetReportPlanPreviewSuccess = (res) => {
		setVoyPlanReportData(res.data);
	};

	// 请求预览pdf数据
	const handlePrePdfDataRequest = () => {
		onRequest(
			"getReportPlanPreviewApi",
			{ id },
			handleRequestFront,
			handleGetReportPlanPreviewSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const updatePlanSyncStatusFront = () => {};
	const updatePlanSyncStatusSuccess = () => {
		handlePrePdfDataRequest();
	};
	const updatePlanSyncStatusError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	// 同步状态处理
	const handleSyncStatusChange = (id: string) => {
		onRequest(
			"updatePlanSyncStatusApi",
			{ id },
			updatePlanSyncStatusFront,
			updatePlanSyncStatusSuccess,
			updatePlanSyncStatusError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (!hide) {
			handlePrePdfDataRequest();
		} else {
			setVoyPlanReportData(null);
		}
	}, [hide]);

	return (
		<Dialog
			mode="fixed"
			hide={hide}
			closeable={true}
			drawer={true}
			onClose={() => {
				onClose();
			}}
		>
			<div className={style["route-plan-dialog"]}>
				<div className="route-plan-header">
					<p className="route-plan-title">Route Plan</p>
					<div className="route-plan-btns">
						{voyPlanReportData?.reportDetail?.syncStatus ? (
							<Popover
								render={(setIsOpen) => {
									return (
										<Toast
											label="Do you want to cancel synchronization?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												handleSyncStatusChange(props.id);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<div className="rp-sync-btn">
									<Icon
										size={20}
										type="icon-date-synchronized"
										color="#1bae23"
									></Icon>
								</div>
							</Popover>
						) : (
							<Popover
								render={(setIsOpen) => {
									return (
										<Toast
											label="Synchronize or not?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												handleSyncStatusChange(props.id);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<div className="rp-sync-btn">
									<Icon
										size={20}
										color="#333"
										type="icon-a-date-notsynchronized"
									></Icon>
								</div>
							</Popover>
						)}

						<div className="rp-email-btn">
							<Icon type="icon-send_email" />
						</div>
						<div className="rp-download-btn" onClick={() => handleHtmlToPdf()}>
							<Icon type="icon-download1" />
						</div>
						<div className="rp-close-btn" onClick={() => onClose()}>
							<Icon type="icon-close2" />
						</div>
					</div>
				</div>
				<div className="route-plan-body">
					<VoyPlanReport data={voyPlanReportData} />
				</div>
			</div>
		</Dialog>
	);
};
export default RoutePlanDialog;
