import { CommonOptionType } from "@/types/common";
import { RouteCommonToolbarMap } from "../list";

export const metoeLayerOptions: CommonOptionType<
	RouteCommonToolbarMap,
	keyof typeof RouteCommonToolbarMap
>[] = [
	{
		label: RouteCommonToolbarMap.Wind,
		value: "Wind"
	},
	{
		label: RouteCommonToolbarMap.Wave,
		value: "Wave"
	},
	{
		label: RouteCommonToolbarMap.Swell,
		value: "Swell"
	},
	{
		label: RouteCommonToolbarMap.Current,
		value: "Current"
	},
	{
		label: RouteCommonToolbarMap.Typhoon,
		value: "Typhoon"
	},
	{
		label: RouteCommonToolbarMap.Pressure,
		value: "Pressure"
	}
];

export const controllLayerOptions: CommonOptionType<
	RouteCommonToolbarMap,
	keyof typeof RouteCommonToolbarMap
>[] = [
	{ label: RouteCommonToolbarMap.Ports, value: "Ports" },
	{
		label: RouteCommonToolbarMap.Loadline,
		value: "Loadline"
	},
	{
		label: RouteCommonToolbarMap.Eca,
		value: "Eca"
	},
	{
		label: RouteCommonToolbarMap.War,
		value: "War"
	},
	{
		label: RouteCommonToolbarMap.ENC,
		value: "ENC"
	}
];
