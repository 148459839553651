import { useMemo, useState } from "react";
import { VoyGwClauseItemType } from "../type";
import { CommonChangeEvent, CommonCommitEvent } from "@/types/event";
import { initialData } from "../source";

const useIndex = () => {
	const [voyGwClauseData, setVoyGwClauseData] =
		useState<VoyGwClauseItemType>(initialData);

	const handleChangeVoyGwClauseItem: CommonChangeEvent<VoyGwClauseItemType> = (
		key,
		value
	) => {
		setVoyGwClauseData((prev) => {
			return { ...prev, [key]: value };
		});
	};

	const handleVoyGwClauseItemCommit: CommonCommitEvent<VoyGwClauseItemType> = (
		item
	) => {
		setVoyGwClauseData((prev) => {
			return { ...prev, ...item };
		});
	};

	const clearVoyGwClauseData = () => {
		setVoyGwClauseData(initialData);
	};

	return {
		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,
		setVoyGwClauseData,
		clearVoyGwClauseData
	};
};

export default useIndex;
