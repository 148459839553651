import React from "react";
import type { ColumnsType } from "antd/es/table/interface";
import { convertDdToDm } from "@/tools/latLong";
import { RouteForecastTableItemType } from "./type";
import { timestampToUtcMdhm } from "@/tools/date";
import { formatNumber } from "@/tools/amount";

export const columns: ColumnsType<RouteForecastTableItemType> = [
	{
		title: `Date Time
		(UTC)`,
		key: "dataTime",
		dataIndex: "dataTime",
		align: "center",
		width: 110,
		render(value, record, index) {
			return <div>{timestampToUtcMdhm(value)}</div>;
		}
	},
	{
		title: `Position
		(Lat/Lon)`,
		align: "center",
		width: 160,
		render(value, record, index) {
			const {
				degree: latDegree,
				minute: latMinute,
				unit: latUnit
			} = convertDdToDm(record.lat, true);
			const {
				degree: lonDegree,
				minute: lonMinute,
				unit: lonUnit
			} = convertDdToDm(record.lon, false);

			const positionStr = `${latDegree}°${formatNumber(latMinute, 2)}′${latUnit} / ${lonDegree}°${formatNumber(lonMinute, 2)}′${lonUnit}`;
			return <div>{positionStr}</div>;
		}
	},
	{
		title: "Wind",
		align: "center",
		width: 131,
		children: [
			{
				title: "Dir",
				key: "wd",
				dataIndex: "wd",
				align: "center",
				render(value, record, index) {
					return <div>{record.wdStr}</div>;
				}
			},
			{
				title: "Speed (kts)",
				key: "ws",
				dataIndex: "ws",
				align: "center",
				render(value, record, index) {
					return <div>{formatNumber(Number(value), 1)}</div>;
				}
			}
		]
	},
	{
		title: "Swell",
		align: "center",
		width: 221,
		children: [
			{
				title: "Dir",
				key: "swellDir",
				dataIndex: "swellDir",
				align: "center",
				render(value, record, index) {
					return <div>{record.swellDirStr}</div>;
				}
			},
			{
				title: "Ht (m)",
				key: "swellHeight",
				dataIndex: "swellHeight",
				align: "center",
				render(value, record, index) {
					return <div>{formatNumber(Number(value), 1)}</div>;
				}
			},
			{
				title: "Prd (s)",
				key: "swellPeriod",
				dataIndex: "swellPeriod",
				align: "center",
				render(value, record, index) {
					return <div>{formatNumber(Number(value), 1)}</div>;
				}
			}
		]
	},
	{
		title: "Sig. Wave (m)",
		key: "sigWaveHeight",
		dataIndex: "sigWaveHeight",
		align: "center",
		width: 83,
		render(value, record, index) {
			return <div>{formatNumber(Number(value), 1)}</div>;
		}
	},
	{
		title: "Current",
		align: "center",
		width: 185,
		children: [
			{
				title: "Dir",
				key: "currentDir",
				dataIndex: "currentDir",
				align: "center",
				render(value, record, index) {
					return <div>{record.currentDirStr}</div>;
				}
			},
			{
				title: "Speed (kts)",
				key: "currentSpeed",
				dataIndex: "currentSpeed",
				align: "center",
				render(value, record, index) {
					return <div>{formatNumber(Number(value), 2)}</div>;
				}
			},
			{
				title: "Factor (kts)",
				key: "cf",
				dataIndex: "cf",
				align: "center",
				render(value, record, index) {
					return <div>{formatNumber(Number(value), 2)}</div>;
				}
			}
		]
	}
];
