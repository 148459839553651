import { onRequest } from "@/api";
import { useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
const useIndex = (details, itemIndex, itemkey) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	let newDetails = { ...details };
	const [loading, setLoading] = useState(false);

	const hanleCloseDialog = (closeDialog) => {
		closeDialog && closeDialog();
	};

	console.log(details, itemIndex, itemkey);
	const [time, setTime] = useState(null);

	const onChange = (val) => {
		setTime(val);
		newDetails.portRotation[itemIndex] = {
			...newDetails.portRotation[itemIndex],
			[itemkey]: val
		};
	};
	const onConfirm = (onClose, successCb) => {
		const params = {
			portRotationFOS: newDetails?.portRotation,
			voyageLegFO: {
				voyageId: newDetails?.voyage?.id,
				legType: newDetails?.voyage?.legType ?? "B",
				quotation: newDetails?.voyage?.quotation ?? 1
			}
		};
		editLegPort(params, onClose, successCb);
	};
	const editLegPortFront = () => {
		setLoading(true);
	};

	const editLegPortSuccess = (onClose, successCb) => {
		successCb && successCb();
		hanleCloseDialog(onClose);
		setLoading(false);
	};
	const editLegPortFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const editLegPort = (params, onClose, successCb) => {
		onRequest(
			"addLegPortApi",
			{
				...params
			},
			editLegPortFront,
			() => {
				editLegPortSuccess(onClose, successCb);
			},
			editLegPortFailed,
			dispatch,
			navigate
		);
	};
	return {
		loading,
		hanleCloseDialog,
		time,
		onChange,
		onConfirm
	};
};

export default useIndex;
