import { useCallback, useEffect, useState } from "react";
import { LegBasicInfoItemType } from "../components/LegBasicInfo/type";
import {
	LegPortPotationType,
	LegPortRotationProps
} from "../components/LegPortRotation/type";
import { CommonChangeEvent } from "@/types/event";
import { AddLegPortParameterType } from "@/api/leg/type";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";

const useIndex = (
	voyageId,
	legId,
	portRotationList,
	basicInfoData,
	afterConfirm
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const portRotationAdapted: LegPortPotationType = portRotationList?.map(
		(item) => {
			return { port: { ...item }, ETD: item.etd || "", disable: true };
		}
	);
	const updateLegPortRotationList = useCallback((portRotationAdapted) => {
		setLegPortRotationList(portRotationAdapted);
	}, []);
	const updateBasicInfoData = useCallback((basicInfoData) => {
		setLegBasicInfoItemData(basicInfoData);
	}, []);
	useEffect(() => {
		updateLegPortRotationList(
			portRotationAdapted || [{ port: null, ETD: "", disable: true }]
		);
		updateBasicInfoData(
			basicInfoData || {
				condition: "B",
				quotation: ""
			}
		);
	}, [portRotationList, basicInfoData]);
	const [legBasicInfoItemData, setLegBasicInfoItemData] = useState(
		basicInfoData || {
			condition: "B",
			quotation: ""
		}
	);

	const handleChangeBasicInfoItem: CommonChangeEvent<LegBasicInfoItemType> = (
		key,
		value
	) => {
		setLegBasicInfoItemData((prev) => {
			return { ...prev, [key]: value };
		});
	};

	const [loading, setLoading] = useState(false);
	const [delIds, setDelIds] = useState<number[]>([]);

	const [dialogVisible, setDialogVisible] = useState(false);
	const closeAndReset_dialog = (closeDialog: () => void) => {
		setLegBasicInfoItemData(
			basicInfoData || {
				condition: "B",
				quotation: ""
			}
		);
		setLegPortRotationList([{ port: null, ETD: "", disable: true }]);
		setDelIds([]);
		closeDialog && closeDialog();
	};
	const handleConfirmDialog = (closeDialog: () => void) => {
		const legPortRotationList_adaptApi: AddLegPortParameterType["portRotationFOS"] =
			legPortRotationList.map((item) => {
				return {
					...item.port,
					portCode: item.port?.portCode,
					portName: item.port?.portName,
					etd: item?.ETD ? new Date(item?.ETD).getTime() + "" : "",
					legId
				};
			});
		const params = {
			...legBasicInfoItemData,
			portRotaionList: legPortRotationList
		};
		const params_adaptApi: AddLegPortParameterType = {
			delIds: delIds,
			voyageLegFO: {
				legType: params.condition,
				quotation: params.quotation || 0,
				voyageId: voyageId,
				id: legId
			},
			portRotationFOS: legPortRotationList_adaptApi
		};
		addLegPort(params_adaptApi, () => {
			afterConfirm && afterConfirm();
			closeAndReset_dialog(closeDialog);
		});
	};

	const [legPortRotationList, setLegPortRotationList] =
		useState<LegPortPotationType>([{ port: null, ETD: "", disable: true }]);

	const onChangeLegPortPotationList: LegPortRotationProps["onChangeList"] = (
		index,
		type,
		key,
		value
	) => {
		switch (type) {
			case "create":
				setLegPortRotationList((prev) => {
					return [...prev, { port: null, ETD: "", disable: false }];
				});
				break;
			case "update":
				setLegPortRotationList((prev) => {
					return prev.map((item, ietmIndex) => {
						if (ietmIndex === index) {
							return { ...item, [key]: value };
						} else {
							return item;
						}
					});
				});
				break;
			case "delete":
				// if (legPortRotationList.length <= 2) {
				// 	return reminder("error", "港口数量不能小于2, 删除操作失败");
				// }
				setLegPortRotationList((prev) => {
					console.log("查看一下数据, ", legPortRotationList);
					setDelIds((pre) => {
						if (prev[index].port?.id) {
							pre.push(prev[index].port.id);
						}
						return pre;
					});
					console.log("查看删除的港口：", prev[index]);
					return prev.filter((item, ietmIndex) => ietmIndex !== index);
				});
				break;
			case "move_down":
				setLegPortRotationList((prev) => {
					const newList = [...prev];
					const currentItem = newList[index];
					newList[index] = newList[index + 1];
					newList[index + 1] = currentItem;
					return newList;
				});
				break;
			case "move_up":
				setLegPortRotationList((prev) => {
					const newList = [...prev];
					const currentItem = newList[index];
					newList[index] = newList[index - 1];
					newList[index - 1] = currentItem;
					return newList;
				});
				break;
		}
	};

	const addLegPortFront = () => {
		console.log("addLegPortFront");
		setLoading(true);
	};

	const addLegPortSuccess = (successCb) => {
		successCb && successCb();
		setLoading(false);
	};
	const addLegPortFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const addLegPort = (params: AddLegPortParameterType, successCb: any) => {
		console.log("来看看数据！！！！！！！！！！！", params);

		onRequest(
			"addLegPortApi",
			{
				...params
			},
			addLegPortFront,
			() => {
				addLegPortSuccess(successCb);
			},
			addLegPortFailed,
			dispatch,
			navigate
		);
	};

	return {
		legBasicInfoItemData,
		handleChangeBasicInfoItem,
		loading,
		dialogVisible,
		handleConfirmDialog,
		legPortRotationList,
		setLegPortRotationList,
		onChangeLegPortPotationList,
		closeAndReset_dialog
	};
};

export default useIndex;
