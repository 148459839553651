export enum RouteCommonToolbarMap {
	Wind = "Wind",
	Wave = "Wave",
	Swell = "Swell",
	Current = "Current",
	Typhoon = "Typhoon",
	Pressure = "Pressure",
	Loadline = "Load Line",
	Eca = "ECA",
	War = "Zones",
	Ports = "Ports",
	ENC = "ENC"
}

export const initialToolbalItems = (
	items: (keyof typeof RouteCommonToolbarMap)[]
) => {
	const meteoItems: (keyof typeof RouteCommonToolbarMap)[] = [],
		controllItems: (keyof typeof RouteCommonToolbarMap)[] = [];
	for (let item of items) {
		if (
			["Wind", "Wave", "Swell", "Current", "Typhoon", "Pressure"]?.includes(
				item
			)
		) {
			meteoItems?.push(item);
		} else {
			controllItems?.push(item);
		}
	}
	return {
		controllItems,
		meteoItems
	};
};
