import React from "react";
import { PageRouterProps } from "@/router";
import Leg from "@/pages/leg";
import legRouter from "@/pages/leg/router";
import voyRouter from "@/pages/voy/router";

import { Voyage } from "@/pages";
export interface LayoutChildrenProps extends PageRouterProps {}

export enum LayoutMap {
	"/layout/estimation" = "@/pages/estimation",
	"/layout/voyagemanage" = "@/pages/voyagemanage",
	"/layout/monitoring" = "@/pages/monitoring",
	"/layout/database" = "@/pages/database",
	"/layout/perfreport" = "@/pages/perfReport"
}

export const LayoutChildren: PageRouterProps[] = [
	{
		element: <Voyage />,
		key: "/layout/voy",
		path: "voy",
		label: "Voy List",
		icon: "icon-voy",
		children: voyRouter
		// children: legRouter
	},
	{
		element: <Leg />,
		key: "/layout/leg",
		path: "leg",
		label: "Leg List",
		children: legRouter,
		icon: "icon-leg"
	}

	// {
	// 	element: <LegBasic />,
	// 	key: "/layout/basic",
	// 	path: "basic",
	// 	label: "Leg Basic",
	// 	children: legBasicRouter,
	// 	icon: "icon-leg"
	// }
	// {
	// 	element: <MarketInsights />,
	// 	key: "/layout/marketinsights",
	// 	path: "marketinsights",
	// 	label: "Market Insights",
	// 	icon: "LayoutHeaderEstinamtion",
	// 	selectedIcon: "LayoutHeaderEstinamtionActive"
	// },
	// {
	// 	// index: true,
	// 	key: "/layout/estimation",
	// 	path: "estimation",
	// 	label: "Estimation",
	// 	element: <Estimation />,
	// 	icon: "LayoutHeaderEstinamtion",
	// 	selectedIcon: "LayoutHeaderEstinamtionActive",
	// 	children: EstimationRouter
	// },
	// {
	// 	key: "/layout/voyagemanage",
	// 	path: "voyagemanage",
	// 	label: "Voyage Management",
	// 	element: <VoyageManage />,
	// 	children: VoyageManageRouter,
	// 	icon: "LayoutHeaderManage",
	// 	selectedIcon: "LayoutHeaderManageActive"
	// 	// disabled:true
	// },
	// {
	// 	key: "/layout/monitoring",
	// 	path: "monitoring",
	// 	label: "Monitoring",
	// 	element: <Monitoring />,
	// 	icon: "LayoutHeaderMonitoring",
	// 	selectedIcon: "LayoutHeaderMonitoringActive"
	// 	// disabled:true
	// },
	// // {
	// //   key: "/layout/biInsights",
	// //   path: "biInsights",
	// //   label: "BI Insights",
	// //   element: <BiInsights />,
	// //   icon: "LayoutHeaderInsights",
	// //   selectedIcon: "LayoutHeaderInsightsActive",
	// //   disabled:true
	// // },
	// {
	// 	key: "/layout/database",
	// 	path: "database",
	// 	label: "Database",
	// 	element: <FleetDatabase />,
	// 	children: DatabaseRouter,
	// 	icon: "LayoutHeaderInsights",
	// 	selectedIcon: "LayoutHeaderInsightsActive"
	// },
	// {
	// 	key: "/layout/perfreport",
	// 	element: <PerfReport />,
	// 	children: [],
	// 	path: "perfreport",
	// 	label: "Perf. Report"
	// }
];

const LayoutRouter: PageRouterProps[] = [].concat(LayoutChildren);

export default LayoutRouter;
