import { ApiParameter, RequestApiMapType } from "@/api/type";
import { APP_VERSION_V1 } from "@/api/constant";
import request from "@/service";

import {
	getRouteCommonDiscreteParams,
	RouteCommonMeteorologicalParamsType,
	updateMapWayPointParams,
	deleteMapWayPointParams,
	addMapWayPointParams,
	getMapWayPointsParams,
	getMapLayersParams,
	updateMapLayersParams,
	getMapHiFleetParams
} from "./type";

const enum ApiMap {
	getRouteCommonDiscreteParameter,
	updateMapWayPointParameter,
	deleteMapWayPointParameter,
	addMapWayPointParameter,
	getMapWayPointsParameter,
	getMapLayersParameter,
	updateMapLayersParameter,
	getMapHiFleetParameter
}

export type ApiUrl = keyof typeof ApiMap;

const meteorologicalApi: ApiParameter<ApiUrl> = {
	getRouteCommonDiscreteParameter: {
		method: "post",
		url: "/meteoapi/meteorological/discrete"
	},
	updateMapWayPointParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/way-point"
	},
	deleteMapWayPointParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/way-point"
	},
	addMapWayPointParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/way-point"
	},
	getMapWayPointsParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/way-point/plan"
	},
	getMapLayersParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/favorite-layer"
	},
	updateMapLayersParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/favorite-layer"
	},
	getMapHiFleetParameter: {
		method: "get",
		url: "https://api.hifleet.com/nauticalmap/en/token"
	}
};

export const getRouteCommonDiscreteApi = (
	params: getRouteCommonDiscreteParams
) => {
	return request.request({
		...meteorologicalApi.getRouteCommonDiscreteParameter,
		data: [params],
		headers: {
			// "x-api-key": "hf9r5RUhDd3dIwhe3cycH4xHOUvBDGT537d3T3VV",
			"Content-Type": "application/json"
		}
	});
};

export const updateMapWayPointApi = (params: updateMapWayPointParams) => {
	return request.request({
		...meteorologicalApi.updateMapWayPointParameter,
		data: params
	});
};

export const deleteMapWayPointApi = (params: deleteMapWayPointParams) => {
	return request.request({
		...meteorologicalApi.deleteMapWayPointParameter,
		url: `${meteorologicalApi?.deleteMapWayPointParameter?.url}/${params?.id}`
	});
};

export const addMapWayPointApi = (params: addMapWayPointParams) => {
	return request.request({
		...meteorologicalApi.addMapWayPointParameter,
		data: params
	});
};

export const getMapWayPointsApi = (params: getMapWayPointsParams) => {
	return request.request({
		...meteorologicalApi.getMapWayPointsParameter,
		url: `${meteorologicalApi?.getMapWayPointsParameter?.url}/${params?.planId}`
	});
};

export const getMapLayersApi = (params: getMapLayersParams) => {
	return request.request({
		...meteorologicalApi.getMapLayersParameter
	});
};

export const updateMapLayersApi = (params: updateMapLayersParams) => {
	return request.request({
		...meteorologicalApi.updateMapLayersParameter,
		data: params
	});
};

export const getMapHiFleetApi = (params: getMapHiFleetParams) => {
	return request.request({
		...meteorologicalApi.getMapHiFleetParameter,
		params
	});
};

export const meteorologicalApiMap: RequestApiMapType<
	ApiUrl,
	RouteCommonMeteorologicalParamsType
> = {
	getRouteCommonDiscreteApi,
	updateMapWayPointApi,
	deleteMapWayPointApi,
	addMapWayPointApi,
	getMapWayPointsApi,
	getMapLayersApi,
	updateMapLayersApi,
	getMapHiFleetApi
};
