import React from "react";
import style from "./index.module.less";

const FleetToast: React.FC<{
	label?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
}> = ({ label, onCancel, onConfirm }) => {
	return (
		<div className={style["fleet-toast"]}>
			<div className="toast-header">
				{/* <FleetIcon type="FleetToastWarningIcon" outerLayerClassname="header-icon" /> */}
				<span>{label}</span>
			</div>
			<div className="toast-actions">
				<span
					onClick={(e) => {
						e?.stopPropagation();
						onCancel?.();
					}}
					className="toast-action"
				>
					No
				</span>
				<span
					onClick={(e) => {
						e?.stopPropagation();
						onConfirm?.();
					}}
					className="toast-action"
				>
					Yes
				</span>
			</div>
		</div>
	);
};

export default FleetToast;
