import React from "react";
import { Table } from "@/components";
import style from "./index.module.less";
import { PlanTitle } from "../../components";
import { columns } from "./source";
import { RouteSummaryProps } from "./type";
import classNames from "classnames";

const RouteSummary: React.FC<RouteSummaryProps> = (props) => {
	return (
		<div className={classNames(["flag-no-break", style["vpr-route-summary"]])}>
			<PlanTitle title="ROUTE SUMMARY" showDot={true} />
			<Table
				id="id"
				className="route-summary-table"
				columns={columns}
				dataSource={props.data}
				scroll={null}
				pagination={false}
				bordered
			/>
		</div>
	);
};

export default RouteSummary;
