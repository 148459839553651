import React, { FC } from "react";
import { Dialog, Button, Table } from "@/components";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";
import { AddNoonSegmentProps } from "./type";
// import { ColumnsType } from "antd/es/table";

const AddNoonSegment: FC<AddNoonSegmentProps> = (props) => {
	const { hide, onClose, queryData, scReportDateList } = props;
	const {
		opereteColumns,
		handleAddSegmentClick,
		segmentTableData,
		handleConfirmClick,
		dataSource
	} = useIndex(props);

	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={hide}
			onClose={onClose}
		>
			<div className={style["add-noon-segment"]}>
				<div className="segment-add-header">
					<div>Add Noon Segment</div>
					<div className="close-btn" onClick={onClose}>
						×
					</div>
				</div>
				<div className="segment-add-main">
					<div className="segment-header">
						<div className="segment-title">Segment</div>
						<Button type="secondary" onClick={handleAddSegmentClick}>
							Add
						</Button>
					</div>
					<div className="segment-body">
						<Table
							id="key"
							dataSource={dataSource}
							scroll={null}
							columns={opereteColumns}
							pagination={false}
						/>
					</div>
				</div>
				<div className="segment-add-footer">
					<Button type="secondary" size="large" onClick={onClose}>
						Cancel
					</Button>
					<Button size="large" onClick={handleConfirmClick}>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default AddNoonSegment;
