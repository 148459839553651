import React, { FC } from "react";
import styles from "./index.module.less";
import { GridTableProps } from "./type";
const GridTable: FC<GridTableProps> = ({ dataSource }) => {
	return (
		<div className={styles.GridTable}>
			{dataSource?.map((item, index) => (
				<div
					className="gridTable-item"
					key={index}
					style={{ gridColumn: `auto / span ${item.colSpan || 1}` }}
				>
					<div className="item-label">{item.label}</div>
					<div className="item-value">{item.value}</div>
				</div>
			))}
		</div>
	);
};

export default GridTable;
