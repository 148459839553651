import React, { useRef } from "react";
import { DatePicker, DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { FleetUtcTimePickerProps } from "./type";

dayjs.extend(utc);

const FleetUtcTimePicker: React.FC<FleetUtcTimePickerProps> = ({
	value,
	onChange,
	className,
	disabled,
	minDate,
	maxDate
}) => {
	const utcValue = value ? dayjs.utc(value) : null;
	// const isSelected = useRef(false);

	console.log("选择的时间: ", dayjs(value));
	console.log("经过UTC变换的时间: ", utcValue);

	const handleDateChange = (date: Dayjs | null) => {
		if (!date) {
			onChange?.(null);
			// isSelected.current = false;
			return;
		}
		const timestamp = date.valueOf();
		// const timeZoneOffset = isSelected.current
		// 	? 0
		// 	: new Date().getTimezoneOffset() * 60 * 1000;
		// onChange?.(timestamp - timeZoneOffset);
		onChange?.(timestamp);
		// isSelected.current = true;
	};

	return (
		<DatePicker
			className={className}
			showTime
			disabled={disabled}
			format="YYYY-MM-DD HH:mm"
			onChange={handleDateChange}
			minDate={minDate ? dayjs(minDate) : null}
			maxDate={maxDate ? dayjs(maxDate) : null}
			value={utcValue}
		/>
	);
};

export default FleetUtcTimePicker;
