import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { htmlToMultiPagePdf, htmlsToMultiPagePdf } from "@/tools/htmlToPdf";
import { ReportContentType } from "../../../type";

const useIndex = (reportId: string) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const [reportContent, setReportContent] = useState<ReportContentType>(null);

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");

	const handleConfirm = () => {
		htmlsToMultiPagePdf(
			[document.querySelector("#main-voyageDeviationReportPdf")],
			"Voyage_Deviation_Report"
		);
	};

	const initReportDataFront = () => {
		setLoading(true);
	};
	const initReportDataSuccess = (data) => {
		console.log("报告数据", data);
		const { reportContent } = data.data;
		setReportContent(reportContent);
		setLoading(false);
	};

	const initReportDataError = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const initReportData = () => {
		onRequest(
			"getReportDataApi",
			{ reportId },
			initReportDataFront,
			initReportDataSuccess,
			initReportDataError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		initReportData();
	}, []);

	return {
		loading,
		handleConfirm,
		reportContent
	};
};

export default useIndex;
