import React, { FC } from "react";
import { Dialog, Button, Loading, Icon } from "@/components";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { LegBasicInfo, LegPortRotation } from "./components";
import { LegAddProps } from "./type";
const LegAdd: FC<LegAddProps> = ({
	visible,
	onClose,
	voyageId,
	legId,
	portRotationList,
	basicInfoData,
	afterConfirm
}) => {
	const {
		legBasicInfoItemData,
		handleChangeBasicInfoItem,
		loading,
		dialogVisible,
		closeAndReset_dialog,
		handleConfirmDialog,
		legPortRotationList,
		onChangeLegPortPotationList
	} = useIndex(voyageId, legId, portRotationList, basicInfoData, afterConfirm);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={() => {
				closeAndReset_dialog(onClose);
			}}
			loading={loading}
		>
			<div className={styles["leg-add"]}>
				<div className="fleet-header">
					<div className="leg-add-header">
						<span>{portRotationList ? "Edit Leg" : "Add Leg"}</span>
						<Icon
							type="icon-close2"
							className="header-close"
							onClick={() => {
								closeAndReset_dialog(onClose);
							}}
						/>
					</div>
				</div>
				<div className="leg-add-main">
					<LegBasicInfo
						item={legBasicInfoItemData}
						onChange={(key, value) => {
							handleChangeBasicInfoItem(key, value);
						}}
					/>
					<LegPortRotation
						list={legPortRotationList}
						onChangeList={onChangeLegPortPotationList}
					/>
				</div>
				<div className="leg-add-footer">
					<Button
						type="secondary"
						size="small"
						onClick={() => {
							closeAndReset_dialog(onClose);
						}}
					>
						Cancel
					</Button>
					<Button
						size="small"
						onClick={() => {
							handleConfirmDialog(onClose);
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default LegAdd;
