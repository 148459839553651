import React, { FC } from "react";
import style from "./index.module.less";
import { Image } from "@/components";
import { SiderMenuItem } from "./components";
import { CommonSiderMenuProps } from "./type";

const CommonSiderMenu: FC<CommonSiderMenuProps> = ({
	items,
	isCarryParameter
}) => {
	return (
		<div className={style["common-layout-siderMenu"]}>
			<Image src="/images/logo.png" className="siderMenu-logo" />
			<div className="siderMenu-container">
				{items?.map((item, index) => {
					return item?.hidden ? null : (
						<SiderMenuItem
							isCarryParameter={isCarryParameter}
							key={index}
							{...item}
							keyPath={item?.key as string}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default CommonSiderMenu;
