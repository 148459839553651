import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { cacheStorage } from "@/storage";
import { message } from "@/tools/message";
import { uuid } from "@/tools/generate";

export const createAxiosByinterceptors = (
	config?: AxiosRequestConfig
): AxiosInstance => {
	const instance: AxiosInstance = axios.create({
		timeout: 60 * 1000, //超时配置
		// withCredentials: true, //跨域携带cookie
		...config // 自定义配置覆盖基本配置
	});

	// 添加请求拦截器
	instance.interceptors.request.use(
		function (config: any) {
			if (
				!config.url.includes("/s3file/shiptrack/") &&
				!config?.url?.startsWith("/meteoapi/meteorological/discrete") &&
				!config?.url?.startsWith("/api/meteorological/discrete") &&
				!config?.url?.startsWith("/dev/seaweather/storm")
			) {
				config.headers.Authorization =
					"Bearer " + cacheStorage?.get("access_token");
				console.log(
					"Authorization",
					"Bearer " + cacheStorage?.get("access_token")
				);
			}
			// if (config.url.includes("/dev/seaweather/")) {
			// 	config.headers["x-api-key"] =
			// 		"eMLD7MYjfm2NI9u2Xckk43NDjYcg2v223jwZEqy1";
			// }
			console.log("config", config);
			return config;
		},
		function (error) {
			console.log("before", error);
			message.error(error);
			// 对请求错误做些什么
			return Promise.reject(error);
		}
	);

	// 添加响应拦截器
	instance.interceptors.response.use(
		function (response) {
			const { msg, code } = response.data;
			const { config } = response;
			if (response.data instanceof Blob) {
				if (response?.data?.size <= 100) {
					return response?.data;
				}
				return downloadFile(response);
			} else {
				if (response.status === 200) {
					if (
						config.url.includes("/s3file/shiptrack/") ||
						config.url.includes("/dev/seaweather/") ||
						config.url.includes("/api/meteorological/") ||
						config.url.includes("/user/logout")
					) {
						return response.data;
					}
					switch (code) {
						case 200:
							if (config.url.endsWith("/login")) {
								cacheStorage.set(
									"access_token",
									response.data.data.token,
									86400,
									true
								);
								cacheStorage.set(
									"user_info",
									{
										username: config.data?.userName,
										password: config.data?.password
									},
									86400,
									true
								);
							}
							return response.data;
						case 404:
							return {
								code: code,
								msg: msg,
								data: []
							};
						case 201:
						case 202:
							// message.error(msg);
							return Promise.reject(response?.data);
						case 403:
						case 406:
							// cacheStorage.logout();
							message.error(msg);
							return Promise.reject({
								...response.data,
								loginStatus: "token faild"
							});
						default:
							// message.error(msg);
							return Promise.reject(response.data);
					}
				} else {
					message.error(msg);
					return Promise.reject(response?.data);
				}
			}
		},
		function (error) {
			if (!axios.isCancel(error)) {
				message.error(error?.response?.data?.msg || "服务端异常");
			}
			return Promise.reject(error);
		}
	);
	return instance;
};

function downloadFile(response: AxiosResponse<any, any>): any {
	const { data, headers } = response;
	const blob = new Blob([data], { type: data?.type });
	const link = document.createElement("a");
	if (link.download !== undefined) {
		const url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute(
			"download",
			decodeURIComponent(headers?.["download-filename"]) ?? uuid()
		);
		link.style.visibility = "hidden";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	}
}
