import { BasicPortGeoJson, BasicPortItemType } from "../../types";

export const generateBasicPorts = (
	items: BasicPortItemType[]
): BasicPortGeoJson[] => {
	return items?.map((item) => {
		return {
			type: "Feature",
			geometry: {
				coordinates: [item?.lon, item?.lat],
				type: "Point"
			},
			properties: {
				...item
			}
		};
	});
};
