import React, { useState } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { LegFilterItemType, LegFilterProps } from "./type";
import {
	Button,
	FleetInput,
	FormItem,
	Select,
	FleetRangePicker
} from "@/components";
import {
	statusOptions,
	serviceOptions,
	roleOptions,
	conditionOptions,
	tagsOptions
} from "./source";
import dayjs, { Dayjs } from "dayjs";
import {
	CommonPortSelect,
	CommonPortItemType,
	CommonVesselSelect,
	CommonVesselItemType,
	CommonCompanySelect,
	CommonCompanyItemType
} from "@/common";
const LegFilter: React.FC<LegFilterProps> = ({
	item,
	onCommit,
	// onAdd,
	onSearch
}) => {
	return (
		<div className={classNames(style["leg-list-filter"])}>
			<div className="filter-container">
				<FormItem label="Vessel:" type="row">
					<CommonVesselSelect
						allowClear
						activeItems={item}
						showKey={"vesselName"}
						primaryKey={"imo"}
						onFinish={(newItem, operateType) => {
							onCommit?.({
								imo: newItem?.imo,
								vesselName: newItem?.vesselName
							});
						}}
						className="select_common"
					/>
				</FormItem>
				<FormItem label="Company:" type="row">
					<CommonCompanySelect
						allowClear
						activeItems={{ id: item?.companyId, name: item?.companyName }}
						showKey={"name"}
						primaryKey="id"
						onFinish={(newItem, operateType) => {
							onCommit?.({
								companyId: newItem?.id,
								companyName: newItem?.name
							});
						}}
						className="select_common"
					/>
				</FormItem>
				<FormItem label="Port:" type="row">
					<CommonPortSelect
						allowClear
						activeItems={item}
						showKey={"portName"}
						primaryKey="portCode"
						onFinish={(newItem, operateType) => {
							onCommit?.({
								portCode: newItem?.portCode,
								portName: newItem?.portName
							});
						}}
						className="select_common"
					/>
				</FormItem>

				<FormItem label="Status:" type="row">
					<Select
						onChange={(val: LegFilterItemType["status"]) => {
							onCommit?.({
								status: val
							});
						}}
						allowClear={true}
						onClear={() => {
							onCommit?.({
								status: null
							});
						}}
						options={statusOptions}
						value={item?.status}
						className="select_common"
					/>
				</FormItem>
				<FormItem label="Service:" type="row">
					<Select
						onChange={(val: LegFilterItemType["service"]) => {
							onCommit?.({
								service: val
							});
						}}
						options={serviceOptions}
						allowClear={true}
						onClear={() => {
							onCommit?.({
								service: null
							});
						}}
						value={item?.service}
						className="select_common"
					/>
				</FormItem>
				<FormItem label="Role:" type="row">
					<Select
						onChange={(val: LegFilterItemType["role"]) => {
							onCommit?.({
								role: val
							});
						}}
						allowClear={true}
						onClear={() => {
							onCommit?.({
								role: null
							});
						}}
						options={roleOptions}
						value={item?.role}
						className="select_common"
					/>
				</FormItem>
				<FormItem label="Condition:" type="row">
					<Select
						onChange={(val: LegFilterItemType["legType"]) => {
							onCommit?.({
								legType: val
							});
						}}
						allowClear={true}
						onClear={() => {
							onCommit?.({
								legType: null
							});
						}}
						options={conditionOptions}
						value={item?.legType}
						className="select_common"
					/>
				</FormItem>
				<FormItem label="ETD Period:" type="row" className="order-time">
					<FleetRangePicker
						onChange={(vals: [Dayjs, Dayjs]) => {
							onCommit?.({
								startETDDate: vals?.[0]?.format("YYYY-MM-DD") ?? "",
								endETDDate: vals?.[1]?.format("YYYY-MM-DD") ?? ""
							});
						}}
						value={[
							item?.startETDDate ? dayjs(item?.startETDDate) : null,
							item?.endETDDate ? dayjs(item?.endETDDate) : null
						]}
						className="rankPicker_common"
						allowClear
					/>
				</FormItem>
				{/* <FormItem label="Tags:" type="row">
					<Select
						onChange={(val) => {
							onCommit?.({
								tags: val
							});
						}}
						allowClear={true}
						onClear={() => {
							onCommit?.({
								tags: null
							});
						}}
						mode="multiple"
						options={tagsOptions}
						value={item?.tags}
						className="select_common"
					/>
				</FormItem> */}
			</div>
			{/* <Button
				type="default"
				onClick={() => {
					onAdd?.("add");
				}}
			>
				Add New Voyage
			</Button> */}
		</div>
	);
};

export default LegFilter;
