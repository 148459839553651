import {
	Button,
	Dialog,
	Upload,
	Tabs,
	FormItem,
	FleetInput,
	FleetDatePicker,
	Icon
} from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { WaypointEditDialogProps } from "./type";
import { LatLongInput } from "@/common";
import dayjs, { type Dayjs } from "dayjs";
import { FleetUtcTimePicker } from "../../../../components";

const WaypointEditDialog: FC<WaypointEditDialogProps> = ({
	visible,
	waypointId,
	onClose,
	onFinish
}) => {
	const {
		loading,
		hanleCloseDialog,
		onConfirm,
		checkList,
		handleClickCheckBox,
		waypointDetail,
		handleChangeWaypoint
	} = useIndex(waypointId, onClose, onFinish);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={() => {
				hanleCloseDialog(onClose);
			}}
			loading={loading}
		>
			<div className={styles["waypointEditDialog"]}>
				<div className="fleet-header">
					<div className="waypointEditDialog-header">
						<div>Waypoint</div>
						<Icon
							type="icon-close2"
							className="close-btn"
							onClick={() => {
								hanleCloseDialog(onClose);
							}}
						/>
					</div>
				</div>
				<div className="waypointEditDialog-main">
					<div className="main-form">
						<div className="form-item">
							<FormItem
								containerClassname="password-item"
								label="Waypoint Name"
								type="row"
								// needVerity
							>
								<FleetInput
									className="commonInput"
									value={waypointDetail?.name}
									onChange={(val) => {
										handleChangeWaypoint("name", val as string);
									}}
								/>
							</FormItem>
						</div>
						<div className="form-item">
							<FormItem label="Latitude" type="row">
								<LatLongInput
									value={waypointDetail?.lat}
									type="latitude"
									onChange={(val) => {
										handleChangeWaypoint("lat", val);
									}}
								/>
							</FormItem>
						</div>
						<div className="form-item">
							<FormItem label="Longitude" type="row">
								<LatLongInput
									value={waypointDetail?.lon}
									type="longitude"
									onChange={(val) => {
										handleChangeWaypoint("lon", val);
									}}
								/>
							</FormItem>
						</div>
						<div className="form-item">
							<FormItem
								containerClassname="password-item"
								label="Speed (kt)"
								type="row"
							>
								<FleetInput
									className="commonInput"
									value={waypointDetail?.speed}
									onChange={(value: number) => {
										handleChangeWaypoint("speed", value);
									}}
								/>
							</FormItem>
						</div>
						<div className="form-checkBoxItem">
							<input
								type="checkbox"
								checked={checkList[0]}
								onChange={() => {
									handleClickCheckBox(0);
								}}
							/>
							<FormItem
								containerClassname="password-item"
								label="Stoppage (hr)"
								type="row"
							>
								<FleetInput
									className="commonInput"
									disabled={!checkList[0]}
									type="number"
									value={waypointDetail?.stopHours}
									onChange={(value: number) => {
										handleChangeWaypoint("stopHours", value);
									}}
								/>
							</FormItem>
						</div>
						<div className="form-checkBoxItem">
							<input
								type="checkbox"
								checked={checkList[1]}
								onChange={() => {
									handleClickCheckBox(1);
								}}
							/>
							<FormItem
								containerClassname="password-item"
								label="Resume Time"
								type="row"
							>
								<FleetUtcTimePicker
									// disabled={isDisabled}
									disabled={!checkList[1]}
									onChange={(value) => {
										handleChangeWaypoint(
											"resumeTime",
											new Date(value).getTime()
										);
									}}
									value={Number(waypointDetail?.resumeTime)}
									// allowClear
									// showTime={true}
									// showHour={true}
									// showMinute={true}
								/>
								{/* <FleetDatePicker
									// disabled={isDisabled}
									disabled={!checkList[1]}
									onChange={(value) => {
										handleChangeWaypoint(
											"resumeTime",
											new Date(value).getTime()
										);
									}}
									value={
										waypointDetail?.resumeTime
											? dayjs(waypointDetail?.resumeTime)
											: null
									}
									allowClear
									showTime={true}
									showHour={true}
									showMinute={true}
								/> */}
							</FormItem>
						</div>
					</div>
				</div>
				<div className="waypointEditDialog-footer">
					<Button
						type="secondary"
						onClick={() => {
							hanleCloseDialog(onClose);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							onConfirm();
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default WaypointEditDialog;
