import React, { createContext, useContext } from "react";
import {
	RouteWayTrackPointGeoJson,
	RouteWayTrackLineGeoJson,
	RouteDataCenterType
} from "./components";
import useRouteCenter from "./hooks/useRouteIndex";

export type LegProviderProps = {
	value?: {
		route?: RouteDataCenterType;
	};
	children?: React.ReactNode;
	mapActions?: {
		onWayTrackPointLoad: (items: RouteWayTrackPointGeoJson[]) => void;
		onWayTrackLineLoad: (items: RouteWayTrackLineGeoJson[]) => void;
	};
};

export const LegContext = createContext<LegProviderProps["value"]>({});

const useLegContext = () => {
	const context = useContext(LegContext);
	return context;
};

const LegProvider: React.FC<LegProviderProps> = ({
	value,
	children,
	mapActions
}) => {
	const {
		isUpdateing,
		activePlan,
		dataSource,
		activeEditPlanId,
		handleRoutePlanCrud,
		handleWayPointCrud,
		handleWayPointsReset,
		handlePlanChange,
		handleRouteRemove
	} = useRouteCenter(
		mapActions?.onWayTrackPointLoad,
		mapActions?.onWayTrackLineLoad
	);
	return (
		<LegContext.Provider
			value={{
				route: {
					isUpdateing,
					dataSource,
					activePlan,
					activeEditPlanId,
					handleRoutePlanCrud,
					handleWayPointCrud,
					handleWayPointsReset,
					handlePlanChange,
					handleRouteRemove
				}
			}}
		>
			{children}
		</LegContext.Provider>
	);
};

export { useLegContext, LegProvider };
