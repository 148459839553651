import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonToolbarListProps } from "./type";
import { Radio } from "@/components";
import { RouteCommonToolbarMap } from "./source";
import { RouteCommonToolbarMeteoSwitchMap } from "../../type";

const RouteCommonToolbarList: FC<RouteCommonToolbarListProps> = ({
	items,
	onSelect,
	selectControlls,
	selectMeteos
}) => {
	return (
		<ul className={style["routeCommon-toolbar-list"]}>
			{items?.map((item, index) => (
				<Radio
					key={index}
					label={RouteCommonToolbarMap[item]}
					className="toolbar-list-radio"
					onChange={(checked) => {
						onSelect?.(item);
					}}
					value={
						selectMeteos
							? selectMeteos[item] === RouteCommonToolbarMeteoSwitchMap.On
							: selectControlls?.includes(item)
					}
				/>
			))}
		</ul>
	);
};

export default RouteCommonToolbarList;
