import React, { useEffect, useRef } from "react";
import style from "./index.module.less";
import { VoyBasicInfo, VoyCpSpdTable, VoyGwClause } from "@/pages/voy/common";
import { Dialog, Button, Loading } from "@/components";
import useIndex from "./hooks/useIndex";
import { EditVoyProps } from "./type";
import { useReminder } from "@/hooks";

const EditVoy: React.FC<EditVoyProps> = (props) => {
	const { hide, onClose } = props;

	const {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,

		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,

		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,

		handleConfirmClick,
		loading
	} = useIndex(props);

	return (
		<>
			<Loading loading={loading} />
			<Dialog
				mode="fixed"
				hide={hide}
				closeable={true}
				drawer={true}
				onClose={onClose}
			>
				<div className={style["voy-edit-dialog"]}>
					<div className="voy-edit-header">
						<div>Edit Voy</div>
						<div className="close-btn" onClick={onClose}>
							×
						</div>
					</div>

					<div className="voy-edit-main">
						<VoyBasicInfo
							item={voyBasicInfoData}
							onCommit={handleBasicInfoItemCommit}
							onChange={handleChangeBasicInfoItem}
							disabledField={["vesselName", "companyName"]}
						/>
						<VoyCpSpdTable
							voyCpSpdTableData={voyCpSpdTableData}
							handleDoubleClick={handleDoubleClick}
							selectedRowKeys={selectedRowKeys}
							onSelectChange={onSelectChange}
							pager={pager}
							onPageChange={onPageChange}
							dataSource={dataSource}
							opereteColumns={opereteColumns}
							onChange={handleChangeVoyCpSpdTableItem}
							add={addVoyCpSpd}
						/>
						<VoyGwClause
							item={voyGwClauseData}
							onChange={handleChangeVoyGwClauseItem}
							onCommit={handleVoyGwClauseItemCommit}
						/>
					</div>

					<div className="voy-edit-footer">
						<Button type="secondary" size="large" onClick={onClose}>
							Cancel
						</Button>
						<Button size="large" onClick={handleConfirmClick}>
							Confirm
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default EditVoy;
