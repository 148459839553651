import React, { FC } from "react";
import { formatNumber } from "@/tools/amount";
import style from "./index.module.less";
import { DistanceTableProps } from "./type";

const DistanceTable: React.FC<DistanceTableProps> = ({ data }) => {
	return (
		<div className={style["distanceTable"]}>
			<table>
				<thead>
					<tr>
						<th className="first-col"></th>
						<th>Actual Route</th>
						<th>Recommended Route</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="first-col">Distance (nm)</td>
						<td>
							{data.actualRouteDistance || data.actualRouteDistance === 0
								? formatNumber(data.actualRouteDistance, 1)
								: "---"}
						</td>
						<td>
							{data.recommendedRouteDistance ||
							data.recommendedRouteDistance === 0
								? formatNumber(data.recommendedRouteDistance, 1)
								: "---"}
						</td>
					</tr>
					<tr>
						<td className="first-col">Extra Distance (nm)</td>
						<td colSpan={2} className="hot-text">
							{data.extraDistance || data.extraDistance === 0
								? formatNumber(data.extraDistance, 1)
								: "---"}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default DistanceTable;
