import React from "react";
import style from "./index.module.less";
import { CommonLayoutProps } from "./type";
import { CommonSiderMenu } from "./components";
import classnames from "classnames";

const CommonLayout: React.FC<CommonLayoutProps> = ({
	className,
	items,
	children,
	isCarryParameter
}) => {
	return (
		<div className={classnames(style["common-layout"], className)}>
			<CommonSiderMenu items={items} isCarryParameter={isCarryParameter} />
			<div className="common-layout-container">{children}</div>
		</div>
	);
};

export default CommonLayout;
