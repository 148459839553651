import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { Filter } from "./components";
import useIndex from "./hooks/useIndex";
import { Loading, Table } from "@/components";
import { columns } from "./source";

const LegList: FC<{}> = (props) => {
	const {
		loading,
		filter,
		handleFilterCommit,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		onSearch,
		opereteColumns,
		handleRowDblClick
	} = useIndex();

	return (
		<div className={classnames("voy-common", style["leg-list"])}>
			<Loading loading={loading} />
			<Filter
				item={filter}
				onCommit={handleFilterCommit}
				// onAdd={handleRowDblClick}
				onSearch={onSearch}
			/>
			<Table
				className="leg-list-container"
				columns={columns}
				id="id"
				dataSource={dataSource}
				// rowSelection={{
				// 	selectedRowKeys,
				// 	onChange: onSelectChange
				// }}
				onRow={(item) => {
					return {
						onDoubleClick: () => {
							handleRowDblClick(item);
						}
					};
				}}
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
			/>
		</div>
	);
};

export default LegList;
