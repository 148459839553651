import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { LegIdType } from "@/api/leg/type";
import {
	RouteInfoType,
	SimulationSelectedsType,
	RouteDistanceItemType
} from "../type";
import {
	WaypointType,
	CustomTableRefType
} from "../components/customTable/type";
import { RouteWayPointItemType } from "../../../components";

const useIndex = (activeTab, focusWaypoint) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const location = useLocation();

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	// const customTableRef = useRef<CustomTableRefType>(null);
	const customTableRefs = useRef<Record<string, CustomTableRefType>>({});

	const [simulationSelecedInfo, setSimulationSelecedInfo] =
		useState<SimulationSelectedsType>();
	const [loading, setLoading] = useState(false);
	const [addVisible, setAddVisible] = useState<boolean>(false);
	const [routeList, setRouteList] = useState<RouteInfoType[]>([]);
	// 激活的routeID
	const [activeRouteId, setActiveRouteId] = useState<string>(null);
	// 更新激活的routeID
	const updateActiveRouteId = (id: string) => {
		setActiveRouteId(id);
	};

	console.log("看看routeList数据", routeList);

	const [activeWaypointRowId, setActiveWaypointRowId] = useState<string>();
	const initPageData = () => {
		getRouteInfo({ legId: id });
	};
	const handleCloseNewRouteDialog = () => {
		setAddVisible(false);
	};
	const handleOpenNewRouteDialog = () => {
		setAddVisible(true);
	};
	const handleFocusWaypoint = (waypointItem: WaypointType) => {
		const targetElement = document.getElementById(waypointItem?.id);
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
		}
		setActiveWaypointRowId(waypointItem?.id);
	};
	const handleClickWaypoint = (waypointItem: RouteWayPointItemType) => {
		setActiveWaypointRowId(waypointItem?.id);
		focusWaypoint?.(waypointItem);
	};

	// API
	// API - getRoute
	const getRouteInfoFront = () => {
		setLoading(true);
	};
	const getRouteInfoSuccess = (data) => {
		console.log("11111", data);
		setRouteList(data?.data || []);
		setLoading(false);
	};
	const getRouteInfoFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getRouteInfo = (params?: LegIdType) => {
		onRequest(
			"getRouteInfoApi",
			params,
			getRouteInfoFront,
			getRouteInfoSuccess,
			getRouteInfoFailed,
			dispatch,
			navigate
		);
	};

	const handleDistanceUpdate = (item: RouteDistanceItemType) => {
		console.log("看一眼数据", item);
		customTableRefs.current[activeRouteId].updatePlanDistance(item);
	};

	// DidMount
	useEffect(() => {
		if (activeTab !== "route") {
			return;
		}
		initPageData();
	}, [activeTab]);

	return {
		addVisible,
		routeList,
		loading,
		simulationSelecedInfo,
		activeWaypointRowId,
		setSimulationSelecedInfo,
		handleCloseNewRouteDialog,
		handleOpenNewRouteDialog,
		initPageData,
		handleFocusWaypoint,
		handleClickWaypoint,
		customTableRefs,
		handleDistanceUpdate,
		updateActiveRouteId
	};
};

export default useIndex;
