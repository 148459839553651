import { ColumnsType } from "antd/es/table";
import { VoyCpSpdDataType, VoyCpSpdTableItemType } from "./type";
import { CommonOptionType } from "@/types/common";
import { CommonInitItemEvent } from "@/types/event";

// export const voyBasicInfoTableColumn: ColumnsType<VoyCpSpdDataType> = [
// 	{
// 		title: "B&L",
// 		dataIndex: "loadCondition",
// 		key: "loadCondition",
// 		width: "120",
// 		fixed: "left",
// 		align: "center",
// 		ellipsis: true
// 	},
// 	{
// 		title: "Category",
// 		dataIndex: "speedCategory",
// 		key: "speedCategory",
// 		width: "120",
// 		align: "center",
// 		ellipsis: true
// 	},

// 	{
// 		title: "SPD(kt)",
// 		dataIndex: "speed",
// 		key: "speed",
// 		width: "120",
// 		align: "center",
// 		ellipsis: true
// 	},
// 	{
// 		title: "FO(mt)",
// 		dataIndex: "foConsumption",
// 		key: "foConsumption",
// 		width: "120",
// 		align: "center",
// 		ellipsis: true
// 	},
// 	{
// 		title: "DGO(mt)",
// 		dataIndex: "dgoConsumption",
// 		key: "dgoConsumption",
// 		width: "120",
// 		align: "center",
// 		ellipsis: true
// 	}
// ];

export const swellWaveAppliedOptions: CommonOptionType<
	string,
	VoyCpSpdDataType["loadCondition"]
>[] = [
	{ label: "Not Applied", value: 0 },
	{ label: "Applied", value: 1 }
];

export const initialCpSpd: CommonInitItemEvent<VoyCpSpdTableItemType> = (
	item
) => {
	return {
		loadCondition: item?.loadCondition ?? "B",
		speedCategory: item?.speedCategory ?? "ECO",
		speed: item?.speed ?? 0,
		foConsumption: item?.foConsumption ?? 0,
		dgoConsumption: item?.dgoConsumption ?? 0
	};
};

export const loadConditionOptions: CommonOptionType<
	VoyCpSpdTableItemType["loadCondition"],
	VoyCpSpdTableItemType["loadCondition"]
>[] = [
	{ label: "B", value: "B" },
	{ label: "L", value: "L" }
];

export const categoryOptions: CommonOptionType<
	VoyCpSpdTableItemType["speedCategory"],
	VoyCpSpdTableItemType["speedCategory"]
>[] = [
	{ label: "ECO", value: "ECO" },
	{ label: "FULL", value: "FULL" },
	{ label: "OTHER", value: "OTHER" }
];
