import React, { FC } from "react";
import style from "./index.module.less";
import { ReportBannerProps } from "./type";

const ReportBanner: FC<ReportBannerProps> = ({ icon, title }) => {
	return (
		<div className={style["banner"]}>
			<span className="icon">{icon}</span>
			<span className="title">{title}</span>
		</div>
	);
};

export default ReportBanner;
