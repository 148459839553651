import { Button, Dialog, Upload, Tabs, Icon } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { PlanDownloadDialogProps } from "./type";
import classNames from "classnames";
const PlanDownLoadDialog: FC<PlanDownloadDialogProps> = ({
	visible,
	onClose,
	onFinish,
	planId
}) => {
	const {
		loading,
		hanleCloseDialog,
		onConfirm,
		downloadType,
		handleChangeTypeRadio
	} = useIndex(onClose, onFinish, planId);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={() => {
				hanleCloseDialog(onClose);
			}}
			loading={loading}
		>
			<div className={styles["planDownloadDialog"]}>
				<div className="fleet-header">
					<div className="planDownloadDialog-header">
						<div>Download</div>
						<Icon
							type="icon-close2"
							className="close-btn"
							onClick={() => {
								hanleCloseDialog(onClose);
							}}
						/>
					</div>
				</div>
				<div className="planDownloadDialog-main">
					<div className="main-templateList">
						<div className="templateList-template">
							<input
								type="radio"
								name="type"
								value="A"
								checked={downloadType === "A"}
								onChange={(e) => {
									handleChangeTypeRadio("A");
								}}
							/>
							<div className="templateList-template-info">
								<span>01°15.854'N</span>
								<span>103°57.854'E</span>
							</div>
						</div>
						<div className="templateList-template">
							<input
								type="radio"
								name="type"
								value="B"
								checked={downloadType === "B"}
								onChange={(e) => {
									handleChangeTypeRadio("B");
								}}
							/>
							<div className="templateList-template-info">
								<span>01</span>
								<span>15</span>
								<span>854</span>
								<span>N</span>
								<span>57</span>
								<span>854</span>
								<span>E</span>
							</div>
						</div>
					</div>
				</div>
				<div className="planDownloadDialog-footer">
					<Button
						type="secondary"
						onClick={() => {
							hanleCloseDialog(onClose);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							onConfirm();
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default PlanDownLoadDialog;
