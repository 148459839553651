import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import debounce from "lodash/debounce";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	getLegBasicDetailParamsType,
	deleteLegParamsType,
	LegIdType,
	CommonIdType
} from "@/api/leg/type";
import { AddLegPortParameterType } from "@/api/leg/type";
import { SimulationForecastListType } from "../type";
import { SimulationSelectedInfoType } from "../../../type";
import { RoutePosition } from "@/tools/latLong";
import { RouteCommonMapProps } from "@/pages/leg/business/details/components/map";

const useIndex = (simulationInfo: SimulationSelectedInfoType) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const location = useLocation();
	console.log("simulationInfo", simulationInfo);
	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");
	const legType = queryParams.get("legType");

	const [loading, setLoading] = useState(false);
	const [simulationForecastList, setSimulationForecastList] =
		useState<SimulationForecastListType>([]);
	const [graphDialogVisible, setGraphDialogVisible] = useState<boolean>(false);
	const [cpaDialogVisible, setCpaDialogVisible] = useState<boolean>(false);
	const [typhoonRoute, setTyphoonRoute] = useState<RoutePosition[]>([]);
	const [typhoonId, setTyphoonId] = useState<string>("");
	const [scrollList, setScrollList] = useState<SimulationForecastListType>([]);
	const [height, setHeight] = useState(500);
	const startYRef = useRef(0);
	const startHeightRef = useRef(0);

	const handleMouseDown = (e: React.MouseEvent) => {
		startYRef.current = e.clientY;
		startHeightRef.current = height;
		document.onselectstart = () => false;
		document.ondragstart = () => false;
		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
	};

	const handleMouseMove = (e: MouseEvent) => {
		const diff = startYRef.current - e.clientY;
		startHeightRef.current + diff <= 890 &&
			setHeight(startHeightRef.current + diff);
	};

	const handleMouseUp = () => {
		document.removeEventListener("mousemove", handleMouseMove);
		document.removeEventListener("mouseup", handleMouseUp);
		document.onselectstart = null;
		document.ondragstart = null;
	};

	const handleScroll = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const total = simulationForecastList?.length;
		const currentLength = scrollList.length;
		if (
			scrollTop + clientHeight >= scrollHeight - 80 &&
			currentLength < total
		) {
			const newAisList = [
				...scrollList,
				...simulationForecastList?.slice(currentLength, currentLength + 50)
			];
			setScrollList(newAisList);
		}
	};

	const handleOpenGraphDialog = () => {
		setGraphDialogVisible(true);
	};
	const handleCloseGraphDialog = () => {
		setGraphDialogVisible(false);
	};

	const generationTyphoonRoute: RouteCommonMapProps["onTyhoonSelect"] = (
		tyhoonId,
		forcast
	) => {
		const typhoonRoute = forcast.map((item) => {
			return [item.ts, item.lat, item.lon] as RoutePosition;
		});
		setTyphoonId(tyhoonId);
		setTyphoonRoute(typhoonRoute);
		console.log("typhoonRoute", typhoonRoute);
	};

	const handleOpenCpaDialog = () => {
		if (!typhoonId) {
			return;
		}
		setCpaDialogVisible(true);
	};
	const handleCloseCpaDialog = () => {
		setCpaDialogVisible(false);
	};

	// API
	// Api - getSimulationForecast
	const getSimulationForecastFront = () => {
		setLoading(true);
	};
	const getSimulationForecastSuccess = (data) => {
		setSimulationForecastList(data?.data || []);
		setScrollList(data?.data?.slice(0, 50) || []);
		setLoading(false);
	};
	const getSimulationForecastFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getSimulationForecast = (params: CommonIdType) => {
		onRequest(
			"getSimulationForecastApi",
			params,
			getSimulationForecastFront,
			getSimulationForecastSuccess,
			getSimulationForecastFailed,
			dispatch,
			navigate
		);
	};

	// DidMount
	useEffect(() => {
		simulationInfo?.id && getSimulationForecast({ id: simulationInfo?.id });
	}, [simulationInfo]);
	return {
		graphDialogVisible,
		simulationForecastList,
		cpaDialogVisible,
		typhoonId,
		typhoonRoute,
		height,
		scrollList,
		handleMouseDown,
		handleOpenGraphDialog,
		handleCloseGraphDialog,
		handleOpenCpaDialog,
		handleCloseCpaDialog,
		generationTyphoonRoute,
		handleScroll
	};
};

export default useIndex;
