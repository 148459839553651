import { FuelTableDataType } from "../../common/noonFuelForm";
import { NoonMainInfoFormType } from "../../common/noonMainInfoForm/type";
import { SpSiFormType, SpBasicDataType } from "./type";

export const initialSpFuelData: FuelTableDataType = [
	{
		id: 1,
		mt: "Stop BROB",
		isNecessary: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 2,
		mt: "Resume BROB",
		isNecessary: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 3,
		mt: "Total Cons Since Stoppage",
		isNecessary: false,
		disabled: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	}
];

export const initialSpMiData: NoonMainInfoFormType = {
	vesselName: "",
	imo: "",
	callSign: ""
};

export const initialSpSiData: SpSiFormType = {
	stopLatitude: 0,
	stopLongitude: 0,
	stopDate: "",
	distSlrAtStop: "",
	resumeLatitude: 0,
	resumeLongitude: 0,
	resumeDate: "",
	distRemainAtResume: "",
	stopReason: "",
	stoppageTime: ""
};

export const initialSpBasicData: SpBasicDataType = {
	id: null
};
