import React, { FC } from "react";
import style from "./index.module.less";
import { FleetInput, FleetTimePicker } from "@/components";
import { LatLongInput } from "@/common";
import { NoonMainInfoForm, NoonLabel, NoonFuelForm } from "../../common";
import { SpSiFormType, NoonSpRFProps } from "./type";
import dayjs from "dayjs";
import { FleetUtcTimePicker } from "../../../../components";
import { formatNumber } from "@/tools/amount";

const NoonSpRF: FC<NoonSpRFProps> = (props) => {
	const { spData, onSpMiCommit, onSpSiCommit, onSpFuelCommit } = props;
	const { miData, siData, fuelData } = spData;

	return (
		<div className={style["noon-sp-rf"]}>
			<NoonMainInfoForm
				disabled={true}
				data={miData}
				onChange={(data) => {
					onSpMiCommit(data);
				}}
			/>
			<div className="noon-si-form-container">
				<p className="noon-si-title">Stop Information</p>
				<div className="noon-si-form">
					<div className="si-form-row">
						<NoonLabel
							className="custom-label-content"
							title="Stop Latitude"
							isNecessary
						>
							<LatLongInput
								className="sp-input"
								value={siData.stopLatitude}
								type="latitude"
								onChange={(val: SpSiFormType["stopLatitude"]) => {
									onSpSiCommit({ ...siData, stopLatitude: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel
							className="custom-label-content"
							title="Stop Longitude"
							isNecessary
						>
							<LatLongInput
								className="sp-input"
								value={siData.stopLongitude}
								type="longitude"
								onChange={(val: SpSiFormType["stopLongitude"]) => {
									onSpSiCommit({ ...siData, stopLongitude: val });
								}}
							/>
						</NoonLabel>
					</div>
					<div className="si-form-row">
						<NoonLabel title="Stop Date" isNecessary>
							<FleetUtcTimePicker
								className="sp-input"
								value={Number(siData.stopDate)}
								onChange={(val) => {
									onSpSiCommit({ ...siData, stopDate: val });
									// 计算StoppageTime
									const sdate = dayjs(val);
									const rdate = dayjs(siData.resumeDate);
									const millisecondsDiff = rdate.diff(sdate);
									const hoursDiff = millisecondsDiff / (1000 * 60 * 60);
									if (!Number.isNaN(hoursDiff)) {
										onSpSiCommit({
											...siData,
											stoppageTime: formatNumber(hoursDiff, 2)
										});
									}
								}}
							/>
							<span className="sp-init">UTC</span>
						</NoonLabel>
						<NoonLabel
							className="custom-label-title"
							title="Dist SLR at Stop"
							isNecessary
						>
							<FleetInput
								className="sp-input"
								value={siData.distSlrAtStop}
								onChange={(val: SpSiFormType["distSlrAtStop"]) => {
									onSpSiCommit({ ...siData, distSlrAtStop: val });
								}}
							/>
							<span className="sp-init">NM</span>
						</NoonLabel>
					</div>

					<div className="si-form-row">
						<NoonLabel
							className="custom-label-title custom-label-content"
							title="Resume Latitude"
							isNecessary
						>
							<LatLongInput
								className="sp-input"
								value={siData.resumeLatitude}
								type="latitude"
								onChange={(val: SpSiFormType["resumeLatitude"]) => {
									onSpSiCommit({ ...siData, resumeLatitude: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel
							className="custom-label-title custom-label-content"
							title="Resume Longitude"
							isNecessary
						>
							<LatLongInput
								className="sp-input"
								value={siData.resumeLongitude}
								type="longitude"
								onChange={(val: SpSiFormType["resumeLongitude"]) => {
									onSpSiCommit({ ...siData, resumeLongitude: val });
								}}
							/>
						</NoonLabel>
					</div>

					<div className="si-form-row">
						<NoonLabel title="Resume Date" isNecessary>
							<FleetUtcTimePicker
								className="sp-input"
								value={Number(siData.resumeDate)}
								onChange={(val) => {
									onSpSiCommit({
										...siData,
										resumeDate: val
									});
									// 计算StoppageTime
									const sdate = dayjs(siData.stopDate);
									const rdate = dayjs(val);
									const millisecondsDiff = rdate.diff(sdate);
									const hoursDiff = millisecondsDiff / (1000 * 60 * 60);
									if (!Number.isNaN(hoursDiff)) {
										onSpSiCommit({
											...siData,
											resumeDate: val,
											stoppageTime: formatNumber(hoursDiff, 2)
										});
									}
								}}
							/>
							<span className="sp-init">UTC</span>
						</NoonLabel>
						<NoonLabel
							className="custom-label-title"
							title="Dist remain at Resume"
							isNecessary
						>
							<FleetInput
								className="sp-input"
								value={siData.distRemainAtResume}
								onChange={(val: SpSiFormType["distRemainAtResume"]) => {
									onSpSiCommit({ ...siData, distRemainAtResume: val });
								}}
							/>
							<span className="sp-init">NM</span>
						</NoonLabel>
					</div>

					<div className="si-form-row">
						<NoonLabel title="Stop Reason" isNecessary={false}>
							<FleetInput
								className="sp-input"
								value={siData.stopReason}
								onChange={(val: SpSiFormType["stopReason"]) => {
									onSpSiCommit({ ...siData, stopReason: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Stoppage Time" isNecessary>
							<FleetInput
								className="sp-input"
								disabled
								value={siData.stoppageTime}
								onChange={(val: SpSiFormType["stoppageTime"]) => {
									onSpSiCommit({ ...siData, stoppageTime: val });
								}}
							/>
							<span className="sp-init">HRS</span>
						</NoonLabel>
					</div>
				</div>
			</div>

			<NoonFuelForm
				dataSource={fuelData}
				onCommit={(index, item) => {
					onSpFuelCommit(index, item);
					if (index === 0 || index === 1) {
						const totalConsSlr = {
							...fuelData[2],
							hfo: formatNumber(
								Number(fuelData[0].hfo) - Number(fuelData[1].hfo),
								3
							),
							lsfo: formatNumber(
								Number(fuelData[0].lsfo) - Number(fuelData[1].lsfo),
								3
							),
							mdo: formatNumber(
								Number(fuelData[0].mdo) - Number(fuelData[1].mdo),
								3
							),
							mgo: formatNumber(
								Number(fuelData[0].mgo) - Number(fuelData[1].mgo),
								3
							)
						};
						onSpFuelCommit(2, totalConsSlr);
					}
				}}
			/>
		</div>
	);
};

export default NoonSpRF;
