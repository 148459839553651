import React, { FC } from "react";
import { Icon } from "@/components";
import { formatNumber } from "@/tools/amount";
import style from "./index.module.less";
import { PhaseCardProps } from "./type";

const PhaseCard: FC<PhaseCardProps> = (props) => {
	const { type, value, gw } = props;
	const getTitle = () => {
		let title = "";
		switch (type) {
			case "Time":
				if (gw) {
					if (value > 0) {
						title = "Time Gained:";
					} else if (value < 0) {
						title = "Time Lost:";
					} else {
						title = "Time Lost/Gained:";
					}
				} else {
					title = "Time Lost/Gained:";
				}
				break;
			case "FO Over":
				if (gw) {
					if (value > 0) {
						title = "FO Over:";
					} else if (value < 0) {
						title = "FO Under:";
					} else {
						title = "FO Over/Under:";
					}
				} else {
					title = "FO Over/Under:";
				}
				break;
			case "DO/GO":
				if (gw) {
					if (value > 0) {
						title = "DO/GO Over:";
					} else if (value < 0) {
						title = "DO/GO Under:";
					} else {
						title = "DO/GO Over/Under:";
					}
				} else {
					title = "DO/GO Over/Under:";
				}
				break;
		}
		return title;
	};

	const getContent = () => {
		// let phaseIcon = <Icon type="icon-rise" color="green" />;
		// let phaseValue = 9.3;
		// let phaseUnit = "mt";
		let phaseIcon = null;
		let phaseValue = null;
		let phaseUnit = "";
		switch (type) {
			case "Time":
				if (gw) {
					if (value > 0) {
						phaseIcon = <Icon type="icon-rise" color="green" />;
						// phaseValue = Number(value).toFixed(2);
						phaseValue = formatNumber(value, 2);
						phaseUnit = "hrs";
					} else if (value < 0) {
						phaseIcon = <Icon type="icon-decline" color="red" />;
						// phaseValue = Number(Math.abs(value)).toFixed(2);
						phaseValue = formatNumber(Math.abs(value), 2);
						phaseUnit = "hrs";
					} else {
					}
				} else {
					phaseValue = "No Good Wx Day";
				}
				break;
			case "FO Over":
				if (gw) {
					if (value > 0) {
						phaseIcon = <Icon type="icon-rise" color="green" />;
						// phaseValue = Number(value).toFixed(3);
						phaseValue = formatNumber(value, 3);
						phaseUnit = "mt";
					} else if (value < 0) {
						phaseIcon = <Icon type="icon-decline" color="red" />;
						// phaseValue = Number(Math.abs(value)).toFixed(3);
						phaseValue = formatNumber(Math.abs(value), 3);
						phaseUnit = "mt";
					} else {
					}
				} else {
					phaseValue = "No Good Wx Day";
				}
				break;
			case "DO/GO":
				if (gw) {
					if (value > 0) {
						phaseIcon = <Icon type="icon-rise" color="green" />;
						// phaseValue = Number(value).toFixed(3);
						phaseValue = formatNumber(value, 3);
						phaseUnit = "mt";
					} else if (value < 0) {
						phaseIcon = <Icon type="icon-decline" color="red" />;
						// phaseValue = Number(Math.abs(value)).toFixed(3);
						phaseValue = formatNumber(Math.abs(value), 3);
						phaseUnit = "mt";
					} else {
					}
				} else {
					phaseValue = "No Good Wx Day";
				}
				break;
		}
		return (
			<>
				<div className={"phase-icon"}>{phaseIcon}</div>
				<div className={"phase-value"}>{phaseValue}</div>
				<div className={"phase-unit"}>{phaseUnit}</div>
			</>
		);
	};

	const getFooter = () => {
		// let footerIcon = <Icon type="icon-a-fuelmanagement" color="green" />;
		// let footerContent = "FO Over";
		let footerIcon = null;
		let footerContent = "";
		switch (type) {
			case "Time":
				if (gw) {
					if (value > 0) {
						footerIcon = <Icon type="icon-alarm" color="green" />;
						footerContent = "Time Gained";
					} else if (value < 0) {
						footerIcon = <Icon type="icon-alarm" color="red" />;
						footerContent = "Time Lost";
					} else {
						footerIcon = <Icon type="icon-alarm" />;
						footerContent = "No Time Lost/Gained";
					}
				} else {
					footerIcon = <Icon type="icon-alarm" />;
					footerContent = "Not Available";
				}
				break;
			case "FO Over":
				if (gw) {
					if (value > 0) {
						footerIcon = <Icon type="icon-a-fuelmanagement" color="green" />;
						footerContent = "FO Over";
					} else if (value < 0) {
						footerIcon = <Icon type="icon-a-fuelmanagement" color="red" />;
						footerContent = "FO Under";
					} else {
						footerIcon = <Icon type="icon-a-fuelmanagement" />;
						footerContent = "No FO Over/Under";
					}
				} else {
					footerIcon = <Icon type="icon-a-fuelmanagement" />;
					footerContent = "Not Available";
				}
				break;
			case "DO/GO":
				if (gw) {
					if (value > 0) {
						footerIcon = <Icon type="icon-a-fuelmanagement" color="green" />;
						footerContent = "DO/GO Over";
					} else if (value < 0) {
						footerIcon = <Icon type="icon-a-fuelmanagement" color="red" />;
						footerContent = "DO/GO Under";
					} else {
						footerIcon = <Icon type="icon-a-fuelmanagement" />;
						footerContent = "No DO/GO Over/Under";
					}
				} else {
					footerIcon = <Icon type="icon-a-fuelmanagement" />;
					footerContent = "Not Available";
				}
				break;
		}
		return (
			<>
				{footerIcon}
				<div>{footerContent}</div>
			</>
		);
	};

	return (
		<div className={style["phase-card"]}>
			<div className="phase-card-header">{getTitle()}</div>
			<div className="phase-card-main">{getContent()}</div>
			<div className="phase-card-footer">{getFooter()}</div>
		</div>
	);
};

export default PhaseCard;
