import dayjs from "dayjs";
import { convertDdToDm } from "@/tools/latLong";

export const detailTablecolumns = [
	{
		title: "NO",
		dataIndex: "seq",
		key: "no",
		width: "50px"
	},
	{
		title: "Time",
		dataIndex: "time",
		key: "time",
		render: (value) => {
			return dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm");
		}
	},
	{
		title: "Position",
		dataIndex: "lat",
		key: "position",
		width: "180px",
		render: (value, row) => {
			if (!row?.lat || !row?.lon) {
				return "-";
			}
			const {
				degree: latDegree,
				minute: latMinute,
				unit: latUnit
			} = convertDdToDm(row?.lat, true);
			const {
				degree: lonDegree,
				minute: lonMinute,
				unit: lonUnit
			} = convertDdToDm(row?.lon, false);
			const latStr = `${latDegree}°${latMinute}${latUnit}`;
			const lonStr = `${lonDegree}°${lonMinute}${lonUnit}`;
			return `${latStr} / ${lonStr}`;
		}
	},
	{
		title: "Distance(nm)",
		dataIndex: "distance",
		key: "distance"
		// width: "110px"
	},
	{
		title: "Speed(kts)",
		dataIndex: "speed",
		key: "speed"
		// width: "90px"
	},
	{
		title: "Wind(kts)",
		dataIndex: "ws",
		key: "ws",
		// width: "90px",
		render: (value) => {
			if (typeof value === "number" && value % 1 !== 0) {
				return value.toFixed(1);
			} else {
				return value;
			}
		}
	},
	{
		title: "Swell(m)",
		dataIndex: "swellHeight",
		key: "swellHeight"
		// width: "90px"
	},
	{
		title: "Sig Wave(m)",
		dataIndex: "sigWaveHeight",
		key: "sigWaveHeight"
		// width: "90px"
	},
	{
		title: "CF(kts)",
		dataIndex: "cf",
		key: "cf",
		// width: "130px"
		render: (value) => {
			return Number(value).toFixed(2);
		}
	},
	{
		title: "WF(kts)",
		dataIndex: "wf",
		key: "wf",
		// width: "130px"
		render: (value) => {
			return Number(value).toFixed(2);
		}
	}
];
