import React, { FC } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { LegDetailsMenu, RouteCommonMap } from "./components";
import useIndex from "./hooks/useIndex";
import {
	LegDetailsBasic,
	LegDetailsRoute,
	LegDetailsRouteAIS,
	LegDetailsNoon,
	LegDetailsPlan,
	Report
} from "./business";
import { LegProvider } from "./store";
import { SimulationForecastTable } from "./business/route/components/simulationForecastTable";
import useForecastIndex from "./hooks/useForecastIndex";
import { DetailReportList } from "../details/business/noon/components";
import useNoonIndex from "./hooks/useNoonIndex";

const LegDetails: FC<{}> = (props) => {
	const {
		containerRef,
		activeTab,
		handleTabSelect,
		handleDragDown,
		mapRef,
		routeRef
	} = useIndex();

	const {
		forecastVisible,
		forecastItem,
		forecastRef,
		setForecastItem,
		handleOpenForecast,
		handleCloseForecast
	} = useForecastIndex();

	const {
		noonRef,
		noonListVisible,
		handleNoonListClose,
		handleNoonListOpen,
		basicReportTableData,
		detailsReportTableData,
		updateDataReportList,
		handleChangeReportTableItem,
		isCopyReport,
		handleIsCopyChange,
		detailReportData,
		handleDrIndexChange,
		visibleAddReportDialog,
		handleArDialogVisibleChange,
		noorReportsVisible,
		handleNoorReportsVisibleChange
	} = useNoonIndex(
		mapRef.current?.loadNoonReports,
		mapRef.current?.onNoorReportsVisible
	);

	const routeCommonMapSlotRender = () => {
		switch (true) {
			case activeTab === "noon":
				return (
					<DetailReportList
						noonListVisible={noonListVisible}
						handleNoonListClose={handleNoonListClose}
						basicReportTableData={basicReportTableData}
						detailsReportTableData={detailsReportTableData}
						updateDataReportList={updateDataReportList}
						handleChangeReportTableItem={handleChangeReportTableItem}
						isCopyReport={isCopyReport}
						handleIsCopyChange={handleIsCopyChange}
						detailReportData={detailReportData}
						handleDrIndexChange={handleDrIndexChange}
						visibleAddReportDialog={visibleAddReportDialog}
						handleArDialogVisibleChange={handleArDialogVisibleChange}
					/>
				);
			case activeTab === "route":
				return (
					<SimulationForecastTable
						visible={forecastVisible}
						onClose={handleCloseForecast}
						simulationInfo={forecastItem}
						ref={forecastRef}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<LegProvider
			mapActions={{
				onWayTrackLineLoad: (items) => {
					mapRef?.current?.loadWayTrackLine?.(items);
				},
				onWayTrackPointLoad: (items) => {
					mapRef?.current?.loadWayTrackPoint?.(items);
				}
			}}
		>
			<div className={classNames("voy-common", style["leg-details"])}>
				<LegDetailsMenu activeTab={activeTab} onSelect={handleTabSelect} />
				<div className="leg-details-container" ref={containerRef}>
					<div className="leg-list-container">
						<LegDetailsNoon
							ref={noonRef}
							activeTab={activeTab}
							handleNoonListOpen={handleNoonListOpen}
							basicReportTableData={basicReportTableData}
							detailsReportTableData={detailsReportTableData}
							updateDataReportList={updateDataReportList}
							handleChangeReportTableItem={handleChangeReportTableItem}
							isCopyReport={isCopyReport}
							handleIsCopyChange={handleIsCopyChange}
							detailReportData={detailReportData}
							handleDrIndexChange={handleDrIndexChange}
							visibleAddReportDialog={visibleAddReportDialog}
							handleArDialogVisibleChange={handleArDialogVisibleChange}
							noorReportsVisible={noorReportsVisible}
							handleNoorReportsVisibleChange={handleNoorReportsVisibleChange}
						/>
						<LegDetailsPlan activeTab={activeTab} />
						<LegDetailsBasic
							activeTab={activeTab}
							onLoadBasicPorts={(ports) => {
								mapRef?.current?.loadBasicPorts(ports);
							}}
						/>
						<LegDetailsRoute
							activeTab={activeTab}
							ref={routeRef}
							onWayPointFocus={(item) => {
								mapRef?.current?.focusWayTrackPoint("fromList", item);
							}}
							onSelectForecast={(item) => {
								setForecastItem(item);
							}}
							onOpenForecast={handleOpenForecast}
							onCloseForecast={handleCloseForecast}
						/>
						<LegDetailsRouteAIS
							activeTab={activeTab}
							onLoadAisTracks={(item) => {
								mapRef?.current?.loadAisTracks(item);
							}}
							onFocus={(item) => {
								mapRef?.current?.flyTo(item);
							}}
						/>
						<Report activeTab={activeTab} />
					</div>
					<span
						className="leg-details-dragBar"
						onMouseDown={handleDragDown}
					></span>
					<div className="leg-map-container">
						<RouteCommonMap
							ref={mapRef}
							onWayPointFocus={(item) => {
								routeRef?.current?.scrollToWayPoint(item);
							}}
							onTyhoonSelect={(tyhoonId, forcast) => {
								forecastRef.current?.generationTyphoonRoute(tyhoonId, forcast);
							}}
							children={routeCommonMapSlotRender()}
							onResetDistance={(params) => {
								routeRef?.current?.moveWayPointAfter(params);
							}}
						/>
					</div>
				</div>
			</div>
		</LegProvider>
	);
};

export default LegDetails;
