import { normalizeLongitude } from "@/pages/leg/business/details/components";
import { RouteWayPointItemType } from "./type";
import { greatCircle } from "@turf/turf";

export type RouteWayTrackLineGeoJson<
	GeoType extends GeoJSON.LineString | GeoJSON.Point | GeoJSON.MultiLineString
> = GeoJSON.Feature<GeoType, Partial<RouteWayPointItemType>>;

const generateWayPoints = (
	items: RouteWayPointItemType[]
): RouteWayTrackLineGeoJson<GeoJSON.Point>[] => {
	return items?.map((pointItem) => {
		return {
			type: "Feature",
			geometry: {
				type: "Point",
				coordinates: [normalizeLongitude(pointItem?.lon), pointItem?.lat]
			},
			properties: {
				...pointItem
			}
		};
	});
};

const generateWayLineString = (
	items: RouteWayPointItemType[]
): RouteWayTrackLineGeoJson<GeoJSON.LineString>[] => {
	const trackLines: RouteWayTrackLineGeoJson<GeoJSON.LineString>[] = [];
	let coordinates: GeoJSON.Position[] = [],
		prevCoordinate: GeoJSON.Position = null;

	for (let index = 0; index <= items?.length - 1; index++) {
		const currentItem = items?.[index];
		switch (true) {
			case index === 0:
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				break;
			case currentItem?.nav === "GC":
				let tempCoordinates = greatCircle(
					prevCoordinate,
					[normalizeLongitude(currentItem?.lon), currentItem?.lat],
					{
						npoints: 30
					}
				)?.geometry?.coordinates;
				const isAcross180 = tempCoordinates?.every(
					(selectItem) => selectItem?.length > 2
				);
				if (isAcross180) {
					tempCoordinates = (
						tempCoordinates as unknown as GeoJSON.Position[][]
					)?.flat();
				}
				coordinates = (tempCoordinates as unknown as GeoJSON.Position[])?.map(
					(coordinate) => [normalizeLongitude(coordinate?.[0]), coordinate?.[1]]
				);
				trackLines?.push({
					type: "Feature",
					geometry: {
						coordinates: coordinates,
						type: "LineString"
					},
					properties: {
						...currentItem
					}
				});
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				break;
			case currentItem?.nav === "RL":
				trackLines?.push({
					type: "Feature",
					geometry: {
						coordinates: [
							prevCoordinate,
							[normalizeLongitude(currentItem?.lon), currentItem?.lat]
						],
						type: "LineString"
					},
					properties: {
						...currentItem
					}
				});
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				break;
			default:
				break;
		}
	}
	return trackLines;
};

const generateRouteWayLineString = (
	items: RouteWayPointItemType[]
): RouteWayTrackLineGeoJson<GeoJSON.MultiLineString> => {
	const trackLines: RouteWayTrackLineGeoJson<GeoJSON.MultiLineString> = {
		type: "Feature",
		geometry: {
			type: "MultiLineString",
			coordinates: []
		},
		properties: {}
	};
	let lineType: RouteWayPointItemType["nav"] = null,
		coordinates: GeoJSON.Position[] = [],
		prevCoordinate: GeoJSON.Position = null;

	for (let index = 0; index <= items?.length - 1; index++) {
		const currentItem = items?.[index];
		switch (true) {
			case index === 0:
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				break;
			case currentItem?.nav === "GC":
				coordinates?.length > 0 &&
					trackLines?.geometry?.coordinates?.push(coordinates);
				let tempCoordinates = greatCircle(
					prevCoordinate,
					[normalizeLongitude(currentItem?.lon), currentItem?.lat],
					{
						npoints: 30
					}
				)?.geometry?.coordinates;
				const isAcross180 = tempCoordinates?.every(
					(selectItem) => selectItem?.length > 2
				);
				if (isAcross180) {
					tempCoordinates = (
						tempCoordinates as unknown as GeoJSON.Position[][]
					)?.flat();
				}
				coordinates = (tempCoordinates as unknown as GeoJSON.Position[])?.map(
					(coordinate) => [normalizeLongitude(coordinate?.[0]), coordinate?.[1]]
				);
				trackLines?.geometry?.coordinates?.push(coordinates);
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				lineType = currentItem?.nav;
				coordinates = [];
				break;
			case lineType !== currentItem?.nav && currentItem?.nav === "RL":
				coordinates?.push(prevCoordinate);
			case currentItem?.nav === "RL":
				coordinates?.push([
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				]);
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				lineType = currentItem?.nav;
				break;
			default:
				break;
		}
	}

	if (coordinates?.length > 0) {
		trackLines?.geometry?.coordinates?.push(coordinates);
	}

	return trackLines;
};

export const generateWayTrackPoints = (
	selectItem: RouteWayPointItemType[],
	otherItems: RouteWayPointItemType[][]
): {
	trackLines: RouteWayTrackLineGeoJson<GeoJSON.MultiLineString>[];
	trackPoints: RouteWayTrackLineGeoJson<GeoJSON.Point>[];
	trackPointLines: RouteWayTrackLineGeoJson<GeoJSON.LineString>[];
} => {
	const trackLines: RouteWayTrackLineGeoJson<GeoJSON.MultiLineString>[] = [],
		trackPoints: RouteWayTrackLineGeoJson<GeoJSON.Point>[] = [],
		trackPointLines: RouteWayTrackLineGeoJson<GeoJSON.LineString>[] = [];

	trackPoints?.push(...generateWayPoints(selectItem));
	trackPointLines?.push(...generateWayLineString(selectItem));

	for (let pointItems of otherItems) {
		trackLines?.push(generateRouteWayLineString(pointItems));
	}
	return {
		trackLines,
		trackPoints,
		trackPointLines
	};
};
