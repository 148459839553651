import React, { ReactNode } from "react";
import { Popover } from "@/components";
import { AlertFieldTipProps } from "./type";
import style from "./index.module.less";

const AlertFieldTip: React.FC<AlertFieldTipProps> = (props) => {
	return (
		<Popover
			overlayClassName={style["alert-field-tip"]}
			placement="top-start"
			trigger="hover"
			render={() => {
				return (
					<div className="alert-field-container">
						<span className="alert-field-title">{props.title}</span>
						<span className="alert-field-content">{props.content}</span>
					</div>
				);
			}}
		>
			{props.children}
		</Popover>
	);
};

export default AlertFieldTip;
