import React, { FC } from "react";
import classNames from "classnames";
import { Dialog } from "@/components";
import { FilePreviewDialogProps } from "./type";
import style from "./index.module.less";
import { getFileType } from "./tools";
import {
	EmailContainer,
	ExcelContainer,
	ImageContainer,
	PdfContainer,
	UnknownContainer,
	WordContainer
} from "./component";

const FilePreviewDialog: FC<FilePreviewDialogProps> = (props) => {
	const { fileUrl, onClose } = props;

	const containerRender = () => {
		switch (getFileType(fileUrl)) {
			case "email":
				return <EmailContainer url={fileUrl} />;
			case "excel":
				return <ExcelContainer url={fileUrl} />;
			case "image":
				return <ImageContainer url={fileUrl} />;
			case "pdf":
				return <PdfContainer url={fileUrl} />;
			case "word":
				return <WordContainer url={fileUrl} />;
			case "unknown":
				return <UnknownContainer url={fileUrl} />;
		}
	};

	return (
		<div
			className={classNames([
				style["fleet-file-preview-dialog"],
				props.className
			])}
		>
			<Dialog
				mode="fixed"
				hide={props.hide}
				closeable={true}
				onClose={() => props.onClose()}
			>
				<div className="file-preview-container" onClick={() => onClose()}>
					<div onClick={(e) => e.stopPropagation()}>{containerRender()}</div>
				</div>
			</Dialog>
		</div>
	);
};

export default FilePreviewDialog;
