import React from "react";
import { formatNumber } from "@/tools/amount";
import style from "./index.module.less";
import { ReportSummaryTagProps } from "./type";
import classNames from "classnames";

const ReportSummaryTag: React.FC<ReportSummaryTagProps> = ({ title, list }) => {
	const isValid = (value: any) => {
		return value || value === 0;
	};

	return (
		<div className={style["reportSummaryTag"]}>
			<div className={classNames(["title", { "title-hidden": !title }])}>
				{title}
			</div>
			<div className="info">
				{list.map((item, index) => {
					return (
						<div key={index} className="info-item">
							<div className="item-title">{item.title}</div>
							<div className="item-value">
								<span>
									{isValid(item.value)
										? item.fixed
											? formatNumber(item.value, item.fixed)
											: item.value
										: "---"}
								</span>{" "}
								<span className="value-unit">
									{isValid(item.value) ? item.unit : ""}
								</span>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ReportSummaryTag;
