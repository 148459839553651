import React, { FC } from "react";
import style from "./index.module.less";
import { ReportTagProps } from "./type";

const ReportTag: FC<ReportTagProps> = ({
	title,
	titleIcon,
	value,
	valueIcon,
	unit,
	type,
	typeIcon,
	maxHeight
}) => {
	return (
		<div className={style["tag"]} style={{ maxHeight: maxHeight || "100%" }}>
			<div className="title">
				{title} {titleIcon}
			</div>
			<div className="value">
				{valueIcon} {value}{" "}
				<span className="unit">{value === "---" ? "" : unit}</span>
			</div>
			<div className="type">
				{typeIcon} {type}
			</div>
		</div>
	);
};

export default ReportTag;
