import { useState, useEffect } from "react";
import {
	LatLongInputProps,
	latitudeUnitType,
	longitudeUnitType
} from "../type";
import {
	latitudeUnitOptions,
	longitudeUnitOptions,
	latLngRange
} from "../source";
import { CommonChangeEvent } from "@/types/event";
import { convertDdToDm, convertDmToDd } from "@/tools/latLong";

const useIndex = (props: LatLongInputProps) => {
	const { value, onChange, type } = props;

	const [degree, setDegree] = useState<number>(0);
	const [minute, setMinute] = useState<number>(0);
	const [unit, setUnit] = useState<string>("");

	const unitOptions =
		type === "latitude" ? latitudeUnitOptions : longitudeUnitOptions;

	useEffect(() => {
		const {
			degree: deg,
			minute: min,
			unit: uni
		} = convertDdToDm(value, type === "latitude");
		setDegree(deg);
		setMinute(min);
		setUnit(uni);
	}, [value]);

	const handleDegreeChange = (val: number) => {
		if (type === "latitude") {
			let ret = null;
			if (val > latLngRange.latitude.max) {
				ret = convertDmToDd({ degree: latLngRange.latitude.max, minute, unit });
			} else if (val < latLngRange.latitude.min) {
				ret = convertDmToDd({ degree: latLngRange.latitude.min, minute, unit });
			} else {
				ret = convertDmToDd({ degree: val, minute, unit });
			}
			onChange(ret);
		} else {
			let ret = null;
			if (val > latLngRange.longitude.max) {
				ret = convertDmToDd({
					degree: latLngRange.longitude.max,
					minute,
					unit
				});
			} else if (val < latLngRange.longitude.min) {
				ret = convertDmToDd({
					degree: latLngRange.longitude.min,
					minute,
					unit
				});
			} else {
				ret = convertDmToDd({ degree: val, minute, unit });
			}
			onChange(ret);
		}
	};

	const handleMinuteChange = (val: number) => {
		let ret = null;
		if (val < 0) {
			ret = convertDmToDd({ degree, minute: 0, unit });
		} else if (val > 59) {
			ret = convertDmToDd({ degree, minute: 59, unit });
		} else {
			ret = convertDmToDd({ degree, minute: val, unit });
		}
		onChange(ret);
	};

	const handleUnitChange = (val: string) => {
		const ret = convertDmToDd({ degree, minute, unit: val });
		onChange(ret);
	};

	return {
		degree,
		minute,
		unit,
		unitOptions,
		handleDegreeChange,
		handleMinuteChange,
		handleUnitChange
	};
};

export default useIndex;
