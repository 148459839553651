export type DegreeMinuteType = {
	degree: number;
	minute: number;
	unit: string;
};

export type CoordinatesType = [number, number][];

export type RoutePosition = [number, number, number]; // 时间戳, 纬度, 经度

type TimeArray = number[];

// 经纬度转换: 十进制D转DM(度,分)
export function convertDdToDm(
	decimalDegree: number,
	isLatitude: boolean
): DegreeMinuteType {
	const unit =
		decimalDegree === 0
			? ""
			: isLatitude
				? decimalDegree > 0
					? "N"
					: "S"
				: decimalDegree > 0
					? "E"
					: "W";
	const absDecimalDegree = Math.abs(decimalDegree);
	const degree = Math.floor(absDecimalDegree);
	const minute = Math.round((absDecimalDegree - degree) * 60 * 1000) / 1000;
	return { degree, minute, unit };
}

// 经纬度转换: DM(度,分)转十进制D
export function convertDmToDd(degreeMinute: DegreeMinuteType): number {
	const decimalDegree = degreeMinute.degree + degreeMinute.minute / 60;
	return degreeMinute.unit === "S" || degreeMinute.unit === "W"
		? -decimalDegree
		: decimalDegree;
}

/**
 * 将一组地理坐标从 [-180, 180] 经度范围转换到 [0, 360]，纬度保持不变。调整经度以处理跨越180度经线的情况
 * @param coordinates 原始的地理坐标数组，每个元素是一个经度和纬度的数组
 * @returns 转换后的地理坐标数组，格式与输入相同
 */
export function transformCoordinates(
	coordinates: CoordinatesType
): CoordinatesType {
	const transformedCoordinates: CoordinatesType = [...coordinates];
	// 只需要处理相邻经度跨越180度经线的情况
	for (let i = 0; i < coordinates?.length - 1; i++) {
		const diff = (coordinates[i + 1][0] - coordinates[i][0]) % 360;
		if (diff > 180) {
			transformedCoordinates[i][0] += 360;
		} else if (diff < -180) {
			transformedCoordinates[i + 1][0] += 360;
		}
	}
	return transformedCoordinates;
}

/**
 * 根据船只轨迹数据计算在指定时间点的位置
 * @param route 船只轨迹数据，格式为 [[timestamp, latitude, longitude], ...]
 * @param t 目标时间点
 * @returns 在指定时间点的位置数据 [timestamp,latitude, longitude]
 */
function estimatePositionAtTime(
	route: RoutePosition[],
	t: number
): RoutePosition {
	let left = 0;
	let right = route.length - 1;
	while (left <= right) {
		const mid = Math.floor((left + right) / 2);
		const [timestampMid] = route[mid];
		if (timestampMid < t) {
			left = mid + 1;
		} else if (timestampMid > t) {
			right = mid - 1;
		} else {
			return route[mid];
		}
	}
	if (
		left > 0 &&
		left < route.length &&
		route[left - 1][0] <= t &&
		t <= route[left][0]
	) {
		const [timestamp1, lat1, lon1] = route[left - 1];
		const [timestamp2, lat2, lon2] = route[left];

		const ratio = (t - timestamp1) / (timestamp2 - timestamp1);
		const lat = lat1 + ratio * (lat2 - lat1);
		const lon = lon1 + ratio * (lon2 - lon1);
		return [t, lat, lon];
	}
	return null;
}
/**
 * 计算两个地理坐标点之间的最短距离（haversine公式）
 * @param lat1 第一个点的纬度
 * @param lon1 第一个点的经度
 * @param lat2 第二个点的纬度
 * @param lon2 第二个点的经度
 * @returns 两点之间的距离（公里）
 */
function haversine(
	lat1: number,
	lon1: number,
	lat2: number,
	lon2: number
): number {
	const R = 6371; // 地球半径 (公里)
	const dLat = (lat2 - lat1) * (Math.PI / 180);
	const dLon = (lon2 - lon1) * (Math.PI / 180);
	lat1 = lat1 * (Math.PI / 180);
	lat2 = lat2 * (Math.PI / 180);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return R * c;
}

/**
 * 计算两个坐标在特定时间点上的距离
 * @param route1 第一个坐标，格式为[[timestamp, latitude, longitude], ...]
 * @param route2 第二个坐标，格式为[[timestamp, latitude, longitude], ...]
 * @param t 时间点数组
 * @returns 每个时间点上两个坐标之间的距离（公里）
 */
export function calculateDistanceAtTime(
	route1: RoutePosition[],
	route2: RoutePosition[],
	t: TimeArray
): number[] {
	const distances: number[] = [];
	for (const time of t) {
		const estimatePosition1 = estimatePositionAtTime(route1, time);
		const estimatePosition2 = estimatePositionAtTime(route2, time);
		if (estimatePosition1 && estimatePosition2) {
			const [, lat1, lon1] = estimatePosition1;
			const [, lat2, lon2] = estimatePosition2;
			distances.push(haversine(lat1, lon1, lat2, lon2));
		} else {
			distances.push(null); // 如果没有匹配的时间戳，可以返回0或其他标识值
		}
	}
	return distances;
}
