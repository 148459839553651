import React, { FC, useState } from "react";
import styles from "./index.module.less";
import { Popover, Toast } from "@/components";

import useIndex from "./hook/useIndex";
import { ContextMenuProps } from "./type";

const ContextMenu: FC<ContextMenuProps> = ({
	show,
	aisItem,
	position,
	onSuccess,
	onClose
}) => {
	const { handleDelete } = useIndex(onSuccess);
	if (!show) return null;
	return (
		<div className={styles.contextMenu} style={position}>
			<ul>
				<li style={{ cursor: "pointer" }}>
					<Popover
						render={(setIsOpen) => {
							return (
								<Toast
									label="Are you sure want to delete?"
									onCancel={() => {
										setIsOpen(false);
										onClose();
									}}
									onConfirm={() => {
										handleDelete(aisItem?.id);
										onClose();
										setIsOpen(false);
									}}
								/>
							);
						}}
					>
						delete
					</Popover>
				</li>
			</ul>
		</div>
	);
};

export default ContextMenu;
