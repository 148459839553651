import { useState } from "react";
import { CommonCommitEvent, CommonColumnCommitEvent } from "@/types/event";
import { NoonMainInfoFormType } from "../../../common/noonMainInfoForm/type";
import {
	FuelTableDataType,
	FuelTableDataItemType
} from "../../../common/noonFuelForm/type";
import {
	SpSiFormType,
	SpNetDataType,
	NoonSpDataType,
	SpBasicDataType
} from "../type";
import {
	initialSpFuelData,
	initialSpMiData,
	initialSpSiData,
	initialSpBasicData
} from "../source";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { AddNoonReportProps } from "../../addNoonReport/type";
import { getHoursByTimeStampRange } from "@/tools/date";
import { formatNumber } from "@/tools/amount";

const useIndex = (props: AddNoonReportProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	// Main Information data
	const [miData, setMiData] = useState<NoonMainInfoFormType>(initialSpMiData);

	// Stop Information data
	const [siData, setSiData] = useState<SpSiFormType>(initialSpSiData);

	// Fuel data
	const [fuelData, setFuelData] =
		useState<FuelTableDataType>(initialSpFuelData);

	// basic data
	const [basicData, setBasicData] =
		useState<SpBasicDataType>(initialSpBasicData);

	const handleSpMiCommit: CommonCommitEvent<NoonMainInfoFormType> = (item) => {
		setMiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleSpSiCommit: CommonCommitEvent<SpSiFormType> = (item) => {
		setSiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleSpFuelCommit: CommonColumnCommitEvent<FuelTableDataItemType> = (
		index,
		item
	) => {
		setFuelData((prev) => {
			prev.splice(index, 1, item as FuelTableDataItemType);
			return [...prev];
		});
	};

	const handleClearSpData = () => {
		// setMiData(initialSpMiData);
		setSiData(initialSpSiData);
		setFuelData(initialSpFuelData);
		setBasicData(initialSpBasicData);
	};

	// 接口数据赋值
	const handleSpNetDataInit = (data: SpNetDataType) => {
		// setMiData({
		// 	vesselName: data.shipName ?? "",
		// 	imo: data.imo ?? "",
		// 	mmsi: data.mmsi ?? "",
		// 	callSign: data.callSign ?? ""
		// });

		setSiData(() => {
			const hours = formatNumber(
				getHoursByTimeStampRange(
					Number(data.driftStartTime),
					Number(data.driftEndTime)
				) as number,
				2
			);

			return {
				stopLatitude: data.driftStartPositionLat ?? 0,
				stopLongitude: data.driftStartPositionLon ?? 0,
				stopDate: data.driftStartTime ?? "",
				distSlrAtStop: data.distSlrAtStop ?? "",
				resumeLatitude: data.driftEndPositionLat ?? 0,
				resumeLongitude: data.driftEndPositionLon ?? 0,
				resumeDate: data.driftEndTime ?? "",
				distRemainAtResume: data.distRemainAtResume ?? "",
				stopReason: data.remarks ?? "",
				stoppageTime: Number.isNaN(hours) ? "" : hours
				// stoppageTime: data.stoppageTime
			};
		});

		setFuelData((prev) => {
			return [
				{
					...prev[0],
					hfo: data.brobHfoAtStop ?? "",
					lsfo: data.brobLsfoAtStop ?? "",
					mdo: data.brobMdoAtStop ?? "",
					mgo: data.brobMgoAtStop ?? ""
				},
				{
					...prev[1],
					hfo: data.brobHfoAtResume ?? "",
					lsfo: data.brobLsfoAtResume ?? "",
					mdo: data.brobMdoAtResume ?? "",
					mgo: data.brobMgoAtResume ?? ""
				},
				{
					...prev[2],
					hfo: formatNumber(
						Number(data.brobHfoAtStop) - Number(data.brobHfoAtResume),
						3
					),
					lsfo: formatNumber(
						Number(data.brobLsfoAtStop) - Number(data.brobLsfoAtResume),
						3
					),
					mdo: formatNumber(
						Number(data.brobMdoAtStop) - Number(data.brobMdoAtResume),
						3
					),
					mgo: formatNumber(
						Number(data.brobMgoAtStop) - Number(data.brobMgoAtResume),
						3
					)
				}
				// },
				// {
				// 	...prev[2],
				// 	hfo: data.totalHfo ?? "",
				// 	lsfo: data.totalLsfo ?? "",
				// 	mdo: data.totalMdo ?? "",
				// 	mgo: data.totalMgo ?? ""
				// }
			];
		});

		setBasicData({ id: data.id });
	};

	// 提交数据
	const handleSpDataCommit = (data: NoonSpDataType) => {
		const commitData: Partial<SpNetDataType> = {
			id: data.basicData.id,
			legId: props.legId,
			type: "Stoppage",
			// heading: "W",
			mmsi: data.miData.mmsi,
			shipName: data.miData.vesselName,
			imo: data.miData.imo,
			callSign: data.miData.callSign,
			// reportDateTimeStr: "20230917/0900 UTC",
			// reportDateTime: 1694941200000,
			// positionStr: null,
			// positionLon: null,
			// positionLat: null,
			// course: 267.0,
			// driftStartPositionStr: "26°53.000S 038°46.000E",
			driftStartPositionLon: data.siData.stopLongitude,
			driftStartPositionLat: data.siData.stopLatitude,
			// driftEndPositionStr: "26°53.000S 038°46.000E",
			driftEndPositionLat: data.siData.resumeLatitude,
			driftEndPositionLon: data.siData.resumeLongitude,
			distSlrAtStop: data.siData.distSlrAtStop,
			// ecaDistSlrAtStop: data.siData.,
			distRemainAtResume: data.siData.distRemainAtResume,
			// ecaDistRemainAtResume: ,
			// driftStartTimeStr: "20230917/0900 UTC",
			driftStartTime: data.siData.stopDate,
			// driftEndTimeStr: "20230918/0900 UTC",
			driftEndTime: data.siData.resumeDate,
			brobHfoAtStop: data.fuelData[0].hfo,
			brobLsfoAtStop: data.fuelData[0].lsfo,
			brobMdoAtStop: data.fuelData[0].mdo,
			brobMgoAtStop: data.fuelData[0].mgo,
			brobHfoAtResume: data.fuelData[1].hfo,
			brobLsfoAtResume: data.fuelData[1].lsfo,
			brobMdoAtResume: data.fuelData[1].mdo,
			brobMgoAtResume: data.fuelData[1].mgo,
			totalHfo: data.fuelData[2].hfo,
			totalLsfo: data.fuelData[2].lsfo,
			totalMdo: data.fuelData[2].mdo,
			totalMgo: data.fuelData[2].mgo,
			remarks: data.siData.stopReason,
			stoppageTime: data.siData.stoppageTime
		};

		onRequest(
			"saveLegSpReportApi",
			commitData,
			handleSpDataCommitFront,
			handleSpDataCommitSuccess,
			handleSpDataCommitError,
			dispatch,
			navigate
		);
	};

	const handleSpDataCommitFront = () => {};
	const handleSpDataCommitSuccess = (res) => {
		console.log("success", res);
		props.onAddSuccess();
		handleClearSpData();
	};
	const handleSpDataCommitError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	return {
		spData: { miData, siData, fuelData, basicData },
		handleSpMiCommit,
		handleSpSiCommit,
		handleSpFuelCommit,
		handleClearSpData,
		handleSpNetDataInit,
		handleSpDataCommit
	};
};

export default useIndex;
