export const normalizeLongitude = (
	longitude: number,
	mode: "EPR" | "GCR" = "GCR"
): number => {
	// 将经度模 360 的结果限制在 -360 到 360 度范围内
	let normalized = ((longitude % 360) + 360) % 360;
	switch (true) {
		case mode === "EPR" && normalized > 180:
			normalized -= 360;
			break;
		default:
			break;
	}
	return normalized;
};
