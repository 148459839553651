import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonIdType } from "@/api/leg/type";
import { RouteInfoType, RouteDistanceItemType } from "../../../type";
import {
	PlanType,
	WaypointListType,
	WayPointShowInMapObjectType,
	WaypointType
} from "../type";
import { useLegContext } from "@/pages/leg/business/details/store";
const useIndex = (
	routeInfo: RouteInfoType,
	resetRouteList: () => void,
	isFirstTable: boolean,
	updateActiveRouteId: (id: string) => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const location = useLocation();
	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");

	const {
		route: { activePlan, handleRoutePlanCrud, handleRouteRemove }
	} = useLegContext();

	const [loading, setLoading] = useState(false);
	const [routeImportDialogVisible, setRouteImportDialogVisible] =
		useState<boolean>(false);
	const [planList, setPlanList] = useState<PlanType[]>([]);
	const curSelcetedPlanId = useRef<string>(null);
	const [wayPointShowInMapData, setWayPointShowInMapData] =
		useState<WayPointShowInMapObjectType>(null);
	const [planDownloadDialogVisible, setPlanDownloadDialogVisible] =
		useState(false);
	const [waypointList, setWaypointList] = useState<WaypointListType>(null);

	const initPageData = () => {
		getRouteDetailList({ id: routeInfo?.id });
	};

	// RouteImportDialog
	const handleCloseRouteImportDialog = () => {
		setRouteImportDialogVisible(false);
	};
	const handleOpenRouteImportDialog = () => {
		setRouteImportDialogVisible(true);
	};

	// Route List
	const handleDelRoute = (id: string) => {
		delRoute({ id });
	};

	// planList
	const handleDelPlan = (id: string) => {
		delPlan({ id });
	};
	const handelCollectPlan = (id: string) => {
		collectPlan({ id });
	};
	const handleCancelCollectPlan = (id: string) => {
		cancelCollectPlan({ id });
	};
	const handleChangePlanName = (val: string, index: number) => {
		const newPlanItem = { ...planList[index], routeName: val };
		let newPlanList = [...planList];
		newPlanList[index] = newPlanItem;
		setPlanList(newPlanList);
	};
	const handleCopyPlan = (id: string) => {
		copyPlan({ id });
	};
	const handleShowWaypoint = (id: string) => {
		// 隐藏
		if (wayPointShowInMapData?.[id]) {
			changeWappointDataInMap("hidden", id);
			return;
		}
		// 显示
		getWaypointToShow({ id });
	};

	const changeWappointDataInMap = (
		type: "visible" | "hidden" | "edit",
		planId: string,
		items?: WaypointType[]
	) => {
		const isExist = wayPointShowInMapData?.[planId];
		// visible
		if (type === "visible") {
			setWayPointShowInMapData({
				...wayPointShowInMapData,
				[planId]: items
			});
			handleRoutePlanCrud("visible", planId, items);
		}
		// hidden
		if (type === "hidden") {
			let newWayPointShowInMapData = { ...wayPointShowInMapData };
			delete newWayPointShowInMapData?.[planId];
			setWayPointShowInMapData(newWayPointShowInMapData);
			handleRoutePlanCrud("hidden", planId);
		}
		// edit
		if (type === "edit") {
			setWayPointShowInMapData({
				...wayPointShowInMapData,
				[planId]: items
			});
			handleRoutePlanCrud("edit", planId, items);
			// 更新激活的routeInfo id
			updateActiveRouteId(routeInfo.id);
		}
	};

	// PlanDownloadDialog
	const handleClosePlanDownloadDialog = () => {
		setPlanDownloadDialogVisible(false);
	};
	const handleOpenPlanDownloadDialog = () => {
		setPlanDownloadDialogVisible(true);
	};

	// WaypointList(RouteDetail)
	const handleOpenWaypointList = (id: string) => {
		getWaypointList({ id });
	};

	// Simulation
	const [simulationOpenIds, setSimulationOpenIds] = useState<string[]>([]);
	const handleShowSimulation = (id: string) => {
		if (simulationOpenIds.includes(id)) {
			handleCloseSimulation(id);
		} else {
			setSimulationOpenIds([...simulationOpenIds, id]);
		}
	};
	const handleCloseSimulation = (id: string) => {
		setSimulationOpenIds(simulationOpenIds.filter((item) => item !== id));
	};
	// API
	// API - delRoute
	const delRouteFront = () => {
		setLoading(true);
	};
	const delRouteFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const delRoute = (params?: { id: string }) => {
		onRequest(
			"delRouteApi",
			params,
			delRouteFront,
			(response) => {
				resetRouteList && resetRouteList();
				handleRouteRemove(planList?.map((planItem) => planItem?.id));
				setLoading(false);
			},
			delRouteFailed,
			dispatch,
			navigate
		);
	};

	// API - getRouteDetail (getRoutePlanList)
	const getRouteDetailListFront = () => {
		setLoading(true);
	};
	const getRouteDetailListSuccess = (data) => {
		setLoading(false);
		setPlanList(data?.data || []);
		//  首个列表, 首条waypoint自动展示
		if (isFirstTable && data?.data?.[0]?.id) {
			getWaypointToShow({ id: data?.data?.[0]?.id });
		}
	};
	const getRouteDetailListFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getRouteDetailList = (params: { id: string }) => {
		onRequest(
			"getRouteDetailListApi",
			params,
			getRouteDetailListFront,
			getRouteDetailListSuccess,
			getRouteDetailListFailed,
			dispatch,
			navigate
		);
	};

	// API - getWaypointList
	const getWaypointListFront = () => {
		setLoading(true);
	};
	const getWaypointListSuccess = (data, planId) => {
		setLoading(false);
		setWaypointList({ planId, list: data?.data || [] });
		// 每次请求展开或更新waypointList, 同步到地图
		changeWappointDataInMap(
			wayPointShowInMapData?.[planId] ? "edit" : "visible",
			planId,
			data?.data
		);
	};
	const getWaypointListFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getWaypointList = (params: { id: string }) => {
		onRequest(
			"getWaypointListApi",
			params,
			getWaypointListFront,
			(data) => {
				getWaypointListSuccess(data, params?.id);
			},
			getWaypointListFailed,
			dispatch,
			navigate
		);
	};

	// API - getWaypointToShow
	const getWaypointToShowFront = () => {
		setLoading(true);
	};
	const getWaypointToShowSuccess = (data, planId) => {
		// 每次请求代表需要将数据展示到地图
		data?.data && changeWappointDataInMap("visible", planId, data?.data);
		setLoading(false);
	};
	const getWaypointToShowFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getWaypointToShow = (params: { id: string }) => {
		onRequest(
			"getWaypointListApi",
			params,
			getWaypointToShowFront,
			(data) => {
				getWaypointToShowSuccess(data, params?.id);
			},
			getWaypointToShowFailed,
			dispatch,
			navigate
		);
	};

	// API - delRoute
	const delPlanFront = () => {
		setLoading(true);
	};
	const delPlanSuccess = (id) => {
		setLoading(false);
		changeWappointDataInMap("hidden", id);
		initPageData();
	};
	const delPlanFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const delPlan = (params?: { id: string }) => {
		onRequest(
			"delPlanApi",
			params,
			delPlanFront,
			() => {
				delPlanSuccess(params.id);
			},
			delPlanFailed,
			dispatch,
			navigate
		);
	};

	// API - collectPlan
	const collectPlanFront = () => {
		setLoading(true);
	};
	const collectPlanSuccess = (data) => {
		setLoading(false);
		initPageData();
	};
	const collectPlanFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const collectPlan = (params?: { id: string }) => {
		onRequest(
			"collectPlanApi",
			params,
			collectPlanFront,
			collectPlanSuccess,
			collectPlanFailed,
			dispatch,
			navigate
		);
	};

	// API - cancelCollectPlan
	const cancelCollectPlanFront = () => {
		setLoading(true);
	};
	const cancelCollectPlanSuccess = (data) => {
		setLoading(false);
		initPageData();
	};
	const cancelCollectPlanFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const cancelCollectPlan = (params?: { id: string }) => {
		onRequest(
			"cancelCollectPlanApi",
			params,
			cancelCollectPlanFront,
			cancelCollectPlanSuccess,
			cancelCollectPlanFailed,
			dispatch,
			navigate
		);
	};

	// API - copyPlan
	const copyPlanFront = () => {
		setLoading(true);
	};
	const copyPlanSuccess = (data) => {
		setLoading(false);
		initPageData();
	};
	const copyPlanFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const copyPlan = (params?: { id: string }) => {
		onRequest(
			"copyPlanApi",
			params,
			copyPlanFront,
			copyPlanSuccess,
			copyPlanFailed,
			dispatch,
			navigate
		);
	};

	const handleDistanceUpdate = (item: RouteDistanceItemType) => {
		console.log("再看一眼数据", item, activePlan);

		console.log(planList);
		console.log(planList.map((item) => item.id));

		// if (item.planId === )

		setPlanList((prev) => {
			prev.forEach((plan) => {
				if (plan.id === item.planId) {
					plan.distance = item.distance;
				}
			});
			return prev;
		});
	};

	// DidMount
	useEffect(() => {
		initPageData();
	}, []);
	// 点击展开wayPoints， 地图同步wayPoints编辑状态
	useEffect(() => {
		// 编辑
		if (waypointList?.planId) {
			changeWappointDataInMap("edit", waypointList?.planId, waypointList?.list);
		}
	}, [waypointList]);

	return {
		planList,
		routeImportDialogVisible,
		planDownloadDialogVisible,
		curSelcetedPlanId,
		loading,
		wayPointShowInMapData,
		activePlan,
		simulationOpenIds,
		handleOpenRouteImportDialog,
		handleDelRoute,
		handleCloseRouteImportDialog,
		initPageData,
		handleOpenWaypointList,
		handleDelPlan,
		handelCollectPlan,
		handleCancelCollectPlan,
		handleClosePlanDownloadDialog,
		handleOpenPlanDownloadDialog,
		handleChangePlanName,
		handleCopyPlan,
		handleShowWaypoint,
		handleShowSimulation,
		handleCloseSimulation,
		handleDistanceUpdate
	};
};

export default useIndex;
