import React, { FC } from "react";
import classnames from "classnames";
import classes from "./index.module.less";
import { IconProps } from "./interface";

const Icon: FC<IconProps> = ({
	className,
	size = 16,
	type,
	color,
	disabled,
	onClick,
	style
}) => {
	const handleClick: React.MouseEventHandler<HTMLSpanElement> = (event) => {
		// event?.stopPropagation();
		if (disabled) return;
		onClick?.(event);
	};
	return (
		<span
			style={{
				...style,
				fontSize: size,
				color
			}}
			className={classnames(classes["voy-icon"], "iconfont", type, className, {
				"voy-disabled": disabled
			})}
			onClick={handleClick}
		></span>
	);
};

export default Icon;
