import {
	Button,
	Dialog,
	Upload,
	Tabs,
	FormItem,
	FleetDatePicker,
	FleetInput,
	Select,
	Icon
} from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { SimulationDialogProps, TabKeyType } from "./type";
import { tabList } from "./source";
import { Switch } from "antd";
import dayjs from "dayjs";
import { LatLongInput } from "@/common";
import classNames from "classnames";
import { FleetUtcTimePicker } from "@/pages/leg/business/details/components";
const SimulationDialog: FC<SimulationDialogProps> = ({
	planId,
	visible,
	simulationTitle,
	onClose,
	onFinish,
	routeId
}) => {
	const {
		loading,
		tabActiveKey,
		// simulationData,
		pilotData,
		vesselPositionData,
		cpList,
		vesselCurrentState,
		hanleCloseDialog,
		handleClickTab,
		// handleChangeSimulation,
		handlePilotDataChange,
		handleVesselPositionDataChange,
		handleConfirm
	} = useIndex(onClose, onFinish, routeId);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={() => {
				hanleCloseDialog();
			}}
			loading={loading}
		>
			<div className={styles["simulationDialog"]}>
				<div className="fleet-header">
					<div className="simulationDialog-header">
						<div>Simulation</div>
						<Icon
							type="icon-close2"
							className="close-btn"
							onClick={() => {
								hanleCloseDialog();
							}}
						/>
					</div>
				</div>
				<div className="simulationDialog-main">
					{tabActiveKey === "Pilot" && (
						<div className="main-pilot">
							<div className="main-title">
								{/* * Route [1] Shakhtersk ➡ [2] Lianyungang */}
								{simulationTitle}
							</div>
							<div className="form-item">
								<FormItem label="From" type="row">
									<span
										className={classNames("formButton", {
											"formButton-active": tabActiveKey === "Pilot"
										})}
										onClick={() => {
											handleClickTab("Pilot");
										}}
									>
										Pilot
									</span>
									<span
										className={classNames("formButton", {
											"formButton-active": tabActiveKey !== "Pilot"
										})}
										onClick={() => {
											handleClickTab("Vessel Position");
										}}
									>
										Vessel Position
									</span>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem
									containerClassname="password-item"
									label="Instructed Spd"
									type="row"
									// needVerity
								>
									<FleetInput
										className="commonInput"
										value={pilotData?.instructedSpeed}
										type="number"
										onChange={(value: number) => {
											handlePilotDataChange("instructedSpeed", value);
										}}
									/>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem
									containerClassname="password-item"
									label="ETD"
									type="row"
								>
									<FleetUtcTimePicker
										className="commonInput"
										value={Number(pilotData?.etd)}
										onChange={(value) => {
											handlePilotDataChange("etd", value);
										}}
									/>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem
									containerClassname="password-item"
									label="CP"
									type="row"
								>
									<Select
										className="cpInput"
										value={pilotData?.cpId}
										onChange={(value: string) => {
											handlePilotDataChange("cpId", value);
										}}
										options={cpList || []}
									/>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem
									containerClassname="password-item"
									label="Use Route Parameters"
									type="row"
								>
									<Switch
										value={pilotData?.isRouteParameters}
										onChange={(value) => {
											handlePilotDataChange("isRouteParameters", value);
										}}
									/>
								</FormItem>
							</div>
						</div>
					)}
					{tabActiveKey === "Vessel Position" && (
						<div className="main-vessel">
							<div className="main-title">
								Last position time :{" "}
								{dayjs(vesselCurrentState?.updateTime)
									?.utc()
									?.format("YYYY-MM-DD HH:mm:ss")}
							</div>
							<div className="form-item">
								<FormItem label="From" type="row">
									<span
										className={classNames("formButton", {
											"formButton-active": tabActiveKey !== "Vessel Position"
										})}
										onClick={() => {
											handleClickTab("Pilot");
										}}
									>
										Pilot
									</span>
									<span
										className={classNames("formButton", {
											"formButton-active": tabActiveKey === "Vessel Position"
										})}
										onClick={() => {
											handleClickTab("Vessel Position");
										}}
									>
										Vessel Position
									</span>
								</FormItem>
							</div>

							<div className="form-item">
								<FormItem label="Position" type="row">
									<LatLongInput
										className="cosp-input"
										value={vesselPositionData?.lat}
										type="latitude"
										onChange={(value) => {
											handleVesselPositionDataChange("lat", value);
										}}
									/>
									,
									<LatLongInput
										className="cosp-input"
										value={vesselPositionData?.lon || 0}
										type="longitude"
										onChange={(value) => {
											handleVesselPositionDataChange("lon", value);
										}}
									/>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem
									containerClassname="password-item"
									label="Instructed Spd"
									type="row"
									// needVerity
								>
									<FleetInput
										className="commonInput"
										value={vesselPositionData?.instructedSpeed}
										type="number"
										onChange={(value: number) => {
											handleVesselPositionDataChange("instructedSpeed", value);
										}}
									/>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem label="Time" type="row">
									<FleetUtcTimePicker
										className="commonInput"
										value={Number(vesselPositionData?.time)}
										onChange={(value) => {
											handleVesselPositionDataChange("time", value);
										}}
									/>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem
									containerClassname="password-item"
									label="CP"
									type="row"
								>
									<Select
										className="cpInput"
										value={vesselPositionData?.cpId}
										onChange={(value: string) => {
											handleVesselPositionDataChange("cpId", value);
										}}
										options={cpList || []}
									/>
								</FormItem>
							</div>
							<div className="form-item">
								<FormItem
									containerClassname="password-item"
									label="Use Route Parameters"
									type="row"
								>
									<Switch
										value={vesselPositionData?.isRouteParameters}
										onChange={(value) => {
											handleVesselPositionDataChange(
												"isRouteParameters",
												value
											);
										}}
									/>
								</FormItem>
							</div>
						</div>
					)}
				</div>
				<div className="simulationDialog-footer">
					<Button
						type="secondary"
						onClick={() => {
							hanleCloseDialog();
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							handleConfirm(planId);
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default SimulationDialog;
