import React, { FC } from "react";
import style from "./index.module.less";
import { SplitPanelProps } from "./type";
import classNames from "classnames";
import useIndex from "./hooks/useIndex";

const SplitPanel: FC<SplitPanelProps> = (props) => {
	const { leftChildren, rightChildren, className } = props;

	const {
		splitPaneRef,
		handleMouseDown,
		leftStyle,
		rightStyle,
		splitBarStyle
	} = useIndex(props);

	return (
		<div
			ref={splitPaneRef}
			className={classNames(style["fleet-split-panel"], className)}
		>
			<div className="left-container" style={leftStyle}>
				{leftChildren}
			</div>
			<div
				className="split-bar"
				style={splitBarStyle}
				onMouseDown={handleMouseDown}
			></div>
			<div className="right-container" style={rightStyle}>
				{rightChildren}
			</div>
		</div>
	);
};

export default SplitPanel;
