import React, { FC } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import useIndex from "./hooks/useIndex";
import { FleetTabbar } from "@/components/Tabs";
import { Button, FleetInput, Icon } from "@/components";
import {
	CommonLatitudeCoordinateOptionType,
	CommonLatitudeSelectProps
} from "./type";
import { ButtonProps } from "@/components/Button/interface";

const CommonLatitudeSelect: FC<CommonLatitudeSelectProps> = ({
	onClose,
	onBlur,
	open
}) => {
	const {
		status,
		currentIndex,
		items,
		active,
		value,
		translate,
		inputRef,
		isFull,
		change,
		keyDown,
		blur,
		select,
		coordinate,
		handleCoordinateChange
	} = useIndex(open, onBlur);

	const handleConfirm: ButtonProps["onClick"] = (event) => {
		event?.preventDefault();
		if (!isFull) return;
		const result = translate();
		onClose?.(result);
	};

	return (
		<div className={style["fleet-latitudeSelect"]}>
			<div className="latitudeSelect-header">
				<div className="header-item">
					<span>Latitude</span>
					<FleetTabbar
						value={coordinate?.lat}
						onChange={(value: CommonLatitudeCoordinateOptionType["lat"]) => {
							handleCoordinateChange("lat", value);
						}}
						list={["N", "S"]}
						className="latitudeSelect-tabbar"
					/>
				</div>
				<div className="header-item">
					<span>Longitude</span>
					<FleetTabbar
						value={coordinate?.lon}
						onChange={(value: CommonLatitudeCoordinateOptionType["lon"]) => {
							handleCoordinateChange("lon", value);
						}}
						list={["E", "W"]}
						className="latitudeSelect-tabbar"
					/>
				</div>
			</div>
			<div className="latitudeSelect-container">
				<div className="latitudeSelect-wrap">
					<FleetInput
						value={value}
						autoFocus
						className={classNames("latitudeSelect-input", status.current)}
						inputStyle={{
							left: 80 * active
						}}
						inputRef={inputRef}
						type="number"
						onKeyDown={keyDown}
						// onFocus={handleFocus}
						onBlur={blur}
						onChange={change}
					/>
					<div className="latitudeSelect-content">
						{items.map((item, idx) => {
							return (
								<div
									key={idx}
									onClick={() => {
										select(idx);
									}}
									className={classNames("latitudeSelect-item", {
										active: active === idx
									})}
								>
									<span>{items[idx]}</span>
								</div>
							);
						})}
					</div>
				</div>
				<Button
					className="latitudeSelect-action"
					type="icon"
					size="small"
					disabled={!isFull}
					onClick={handleConfirm}
				>
					<Icon type="icon-right" color={"#fff"} />
				</Button>
			</div>
		</div>
	);
};

export default CommonLatitudeSelect;
