export const tabList = [
	{
		key: "algorithm",
		label: "Algorithm"
	},
	{
		key: "custom",
		label: "Custom"
	},
	{
		key: "refer",
		label: "Refer"
	}
];
