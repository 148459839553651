import { DataType } from "@/components/Table";
import { convertDdToDm } from "@/tools/latLong";
import type { ColumnsType } from "antd/es/table/interface";
import { dateToUtcString } from "@/tools/date";

export const simulationTableColumns: ColumnsType<DataType> = [
	{
		title: "Route Plan",
		dataIndex: "routeName"
	},
	{
		title: "Distance",
		dataIndex: "distance"
	},
	{
		title: "ETA",
		dataIndex: "eta",
		render: (value) => {
			return value ? dateToUtcString(value) : "";
		}
	},
	{
		title: "FO/DGO",
		dataIndex: "consFo",
		render(value, row, index) {
			return `${row?.consFo || "-"}/${row?.consDgo || "-"}`;
		}
	}
];

export const pointTableColumns: ColumnsType<DataType> = [
	{
		title: "Waypoint Name",
		dataIndex: "name",
		width: 150
	},
	{
		title: "Nav",
		dataIndex: "nav",
		width: 80
	},
	{
		title: "Position",
		dataIndex: "lat",
		render(value, row, index) {
			if (!row?.lat || !row?.lon) {
				return "-";
			}
			const {
				degree: latDegree,
				minute: latMinute,
				unit: latUnit
			} = convertDdToDm(row?.lat, true);
			const {
				degree: lonDegree,
				minute: lonMinute,
				unit: lonUnit
			} = convertDdToDm(row?.lon, false);
			const latStr = `${latDegree}°${latMinute}${latUnit}`;
			const lonStr = `${lonDegree}°${lonMinute}${lonUnit}`;
			return `${latStr} / ${lonStr}`;
		}
	},
	{
		title: "Speed (kts)",
		dataIndex: "speed",
		width: 120
	},
	{
		title: "Stoppage (hrs)",
		dataIndex: "stopHours",
		width: 150
	},
	{
		title: "Resume Time",
		dataIndex: "resumeTime"
	}
];
