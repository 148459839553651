import ReactDOMServer from "react-dom/server";
import { RouteCommonVesselInfoPopup, RouteCommonVesselInfoPopupItemType } from "../components";
import React, { MutableRefObject } from "react";
import mapboxgl, { SourceSpecification } from "mapbox-gl";
import mapStyle from "../index.module.less";

export const generateGeoJson = <
	G extends GeoJSON.Geometry | null = GeoJSON.Geometry,
	P = GeoJSON.GeoJsonProperties
>(
	features: Array<GeoJSON.Feature<G, P>> = []
): GeoJSON.FeatureCollection<G, P> => {
	return {
		type: "FeatureCollection",
		features
	};
};


export const generateVesselInfoPopup = (
	mapboxGl: mapboxgl.Map,
	option: {
		popupElement: MutableRefObject<mapboxgl.Popup>;
		dataSource: RouteCommonVesselInfoPopupItemType;
		position: [number, number];
		type: "newly" | "delete";
	}
) => {
	console.log("dataSource", {
		option
	});
	if (!mapboxGl) return;
	if (option?.type === "delete") {
		option.popupElement.current.remove();
		option.popupElement.current = null;
		return;
	}
	const htmlString = ReactDOMServer.renderToString(
		<RouteCommonVesselInfoPopup
			item={
				typeof option?.dataSource === "string"
					? JSON.parse(option?.dataSource)
					: option?.dataSource
			}
		/>
	);

	const popup = new mapboxgl.Popup({
		closeOnMove: false,
		closeButton: false,
		offset: [0, -25],
		className: mapStyle["routeCommon-map-vesselInfo-popup"]
	})
		.setHTML(htmlString)
		.setLngLat(option?.position)
		.addTo(mapboxGl);
	option.popupElement.current = popup;
};

export const loadImage = (
	mapboxGl: mapboxgl.Map,
	option: {
		imagePath: string;
		imageName: string;
	}
) => {
	if (!mapboxGl) return;
	mapboxGl.loadImage(option?.imagePath, (error, image) => {
		if (error) throw error;
		mapboxGl.addImage(option?.imageName, image);
	});
};

export const addSourceToMap = (
	mapboxGl: mapboxgl.Map,
	option: {
		sourceName: string;
		source: SourceSpecification;
	}
) => {
	if (!mapboxGl) return;
	if (mapboxGl.getSource(option?.sourceName)) return;
	mapboxGl.addSource(option?.sourceName, option?.source);
};

export const addVesselGroupLayerToMap = (
	mapboxGl: mapboxgl.Map,
	option: {
		layerName: string;
		sourceName: string;
		imageName: string;
	}
) => {
	if (!mapboxGl) return;
	const currentLayer = mapboxGl.addLayer({
		id: option?.layerName,
		type: "symbol",
		source: option?.sourceName,
		layout: {
			"icon-image": option?.imageName,
			"icon-size": 0.2
		}
	});
	currentLayer.on("mouseenter", (event) => {});
};


export class TileSystem {
	private static EarthRadius: number = 6378137;
	private static MinLatitude: number = -85.05112878;
	private static MaxLatitude: number = 85.05112878;
	private static MinLongitude: number = -180;
	private static MaxLongitude: number = 180;

	private static clip(n: number, minValue: number, maxValue: number): number {
		return Math.min(Math.max(n, minValue), maxValue);
	}

	public static mapSize(levelOfDetail: number): number {
		return 256 << levelOfDetail; // 2^levelOfDetail * 256
	}

	public static groundResolution(
		latitude: number,
		levelOfDetail: number
	): number {
		latitude = this.clip(latitude, this.MinLatitude, this.MaxLatitude);
		return (
			(Math.cos((latitude * Math.PI) / 180) * 2 * Math.PI * this.EarthRadius) /
			this.mapSize(levelOfDetail)
		);
	}

	public static mapScale(
		latitude: number,
		levelOfDetail: number,
		screenDpi: number
	): number {
		return (
			(this.groundResolution(latitude, levelOfDetail) * screenDpi) / 0.0254
		); // Convert mm to inches
	}

	public static latLongToPixelXY(
		latitude: number,
		longitude: number,
		levelOfDetail: number
	): { pixelX: number; pixelY: number } {
		latitude = this.clip(latitude, this.MinLatitude, this.MaxLatitude);
		longitude = this.clip(longitude, this.MinLongitude, this.MaxLongitude);

		const x = (longitude + 180) / 360;
		const sinLatitude = Math.sin((latitude * Math.PI) / 180);
		const y =
			0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (4 * Math.PI);

		const mapSize = this.mapSize(levelOfDetail);
		const pixelX = Math.round(this.clip(x * mapSize + 0.5, 0, mapSize - 1));
		const pixelY = Math.round(this.clip(y * mapSize + 0.5, 0, mapSize - 1));

		return { pixelX, pixelY };
	}

	public static pixelXYToLatLong(
		pixelX: number,
		pixelY: number,
		levelOfDetail: number
	): { latitude: number; longitude: number } {
		const mapSize = this.mapSize(levelOfDetail);
		const x = this.clip(pixelX, 0, mapSize - 1) / mapSize - 0.5;
		const y = 0.5 - this.clip(pixelY, 0, mapSize - 1) / mapSize;

		const latitude =
			90 - (360 * Math.atan(Math.exp(-y * 2 * Math.PI))) / Math.PI;
		const longitude = 360 * x;

		return { latitude, longitude };
	}

	public static pixelXYToTileXY(
		pixelX: number,
		pixelY: number
	): { tileX: number; tileY: number } {
		const tileX = Math.floor(pixelX / 256);
		const tileY = Math.floor(pixelY / 256);

		return { tileX, tileY };
	}

	public static tileXYToPixelXY(
		tileX: number,
		tileY: number
	): { pixelX: number; pixelY: number } {
		const pixelX = tileX * 256;
		const pixelY = tileY * 256;

		return { pixelX, pixelY };
	}

	public static tileXYToQuadKey(
		tileX: number,
		tileY: number,
		levelOfDetail: number
	): string {
		let quadKey = "";
		for (let i = levelOfDetail; i > 0; i--) {
			let digit = 0;
			const mask = 1 << (i - 1);
			if ((tileX & mask) !== 0) {
				digit++;
			}
			if ((tileY & mask) !== 0) {
				digit++;
				digit++;
			}
			quadKey += digit;
		}
		return quadKey;
	}

	public static quadKeyToTileXY(quadKey: string): {
		tileX: number;
		tileY: number;
		levelOfDetail: number;
	} {
		let tileX = 0;
		let tileY = 0;
		const levelOfDetail = quadKey.length;

		for (let i = levelOfDetail; i > 0; i--) {
			const mask = 1 << (i - 1);
			switch (quadKey[levelOfDetail - i]) {
				case "0":
					break;
				case "1":
					tileX |= mask;
					break;
				case "2":
					tileY |= mask;
					break;
				case "3":
					tileX |= mask;
					tileY |= mask;
					break;
				default:
					throw new Error("Invalid character in quadKey.");
			}
		}

		return { tileX, tileY, levelOfDetail };
	}
}

// Example usage
// const { pixelX, pixelY } = TileSystem.latLongToPixelXY(37.7749, -122.4194, 12);
// console.log(`Pixel coordinates: ${pixelX}, ${pixelY}`);

// const quadKey = TileSystem.tileXYToQuadKey(1666, 2046, 12);
// console.log(`QuadKey: ${quadKey}`);

// const { tileX, tileY, levelOfDetail } = TileSystem.quadKeyToTileXY(quadKey);
// console.log(
// 	`Tile coordinates from QuadKey: ${tileX}, ${tileY}, Zoom Level: ${levelOfDetail}`
// );
