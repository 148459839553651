import { APP_VERSION_SYS, APP_VERSION_V1 } from "@/api/constant";
import { ApiParameter, RequestApiMapType } from "@/api/type";
import request from "@/service";
import {
	VoyagePageParamsType,
	AddVoyageParams,
	GetVesselPageParams,
	GetCompanyPageParams,
	VoyageParamsType,
	GetVoyVesselParamsType,
	SaveVesCpTermParamsType,
	GetVesCpTermPageParamsType,
	DeleteVesCpTermParamsType
} from "./type";
import { CommonPageParamsType } from "@/types/common";

const enum ApiMap {
	getVoyagePageParameter,
	deleteVoyageParameter,
	addVoyageParameter,
	getVesselPageParameter,
	getCompanyPageParameter,
	getVoyageDetailsParameter,
	updateVoyageParameter,
	getVoyVesselParameter,
	saveVesCpTermParameter,
	getVesCpTermPageParameter,
	deleteVesCpTermParameter
}

export type ApiUrl = keyof typeof ApiMap;

const voyageApi: ApiParameter<ApiUrl> = {
	getVoyagePageParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/voyage/page"
	},
	deleteVoyageParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/voyage/"
	},
	addVoyageParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/voyage"
	},
	getVesselPageParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/vessel/selection"
	},
	getCompanyPageParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/company/selection"
	},
	getVoyageDetailsParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/voyage/"
	},
	updateVoyageParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/voyage"
	},
	getVoyVesselParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/voyage/vessel/"
	},
	saveVesCpTermParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/vessel-cp-term"
	},
	getVesCpTermPageParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/vessel-cp-term/page"
	},
	deleteVesCpTermParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/vessel-cp-term/"
	}
};

export const getVoyagePageApi = (params: VoyagePageParamsType) => {
	return request.request({
		...voyageApi.getVoyagePageParameter,
		params
	});
};

export const deleteVoyageApi = (id: string) => {
	return request.request({
		...voyageApi.deleteVoyageParameter,
		url: voyageApi.deleteVoyageParameter.url + id
	});
};

export const addVoyageApi = (params: AddVoyageParams) => {
	return request.request({
		...voyageApi.addVoyageParameter,
		data: params
	});
};

export const getVesselPageApi = (params: GetVesselPageParams) => {
	return request.request({
		...voyageApi.getVesselPageParameter,
		params
	});
};

export const getCompanyPageApi = (params: GetCompanyPageParams) => {
	return request.request({
		...voyageApi.getCompanyPageParameter,
		params
	});
};

export const getVoyageDetailsApi = (id: string) => {
	return request.request({
		...voyageApi.getVoyageDetailsParameter,
		url: voyageApi.getVoyageDetailsParameter.url + id
	});
};

export const updateVoyageApi = (params: VoyageParamsType) => {
	return request.request({
		...voyageApi.updateVoyageParameter,
		data: params
	});
};

export const getVoyVesselApi = (params: GetVoyVesselParamsType) => {
	return request.request({
		...voyageApi.getVoyVesselParameter,
		url: voyageApi.getVoyVesselParameter.url + params.id
	});
};

export const saveVesCpTermApi = (params: SaveVesCpTermParamsType) => {
	return request.request({
		...voyageApi.saveVesCpTermParameter,
		data: params
	});
};

export const getVesCpTermPageApi = (params: GetVesCpTermPageParamsType) => {
	return request.request({
		...voyageApi.getVesCpTermPageParameter,
		params
	});
};

export const deleteVesCpTermApi = (params: DeleteVesCpTermParamsType) => {
	return request.request({
		...voyageApi.deleteVesCpTermParameter,
		url: voyageApi.deleteVesCpTermParameter.url + params.id
	});
};

export const voyagePageApiMap: RequestApiMapType<ApiUrl, VoyagePageParamsType> =
	{
		getVoyagePageApi,
		deleteVoyageApi,
		addVoyageApi,
		getVesselPageApi,
		getCompanyPageApi,
		getVoyageDetailsApi,
		updateVoyageApi,
		getVoyVesselApi,
		saveVesCpTermApi,
		getVesCpTermPageApi,
		deleteVesCpTermApi
	};
