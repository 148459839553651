import React, { FC } from "react";
import { NoonReportTypeFormProps } from "./type";
import { Select, FormItem } from "@/components";
import { noonReportTypeOptions } from "./source";
import style from "./index.module.less";
import classNames from "classnames";

const NoonReportTypeForm: FC<NoonReportTypeFormProps> = (props) => {
	const { value, onChange, showLabel = true, className } = props;

	return (
		<div className={classNames(className, style["noon-rt-form"])}>
			{showLabel ? (
				<FormItem className="noon-rt-form-item" label="Report Type" type="row">
					<Select
						className="noon-rt-select"
						onChange={(val: NoonReportTypeFormProps["value"]) => {
							onChange(val);
						}}
						options={noonReportTypeOptions}
						value={value}
					/>
				</FormItem>
			) : (
				<Select
					className="noon-rt-select"
					onChange={(val: NoonReportTypeFormProps["value"]) => {
						onChange(val);
					}}
					options={noonReportTypeOptions}
					value={value}
				/>
			)}
		</div>
	);
};

export default NoonReportTypeForm;
