import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { AddRouteType } from "@/api/leg/type";
const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");
	// DidMount

	return {
		loading
	};
};

export default useIndex;
