import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { LegReportAisGeoJson, LegReportMapRefType } from "../type";
import { routeSource } from "../source";

const useIndex = () => {
	const mapContainer = useRef<HTMLDivElement>(null);
	const mapboxGl = useRef<mapboxgl.Map>(null);
	const renderQueue = useRef<(() => void)[]>([]);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const mapboxglInit = () => {
		mapboxGl.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/voyagecentury2018/cln1634sd003g01rc1alr5qmp",
			accessToken:
				"pk.eyJ1Ijoidm95YWdlY2VudHVyeTIwMTgiLCJhIjoiY2xmOTM0NDJyMXV1MjQwb2NrNzFvZWJ3dSJ9.zyBT0Gd6HYyeywftD_XKtg",
			dragPan: true,
			center: { lng: 105.78, lat: 32.99 },
			attributionControl: false,
			preserveDrawingBuffer: true
		});
		mapboxGl?.current?.on("load", () => {
			loadSource();
			loadlayers();
			setIsLoaded(true);
			// getCurrentMapInfo();
			mapboxGl?.current?.setLayoutProperty("ports", "visibility", "none");
		});
	};

	const loadSource = () => {
		mapboxGl?.current?.addSource("report-line-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
	};

	const loadlayers = () => {
		mapboxGl?.current?.addLayer({
			id: routeSource["report-line-source"],
			source: "report-line-source",
			type: "line",
			layout: {
				"line-join": "round"
			},
			paint: {
				"line-color": [
					"case",
					["==", ["get", "goodWeather"], true],
					"#18D31F",
					"#000"
				],
				"line-width": 3
				// "line-dasharray": [4, 2]
			}
		});
	};

	const handleFlyTo = (features: LegReportAisGeoJson[]) => {
		const bounds = new mapboxgl.LngLatBounds();
		for (let featrue of features) {
			for (let condition of featrue?.geometry?.coordinates) {
				bounds?.extend(condition as unknown as [number, number]);
			}
		}

		mapboxGl?.current?.fitBounds(bounds, {
			padding: 20, // 可选：设置边距
			zoom: 2.5
		});
	};

	const handleAisItemsLoad: LegReportMapRefType["onAisItemsLoad"] = (items) => {
		console.log("items", items);
		debugger;
		if (!isLoaded) {
			renderQueue.current.push(() => {
				const currentSource = mapboxGl?.current?.getSource(
					"report-line-source"
				) as GeoJSONSource;
				currentSource?.setData(items);
				handleFlyTo(items.features);
			});
			return;
		}
		const currentSource = mapboxGl?.current?.getSource(
			"report-line-source"
		) as GeoJSONSource;
		currentSource?.setData(items);
		handleFlyTo(items.features);
	};

	const getCurrentMapInfo: LegReportMapRefType["getMapInfo"] = () => {
		return new Promise((resolve, reject) => {
			requestAnimationFrame(() => {
				const zoom = mapboxGl?.current?.getZoom(),
					center = mapboxGl?.current?.getCenter(),
					canvas = mapboxGl?.current?.getCanvas();
				const dataURL = canvas.toDataURL("image/png");

				// 将Data URL转换为文件对象
				const byteString = atob(dataURL.split(",")[1]);
				const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

				const arrayBuffer = new ArrayBuffer(byteString.length);
				const uint8Array = new Uint8Array(arrayBuffer);
				for (let i = 0; i < byteString.length; i++) {
					uint8Array[i] = byteString.charCodeAt(i);
				}

				const blob = new Blob([arrayBuffer], { type: mimeString });
				const file = new File([blob], "canvas-image.png", {
					type: mimeString
				});

				resolve({
					zoom,
					center,
					mapImage: file
				});
			});
		});
	};

	useEffect(() => {
		if (!mapboxGl.current) {
			mapboxglInit();
		}
		return () => {
			mapboxGl?.current?.remove();
			mapboxGl.current = null;
		};
	}, []);

	useEffect(() => {
		if (!isLoaded) return;
		while (renderQueue?.current?.length > 0) {
			const callback = renderQueue?.current?.shift();
			callback?.();
		}
	}, [isLoaded]);

	return {
		mapContainer,
		getCurrentMapInfo,
		handleAisItemsLoad
	};
};

export default useIndex;
