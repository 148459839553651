import { onRequest } from "@/api";
import { useState, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";

import {
	getLegBasicDetailParamsType,
	deleteLegParamsType
} from "@/api/leg/type";
import { Details } from "../type";
import { AddLegPortParameterType } from "@/api/leg/type";
import { RouteCommonMapRefType } from "../../../components";
const useIndex = (
	activeTab,
	loadBasicPorts: RouteCommonMapRefType["loadBasicPorts"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	// const { reminder } = useReminder();
	// const [loading, setLoading] = useState(false);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");
	const legType = queryParams.get("legType");

	const initPageData = () => {
		getLegBasicDetail({ id, voyageId, legType });
		getLegDetailById({ id });
	};

	// state
	const [details, setDetails] = useState<Details>({});
	const [GWClauseData, setGWClauseData] = useState([]);
	const [legAddDialogVisible, setLegAddDialogVisible] = useState(false);

	const [editLegParams, setEditLegParams] = useState<AddLegPortParameterType>(
		{}
	);
	// handle
	const handleDeleteLeg = () => {
		const params = [id];
		deleteLeg(params);
	};

	const handleCloseLegAdd = () => {
		setLegAddDialogVisible(false);
	};

	const handleOpenLegAdd = () => {
		setLegAddDialogVisible(true);
	};

	const convertToGWClauseData = (GWClauseItem) => {
		const GWClauseData = [
			{
				label: "Overall GW:",
				value: GWClauseItem?.overallGw ? "Yes" : "No"
				// colSpan: 3
			},
			{
				label: null,
				value: null
			},
			{
				label: null,
				value: null
			},
			{ label: "Day Hours:", value: GWClauseItem?.dayHours },
			{ label: "Proportion:", value: GWClauseItem?.proportion },
			{ label: "Consecutive:", value: GWClauseItem?.consecutive },
			{
				label: "No Extrapolation:",
				value: GWClauseItem?.noExtrapolation ? "Yes" : "No"
			},
			{
				label: "Abt Speed:",
				value: `${GWClauseItem.aboutSpeedFrom},${GWClauseItem.aboutSpeedTo} ${GWClauseItem.aboutSpeedUnit}`
			},
			{
				label: "Abt Cons:",
				value: `${GWClauseItem?.aboutConsFrom},${GWClauseItem?.aboutConsTo} %`
			},
			{ label: "BF:", value: GWClauseItem?.bf },
			{ label: "DSS:", value: GWClauseItem?.dss },
			{ label: "Sign Wave:", value: GWClauseItem?.signWaveValue },
			{
				label: "No Adverse Current:",
				value: GWClauseItem?.noAdverseCurrent ? "Yes" : "No"
			},
			{
				label: "No Favourable Current:",
				value: GWClauseItem?.noFavourableCurrent ? "Yes" : "No"
			},
			{ label: "Swell Wave:", value: GWClauseItem?.swellWaveValue },
			{
				label: "No Extrapolation:",
				value: GWClauseItem?.noExtrapolation ? "Yes" : "No"
			},
			{
				label: null,
				value: null
			},
			{ label: "Wind Wave:", value: GWClauseItem?.windWaveValue }
		];
		setGWClauseData(GWClauseData);
	};

	const saveEditLegParams = (data) => {
		const params: AddLegPortParameterType = {
			portRotationFOS: data?.portRotations,
			voyageLegFO: {
				voyageId: voyageId,
				id: data?.id,
				quotation: data?.quotation,
				legType: data?.legType
			}
		};
		setEditLegParams(params);
	};

	const handlePortTimeSave = (index: number, key: string, value: number) => {
		const newEditLegParams = { ...editLegParams };
		if (newEditLegParams.portRotationFOS) {
			newEditLegParams.portRotationFOS[index][key] = value;
		}
		editLegPort(newEditLegParams);
	};

	// API
	// API - getLegBasicDetail
	const getLegBasicDetailFront = () => {
		setDetails({});
	};
	const getLegBasicDetailSuccess = (data) => {
		const cpArr = data?.data?.cp?.map((item) => {
			return {
				...item,
				key: item.id
			};
		});
		if (data?.data?.cp) {
			data.data.cp = cpArr;
		}
		setDetails(data?.data);
		convertToGWClauseData(data?.data?.gwClause);
		loadBasicPorts(data?.data?.portRotation);
	};
	const getLegBasicDetailFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getLegBasicDetail = (params?: getLegBasicDetailParamsType) => {
		onRequest(
			"getLegBasicDetailApi",
			{
				...params
			},
			getLegBasicDetailFront,
			getLegBasicDetailSuccess,
			getLegBasicDetailFailed,
			dispatch,
			navigate
		);
	};
	// API - deleteLeg
	const deleteLegFront = () => {};
	const deleteLegSuccess = () => {
		reminder("success", "Delete Leg Success");
		navigate("/layout/leg");
	};
	const deleteLegFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const deleteLeg = (params: deleteLegParamsType) => {
		onRequest(
			"deleteLegApi",
			params,
			deleteLegFront,
			deleteLegSuccess,
			deleteLegFailed,
			dispatch,
			navigate
		);
	};
	// API - getLegDetailById
	const getLegDetailByIdFront = () => {};
	const getLegDetailByIdSuccess = (data) => {
		saveEditLegParams(data?.data);
	};
	const getLegDetailByIdFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getLegDetailById = (params) => {
		onRequest(
			"getLegDetailByIdApi",
			params,
			getLegDetailByIdFront,
			getLegDetailByIdSuccess,
			getLegDetailByIdFailed,
			dispatch,
			navigate
		);
	};
	// API - editLegPort
	const editLegPortFront = () => {};
	const editLegPortSuccess = () => {
		getLegBasicDetail({ id, voyageId, legType });
	};
	const editLegPortFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const editLegPort = (params) => {
		onRequest(
			"addLegPortApi",
			{
				...params
			},
			editLegPortFront,
			editLegPortSuccess,
			editLegPortFailed,
			dispatch,
			navigate
		);
	};
	useEffect(() => {
		if (activeTab !== "basic") {
			return;
		}
		initPageData();
	}, [activeTab]);
	return {
		details,
		voyageId,
		legAddDialogVisible,
		id,
		GWClauseData,
		editLegParams,
		handleDeleteLeg,
		handleCloseLegAdd,
		handleOpenLegAdd,
		initPageData,
		handlePortTimeSave
	};
};
export default useIndex;
