import React from "react";
import style from "./index.module.less";
import PersonalInfo from "./components/personalInfo";
import BasicInfo from "./components/basicInfo";
import useIndex from "./hook/useIndex";
import { Loading } from "@/components";

const PersonalCenter: React.FC<{}> = (props) => {
	const { loading, dataSource, handleChange, handleBack, getPersonalInfo } =
		useIndex();
	return (
		<div className={style["fleet-personal-center"]}>
			<Loading loading={loading} />
			<div className="personal-center-back" onClick={handleBack}>
				<span className="font_family fleet-back back-icon"></span>
				<span className="back-title">Back</span>
			</div>
			<div className="personal-center-content">
				<PersonalInfo data={dataSource} />
				<BasicInfo
					data={dataSource}
					onReset={getPersonalInfo}
					onChange={handleChange}
				/>
			</div>
		</div>
	);
};

export default PersonalCenter;
