import React from "react";
import style from "./index.module.less";
import {
	VoyBasics,
	RouteDetails,
	RouteSummary,
	Waypoint,
	RouteForecast
} from "./common";
import { VoyPlanReportProps } from "./type";

const VoyPlanReport: React.FC<VoyPlanReportProps> = (props) => {
	const { data } = props;

	return (
		<div id="voy-plan-report" className={style["voy-plan-report"]}>
			<div className="flag-no-break vp-logo">
				<img
					className="vp-logo-bg"
					src="/images/leg/plan/leg_plan_report_bg.svg"
				/>
				<img
					className="vp-logo-title"
					src="/images/leg/plan/leg_plan_report_title.svg"
				/>
			</div>

			{/* <div className="flag-no-break vp-content"> */}
			<VoyBasics data={data?.reportDetail} />
			<RouteDetails
				data={data?.reportDetail}
				summaryData={data?.reportRoutes}
			/>
			<RouteSummary data={data?.reportRoutes} />
			<Waypoint data={data?.wayPoints} />
			<RouteForecast data={data?.forecasts} />
			{/* </div> */}
		</div>
	);
};

export default VoyPlanReport;
