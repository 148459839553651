/**
 *
 * @param input number
 * @returns string
 * @author azuuuuuuuu
 * @description transfer number to thousandth string
 */
export const formatThousandthNumber = (
	input: number = 0,
	minimumFractionDigits: number = 2,
	maximumFractionDigits: number = 2
): string => {
	return input?.toLocaleString("en-US", {
		minimumFractionDigits: minimumFractionDigits,
		maximumFractionDigits: maximumFractionDigits
	});
};

export const getRetainDecimalsNumber = (input: number, digit: number) => {
	return +input?.toFixed(digit);
};

// 转换对象中的boolean类型属性为01
export const transformBooleanProperties = (
	obj: Record<string, any>
): Record<string, any> => {
	const transformedObj: Record<string, any> = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			if (typeof obj[key] === "boolean") {
				transformedObj[key] = obj[key] ? 1 : 0;
			} else {
				transformedObj[key] = obj[key];
			}
		}
	}

	return transformedObj;
};

/**
 * 保留小数
 * @param num
 * @param decimalPlaces
 * @returns
 */
export const formatNumber = (num: number, decimalPlaces: number): string => {
	let formattedNumber = num.toFixed(decimalPlaces);
	formattedNumber = parseFloat(formattedNumber).toString();
	return formattedNumber;
};
