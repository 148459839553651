import { CommonOptionType } from "@/types/common";
import { CospDiDtType, CospDiLoadType } from "./type";
import { FuelTableDataType } from "../../common/noonFuelForm";
import { NoonMainInfoFormType } from "../../common/noonMainInfoForm/type";
import { NoonNextPortFormType } from "../../common/noonNextPortForm/type";
import {
	CospPatiFormType,
	CospDiFormType,
	CospBasicDataType,
	CospIsoClassType
} from "./type";

export const initialCospFuelData: FuelTableDataType = [
	{
		id: 1,
		mt: "BROB",
		isNecessary: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 2,
		mt: "ISO CLASS",
		isNecessary: false,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	}
];

export const initialCospMiData: NoonMainInfoFormType = {
	vesselName: "",
	imo: "",
	callSign: ""
};

export const initialCospPatiData: CospPatiFormType = {
	departurePort: "",
	unlocode: "",
	cospDate: "",
	latitude: 0,
	longitude: 0
};

export const initialCospDiData: CospDiFormType = {
	foreDraft: "",
	aftDraft: "",
	midDraft: "",
	draftType: "SUMMER",
	load: "BALLAST",
	rollPeriod: "",
	gm: "",
	cargo: "",
	cargoWeight: "",
	displacement: "",
	distanceToGo: "",
	orderedSpeed: "",
	course: "",
	setRpm: ""
};

export const initialCospNpData: NoonNextPortFormType = {
	nextPort: "",
	unlocode: "",
	eta: ""
};

export const initialCospBasicData: CospBasicDataType = {
	id: null
};

export const cospDiDtTypeOptions: CommonOptionType<
	CospDiDtType,
	CospDiDtType
>[] = [
	{ label: "SUMMER", value: "SUMMER" },
	{ label: "TROPICAL", value: "TROPICAL" },
	{ label: "WINTER", value: "WINTER" }
];

export const cospDiLoadTypeOptions: CommonOptionType<
	CospDiLoadType,
	CospDiLoadType
>[] = [
	{ label: "BALLAST", value: "BALLAST" },
	{ label: "LADEN", value: "LADEN" }
];

export const cospIsoClassTypeOptions: CommonOptionType<
	CospIsoClassType,
	CospIsoClassType
>[] = [
	{ label: "other", value: "other" },
	{ label: "DMX", value: "DMX" },
	{ label: "DMA", value: "DMA" },
	{ label: "DMZ", value: "DMZ" },
	{ label: "DMB", value: "DMB" },
	{ label: "DMC", value: "DMC" },
	{ label: "RMA10", value: "RMA10" },
	{ label: "RMB30", value: "RMB30" },
	{ label: "RMD50", value: "RMD50" },
	{ label: "RME180", value: "RME180" },
	{ label: "RMG180", value: "RMG180" },
	{ label: "RMG380", value: "RMG380" },
	{ label: "RMG500", value: "RMG500" },
	{ label: "RMG700", value: "RMG700" }
];
