import { Select } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import { CommonPortSelectProps } from "./type";
import { CommonLatitudeSelect, CommonPortPopup } from "./components";

const CommonPortSelect: FC<CommonPortSelectProps> = ({
	onFinish,
	activeItems,
	primaryKey,
	showKey,
	showCount,
	className,
	disabled,
	placeholder,
	allowClear
}) => {
	const {
		status,
		loading,
		list,
		open,
		loadMore,
		closePopup,
		focusPopup,
		searchValue,
		tagRender,
		handleSearch,
		handleItemSelect,
		handleLatitudeClose,
		selectValue,
		handleEnter
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled);

	return (
		<Select
			showSearch
			disabled={disabled}
			allowClear={allowClear}
			onClear={() => {
				onFinish?.(null, "del");
			}}
			className={className}
			placeholder={placeholder}
			value={selectValue}
			showCount={showCount}
			popupMatchSelectWidth={false}
			onDropdownVisibleChange={closePopup}
			tagRender={tagRender}
			mode={Array?.isArray(activeItems) ? "multiple" : undefined}
			open={open}
			onInputKeyDown={handleEnter}
			// searchValue={searchValue}
			onSearch={handleSearch}
			onFocus={() => focusPopup()}
			searchValue={status === "num" ? searchValue : undefined}
			dropdownRender={() =>
				status === "num" ? (
					<CommonLatitudeSelect onClose={handleLatitudeClose} open={open} />
				) : (
					<CommonPortPopup
						loadMore={loadMore}
						list={list}
						onSelect={handleItemSelect}
						loading={loading}
					/>
				)
			}
		/>
	);
};

export default CommonPortSelect;
