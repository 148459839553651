import { RouteWayPointItemType } from "../types";

export function haversineDistance(
	point: Partial<RouteWayPointItemType>,
	otherPoint: Partial<RouteWayPointItemType>
) {
	const R = 6371; // 地球半径 (km)
	const dLat = toRad(point?.lat - otherPoint?.lat);
	const dLon = toRad(point?.lon - otherPoint?.lon);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(toRad(point?.lat)) *
			Math.cos(toRad(otherPoint?.lat)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return R * c;
}

function toRad(deg: number) {
	return deg * (Math.PI / 180);
}

export const getShortesPoints = (
	wayPoints: RouteWayPointItemType[],
	activePoint: Partial<RouteWayPointItemType>
) => {
	let pointItems = [...wayPoints];
	pointItems?.sort(
		(a, b) =>
			haversineDistance(activePoint, a) - haversineDistance(activePoint, b)
	);
	return pointItems?.[0]?.seq;
};