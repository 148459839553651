import { NoonReportGeoJson, NoonReportItemType } from "../../types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const generateNoonReportPoints = (
	items: NoonReportItemType[]
): NoonReportGeoJson[] => {
	return items?.map((item) => {
		return {
			type: "Feature",
			geometry: {
				coordinates: [item?.lon, item?.lat],
				type: "Point"
			},
			properties: {
				...item,
				formatTime: dayjs?.utc(item?.datetime)?.format("MMM-DD/HH:mm")
			}
		};
	});
};
