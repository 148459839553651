import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";

import {
	WaypointListType,
	WayPointShowInMapObjectType,
	WaypointType,
	WaypointTableProps
} from "../type";

import { useLegContext } from "@/pages/leg/business/details/store";
import { PlanType } from "../../../type";
import { RouteWayPointItemType } from "@/pages/leg/business/details/components";
const useIndex = (
	isFirstTable: boolean,
	planInfo: PlanType,
	resetPlanList: WaypointTableProps["resetPlanList"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const location = useLocation();
	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");

	const {
		route: { activePlan, isUpdateing, handleRoutePlanCrud, handleWayPointCrud }
	} = useLegContext();

	// state
	const [waypointEditDialogVisible, setWaypointEditDialogVisible] =
		useState<boolean>(false);
	const curSelcetedWaypointId = useRef(null);
	const [loading, setLoading] = useState(false);
	const activeWaypointList = useMemo(() => {
		return handleRoutePlanCrud("get", activePlan);
	}, [activePlan, isUpdateing]);
	const [waypointList, setWaypointList] = useState<WaypointListType>(null);
	const [wayPointShowInMapData, setWayPointShowInMapData] =
		useState<WayPointShowInMapObjectType>(null);
	const [activeWaypointRowId, setActiveWaypointRowId] = useState<string>();
	// handle
	const cancelEditRoute = (plainId: string) => {
		changeWappointDataInMap("hidden", plainId);
		changeWappointDataInMap("visible", plainId, activeWaypointList);
	};
	const changeWappointDataInMap = (
		type: "visible" | "hidden" | "edit",
		planId: string,
		items?: RouteWayPointItemType[]
	) => {
		switch (type) {
			case "visible":
				handleRoutePlanCrud("visible", planId, items);
				break;
			case "hidden":
				handleRoutePlanCrud("hidden", planId);
				break;
			case "edit":
				handleRoutePlanCrud("edit", planId, items);
				break;
			default:
				return;
		}
	};
	const handleSavePlanName = (value: string, id: string) => {
		updatePlanName({
			id,
			name: value
		});
	};
	const handleChangeWaypointNav = (waypointId, navValue, waypointItem) => {
		updateWaypointNav(
			{
				id: waypointId,
				nav: navValue
			},
			{
				...waypointItem,
				nav: navValue
			}
		);
	};
	const handleCloseWaypointEditDialog = () => {
		setWaypointEditDialogVisible(false);
	};
	const handleOpenWaypointEditDialog = () => {
		setWaypointEditDialogVisible(true);
	};
	const initWaypointList = () => {
		getWaypointList({ id: planInfo?.id });
		// resetPlanList();
	};
	const handleFocusWaypoint = (id: string) => {
		const targetElement = document.getElementById(id);
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
		}
		setActiveWaypointRowId(id);
	};
	// API
	// API - updatePlanName
	const updatePlanNameFront = () => {
		setLoading(true);
	};
	const updatePlanNameSuccess = (data) => {
		// resetPlanList && resetPlanList();
		setLoading(false);
	};
	const updatePlanNameFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const updatePlanName = (params: { name: string; id: string }) => {
		onRequest(
			"updatePlanNameApi",
			params,
			updatePlanNameFront,
			updatePlanNameSuccess,
			updatePlanNameFailed,
			dispatch,
			navigate
		);
	};
	// API - updateWaypointNav
	const updateWaypointNavFront = () => {
		setLoading(true);
	};
	const updateWaypointNavSuccess = (waypointItem) => {
		handleWayPointCrud("update", waypointItem);
		setLoading(false);
	};
	const updateWaypointNavFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const updateWaypointNav = (
		params: { id: string; nav: string },
		waypointItem
	) => {
		onRequest(
			"updateWaypointNavApi",
			params,
			updateWaypointNavFront,
			(data) => {
				const { totalDist, wayPoint } = data.data;
				resetPlanList({ planId: wayPoint.planId, distance: totalDist });
				updateWaypointNavSuccess(waypointItem);
			},
			updateWaypointNavFailed,
			dispatch,
			navigate
		);
	};
	// API - getWaypointList
	const getWaypointListFront = () => {
		setLoading(true);
	};
	const getWaypointListSuccess = (data, planId) => {
		setLoading(false);
		setWaypointList({ planId, list: data?.data || [] });
		// 每次请求展开或更新waypointList, 同步到地图
		changeWappointDataInMap(
			wayPointShowInMapData?.[planId] ? "edit" : "visible",
			planId,
			data?.data
		);
	};
	const getWaypointListFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getWaypointList = (params: { id: string }) => {
		onRequest(
			"getWaypointListApi",
			params,
			getWaypointListFront,
			(data) => {
				getWaypointListSuccess(data, params?.id);
			},
			getWaypointListFailed,
			dispatch,
			navigate
		);
	};

	// API - getWaypointToShow
	const getWaypointToShowFront = () => {
		setLoading(true);
	};
	const getWaypointToShowSuccess = (data, planId) => {
		// 每次请求代表需要将数据展示到地图
		data?.data && changeWappointDataInMap("visible", planId, data?.data);
		setLoading(false);
	};
	const getWaypointToShowFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getWaypointToShow = (params: { id: string }) => {
		onRequest(
			"getWaypointListApi",
			params,
			getWaypointToShowFront,
			(data) => {
				getWaypointToShowSuccess(data, params?.id);
			},
			getWaypointToShowFailed,
			dispatch,
			navigate
		);
	};
	// DidMount
	// 点击展开wayPoints， 地图同步wayPoints编辑状态
	useEffect(() => {
		// 编辑
		if (waypointList?.planId) {
			changeWappointDataInMap("edit", waypointList?.planId, waypointList?.list);
		}
	}, [waypointList]);
	return {
		waypointEditDialogVisible,
		curSelcetedWaypointId,
		loading,
		activeWaypointList,
		activeWaypointRowId,
		cancelEditRoute,
		handleSavePlanName,
		handleChangeWaypointNav,
		handleOpenWaypointEditDialog,
		handleCloseWaypointEditDialog,
		initWaypointList,
		setActiveWaypointRowId,
		handleFocusWaypoint
	};
};

export default useIndex;
