import React from "react";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { Icon, Popover, Toast } from "@/components";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { VoyPlanTableItemType, VoyPlanTableProps } from "../type";

const useIndex = (props: VoyPlanTableProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();

	const vpTableOpColumns: ColumnsType<VoyPlanTableItemType> = [
		{
			title: "",
			align: "left",
			width: 120,
			render(value, record, index) {
				return (
					<div className="vp-table-op-column">
						{record.syncStatus ? (
							<Popover
								render={(setIsOpen) => {
									return (
										<Toast
											label="Do you want to cancel synchronization?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												handleSyncStatusChange(record.id);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<Icon
									size={20}
									type="icon-date-synchronized"
									color="#1bae23"
								></Icon>
							</Popover>
						) : (
							<Popover
								render={(setIsOpen) => {
									return (
										<Toast
											label="Synchronize or not?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												handleSyncStatusChange(record.id);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<Icon
									size={20}
									color="#333"
									type="icon-a-date-notsynchronized"
								></Icon>
							</Popover>
						)}

						<Icon
							size={20}
							type="icon-report"
							onClick={() => handlePreviewClick(record.id)}
						/>

						<Popover
							render={(setIsOpen) => {
								return (
									<Toast
										label="Are you sure delete this item?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											handleDeleteClick(record.id);
											setIsOpen(false);
										}}
									/>
								);
							}}
						>
							<Icon size={20} type="icon-delete" />
						</Popover>
					</div>
				);
			}
		}
	];

	const handlePreviewClick = (reportId: string) => {
		props.onPreviewClick(reportId);
	};

	const handleDeleteClick = (reportId: string) => {
		props.onDeleteClick(reportId);
	};

	const updatePlanSyncStatusFront = () => {};
	const updatePlanSyncStatusSuccess = () => {
		props.onSyncStatusChange();
	};
	const updatePlanSyncStatusError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	// 同步状态处理
	const handleSyncStatusChange = (id: string) => {
		onRequest(
			"updatePlanSyncStatusApi",
			{ id },
			updatePlanSyncStatusFront,
			updatePlanSyncStatusSuccess,
			updatePlanSyncStatusError,
			dispatch,
			navigate
		);
	};

	return { vpTableOpColumns };
};

export default useIndex;
