import CryptoJS from "crypto-js";
import { StorageKeyMap, StorageType } from "./types";

// 十六位十六进制数作为密钥
const SECRET_KEY = CryptoJS.enc.Utf8.parse("3333e6e143439161");
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse("e3bbe7e3ba84431a");

const config = {
	type: "localStorage", // 本地存储类型 localStorage sessionStorage
	prefix: "fleet_royage_0.0.1", // 名称前缀 建议：项目名 + 项目版本
	expire: 0, //过期时间 单位：秒
	isEncrypt: true // 默认加密 为了调试方便, 开发过程中可以不加密
};

// 判断是否支持 Storage
export const isSupStorage = () => {
	if (!window) {
		throw new Error("当前环境非浏览器，无法消费全局window实例。");
	}
	if (!window.localStorage) {
		throw new Error("当前环境非无法使用localStorage");
	}
	if (!window.sessionStorage) {
		throw new Error("当前环境非无法使用sessionStorage");
	}

	return typeof Storage !== "undefined" ? true : false;
};

// 设置 setStorage
export const setStorage = (key, value, expire = 0, isEncrypt?: boolean) => {
	if (value === "" || value === null || value === undefined) {
		value = null;
	}

	if (isNaN(expire) || expire < 0) throw new Error("Expire must be a number");

	// console.log("111", JSON.stringify(expire));
	expire = (expire ? expire : config.expire) * 1000;
	// console.log("2222", JSON.stringify(expire));
	let data = {
		value: value, // 存储值
		time: Date.now(), //存值时间戳
		expire: expire // 过期时间
	};
	const encryptString = config.isEncrypt
		? encrypt(JSON.stringify(data))
		: JSON.stringify(data);
	window[config.type].setItem(autoAddPrefix(key), encryptString);
};

// 获取 getStorage
export const getStorage = (key) => {
	let value = null;
	key = autoAddPrefix(key);
	// key 不存在判断
	if (
		!window[config.type].getItem(key) ||
		JSON.stringify(window[config.type].getItem(key)) === "null"
	) {
		return null;
	}
	// 优化 持续使用中续期
	const storage = config.isEncrypt
		? JSON.parse(decrypt(window[config.type].getItem(key)))
		: JSON.parse(window[config.type].getItem(key));
	const nowTime = Date.now();
	// 过期删除
	if (storage.expire && storage.expire < nowTime - storage.time) {
		removeStorage(key);
		return null;
	} else {
		// // 未过期期间被调用 则自动续期 进行保活
		// setStorage(autoRemovePrefix(key), storage.value);
		if (isJson(storage.value)) {
			value = JSON.parse(storage.value);
		} else {
			value = storage.value;
		}
		return value;
	}
};

// 是否存在 hasStorage
export const hasStorage = (key) => {
	key = autoAddPrefix(key);
	let arr = getStorageAll().filter((item) => {
		return item.key === key;
	});
	return arr.length ? true : false;
};

// 获取所有key
export const getStorageKeys = () => {
	let items: any = getStorageAll();
	let keys: any = [];
	for (let index = 0; index < items.length; index++) {
		keys.push(items[index].key);
	}
	return keys;
};

// 根据索引获取key
export const getStorageForIndex = (index) => {
	return window[config.type].key(index);
};

// 获取localStorage长度
export const getStorageLength = () => {
	return window[config.type].length;
};

// 获取全部 getAllStorage
export const getStorageAll = () => {
	const len = getStorageLength(); // 获取长度
	let arr: any = []; // 定义数据集
	for (let i = 0; i < len; i++) {
		const key = window[config.type].key(i);
		// 获取key 索引从0开始
		const getKey = autoRemovePrefix(key);
		// 获取key对应的值
		const storage = config.isEncrypt
			? JSON.parse(decrypt(window[config.type].getItem(key)))
			: JSON.parse(window[config.type].getItem(key));

		const nowTime = Date.now();
		if (storage.expire && nowTime - storage.time > storage.expire) {
			removeStorage(getKey);
		} else {
			let getVal = storage.value;
			// console.log(Object.prototype.toString.call(value));
			if (isJson(getVal)) {
				getVal = JSON.parse(getVal);
			}
			// 放进数组
			arr.push({ key: getKey, val: getVal });
		}
	}
	return arr;
};

// 删除 removeStorage
export const removeStorage = (key) => {
	window[config.type].removeItem(autoAddPrefix(key));
};

// 清空 clearStorage
export const clearStorage = () => {
	window[config.type].clear();
};

export const logoutStorage = () => {
	const userInfo = getStorage(StorageKeyMap["user_info"]);
	const remember = getStorage(StorageKeyMap["remember"]);
	const vmSearchKeys = getStorage(StorageKeyMap.vm_search_keys);
	const aisSearchKeys = getStorage(StorageKeyMap.ais_search_keys);
	window[config.type].clear();
	setStorage(StorageKeyMap["user_info"], {
		username: userInfo?.username,
		password: remember ? userInfo?.password : ""
	});
	setStorage(StorageKeyMap["remember"], remember);
	setStorage(StorageKeyMap.vm_search_keys, vmSearchKeys);
	setStorage(StorageKeyMap.ais_search_keys, aisSearchKeys);
};

// 判断是否可用 JSON.parse
export const isJson = (value) => {
	if (Object.prototype.toString.call(value) === "[object String]") {
		try {
			const obj = JSON.parse(value);
			const objType = Object.prototype.toString.call(obj);
			return objType === "[object Object]" || objType === "[object Array]";
		} catch (e) {
			// console.log('error：' + value + '!!!' + e);
			return false;
		}
	}
	return false;
};

// 名称前自动添加前缀
const autoAddPrefix = (key) => {
	const prefix = config.prefix ? config.prefix + "_" : "";
	return prefix + key;
};

// 移除已添加的前缀
const autoRemovePrefix = (key) => {
	const len = config.prefix ? config.prefix.length + 1 : "";
	return key.substr(len);
};

/**
 * 加密方法
 * @param data
 * @returns {string}
 */
export const encrypt = (data) => {
	if (typeof data === "object") {
		try {
			data = JSON.stringify(data);
		} catch (error) {
			console.log("encrypt error:", error);
		}
	}
	const dataHex = CryptoJS.enc.Utf8.parse(data);
	const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
		iv: SECRET_IV,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	return encrypted.ciphertext.toString();
};

/**
 * 解密方法
 * @param data
 * @returns {string}
 */
export const decrypt = (data) => {
	const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
	const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
	const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
		iv: SECRET_IV,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
	return decryptedStr.toString();
};

export const cacheStorage: StorageType = {
	set: setStorage,
	get: getStorage,
	getAll: getStorageAll,
	getLen: getStorageLength,
	isSub: isSupStorage,
	isJson: isJson,
	has: hasStorage,
	del: removeStorage,
	clear: clearStorage,
	logout: logoutStorage
};

export default {
	install(window) {
		// 挂载全局
		if (!window.$storage) {
			window.$storage = {
				set: setStorage,
				get: getStorage,
				getAll: getStorageAll,
				getLen: getStorageLength,
				isSub: isSupStorage,
				isJson: isJson,
				has: hasStorage,
				del: removeStorage,
				clear: clearStorage,
				logout: logoutStorage
			};
		} else {
			window.$storage.set = setStorage;
			window.$storage.get = getStorage;
			window.$storage.getAll = getStorageAll;
			window.$storage.getLen = getStorageLength;
			window.$storage.isSub = isSupStorage;
			window.$storage.isJson = isJson;
			window.$storage.has = hasStorage;
			window.$storage.del = removeStorage;
			window.$storage.clear = clearStorage;
		}

		window.onerror = function (msg, url, lineNo, columnNo, error) {
			// 创建一个新的错误对象
			const errObj = {
				message: msg,
				url: url,
				lineNo: lineNo,
				columnNo: columnNo,
				stackTrace: error.stack
			};

			// 将错误信息序列化为字符串
			const errStr = JSON.stringify(errObj);

			// 将错误信息写入文件
			const filename = "error-" + Date.now() + ".log";
			const fileUrl = "./" + filename;
			const blob = new Blob([errStr], { type: "text/plain" });
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = filename;
			link.click();

			console.log("errObj", errObj);

			// 阻止浏览器默认行为，防止异常继续传递
			return false;
		};
	}
};
