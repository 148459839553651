import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "@/hooks";
import { cacheStorage } from "@/storage";

const PublicRoute: React.FC<{
	[propName: string]: any;
}> = ({ ...rest }) => {
	const access_token = cacheStorage.get("access_token");
	const _access_token = useAppSelector((state) => state.UserStore.access_token);
	return access_token || _access_token ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
