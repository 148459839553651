import { PageRouterProps } from "@/router";
import React from "react";
import { default as LegDetailsNoon } from "./business/noon";
import { LegDetailsBasic } from "./business/basic";
import { LegDetailsRouteAIS } from "./business/ais";
import { LegDetailsRoute } from "./business/route";
import { default as LegDetailsPlan } from "./business/plan";
import { Report } from "./business/report";

export const legDetailsRouters: PageRouterProps[] = [
	{
		index: true,
		path: "basic",
		element: <LegDetailsBasic />,
		key: "/layout/legDetails/basic",
		label: "Basic"
	},
	{
		path: "route",
		element: <LegDetailsRoute />,
		key: "/layout/legDetails/route",
		label: "Route"
	},
	{
		path: "noon",
		element: <LegDetailsNoon />,
		key: "/layout/legDetails/noon",
		label: "Noon"
	},
	{
		path: "plan",
		element: <LegDetailsPlan />,
		key: "/layout/legDetails/plan",
		label: "Plan"
	},
	{
		path: "report",
		element: <Report />,
		key: "/layout/legDetails/report",
		label: "Per."
	},
	{
		path: "ais",
		element: <LegDetailsRouteAIS />,
		key: "/layout/legDetails/ais",
		label: "AIS"
	}
];
