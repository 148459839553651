import React, { forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { WayPointMapRefType, WayPointMapProps } from "./type";
import useIndex from "./hooks/useIndex";

const WayPointMap = forwardRef<WayPointMapRefType, WayPointMapProps>(
	({}, ref) => {
		const {
			mapContainer,
			handleWayPointsLoad,
			handleWayPointsVisible,
			handleWayPointsFocus,
			handleWayPointsLayoutVisible,
			getCurrentMapInfo
		} = useIndex();

		useImperativeHandle(ref, () => {
			return {
				onWayPointsLoad: handleWayPointsLoad,
				onWayPointsVisible: handleWayPointsVisible,
				onWayPointFocus: handleWayPointsFocus,
				getMapInfo: getCurrentMapInfo,
				onWayPointsLayoutVisible: handleWayPointsLayoutVisible
			};
		});

		return <div className={style["wayPoint-map"]} ref={mapContainer}></div>;
	}
);

export default WayPointMap;
