import { useState, useMemo, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { RouteInfoType } from "@/pages/leg/business/details/business/route/type";
import { VoyCpSpdTableItemType } from "@/pages/voy/common/cpSpd/type";
import {
	CoordinateType,
	AddDevReportDialogProps,
	DevTimeRangeType
} from "../type";
import { initialCoordinate } from "../source";

const useIndex = (onConfirm: AddDevReportDialogProps["onConfirm"]) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [searchParams] = useSearchParams();

	const [startCoordinate, setStartCoordinate] =
		useState<CoordinateType>(initialCoordinate);
	const [endCoordinate, setEndCoordinate] =
		useState<CoordinateType>(initialCoordinate);
	// const []
	const [currentRouteId, setCurrentRouteId] = useState<string>("");
	const [routeList, setRouteList] = useState<RouteInfoType[]>([]);
	const [currentCpId, setCurrentCpId] = useState<string>("");
	const [voyCpSpdTableData, setVoyCpSpdTableData] = useState<
		VoyCpSpdTableItemType[]
	>([]);
	const [timeRange, setTimeRange] = useState<DevTimeRangeType>({
		start: null,
		end: null
	});

	console.log("检查cp数据：", voyCpSpdTableData);

	const routeOptions = useMemo(() => {
		return routeList.map((route) => {
			let labelStr = "";
			route.portName.forEach((item, index) => {
				labelStr += `[${index + 1}] ${item} ~`;
			});
			return {
				label: labelStr.slice(0, -1),
				value: route.id
			};
		});
	}, [routeList]);

	const cpOptions = useMemo(() => {
		return voyCpSpdTableData.map((item) => {
			return {
				label: `${item.speedCategory} ${item.speed}kts ${item.foConsumption}mt ${item.dgoConsumption}mt`,
				value: item.id
			};
		});
	}, [voyCpSpdTableData]);

	// const null = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleStartTimeChange = (val: number) => {
		setStartCoordinate((prev) => {
			return { ...prev, dateTime: val };
		});

		if (val) {
			onRequest(
				"getDevReportPositionStartApi",
				{
					startTime: val,
					legId: searchParams.get("id")
				},
				null,
				getDevReportPositionStartSuccess,
				handleRequestError,
				dispatch,
				navigate
			);
		}
	};

	const getDevReportPositionStartSuccess = (data) => {
		const { lat, lon } = data.data;
		setStartCoordinate((prev) => {
			return { ...prev, lat, lon };
		});
	};

	const handleEndTimeChange = (val: number) => {
		setEndCoordinate((prev) => {
			return { ...prev, dateTime: val };
		});

		if (val) {
			onRequest(
				"getDevReportPositionEndApi",
				{
					startTime: val,
					legId: searchParams.get("id")
				},
				null,
				getDevReportPositionEndSuccess,
				handleRequestError,
				dispatch,
				navigate
			);
		}
	};

	const handleCoordinateChange = (
		type: "start" | "end",
		latlngType: "lat" | "lng",
		val: number
	) => {
		if (type === "start" && latlngType === "lat") {
			setStartCoordinate((prev) => ({ ...prev, lat: val }));
			return;
		}
		if (type === "start" && latlngType === "lng") {
			setStartCoordinate((prev) => ({ ...prev, lon: val }));
			return;
		}
		if (type === "end" && latlngType === "lat") {
			setEndCoordinate((prev) => ({ ...prev, lat: val }));
			return;
		}
		if (type === "end" && latlngType === "lng") {
			setEndCoordinate((prev) => ({ ...prev, lon: val }));
			return;
		}
	};

	const getDevReportPositionEndSuccess = (data) => {
		const { lat, lon } = data.data;
		setEndCoordinate((prev) => {
			return { ...prev, lat, lon };
		});
	};

	const getRouteInfo = () => {
		onRequest(
			"getRouteInfoApi",
			{
				legId: searchParams.get("id")
			},
			null,
			getRouteInfoSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const getRouteInfoSuccess = (data) => {
		console.log("123看看数据", data);
		setRouteList(data?.data || []);
	};

	const getVoyageDetails = () => {
		onRequest(
			"getVoyageDetailsApi",
			searchParams.get("voyageId"),
			null,
			getVoyageDetailsSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const getVoyageDetailsSuccess = (data) => {
		setVoyCpSpdTableData(data.data.cpList);
	};

	const getDevTimeRange = () => {
		onRequest(
			"getDevTimeRangeApi",
			{ id: searchParams.get("id") },
			null,
			getDevTimeRangeSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const getDevTimeRangeSuccess = (res) => {
		setTimeRange({ start: res.data?.start, end: res.data?.end });
	};

	const handleRouteSelect = (val: string) => {
		setCurrentRouteId(val);
	};

	const handleCpSelect = (val: string) => {
		setCurrentCpId(val);
	};

	const handleConfirmClick = () => {
		const data = {
			start: startCoordinate,
			end: endCoordinate,
			routeId: currentRouteId,
			cpId: currentCpId,
			legId: searchParams.get("id")
		};
		onConfirm(data);
	};

	useEffect(() => {
		getRouteInfo();
		getVoyageDetails();
		getDevTimeRange();
	}, []);

	return {
		startCoordinate,
		endCoordinate,
		handleStartTimeChange,
		handleEndTimeChange,
		currentRouteId,
		routeOptions,
		handleRouteSelect,
		currentCpId,
		handleCpSelect,
		cpOptions,
		handleConfirmClick,
		handleCoordinateChange,
		timeRange
	};
};

export default useIndex;
