import React from "react";
import style from "./index.module.less";

const PdfContainer: React.FC<{ url: string }> = ({ url }) => {
	return (
		<div className={style["fp-pdf-container"]}>
			<iframe src={url}></iframe>
		</div>
	);
};

export default PdfContainer;
