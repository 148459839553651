import { APP_VERSION_SYS } from "@/api/constant";
import { ApiParameter, RequestApiMapType } from "@/api/type";
import request from "@/service";
import {
	PersonalCenterParamsType,
	getPersonalInfoParams,
	savePersonalInfoParams,
	updatePersonalAvatarParams,
	updatePersonalPwdParams
} from "./type";

const enum ApiMap {
	getPersonalInfoParameter,
	savePersonalInfoParameter,
	updatePersonalPwdParameter,
	updatePersonalAvatarParameter
}

export type ApiUrl = keyof typeof ApiMap;

const personalApi: ApiParameter<ApiUrl> = {
	getPersonalInfoParameter: {
		method: "get",
		url: APP_VERSION_SYS + "/system/user/profile"
	},
	savePersonalInfoParameter: {
		method: "put",
		url: APP_VERSION_SYS + "/system/user/profile"
	},
	updatePersonalAvatarParameter: {
		method: "post",
		url: APP_VERSION_SYS + "/system/user/profile/avatar"
	},
	updatePersonalPwdParameter: {
		method: "put",
		url: APP_VERSION_SYS + "/system/user/profile/updatePwd"
	}
};

export const getPersonalInfoApi = (params: getPersonalInfoParams) => {
	return request.request({
		...personalApi.getPersonalInfoParameter
	});
};

export const savePersonalInfoApi = (params: savePersonalInfoParams) => {
	return request.request({
		...personalApi.getPersonalInfoParameter,
		data: params
	});
};

export const updatePersonalPwdApi = (params: updatePersonalPwdParams) => {
	return request.request({
		...personalApi.updatePersonalPwdParameter,
		params
	});
};

export const updatePersonalAvatarApi = (params: updatePersonalAvatarParams) => {
	return request.request({
		...personalApi.updatePersonalAvatarParameter
	});
};
export const personalApiMap: RequestApiMapType<
	ApiUrl,
	PersonalCenterParamsType
> = {
	getPersonalInfoApi,
	savePersonalInfoApi,
	updatePersonalPwdApi,
	updatePersonalAvatarApi
};
