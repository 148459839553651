import React from "react";
import { formatNumber } from "@/tools/amount";
import style from "./index.module.less";

interface Column {
	label: string;
	index: string;
	fixed?: number;
}

interface DataItem {
	[key: string]: number;
}

interface ReportTableProps {
	columns: Column[];
	dataSource: DataItem[];
}

const ReportTable: React.FC<ReportTableProps> = ({ columns, dataSource }) => {
	return (
		<div className={style["report-table"]}>
			<table>
				<thead>
					<tr>
						{columns.map((column, colIndex) => (
							<th key={column.index} className="header-cell">
								{<div className="header-label">{column.label}</div>}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{dataSource.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{columns.map((column) => {
								if (column.fixed) {
									return (
										<td key={column.index}>
											{formatNumber(row[column.index], column.fixed)}
											{/* {Number(row[column.index]).toFixed(column.fixed)} */}
										</td>
									);
								}
								return <td key={column.index}>{row[column.index]}</td>;
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default ReportTable;
