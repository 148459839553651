import { CommonOptionType } from "@/types/common";
import { NoonReportTypeFormProps } from "./type";

export const noonReportTypeOptions: CommonOptionType<
	NoonReportTypeFormProps["value"],
	NoonReportTypeFormProps["value"]
>[] = [
	{ label: "COSP", value: "COSP" },
	{ label: "Noon Report", value: "Noon Report" },
	{ label: "EOSP", value: "EOSP" },
	{ label: "Stoppage", value: "Stoppage" },
	{ label: "Speed Change", value: "Speed Change" }
];
