import { useMemo, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useReminder, useAppDispatch } from "@/hooks";
import { onRequest } from "@/api";
import { Details as LegDetailsType } from "../../basic/type";
import { LegDetailsMenuItemType } from "../../../components/siderMenu/type";
import { VoyPlanTableType } from "../common/voyPlanTable/type";

const useIndex = (activeTab: LegDetailsMenuItemType) => {
	const [searchParams] = useSearchParams();
	const { reminder } = useReminder();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	// query数据
	const queryData = useMemo(() => {
		return {
			id: searchParams.get("id"),
			legType: searchParams.get("legType"),
			voyageId: searchParams.get("voyageId")
		};
	}, []);

	const [legDetailsData, setLegDetailsData] = useState<LegDetailsType>(null);

	const [voyPlanTableListData, setVoyPlanTableListData] = useState<
		VoyPlanTableType[]
	>([]);

	// 预览pdf数据ID
	const [previewPdfId, setPreviewPdfId] = useState<string>("");

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleGetLegBasicDetailSuccess = (res) => {
		setLegDetailsData(res.data);
	};

	const handleGetReportPlanListSuccess = (res) => {
		setVoyPlanTableListData(res.data);
	};

	// 请求legBasicData
	const handleGetLegBasicDetail = () => {
		onRequest(
			"getLegBasicDetailApi",
			queryData,
			handleRequestFront,
			handleGetLegBasicDetailSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	// 请求VoyPlan表格数据
	const handleGetVoyPlanTableData = () => {
		onRequest(
			"getReportPlanListApi",
			{ id: queryData.id },
			// { id: "1828752578733826049" },
			handleRequestFront,
			handleGetReportPlanListSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeTab === "plan") {
			handleGetLegBasicDetail();
			handleGetVoyPlanTableData();
		}
	}, [activeTab]);

	// route plan dialog
	const [isHideRpDialog, setIsHideRpDialog] = useState<boolean>(true);

	// 关闭报告弹框
	const handleRpDialogClose = () => {
		setPreviewPdfId("");
		setIsHideRpDialog(true);
		handleGetVoyPlanTableData();
	};

	// 打开报告弹框
	const handleRpDialogOpen = (reportId: string) => {
		setPreviewPdfId(reportId);
		setIsHideRpDialog(false);
	};

	// 删除VoyPlan
	const handleVoyPlanDelete = (reportId: string) => {
		console.log("删除VoyPlan", reportId);
		onRequest(
			"delReportPlanApi",
			{ id: reportId },
			handleRequestFront,
			handleDelRouteSimulationSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};
	// 删除VoyPlan成功
	const handleDelRouteSimulationSuccess = (res) => {
		console.log("删除VoyPlan成功", res);
		handleGetVoyPlanTableData();
	};

	return {
		queryData,
		legDetailsData,
		isHideRpDialog,
		handleRpDialogClose,
		handleRpDialogOpen,
		handleVoyPlanDelete,
		voyPlanTableListData,
		previewPdfId,
		handleGetVoyPlanTableData
	};
};

export default useIndex;
