import React, { FC } from "react";
import { FleetInput, Select } from "@/components";
import { LatLongInputProps } from "./type";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";
import classNames from "classnames";

const LatLongInput: FC<LatLongInputProps> = (props) => {
	const {
		degree,
		minute,
		unit,
		unitOptions,
		handleDegreeChange,
		handleMinuteChange,
		handleUnitChange
	} = useIndex(props);

	return (
		<div className={classNames(props.className, style["lat-long-input"])}>
			<FleetInput
				type="number"
				value={degree}
				onChange={(val: number) => {
					handleDegreeChange(val);
				}}
			/>
			<span className="degree-unit">°</span>
			<FleetInput
				type="number"
				value={minute}
				onChange={(val: number) => {
					handleMinuteChange(val);
				}}
			/>
			<span className="minute-unit">′</span>
			<Select
				className="lat-long-unit"
				value={unit}
				options={unitOptions}
				onChange={(val: string) => {
					handleUnitChange(val);
				}}
			/>
		</div>
	);
};

export default LatLongInput;
