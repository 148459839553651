import { MouseEventHandler, useRef, useState } from "react";

import { SimulationSelectedInfoType } from "../business/route/type";
import { SimulationForecastTableRefType } from "../business/route/components/simulationForecastTable/type";

const useForecastIndex = () => {
	const [forecastVisible, setForecastVisible] = useState(false);
	const [forecastItem, setForecastItem] =
		useState<SimulationSelectedInfoType>();
	const forecastRef = useRef<SimulationForecastTableRefType>(null);
	const handleCloseForecast = () => {
		setForecastVisible(false);
	};
	const handleOpenForecast = () => {
		setForecastVisible(true);
	};

	return {
		forecastVisible,
		forecastItem,
		forecastRef,
		setForecastItem,
		handleOpenForecast,
		handleCloseForecast
	};
};

export default useForecastIndex;
