import { CommonOptionType } from "@/types/common";
import { IconProps } from "@/components/Icon/interface";

export type RouteCommonMeteoType =
	| "Wind"
	| "Wave"
	| "Swell"
	| "Current"
	| "Typhoon"
	| "Pressure";

export enum RouteCommonToolbarMeteoMap {
	Wind = "wind",
	Wave = "wave",
	Swell = "swell1",
	Current = "current",
	Typhoon = "typhoon",
	Pressure = "pressure"
}

export enum RouteCommonToolbarMeteoSwitchMap {
	On = "on",
	Off = "off"
}

export type RouteCommonToolbarControllKeys =
	| "Ports"
	| "Loadline"
	| "Eca"
	| "War";

export type RouteCommonRangingControllTabType = "straight" | "circle";

export type RouteCommonToolbarControllItemType = CommonOptionType<
	"Ports" | "Load Line" | "ECA" | "Zones" | "ENC",
	RouteCommonToolbarControllKeys
> & {
	layerIds:
		| ["ports"]
		| ["load-line-point", "load-line-polygon"]
		| ["eca-zones-point", "eca-zones-polygon"]
		| ["war-zones-point", "war-zones-polygon"]
		| ["enc"];
};

export type RouteCommonToolbarProps = {
	meteoStatus: Record<RouteCommonMeteoType, RouteCommonToolbarMeteoSwitchMap>;
	onMeteoStatusChange: (value: RouteCommonMeteoType) => void;
	onControllChange: (
		layerIds: RouteCommonToolbarControllItemType["layerIds"],
		isShow: boolean
	) => void;
	onRangeSelect: (type: RouteCommonRangingControllTabType) => void;
	legendVisible: boolean;
	onLegendVisibleChange: () => void;
	onPointSelect: (value: boolean) => void;
};
