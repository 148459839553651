import { PageRouterProps } from "@/router";
import { LegList } from "./business";
import React from "react";

const legRouter: PageRouterProps[] = [
	{
		index: true,
		// path: "list",
		key: "/layout/leg/list",
		element: <LegList />,
		label: "Leg List"
	}
];

export default legRouter;
