import {
	Button,
	Dialog,
	Table,
	FleetDatePicker,
	FormItem,
	FleetInput,
	Icon
} from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { GraphDialogProps } from "./type";
import { Graph } from "../graph";
const GraphDialog: FC<GraphDialogProps> = ({
	visible,
	simulationId,
	onClose
}) => {
	const { loading } = useIndex();
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={onClose}
			loading={loading}
		>
			<div className={styles["graphDialog"]}>
				<div className="graphDialog-header">
					<div></div>
					<Icon type="icon-close2" className="close-btn" onClick={onClose} />
				</div>
				<div className="graphDialog-main fleet-nonScrollBar">
					<Graph simulationId={simulationId}></Graph>
				</div>
			</div>
		</Dialog>
	);
};

export default GraphDialog;
