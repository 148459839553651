import dayjs, { Dayjs } from "dayjs";

export const generateDates = () => {
	const currentDay = dayjs()?.startOf("day");
	const currentDates: Dayjs[] = [currentDay];
	for (let i = 1; i <= 9; i++) {
		currentDates?.push(currentDay?.add(i, "day")?.startOf("day"));
	}
	return currentDates;
};

export const getCurrentTimeStartOfHours = (currentTime: Dayjs) => {
	const startOfMinute = currentTime?.startOf("minute"),
		halfStartOfMinut = currentTime?.startOf("minute")?.set("minute", 30),
		startOfHour = currentTime?.startOf("hour");

	return startOfMinute?.isAfter(halfStartOfMinut) ? startOfHour?.add(1, "hour") : startOfHour;
};

export const calcTimeLineOffset = (time: Dayjs) => {
	const timeAreas = generateDates();
	const currentTime = getCurrentTimeStartOfHours(time);
	const currentIndex = timeAreas?.findIndex((item, index) => {
		return currentTime?.startOf("day")?.isSame(item?.startOf("day"));
	});
	const timeOffset = currentIndex === -1 ? 0 : currentTime?.get("hour"),
		dateOffset = currentIndex === -1 ? 10 : currentIndex,
		resTime = currentIndex === -1 ? timeAreas?.at(-1)?.startOf("day")?.add(1, "day") : currentTime;
	return {
		dateOffset,
		timeOffset,
		currentTime: resTime
	};
};
