import { useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import useBasicInfoIndex from "../../../common/basicInfo/hooks/useIndex";
import useCpSpdIndex from "../../../common/cpSpd/hooks/useIndex";
import useGwClauseIndex from "../../../common/gwClause/hooks/useIndex";
import { VoyageFO, CpFO, GwClauseFO } from "@/api/voy/type";
import { onRequest } from "@/api";
import { AddNewVoyProps } from "../type";
import { useNavigate } from "react-router-dom";

const useIndex = (props: AddNewVoyProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,
		validateBasicInfo,
		clearVoyBasicInfoData
	} = useBasicInfoIndex();

	const {
		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess,
		clearVoyCpSpdTableData
	} = useCpSpdIndex();

	const {
		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,
		clearVoyGwClauseData
	} = useGwClauseIndex();

	const [loading, setLoading] = useState(false);

	// 清空数据
	const clearData = () => {
		clearVoyBasicInfoData();
		clearVoyCpSpdTableData();
		clearVoyGwClauseData();
	};

	const handleConfirmClick = () => {
		if (!validateBasicInfo()) return;

		// 过滤voyBasicInfoData
		// const voyBasicInfoDataFilter = {
		// 	orderData: voyBasicInfoData.startOrderDate
		// };
		// const filterKeys = Object.keys(voyBasicInfoData).map((key) => {
		// 	if (key === "endOrderDate" || key === "startOrderDate") {
		// 		return;
		// 	}
		// 	return key;
		// });
		// filterKeys.forEach((key) => {
		// 	voyBasicInfoDataFilter[key] = voyBasicInfoData[key];
		// });

		const voyage: VoyageFO = {
			...voyBasicInfoData,
			// companyName: "甲骨文",
			status: "Close",
			// vesselName: "泰坦尼克号",
			voyageNo: "001"
			// imo: 123,
			// companyId: 234
		};

		const cp: CpFO = {
			cpList: voyCpSpdTableData
		};

		// proportion需要除100再发送给后端
		// const proportion =
		// 	voyGwClauseData.proportion !== ""
		// 		? voyGwClauseData.proportion / 100
		// 		: voyGwClauseData.proportion;
		const gwClause: GwClauseFO = {
			...voyGwClauseData
			// proportion
		};

		const addVoyageFront = () => {
			setLoading(true);
		};

		const addVoyageSuccess = (res) => {
			setLoading(false);
			console.log("addVoyageSuccess", res);
			props?.onAddSuccess();
			clearData();
			reminder("success", "add voyage success");
		};

		const addVoyageError = (error) => {
			setLoading(false);
			console.log("addVoyageError", error);
			reminder(
				"error",
				error?.msg ? error?.msg + ": " + error?.data : error?.data
			);
		};

		onRequest(
			"addVoyageApi",
			{ voyage, cp, gwClause },
			addVoyageFront,
			addVoyageSuccess,
			addVoyageError,
			dispatch,
			navigate
		);

		console.log(voyage);
		console.log(cp);
		console.log(gwClause);
	};

	return {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,

		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess,

		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,

		handleConfirmClick,
		loading,
		clearData
	};
};

export default useIndex;
