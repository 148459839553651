import { onRequest } from "@/api";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { SimulationForecastListType } from "../../simulationForecastTable/type";
import { calculateDistanceAtTime, RoutePosition } from "@/tools/latLong";
const useIndex = (
	simulationId: string,
	simulationForecastList: SimulationForecastListType,
	typhoonId: string,
	typhoonRoute: RoutePosition[]
) => {
	const echarts = require("echarts");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const cpaRef = useRef(null);
	const [dataSource, setDataSource] = useState(null);

	const setCpaEcharts = (time: number[], distances: number[]) => {
		const chart = echarts.init(cpaRef.current); // 绘制图表
		let option = {
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross"
				}
			},
			xAxis: {
				type: "time", // 设置为时间轴
				axisLabel: {
					formatter: function (value) {
						return echarts.format.formatTime("yyyy-MM-dd", value);
					}
				},
				data: time,
				minInterval: 24 * 3600 * 1000 // 设置最小间隔为天
				// maxInterval: "month" // 设置最大间隔为月
				// ... 其他配置
			},
			yAxis: {
				type: "value",
				scale: false
			},
			series: [
				{
					name: "distance",
					data: time?.map?.((item, index) => {
						return [item, distances[index]]; // 使用日期和距离作为数据点
					}),
					type: "line",
					lineStyle: {
						color: "blue", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "blue", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				}
			]
		};

		chart.setOption(option);
	};

	// API - getSimulationGraphApi
	const getSimulationGraphFront = () => {
		setLoading(true);
	};
	const getSimulationGraphSuccess = (data) => {
		setLoading(false);
		setDataSource(data?.data);
	};
	const getSimulationGraphFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getGraphData = (params?: { id: string }) => {
		onRequest(
			"getSimulationGraphApi",
			params,
			getSimulationGraphFront,
			getSimulationGraphSuccess,
			getSimulationGraphFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		const vesselRoute: RoutePosition[] = simulationForecastList?.map((item) => [
			item?.time,
			item?.lat,
			item?.lon
		]);
		const t = typhoonRoute?.map((item) => item?.[0]);
		const calculateData = calculateDistanceAtTime(
			vesselRoute,
			typhoonRoute.sort((a, b) => a[0] - b[0]),
			t
		);
		console.log("calculateData", calculateData);
		setCpaEcharts(t, calculateData);
	}, []);

	return { cpaRef, loading };
};

export default useIndex;
