import React from "react";
import style from "./index.module.less";

interface ReportData {
	label: string;
	data: string;
}

interface ReportDescriptionsProps {
	data: ReportData[];
}
const ReportDescriptions: React.FC<ReportDescriptionsProps> = ({ data }) => {
	return (
		<div className={style["report-descriptions"]}>
			{data.map((item, index) => (
				<div key={index} className="description-item">
					{item.label && <span className="label">{item.label}:</span>}
					<span className="data">{item.data}</span>
				</div>
			))}
		</div>
	);
};

export default ReportDescriptions;
