import React from "react";
import { onRequest } from "@/api";
import { useEffect, useState, useMemo, useRef } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import {
	htmlToMultiPagePdf,
	htmlsToMultiPagePdf,
	htmlToPdf
} from "@/tools/htmlToPdf";
import { ReportContentType, ReportDetail } from "../../../type";
import { DataType } from "@/components/Table";
import { ColumnsType } from "antd/es/table";
import { timestampToUtcObj } from "@/tools/date";
import { formatNumber } from "@/tools/amount";
import { convertDdToDm } from "@/tools/latLong";
import { LegReportMapRefType } from "../../map/type";

const useIndex = (reportId: string) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const [reportContent, setReportContent] = useState<ReportContentType>(null);
	const [reportImgUrl, setReportImgUrl] = useState<string>(null);

	const mapRef = useRef<LegReportMapRefType>(null);

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");

	// cpSpeed数据组
	const countNonNullIntervals = (data: ReportDetail[]) => {
		let result = [];
		let startIndex = -1;

		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i].type !== null) {
					if (startIndex !== -1) {
						result.push([startIndex + 1, i - startIndex]);
					}
					startIndex = i;
				}
			}
		}

		return result;
	};
	const cpSpeedRowSpan = countNonNullIntervals(reportContent?.reportDetail);

	console.log("查看cpSpeed数据", cpSpeedRowSpan);

	// seg数据组
	function retainFirstUniqueInSegments(
		arr: (number | null)[]
	): (number | null)[] {
		if (arr.length === 0) {
			return arr;
		}
		const result: (number | null)[] = [];
		let currentSegmentFirst: number | null = arr[0];
		for (let i = 0; i < arr.length; i++) {
			const num = arr[i];
			if (num === currentSegmentFirst) {
				if (i === 0) {
					result.push(num);
				} else {
					result.push(null);
				}
			} else {
				currentSegmentFirst = num;
				result.push(num);
			}
		}

		return result;
	}
	const segRowSpan = retainFirstUniqueInSegments(
		reportContent?.reportDetail
			? reportContent.reportDetail.map((item) => item.seg)
			: []
	);

	// date数据组
	function filterTimestamps(timestamps: number[]) {
		if (timestamps.length === 0) {
			return timestamps;
		}

		// 将第一个时间戳转换为UTC日期
		let prevDate = new Date(timestamps[0])
			.toUTCString()
			.split(" ")
			.slice(0, 4)
			.join(" ");
		let result = [timestamps[0]];

		for (let i = 1; i < timestamps.length; i++) {
			let currentDate = new Date(timestamps[i])
				.toUTCString()
				.split(" ")
				.slice(0, 4)
				.join(" ");
			if (currentDate === prevDate) {
				result.push(null);
			} else {
				result.push(timestamps[i]);
				prevDate = currentDate;
			}
		}

		return result;
	}
	const dateRowSpan = filterTimestamps(
		reportContent?.reportDetail
			? reportContent.reportDetail.map((item) => item.dateTime)
			: []
	);

	// 现在有一个时间戳数组，是从小到大排序的，要求实现一个函数，返回值是这个时间戳数组同一天只保留第一个，其他设置为null

	const resultTableColums: ColumnsType<ReportDetail> = [
		{
			title: "Seg",
			dataIndex: "seg",
			key: "seg",
			align: "center",
			width: 30,
			render(value, record, index) {
				return <div>{segRowSpan[index]}</div>;
			}
		},
		{
			title: " ",
			dataIndex: "type",
			key: "type",
			align: "center",
			width: 45
		},
		{
			title: "Date",
			align: "center",
			width: 45,
			render: (value, record, index) => {
				return (
					<div>
						{dateRowSpan[index]
							? timestampToUtcObj(dateRowSpan[index]).date
							: ""}
					</div>
				);
			}
		},
		{
			title: "Time\n(UTC)",
			align: "center",
			width: 45,
			render: (value, record, index) => {
				return <div>{timestampToUtcObj(record.dateTime).time}</div>;
			}
		},
		{
			title: "Cordinates",
			align: "center",
			width: 140,
			render: (value, record, index) => {
				let renderStr = "";
				if (record.type === "COSP" || record.type === "EOSP") {
					renderStr = record.port;
				} else {
					const lat = convertDdToDm(record.lat, true);
					const lng = convertDdToDm(record.lon, false);
					renderStr = `${lat.degree}°${lat.minute}′${lat.unit}    ${lng.degree}°${lng.minute}′${lng.unit}`;
				}

				return <div>{renderStr}</div>;
			}
		},
		{
			title: "CP Speed\n(kts)",
			dataIndex: "cpSpeed",
			key: "cpSpeed",
			align: "center",
			width: 50,
			onCell(record, index) {
				const keys = cpSpeedRowSpan.map((item) => item[0]);
				const values = cpSpeedRowSpan.map((item) => item[1]);
				const inx = keys.indexOf(index);
				if (index === 0) {
					return { rowSpan: 1 };
				}
				if (inx !== -1) {
					return { rowSpan: values[inx] };
				} else {
					return { rowSpan: 0 };
				}
			},
			render(value, record, index) {
				return (
					<div>
						{value || value === 0 ? formatNumber(Number(value), 2) : ""}
					</div>
				);
			}
		},
		{
			title: "Analyzed Speed(kts)",
			dataIndex: "analyzedSpeed",
			key: "analyzedSpeed",
			align: "center",
			width: 60,
			render(value, record, index) {
				return (
					<div>
						{value || value === 0 ? formatNumber(Number(value), 2) : ""}
					</div>
				);
				// return <div>{value}</div>;
			}
		},
		{
			title: "Analyzed Distance(nm)",
			dataIndex: "analyzedDistance",
			key: "analyzedDistance",
			align: "center",
			width: 65,
			render(value, record, index) {
				return (
					<div>
						{value || value === 0 ? formatNumber(Number(value), 1) : ""}
					</div>
				);
				// return <div>{value}</div>;
			}
		},
		{
			title: "Beaufort Wind Scale",
			dataIndex: "bf",
			key: "bf",
			width: 60,
			align: "center"
		},
		{
			title: "Swell",
			children: [
				{
					title: "Direction",
					dataIndex: "swellWaveDir",
					key: "swellWaveDir",
					align: "center",
					width: 50,
					render(value, record, index) {
						return (
							// <div>{value || value === 0 ? Number(value).toFixed(0) : ""}</div>
							<div>{value}</div>
						);
					}
				},
				{
					title: "Height(m)",
					dataIndex: "swellWaveHeight",
					key: "swellWaveHeight",
					align: "center",
					width: 60,
					render(value, record, index) {
						return (
							<div>
								{value || value === 0 ? formatNumber(Number(value), 2) : ""}
							</div>
						);
					}
				}
			]
		},
		{
			title: "Sig.Wave\n(m)",
			dataIndex: "sigWaveHeight",
			key: "sigWaveHeight",
			align: "center",
			width: 50,
			render(value, record, index) {
				return (
					<div>
						{value || value === 0 ? formatNumber(Number(value), 2) : ""}
					</div>
				);
			}
		},
		{
			title: "Current Factor(kts)",
			dataIndex: "cf",
			key: "cf",
			align: "center",
			width: 60,
			render(value, record, index) {
				return (
					<div>
						{value || value === 0 ? formatNumber(Number(value), 2) : ""}
					</div>
				);
			}
		},
		{
			title: "Reported Speed(kts)",
			dataIndex: "reportSpeed",
			key: "reportSpeed",
			align: "center",
			width: 60
		},
		{
			title: "Reported Distance(nm)",
			dataIndex: "reportDistance",
			key: "reportDistance",
			align: "center",
			width: 65
		},
		{
			title: "CP Consumption(mt)",
			children: [
				{
					title: "FO",
					dataIndex: "cpFo",
					key: "cpFo",
					align: "center",
					width: 50,
					render(value, record, index) {
						return (
							<div>
								{value || value === 0 ? formatNumber(Number(value), 3) : ""}
							</div>
						);
					}
				},
				{
					title: "DO/GO",
					dataIndex: "cpDgo",
					key: "cpDgo",
					align: "center",
					width: 50,
					render(value, record, index) {
						return (
							<div>
								{value || value === 0 ? formatNumber(Number(value), 3) : ""}
							</div>
						);
					}
				}
			]
		},
		{
			title: "Reported Daily Fuel Consumption(mt)",
			children: [
				{
					title: "FO",
					dataIndex: "reportDailyConsFo",
					key: "reportDailyConsFo",
					align: "center",
					width: 50,
					render(value, record, index) {
						return (
							<div>
								{value || value === 0 ? formatNumber(Number(value), 3) : ""}
							</div>
						);
					}
				},
				{
					title: "DO/GO",
					dataIndex: "reportDailyConsDgo",
					key: "reportDailyConsDgo",
					align: "center",
					width: 50,
					render(value, record, index) {
						return (
							<div>
								{value || value === 0 ? formatNumber(Number(value), 3) : ""}
							</div>
						);
					}
				}
			]
		},
		{
			title: "Reported BROB (mt)",
			fixed: "right",
			children: [
				{
					title: "FO",
					dataIndex: "reportBrobFo",
					key: "reportBrobFo",
					align: "center",
					width: 50,
					render(value, record, index) {
						return (
							<div>{value || value === 0 ? formatNumber(value, 3) : ""}</div>
						);
					}
				},
				{
					title: "DO/GO",
					dataIndex: "reportBrobDgo",
					key: "reportBrobDgo",
					align: "center",
					width: 50,
					render(value, record, index) {
						return (
							<div>{value || value === 0 ? formatNumber(value, 3) : ""}</div>
						);
					}
				}
			]
		}
	];

	const uploadReportImageFront = () => {};
	const uploadReportImageSuccess = (data) => {
		console.log("上传图片成功！！！！！！！！！！！", data);
	};
	const uploadReportImageError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const initReportMap = async (
		ais: GeoJSON.FeatureCollection<GeoJSON.LineString, { goodWeather: boolean }>
	) => {
		console.log("ais数据", ais);
		mapRef.current.onAisItemsLoad(ais);

		setTimeout(async () => {
			const data = await mapRef.current.getMapInfo();
			onRequest(
				"uploadReportImageApi",
				{
					reportId,
					centerLon: data.center.lng,
					centerLat: data.center.lat,
					zoom: data.zoom,
					file: data.mapImage
				},
				uploadReportImageFront,
				uploadReportImageSuccess,
				uploadReportImageError,
				dispatch,
				navigate
			);
		}, 3000);
	};

	const initReportDataFront = () => {
		setLoading(true);
	};
	const initReportDataSuccess = (data) => {
		console.log("报告数据", data);
		const { reportContent, imgUrl } = data.data;
		setReportContent(reportContent);
		setReportImgUrl(imgUrl);
		setLoading(false);
		if (!imgUrl) {
			initReportMap(data.data.reportContent.ais);
		}
	};

	const initReportDataError = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const initReportData = () => {
		onRequest(
			"getReportDataApi",
			{ reportId },
			initReportDataFront,
			initReportDataSuccess,
			initReportDataError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		initReportData();
	}, []);

	const handleConfirm = () => {
		// htmlsToMultiPagePdf(
		// 	[
		// 		document.querySelector("#main-homepage"),
		// 		document.querySelector("#main-analysis-page"),
		// 		document.querySelector("#main-last-page")
		// 	],
		// 	"report"
		// );

		htmlToPdf(
			document.querySelector(".reportPdfDialog-main"),
			"report.pdf",
			""
		);
	};

	return {
		loading,
		handleConfirm,
		reportContent,
		resultTableColums,
		mapRef,
		reportImgUrl
	};
};

export default useIndex;
