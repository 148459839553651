import { onRequest } from "@/api";
import { dateToUtcString, dateToUtcDateString } from "@/tools/date";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { table } from "console";

const useIndex = (simulationId: string) => {
	const echarts = require("echarts");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	// URL Params
	const queryParams = new URLSearchParams(location.search);

	// const dataSource = {
	// 	report: {
	// 		ws: [6.0, 6.0, 6.0, 6.0],
	// 		wd: [225.0, 180.0, 180.0, 90.0],
	// 		swellHeight: [6.0, 4.0, 4.0, 8.0],
	// 		swellDir: [180.0, 202.5, 202.5, 90.0],
	// 		sightWaveHeight: [6.0, 4.0, 4.0, 8.0],
	// 		cf: null,
	// 		xaxis: [1725972720000, 1726052400000, 1726142400000, 1726232400000]
	// 	},
	// 	forecast: {
	// 		ws: [6, 5, 4, 0.0, 0.0, 0.0, 5.0, 0.0, 0.0, 1.0, 0.0, 6.0],
	// 		wd: [90.0, 12, 222, 11, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
	// 		swellHeight: [8.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
	// 		swellDir: [88.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
	// 		sightWaveHeight: [
	// 			null,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0,
	// 			0.0
	// 		],
	// 		cf: [null, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
	// 		xaxis: [
	// 			1726232400000, 1726332400000, 1726432400000, 1726532400000,
	// 			1726532400000, 1726532400000, 1726532400000, 1726532400000,
	// 			1726532400000, 1726532400000, 1726532400000, 1726532400000
	// 		]
	// 	},
	// 	ais: {
	// 		ws: [
	// 			11.59, 8.82, 7.89, 5.6, 5.91, 3.47, 3.02, 1.81, 3.62, 4.05, 6.0, 7.11,
	// 			5.93, 7.05, 9.14, 8.57, 6.47, 4.12, 3.87, 0.36, 1.81, 2.73, 5.39, 3.14,
	// 			0.83, 4.35, 7.7, 10.25, 10.14, 10.84
	// 		],
	// 		wd: [
	// 			182.13, 181.23, 134.18, 149.54, 185.14, 179.34, 144.31, 66.26, 244.99,
	// 			243.62, 203.57, 222.38, 254.13, 248.2, 214.75, 232.54, 250.3, 258.09,
	// 			173.17, 178.41, 40.29, 22.43, 92.55, 72.95, 9.69, 240.66, 213.94,
	// 			234.85, 246.82, 242.94
	// 		],
	// 		swellHeight: [
	// 			2.49,
	// 			null,
	// 			1.62,
	// 			1.45,
	// 			2.04,
	// 			0.63,
	// 			0.56,
	// 			0.26,
	// 			0.29,
	// 			0.31,
	// 			0.32,
	// 			0.31,
	// 			0.26,
	// 			0.2,
	// 			0.16,
	// 			0.36,
	// 			0.34,
	// 			0.33,
	// 			0.33,
	// 			0.15,
	// 			0.12,
	// 			0.1,
	// 			0.1,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null
	// 		],
	// 		swellDir: [
	// 			165.47, 171.64, 168.47, 150.02, 179.49, 204.81, 45.55, 176.35, 189.45,
	// 			177.41, 181.66, 175.72, 174.13, 189.88, 191.51, 89.01, 85.34, 80.52,
	// 			59.4, 67.34, 64.63, 199.82, 191.42, 172.28, 183.26, 167.67, 171.18,
	// 			85.86, 82.77, 88.0
	// 		],
	// 		sightWaveHeight: [
	// 			3.72,
	// 			null,
	// 			2.0,
	// 			1.77,
	// 			2.29,
	// 			0.8,
	// 			0.91,
	// 			0.69,
	// 			0.48,
	// 			0.48,
	// 			0.55,
	// 			0.56,
	// 			0.6,
	// 			0.8,
	// 			0.81,
	// 			0.8,
	// 			0.77,
	// 			0.59,
	// 			0.45,
	// 			0.33,
	// 			0.24,
	// 			0.2,
	// 			0.2,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null
	// 		],
	// 		cf: [
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null,
	// 			null
	// 		],
	// 		xaxis: [
	// 			1725559200000, 1725580800000, 1725602400000, 1725624000000,
	// 			1725645600000, 1725667200000, 1725688800000, 1725710400000,
	// 			1725732000000, 1725753600000, 1725775200000, 1725796800000,
	// 			1725818400000, 1725840000000, 1725861600000, 1725883200000,
	// 			1725904800000, 1725926400000, 1725948000000, 1725969600000,
	// 			1725991200000, 1726012800000, 1726034400000, 1726056000000,
	// 			1726077600000, 1726099200000, 1726120800000, 1726142400000,
	// 			1726164000000, 1726185600000
	// 		]
	// 	}
	// };
	const weatherRef = useRef(null);
	const swellRef = useRef(null);
	const swRef = useRef(null);
	const cfRef = useRef(null);
	const testRef = useRef(null);
	const [dataSource, setDataSource] = useState(null);

	// console.log("123123123", dataSource);

	const initWeatherEcharts = () => {
		const chart = echarts.init(weatherRef.current); // 绘制图表
		const maxValue = getMaxValue(
			dataSource?.ais?.ws,
			dataSource?.report?.ws,
			dataSource?.forecast?.ws
		);

		let option = {
			title: {
				text: "Weather"
			},
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross"
				},
				formatter: (params) => {
					let tooltipHtml = "";
					params.forEach((param) => {
						if (
							param.seriesName !== "reportWd" &&
							param.seriesName !== "forecastWd"
						) {
							tooltipHtml +=
								param.marker + `${param.seriesName}: ${param.value[1]}<br/>`;
						}
					});
					return tooltipHtml;
				}
			},
			xAxis: {
				type: "time",
				scale: true, // 启用时间轴的扩展功能
				axisLabel: {
					rotate: 45, // 旋转45度
					formatter: function (value) {
						return dateToUtcDateString(value);
					}
				},
				minInterval: "day", // 设置最小间隔为天
				maxInterval: "month" // 设置最大间隔为月
			},
			yAxis: {
				type: "value",
				name: "Wind speed (BF)",
				nameLocation: "middle", // 名字相对于轴的位置，可选值有'start', 'middle', 'end'
				nameGap: 30, // 轴名字和轴线之间的距离，单位px，默认为20
				axisLine: {
					// 调整轴线的位置
					show: true,
					lineStyle: {
						color: "#333"
					}
				},
				axisTick: {
					// 调整刻度的位置
					show: true,
					alignWithLabel: true
				}
			},
			legend: {
				right: 80,
				data: [
					{
						name: "Analysis",
						icon: "rect",
						textStyle: {
							color: "green" // 这里设置图例文字的颜色
						}
					},
					{
						name: "Master",
						icon: "rect",
						textStyle: {
							color: "red" // 这里设置图例文字的颜色
						}
					},
					{
						name: "Forecast",
						icon: "rect",
						textStyle: {
							color: "blue" // 这里设置图例文字的颜色
						}
					}
				],
				show: true // 确保图例显示开关打开
			},
			series: [
				{
					name: "Analysis",
					data: dataSource?.ais?.ws?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.ais?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					lineStyle: {
						color: "green", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "green", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Forecast",
					data: dataSource?.forecast?.ws?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.forecast?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					lineStyle: {
						color: "blue", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "blue", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Master",
					data: dataSource?.report?.ws?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.report?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					symbol: "emptyCircle", // 设置为空心圆
					symbolSize: 10, // 设置符号大小
					lineStyle: {
						type: "dashed", // 虚线样式
						color: "red", // 边线颜色
						width: 1 // 边线宽度
					},
					itemStyle: {
						color: "red", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "reportWd",
					type: "scatter",
					data: dataSource?.ais?.wd?.map(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.ais?.xaxis[index]);
						return [utcTime, maxValue + 2, item + 180]; // + 180 默认向下
					}),
					symbol: function (data) {
						return "path://M764.02625771 410.16941125L544.58578814 190.72894168a15.39281832 15.39281832 0 0 0-4.81565853-3.2392549c-0.05593697-0.02542566-0.1067889-0.06102197-0.16781089-0.08644826a15.32162635 15.32162635 0 0 0-2.47647875-0.76786117c-0.15255522-0.0355963-0.29493982-0.10170329-0.45258069-0.13221456-1.01703467-0.20340719-2.04932498-0.31019548-3.07652967-0.31019547H502.54157817a15.45384094 15.45384094 0 0 0-12.51969586 6.42257348L272.45784184 410.16941125a15.50977726 15.50977726 0 0 0 0 21.94252139l21.94252139 21.94760639a15.5199479 15.5199479 0 0 0 21.9425214 0l170.67874563-170.68383062V822.36844741a15.51486291 15.51486291 0 0 0 15.51994791 15.5199479h31.03481079a15.51486291 15.51486291 0 0 0 15.5148629-15.5199479V283.01466097l171.04487806 171.04487806a15.5199479 15.5199479 0 0 0 21.94252139 0l21.9425214-21.9476064a15.50977726 15.50977726 0 0 0 0.00508501-21.94252138z";
					},
					symbolRotate: function (data) {
						return 360 - data[2];
					},
					itemStyle: {
						color: "green" // 设置symbol颜色为绿色
					}
				},
				{
					name: "forecastWd",
					type: "scatter",
					data: dataSource?.forecast?.wd?.map(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.forecast?.xaxis[index]);
						return [utcTime, maxValue + 2, item + 180]; // + 180 默认向下
					}),
					z: 100,
					symbol: function (data) {
						return "path://M764.02625771 410.16941125L544.58578814 190.72894168a15.39281832 15.39281832 0 0 0-4.81565853-3.2392549c-0.05593697-0.02542566-0.1067889-0.06102197-0.16781089-0.08644826a15.32162635 15.32162635 0 0 0-2.47647875-0.76786117c-0.15255522-0.0355963-0.29493982-0.10170329-0.45258069-0.13221456-1.01703467-0.20340719-2.04932498-0.31019548-3.07652967-0.31019547H502.54157817a15.45384094 15.45384094 0 0 0-12.51969586 6.42257348L272.45784184 410.16941125a15.50977726 15.50977726 0 0 0 0 21.94252139l21.94252139 21.94760639a15.5199479 15.5199479 0 0 0 21.9425214 0l170.67874563-170.68383062V822.36844741a15.51486291 15.51486291 0 0 0 15.51994791 15.5199479h31.03481079a15.51486291 15.51486291 0 0 0 15.5148629-15.5199479V283.01466097l171.04487806 171.04487806a15.5199479 15.5199479 0 0 0 21.94252139 0l21.9425214-21.9476064a15.50977726 15.50977726 0 0 0 0.00508501-21.94252138z";
					},
					symbolRotate: function (data) {
						return 360 - data[2];
					},
					itemStyle: {
						color: "blue" // 设置symbol颜色为绿色
					}
				}
			]
		};

		chart.setOption(option);
	};

	const initSwellEcharts = () => {
		const chart = echarts.init(swellRef.current); // 绘制图表
		const maxValue = getMaxValue(
			dataSource?.ais?.swellHeight,
			dataSource?.report?.swellHeight,
			dataSource?.forecast?.swellHeight
		);
		let option = {
			title: {
				text: " "
			},
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross"
				},
				formatter: (params) => {
					let tooltipHtml = "";
					params.forEach((param) => {
						if (
							param.seriesName !== "reportSwellDir" &&
							param.seriesName !== "forecastSwellDir"
						) {
							tooltipHtml +=
								param.marker + `${param.seriesName}: ${param.value[1]}<br/>`;
						}
					});
					return tooltipHtml;
				}
			},
			xAxis: {
				type: "time", // 设置为时间轴
				scale: true, // 启用时间轴的扩展功能
				axisLabel: {
					rotate: 45, // 旋转45度
					formatter: function (value) {
						return dateToUtcDateString(value);
					}
				},
				minInterval: "day", // 设置最小间隔为天
				maxInterval: "month" // 设置最大间隔为月
			},
			yAxis: {
				type: "value",
				name: "Swell height(m)",
				nameLocation: "middle", // 名字相对于轴的位置，可选值有'start', 'middle', 'end'
				nameGap: 30, // 轴名字和轴线之间的距离，单位px，默认为20
				axisLine: {
					// 调整轴线的位置
					show: true,
					lineStyle: {
						color: "#333"
					}
				},
				axisTick: {
					// 调整刻度的位置
					show: true,
					alignWithLabel: true
				}
			},

			series: [
				{
					name: "Analysis",
					data: dataSource?.ais?.swellHeight?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.ais?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					lineStyle: {
						color: "green", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "green", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Forecast",
					data: dataSource?.forecast?.swellHeight?.map?.(
						function (item, index) {
							const utcTime = dateToUtcString(
								dataSource?.forecast?.xaxis[index]
							);
							return [utcTime, item]; // 使用日期和索引作为散点图的坐标
						}
					),
					type: "line",
					lineStyle: {
						color: "blue", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "blue", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Master",
					data: dataSource?.report?.swellHeight?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.report?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					symbol: "emptyCircle", // 设置为空心圆
					symbolSize: 10, // 设置符号大小
					lineStyle: {
						type: "dashed", // 虚线样式
						color: "red", // 边线颜色
						width: 1 // 边线宽度
					},
					itemStyle: {
						color: "red", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "reportSwellDir",
					type: "scatter",
					data: dataSource?.ais?.swellDir?.map(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.ais?.xaxis[index]);
						return [utcTime, maxValue + 2, item + 180]; // + 180 默认向下
					}),
					symbol: function (data) {
						return "path://M764.02625771 410.16941125L544.58578814 190.72894168a15.39281832 15.39281832 0 0 0-4.81565853-3.2392549c-0.05593697-0.02542566-0.1067889-0.06102197-0.16781089-0.08644826a15.32162635 15.32162635 0 0 0-2.47647875-0.76786117c-0.15255522-0.0355963-0.29493982-0.10170329-0.45258069-0.13221456-1.01703467-0.20340719-2.04932498-0.31019548-3.07652967-0.31019547H502.54157817a15.45384094 15.45384094 0 0 0-12.51969586 6.42257348L272.45784184 410.16941125a15.50977726 15.50977726 0 0 0 0 21.94252139l21.94252139 21.94760639a15.5199479 15.5199479 0 0 0 21.9425214 0l170.67874563-170.68383062V822.36844741a15.51486291 15.51486291 0 0 0 15.51994791 15.5199479h31.03481079a15.51486291 15.51486291 0 0 0 15.5148629-15.5199479V283.01466097l171.04487806 171.04487806a15.5199479 15.5199479 0 0 0 21.94252139 0l21.9425214-21.9476064a15.50977726 15.50977726 0 0 0 0.00508501-21.94252138z";
					},
					symbolRotate: function (data) {
						return 360 - data[2];
					},
					itemStyle: {
						color: "green" // 设置symbol颜色为绿色
					}
				},
				{
					name: "forecastSwellDir",
					type: "scatter",
					data: dataSource?.forecast?.swellDir?.map(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.forecast?.xaxis[index]);
						return [utcTime, maxValue + 2, item + 180]; // + 180 默认向下
					}),
					symbol: function (data) {
						return "path://M764.02625771 410.16941125L544.58578814 190.72894168a15.39281832 15.39281832 0 0 0-4.81565853-3.2392549c-0.05593697-0.02542566-0.1067889-0.06102197-0.16781089-0.08644826a15.32162635 15.32162635 0 0 0-2.47647875-0.76786117c-0.15255522-0.0355963-0.29493982-0.10170329-0.45258069-0.13221456-1.01703467-0.20340719-2.04932498-0.31019548-3.07652967-0.31019547H502.54157817a15.45384094 15.45384094 0 0 0-12.51969586 6.42257348L272.45784184 410.16941125a15.50977726 15.50977726 0 0 0 0 21.94252139l21.94252139 21.94760639a15.5199479 15.5199479 0 0 0 21.9425214 0l170.67874563-170.68383062V822.36844741a15.51486291 15.51486291 0 0 0 15.51994791 15.5199479h31.03481079a15.51486291 15.51486291 0 0 0 15.5148629-15.5199479V283.01466097l171.04487806 171.04487806a15.5199479 15.5199479 0 0 0 21.94252139 0l21.9425214-21.9476064a15.50977726 15.50977726 0 0 0 0.00508501-21.94252138z";
					},
					symbolRotate: function (data) {
						return 360 - data[2];
					},
					itemStyle: {
						color: "blue" // 设置symbol颜色为绿色
					}
				}
			]
		};
		chart.setOption(option);
	};

	const initSwEcharts = () => {
		const chart = echarts.init(swRef.current); // 绘制图表
		let option = {
			title: {
				text: " "
			},
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross"
				}
			},
			xAxis: {
				type: "time", // 设置为时间轴
				scale: true, // 启用时间轴的扩展功能
				axisLabel: {
					rotate: 45, // 旋转45度
					formatter: function (value) {
						// return echarts.format.formatTime("yyyy-MM-dd", value);
						return dateToUtcDateString(value);
					}
				},
				minInterval: "day", // 设置最小间隔为天
				maxInterval: "month" // 设置最大间隔为月
				// ... 其他配置
			},
			yAxis: {
				type: "value",
				name: "Sig Wave height (m)",
				nameLocation: "middle", // 名字相对于轴的位置，可选值有'start', 'middle', 'end'
				nameGap: 30, // 轴名字和轴线之间的距离，单位px，默认为20
				axisLine: {
					// 调整轴线的位置
					show: true,
					lineStyle: {
						color: "#333"
					}
				},
				axisTick: {
					// 调整刻度的位置
					show: true,
					alignWithLabel: true
				}
			},

			series: [
				{
					name: "Analysis",
					data: dataSource?.ais?.sigWaveHeight?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.ais?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					lineStyle: {
						color: "green", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "green", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Forecast",
					data: dataSource?.forecast?.sigWaveHeight?.map?.(
						function (item, index) {
							const utcTime = dateToUtcString(
								dataSource?.forecast?.xaxis[index]
							);
							return [utcTime, item]; // 使用日期和索引作为散点图的坐标
						}
					),
					type: "line",
					lineStyle: {
						color: "blue", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "blue", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Master",
					data: dataSource?.report?.sigWaveHeight?.map?.(
						function (item, index) {
							const utcTime = dateToUtcString(dataSource?.report?.xaxis[index]);
							return [utcTime, item]; // 使用日期和索引作为散点图的坐标
						}
					),
					type: "line",
					symbol: "emptyCircle", // 设置为空心圆
					symbolSize: 10, // 设置符号大小
					lineStyle: {
						type: "dashed", // 虚线样式
						color: "red", // 边线颜色
						width: 1 // 边线宽度
					},
					itemStyle: {
						color: "red", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				}
			]
		};

		chart.setOption(option);
	};

	const initCfEcharts = () => {
		const chart = echarts.init(cfRef.current); // 绘制图表
		let option = {
			title: {
				text: " "
			},
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross"
				}
			},
			xAxis: {
				type: "time", // 设置为时间轴
				scale: true, // 启用时间轴的扩展功能
				axisLabel: {
					rotate: 45, // 旋转45度
					formatter: function (value) {
						// return echarts.format.formatTime("yyyy-MM-dd", value);
						return dateToUtcDateString(value);
					}
				},
				minInterval: "day", // 设置最小间隔为天
				maxInterval: "month" // 设置最大间隔为月
			},
			yAxis: {
				type: "value",
				name: "Current factor(kts)",
				nameLocation: "middle", // 名字相对于轴的位置，可选值有'start', 'middle', 'end'
				nameGap: 30, // 轴名字和轴线之间的距离，单位px，默认为20
				axisLine: {
					// 调整轴线的位置
					show: true,
					lineStyle: {
						color: "#333"
					}
				},
				axisTick: {
					// 调整刻度的位置
					show: true,
					alignWithLabel: true
				}
			},

			series: [
				{
					name: "Analysis",
					data: dataSource?.ais?.cf?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.report?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					lineStyle: {
						color: "green", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "green", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Forecast",
					data: dataSource?.forecast?.cf?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.forecast?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					lineStyle: {
						color: "blue", // 边线颜色
						type: "solid", // 边线类型，这里是实线
						width: 2 // 边线宽度
					},
					itemStyle: {
						color: "blue", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				},
				{
					name: "Master",
					data: dataSource?.report?.cf?.map?.(function (item, index) {
						const utcTime = dateToUtcString(dataSource?.report?.xaxis[index]);
						return [utcTime, item]; // 使用日期和索引作为散点图的坐标
					}),
					type: "line",
					symbol: "emptyCircle", // 设置为空心圆
					symbolSize: 10, // 设置符号大小
					lineStyle: {
						type: "dashed", // 虚线样式
						color: "red", // 边线颜色
						width: 1 // 边线宽度
					},
					itemStyle: {
						color: "red", // 边框颜色
						borderWidth: 1 // 边框宽度
					}
				}
			]
		};
		chart.setOption(option);
	};
	const initPageData = () => {
		getSimulationGraph({ id: simulationId });
	};
	const initEcharts = () => {
		initWeatherEcharts();
		initSwellEcharts();
		initSwEcharts();
		initCfEcharts();
	};
	const getMaxValue = (...args: number[][]): number => {
		let max = 0;
		for (const arr of args) {
			if (!arr || arr.length === 0) continue; // 跳过空数组
			const currentMax = Math.max(...arr);
			if (currentMax > max) {
				max = currentMax;
			}
		}
		return max;
	};
	// API - getSimulationGraphApi
	const getSimulationGraphFront = () => {
		setLoading(true);
	};
	const getSimulationGraphSuccess = (data) => {
		setLoading(false);
		setDataSource(data?.data);
	};
	const getSimulationGraphFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getSimulationGraph = (params?: { id: string }) => {
		onRequest(
			"getSimulationGraphApi",
			params,
			getSimulationGraphFront,
			getSimulationGraphSuccess,
			getSimulationGraphFailed,
			dispatch,
			navigate
		);
	};
	useEffect(() => {
		initPageData();
	}, [simulationId]);
	useEffect(() => {
		initEcharts();
	}, [dataSource]);

	return { weatherRef, swellRef, swRef, cfRef, testRef };
};

export default useIndex;
