import React, { FC } from "react";
import style from "./index.module.less";
import { FleetInput, FleetTimePicker, Select } from "@/components";
import { CommonPortSelect, LatLongInput } from "@/common";
import {
	NoonMainInfoForm,
	NoonNextPortForm,
	NoonLabel,
	NoonFuelForm
} from "../../common";
import { wsDirectOptions } from "./source";
import { EospPatiFormType, EospViFormType, NoonEospRFProps } from "./type";
import dayjs from "dayjs";
import { FleetUtcTimePicker } from "../../../../components";
import { getHoursByTimeStampRange } from "@/tools/date";
import { formatNumber } from "@/tools/amount";

const NoonEospRF: FC<NoonEospRFProps> = (props) => {
	const {
		eospData,
		curCospDateTime,
		onEospMiCommit,
		onEospPatiCommit,
		onEospViCommit,
		onEospFuelCommit,
		onEospNpCommit
	} = props;

	const { miData, patiData, viData, fuelData, npData } = eospData;

	return (
		<div className={style["noon-eosp-rf"]}>
			<NoonMainInfoForm
				disabled={true}
				data={miData}
				onChange={(data) => {
					onEospMiCommit(data);
				}}
			/>
			<div className="noon-pati-form-container">
				<p className="noon-pati-title">Position and Time Information</p>
				<div className="noon-pati-form">
					<div className="pati-form-row">
						<NoonLabel title="Arrival Port" isNecessary>
							<FleetInput
								className="eosp-input"
								value={patiData.arrivalPort}
								onChange={(val: EospPatiFormType["arrivalPort"]) => {
									onEospPatiCommit({ ...patiData, arrivalPort: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="UNLOCODE" isNecessary>
							<FleetInput
								className="eosp-input"
								value={patiData.unlocode}
								onChange={(val: EospPatiFormType["unlocode"]) => {
									onEospPatiCommit({ ...patiData, unlocode: val });
								}}
							/>
						</NoonLabel>
					</div>
					<div className="pati-form-row">
						<NoonLabel title="EOSP Date" isNecessary>
							<FleetUtcTimePicker
								className="eosp-input"
								value={Number(patiData.eospDate)}
								onChange={(val) => {
									onEospPatiCommit({
										...patiData,
										eospDate: val
									});
									onEospViCommit({
										...viData,
										timeSinceCosp: getHoursByTimeStampRange(
											curCospDateTime,
											val
										)
									});
								}}
							/>
							<span className="eosp-init">UTC</span>
						</NoonLabel>
						<NoonLabel
							className="custom-label-content"
							title="Latitude"
							isNecessary
						>
							<LatLongInput
								className="eosp-input"
								value={patiData.latitude}
								type="latitude"
								onChange={(val: EospPatiFormType["latitude"]) => {
									onEospPatiCommit({ ...patiData, latitude: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel
							className="custom-label-content"
							title="Longitude"
							isNecessary
						>
							<LatLongInput
								className="eosp-input"
								value={patiData.longitude}
								type="longitude"
								onChange={(val: EospPatiFormType["longitude"]) => {
									onEospPatiCommit({ ...patiData, longitude: val });
								}}
							/>
						</NoonLabel>
					</div>
				</div>
			</div>

			<div className="noon-vi-form-container">
				<p className="noon-vi-title">Voyage Information</p>
				<div className="noon-vi-form">
					<div className="vi-form-row">
						<NoonLabel title="Fore Draft" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.foreDraft}
								onChange={(val: EospViFormType["foreDraft"]) => {
									onEospViCommit({ ...viData, foreDraft: val });
								}}
							/>
							<span className="eosp-init">M</span>
						</NoonLabel>
						<NoonLabel title="Aft Draft" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.aftDraft}
								onChange={(val: EospViFormType["aftDraft"]) => {
									onEospViCommit({ ...viData, aftDraft: val });
								}}
							/>
							<span className="eosp-init">M</span>
						</NoonLabel>
						<NoonLabel title="Mid Draft" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.midDraft}
								onChange={(val: EospViFormType["midDraft"]) => {
									onEospViCommit({ ...viData, midDraft: val });
								}}
							/>
							<span className="eosp-init">M</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Slip SLR" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.slipSlr}
								onChange={(val: EospViFormType["slipSlr"]) => {
									onEospViCommit({ ...viData, slipSlr: val });
								}}
							/>
							<span className="eosp-init">%</span>
						</NoonLabel>
						<NoonLabel title="Time SLR" isNecessary>
							<FleetInput
								className="eosp-input"
								value={viData.timeSlr}
								onChange={(val: EospViFormType["timeSlr"]) => {
									onEospViCommit({ ...viData, timeSlr: val });
								}}
							/>
							<span className="eosp-init">HRS</span>
						</NoonLabel>
						<NoonLabel title="Distance SLR" isNecessary>
							<FleetInput
								className="eosp-input"
								value={viData.distanceSlr}
								onChange={(val: EospViFormType["distanceSlr"]) => {
									onEospViCommit({ ...viData, distanceSlr: val });
								}}
							/>
							<span className="eosp-init">NM</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel
							className="custom-label-title"
							title="Avg Speed Over Ground SLR"
							isNecessary
						>
							<FleetInput
								className="eosp-input"
								value={viData.avgSogSlr}
								onChange={(val: EospViFormType["avgSogSlr"]) => {
									onEospViCommit({ ...viData, avgSogSlr: val });
								}}
							/>
							<span className="eosp-init">KTS</span>
						</NoonLabel>
						<NoonLabel
							className="custom-label-title"
							title="Time Since COSP"
							isNecessary={false}
						>
							<FleetInput
								className="eosp-input"
								value={viData.timeSinceCosp}
								onChange={(val: EospViFormType["timeSinceCosp"]) => {
									onEospViCommit({ ...viData, timeSinceCosp: val });
								}}
							/>
							<span className="eosp-init">HRS</span>
						</NoonLabel>
						<NoonLabel
							className="custom-label-title"
							title="Distance Since COSP"
							isNecessary
						>
							<FleetInput
								className="eosp-input"
								value={viData.distanceSinceCosp}
								onChange={(val: EospViFormType["distanceSinceCosp"]) => {
									onEospViCommit({ ...viData, distanceSinceCosp: val });
								}}
							/>
							<span className="eosp-init">NM</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Avg RPM SLR" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.avgRpmSlr}
								onChange={(val: EospViFormType["avgRpmSlr"]) => {
									onEospViCommit({ ...viData, avgRpmSlr: val });
								}}
							/>
							<span className="eosp-init">RPM</span>
						</NoonLabel>
						<NoonLabel title="Ordered Speed" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.orderedSpeed}
								onChange={(val: EospViFormType["orderedSpeed"]) => {
									onEospViCommit({ ...viData, orderedSpeed: val });
								}}
							/>
							<span className="eosp-init">KTS</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="MCR" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.mcr}
								onChange={(val: EospViFormType["mcr"]) => {
									onEospViCommit({ ...viData, mcr: val });
								}}
							/>
							<span className="eosp-init">%</span>
						</NoonLabel>
						<NoonLabel title="Time in ECA" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.timeInEca}
								onChange={(val: EospViFormType["timeInEca"]) => {
									onEospViCommit({ ...viData, timeInEca: val });
								}}
							/>
							<span className="eosp-init">HRS</span>
						</NoonLabel>
						<NoonLabel title="Distance in ECA" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.distanceInEca}
								onChange={(val: EospViFormType["distanceInEca"]) => {
									onEospViCommit({ ...viData, distanceInEca: val });
								}}
							/>
							<span className="eosp-init">NM</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Wind Beaufort" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.windBeaufort}
								onChange={(val: EospViFormType["windBeaufort"]) => {
									onEospViCommit({ ...viData, windBeaufort: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Wind Direction" isNecessary={false}>
							<Select
								className="eosp-input"
								value={viData.windDirection}
								options={wsDirectOptions}
								onChange={(val: EospViFormType["windDirection"]) => {
									onEospViCommit({ ...viData, windDirection: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Sea Height" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.seasHeight}
								onChange={(val: EospViFormType["seasHeight"]) => {
									onEospViCommit({ ...viData, seasHeight: val });
								}}
							/>
							<span className="eosp-init">M</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Swell Height" isNecessary={false}>
							<FleetInput
								className="eosp-input"
								value={viData.swellHeight}
								onChange={(val: EospViFormType["swellHeight"]) => {
									onEospViCommit({ ...viData, swellHeight: val });
								}}
							/>
							<span className="eosp-init">M</span>
						</NoonLabel>
						<NoonLabel title="Swell Direction" isNecessary={false}>
							<Select
								className="eosp-input"
								value={viData.swellDirection}
								options={wsDirectOptions}
								onChange={(val: EospViFormType["swellDirection"]) => {
									onEospViCommit({ ...viData, swellDirection: val });
								}}
							/>
						</NoonLabel>
					</div>
				</div>
			</div>

			<NoonFuelForm
				dataSource={fuelData}
				onCommit={(index, item) => {
					onEospFuelCommit(index, item);
					if (index === 1 || index === 2 || index === 3) {
						const totalConsSlr = {
							...fuelData[4],
							hfo: formatNumber(
								Number(fuelData[1].hfo) +
									Number(fuelData[2].hfo) +
									Number(fuelData[3].hfo),
								3
							),
							lsfo: formatNumber(
								Number(fuelData[1].lsfo) +
									Number(fuelData[2].lsfo) +
									Number(fuelData[3].lsfo),
								3
							),
							mdo: formatNumber(
								Number(fuelData[1].mdo) +
									Number(fuelData[2].mdo) +
									Number(fuelData[3].mdo),
								3
							),
							mgo: formatNumber(
								Number(fuelData[1].mgo) +
									Number(fuelData[2].mgo) +
									Number(fuelData[3].mgo),
								3
							)
						};
						onEospFuelCommit(4, totalConsSlr);
					}
					if (index === 5 || index === 6 || index === 7) {
						const totalConsSlr = {
							...fuelData[8],
							hfo: formatNumber(
								Number(fuelData[5].hfo) +
									Number(fuelData[6].hfo) +
									Number(fuelData[7].hfo),
								3
							),
							lsfo: formatNumber(
								Number(fuelData[5].lsfo) +
									Number(fuelData[6].lsfo) +
									Number(fuelData[7].lsfo),
								3
							),
							mdo: formatNumber(
								Number(fuelData[5].mdo) +
									Number(fuelData[6].mdo) +
									Number(fuelData[7].mdo),
								3
							),
							mgo: formatNumber(
								Number(fuelData[5].mgo) +
									Number(fuelData[6].mgo) +
									Number(fuelData[7].mgo),
								3
							)
						};
						onEospFuelCommit(8, totalConsSlr);
					}
				}}
			/>

			<NoonNextPortForm
				data={npData}
				onChange={(data) => {
					onEospNpCommit(data);
				}}
			/>
		</div>
	);
};

export default NoonEospRF;
