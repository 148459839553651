import { ColumnsType } from "antd/es/table";
import { DataType } from "./type";
import dayjs, { type Dayjs } from "dayjs";
export const voyLegTableColumn: ColumnsType<DataType> = [
	{
		title: "Leg No.",
		dataIndex: "id",
		key: "id",
		width: "120",
		fixed: "left",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return index + 1;
		}
	},
	{
		title: "Condition",
		dataIndex: "legType",
		key: "legType",
		width: "120",
		align: "center",
		ellipsis: true
	},

	{
		title: "Departure",
		dataIndex: "departurePortName",
		key: "departurePortName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "ETD",
		dataIndex: "etd",
		key: "etd",
		width: "120",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "";
		}
	},
	{
		title: "ATD",
		dataIndex: "atd",
		key: "atd",
		width: "120",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "";
		}
	},
	{
		title: "Arrival",
		dataIndex: "arrivalPortName",
		key: "arrivalPortName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "ATA",
		dataIndex: "ata",
		key: "ata",
		width: "120",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "";
		}
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		width: "120",
		align: "center",
		ellipsis: true
	}
];
