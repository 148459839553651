import React, { FC } from "react";
import style from "./index.module.less";
import { FleetInput, FleetTimePicker, Select } from "@/components";
import { LatLongInput } from "@/common";
import { NoonMainInfoForm, NoonLabel, NoonFuelForm } from "../../common";
import { ScSciFormType, NoonScRFProps } from "./type";
import dayjs from "dayjs";
import { FleetUtcTimePicker } from "../../../../components";
import { formatNumber } from "@/tools/amount";

const NoonScRF: FC<NoonScRFProps> = (props) => {
	const { scData, onScMiCommit, onScSciCommit, onScFuelCommit } = props;
	const { miData, sciData, fuelData } = scData;

	return (
		<div className={style["noon-sc-rf"]}>
			<NoonMainInfoForm
				disabled={true}
				data={miData}
				onChange={(data) => {
					onScMiCommit(data);
				}}
			/>
			<div className="noon-sci-form-container">
				<p className="noon-sci-title">Speed Change Information</p>
				<div className="noon-sci-form">
					<div className="sci-form-row">
						<NoonLabel
							className="custom-label-content"
							title="Latitude"
							isNecessary
						>
							<LatLongInput
								className="sc-input"
								type="latitude"
								value={sciData.latitude}
								onChange={(val: ScSciFormType["latitude"]) => {
									onScSciCommit({ ...sciData, latitude: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel
							className="custom-label-content"
							title="Longitude"
							isNecessary
						>
							<LatLongInput
								className="sc-input"
								type="longitude"
								value={sciData.longitude}
								onChange={(val: ScSciFormType["longitude"]) => {
									onScSciCommit({ ...sciData, longitude: val });
								}}
							/>
						</NoonLabel>
					</div>
					<div className="sci-form-row">
						<NoonLabel
							className="custom-label-title"
							title="Speed Change Date"
							isNecessary
						>
							<FleetUtcTimePicker
								className="sc-input"
								value={Number(sciData.speedChangeDate)}
								onChange={(val) => {
									onScSciCommit({
										...sciData,
										speedChangeDate: val
									});
								}}
							/>
							<span className="sc-init">UTC</span>
						</NoonLabel>
						<NoonLabel title="Ordered Speed" isNecessary>
							<FleetInput
								className="sc-input"
								value={sciData.orderedSpeed}
								onChange={(val: ScSciFormType["orderedSpeed"]) => {
									onScSciCommit({ ...sciData, orderedSpeed: val });
								}}
							/>
							<span className="sc-init">KTS</span>
						</NoonLabel>
						<NoonLabel title="Distance SLR" isNecessary>
							<FleetInput
								className="sc-input"
								value={sciData.distanceSlr}
								onChange={(val: ScSciFormType["distanceSlr"]) => {
									onScSciCommit({
										...sciData,
										distanceSlr: val,
										avgSpeedSlr: formatNumber(
											Number(val) / Number(sciData.steamTimeSlr),
											2
										)
									});
								}}
							/>
							<span className="sc-init">NM</span>
						</NoonLabel>
					</div>
					<div className="sci-form-row">
						<NoonLabel title="Time SLR" isNecessary>
							<FleetInput
								className="sc-input"
								value={sciData.steamTimeSlr}
								onChange={(val: ScSciFormType["steamTimeSlr"]) => {
									onScSciCommit({
										...sciData,
										steamTimeSlr: val,
										avgSpeedSlr: formatNumber(
											Number(sciData.distanceSlr) / Number(val),
											2
										)
									});
								}}
							/>
							<span className="sc-init">HRS</span>
						</NoonLabel>
						<NoonLabel title="Avg Speed SLR" isNecessary={false}>
							<FleetInput
								className="sc-input"
								disabled
								value={sciData.avgSpeedSlr}
								onChange={(val: ScSciFormType["avgSpeedSlr"]) => {
									onScSciCommit({ ...sciData, avgSpeedSlr: val });
								}}
							/>
							<span className="sc-init">KTS</span>
						</NoonLabel>
					</div>
				</div>
			</div>

			<NoonFuelForm
				dataSource={fuelData}
				onCommit={(index, item) => {
					onScFuelCommit(index, item);
					if (index === 1 || index === 2 || index === 3) {
						const totalConsSlr = {
							...fuelData[4],
							hfo: formatNumber(
								Number(fuelData[1].hfo) +
									Number(fuelData[2].hfo) +
									Number(fuelData[3].hfo),
								3
							),
							lsfo: formatNumber(
								Number(fuelData[1].lsfo) +
									Number(fuelData[2].lsfo) +
									Number(fuelData[3].lsfo),
								3
							),
							mdo: formatNumber(
								Number(fuelData[1].mdo) +
									Number(fuelData[2].mdo) +
									Number(fuelData[3].mdo),
								3
							),
							mgo: formatNumber(
								Number(fuelData[1].mgo) +
									Number(fuelData[2].mgo) +
									Number(fuelData[3].mgo),
								3
							)
						};
						onScFuelCommit(4, totalConsSlr);
					}
				}}
			/>
		</div>
	);
};

export default NoonScRF;
