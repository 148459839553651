import React from "react";
import classnames from "classnames";
import Checkbox from "antd/es/checkbox";
import style from "./index.module.less";
import { Button, FleetInput, Image, Loading } from "@/components";
import useIndex from "./hooks/useIndex";

const Login: React.FC<{}> = (props) => {
	const { loginRef, loading, dataSource, change, handleEnter, login } =
		useIndex();

	return (
		<div
			onKeyDown={handleEnter}
			ref={loginRef}
			className={classnames(style["fleet-login"], "voy-common")}
		>
			<div className="login-container">
				<div className="login-warp">
					<Image src="./images/fleet_login_logo.svg" className="login-logo" />
					<span className="login-title">All in One Platform</span>
					<span className="login-tip login-tip-top">
						Chartering Estimation, Operation Monitoring,
					</span>
					<span className="login-tip">Weather Routing, Commercial Report</span>
					<div className="login-form">
						<div className="login-item">
							<span className="label">Username</span>
							<FleetInput
								value={dataSource?.username}
								onChange={(val: string) => {
									change("username", val);
								}}
								className="input"
								disabled={loading}
							/>
						</div>
						<div className="login-item last-item">
							<span className="label">Password</span>
							<FleetInput
								value={dataSource?.password}
								onChange={(val: string) => {
									change("password", val);
								}}
								className="input"
								type="password"
								disabled={loading}
							/>
						</div>
						<div className="login-clause">
							<Checkbox
								checked={dataSource?.checked}
								onChange={(e) => {
									change("checked", e.target.checked);
								}}
							>
								Remember my password
							</Checkbox>
						</div>
						<Button
							loading={loading}
							disabled={
								// true ||
								dataSource?.password?.length === 0 ||
								dataSource?.username?.length === 0
							}
							onClick={login}
							className="login-btn"
							size="large"
							type="primary"
						>
							<Loading loading={loading} />
							Login in
						</Button>
					</div>
				</div>
			</div>
			<div className="logo-container"></div>
		</div>
	);
};

export default Login;
