import React, { FC } from "react";
import styles from "./index.module.less";
import { PortRotationProps } from "./type";
import { TimeItem } from "./components";

const PortRotation: FC<PortRotationProps> = ({ dataSource, onTimeSave }) => {
	return (
		<div className={styles["portRotation"]}>
			{dataSource?.map((item, index) => (
				<div className="portRotation-item" key={item?.id}>
					<div className="progressBar">
						<div className="icon">⚓</div>
						<div className="pointBorder"></div>
					</div>
					<div className="portRotationInfo">
						<div className="portName">{item.portName}</div>
						{index !== 0 && (
							<>
								<TimeItem
									value={item.eta}
									type="ETA"
									key="eta"
									index={index}
									onSave={(value) => {
										onTimeSave(index, "eta", value);
									}}
								/>
								<TimeItem
									value={item.ata}
									type="ATA"
									key="ata"
									index={index}
									onSave={(value) => {
										onTimeSave(index, "ata", value);
									}}
								/>
							</>
						)}
						{(index < dataSource?.length - 1 || index === 0) && (
							<>
								<TimeItem
									value={item.etd}
									type="ETD"
									key="etd"
									index={index}
									onSave={(value) => {
										onTimeSave(index, "etd", value);
									}}
								/>
								<TimeItem
									value={item.atd}
									type="ATD"
									key="atd"
									index={index}
									onSave={(value) => {
										onTimeSave(index, "atd", value);
									}}
								/>
							</>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default PortRotation;
