import { CommonOptionType } from "@/types/common";
import { LegBasicInfoItemType } from "./type";

export const conditionOptions: CommonOptionType<
	LegBasicInfoItemType["condition"],
	LegBasicInfoItemType["condition"]
>[] = [
	{ label: "B", value: "B" },
	{ label: "L", value: "L" }
];
