import { MutableRefObject, useState } from "react";
import {
	RouteCommonToolbarMeteoMap,
	RouteCommonToolbarMeteoSwitchMap,
	RouteCommonToolbarProps
} from "../components";
import { initialMeteoStatus } from "../source";
import { CommonChangeEvent } from "@/types/event";

const useControlIndex = (meteoCore: MutableRefObject<any>) => {
	const [meteoStatus, setMeteoStatus] = useState<
		RouteCommonToolbarProps["meteoStatus"]
	>({
		...initialMeteoStatus
	});

	const handleMeteoChange: CommonChangeEvent<
		RouteCommonToolbarProps["meteoStatus"]
	> = (key, switchType) => {
		const currentMeteoType = RouteCommonToolbarMeteoMap[key];
		let meteoTypeForControl:
				| "isolines"
				| "isobands"
				| "particlesAnim"
				| "hurricanes"
				| "discrete" = null,
			switchTypeForControl:
				| RouteCommonToolbarMeteoSwitchMap
				| RouteCommonToolbarMeteoMap =
				switchType === RouteCommonToolbarMeteoSwitchMap.On
					? currentMeteoType
					: RouteCommonToolbarMeteoSwitchMap.Off;
		switch (currentMeteoType) {
			case RouteCommonToolbarMeteoMap.Wave:
			case RouteCommonToolbarMeteoMap.Swell:
				meteoTypeForControl = "isobands";
				// switchTypeForControl =
				// 	switchType === RouteCommonToolbarMeteoSwitchMap.On
				// 		? currentMeteoType
				// 		: RouteCommonToolbarMeteoSwitchMap.Off;
				break;
			case RouteCommonToolbarMeteoMap.Pressure:
				meteoTypeForControl = "isolines";
				// switchTypeForControl =
				// 	switchType === RouteCommonToolbarMeteoSwitchMap.On
				// 		? currentMeteoType
				// 		: RouteCommonToolbarMeteoSwitchMap.Off;
				break;
			case RouteCommonToolbarMeteoMap.Current:
				meteoTypeForControl = "particlesAnim";
				switchTypeForControl = switchType;
				RouteCommonToolbarMeteoSwitchMap.On === switchType &&
					meteoCore?.current.store.set("overlay", "wind");
				break;
			case RouteCommonToolbarMeteoMap.Typhoon:
				if (meteoCore?.current.map.hurricanesLayers) {
					switchType === RouteCommonToolbarMeteoSwitchMap.On
						? meteoCore?.current.map.hurricanesLayers.forEach((item) =>
								item.addTo(meteoCore?.current.map)
							)
						: meteoCore?.current.map.hurricanesLayers.forEach((item) =>
								item.removeFrom(meteoCore?.current.map)
							);
					meteoTypeForControl = null;
				} else {
					meteoTypeForControl = "hurricanes";
					switchTypeForControl = switchType;
				}
				break;
			case RouteCommonToolbarMeteoMap.Wind:
				meteoTypeForControl = "discrete";
				switchTypeForControl = switchType;
				break;
			default:
				break;
		}
		if (meteoTypeForControl) {
			meteoCore?.current.store.set(meteoTypeForControl, switchTypeForControl);
		}
	};

	const handleMeteoStatusChange: RouteCommonToolbarProps["onMeteoStatusChange"] =
		(key) => {
			const currentMeteoStatus =
				meteoStatus?.[key] === RouteCommonToolbarMeteoSwitchMap.On;
			const params: Partial<RouteCommonToolbarProps["meteoStatus"]> = {
				[key]: currentMeteoStatus
					? RouteCommonToolbarMeteoSwitchMap.Off
					: RouteCommonToolbarMeteoSwitchMap.On
			};
			debugger;
			switch (true) {
				case key === "Swell" &&
					!currentMeteoStatus &&
					meteoStatus["Wave"] === RouteCommonToolbarMeteoSwitchMap.On:
					params.Wave = RouteCommonToolbarMeteoSwitchMap.Off;
					break;
				case key === "Wave" &&
					!currentMeteoStatus &&
					meteoStatus["Swell"] === RouteCommonToolbarMeteoSwitchMap.On:
					params.Swell = RouteCommonToolbarMeteoSwitchMap.Off;
					break;
				default:
					break;
			}
			setMeteoStatus((prev) => ({ ...prev, ...params }));
			handleMeteoChange?.(key, params?.[key]);
		};

	return {
		meteoStatus,
		handleMeteoStatusChange
	};
};

export default useControlIndex;
