import { ColumnsType } from "antd/es/table";
import React, { useMemo, useState } from "react";
import {
	NoonDetailsReportItemType,
	NRDirItemType,
	DlReportType
} from "../../../type";
import {
	FleetInput,
	FleetTimePicker,
	Select,
	Popover,
	Toast,
	Icon
} from "@/components";
import { onRequest } from "@/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReminder, useAppDispatch } from "@/hooks";
import { NrButton } from "../../../common";
import dayjs from "dayjs";
import { wsDirectOptions } from "../../../components/noonNrRF/source";
import { LatLongInput } from "@/common";
import { convertDdToDm } from "@/tools/latLong";
import { reportDetailIgnore } from "../../../source";
import { dateToUtcString } from "@/tools/date";
import { FleetUtcTimePicker } from "../../../../../components";
import { DetailReportListProps } from "../type";
import classNames from "classnames";
import { AlertFieldTip } from "../..";

const useIndex = (props: DetailReportListProps) => {
	const {
		noonListVisible,
		handleNoonListClose,
		basicReportTableData,
		detailsReportTableData,
		updateDataReportList,
		handleChangeReportTableItem,
		isCopyReport,
		handleIsCopyChange,
		detailReportData,
		handleDrIndexChange,
		visibleAddReportDialog,
		handleArDialogVisibleChange
	} = props;

	const { reminder } = useReminder();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();

	const generateLatLngStr = (degree: number, minute: number, unit: string) => {
		return `${degree}°${minute.toFixed(2)}${unit}`;
	};

	const detailsDatasource = useMemo(() => {
		return detailsReportTableData?.map((item) => {
			return { ...item, key: item.id + item.type };
		});
	}, [detailsReportTableData]);

	const noonDetailReportColumns: ColumnsType<NoonDetailsReportItemType> = [
		{
			title: "Seg",
			dataIndex: "seg",
			key: "seg",
			fixed: "left",
			align: "center",
			render(value, record, index) {
				return (
					<div className="detail-table-seg">
						{record.alert !== 0 && (
							<AlertFieldTip
								title="预警字段:"
								content={record.alertCols.join(", ")}
							>
								<span className="iconfont icon-warn" />
							</AlertFieldTip>
						)}
						<p>{value}</p>
					</div>
				);
			}
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			width: 160,
			align: "left",
			render(value, record, index) {
				return <div>{value}</div>;
			}
		},
		{
			title: "Date（UTC）",
			dataIndex: "dateTime",
			key: "dateTime",
			width: 200,
			align: "left",
			render(value, record, index) {
				return (
					<div>
						{editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("dateTime")
								})}
							>
								{value ? dateToUtcString(value).slice(0, -3) : ""}
							</p>
						) : (
							<div onDoubleClick={(event) => event.stopPropagation()}>
								<FleetUtcTimePicker
									value={Number(value)}
									onChange={(val) => {
										handleChangeReportTableItem(index, "dateTime", val);
									}}
								/>
							</div>
						)}
					</div>
				);
			}
		},
		{
			title: "LAT",
			dataIndex: "lat",
			key: "lat",
			align: "left",
			width: 250,
			render(value, record, index) {
				const { degree, minute, unit } = convertDdToDm(value, true);
				const latStr = generateLatLngStr(degree, minute, unit);
				const {
					degree: deg,
					minute: min,
					unit: ut
				} = convertDdToDm(record.tipLat, true);
				const tipLatStr = generateLatLngStr(deg, min, ut);
				const isAlert = record.alertCols.includes("lat");
				const alertElement = isAlert ? (
					<AlertFieldTip title="对比值:" content={tipLatStr}>
						<p className={classNames({ "alert-field": isAlert })}>{latStr}</p>
					</AlertFieldTip>
				) : (
					<p className={classNames({ "alert-field": isAlert })}>{latStr}</p>
				);

				return (
					<div>
						{editIndex !== index ? (
							alertElement
						) : (
							<LatLongInput
								type="latitude"
								value={value}
								onChange={(val) => {
									handleChangeReportTableItem(index, "lat", val);
								}}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: "LON",
			dataIndex: "lon",
			key: "lon",
			align: "left",
			width: 250,
			render(value, record, index) {
				const { degree, minute, unit } = convertDdToDm(value, false);
				const lonStr = generateLatLngStr(degree, minute, unit);
				const {
					degree: deg,
					minute: min,
					unit: ut
				} = convertDdToDm(record.tipLon, false);
				const tipLonStr = generateLatLngStr(deg, min, ut);
				const isAlert = record.alertCols.includes("lon");
				const alertElement = isAlert ? (
					<AlertFieldTip title="对比值:" content={tipLonStr}>
						<p className={classNames({ "alert-field": isAlert })}>{lonStr}</p>
					</AlertFieldTip>
				) : (
					<p className={classNames({ "alert-field": isAlert })}>{lonStr}</p>
				);

				return (
					<div>
						{editIndex !== index ? (
							alertElement
						) : (
							<LatLongInput
								type="longitude"
								value={value}
								onChange={(val) => {
									handleChangeReportTableItem(index, "lon", val);
								}}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: "Time SLR",
			dataIndex: "timeSlr",
			key: "timeSlr",
			align: "left",
			render(value, record, index) {
				const renderNode = reportDetailIgnore[record.type].includes(
					"timeSlr"
				) ? (
					"---"
				) : editIndex !== index ? (
					<p
						className={classNames({
							"alert-field": record.alertCols.includes("timeSlr")
						})}
					>
						{value}
					</p>
				) : (
					<FleetInput
						value={value}
						onChange={(val: number) => {
							handleChangeReportTableItem(index, "timeSlr", val);
						}}
					/>
				);
				return <div>{renderNode}</div>;
			}
		},
		{
			title: "Avg Speed SLR",
			dataIndex: "avgSpeedSlr",
			key: "avgSpeedSlr",
			align: "left",
			render(value, record, index) {
				const isAlert = record.alertCols.includes("avgSpeedSlr");
				const alertElement = isAlert ? (
					<AlertFieldTip
						title="对比值:"
						content={String(record.tipSpeedSlr.toFixed(2))}
					>
						<p className={classNames({ "alert-field": isAlert })}>
							{Number(value)?.toFixed(2)}
						</p>
					</AlertFieldTip>
				) : (
					<p className={classNames({ "alert-field": isAlert })}>
						{Number(value)?.toFixed(2)}
					</p>
				);

				const renderNode = reportDetailIgnore[record.type].includes(
					"avgSpeedSlr"
				) ? (
					"---"
				) : editIndex !== index ? (
					alertElement
				) : (
					<FleetInput
						value={value}
						onChange={(val: number) => {
							handleChangeReportTableItem(index, "avgSpeedSlr", val);
						}}
					/>
				);

				return <div>{renderNode}</div>;
			}
		},
		{
			title: "Dist SLR",
			dataIndex: "distSlr",
			key: "distSlr",
			align: "left",
			render(value, record, index) {
				const isAlert = record.alertCols.includes("distSlr");
				const alertElement = isAlert ? (
					<AlertFieldTip
						title="对比值:"
						content={String(record.tipDistSlr.toFixed(1))}
					>
						<p className={classNames({ "alert-field": isAlert })}>
							{Number(value)?.toFixed(1)}
						</p>
					</AlertFieldTip>
				) : (
					<p className={classNames({ "alert-field": isAlert })}>
						{Number(value)?.toFixed(1)}
					</p>
				);

				const renderNode = reportDetailIgnore[record.type].includes(
					"distSlr"
				) ? (
					"---"
				) : editIndex !== index ? (
					alertElement
				) : (
					<FleetInput
						value={value}
						onChange={(val: number) => {
							handleChangeReportTableItem(index, "distSlr", val);
						}}
					/>
				);
				return <div>{renderNode}</div>;
			}
		},
		{
			title: "Dist To Go",
			dataIndex: "distToGo",
			key: "distToGo",
			align: "left",
			render(value, record, index) {
				const renderNode = reportDetailIgnore[record.type].includes(
					"distToGo"
				) ? (
					"---"
				) : editIndex !== index ? (
					<p
						className={classNames({
							"alert-field": record.alertCols.includes("distToGo")
						})}
					>
						{value}
					</p>
				) : (
					<FleetInput
						value={value}
						onChange={(val: number) => {
							handleChangeReportTableItem(index, "distToGo", val);
						}}
					/>
				);

				return <div>{renderNode}</div>;
			}
		},
		{
			title: "Total Cons",
			align: "left",
			children: [
				{
					title: "HFO",
					dataIndex: "totalConsHfo",
					key: "totalConsHfo",
					align: "left",
					render(value, record, index) {
						const isAlert =
							record.alertCols.includes("totalConsHfo") && record.tipConsHfo;
						const alertElement = isAlert ? (
							<AlertFieldTip
								title="对比值:"
								content={String(record.tipConsHfo.toFixed(3))}
							>
								<p className={classNames({ "alert-field": isAlert })}>
									{Number(value)?.toFixed(3)}
								</p>
							</AlertFieldTip>
						) : (
							<p className={classNames({ "alert-field": isAlert })}>
								{Number(value)?.toFixed(3)}
							</p>
						);

						const renderNode = reportDetailIgnore[record.type].includes(
							"totalConsHfo"
						) ? (
							"---"
						) : editIndex !== index ? (
							alertElement
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "totalConsHfo", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				},
				{
					title: "LSFO",
					dataIndex: "totalConsLsfo",
					key: "totalConsLsfo",
					align: "left",
					render(value, record, index) {
						const isAlert =
							record.alertCols.includes("totalConsLsfo") && record.tipConsLsfo;
						const alertElement = isAlert ? (
							<AlertFieldTip
								title="对比值:"
								content={String(record.tipConsLsfo.toFixed(3))}
							>
								<p className={classNames({ "alert-field": isAlert })}>
									{Number(value)?.toFixed(3)}
								</p>
							</AlertFieldTip>
						) : (
							<p className={classNames({ "alert-field": isAlert })}>
								{Number(value)?.toFixed(3)}
							</p>
						);

						const renderNode = reportDetailIgnore[record.type].includes(
							"totalConsLsfo"
						) ? (
							"---"
						) : editIndex !== index ? (
							alertElement
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "totalConsLsfo", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				},
				{
					title: "MDO",
					dataIndex: "totalConsMdo",
					key: "totalConsMdo",
					align: "left",
					render(value, record, index) {
						const isAlert =
							record.alertCols.includes("totalConsMdo") && record.tipConsMdo;
						const alertElement = isAlert ? (
							<AlertFieldTip
								title="对比值:"
								content={String(record.tipConsMdo.toFixed(3))}
							>
								<p className={classNames({ "alert-field": isAlert })}>
									{Number(value)?.toFixed(3)}
								</p>
							</AlertFieldTip>
						) : (
							<p className={classNames({ "alert-field": isAlert })}>
								{Number(value)?.toFixed(3)}
							</p>
						);

						const renderNode = reportDetailIgnore[record.type].includes(
							"totalConsMdo"
						) ? (
							"---"
						) : editIndex !== index ? (
							alertElement
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "totalConsMdo", val);
								}}
							/>
						);

						return <div>{renderNode}</div>;
					}
				},
				{
					title: "MGO",
					dataIndex: "totalConsMgo",
					key: "totalConsMgo",
					align: "left",
					render(value, record, index) {
						const isAlert =
							record.alertCols.includes("totalConsMgo") && record.tipConsMgo;
						const alertElement = isAlert ? (
							<AlertFieldTip
								title="对比值:"
								content={String(record.tipConsMgo.toFixed(3))}
							>
								<p className={classNames({ "alert-field": isAlert })}>
									{Number(value)?.toFixed(3)}
								</p>
							</AlertFieldTip>
						) : (
							<p className={classNames({ "alert-field": isAlert })}>
								{Number(value)?.toFixed(3)}
							</p>
						);

						const renderNode = reportDetailIgnore[record.type].includes(
							"totalConsMgo"
						) ? (
							"---"
						) : editIndex !== index ? (
							alertElement
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "totalConsMgo", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				}
			]
		},
		{
			title: "BROB",
			align: "left",
			children: [
				{
					title: "HFO",
					dataIndex: "brobHfo",
					key: "brobHfo",
					align: "left",
					render(value, record, index) {
						return (
							<div>
								{editIndex !== index ? (
									<p
										className={classNames({
											"alert-field": record.alertCols.includes("brobHfo")
										})}
									>
										{value}
									</p>
								) : (
									<FleetInput
										value={value}
										onChange={(val: number) => {
											handleChangeReportTableItem(index, "brobHfo", val);
										}}
									/>
								)}
							</div>
						);
					}
				},
				{
					title: "LSFO",
					dataIndex: "brobLsfo",
					key: "brobLsfo",
					align: "left",
					render(value, record, index) {
						return (
							<div>
								{editIndex !== index ? (
									<p
										className={classNames({
											"alert-field": record.alertCols.includes("brobLsfo")
										})}
									>
										{value}
									</p>
								) : (
									<FleetInput
										value={value}
										onChange={(val: number) => {
											handleChangeReportTableItem(index, "brobLsfo", val);
										}}
									/>
								)}
							</div>
						);
					}
				},
				{
					title: "MDO",
					dataIndex: "brobMdo",
					key: "brobMdo",
					align: "left",
					render(value, record, index) {
						return (
							<div>
								{editIndex !== index ? (
									<p
										className={classNames({
											"alert-field": record.alertCols.includes("brobMdo")
										})}
									>
										{value}
									</p>
								) : (
									<FleetInput
										value={value}
										onChange={(val: number) => {
											handleChangeReportTableItem(index, "brobMdo", val);
										}}
									/>
								)}
							</div>
						);
					}
				},
				{
					title: "MGO",
					dataIndex: "brobMgo",
					key: "brobMgo",
					align: "left",
					render(value, record, index) {
						return (
							<div>
								{editIndex !== index ? (
									<p
										className={classNames({
											"alert-field": record.alertCols.includes("brobMgo")
										})}
									>
										{value}
									</p>
								) : (
									<FleetInput
										value={value}
										onChange={(val: number) => {
											handleChangeReportTableItem(index, "brobMgo", val);
										}}
									/>
								)}
							</div>
						);
					}
				}
			]
		},
		{
			title: "Draft",
			align: "left",
			children: [
				{
					title: "Mean",
					dataIndex: "draftMean",
					key: "draftMean",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"draftMean"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("draftMean")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "draftMean", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				},
				{
					title: "Aft",
					dataIndex: "draftAft",
					key: "draftAft",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"draftAft"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("draftAft")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "draftAft", val);
								}}
							/>
						);

						return <div>{renderNode}</div>;
					}
				},
				{
					title: "Fore",
					dataIndex: "draftFore",
					key: "draftFore",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"draftFore"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("draftFore")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "draftFore", val);
								}}
							/>
						);

						return <div>{renderNode}</div>;
					}
				}
			]
		},
		{
			title: "Course",
			dataIndex: "course",
			key: "course",
			align: "left",
			render(value, record, index) {
				const renderNode = reportDetailIgnore[record.type].includes(
					"course"
				) ? (
					"---"
				) : editIndex !== index ? (
					<p
						className={classNames({
							"alert-field": record.alertCols.includes("course")
						})}
					>
						{value}
					</p>
				) : (
					<FleetInput
						value={value}
						onChange={(val: number) => {
							handleChangeReportTableItem(index, "course", val);
						}}
					/>
				);

				return <div>{renderNode}</div>;
			}
		},
		{
			title: "ME",
			align: "left",
			children: [
				{
					title: "MCR",
					dataIndex: "meMcr",
					key: "meMcr",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"meMcr"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("meMcr")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "meMcr", val);
								}}
							/>
						);

						return <div>{renderNode}</div>;
					}
				},
				{
					title: "SlipSLR",
					dataIndex: "meSlipSlr",
					key: "meSlipSlr",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"meSlipSlr"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("meSlipSlr")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "meSlipSlr", val);
								}}
							/>
						);

						return <div>{renderNode}</div>;
					}
				},
				{
					title: "MePowerSLR",
					dataIndex: "mePowerSlr",
					key: "mePowerSlr",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"mePowerSlr"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("mePowerSlr")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "mePowerSlr", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				},
				{
					title: "AvgRpmSLR",
					dataIndex: "meAvgRpmSlr",
					key: "meAvgRpmSlr",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"meAvgRpmSlr"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("meAvgRpmSlr")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "meAvgRpmSlr", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				}
			]
		},
		{
			title: "WIND",
			align: "left",
			children: [
				{
					title: "DIR",
					dataIndex: "windDir",
					key: "windDir",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"windDir"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("windDir")
								})}
							>
								{value}
							</p>
						) : (
							<Select
								value={value}
								options={wsDirectOptions}
								onChange={(val: NRDirItemType) => {
									handleChangeReportTableItem(index, "windDir", val);
								}}
							/>
						);

						return <div>{renderNode}</div>;
					}
				},
				{
					title: "BF",
					dataIndex: "windBf",
					key: "windBf",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"windBf"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("windBf")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "windBf", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				}
			]
		},
		{
			title: "SeaHT(m)",
			dataIndex: "seaHt",
			key: "seaHt",
			align: "left",
			render(value, record, index) {
				const renderNode = reportDetailIgnore[record.type].includes("seaHt") ? (
					"---"
				) : editIndex !== index ? (
					<p
						className={classNames({
							"alert-field": record.alertCols.includes("seaHt")
						})}
					>
						{value}
					</p>
				) : (
					<FleetInput
						value={value}
						onChange={(val: number) => {
							handleChangeReportTableItem(index, "seaHt", val);
						}}
					/>
				);
				return <div>{renderNode}</div>;
			}
		},
		{
			title: "SWELL",
			align: "left",
			children: [
				{
					title: "Dir",
					dataIndex: "swellDir",
					key: "swellDir",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"swellDir"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("swellDir")
								})}
							>
								{value}
							</p>
						) : (
							<Select
								value={value}
								options={wsDirectOptions}
								onChange={(val: NRDirItemType) => {
									handleChangeReportTableItem(index, "swellDir", val);
								}}
							/>
						);

						return <div>{renderNode}</div>;
					}
				},
				{
					title: "HT(m)",
					dataIndex: "swellHt",
					key: "swellHt",
					align: "left",
					render(value, record, index) {
						const renderNode = reportDetailIgnore[record.type].includes(
							"swellHt"
						) ? (
							"---"
						) : editIndex !== index ? (
							<p
								className={classNames({
									"alert-field": record.alertCols.includes("swellHt")
								})}
							>
								{value}
							</p>
						) : (
							<FleetInput
								value={value}
								onChange={(val: number) => {
									handleChangeReportTableItem(index, "swellHt", val);
								}}
							/>
						);
						return <div>{renderNode}</div>;
					}
				}
			]
		},
		{
			title: "ETA(UTC)",
			dataIndex: "eta",
			key: "eta",
			width: 200,
			align: "left",
			render(value, record, index) {
				const renderNode = reportDetailIgnore[record.type].includes("eta") ? (
					"---"
				) : editIndex !== index ? (
					<p
						className={classNames({
							"alert-field": record.alertCols.includes("eta")
						})}
					>
						{value ? dayjs(value).format("YYYY-MM-DD HH:mm") : ""}
					</p>
				) : (
					<FleetTimePicker
						value={value ? dayjs(value) : null}
						onChange={(val) => {
							handleChangeReportTableItem(index, "eta", dayjs(val).valueOf());
						}}
					/>
				);
				return <div>{renderNode}</div>;
			}
		},
		{
			title: "Next Port",
			dataIndex: "nextPortName",
			key: "nextPortName",
			width: 200,
			align: "left",
			render(value, record, index) {
				const renderNode = reportDetailIgnore[record.type].includes(
					"nextPortName"
				)
					? "---"
					: value;
				return (
					<div
						className={classNames({
							"alert-field": record.alertCols.includes("nextPortName")
						})}
					>
						{renderNode}
						{/* {editIndex !== index ? (
							<p>{value}</p>
						) : (
							<CommonPortSelect
								activeItems={record}
								showKey={"nextPortName"}
								onFinish={(newItem, operateType) => {
									console.log(newItem);
									handleChangeReportTableItem(
										index,
										"nextPortName",
										newItem.portName
									);
									handleChangeReportTableItem(
										index,
										"nextPortCode",
										newItem.portCode
									);
								}}
							/>
						)} */}
					</div>
				);
			}
		},
		{
			title: "Not Good Wx",
			dataIndex: "notGoodWx",
			key: "notGoodWx",
			align: "left",
			render(value, record, index) {
				return (
					<NrButton
						className={classNames({
							"alert-field": record.alertCols.includes("notGoodWx")
						})}
						disabled={editIndex !== index}
						value={value}
						onChange={(val) => {
							handleChangeReportTableItem(index, "notGoodWx", val);
						}}
					/>
				);
			}
		},
		{
			title: "DGO · FO",
			dataIndex: "dgoFo",
			key: "dgoFo",
			align: "left",
			render(value, record, index) {
				return (
					<NrButton
						className={classNames({
							"alert-field": record.alertCols.includes("dgoFo")
						})}
						disabled={editIndex !== index}
						value={value}
						onChange={(val) => {
							handleChangeReportTableItem(index, "dgoFo", val);
						}}
					/>
				);
			}
		},
		{
			title: "In ECA",
			dataIndex: "inEca",
			key: "inEca",
			align: "left",
			render(value, record, index) {
				return (
					<NrButton
						className={classNames({
							"alert-field": record.alertCols.includes("inEca")
						})}
						disabled={editIndex !== index}
						value={value}
						onChange={(val) => {
							handleChangeReportTableItem(index, "inEca", val);
						}}
					/>
				);
			}
		},
		{
			title: "Exclude",
			dataIndex: "exclude",
			key: "exclude",
			align: "left",
			render(value, record, index) {
				return (
					<NrButton
						className={classNames({
							"alert-field": record.alertCols.includes("exclude")
						})}
						disabled={editIndex !== index}
						value={value}
						onChange={(val) => {
							handleChangeReportTableItem(index, "exclude", val);
						}}
					/>
				);
			}
		}
	];

	const noonReportOpColumns: ColumnsType<NoonDetailsReportItemType> = [
		{
			dataIndex: "option",
			width: 120,
			title: "Action",
			key: "option",
			align: "center",
			fixed: "right",
			render: (value, record, index) => {
				return (
					<div className="detail-table-action">
						<Icon
							type="icon-edite"
							onClick={() => {
								handleItemEditClick(record, index);
							}}
						/>
						<Popover
							render={(setIsOpen) => {
								return (
									<Toast
										label="Are you sure delete this item?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											setIsOpen(false);
											handleItemDeleteClick(record, index);
										}}
									/>
								);
							}}
						>
							<Icon type="icon-delete" />
						</Popover>
						<Icon
							type="icon-copy"
							onClick={() => {
								handleItemCopyClick(record, index);
							}}
						/>
					</div>
				);
			}
		}
	];

	// 可编辑行索引
	const [editIndex, setEditIndex] = useState<number>(-1);

	// 编辑单条报文
	const handleItemEditClick = (
		record: NoonDetailsReportItemType,
		index: number
	) => {
		handleIsCopyChange(false);
		handleDrIndexChange(index);
		handleArDialogVisibleChange(true);
	};

	// 删除单条报文
	const handleItemDeleteClick = async (
		record: NoonDetailsReportItemType,
		index: number
	) => {
		let apiStr;
		switch (record.type) {
			case "COSP":
				apiStr = "deleteLegCospReportApi";
				break;
			case "EOSP":
				apiStr = "deleteLegEospReportApi";
				break;
			case "NOON":
				apiStr = "deleteLegNoonReportApi";
				break;
			case "RESUME":
				apiStr = "deleteLegResumeReportApi";
				break;
			case "STOP":
				apiStr = "deleteLegStopReportApi";
				break;
			case "Speed Change":
				apiStr = "deleteLegScReportApi";
				break;
		}
		onRequest(
			apiStr,
			{ id: record.id },
			handleRequestFront,
			() => {
				updateDataReportList();
			},
			handleRequestError,
			dispatch,
			navigate
		);
	};

	// 复制单条报文
	const handleItemCopyClick = async (
		record: NoonDetailsReportItemType,
		index: number
	) => {
		handleIsCopyChange(true);
		handleDrIndexChange(index);
		handleArDialogVisibleChange(true);
	};

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		props.updateDataReportList();
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleUpdateReport = (data: NoonDetailsReportItemType) => {
		let apiStr;
		switch (data.type) {
			case "COSP":
				apiStr = "updateLegCospReportApi";
				break;
			case "EOSP":
				apiStr = "updateLegEospReportApi";
				break;
			case "NOON":
				apiStr = "updateLegNoonReportApi";
				break;
			case "RESUME":
				apiStr = "updateLegResumeReportApi";
				break;
			case "STOP":
				apiStr = "updateLegStopReportApi";
				break;
			case "Speed Change":
				apiStr = "updateLegScReportApi";
				break;
		}
		onRequest(
			apiStr,
			data,
			handleRequestFront,
			() => {
				props.updateDataReportList();
			},
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const handleRowDbClick = async (
		data: NoonDetailsReportItemType,
		index: number
	) => {
		if (editIndex === -1) {
			setEditIndex(index);
		} else {
			setEditIndex(-1);
			// 更新报文信息
			handleUpdateReport(data);
		}
	};

	return {
		detailsDatasource,
		noonDetailReportColumns,
		noonReportOpColumns,
		handleRowDbClick
	};
};

export default useIndex;
