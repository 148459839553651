import React, { useState } from "react";
import classNames from "classnames";
import { FilePreviewDialog } from "@/common";
import { SplitPanel } from "../../common";
import { LegBasicInfo } from "../../components";
import { LegDetailsMenuItemType } from "../../components/siderMenu/type";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";
import { RoutePlanDialog, VoyPlanTable } from "./common";

const Plan: React.FC<{ activeTab: LegDetailsMenuItemType }> = ({
	activeTab
}) => {
	const {
		legDetailsData,
		queryData,
		isHideRpDialog,
		handleRpDialogClose,
		handleRpDialogOpen,
		handleVoyPlanDelete,
		voyPlanTableListData,
		previewPdfId,
		handleGetVoyPlanTableData
	} = useIndex(activeTab);

	// 组件测试代码
	const [visibleDialog, setVisibleDialog] = useState<boolean>(false);

	const handlePreviewFileClick = () => {
		setVisibleDialog(true);
	};

	return (
		<div
			className={classNames(style["leg-plan"], {
				"plan-active-tab": activeTab === "plan"
			})}
		>
			<RoutePlanDialog
				id={previewPdfId}
				hide={isHideRpDialog}
				onClose={handleRpDialogClose}
			/>

			<div className="leg-plan-left">
				<LegBasicInfo
					vesselName={legDetailsData?.voyage?.vesselName}
					voyageNo={legDetailsData?.voyage?.voyageNo}
					legTypeName={queryData.legType === "B" ? "Ballast" : "Laden"}
				/>
				<div className="table-container">
					{voyPlanTableListData.map((voyPlanTableData, index) => {
						return (
							<VoyPlanTable
								key={index}
								data={voyPlanTableData}
								onPreviewClick={(reportId) => handleRpDialogOpen(reportId)}
								onDeleteClick={(reportId) => handleVoyPlanDelete(reportId)}
								onSyncStatusChange={handleGetVoyPlanTableData}
							/>
						);
					})}
				</div>

				{/* 组件测试代码 */}
				<FilePreviewDialog
					hide={!visibleDialog}
					fileUrl="http://localhost:8888/w_1.docx"
					onClose={() => setVisibleDialog(false)}
				/>
				{/* <button onClick={handlePreviewFileClick}>预览文件</button> */}
			</div>

			{/* <SplitPanel
				initialLeftWidth={600}
				leftChildren={
					<div className="leg-plan-left">
						<LegBasicInfo
							vesselName={legDetailsData?.voyage?.vesselName}
							voyageNo={legDetailsData?.voyage?.voyageNo}
							legTypeName={queryData.legType === "B" ? "Ballast" : "Laden"}
						/>
						{voyPlanTableListData.map((voyPlanTableData, index) => {
							return (
								<VoyPlanTable
									key={index}
									data={voyPlanTableData}
									onPreviewClick={(reportId) => handleRpDialogOpen(reportId)}
									onDeleteClick={(reportId) => handleVoyPlanDelete(reportId)}
								/>
							);
						})}
						<FilePreviewDialog
							hide={!visibleDialog}
							onClose={() => setVisibleDialog(false)}
						/>
						<button onClick={handlePreviewFileClick}>预览文件</button>
					</div>
				}
				rightChildren={<div className="leg-plan-right">地图</div>}
			/> */}
		</div>
	);
};

export default Plan;
