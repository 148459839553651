import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import debounce from "lodash/debounce";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { VoyageFilterProps, VoyageFilterItemType } from "../components";
import { Toast, Popover } from "@/components";
import { VoyDataType, VoyTableItemType } from "../type";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState<VoyageFilterItemType>({
		imo: "",
		vesselName: "",
		companyId: "",
		companyName: "",
		voyageType: null,
		status: null,
		service: null,
		role: null,
		startOrderDate: "",
		endOrderDate: ""
	});

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const cacheSource = useRef<{ [index: number]: VoyTableItemType[] }>({});

	const handleFilterCommit: VoyageFilterProps["onCommit"] = (item) => {
		setFilter((prev) => {
			return { ...prev, ...item };
		});
	};

	const handleRowDblClick: VoyageFilterProps["onAdd"] = (type, item) => {
		// let query = type === "edit" ? `?id=${item?.id}` : "";

		if (type === "edit") {
			const query = `?id=${item?.id}`;
			navigate("/layout/voy/details" + query);
		} else if (type === "add") {
		}
	};

	const onSelectChange = (newSelectedRowKeys: number[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const dataSource = useMemo(() => {
		return cacheSource.current[pager.pageNum];
	}, [pager]);

	const getVoyagePageFront = () => {
		setLoading(true);
	};

	const getVoyagePageSuccess = (response) => {
		console.log("请求列表数据", response);

		let total = 0;
		if (!Array.isArray(response?.data)) {
			cacheSource.current[1] = response?.data?.records;
			total = response?.data?.total;
			console.log("response?.data?.records", response?.data?.records);
		} else {
			cacheSource.current = {};
		}
		setPager((prev) => ({ ...prev, total, pageNum: pager.pageNum }));
		setLoading(false);
	};

	const getVoyagePageFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};

	const getVoyagePage = (params?: Partial<VoyageFilterProps["item"]>) => {
		onRequest(
			"getVoyagePageApi",
			{
				...filter,
				...params,
				pageSize: pager.pageSize,
				pageNum: pager.pageNum
			},
			getVoyagePageFront,
			getVoyagePageSuccess,
			getVoyagePageFailed,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions: VoyageFilterProps["onSearch"] = (key, value) => {
			cacheSource.current = {};
			handleFilterCommit?.({ [key]: value });
		};
		return debounce(loadOptions, 500);
	}, []);

	useEffect(() => {
		getVoyagePage();
	}, [filter]);

	const onPageChange = (page: number, size: number) => {
		onRequest(
			"getVoyagePageApi",
			{
				...filter,
				pageSize: size,
				pageNum: page
			},
			getVoyagePageFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.records;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getVoyagePageFailed,
			dispatch,
			navigate
		);
	};

	// const opereteColumns: ColumnsType<VoyDataType> = useMemo(() => {
	// 	return [
	// 		{
	// 			dataIndex: "option",
	// 			width: 120,
	// 			title: "Action",
	// 			key: "option",
	// 			align: "center",
	// 			fixed: "right",
	// 			render: (value, record, index) => {
	// 				return (
	// 					<div>
	// 						<Popover
	// 							render={(setIsOpen) => {
	// 								return (
	// 									<Toast
	// 										label="Are you sure delete this item?"
	// 										onCancel={() => {
	// 											setIsOpen(false);
	// 										}}
	// 										onConfirm={() => {
	// 											setIsOpen(true);
	// 										}}
	// 									/>
	// 								);
	// 							}}
	// 						>
	// 							<span className="fleet-delete">delete</span>
	// 						</Popover>
	// 					</div>
	// 				);
	// 			}
	// 		}
	// 	];
	// }, []);

	const [isAddHide, setIsAddHide] = useState<boolean>(true);
	const handleAddClick = () => {
		setIsAddHide(false);
	};
	const handleCloseAdd = () => {
		setIsAddHide(true);
	};
	const handleAddSuccess = () => {
		setIsAddHide(true);
		getVoyagePage();
	};

	return {
		loading,
		filter,
		handleFilterCommit,
		handleRowDblClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		getVoyagePage,
		dataSource,
		onSearch,
		// opereteColumns,
		isAddHide,
		handleAddClick,
		handleCloseAdd,
		handleAddSuccess
	};
};
export default useIndex;
