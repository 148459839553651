import React, { FC } from "react";
import { Table } from "@/components";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";
import { NoonFuelFormProps } from "./type";

const NoonFuelForm: FC<NoonFuelFormProps> = (props) => {
	const { dataSource } = props;

	const { opereteColumns } = useIndex(props);

	return (
		<div className={style["noon-fuel-form-container"]}>
			<div className="noon-fuel-title">Fuel</div>
			<div className="noon-fuel-form">
				<Table
					id="id"
					dataSource={dataSource}
					// rowSelection={{
					// 	selectedRowKeys,
					// 	onChange: onSelectChange
					// }}
					className="noon-fuel-table"
					columns={opereteColumns}
					scroll={{ x: 800, y: 650 }}
					pagination={false}
				/>
			</div>
		</div>
	);
};

export default NoonFuelForm;
