import React, { FC } from "react";
import style from "./index.module.less";
import {
	RouteCommonToolbarMeteoSwitchMap,
	RouteCommonToolbarProps
} from "./type";
import {
	rangingControllOptions,
	meteorologicalOptions,
	legendVisibleOptions
} from "./source";
import { Button, Icon, Popover, Radio, Tooltip } from "@/components";
import useIndex from "./hooks/useIndex";
import {
	RouteCommonToolbarList,
	RouteCommonToolbarListProps,
	RouteCommonToolbarSelectGroup
} from "./components";
import classNames from "classnames";

const RouteCommonToolbar: FC<RouteCommonToolbarProps> = ({
	meteoStatus,
	onMeteoStatusChange,
	onControllChange,
	onRangeSelect,
	legendVisible,
	onLegendVisibleChange,
	onPointSelect
}) => {
	const {
		open,
		selectRows,
		dataSource,
		activeRangeIndex,
		controllStatus,
		handleControllChange,
		handleRangeSelect,
		pointStatus,
		handlePointSelect,
		handleSelect,
		handleOpen,
		handleGroupSelect,
		updateMapLayers
	} = useIndex(
		meteoStatus,
		onControllChange,
		onRangeSelect,
		onPointSelect,
		onMeteoStatusChange
	);
	return (
		<div className={style["routeCommon-operater"]}>
			<div
				className={classNames("routeCommon-operater-list", {
					empty: dataSource?.meteoItems?.length === 0,
					hidden: open
				})}
			>
				<RouteCommonToolbarList
					items={dataSource?.meteoItems}
					selectMeteos={meteoStatus}
					onSelect={handleSelect}
				/>
				<RouteCommonToolbarList
					items={dataSource?.controllItems}
					selectControlls={
						controllStatus as RouteCommonToolbarListProps["items"]
					}
					onSelect={handleSelect}
				/>
			</div>
			<div className="routeCommon-operater-container">
				<Popover
					open={open}
					onOpenChange={handleOpen}
					placement="left-start"
					className={style["routeCommon-toolbar"]}
					children={
						<Button type="icon" size="small">
							<Icon type="icon-layer" color="#fff" />
						</Button>
					}
					render={(setIsOpen) => (
						<RouteCommonToolbarSelectGroup
							selectRows={selectRows}
							onSelect={handleGroupSelect}
							onConfirm={updateMapLayers}
						/>
					)}
				/>
				<ul className="operater-actions">
					{rangingControllOptions?.map((item, index) => {
						return (
							<Tooltip
								key={item?.value}
								trigger="hover"
								placement="left"
								title={item?.label}
								buttonProps={{
									size: "small",
									type: "icon",
									className: classNames("operater-item", {
										active: activeRangeIndex === item?.value
									}),
									onClick: () => {
										handleRangeSelect(item?.value);
									},
									children: (
										<Icon
											type={item?.icon}
											color={
												activeRangeIndex === item?.value ? "#ffffff" : "#355691"
											}
											size={16}
										/>
									)
								}}
							/>
						);
					})}
				</ul>
				<ul className="operater-actions">
					{legendVisibleOptions?.map((item, index) => {
						return (
							<Tooltip
								key={item?.value}
								trigger="hover"
								placement="left"
								title={item?.label}
								buttonProps={{
									size: "small",
									type: "icon",
									className: classNames("operater-item", {
										active:
											(item?.value === "legend" && legendVisible) ||
											(item?.value === "point" && pointStatus)
									}),
									onClick: () => {
										// handleRangeSelect(item?.value);
										if (item?.value === "legend") {
											onLegendVisibleChange?.();
										}

										if (item?.value === "point") {
											handlePointSelect(!pointStatus);
										}
									},
									children: (
										<Icon
											type={
												item?.value === "legend" && legendVisible
													? "icon-legend_show"
													: item?.icon
											}
											color={
												(item?.value === "legend" && legendVisible) ||
												(item?.value === "point" && pointStatus)
													? "#ffffff"
													: "#355691"
											}
											size={16}
										/>
									)
								}}
							/>
						);
					})}
				</ul>
			</div>
		</div>
	);
	return (
		<Popover
			placement="left-start"
			className={style["routeCommon-toolbar"]}
			children={
				<Button type="icon" size="small">
					<Icon type="icon-layer" color="#fff" />
				</Button>
			}
			render={(setIsOpen) => {
				return (
					<div className={style["routeCommon-meteorological"]}>
						<div className="meteorological-container">
							{meteorologicalOptions?.map((item, index) => {
								return (
									<Radio
										key={item?.value}
										className="meteorological-radio"
										value={
											meteoStatus[item?.value] ===
											RouteCommonToolbarMeteoSwitchMap.On
										}
										label={item?.label}
										onChange={() => {
											onMeteoStatusChange?.(item?.value);
										}}
									/>
								);
							})}
						</div>
						<div className="meteorological-container">
							{controllOptions?.map((item, index) => {
								return (
									<Radio
										key={item?.value}
										value={controllStatus?.includes(item?.value)}
										label={item?.label}
										className="meteorological-radio"
										onChange={() => {
											handleControllChange(item);
										}}
									/>
								);
							})}
						</div>
						<div className="meteorological-container ranging">
							{rangingControllOptions?.map((item, index) => {
								return (
									<Radio
										key={item?.value}
										value={activeRangeIndex === item?.value}
										label={item?.label}
										className="meteorological-radio"
										onChange={() => {
											handleRangeSelect(item?.value);
										}}
									/>
								);
							})}
						</div>
						<div className="meteorological-container">
							{legendVisibleOptions?.map((item, index) => {
								return (
									<Radio
										key={item?.value}
										value={
											(item?.value === "legend" && legendVisible) ||
											(item?.value === "point" && pointStatus)
										}
										label={item?.label}
										className="meteorological-radio"
										onChange={() => {
											if (item?.value === "legend") {
												onLegendVisibleChange?.();
											}

											if (item?.value === "point") {
												handlePointSelect(!pointStatus);
											}
										}}
									/>
								);
							})}
						</div>
					</div>
				);
			}}
		/>
		// <div className={style["routeCommon-toolbar"]}>
		// 	<ul className="meteorological-actions">
		// 		{meteorologicalOptions?.map((item, index) => {
		// 			return (
		// 				<Tooltip
		// 					key={item?.value}
		// 					trigger="hover"
		// 					placement="left"
		// 					title={item?.label}
		// 					buttonProps={{
		// 						size: "small",
		// 						type: "icon",
		// 						className: classnames("meteorological-item", {
		// 							active:
		// 								meteoStatus[item?.value] ===
		// 								RouteCommonToolbarMeteoSwitchMap.On
		// 						}),
		// 						onClick: () => {
		// 							onMeteoStatusChange?.(item?.value);
		// 						},
		// 						children: (
		// 							<Icon
		// 								type={item?.icon}
		// 								color={
		// 									meteoStatus[item?.value] ===
		// 									RouteCommonToolbarMeteoSwitchMap.On
		// 										? "#ffffff"
		// 										: "#355691"
		// 								}
		// 								size={16}
		// 							/>
		// 						)
		// 					}}
		// 				/>
		// 			);
		// 		})}
		// 	</ul>
		// 	<ul className="meteorological-actions">
		// 		{controllOptions?.map((item, index) => {
		// 			return (
		// 				<Tooltip
		// 					key={item?.value}
		// 					trigger="hover"
		// 					placement="left"
		// 					title={item?.label}
		// 					buttonProps={{
		// 						size: "small",
		// 						type: "icon",
		// 						className: classnames("meteorological-item", {
		// 							active: controllStatus?.includes(item?.value)
		// 						}),
		// 						onClick: () => {
		// 							handleControllChange(item);
		// 							// onMeteoStatusChange?.(item?.value, !meteoStatus?.[item?.value]);
		// 						},
		// 						children: (
		// 							<Icon
		// 								type={item?.icon}
		// 								color={
		// 									controllStatus?.includes(item?.value)
		// 										? "#ffffff"
		// 										: "#355691"
		// 								}
		// 								size={16}
		// 							/>
		// 						)
		// 					}}
		// 				/>
		// 			);
		// 		})}
		// 	</ul>
		// 	<ul className="meteorological-actions">
		// 		{rangingControllOptions?.map((item, index) => {
		// 			return (
		// 				<Tooltip
		// 					key={item?.value}
		// 					trigger="hover"
		// 					placement="left"
		// 					title={item?.label}
		// 					buttonProps={{
		// 						size: "small",
		// 						type: "icon",
		// 						className: classnames("meteorological-item", {
		// 							active: activeRangeIndex === item?.value
		// 						}),
		// 						onClick: () => {
		// 							handleRangeSelect(item?.value);
		// 						},
		// 						children: (
		// 							<Icon
		// 								type={item?.icon}
		// 								color={
		// 									activeRangeIndex === item?.value ? "#ffffff" : "#355691"
		// 								}
		// 								size={16}
		// 							/>
		// 						)
		// 					}}
		// 				/>
		// 			);
		// 		})}
		// 	</ul>
		// 	<ul className="meteorological-actions">
		// 		{legendVisibleOptions?.map((item, index) => {
		// 			return (
		// 				<Tooltip
		// 					key={item?.value}
		// 					trigger="hover"
		// 					placement="left"
		// 					title={item?.label}
		// 					buttonProps={{
		// 						size: "small",
		// 						type: "icon",
		// 						className: classnames("meteorological-item", {
		// 							active:
		// 								(item?.value === "legend" && legendVisible) ||
		// 								(item?.value === "point" && pointStatus)
		// 						}),
		// 						onClick: () => {
		// 							// handleRangeSelect(item?.value);
		// 							if (item?.value === "legend") {
		// 								onLegendVisibleChange?.();
		// 							}

		// 							if (item?.value === "point") {
		// 								handlePointSelect(!pointStatus);
		// 							}
		// 						},
		// 						children: (
		// 							<Icon
		// 								type={
		// 									item?.value === "legend" && legendVisible
		// 										? "fleet-legend_show"
		// 										: item?.icon
		// 								}
		// 								color={
		// 									(item?.value === "legend" && legendVisible) ||
		// 									(item?.value === "point" && pointStatus)
		// 										? "#ffffff"
		// 										: "#355691"
		// 								}
		// 								size={16}
		// 							/>
		// 						)
		// 					}}
		// 				/>
		// 			);
		// 		})}
		// 	</ul>
		// </div>
	);
};

export default RouteCommonToolbar;
