import React from "react";
import style from "./index.module.less";

interface ReportData {
	label: string;
	data: string;
	isShow: boolean;
}

interface ReportSingleRowDescriptionsProps {
	data: ReportData[];
}

const ReportSingleRowDescriptions: React.FC<
	ReportSingleRowDescriptionsProps
> = ({ data }) => {
	return (
		<div className={style["report-single-row-descriptions"]}>
			{data
				.filter((item) => item.isShow)
				.map((item, index) => (
					<div key={index} className="description-item">
						{item.label && <span className="label">{item.label}:</span>}
						<span className="data">{item.data}</span>
					</div>
				))}
		</div>
	);
};

export default ReportSingleRowDescriptions;
