import React, { FC } from "react";
import style from "./index.module.less";
import { TwoColTableProps } from "./type";

const TwoColTable: React.FC<TwoColTableProps> = ({ data }) => {
	return (
		<div className={style["twoColTable"]}>
			{data.map((item, index) => (
				<div className="tr" key={index}>
					<span className="td label">{item.label}</span>
					<span className="td value">{item.value}</span>
				</div>
			))}
		</div>
	);
};
export default TwoColTable;
