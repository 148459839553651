import React from "react";
import useIndex from "./hooks/useIndex";
import { Button, Select, Dialog, Icon } from "@/components";
import { FleetUtcTimePicker } from "@/pages/leg/business/details/components";
import { LatLongInput } from "@/common";
import { AddDevReportDialogProps } from "./type";
import style from "./index.module.less";

const AddDevReportDialog = ({
	visible,
	onClose,
	onConfirm
}: AddDevReportDialogProps) => {
	const {
		startCoordinate,
		endCoordinate,
		handleStartTimeChange,
		handleEndTimeChange,
		currentRouteId,
		routeOptions,
		handleRouteSelect,
		currentCpId,
		handleCpSelect,
		cpOptions,
		handleConfirmClick,
		handleCoordinateChange,
		timeRange
	} = useIndex(onConfirm);

	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={onClose}
		>
			<div className={style["add-dev-report-dialog"]}>
				<div className="fleet-header">
					<div className="report-add-header">
						<span>Add Dev.Report</span>
						<Icon
							type="icon-close2"
							className="header-close"
							onClick={onClose}
						/>
					</div>
				</div>
				<div className="report-add-main">
					<div className="form-item">
						<span className="form-item-label">Start Deviation</span>
						<FleetUtcTimePicker
							minDate={timeRange.start}
							maxDate={timeRange.end}
							value={startCoordinate.dateTime}
							onChange={handleStartTimeChange}
							className="form-item-input"
						/>
						<LatLongInput
							className="form-item-select"
							value={startCoordinate.lat}
							type="latitude"
							onChange={(val) => handleCoordinateChange("start", "lat", val)}
						/>
						<LatLongInput
							className="form-item-select"
							value={startCoordinate.lon}
							type="longitude"
							onChange={(val) => handleCoordinateChange("start", "lng", val)}
						/>
					</div>
					<div className="form-item">
						<span className="form-item-label">Back on Track</span>
						<FleetUtcTimePicker
							minDate={timeRange.start}
							maxDate={timeRange.end}
							value={endCoordinate.dateTime}
							onChange={handleEndTimeChange}
							className="form-item-input"
						/>
						<LatLongInput
							className="form-item-select"
							value={endCoordinate.lat}
							type="latitude"
							onChange={(val) => handleCoordinateChange("end", "lat", val)}
						/>
						<LatLongInput
							className="form-item-select"
							value={endCoordinate.lon}
							type="longitude"
							onChange={(val) => handleCoordinateChange("end", "lng", val)}
						/>
					</div>
					<div className="form-item">
						<span className="form-item-label">Leg</span>
						<Select
							className="form-item-select"
							options={routeOptions}
							value={currentRouteId}
							onChange={handleRouteSelect}
						/>
					</div>
					<div className="form-item">
						<span className="form-item-label">CP</span>
						<Select
							className="form-item-select"
							options={cpOptions}
							value={currentCpId}
							onChange={handleCpSelect}
						/>
					</div>
				</div>
				<div className="report-add-footer">
					<Button type="secondary" size="small" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={handleConfirmClick}>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default AddDevReportDialog;
