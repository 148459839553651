import { MutableRefObject } from "react";
import { addSourceToMap } from "../utils";
import { aisVesselTrackLayers } from "../source";
import { RouteCommonMapRefType } from "../type";
import { formatThousandthNumber } from "@/tools/amount";
import { dateToUtcString } from "@/tools/date";
import { GeoJSONSource } from "mapbox-gl";
import { AisTrackPointItemType, RouteCommonAisDataSourceType } from "../types";

const useAisTrack = (
	mapboxGl: MutableRefObject<mapboxgl.Map>,
	onFlyTo: (item: { lon: number; lat: number }) => void
) => {
	const loadSource = () => {
		addSourceToMap(mapboxGl?.current, {
			sourceName: "ais-vessel-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
		addSourceToMap(mapboxGl.current, {
			sourceName: "ais-vesselTrack-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
		addSourceToMap(mapboxGl.current, {
			sourceName: "ais-vesselTrackPointer-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
	};

	const loadlayers = () => {
		mapboxGl.current.addLayer({
			id: aisVesselTrackLayers["ais-vesselTrack-source"],
			type: "line",
			source: "ais-vesselTrack-source",
			layout: {
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				"line-color": "#6077A4",
				"line-width": 2
			}
		});
		mapboxGl.current.addLayer({
			id: aisVesselTrackLayers["ais-vesselTrackArrow-source"],
			type: "symbol",
			source: "ais-vesselTrack-source",
			layout: {
				"symbol-placement": "line",
				"symbol-spacing": 50, // 图标间隔，默认为250
				"icon-image": "aisVesselTrackArrowIcon", //箭头图标
				"icon-size": 1,
				"icon-rotate": -90
			}
		});
		mapboxGl.current.addLayer({
			id: aisVesselTrackLayers["ais-vesselTrackPointer-source"],
			source: "ais-vesselTrackPointer-source",
			type: "symbol",
			layout: {
				// 'symbol-placement': 'line',
				"symbol-spacing": 100, // 图标间隔，默认为250
				// 'icon-image': 'vessel-point-icon', //
				"icon-image": [
					"case",
					["==", ["get", "isWarning"], 1],
					"aisVesselPointerWarningIcon",
					"aisVesselPointerIcon"
				],
				"icon-size": 1
				// 'icon-rotate': -90
			}
		});

		mapboxGl.current.addLayer({
			id: aisVesselTrackLayers["ais-vesselTrackInfo-source"],
			type: "symbol",
			source: "ais-vesselTrackPointer-source",
			layout: {
				// 'symbol-placement': 'line',
				"symbol-spacing": 100, // 图标间隔，默认为250
				"icon-image": [
					"case",
					["==", ["get", "isWarning"], 1],
					"aisVesselTrackWarningIcon",
					"aisVesselInfoIcon"
				], //箭头图标
				"icon-size": 1,
				"text-field": [
					"concat",
					["get", "time"],
					"Z ",
					["get", "speed"],
					" knots"
				],
				"icon-offset": [100, 0],
				"text-offset": [10, 0],
				"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
				// 文字大小
				"text-size": 10
			},
			paint: {
				"text-color": [
					"case",
					["==", ["get", "isWarning"], 1],
					"#F33D15",
					"#333"
				]
			}
		});
		mapboxGl.current.addLayer({
			id: aisVesselTrackLayers["ais-vessel-source"],
			type: "symbol",
			source: "ais-vessel-source",
			layout: {
				"icon-image": "aisVesselSelectIcon",
				"icon-size": 0.6,
				"icon-rotate": ["get", "course"]
			}
		});
	};

	const handleAisTrackLoad: RouteCommonMapRefType["loadAisTracks"] = (item) => {
		// debugger;
		let direction = true;
		const trackLines: [number, number][] = [],
			trackPoints: GeoJSON.Feature<
				GeoJSON.Point,
				Omit<AisTrackPointItemType, "speed" | "time"> & {
					time: string;
					speed: string;
					direction: number;
				}
			>[] = [];
		let aisLines: GeoJSON.Feature<
			GeoJSON.MultiLineString | GeoJSON.LineString,
			{}
		>[] = [];

		if (item?.route && item?.route?.trackPoints?.length > 0) {
			for (let trackPoint of item?.route?.trackPoints) {
				trackLines.push([trackPoint.lon, trackPoint.lat]);
				trackPoints.push({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [trackPoint.lon, trackPoint.lat]
					},
					properties: {
						...trackPoint,
						speed: formatThousandthNumber(trackPoint?.speed),
						time: dateToUtcString(trackPoint?.time),
						direction: direction ? -100 : 100,
						isWarning: trackPoint?.isWarning
					}
				});
				direction = !direction;
			}
		}

		if (item?.route?.ais && item?.route?.ais?.features?.length > 0) {
			aisLines = item?.route?.ais?.features;
		}

		const currentTrackSource = mapboxGl.current.getSource<GeoJSONSource>(
			"ais-vesselTrack-source"
		);
		const currentTrackInfoSource = mapboxGl.current.getSource<GeoJSONSource>(
			"ais-vesselTrackPointer-source"
		);
		const currentVesselDataSource =
			mapboxGl.current.getSource<GeoJSONSource>("ais-vessel-source");
		currentTrackSource.setData({
			type: "FeatureCollection",
			features: aisLines
		});
		currentTrackInfoSource?.setData({
			type: "FeatureCollection",
			features: trackPoints
		});
		if (item?.current) {
			currentVesselDataSource?.setData({
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: [item?.current?.lon, item?.current?.lat]
						},
						properties: { ...item?.current }
					}
				]
			});
			onFlyTo?.(item?.current);
		} else {
			currentVesselDataSource?.setData({
				type: "FeatureCollection",
				features: []
			});
		}
	};

	return {
		loadSource,
		loadlayers,
		handleAisTrackLoad
	};
};

export default useAisTrack;
