import { CommonOptionType } from "@/types/common";
import { VoyBasicInfoItemType } from "./type";

export const voyTypeOptions: CommonOptionType<
	VoyBasicInfoItemType["voyageType"],
	VoyBasicInfoItemType["voyageType"]
>[] = [
	{ label: "TCT", value: "TCT" },
	{ label: "TC", value: "TC" }
];

export const serviceOptions: CommonOptionType<
	VoyBasicInfoItemType["service"],
	VoyBasicInfoItemType["service"]
>[] = [
	{ label: "Monitoring", value: "Monitoring" },
	{ label: "Routing", value: "Routing" },
	{ label: "Test", value: "Test" }
];

export const roleOptions: CommonOptionType<
	VoyBasicInfoItemType["customerRole"],
	VoyBasicInfoItemType["customerRole"]
>[] = [
	{ label: "Charterer", value: "Charterer" },
	{ label: "Owner", value: "Owner" }
];

export const initialData = {
	companyName: "",
	imo: null,
	status: null,
	vesselName: null,
	voyageNo: null,
	companyId: null,
	voyageType: null,
	service: null,
	customerRole: null,
	orderDate: null,
	masterEmail: "",
	customerEmail: ""
};
