import { Button, Dialog, FleetDatePicker, FormItem } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import dayjs, { type Dayjs } from "dayjs";
import { portTimeEditDialogProps } from "./type";
const PortTimeEditDialog: FC<portTimeEditDialogProps> = ({
	visible,
	onClose,
	onFinish,
	details,
	itemIndex,
	itemKey
}) => {
	const { loading, hanleCloseDialog, time, onChange, onConfirm } = useIndex(
		details,
		itemIndex,
		itemKey
	);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={() => {
				hanleCloseDialog(onClose);
			}}
			loading={loading}
		>
			<div className={styles["portTimeEditDialog"]}>
				<div className="portTimeEditDialog-header">
					<div>Edit</div>
					<div
						className="close-btn"
						onClick={() => {
							hanleCloseDialog(onClose);
						}}
					>
						×
					</div>
				</div>
				<div className="portTimeEditDialog-main">
					<FormItem label={itemKey?.toUpperCase() + ":"} type="row">
						<FleetDatePicker
							onChange={(val, timeStramp) => {
								onChange?.(val);
							}}
							value={time ? dayjs(time) : null}
							showTime={true}
							showHour={true}
							showMinute={true}
							allowClear
						/>
					</FormItem>
				</div>
				<div className="portTimeEditDialog-footer">
					<Button
						type="secondary"
						size="large"
						onClick={() => {
							hanleCloseDialog(onClose);
						}}
					>
						Cancel
					</Button>
					<Button
						size="large"
						onClick={() => {
							onConfirm(onClose, onFinish);
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default PortTimeEditDialog;
