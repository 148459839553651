import React from "react";
import classNames from "classnames";
import useIndex from "./hooks/useIndex";
import style from "./index.module.less";

const ImageContainer: React.FC<{ url: string }> = ({ url }) => {
	const { imgRef, isWidthGreater } = useIndex();

	return (
		<div className={style["fp-image-container"]}>
			<img
				className={classNames({ "width-greater": isWidthGreater })}
				ref={imgRef}
				src={url}
			/>
		</div>
	);
};

export default ImageContainer;
