import { menuOptions } from "./source";
import { LayoutNavMenuItemType, LayoutNavMenuProps } from "./type";
import style from "./index.module.less";
import React from "react";

const LayoutNavMenu: React.FC<LayoutNavMenuProps> = ({
	onClose,
	onExit,
	onClick
}) => {
	const handleClick = (item: LayoutNavMenuItemType) => {
		if (onClick) {
			onClick?.(item);
			return;
		}
		if (item?.value === "personal") {
			onClose?.();
		} else if (item?.value === "exit") {
			onExit?.();
		}
	};
	return (
		<ul className={style["layout-nav-menu"]}>
			{menuOptions?.map((item, index) => {
				return (
					<li
						key={index}
						className="menu-item"
						onClick={() => {
							handleClick(item);
						}}
					>
						<span className="menu-item-text">{item?.label}</span>
					</li>
				);
			})}
		</ul>
	);
};

export default LayoutNavMenu;
