export const routeSource = {
	"report-line-source": "report-line-layer"
};

export enum SigWaveColor {
	light = "#7AF4D8",
	moderate = "#CEFF85",
	heavy = "#FFE37F",
	severe = "#FF7F7F"
}
