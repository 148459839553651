import React, { useState, useEffect, useMemo } from "react";
import { ColumnsType } from "antd/es/table";
import { FleetTimePicker, Select, Popover, Toast } from "@/components";
import {
	LegSegmentItemType,
	LegSegmentTableType,
	LegCpListType,
	AddNoonSegmentProps
} from "../type";
import dayjs from "dayjs";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { CommonColumnChangeEvent } from "@/types/event";
import { FleetUtcTimePicker } from "../../../../../components";

const useIndex = (props: AddNoonSegmentProps) => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const opereteColumns: ColumnsType<LegSegmentItemType> = [
		{
			title: "Seg",
			dataIndex: "seg",
			key: "seg",
			width: 60,
			align: "center",
			render: (value, record, index) => {
				return <div>{value}</div>;
			}
		},
		{
			title: "DueDate",
			dataIndex: "dueDate",
			key: "dueDate",
			align: "center",
			render: (value, record, index) => {
				return (
					<div>
						{record.disabled ? (
							"---"
						) : (
							<FleetUtcTimePicker
								value={Number(value)}
								onChange={(val: LegSegmentItemType["dueDate"]) => {
									handleChangeSegmentTableItem(index, "dueDate", val);
									handleSegmentSort();
								}}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: "Plan",
			dataIndex: "cpId",
			key: "cpId",
			width: 200,
			align: "center",
			render: (value, record, index) => {
				return (
					<div>
						<Select
							value={value}
							options={cpOptions}
							onChange={(val: LegSegmentItemType["cpId"]) => {
								cpOptions.forEach((item, indey) => {
									if (val === item.value) {
										handleChangeSegmentTableItem(index, "seg", indey + 1);
									}
								});
								handleChangeSegmentTableItem(index, "cpId", val);
							}}
						/>
					</div>
				);
			}
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			width: 100,
			align: "center",
			render: (value, record, index) => {
				return (
					<Popover
						render={(setIsOpen) => {
							return (
								<Toast
									label="Are you sure delete this item?"
									onCancel={() => {
										setIsOpen(false);
									}}
									onConfirm={() => {
										handleDeleteSegmentClick(index);
										setIsOpen(false);
									}}
								/>
							);
						}}
					>
						<div className="delete-btn">-</div>
					</Popover>
				);
			}
		}
	];

	// cpList数据
	const [legCpListData, setLegCpListData] = useState<LegCpListType>([]);

	// segment数据
	const [segmentTableData, setSegmentTableData] = useState<LegSegmentTableType>(
		[]
	);

	// segment排序
	const handleSegmentSort = () => {
		const segmentsSorted = segmentTableData.slice(0, -1);
		segmentsSorted.sort((a, b) => Number(a.dueDate) - Number(b.dueDate));
		setSegmentTableData([
			...segmentsSorted,
			segmentTableData[segmentTableData.length - 1]
		]);
	};

	const cpOptions = useMemo(() => {
		return legCpListData.map((cp, index) => {
			return {
				label: `${cp.speedCategory} ${cp.speed}kts ${cp.foConsumption}mt ${cp.dgoConsumption}mt`,
				value: cp.id
			};
		});
	}, [legCpListData]);

	const dataSource = useMemo(() => {
		return segmentTableData.map((item, index) => {
			return { ...item, key: item.id ? item.id : index };
		});
	}, [segmentTableData]);

	useEffect(() => {
		onRequest(
			"getCpListApi",
			{ voyageId: props.queryData.voyageId, legType: props.queryData.legType },
			handleRequestFront,
			handleGetCpListSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	}, []);

	// 初始化segment数据
	useEffect(() => {
		if (props.hide === false) {
			setSegmentTableData([]);
			onRequest(
				"getSegmentByIdApi",
				{ legId: props.queryData.id },
				handleRequestFront,
				handleGetSegmentSuccess,
				handleRequestError,
				dispatch,
				navigate
			);
		}
		// props.scReportDateList
	}, [props.hide]);

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleGetCpListSuccess = (res) => {
		console.log("handleGetCpListSuccess", res);
		setLegCpListData(res.data);
	};
	const handleGetSegmentSuccess = (res) => {
		console.log("handleGetSegmentSuccess", res);
		// 数据库segment
		if (res.data.length === 0) {
			setSegmentTableData([
				{
					seg: 1,
					dueDate: null,
					cpId: cpOptions[0]?.value,
					legId: props.queryData.id,
					disabled: true
				}
			]);
		} else {
			const segmentArr = res.data?.map((item, index, arr) => {
				if (!item.cpId) {
					item.cpId = cpOptions[0]?.value;
					item.seg = 1;
				}
				if (index === arr.length - 1) {
					item.disabled = true;
					item.dueDate = null;
				}
				return item;
			});

			setSegmentTableData(segmentArr);
		}
	};
	const handleAddSegmentClick = () => {
		setSegmentTableData((prev) => {
			const item = {
				seg: 1,
				dueDate: null,
				cpId: cpOptions[0]?.value,
				legId: props.queryData.id,
				disabled: false
			};
			prev.splice(prev.length - 1, 0, item);
			return [...prev];
		});
	};

	const [delIds, setDelIds] = useState<string[]>([]);

	const handleDeleteSegmentClick = (index: number) => {
		setSegmentTableData((prev) => {
			const item = prev[index];
			if (item.id) {
				setDelIds((prev) => [...prev, item.id]);
			}
			prev.splice(index, 1);
			return [...prev];
		});
	};

	const handleChangeSegmentTableItem: CommonColumnChangeEvent<
		LegSegmentItemType
	> = (currentIndex, key, value) => {
		setSegmentTableData((prev) => {
			const curItem = prev[currentIndex];
			prev.splice(currentIndex, 1, { ...curItem, [key]: value });
			return [...prev];
		});
	};

	const handleUpdateSegmentListSuccess = (res) => {
		console.log("updateSegmentListApi", res.data);
		props.onAddSuccess();
		// 清空删除列表
		setDelIds([]);
	};

	const handleConfirmClick = () => {
		onRequest(
			"updateSegmentListApi",
			{
				delIds,
				list: segmentTableData.map((item) => ({
					...item,
					dueDate: item.dueDate
				}))
			},
			handleRequestFront,
			handleUpdateSegmentListSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	return {
		opereteColumns,
		handleAddSegmentClick,
		segmentTableData,
		cpOptions,
		handleConfirmClick,
		dataSource
	};
};

export default useIndex;
