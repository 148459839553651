import { MutableRefObject } from "react";
import { addSourceToMap } from "../utils";
import { NoonReportItemType } from "../types";
import { GeoJSONSource } from "mapbox-gl";
import { generateNoonReportPoints } from "../utils/noon";
import { RouteCommonMapRefType } from "../type";

const noonSource = {
	"noon-report-source": "noon-report-layer",
	"noon-report-info": "noon-report-info-layer"
};

const useNoon = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadSource = () => {
		addSourceToMap(mapboxGl?.current, {
			sourceName: "noon-report-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
	};

	const loadlayers = () => {
		mapboxGl.current.addLayer({
			id: noonSource["noon-report-info"],
			type: "symbol",
			source: "noon-report-source",
			layout: {
				// 'symbol-placement': 'line',
				"symbol-spacing": 100, // 图标间隔，默认为250
				"icon-image": "noonReportBgIcon", //箭头图标
				"icon-size": 0.2,
				"text-field": ["concat", ["get", "type"], " ", ["get", "formatTime"]],
				"icon-offset": [-380, 0],
				"text-offset": [-7.3, 0],
				"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
				// 文字大小
				"text-size": 10,
				"text-allow-overlap": true,
				"icon-allow-overlap": true
			}
		});
		mapboxGl?.current?.addLayer({
			id: noonSource["noon-report-source"],
			source: "noon-report-source",
			type: "symbol",
			layout: {
				"icon-image": "noonReportIcon",
				"icon-size": 0.4,
				"icon-allow-overlap": true
			}
		});
	};

	const handleNoonReportLoad: RouteCommonMapRefType["loadNoonReports"] = (
		items
	) => {
		const currentSource =
				mapboxGl?.current?.getSource<GeoJSONSource>("noon-report-source"),
			reportItems = generateNoonReportPoints(items);
		currentSource?.setData({
			type: "FeatureCollection",
			features: reportItems
		});
	};

	const handleNoonReportVisible: RouteCommonMapRefType["onNoorReportsVisible"] =
		(value) => {
			mapboxGl?.current?.setLayoutProperty(
				noonSource["noon-report-info"],
				"visibility",
				value
			);
			mapboxGl?.current?.setLayoutProperty(
				noonSource["noon-report-source"],
				"visibility",
				value
			);
		};

	return {
		loadSource,
		loadlayers,
		handleNoonReportLoad,
		handleNoonReportVisible
	};
};

export default useNoon;
