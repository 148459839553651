import { PageRouterProps } from "@/router";
import { VoyDetails, VoyList } from "./business";
import React from "react";

const voyRouter: PageRouterProps[] = [
	{
		index: true,
		// path: "list",
		key: "/layout/voy/list",
		element: <VoyList />,
		label: "Voy List"
	},
	{
		path: "details",
		key: "/layout/voy/details",
		element: <VoyDetails />,
		label: "Voy Details"
	}
];

export default voyRouter;
