import { onRequest } from "@/api";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import {
	AddRouteType,
	CommonIdType,
	generateReportParamsType,
	getSimulationSelectionsByIdsParamsType
} from "@/api/leg/type";
import {
	SimulationSelectionsType,
	WaypointSelectionsType,
	WaypointsType
} from "../type";
import { WayPointMapRefType } from "../components/map/type";
const useIndex = (simulationSelecedInfo = [], closeDialog) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");

	const mapRef = useRef<WayPointMapRefType>(null);

	const [loading, setLoading] = useState(false);
	const [simulationSelections, setSimulationSelections] =
		useState<SimulationSelectionsType>([]);
	const [simulationSelectedKey, setSimulationSelectedKey] =
		useState<string>("");

	const [waypoints, setWaypoints] = useState<WaypointsType>();

	const [waypointSelections, setWaypointSelections] =
		useState<WaypointSelectionsType>([]);
	const [selectedPointRowKeys, setSelectedPointRowKeys] = useState<string[]>(
		[]
	);
	const [selectedWaypointItems, setSelectedWaypointItems] =
		useState<WaypointSelectionsType>();
	const [ohterWaypointItems, setOtherWaypointItems] =
		useState<WaypointSelectionsType[]>();

	const getSimulationSelections = () => {
		const ids = simulationSelecedInfo.map((item) => item.id)?.join(",");
		ids && getSimulationSelectionsByIds({ ids });
	};

	const handleConfirm = async () => {
		const mapInfo = await mapRef?.current?.getMapInfo();
		const { zoom, center, mapImage } = mapInfo;
		const planList = simulationSelections.map((item) => {
			return {
				simulationId: item?.id,
				main: item?.id === simulationSelectedKey
			};
		});
		const formData = new FormData();
		planList.forEach((item, index) => {
			formData.append(`planList[${index}].simulationId`, item.simulationId);
			formData.append(`planList[${index}].main`, item.main ? "true" : "false");
		});
		formData.append("pointIds", `${selectedPointRowKeys}`);
		formData.append("legId", id);
		formData.append("centerLon", `${center?.lng}`);
		formData.append("centerLat", `${center?.lat}`);
		formData.append("zoom", `${zoom}`);
		formData.append("imgFile", mapImage, "imgFile.png");

		generateReport(formData);
	};
	// API
	// API - getSimulationSelectionsByIds
	const getSimulationSelectionsByIdsFront = () => {
		setLoading(true);
	};
	const getSimulationSelectionsByIdsSuccess = (data) => {
		// init
		const routePlan = data?.data?.routePlan || [];
		const wayPoint = data?.data?.wayPoint || {};
		const firstRouteKey = data?.data?.routePlan?.[0]?.id;
		setSimulationSelections(routePlan);
		setWaypoints(wayPoint);
		// 默认选中
		setSimulationSelectedKey(firstRouteKey);
		setWaypointSelections(wayPoint?.[firstRouteKey] || []);
		setSelectedPointRowKeys(wayPoint?.[firstRouteKey]?.map((item) => item.id));
		// waypointsLoad
		const selectedItems = wayPoint?.[firstRouteKey] || [];
		let otherItems = Object.entries(wayPoint)
			.filter(([simulationId, wayPoints]) => {
				return simulationId !== firstRouteKey;
			})
			.map(
				([simulationId, wayPoints]) => wayPoints
			) as WaypointSelectionsType[];

		mapRef?.current?.onWayPointsLoad(selectedItems, otherItems);

		setLoading(false);
	};
	const getSimulationSelectionsByIdsFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getSimulationSelectionsByIds = (
		params?: getSimulationSelectionsByIdsParamsType
	) => {
		onRequest(
			"getSimulationSelectionsByIdsApi",
			params,
			getSimulationSelectionsByIdsFront,
			getSimulationSelectionsByIdsSuccess,
			getSimulationSelectionsByIdsFailed,
			dispatch,
			navigate
		);
	};
	// Api - getWaypointBySimulationIdApi
	const getWaypointBySimulationIdFront = () => {
		setLoading(true);
	};
	const getWaypointBySimulationIdSuccess = (data) => {
		setWaypointSelections(data?.data || []);
		// 默认全选
		setSelectedPointRowKeys(data?.data?.map((item) => item.id));
		setLoading(false);
	};
	const getWaypointBySimulationIdFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getWaypointBySimulationId = (params) => {
		onRequest(
			"getWaypointBySimulationIdApi",
			params,
			getWaypointBySimulationIdFront,
			getWaypointBySimulationIdSuccess,
			getWaypointBySimulationIdFailed,
			dispatch,
			navigate
		);
	};

	// Api - generateReportApi
	const generateReportFront = () => {
		setLoading(true);
	};
	const generateReportSuccess = () => {
		closeDialog?.();
		setLoading(false);
	};
	const generateReportFailed = (error) => {
		mapRef?.current?.onWayPointsLayoutVisible("visible");
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const generateReport = (params: generateReportParamsType) => {
		onRequest(
			"generateReportApi",
			params,
			generateReportFront,
			generateReportSuccess,
			generateReportFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		getSimulationSelections();
	}, []);

	const handleSelectSimulation = (key: string) => {
		setSimulationSelectedKey(key);
		setWaypointSelections(waypoints?.[key] || []);
		// 默认全选
		setSelectedPointRowKeys(waypoints?.[key]?.map((item) => item.id));
		// WayPointsLoad
		const selectedItems = waypoints?.[key] || [];
		let otherItems = [];
		waypoints &&
			Object.keys(waypoints)?.forEach((waypointsKey) => {
				if (key !== waypointsKey) {
					otherItems.push(waypoints[waypointsKey]);
				}
			});
		mapRef?.current?.onWayPointsLoad(selectedItems, otherItems);
		mapRef?.current?.onWayPointsVisible(selectedItems.map((item) => item.id));
	};

	const handleSelectWayPoint = (keys: string[]) => {
		setSelectedPointRowKeys(keys);
		mapRef?.current?.onWayPointsVisible(keys);
	};

	return {
		loading,
		simulationSelections,
		simulationSelectedKey,
		waypointSelections,
		selectedPointRowKeys,
		selectedWaypointItems,
		ohterWaypointItems,
		mapRef,
		setSimulationSelectedKey,
		setSelectedPointRowKeys,
		handleConfirm,
		handleSelectSimulation,
		handleSelectWayPoint
	};
};

export default useIndex;
