import { useState } from "react";
import { CommonCommitEvent, CommonColumnCommitEvent } from "@/types/event";
import { NoonMainInfoFormType } from "../../../common/noonMainInfoForm/type";
import {
	FuelTableDataType,
	FuelTableDataItemType
} from "../../../common/noonFuelForm/type";
import { NoonNextPortFormType } from "../../../common/noonNextPortForm/type";
import {
	NrPatiFormType,
	NrViFormType,
	NrNetDataType,
	NoonNrDataType,
	NrBasicDataType
} from "../type";
import {
	initialNrFuelData,
	initialNrMiData,
	initialNrNpData,
	initialNrPatiData,
	initialNrViData,
	initialNrBasicData
} from "../source";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { AddNoonReportProps } from "../../addNoonReport/type";
import { formatNumber } from "@/tools/amount";

const useIndex = (props: AddNoonReportProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	// Main Information data
	const [miData, setMiData] = useState<NoonMainInfoFormType>(initialNrMiData);

	// Position and Time Information data
	const [patiData, setPatiData] = useState<NrPatiFormType>(initialNrPatiData);

	// Voyage Information data
	const [viData, setViData] = useState<NrViFormType>(initialNrViData);

	// Fuel data
	const [fuelData, setFuelData] =
		useState<FuelTableDataType>(initialNrFuelData);

	// Next Port
	const [npData, setNpData] = useState<NoonNextPortFormType>(initialNrNpData);

	// basic data
	const [basicData, setBasicData] =
		useState<NrBasicDataType>(initialNrBasicData);

	const handleNrMiCommit: CommonCommitEvent<NoonMainInfoFormType> = (item) => {
		setMiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleNrPatiCommit: CommonCommitEvent<NrPatiFormType> = (item) => {
		setPatiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleNrViCommit: CommonCommitEvent<NrViFormType> = (item) => {
		setViData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleNrFuelCommit: CommonColumnCommitEvent<FuelTableDataItemType> = (
		index,
		item
	) => {
		setFuelData((prev) => {
			prev.splice(index, 1, item as FuelTableDataItemType);
			return [...prev];
		});
	};
	const handleNrNpCommit: CommonCommitEvent<NoonNextPortFormType> = (item) => {
		setNpData((prev) => {
			return { ...prev, ...item };
		});
	};

	const handleClearNrData = () => {
		// setMiData(initialNrMiData);
		setPatiData(initialNrPatiData);
		setViData(initialNrViData);
		setFuelData(initialNrFuelData);
		setNpData(initialNrNpData);
		setBasicData(initialNrBasicData);
	};

	// 接口数据赋值
	const handleNrNetDataInit = (data: NrNetDataType) => {
		// setMiData({
		// 	vesselName: data.shipName ?? "",
		// 	imo: data.imo ?? "",
		// 	mmsi: data.mmsi ?? "",
		// 	callSign: data.callSign ?? ""
		// });

		setPatiData({
			latitude: data.positionLat,
			longitude: data.positionLon,
			positionDate: data.reportDateTime
		});

		setViData({
			foreDraft: data.draftFwd,
			aftDraft: data.draftAft,
			midDraft: data.meanDraft,
			avgSogSlr: data.avgSpeed,
			course: data.course,
			distanceSlr: data.distSlr,
			distanceToGo: data.distRemain,
			mePowerSlr: data.mePower,
			avgRpmSlr: data.avgRpm,
			slipSlr: data.slip,
			timeSlr: data.steamTimeSlr,
			mcr: data.meLoadSlr,
			orderedSpeed: data.intdSpeed,
			windBeaufort: data.windBf,
			windDirection: data.windDir,
			seasHeight: data.sea,
			swellHeight: data.swellHeight,
			swellDirection: data.swellDir
		});

		setFuelData((prev) => {
			return [
				{
					...prev[0],
					hfo: data.brobHfo ?? "",
					lsfo: data.brobLsfo ?? "",
					mdo: data.brobMdo ?? "",
					mgo: data.brobMgo ?? ""
				},
				{
					...prev[1],
					hfo: data.meHfoSlr ?? "",
					lsfo: data.meLsfoSlr ?? "",
					mdo: data.meMdoSlr ?? "",
					mgo: data.meMgoSlr ?? ""
				},
				{
					...prev[2],
					hfo: data.auxHfoSlr ?? "",
					lsfo: data.auxLsfoSlr ?? "",
					mdo: data.auxMdoSlr ?? "",
					mgo: data.auxMgoSlr ?? ""
				},
				{
					...prev[3],
					hfo: data.otherHfoSlr ?? "",
					lsfo: data.otherLsfoSlr ?? "",
					mdo: data.otherMdoSlr ?? "",
					mgo: data.otherMgoSlr ?? ""
				},
				// {
				// 	...prev[4],
				// 	hfo: data.totalHfoSlr ?? "",
				// 	lsfo: data.totalLsfoSlr ?? "",
				// 	mdo: data.totalMdoSlr ?? "",
				// 	mgo: data.totalMgoSlr ?? ""
				// }
				{
					...prev[4],
					hfo: formatNumber(
						Number(data.meHfoSlr) +
							Number(data.auxHfoSlr) +
							Number(data.otherHfoSlr),
						3
					),
					lsfo: formatNumber(
						Number(data.meLsfoSlr) +
							Number(data.auxLsfoSlr) +
							Number(data.otherLsfoSlr),
						3
					),
					mdo: formatNumber(
						Number(data.meMdoSlr) +
							Number(data.auxMdoSlr) +
							Number(data.otherMdoSlr),
						3
					),
					mgo: formatNumber(
						Number(data.meMgoSlr) +
							Number(data.auxMgoSlr) +
							Number(data.otherMgoSlr),
						3
					)
				}
			];
		});

		setNpData({
			nextPort: data.nextPortName ?? "",
			unlocode: data.nextPortCode ?? "",
			eta: data.eta ?? ""
		});

		setBasicData({
			id: data.id
		});
	};

	// 提交数据
	const handleNrDataCommit = (data: NoonNrDataType) => {
		const commitData: Partial<NrNetDataType> = {
			id: data.basicData.id,
			legId: props.legId,
			type: "Noon Report",
			// heading: "W",
			mmsi: data.miData.mmsi,
			shipName: data.miData.vesselName,
			imo: data.miData.imo,
			callSign: data.miData.callSign,
			// reportDateTimeStr: "20230917/0900 UTC",
			reportDateTime: data.patiData.positionDate,
			// positionStr: "26°53.000S 038°46.000E",
			positionLon: data.patiData.longitude,
			positionLat: data.patiData.latitude,
			course: data.viData.course,
			avgSpeed: data.viData.avgSogSlr,
			// avgWaterSpeed: 1071.0,
			steamTimeSlr: data.viData.timeSlr,
			distSlr: data.viData.distanceSlr,
			distRemain: data.viData.distanceToGo,
			// ecaDistSlr: 305.0,
			brobHfo: data.fuelData[0].hfo,
			brobLsfo: data.fuelData[0].lsfo,
			brobMdo: data.fuelData[0].mdo,
			brobMgo: data.fuelData[0].mgo,
			meHfoSlr: data.fuelData[1].hfo,
			meLsfoSlr: data.fuelData[1].lsfo,
			meMdoSlr: data.fuelData[1].mdo,
			meMgoSlr: data.fuelData[1].mgo,
			auxHfoSlr: data.fuelData[2].hfo,
			auxLsfoSlr: data.fuelData[2].lsfo,
			auxMdoSlr: data.fuelData[2].mdo,
			auxMgoSlr: data.fuelData[2].mgo,
			otherHfoSlr: data.fuelData[3].hfo,
			otherLsfoSlr: data.fuelData[3].lsfo,
			otherMdoSlr: data.fuelData[3].mdo,
			otherMgoSlr: data.fuelData[3].mgo,
			totalHfoSlr: data.fuelData[4].hfo,
			totalLsfoSlr: data.fuelData[4].lsfo,
			totalMdoSlr: data.fuelData[4].mdo,
			totalMgoSlr: data.fuelData[4].mgo,
			// nameOfLastPortName: ???,
			// nameOfLastPortCode: ???,
			// positionOfLastPortStr: "21°25.000N 108°38.700E",
			// positionOfLastPortLon: 108.645,
			// positionOfLastPortLat: 21.41667,
			// timeOfLastPortStr: "20230828/0930 UTC",
			// timeOfLastPort: 1693215000000,
			nextPortName: data.npData.nextPort,
			nextPortCode: data.npData.unlocode,
			// positionOfNextPortStr: "24°00.500S 046°20.100W",
			// positionOfNextPortLon: -46.335,
			// positionOfNextPortLat: -24.00833,
			// timeOfNextPortStr: "20231002/1640 UTC",
			// timeOfNextPort: 1696264800000,
			eta: data.npData.eta,
			intdSpeed: data.viData.orderedSpeed,
			draftFwd: data.viData.foreDraft,
			draftAft: data.viData.aftDraft,
			meanDraft: data.viData.midDraft,
			avgRpm: data.viData.avgRpmSlr,
			mePower: data.viData.mePowerSlr,
			meLoadSlr: data.viData.mcr,
			slip: data.viData.slipSlr,
			windBf: data.viData.windBeaufort,
			windDir: data.viData.windDirection,
			sea: data.viData.seasHeight,
			swellHeight: data.viData.swellHeight,
			swellDir: data.viData.swellDirection
		};

		onRequest(
			"saveLegNrReportApi",
			commitData,
			handleNrDataCommitFront,
			handleNrDataCommitSuccess,
			handleNrDataCommitError,
			dispatch,
			navigate
		);
	};
	const handleNrDataCommitFront = () => {};
	const handleNrDataCommitSuccess = (res) => {
		console.log("success", res);
		props.onAddSuccess();
		handleClearNrData();
	};
	const handleNrDataCommitError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	return {
		nrData: { miData, patiData, viData, fuelData, npData, basicData },
		handleNrMiCommit,
		handleNrPatiCommit,
		handleNrViCommit,
		handleNrFuelCommit,
		handleNrNpCommit,
		handleClearNrData,
		handleNrNetDataInit,
		handleNrDataCommit
	};
};

export default useIndex;
