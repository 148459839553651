import dayjs from "dayjs";

export const dateToUtcString = (d: Date | string | number) => {
	const date = new Date(d);
	const YY = date.getUTCFullYear() + "-";
	const MM =
		(date.getUTCMonth() + 1 < 10
			? "0" + (date.getUTCMonth() + 1)
			: date.getUTCMonth() + 1) + "-";
	const DD =
		date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
	const hh =
		(date.getUTCHours() < 10 ? "0" + date.getUTCHours() : date.getUTCHours()) +
		":";
	const mm =
		(date.getUTCMinutes() < 10
			? "0" + date.getUTCMinutes()
			: date.getUTCMinutes()) + ":";
	const ss =
		date.getUTCSeconds() < 10
			? "0" + date.getUTCSeconds()
			: date.getUTCSeconds();
	return YY + MM + DD + " " + hh + mm + ss;
};

// 计算两个时间戳之间的小时数
export const getHoursByTimeStampRange = (start: number, end: number) => {
	if (start === null || end === null || start === 0 || end === 0) {
		return "";
	}

	const startDate = dayjs(start);
	const endDate = dayjs(end);

	const millisecondsDiff = endDate.diff(startDate);

	const hoursDiff = millisecondsDiff / (1000 * 60 * 60);

	return hoursDiff;
};

// 格式化时间戳, 例 "Sep-30/1515Z" (不显示年份)
export const timestampToUtcMdhm = (timestamp: number): string => {
	if (!timestamp) {
		return "";
	}

	const date = new Date(timestamp);
	const monthAbbreviations = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];
	const month = monthAbbreviations[date.getUTCMonth()];
	const day = date.getUTCDate().toString().padStart(2, "0");
	const hours = date.getUTCHours().toString().padStart(2, "0");
	const minutes = date.getUTCMinutes().toString().padStart(2, "0");
	return `${month}-${day}/${hours}${minutes}Z`;
};

// 格式化时间戳, 例子 { data: "1-Oct", time: 03:00 }
export const timestampToUtcObj = (
	timestamp: number
): { date: string; time: string } => {
	// 创建一个 Date 对象
	const date = new Date(timestamp);

	// 获取 UTC 日期和时间
	const utcDate = date.getUTCDate();
	const utcMonth = date.toUTCString().split(" ")[2]; // 获取月份的缩写
	const utcHours = date.getUTCHours().toString().padStart(2, "0");
	const utcMinutes = date.getUTCMinutes().toString().padStart(2, "0");

	// 格式化日期和时间
	const formattedDate = `${utcDate}-${utcMonth}`;
	const formattedTime = `${utcHours}:${utcMinutes}`;

	// 返回结果对象
	return {
		date: formattedDate,
		time: formattedTime
	};
};

export const dateToUtcDateString = (d: Date | string | number) => {
	const date = new Date(d);
	const YY = date.getUTCFullYear() + "-";
	const MM =
		(date.getUTCMonth() + 1 < 10
			? "0" + (date.getUTCMonth() + 1)
			: date.getUTCMonth() + 1) + "-";
	const DD =
		date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
	// const hh =
	// 	(date.getUTCHours() < 10 ? "0" + date.getUTCHours() : date.getUTCHours()) +
	// 	":";
	// const mm =
	// 	(date.getUTCMinutes() < 10
	// 		? "0" + date.getUTCMinutes()
	// 		: date.getUTCMinutes()) + ":";
	// const ss =
	// 	date.getUTCSeconds() < 10
	// 		? "0" + date.getUTCSeconds()
	// 		: date.getUTCSeconds();
	// return YY + MM + DD + " " + hh + mm + ss;
	return YY + MM + DD;
	// return MM + DD;
};
