import React, { useState, useMemo, useRef } from "react";
import { ColumnsType } from "antd/es/table";
import { VoyCpSpdTableItemType, VoyCpSpdDataType } from "../type";
import { initialCpSpd, loadConditionOptions, categoryOptions } from "../source";
import { Toast, Popover, FleetInput, Select } from "@/components";
import { CommonColumnChangeEvent } from "@/types/event";

const useIndex = (isDisabled = false) => {
	const [voyCpSpdTableData, setVoyCpSpdTableData] = useState<
		VoyCpSpdTableItemType[]
	>([]);

	const [delIds, setDelIds] = useState<string[]>([]);

	const handleDoubleClick = () => {
		console.log("handleDoubleClick");
		// openDetailDialog.current = true;
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

	const onSelectChange = (newSelectedRowKeys: number[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const onPageChange = (page: number, size: number) => {
		// onRequest(
		// 	"getVoyagePageApi",
		// 	{
		// 		pageSize: size,
		// 		pageNum: page,
		// 		imo: voyageSearchData.imo,
		// 		companyId: voyageSearchData.companyId,
		// 		voyageType: voyageSearchData.voyageType,
		// 		status: voyageSearchData.status,
		// 		service: voyageSearchData.service,
		// 		role: voyageSearchData.role,
		// 		startOrderDate: voyageSearchData.startOrderDate,
		// 		endOrderDate: voyageSearchData.endOrderDate
		// 	},
		// 	voyageFront,
		// 	(response) => {
		// 		if (!Array.isArray(response?.data)) {
		// 			cacheSource.current[page] = response?.data?.records;
		// 		} else {
		// 			cacheSource.current[page] = [];
		// 		}
		// 		setPager((prev) => ({ ...prev, pageNum: page }));
		// 		setLoading(false);
		// 	},
		// 	voyageError,
		// 	dispatch,
		// 	navigate
		// );
	};

	// const cacheSource = useRef<{ [index: number]: VoyCpSpdTableItemType[] }>({});

	// const dataSource = useMemo(() => {
	// 	return cacheSource.current[pager.pageNum];
	// }, [pager.pageNum, pager.total]);

	const dataSource = useMemo(() => {
		return voyCpSpdTableData?.map((item, index) => {
			return { ...item, id: index };
		});
	}, [voyCpSpdTableData]);

	const opereteColumns: ColumnsType<VoyCpSpdDataType> = useMemo(() => {
		const initOpereteColumns: ColumnsType<VoyCpSpdDataType> = [
			{
				title: "B&L",
				dataIndex: "loadCondition",
				key: "loadCondition",
				// width: 100,
				fixed: "left",
				align: "center",
				ellipsis: true,
				render: (value, record, index) => {
					return (
						<div>
							{isDisabled ? (
								<span className="cp-item-value">{value}</span>
							) : (
								<Select
									// disabled={isDisabled}
									options={loadConditionOptions}
									value={value}
									onChange={(val: VoyCpSpdTableItemType["loadCondition"]) => {
										handleChangeVoyCpSpdTableItem(index, "loadCondition", val);
									}}
								/>
							)}
						</div>
					);
				}
			},
			{
				title: "Category",
				dataIndex: "speedCategory",
				key: "speedCategory",
				// width: 100,
				align: "center",
				ellipsis: true,
				render: (value, record, index) => {
					return (
						<div>
							{isDisabled ? (
								<span className="cp-item-value">{value}</span>
							) : (
								<Select
									// disabled={isDisabled}
									options={categoryOptions}
									value={value}
									onChange={(val: VoyCpSpdTableItemType["speedCategory"]) => {
										handleChangeVoyCpSpdTableItem(index, "speedCategory", val);
									}}
								/>
							)}
						</div>
					);
				}
			},
			{
				title: "SPD(kt)",
				dataIndex: "speed",
				key: "speed",
				// width: 100,
				align: "center",
				ellipsis: true,
				render: (value, record, index) => {
					return (
						<div>
							{isDisabled ? (
								<span className="cp-item-value">{value}</span>
							) : (
								<FleetInput
									type="number"
									// disabled={isDisabled}
									value={value}
									onChange={(val: VoyCpSpdTableItemType["speed"]) => {
										handleChangeVoyCpSpdTableItem(index, "speed", val);
									}}
								/>
							)}
						</div>
					);
				}
			},
			{
				title: "FO(mt)",
				dataIndex: "foConsumption",
				key: "foConsumption",
				// width: 100,
				align: "center",
				ellipsis: true,
				render: (value, record, index) => {
					return (
						<div>
							{isDisabled ? (
								<span className="cp-item-value">{value}</span>
							) : (
								<FleetInput
									type="number"
									// disabled={isDisabled}
									value={value}
									onChange={(val: VoyCpSpdTableItemType["foConsumption"]) => {
										handleChangeVoyCpSpdTableItem(index, "foConsumption", val);
									}}
								/>
							)}
						</div>
					);
				}
			},
			{
				title: "DGO(mt)",
				dataIndex: "dgoConsumption",
				key: "dgoConsumption",
				// width: 100,
				align: "center",
				ellipsis: true,
				render: (value, record, index) => {
					return (
						<div>
							{isDisabled ? (
								<span className="cp-item-value">{value}</span>
							) : (
								<FleetInput
									type="number"
									// disabled={isDisabled}
									value={value}
									onChange={(val: VoyCpSpdTableItemType["dgoConsumption"]) => {
										handleChangeVoyCpSpdTableItem(index, "dgoConsumption", val);
									}}
								/>
							)}
						</div>
					);
				}
			}
		];

		if (!isDisabled) {
			initOpereteColumns.push({
				title: "Action",
				dataIndex: "option",
				key: "option",
				// width: 100,
				align: "center",
				fixed: "right",
				render: (value, record, index) => {
					return (
						<div>
							<Popover
								render={(setIsOpen) => {
									return (
										<Toast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												setIsOpen(true);
												handleRemoveVoyCpSpdTableItem(index);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<span className="iconfont icon-delete"></span>
							</Popover>
						</div>
					);
				}
			});
		}

		return initOpereteColumns;
	}, [isDisabled]);

	const handleChangeVoyCpSpdTableItem: CommonColumnChangeEvent<
		VoyCpSpdTableItemType
	> = (currentIndex, key, value) => {
		setVoyCpSpdTableData((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const handleRemoveVoyCpSpdTableItem = (index: number) => {
		setVoyCpSpdTableData((prev) => {
			const [item] = prev?.splice(index, 1);
			setDelIds((prev) => {
				return [...prev, item.id];
			});
			return [...prev];
		});
	};

	const handleClearDelIds = () => {
		setDelIds([]);
	};

	// const handleInputVoyCpSpdTableItem: CommonColumnChangeEvent<
	// 	VoyCpSpdTableItemType
	// > = (currentIndex, key, value) => {
	// 	setVoyCpSpdTableData((prev) => {
	// 		const currentItem = prev?.[currentIndex];
	// 		prev?.splice(currentIndex, 1, {
	// 			...currentItem,
	// 			[key]: value
	// 		});
	// 	});
	// };

	const addVoyCpSpd = () => {
		setIsHideCpDialog(false);
	};

	const [isHideCpDialog, setIsHideCpDialog] = useState<boolean>(true);

	const onCpClose = () => {
		setIsHideCpDialog(true);
	};

	const onCpPickSuccess = (data: VoyCpSpdTableItemType[]) => {
		setVoyCpSpdTableData((prev) => [...prev, ...data]);
		setIsHideCpDialog(true);
	};

	const clearVoyCpSpdTableData = () => {
		setVoyCpSpdTableData([]);
	};

	return {
		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		setVoyCpSpdTableData,
		delIds,
		handleClearDelIds,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess,
		clearVoyCpSpdTableData
	};
};

export default useIndex;
