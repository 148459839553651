import useCommonIndex from "../../../hooks/useIndex";
import { CommonCompanyItemType } from "../components/CompanyItem/type";
import { useMemo, useState } from "react";
import debounce from "lodash/debounce";

const useIndex = (
	activeItems:
		| Partial<CommonCompanyItemType>
		| Partial<CommonCompanyItemType>[],
	showKey: keyof CommonCompanyItemType,
	onFinish: (
		newItem: CommonCompanyItemType,
		operateType: "add" | "del"
	) => void,
	primaryKey: keyof CommonCompanyItemType = "id",
	disabled?: boolean
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		setOpen,
		setList,
		focusPopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonCompanyItemType, keyof CommonCompanyItemType>(
		activeItems,
		showKey,
		onFinish,
		"getCompaniesApi",
		primaryKey,
		disabled
	);

	const closePopup = (open: boolean) => {
		if (!open) {
			// setSearchVal("");
			page.current.pageNum = 1;
		}
		setOpen(open);
	};

	const handleSearch = useMemo(() => {
		if (status === "num") {
			return (val: string) => {
				// setSearchVal(val)
			};
		}

		const loadOptions = (val: string) => {
			page.current.pageNum = 1;
			setList([]);
			focusPopup(false, val);
		};
		return debounce(loadOptions, 800);
	}, [focusPopup, status]);

	const handleLatitudeClose = (portCode: string) => {
		console.log("portCode", portCode);
		handleItemSelect?.({
			portCode: "(" + portCode + ")",
			portName: portCode
		} as any);
		setOpen(false);
	};

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		handleLatitudeClose,
		tagRender
	};
};

export default useIndex;
