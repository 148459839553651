import {
	Button,
	Dialog,
	Table,
	FleetDatePicker,
	FormItem,
	FleetInput,
	Icon
} from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { PreviewDialogProps } from "./type";
import { pointTableColumns, simulationTableColumns } from "./source";
import { WayPointMap } from "./components";
const PreviewDialog: FC<PreviewDialogProps> = ({
	visible,
	onClose,
	simulationSelecedInfo
}) => {
	const {
		loading,
		simulationSelections,
		simulationSelectedKey,
		waypointSelections,
		selectedPointRowKeys,
		mapRef,
		handleConfirm,
		handleSelectSimulation,
		handleSelectWayPoint
	} = useIndex(simulationSelecedInfo, onClose);

	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={onClose}
			loading={loading}
		>
			<div className={styles["previewDialog"]}>
				<div className="previewDialog-header">
					<Icon type="icon-close2" className="close-btn" onClick={onClose} />
				</div>
				<div className="previewDialog-main fleet-nonScrollBar">
					<WayPointMap ref={mapRef} />
					<div className="main-simulationTable">
						<p className="simulationTable-title">
							Select a main recommended route
						</p>
						<Table
							dataSource={simulationSelections}
							columns={simulationTableColumns}
							rowSelection={{
								selectedRowKeys: [simulationSelectedKey],
								onChange: (key: string[]) => {
									if (key.length === 0) {
										return;
									}
									handleSelectSimulation(key[key?.length - 1]);
								}
							}}
							bordered={true}
							id="id"
							scroll={{ x: 350 }}
							pagination={false}
						/>
					</div>
					<div className="main-pointTable">
						<p className="pointTable-title">
							Select the steering point of the main route
						</p>
						<Table
							dataSource={waypointSelections}
							columns={pointTableColumns}
							rowSelection={{
								selectedRowKeys: selectedPointRowKeys,
								onChange: (keys: string[]) => {
									handleSelectWayPoint(keys);
								}
							}}
							onRow={(data, index) => {
								return {
									onClick: () => {
										mapRef?.current?.onWayPointFocus(data);
									}
								};
							}}
							bordered={true}
							id="id"
							scroll={{ x: 950 }}
							pagination={false}
						/>
					</div>
				</div>
				<div className="previewDialog-footer">
					<Button
						onClick={() => {
							handleConfirm();
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default PreviewDialog;
