import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { CommonColumnChangeEvent } from "@/types/event";
import { onRequest } from "@/api";
import { useReminder, useAppDispatch } from "@/hooks";
import { Select, Popover, FleetInput, Toast, Button } from "@/components";
import { CpTableItemType, CharterPartyDialogProps } from "../type";
import { loadConditionOptions, categoryOptions } from "../source";
import { VoyCpSpdTableItemType } from "@/pages/voy/common/cpSpd";

const useIndex = (props: CharterPartyDialogProps) => {
	const { reminder } = useReminder();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [cpTableData, setCpTableData] = useState<CpTableItemType[]>([]);

	// const [delIds, setDelIds] = useState<string[]>([]);

	const columns: ColumnsType<CpTableItemType> = [
		{
			title: "Load Condition",
			dataIndex: "loadCondition",
			key: "loadCondition",
			align: "center",
			render: (value, record, index) => {
				return (
					<div>
						{record.disabled ? (
							<span className="cp-item-value">{value}</span>
						) : (
							<Select
								options={loadConditionOptions}
								value={value}
								onChange={(val: CpTableItemType["loadCondition"]) => {
									handleChangeCpSpdTableItem(index, "loadCondition", val);
								}}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: "Category",
			dataIndex: "speedCategory",
			key: "speedCategory",
			align: "center",
			ellipsis: true,
			render: (value, record, index) => {
				return (
					<div>
						{record.disabled ? (
							<span className="cp-item-value">{value}</span>
						) : (
							<Select
								options={categoryOptions}
								value={value}
								onChange={(val: CpTableItemType["speedCategory"]) => {
									handleChangeCpSpdTableItem(index, "speedCategory", val);
								}}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: "SPD(kt)",
			dataIndex: "speed",
			key: "speed",
			align: "center",
			ellipsis: true,
			render: (value, record, index) => {
				return (
					<div>
						{record.disabled ? (
							<span className="cp-item-value">{value}</span>
						) : (
							<FleetInput
								type="number"
								value={value}
								onChange={(val: CpTableItemType["speed"]) => {
									handleChangeCpSpdTableItem(index, "speed", val);
								}}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: "FO(mt)",
			dataIndex: "foConsumption",
			key: "foConsumption",
			align: "center",
			ellipsis: true,
			render: (value, record, index) => {
				return (
					<div>
						{record.disabled ? (
							<span className="cp-item-value">{value}</span>
						) : (
							<FleetInput
								type="number"
								value={value}
								onChange={(val: CpTableItemType["foConsumption"]) => {
									handleChangeCpSpdTableItem(index, "foConsumption", val);
								}}
							/>
						)}
					</div>
				);
			}
		},
		{
			title: "DGO(mt)",
			dataIndex: "dgoConsumption",
			key: "dgoConsumption",
			align: "center",
			ellipsis: true,
			render: (value, record, index) => {
				return (
					<div>
						{record.disabled ? (
							<span className="cp-item-value">{value}</span>
						) : (
							<FleetInput
								type="number"
								value={value}
								onChange={(val: CpTableItemType["dgoConsumption"]) => {
									handleChangeCpSpdTableItem(index, "dgoConsumption", val);
								}}
							/>
						)}
					</div>
				);
			}
		}
	];

	const opColumns: ColumnsType<CpTableItemType> = [
		{
			title: "Action",
			dataIndex: "option",
			key: "option",
			align: "center",
			// fixed: "right",
			render: (value, record, index) => {
				return (
					<div className="cp-column-action">
						{record.disabled ? (
							<Button type="text" onClick={() => handleEditClick(index)}>
								Edit
							</Button>
						) : (
							<Button type="text" onClick={() => handleSaveClick(record)}>
								Save
							</Button>
						)}
						{/* 删除按钮 */}
						<Popover
							render={(setIsOpen) => {
								return (
									<Toast
										label="Are you sure delete this item?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											setIsOpen(true);
											handleRemoveCpSpdTableItem(index);
											setIsOpen(false);
										}}
									/>
								);
							}}
						>
							<Button type="text">Delete</Button>
						</Popover>
					</div>
				);
			}
		}
	];
	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const [filter, setFilter] = useState<{
		loadCondition: CpTableItemType["loadCondition"] | "";
		type: CpTableItemType["speedCategory"] | "";
	}>({
		loadCondition: "",
		type: ""
	});

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

	const handleChangeCpSpdTableItem: CommonColumnChangeEvent<CpTableItemType> = (
		currentIndex,
		key,
		value
	) => {
		setCpTableData((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const handleDeleteVcpSuccess = () => {
		// if (props.imo) {
		// 	getVesCpTermPage();
		// }
	};

	const handleRemoveCpSpdTableItem = (index: number) => {
		setCpTableData((prev) => {
			const [item] = prev?.splice(index, 1);
			// setDelIds((prev) => {
			// 	return [...prev, item.id];
			// });
			onRequest(
				"deleteVesCpTermApi",
				{ id: item.id },
				handleRequestFront,
				handleDeleteVcpSuccess,
				handleRequestError,
				dispatch,
				navigate
			);
			return [...prev];
		});
	};

	const handleSaveVesCpTermSuccess = (data: any, id: number) => {
		setCpTableData((prev) => {
			prev.forEach((item) => {
				if (item.id === id) {
					item.disabled = true;
				}
			});
			return [...prev];
		});
	};

	const handleCpAddClick = () => {
		setCpTableData((prev) => {
			prev = prev ? prev : [];
			const newItem = {
				loadCondition: "B" as VoyCpSpdTableItemType["loadCondition"],
				speedCategory: "ECO" as VoyCpSpdTableItemType["speedCategory"],
				speed: 0,
				foConsumption: 0,
				dgoConsumption: 0,
				imo: props.imo,
				disabled: false,
				id: prev.length
			};
			return [...prev, newItem];
		});
	};

	const onSelectChange = (newSelectedRowKeys: number[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handlePickClick = () => {
		const cpData = [];
		cpTableData.forEach((item) => {
			if (selectedRowKeys.includes(item.id)) {
				cpData.push({ ...item, id: null });
			}
		});
		props.onCpPickSuccess(cpData);
	};

	const handleEditClick = (index: number) => {
		setCpTableData((prev) => {
			// prev.forEach((item) => {
			// 	if (item.disabled === false) {
			// 		item.disabled = true;
			// 	}
			// });
			prev[index].disabled = false;
			return [...prev];
		});
	};

	const handleSaveClick = (record: CpTableItemType) => {
		console.log("查看数据：", record, typeof record.id);

		// 判断是否是自定义ID
		const data =
			typeof record.id === "number"
				? {
						loadCondition: record.loadCondition,
						type: record.speedCategory,
						speed: record.speed,
						fo: record.foConsumption,
						dgo: record.dgoConsumption,
						imo: props.imo
					}
				: {
						loadCondition: record.loadCondition,
						type: record.speedCategory,
						speed: record.speed,
						fo: record.foConsumption,
						dgo: record.dgoConsumption,
						id: record.id,
						imo: props.imo
					};

		onRequest(
			"saveVesCpTermApi",
			data,
			handleRequestFront,
			(data) => {
				handleSaveVesCpTermSuccess(data, record.id);
			},
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleGetVctPageSuccess = (res) => {
		const data = res.data.records?.map((item) => {
			return {
				id: item.id,
				loadCondition: item.loadCondition,
				speedCategory: item.type,
				speed: item.speed,
				foConsumption: item.fo,
				dgoConsumption: item.dgo,
				disabled: true
			};
		});

		setCpTableData(data);

		setPager((prev) => {
			return { ...prev, total: res.total };
		});
	};

	const getVesCpTermPage = () => {
		onRequest(
			"getVesCpTermPageApi",
			{
				...filter,
				...pager,
				imo: props.imo
			},
			handleRequestFront,
			handleGetVctPageSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const onPageChange = (page: number, size: number) => {
		setPager((prev) => {
			return { ...prev, pageNum: page, pageSize: size };
		});
	};

	const handleClearData = () => {
		setCpTableData([]);
		setSelectedRowKeys([]);
	};

	// 当弹框弹出, 请求列表数据; 当弹框弹退出, 清理数据
	useEffect(() => {
		if (!props.hide && props.imo) {
			getVesCpTermPage();
		} else {
			handleClearData();
		}
	}, [props.hide, pager.pageNum, pager.pageSize, filter, props.imo]);

	return {
		cpTableData,
		columns,
		opColumns,
		handleChangeCpSpdTableItem,
		handleCpAddClick,
		handlePickClick,
		selectedRowKeys,
		onSelectChange,
		filter,
		setFilter,
		pager,
		onPageChange
	};
};

export default useIndex;
