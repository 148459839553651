import React, { FC, forwardRef, useImperativeHandle } from "react";
import styles from "./index.module.less";
import { Popover, Toast, Icon, Loading, NoData, Tooltip } from "@/components";
import { RouteImportDialog, PlanDownloadDialog } from "../index";
import useIndex from "./hooks/useIndex";
import { routeListColumns } from "./source";
import { CustomTableProps, CustomTableRefType } from "./type";
import { WaypointTable, SimulationTable } from "./components";

const CustomTable = forwardRef<CustomTableRefType, CustomTableProps>(
	(
		{
			routeInfo,
			isFirstTable,
			activeWaypointRowId,
			simulationSelecedInfo,
			resetRouteList,
			onClickWaypoint,
			setSimulationSelecedInfo,
			onSelectForecast,
			onOpenForecast,
			onCloseForecast,
			updateActiveRouteId
		},
		ref
	) => {
		const {
			planList,
			routeImportDialogVisible,
			planDownloadDialogVisible,
			curSelcetedPlanId,
			loading,
			wayPointShowInMapData,
			activePlan,
			simulationOpenIds,
			handleOpenRouteImportDialog,
			handleDelRoute,
			handleCloseRouteImportDialog,
			initPageData,
			handleOpenWaypointList,
			handleDelPlan,
			handelCollectPlan,
			handleCancelCollectPlan,
			handleClosePlanDownloadDialog,
			handleOpenPlanDownloadDialog,
			handleChangePlanName,
			handleCopyPlan,
			handleShowWaypoint,
			handleShowSimulation,
			handleCloseSimulation,
			handleDistanceUpdate
		} = useIndex(routeInfo, resetRouteList, isFirstTable, updateActiveRouteId);
		useImperativeHandle(ref, () => {
			return {
				updatePlanDistance: handleDistanceUpdate
			};
		});

		const portNames = routeInfo?.portName || [];
		const titleArr = portNames.map((item, index) => `[${index + 1}] ${item}`);
		const title = `＞ ${titleArr?.join(" ~ ")}` || "";
		const simulationTitle = `* Route  ${titleArr?.join(" ➡ ")}`;
		return (
			<div className={styles["customTable"]}>
				<Loading loading={loading} />
				<div className="customTable-title">
					<div className="customTable-title-left">
						<Tooltip title={title}>{title}</Tooltip>
					</div>
					<div className="customTable-title-right">
						<Icon
							type={"icon-new"}
							className="canClick"
							onClick={() => {
								handleOpenRouteImportDialog();
							}}
						/>
						<Popover
							render={(setIsOpen) => {
								return (
									<Toast
										label="Are you sure want to delete?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											handleDelRoute(routeInfo?.id);
											setIsOpen(false);
										}}
									/>
								);
							}}
						>
							<Icon type={"icon-delete"} className="canClick" />
						</Popover>
					</div>
				</div>
				<div className="customTable-content fleet-nonScrollBar">
					<div className="customTable-header">
						{routeListColumns?.map((item, index) => (
							<div
								key={index}
								style={{
									maxWidth: item.width ?? 150,
									minWidth: item.width ?? 150
								}}
							>
								{item.title}
							</div>
						))}
						<div
							style={{
								maxWidth: 200,
								minWidth: 200
							}}
						>
							Operation
						</div>
					</div>
					<div className="customTable-body">
						{planList?.length === 0 && <NoData className="nodata" />}
						{planList?.map((item, index) => (
							<div key={item?.id}>
								<div className="customTable-body-row">
									{routeListColumns?.map((columnsItem, columnsIndex) => {
										return (
											<div
												key={columnsIndex}
												style={{
													maxWidth: columnsItem?.width ?? 150,
													minWidth: columnsItem.width ?? 150
												}}
											>
												{columnsItem?.render
													? columnsItem?.render(item?.routeName, item, index)
													: item[columnsItem?.dataIndex]}
											</div>
										);
									})}
									<div
										className="row-operations"
										style={{
											maxWidth: 200,
											minWidth: 200
										}}
									>
										<Icon
											type={"icon-edite_route"}
											className="canClick"
											onClick={() => {
												handleOpenWaypointList(item?.id);
											}}
										/>
										<Icon
											type={"icon-analyze"}
											className="canClick"
											onClick={() => {
												handleShowSimulation(item?.id);
											}}
										/>
										{wayPointShowInMapData?.[item?.id] ? (
											<Icon
												type={"icon-show"}
												className="canClick"
												onClick={() => {
													handleShowWaypoint(item?.id);
												}}
											/>
										) : (
											<Icon
												type={"icon-hide"}
												className="canClick"
												onClick={() => {
													handleShowWaypoint(item?.id);
												}}
											/>
										)}
										{item?.favoriteId ? (
											<Icon
												type={"icon-liked"}
												className="canClick"
												onClick={() => {
													handleCancelCollectPlan(item?.favoriteId);
												}}
											/>
										) : (
											<Icon
												type={"icon-like"}
												className="canClick"
												onClick={() => {
													handelCollectPlan(item?.id);
												}}
											/>
										)}
										<Icon
											type={"icon-copy"}
											className="canClick"
											onClick={() => {
												handleCopyPlan(item?.id);
											}}
										/>
										<Icon
											type={"icon-download"}
											className="canClick"
											onClick={() => {
												curSelcetedPlanId.current = item?.id;
												handleOpenPlanDownloadDialog();
											}}
										/>
										<Popover
											render={(setIsOpen) => {
												return (
													<Toast
														label="Are you sure want to delete?"
														onCancel={() => {
															setIsOpen(false);
														}}
														onConfirm={() => {
															handleDelPlan(item?.id);
															setIsOpen(false);
														}}
													/>
												);
											}}
										>
											<Icon type={"icon-delete"} className="canClick" />
										</Popover>
									</div>
								</div>
								{activePlan === item?.id && (
									<WaypointTable
										isFirstTable={index === 0}
										planInfo={item}
										resetPlanList={(data) => handleDistanceUpdate(data)}
										handleChangePlanName={(val) => {
											handleChangePlanName(val, index);
										}}
										activeWaypointRowId={activeWaypointRowId}
										onClickWaypoint={onClickWaypoint}
									/>
								)}
								{simulationOpenIds.includes(item?.id) && (
									<SimulationTable
										routeId={routeInfo.id}
										key={item?.id}
										planInfo={item}
										simulationTitle={simulationTitle}
										handleCloseSimulationList={() => {
											handleCloseSimulation(item?.id);
										}}
										simulationSelecedInfo={simulationSelecedInfo}
										setSimulationSelecedInfo={setSimulationSelecedInfo}
										onSelectForecast={onSelectForecast}
										onOpenForecast={onOpenForecast}
										onCloseForecast={onCloseForecast}
									/>
								)}
							</div>
						))}
					</div>
				</div>
				{routeImportDialogVisible && (
					<RouteImportDialog
						routeInfo={routeInfo}
						visible={routeImportDialogVisible}
						onClose={handleCloseRouteImportDialog}
						onFinish={initPageData}
					/>
				)}
				{planDownloadDialogVisible && (
					<PlanDownloadDialog
						visible={planDownloadDialogVisible}
						onClose={handleClosePlanDownloadDialog}
						onFinish={() => {}}
						planId={curSelcetedPlanId.current}
					/>
				)}
			</div>
		);
	}
);

// const CustomTable: FC<CustomTableProps> =

export default CustomTable;
