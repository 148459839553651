import { createSlice } from '@reduxjs/toolkit';
import InitialGlobalState, { GlobalState } from "./state";
import { cacheStorage } from '@/storage';

export const GlobalSlice = createSlice({
  name: "globalStore",
  initialState: InitialGlobalState,
  reducers: {
    setFirstSelected: (state, action) => {
      const globalInfo = cacheStorage.get("global_info")
      cacheStorage.set("global_info", {
        ...globalInfo,
        firstSelected: action.payload
      })
      return {
        ...state,
        firstSelected: action.payload
      }
    }
  }
})

export const {
  setFirstSelected
} = GlobalSlice.actions;

export default GlobalSlice.reducer;