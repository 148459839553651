export function replaceNullWithEmptyString<T extends Record<string, any>>(
	obj: T
): T {
	const result: Partial<T> = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			result[key] = obj[key] === null ? ("" as any) : obj[key];
		}
	}

	return result as T;
}
