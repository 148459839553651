import React, { FC } from "react";
import { Dialog, Button, Table, Icon, FormItem, Select } from "@/components";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";
import { CharterPartyDialogProps, CpTableItemType } from "./type";
import { categoryOptions, loadConditionOptions } from "./source";

const CharterPartyDialog: FC<CharterPartyDialogProps> = (props) => {
	const { hide, onCpClose, vesselName, imo } = props;

	const {
		cpTableData,
		columns,
		opColumns,
		handleCpAddClick,
		handlePickClick,
		selectedRowKeys,
		onSelectChange,
		filter,
		setFilter,
		pager,
		onPageChange
	} = useIndex(props);

	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={hide}
			onClose={onCpClose}
		>
			<div className={style["charter-party-dialog"]}>
				<div className="charter-party-header">
					<div>Charter Party</div>
					<div className="close-btn" onClick={onCpClose}>
						×
					</div>
				</div>
				<div className="charter-party-main">
					<div className="cp-header">
						<div>
							<span>charter parties of </span>
							<span>{vesselName}</span>
						</div>

						<Button
							type="secondary"
							suffixIcon={<Icon type="icon-add" />}
							onClick={() => handleCpAddClick()}
						>
							Add
						</Button>
					</div>

					<div className="cp-body">
						<div className="cp-filter">
							<FormItem label="Load Condition:" type="row">
								<Select
									className="cp-filter-select"
									allowClear
									onChange={(val: CpTableItemType["loadCondition"]) => {
										setFilter((prev) => ({ ...prev, loadCondition: val }));
									}}
									options={loadConditionOptions}
									value={filter.loadCondition}
									placeholder="Please select load condition"
								/>
							</FormItem>
							<FormItem label="Type:" type="row">
								<Select
									className="cp-filter-select"
									allowClear
									onChange={(val: CpTableItemType["speedCategory"]) => {
										setFilter((prev) => ({ ...prev, type: val }));
									}}
									options={categoryOptions}
									value={filter.type}
									placeholder="Please choose speed type"
								/>
							</FormItem>
						</div>
						<Table
							className="cp-table"
							id="id"
							scroll={null}
							dataSource={cpTableData}
							columns={[...columns, ...opColumns]}
							rowSelection={{
								selectedRowKeys,
								onChange: onSelectChange
							}}
							pagination={{
								current: pager.pageNum,
								pageSize: pager.pageSize,
								total: pager.total,
								onChange: onPageChange,
								showSizeChanger: true
							}}
						/>
					</div>
				</div>
				<div className="charter-party-footer">
					<Button type="secondary" onClick={onCpClose}>
						Cancel
					</Button>
					<Button onClick={() => handlePickClick()}>Pick</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default CharterPartyDialog;
