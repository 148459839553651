import { convertDdToDm } from "@/tools/latLong";
import { RouteListColumnsType } from "./type";
import React from "react";
import dayjs from "dayjs";

export const waypointTableColumns: RouteListColumnsType = [
	{
		title: "NO",
		dataIndex: "index",
		width: 40,
		render: (value, row, index) => {
			return <div>{index + 1}</div>;
		}
	},
	{
		title: "Position",
		dataIndex: "lat",
		width: 225,
		render: (value, row, index) => {
			if (!row?.lat || !row?.lon) {
				return <span>-</span>;
			}
			const {
				degree: latDegree,
				minute: latMinute,
				unit: latUnit
			} = convertDdToDm(row?.lat, true);
			const {
				degree: lonDegree,
				minute: lonMinute,
				unit: lonUnit
			} = convertDdToDm(row?.lon, false);
			const latStr = `${latDegree}°${latMinute}${latUnit}`;
			const lonStr = `${lonDegree}°${lonMinute}${lonUnit}`;
			return <span>{`${latStr} / ${lonStr}`}</span>;
		}
	},
	{
		title: "Nav",
		dataIndex: "nav"
	},
	{
		title: "Spd (kts)",
		dataIndex: "speed"
	},

	{
		title: "Stoppage(hr)",
		dataIndex: "stopHours"
	},
	{
		title: "Resume Time",
		dataIndex: "resumeTime",
		width: 180,
		render: (value, row, index) => {
			return (
				<div>
					{value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "-"}
				</div>
			);
		}
	}
];
