import React, { forwardRef, useImperativeHandle } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import mapboxgl from "mapbox-gl";
import { connectMapboxglToLeaflet } from "./tools";
import { RouteCommonMapProps, RouteCommonMapRefType } from "./type";
import useIndex from "./hooks/useIndex";
import "./lib/libBoot.js";
import {
	RouteCommonTimelineLegendPanel,
	RouteCommonToolbar
} from "./components";
import { RouteCommonProvider } from "./store";

connectMapboxglToLeaflet(window.L, mapboxgl);

const RouteCommonMap = forwardRef<RouteCommonMapRefType, RouteCommonMapProps>(
	(
		{ className, children, onWayPointFocus, onTyhoonSelect, onResetDistance },
		ref
	) => {
		const {
			mapRouteMenuRef,
			handleControllChange,
			meteoStatus,
			handleMeteoStatusChange,
			getMeteoCalendar,
			handleMeteoTimeChange,
			handleRangingSelect,
			handlePointControlSelect,
			legendVisible,
			handleLegendVisibleChange,
			handleRun,
			getMeteoCalendarEndTime,
			handlePointRemove,
			handleTrackLineCommit,
			handleTrackPointCommit,
			handleTrackPointFocus,
			handleNoonReportLoad,
			handleAisTrackLoad,
			handleMapFlyTo,
			handleBasicPortLoad,
			handleNoonReportVisible
		} = useIndex(onWayPointFocus, onTyhoonSelect, onResetDistance);

		useImperativeHandle(ref, () => {
			return {
				loadWayTrackLine: handleTrackLineCommit,
				loadWayTrackPoint: handleTrackPointCommit,
				focusWayTrackPoint: handleTrackPointFocus,
				loadNoonReports: handleNoonReportLoad,
				loadAisTracks: handleAisTrackLoad,
				flyTo: handleMapFlyTo,
				loadBasicPorts: handleBasicPortLoad,
				onNoorReportsVisible: handleNoonReportVisible
			};
		});

		return (
			<RouteCommonProvider
				value={{
					getMeteoCalendarEndTime: getMeteoCalendarEndTime
				}}
			>
				<div className={classnames(style["routeCommon-map"], className)}>
					<div className={style["wMap"]} id={"windy"}></div>
					<RouteCommonToolbar
						meteoStatus={meteoStatus}
						legendVisible={legendVisible}
						onMeteoStatusChange={handleMeteoStatusChange}
						onControllChange={handleControllChange}
						onLegendVisibleChange={handleLegendVisibleChange}
						onPointSelect={handlePointControlSelect}
						onRangeSelect={handleRangingSelect}
					/>
					<div className="routeCommon-map-extend">
						<RouteCommonTimelineLegendPanel
							meteoStatus={meteoStatus}
							visible={legendVisible}
							onMeteoTimeChange={handleMeteoTimeChange}
							onRun={handleRun}
						/>
						{children && <div className="map-extend-container">{children}</div>}
					</div>
					<div
						id="map-route-menu"
						onClick={() => {
							handlePointRemove();
						}}
						ref={mapRouteMenuRef}
					></div>
				</div>
			</RouteCommonProvider>
		);
	}
);

export default RouteCommonMap;
