import RouteDiscretePointerIcon from "static/images/routeCommon/routeCommon_discrete_pointer.png";
import RouteWayPointIcon from "static/images/leg/route/leg_waypoint_icon.png";
import RouteWayPointSelectedIcon from "static/images/leg/route/leg_waypoint_selected_icon.png";
import NoonReportIcon from "static/images/leg/noon/leg_noon_report_icon.png";
import NoonReportBgIcon from "static/images/leg/noon/leg_noon_report_bg_icon.png";
import AisVesselSelectIcon from "static/images/leg/ais/leg_ais_vessel_selected.png";
import AisVesselPointerIcon from "static/images/leg/ais/leg_ais_vessel_pointer_icon.png";
import AisVesselTrackArrowIcon from "static/images/leg/ais/leg_ais_vessel_arrow_icon.png";
import AisVesselInfoIcon from "static/images/leg/ais/leg_ais_vessel_info_icon.png";
import AisVesselTrackWarningIcon from "static/images/leg/ais/leg_ais_vessel_info_warning_icon.png";
import AisVesselPointerWarningIcon from "static/images/leg/ais/leg_ais_vessel_pointer_warning_icon.png";
import BasicPortIcon from "static/images/leg/basic/leg_basic_port_icon.png";
import BasicPortInfoIcon from "static/images/leg/basic/leg_basic_port_bg_icon.png";
import {
	RouteCommonMeteoType,
	RouteCommonToolbarMeteoSwitchMap
} from "./components";

export const aisVesselTrackLayers = {
	"ais-vessel-source": "ais-vessel-layer",
	"ais-vesselTrack-source": "ais-vesselTrack-layer",
	"ais-vesselTrackPointer-source": "ais-vesselTrackPointer-layer",
	"ais-vesselTrackArrow-source": "ais-vesselTrackArrow-layer",
	"ais-vesselTrackInfo-source": "ais-vesselTrackInfo-layer"
};

export const vesselIconNames: (
	| "discretePointerIcon"
	| "routeWayPointIcon"
	| "routeWayPointSelectedIcon"
	| "noonReportIcon"
	| "noonReportBgIcon"
	| "aisVesselSelectIcon"
	| "aisVesselPointerIcon"
	| "aisVesselInfoIcon"
	| "aisVesselTrackArrowIcon"
	| "aisVesselTrackWarningIcon"
	| "aisVesselPointerWarningIcon"
	| "basicPortIcon"
	| "basicPortInfoIcon"
)[] = [
	"discretePointerIcon",
	"routeWayPointIcon",
	"routeWayPointSelectedIcon",
	"noonReportIcon",
	"noonReportBgIcon",
	"aisVesselSelectIcon",
	"aisVesselPointerIcon",
	"aisVesselInfoIcon",
	"aisVesselTrackArrowIcon",
	"aisVesselTrackWarningIcon",
	"aisVesselPointerWarningIcon",
	"basicPortIcon",
	"basicPortInfoIcon"
];

export const routeCommonVesselIconMap: Record<
	(typeof vesselIconNames)[number],
	string
> = {
	discretePointerIcon: RouteDiscretePointerIcon,
	routeWayPointIcon: RouteWayPointIcon,
	routeWayPointSelectedIcon: RouteWayPointSelectedIcon,
	noonReportIcon: NoonReportIcon,
	noonReportBgIcon: NoonReportBgIcon,
	aisVesselSelectIcon: AisVesselSelectIcon,
	aisVesselPointerIcon: AisVesselPointerIcon,
	aisVesselInfoIcon: AisVesselInfoIcon,
	aisVesselTrackArrowIcon: AisVesselTrackArrowIcon,
	aisVesselTrackWarningIcon: AisVesselTrackWarningIcon,
	aisVesselPointerWarningIcon: AisVesselPointerWarningIcon,
	basicPortIcon: BasicPortIcon,
	basicPortInfoIcon: BasicPortInfoIcon
};

export const initialMeteoStatus: Record<
	RouteCommonMeteoType,
	RouteCommonToolbarMeteoSwitchMap
> = {
	Wind: RouteCommonToolbarMeteoSwitchMap.Off,
	Wave: RouteCommonToolbarMeteoSwitchMap.Off,
	Swell: RouteCommonToolbarMeteoSwitchMap.Off,
	Current: RouteCommonToolbarMeteoSwitchMap.Off,
	Typhoon: RouteCommonToolbarMeteoSwitchMap.Off,
	Pressure: RouteCommonToolbarMeteoSwitchMap.Off
};
