import {
	Button,
	Dialog,
	Table,
	FleetDatePicker,
	FormItem,
	FleetInput,
	Icon
} from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { newRouteDialogProps } from "./type";
const NewRouteDialogDialog: FC<newRouteDialogProps> = ({
	visible,
	onClose,
	onFinish
}) => {
	const {
		loading,
		hanleCloseDialog,
		tableData,
		handleSelectPort,
		handleChangeStopHours,
		handleConfirm
	} = useIndex(onFinish, onClose);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={() => {
				hanleCloseDialog(onClose);
			}}
			loading={loading}
		>
			<div className={styles["newRouteDialog"]}>
				<div className="fleet-header">
					<div className="newRouteDialog-header">
						<div>New Route</div>
						<Icon
							type="icon-close2"
							className="close-btn"
							onClick={() => {
								hanleCloseDialog(onClose);
							}}
						/>
					</div>
				</div>
				<div className="newRouteDialog-main">
					{/* <FormItem label={itemKey?.toUpperCase() + ":"} type="row">
						<FleetDatePicker
							onChange={(val, timeStramp) => {
								onChange?.(val);
							}}
							value={time ? dayjs(time) : null}
							showTime={true}
							showHour={true}
							showMinute={true}
							allowClear
						/>
					</FormItem> */}
					<Table
						dataSource={tableData?.dataSource}
						columns={[
							{
								title: "Port Name",
								dataIndex: "portName"
							}
							// {
							// 	title: " Stop Hours",
							// 	dataIndex: "stopHours",
							// 	render: (text, record) => (
							// 		<FleetInput
							// 			value={text || ""}
							// 			onChange={(val) => {
							// 				handleChangeStopHours(val, record);
							// 				// console.log(val, text, record);
							// 			}}
							// 		/>
							// 	)
							// }
						]}
						rowSelection={{
							selectedRowKeys: tableData?.selectedRowKeys,
							onChange: (key) => {
								handleSelectPort(key);
							}
						}}
						bordered={true}
						id="id"
						scroll={{ x: 350 }}
						pagination={false}
						className="ais-table"
					/>
				</div>
				<div className="newRouteDialog-footer">
					<Button
						type="secondary"
						onClick={() => {
							hanleCloseDialog(onClose);
						}}
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							handleConfirm();
						}}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default NewRouteDialogDialog;
