import React, { createContext, useContext } from "react";
import { Dayjs } from "dayjs";

export type RouteCommonProviderProps = {
	value: {
		getMeteoCalendarEndTime?: () => Dayjs;
	};
	children?: React.ReactNode;
};

export const RouteCommonContext = createContext<
	RouteCommonProviderProps["value"]
>({});

const useRouteCommonContext = () => {
	const context = useContext(RouteCommonContext);
	return context;
};

const RouteCommonProvider: React.FC<RouteCommonProviderProps> = ({
	value,
	children
}) => {
	return (
		<RouteCommonContext.Provider
			value={{
				...value
			}}
		>
			{children}
		</RouteCommonContext.Provider>
	);
};

export { RouteCommonProvider, useRouteCommonContext };
