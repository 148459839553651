import { GeoJSONSource, MapEventOf } from "mapbox-gl";
import { MutableRefObject, useRef } from "react";
import { addSourceToMap } from "../utils";
import ReactDOMServer from "react-dom/server";
import { RouteCommonPointPopup } from "../components";
import React from "react";
import mapboxgl from "mapbox-gl";
import dayjs from "dayjs";
import { useAppDispatch } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { useReminder } from "@/hooks";
import mapStyle from "../index.module.less";
import { onRequest } from "@/api";

const useDiscrete = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const pointOption = useRef<{ visible: boolean; popup: mapboxgl.Popup }>({
		visible: false,
		popup: null
	});
	const loadDiscreteLayers = () => {
		addSourceToMap(mapboxGl.current, {
			sourceName: "route-discrete-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
		mapboxGl.current.addLayer({
			id: "route-discrete-layer",
			type: "symbol",
			source: "route-discrete-source",
			layout: {
				"icon-image": "discretePointerIcon",
				"icon-size": 0.2,
				"icon-offset": [0, -50]
			}
		});
	};

	const getRouteCommonDiscreteFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const onDiscreteListen = async (event: MapEventOf<"click">) => {
		// return;
		if (!pointOption.current.visible) return;
		const currentSource = mapboxGl?.current?.getSource(
			"route-discrete-source"
		) as GeoJSONSource;
		// // return;
		onRequest(
			"getRouteCommonDiscreteApi",
			{
				lat: event?.lngLat?.lat,
				lng: event?.lngLat?.lng,
				dataTime: dayjs()?.valueOf()
			},
			null,
			(response) => {
				currentSource?.setData({
					type: "FeatureCollection",
					features: [
						{
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: [event?.lngLat?.lng, event?.lngLat?.lat]
							},
							properties: {}
						}
					]
				});

				const htmlString = ReactDOMServer.renderToString(
					<RouteCommonPointPopup
						item={{
							...response?.data?.[0],
							lat: event?.lngLat?.lat,
							lng: event?.lngLat?.lng
						}}
					/>
				);
				if (pointOption.current.popup) {
					pointOption.current.popup
						?.setHTML(htmlString)
						.setLngLat(event?.lngLat);
					return;
				}

				const popup = new mapboxgl.Popup({
					closeButton: false,
					closeOnClick: false,
					closeOnMove: false,
					anchor: "bottom-left",
					offset: [0, -25],
					className: mapStyle["routeCommon-map-vesselInfo-popup"]
				})
					.setHTML(htmlString)
					.setLngLat(event?.lngLat)
					.addTo(mapboxGl?.current);
				pointOption.current.popup = popup;
			},
			getRouteCommonDiscreteFailed,
			dispatch,
			navigate
		);
	};

	const handlePointControlSelect = (value: boolean) => {
		if (!value) {
			pointOption?.current?.popup?.remove();
			pointOption.current.popup = null;
			const currentSource = mapboxGl?.current?.getSource(
				"route-discrete-source"
			) as GeoJSONSource;
			currentSource?.setData({
				type: "FeatureCollection",
				features: []
			});
		}
		pointOption.current.visible = value;
		// const htmlString = ReactDOMServer.renderToString(<RouteCommonPointPopup />);

		// const popup = new mapboxgl.Popup({
		// 	closeButton: false,
		// 	offset: [0, -25]
		// })
		// 	.setHTML(htmlString)
		// 	.setLngLat(option?.position)
		// 	.addTo(mapboxGl);
	};

	return {
		pointOption,
		loadDiscreteLayers,
		onDiscreteListen,
		handlePointControlSelect
	};
};

export default useDiscrete;
