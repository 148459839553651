import React, { FC } from "react";
import style from "./index.module.less";
import { NyButtonProps } from "./type";
import classNames from "classnames";

const NyButton: FC<NyButtonProps> = (props) => {
	const { value, onChange, disabled = false, className } = props;

	const handleDoubleClick = (event) => {
		if (!disabled) {
			event.stopPropagation();
			onChange(!value);
		}
	};

	return (
		<div
			className={classNames(style["ny-button"], className, {
				["ny-button-disabled"]: disabled
			})}
			onDoubleClick={handleDoubleClick}
		>
			{value ? "Y" : "N"}
		</div>
	);
};

export default NyButton;
