import { CommonOptionType } from "@/types/common";
import { CpTableItemType } from "./type";

export const loadConditionOptions: CommonOptionType<
	CpTableItemType["loadCondition"],
	CpTableItemType["loadCondition"]
>[] = [
	{ label: "B", value: "B" },
	{ label: "L", value: "L" }
];

export const categoryOptions: CommonOptionType<
	CpTableItemType["speedCategory"],
	CpTableItemType["speedCategory"]
>[] = [
	{ label: "ECO", value: "ECO" },
	{ label: "FULL", value: "FULL" },
	{ label: "OTHER", value: "OTHER" }
];
