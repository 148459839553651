import React, { useState, useRef, useEffect } from "react";
import { SplitPanelProps } from "../type";

const useIndex = (props: SplitPanelProps) => {
	const { initialLeftWidth } = props;

	const [leftWidth, setLeftWidth] = useState<number>(initialLeftWidth);
	const [isDragging, setIsDragging] = useState(false);
	const splitPaneRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleMouseMove = (event: MouseEvent) => {
			if (!isDragging || !splitPaneRef.current) return;
			const rect = splitPaneRef.current.getBoundingClientRect();
			const newLeftWidth = event.clientX - rect.left;
			setLeftWidth(newLeftWidth);
		};

		const handleMouseUp = () => {
			setIsDragging(false);
			document.body.style.userSelect = "";
		};

		window.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("mouseup", handleMouseUp);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, [isDragging]);

	const handleMouseDown = () => {
		setIsDragging(true);
		document.body.style.userSelect = "none";
	};

	const leftStyle: React.CSSProperties = {
		width: `${leftWidth}px`
	};
	const rightStyle: React.CSSProperties = {
		width: `calc(100% - ${leftWidth}px)`
	};
	const splitBarStyle: React.CSSProperties = {
		backgroundColor: isDragging ? "#aacee5" : ""
	};

	return {
		splitPaneRef,
		handleMouseDown,
		leftStyle,
		rightStyle,
		splitBarStyle
	};
};

export default useIndex;
