import React, { FC } from "react";
import styles from "./index.module.less";
import {
	Button,
	Icon,
	Loading,
	Popover,
	Table,
	Toast,
	Radio,
	Select
} from "@/components";
import { ReportProps } from "./type";
import classNames from "classnames";
import {
	ReportPdfDialog,
	VoyageDeviationReport,
	AddDevReportDialog
} from "./components";
import { LegBasicInfo } from "../../components";
import useIndex from "./hooks/useIndex";
import { cloumns } from "./source";
const Report: FC<ReportProps> = ({ activeTab }) => {
	const {
		loading,
		reportList,
		pdfDialogVisible,
		deviationVisible,
		handleDeviationVisibleChange,
		handleReportPdfDialogVisible,
		distType,
		cfType,
		handleDistTypeChange,
		handleCfTypeChange,
		handleReportCreate,
		opColumns,
		curReportId,
		fromPortId,
		setFromPortId,
		toPortId,
		setToPortId,
		fromListPortOptions,
		toListPortOptions,
		addDevDialogVisible,
		handleAddDevVisibleChange,
		handleAddDevReport
	} = useIndex(activeTab);
	return (
		<div
			className={classNames(styles["report"], {
				"report-active": activeTab === "report"
			})}
		>
			{/* <Loading loading={loading} /> */}
			{/* Reported --- handleDeviationVisibleChange(); */}
			{/* Analyzed --- handleReportPdfDialogVisible(); */}
			<LegBasicInfo />
			<div className="operationArea">
				<Button onClick={() => handleAddDevVisibleChange(true)}>
					Dev. Report
				</Button>
				<Popover
					placement="right-start"
					render={(setIsOpen) => (
						<div className="popover-content">
							<div className="create-form-item">
								<p>Distance</p>
								<div className="create-form-box">
									<Radio
										value={distType === "Reported"}
										label="Reported"
										onChange={() => handleDistTypeChange("Reported")}
									/>
									<Radio
										value={distType === "Analyzed"}
										label="Analyzed"
										onChange={() => handleDistTypeChange("Analyzed")}
									/>
								</div>
							</div>
							<div className="create-form-item">
								<p>CF</p>
								<div className="create-form-box">
									<Radio
										value={cfType === "Daily"}
										label="Daily"
										onChange={() => handleCfTypeChange("Daily")}
									/>
									<Radio
										value={cfType === "3 Hourly"}
										label="3 Hourly"
										onChange={() => handleCfTypeChange("3 Hourly")}
									/>
								</div>
							</div>
							<div className="create-form-item">
								<p className="create-form-item-title">Port Rotation</p>
								<p>From: </p>
								<Select
									className="port-rotation-select"
									value={fromPortId}
									options={fromListPortOptions}
									onChange={(val: string) => {
										setFromPortId(val);
									}}
								></Select>
								<p>To: </p>
								<Select
									className="port-rotation-select"
									value={toPortId}
									options={toListPortOptions}
									onChange={(val: string) => {
										setToPortId(val);
									}}
								></Select>
							</div>

							<Button
								className="create-form-btn"
								type="text"
								onClick={() => {
									setIsOpen(false);
									handleReportCreate();
								}}
							>
								Confirm
							</Button>
						</div>
					)}
				>
					<Button>Perf. Report</Button>
				</Popover>
			</div>
			<Table
				loading={loading}
				dataSource={reportList}
				bordered={true}
				columns={[...cloumns, ...opColumns]}
				id="id"
				pagination={false}
				className="report-table"
				scroll={{ x: 500 }}
			/>
			{pdfDialogVisible && (
				<ReportPdfDialog
					reportId={curReportId}
					visible={pdfDialogVisible}
					onClose={handleReportPdfDialogVisible}
				/>
			)}
			{deviationVisible && (
				<VoyageDeviationReport
					reportId={curReportId}
					visible={deviationVisible}
					onClose={handleDeviationVisibleChange}
				/>
			)}

			{addDevDialogVisible && (
				<AddDevReportDialog
					visible={addDevDialogVisible}
					onClose={() => handleAddDevVisibleChange(false)}
					onConfirm={(data) => handleAddDevReport(data)}
				/>
			)}
		</div>
	);
};

export default Report;
