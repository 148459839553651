import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { Image } from "@/components";
import { RadioProps } from "./interface";
import CheckedIcon from "static/images/routeCommon/routeCommon_checked_icon.svg";
import CheckedBgIcon from "static/images/routeCommon/routeCommon_checkedBg_icon.svg";

export const Radio: React.FC<RadioProps> = ({
	label,
	onChange,
	className,
	disabled,
	value
}) => {
	const handleChange: React.MouseEventHandler<HTMLDivElement> = (e) => {
		e?.stopPropagation();
		if (disabled) return;
		onChange?.(!value);
	};
	return (
		<div
			className={classnames(style["fleet-radio"], className)}
			onClick={handleChange}
		>
			<Image className="radio-icon" src={value ? CheckedIcon : CheckedBgIcon} />
			{label && <span className="radio-label">{label}</span>}
		</div>
	);
};

export default Radio;
