import React, { FC, useState } from "react";
import styles from "./index.module.less";
import {
	Button,
	Popover,
	Toast,
	Table,
	FleetDatePicker,
	Icon,
	Loading
} from "@/components";
import { LegBasicInfo } from "@/pages/leg/business/details/components/legBasicInfo";
import { AISTableColumns } from "./source";
import { FleetTitle, SplitPanel } from "../../common";
import classNames from "classnames";
import { AisProps, AisType } from "./type";
import useIndex from "./hook/useIndex";
import { ContextMenu } from "./components";

const Ais: FC<AisProps> = ({ activeTab, onLoadAisTracks, onFocus }) => {
	const {
		loading,
		aisList,
		contextMenu,
		menuPosition,
		selectedAisItem,
		initAisList,
		handleContextMenu,
		handleCloseMenu,
		handleDownloadAis,
		handleShowTracks,
		handleScroll
	} = useIndex(activeTab, onLoadAisTracks);

	return (
		<div
			className={classNames(styles["leg-ais"], {
				[styles["leg-ais-activity"]]: activeTab === "ais"
			})}
		>
			<Loading loading={loading} />
			<div className="ais-basicInfo">
				<LegBasicInfo />
				<div className="ais-title">
					<FleetTitle text="AIS" />
					<div className="btns">
						<Icon
							type="icon-refresh"
							onClick={() => {
								initAisList();
							}}
						></Icon>
						<Icon type="icon-leg" onClick={handleShowTracks}></Icon>
						<Icon
							type="icon-download"
							onClick={handleDownloadAis}
							className={classNames({
								"voy-disabled": !aisList || !aisList?.length
							})}
						></Icon>
					</div>
				</div>

				<Table
					className="ais-table"
					id="id"
					bordered={true}
					pagination={false}
					scroll={{ x: 350, y: 600 }}
					dataSource={aisList}
					columns={AISTableColumns}
					onRow={(data, index) => {
						return {
							onContextMenu: (e) => {
								handleContextMenu(e, data);
							},
							onClick: () => {
								onFocus({
									lon: data.lon,
									lat: data.lat
								});
							}
						};
					}}
					onScroll={(e) => {
						handleScroll(e);
					}}
				/>
			</div>
			<ContextMenu
				show={contextMenu}
				aisItem={selectedAisItem}
				position={menuPosition}
				onClose={handleCloseMenu}
				onSuccess={initAisList}
			/>
		</div>
	);
};

export default Ais;
