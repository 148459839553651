import React from "react";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";

const ExcelContainer: React.FC<{ url: string }> = ({ url }) => {
	const { sheets } = useIndex(url);

	return (
		<div className={style["fp-excel-container"]}>
			{Object.keys(sheets).map((sheetName) => (
				<div key={sheetName}>
					<h2>{sheetName}</h2>
					<table>
						<tbody>
							{sheets[sheetName].map((row, rowIndex) => (
								<tr key={rowIndex}>
									{row.map((cell, cellIndex) => (
										<td key={cellIndex}>{cell}</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			))}
		</div>
	);
};

export default ExcelContainer;
