import React, { useCallback } from "react";
import { Button, Image } from "@/components";
import style from "./index.module.less";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hooks";
import { loginOutAction } from "@/store/userSlice";

const Page404: React.FC<{}> = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const handleBack = useCallback(() => {
		navigate("/");
	}, []);

	const handleExit = useCallback(() => {
		dispatch(loginOutAction(() => navigate("/login")));
	}, []);
	return (
		<div className={classnames("voy-common", style["fleet-404"])}>
			<div className="errorPage">
				<Image
					className="errorPage-logo"
					src="./images/fleet_404_logo_icon.svg"
				/>
				<Image
					className="errorPage-logoTitle"
					src="./images/fleet_logoTitle_icon.svg"
				/>
				<Image className="errorPage-image" src="./images/fleet_404_image.svg" />
				<span className="errorPage-404">404</span>
				<span className="errorPage-tip">
					Sorry, you are not authorized to access this page.
				</span>
				<div className="errorPage-operation">
					<Button onClick={handleBack}>Back Home</Button>
					<Button onClick={handleExit}>Exit</Button>
				</div>
			</div>
		</div>
	);
};

export default Page404;
