import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cacheStorage } from "@/storage";
import { StorageKeyMap } from "@/storage/types";
import { initialUserState } from "./state";
import { userLogoutApi } from "@/api/login";

export const loginOutAction = createAsyncThunk(
	"login/out",
	async (callback?: () => void) => {
		debugger;
		const res = await userLogoutApi();
		console.log("res", res);
		cacheStorage?.logout();
		callback?.();
		return res;
	}
);

export const UserSlice = createSlice({
	name: "userStore",
	initialState: initialUserState,
	reducers: {
		loginIn: (state, action) => {
			const access_token =
				action.payload.access_token ||
				cacheStorage.get(StorageKeyMap["access_token"]);
			const userInfo = { ...action.payload.userInfo };
			cacheStorage.set(StorageKeyMap["user_info"], userInfo);
			cacheStorage.set(StorageKeyMap["access_token"], access_token);
			return {
				...state,
				access_token: access_token,
				userInfo: userInfo
			};
		},
		loginOut: (state, action) => {
			cacheStorage.logout();
			return {
				...state,
				access_token: "",
				userInfo: {
					username: state?.userInfo?.username,
					password: state?.userInfo?.password
				}
			};
		},
		rememberIn: (state, action) => {
			const userInfo = {
				...state,
				username: action.payload.username,
				password: action.payload.password || ""
			};
			cacheStorage.set(StorageKeyMap["remember"], action.payload.remember);
			cacheStorage.set(StorageKeyMap["user_info"], userInfo);
			return {
				...state,
				userInfo: {
					...userInfo
				},
				remember: action.payload.remember
			};
		}
	},
	extraReducers(builder) {
		builder
			.addCase(loginOutAction.pending, (state) => {
				console.log("🚀 ~ 进行中！");
			})
			.addCase(loginOutAction.fulfilled, (state, { payload }) => {
				console.log("🚀 ~ fulfilled", payload);
				// state.list = payload.data.list
				// state.totals = payload.data.list.length
				cacheStorage.clear();
				return {
					...state,
					access_token: "",
					userInfo: {
						username: state?.userInfo?.username,
						password: state?.userInfo?.password
					}
				};
			})
			.addCase(loginOutAction.rejected, (state, err) => {
				console.log("🚀 ~ rejected", err);
			});
	}
});

export const { loginIn, loginOut, rememberIn } = UserSlice.actions;

export default UserSlice.reducer;
