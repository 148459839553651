import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import debounce from "lodash/debounce";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { LegFilterItemType, LegFilterProps } from "../components";
import { Toast, Popover } from "@/components";
import { VoyDataType, LegTableItemType } from "../type";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState<LegFilterItemType>({
		imo: "",
		companyId: "",
		portCode: "",
		status: null,
		service: null,
		role: null,
		condition: null,
		legType: null,
		startETDDate: null,
		endETDDate: null
		// tags: null
	});

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const cacheSource = useRef<{ [index: number]: LegTableItemType[] }>({});

	const handleFilterCommit: LegFilterProps["onCommit"] = (item) => {
		setFilter((prev) => {
			return { ...prev, ...item };
		});
		getLegPage?.({ ...filter, ...item });
	};

	const onSelectChange = (newSelectedRowKeys: number[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const dataSource = useMemo(() => {
		return cacheSource.current[pager.pageNum];
	}, [pager.pageNum, pager.total]);

	const getLegPageFront = () => {
		setLoading(true);
	};

	const getLegPageSuccess = (response) => {
		let total = 0;
		if (!Array.isArray(response?.data)) {
			cacheSource.current[1] = response?.data?.records;
			total = response?.data?.total;
		} else {
			cacheSource.current = {};
		}
		setPager((prev) => ({ ...prev, total, pageNum: pager.pageNum }));
		setLoading(false);
	};

	const getLegPageFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};

	const getLegPage = (params?: Partial<LegFilterItemType>) => {
		onRequest(
			"getLegPageApi",
			{
				...filter,
				...params,
				pageSize: pager.pageSize,
				pageNum: pager.pageNum
			},
			getLegPageFront,
			getLegPageSuccess,
			getLegPageFailed,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions: LegFilterProps["onSearch"] = (key, value) => {
			cacheSource.current = {};
			handleFilterCommit?.({ [key]: value });
		};
		return debounce(loadOptions, 500);
	}, []);

	useEffect(() => {
		getLegPage();
	}, []);

	const onPageChange = (page: number, size: number) => {
		onRequest(
			"getLegPageApi",
			{
				...filter,
				pageSize: size,
				pageNum: page
			},
			getLegPageFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.records;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getLegPageFailed,
			dispatch,
			navigate
		);
	};

	const opereteColumns: ColumnsType<VoyDataType> = useMemo(() => {
		return [
			{
				dataIndex: "option",
				width: 120,
				title: "Action",
				key: "option",
				align: "center",
				fixed: "right",
				render: (value, record, index) => {
					return (
						<div>
							<Popover
								render={(setIsOpen) => {
									return (
										<Toast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												setIsOpen(true);
											}}
										/>
									);
								}}
							>
								<span className="fleet-delete">delete</span>
							</Popover>
						</div>
					);
				}
			}
		];
	}, []);

	const handleRowDblClick = (item) => {
		const query = `?id=${item?.id}&legType=${item?.legType}&voyageId=${item?.voyageId}&imo=${item?.imo}`;
		window.open("/layout/legDetails" + query);
	};

	return {
		loading,
		filter,
		handleFilterCommit,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		onSearch,
		opereteColumns,
		handleRowDblClick
	};
};
export default useIndex;
