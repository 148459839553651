import { APP_VERSION_SYS, APP_VERSION_V1 } from "@/api/constant";
import { ApiParameter, RequestApiMapType } from "@/api/type";
import request from "@/service";
import { FuzzySearchType, CommonParamsType } from "./type";

const enum ApiMap {
	getPortsParameter,
	getVesselsParameter,
	getCompaniesParameter
}
export type ApiUrl = keyof typeof ApiMap;

const legApi: ApiParameter<ApiUrl> = {
	getPortsParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/port/selection"
	},
	getVesselsParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/vessel/selection"
	},
	getCompaniesParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/company/selection"
	}
};

export const getPortsApi = (params: FuzzySearchType) => {
	return request.request({
		...legApi.getPortsParameter,
		url: `${legApi.getPortsParameter.url}?key=${params.key}`,
		params: { ...params, key: undefined } // fix: Avoid converting Spaces to +
	});
};
export const getVesselsApi = (params: FuzzySearchType) => {
	return request.request({
		...legApi.getVesselsParameter,
		url: `${legApi.getVesselsParameter.url}?key=${params.key}`,
		params: { ...params, key: undefined } // fix: Avoid converting Spaces to +
	});
};
export const getCompaniesApi = (params: FuzzySearchType) => {
	return request.request({
		...legApi.getCompaniesParameter,
		url: `${legApi.getCompaniesParameter.url}?key=${params.key}`,
		params: { ...params, key: undefined } // fix: Avoid converting Spaces to +
	});
};

export const CommonApiMap: RequestApiMapType<ApiUrl, CommonParamsType> = {
	getPortsApi,
	getVesselsApi,
	getCompaniesApi
};
