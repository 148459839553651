import { useLocation, useNavigate } from "react-router-dom";
import { useReminder, useAppDispatch, useAppSelector } from "@/hooks";
import { cacheStorage } from "@/storage";
import { useEffect, useState } from "react";
import { BasicInfoChangeEvent } from "../components/basicInfo/type";
import { PersonalCenterItemType } from "../type";
import { onRequest } from "@/api";

const useIndex = () => {
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const searchParams = useLocation();
	const { firstSelected } = useAppSelector((state) => state.GlobalStore);

	const access_token = cacheStorage.get("access_token");
	const _access_token = useAppSelector((state) => state.UserStore.access_token);

	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<PersonalCenterItemType>();
	const getPersonalInfoSuccess = (res) => {
		setLoading(false);
		setDataSource(res.data?.user);
	};
	const getPersonalInfoError = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleChange: BasicInfoChangeEvent = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }));
	};

	const getPersonalInfo = () => {
		onRequest(
			"getPersonalInfoApi",
			null,
			getPersonalInfoFront,
			getPersonalInfoSuccess,
			getPersonalInfoError,
			dispatch,
			navigate
		);
	};

	const getPersonalInfoFront = () => {
		setLoading(true);
	};

	const handleBack = () => {
		navigate(`${firstSelected}${searchParams.search}`);
	};
	useEffect(() => {
		if (!(access_token || _access_token)) {
			navigate("/login");
			return;
		}
		getPersonalInfo();
	}, []);

	return {
		loading,
		dataSource,
		handleChange,
		handleBack,
		getPersonalInfo
	};
};

export default useIndex;
