import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import style from "./index.module.less";
import { useAppDispatch } from "@/hooks";
import { setFirstSelected } from "@/store/globalSlice";
import { LayoutNav } from "./components";
import { CommonLayout } from "@/common";
import { LayoutChildren } from "./router";

const Layout: React.FC<{}> = (props) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setFirstSelected(location?.pathname));
	}, [location?.pathname]);

	return (
		<CommonLayout className={style["fleet-layout"]} items={LayoutChildren}>
			<LayoutNav />
			<div className="layout-outlet">
				<Outlet />
			</div>
		</CommonLayout>
	);
};

export default Layout;
