import { useEffect } from "react";
import { useLocation, Location } from "react-router-dom";

type RouteChangeCBType = (location: Location, pathList: string[]) => void;

const useRouteChange = (callback: RouteChangeCBType) => {
	const location = useLocation();

	useEffect(() => {
		const pathList = location.pathname
			.split("/")
			.filter((segment) => segment !== "");
		callback(location, pathList);
	}, [location]);
};

export default useRouteChange;
