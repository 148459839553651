import { MutableRefObject } from "react";
import { addSourceToMap } from "../utils";
import { RouteCommonMapRefType } from "../type";
import { GeoJSONSource } from "mapbox-gl";
import { generateBasicPorts } from "../utils/basic";

const portSource = {
	"basic-port-source": "basic-port-layer",
	"basic-port-info-source": "basic-port-info-layer"
};

const usePort = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadSource = () => {
		addSourceToMap(mapboxGl?.current, {
			sourceName: "basic-port-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: []
				}
			}
		});
	};

	const loadlayers = () => {
		mapboxGl.current.addLayer({
			id: portSource["basic-port-info-source"],
			type: "symbol",
			source: "basic-port-source",
			layout: {
				// 'symbol-placement': 'line',
				"symbol-spacing": 100, // 图标间隔，默认为250
				"icon-image": "basicPortInfoIcon",
				"icon-size": 0.2,
				"text-field": ["concat", ["get", "seq"], "   ", ["get", "portName"]],
				// "text-field": ["concat", ["get", "seq"], " ", ["get", "portName"]],
				"icon-offset": [380, 0],
				"text-offset": [7.3, 0],
				"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
				// 文字大小
				"text-size": 10,
				"text-allow-overlap": true,
				"icon-allow-overlap": true
			},
			paint: {
				"text-color": "#fff"
			}
		});
		mapboxGl?.current?.addLayer({
			id: portSource["basic-port-source"],
			source: "basic-port-source",
			type: "symbol",
			layout: {
				"icon-image": "basicPortIcon",
				"icon-size": 1,
				"icon-allow-overlap": true
			}
		});
	};

	const handleBasicPortLoad: RouteCommonMapRefType["loadBasicPorts"] = (
		items
	) => {
		console.log("items", items);
		const currentSource =
				mapboxGl?.current?.getSource<GeoJSONSource>("basic-port-source"),
			portItems = generateBasicPorts(items);
		console.log("items", items, portItems);
		currentSource?.setData({
			type: "FeatureCollection",
			features: portItems
		});
	};

	return {
		loadSource,
		loadlayers,
		handleBasicPortLoad
	};
};

export default usePort;
