export enum StorageKeyMap {
	access_token = "access_token",
	refresh_token = "refresh_token",
	user_info = "user_info",
	global_info = "global_info",
	estimation_info = "estimation_info",
	remember = "remember",
	permissions = "permissions",
	vm_search_keys = 'vm_search_keys',
	ais_search_keys = 'ais_search_keys',
};

export type StorageKeys = keyof typeof StorageKeyMap;

export type StorageType = {
	set: (
		key: StorageKeys,
		value: any,
		expire?: number,
		isEncrypt?: boolean
	) => void;
	get: (key: StorageKeys) => any;
	getAll: () => any;
	getLen: () => any;
	isSub: () => boolean;
	isJson: (value: any) => boolean;
	has: (key: StorageKeys) => boolean;
	del: (key: StorageKeys) => void;
	clear: () => void;
	logout: () => void;
};