import React, { useMemo, useEffect } from "react";
import { Dialog, Icon } from "@/components";
import style from "./index.module.less";
import { AddNoonReportProps } from "./type";
import { FleetTextarea, Button } from "@/components";
import { NoonReportTypeForm } from "../../common";
import { NoonCospRF, NoonNrRF, NoonEospRF, NoonSpRF, NoonScRF } from "..";
import useIndex from "./hooks/useIndex";
import useCospIndex from "../noonCospRF/hooks/useIndex";
import useNrIndex from "../noonNrRF/hooks/useIndex";
import useEospIndex from "../noonEospRF/hooks/useIndex";
import useSpIndex from "../noonSpRF/hooks/useIndex";
import useScIndex from "../noonScRF/hooks/useIndex";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReportType } from "../../type";

const AddNoonReport: React.FC<AddNoonReportProps> = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const { reminder } = useReminder();

	const { hide, onClose, localData, isCopy, legData } = props;
	const { reportType, handleReportTypeChange, fillText, handleFillTextChange } =
		useIndex();

	const {
		cospData,
		handleCospMiCommit,
		handleCospPatiCommit,
		handleCospDiCommit,
		handleCospFuelCommit,
		handleCospNpCommit,
		handleClearCospData,
		handleCospNetDataInit,
		handleCospDataCommit
	} = useCospIndex({
		...props,
		onAddSuccess: () => {
			handleFillTextClear();
			props.onAddSuccess();
		}
	});

	const {
		nrData,
		handleNrMiCommit,
		handleNrPatiCommit,
		handleNrViCommit,
		handleNrFuelCommit,
		handleNrNpCommit,
		handleClearNrData,
		handleNrNetDataInit,
		handleNrDataCommit
	} = useNrIndex({
		...props,
		onAddSuccess: () => {
			handleFillTextClear();
			props.onAddSuccess();
		}
	});

	const {
		eospData,
		handleEospMiCommit,
		handleEospPatiCommit,
		handleEospViCommit,
		handleEospFuelCommit,
		handleEospNpCommit,
		handleClearEospData,
		handleEospNetDataInit,
		handleEospDataCommit
	} = useEospIndex({
		...props,
		onAddSuccess: () => {
			handleFillTextClear();
			props.onAddSuccess();
		}
	});

	const {
		spData,
		handleSpMiCommit,
		handleSpSiCommit,
		handleSpFuelCommit,
		handleClearSpData,
		handleSpNetDataInit,
		handleSpDataCommit
	} = useSpIndex({
		...props,
		onAddSuccess: () => {
			handleFillTextClear();
			props.onAddSuccess();
		}
	});

	const {
		scData,
		handleScMiCommit,
		handleScSciCommit,
		handleScFuelCommit,
		handleClearScData,
		handleScNetDataInit,
		handleScDataCommit
	} = useScIndex({
		...props,
		onAddSuccess: () => {
			handleFillTextClear();
			props.onAddSuccess();
		}
	});

	const renderReportForm = useMemo(() => {
		switch (reportType) {
			case "COSP":
				return (
					<NoonCospRF
						cospData={cospData}
						onCospMiCommit={handleCospMiCommit}
						onCospPatiCommit={handleCospPatiCommit}
						onCospDiCommit={handleCospDiCommit}
						onCospFuelCommit={handleCospFuelCommit}
						onCospNpCommit={handleCospNpCommit}
					/>
				);
			case "Noon Report":
				return (
					<NoonNrRF
						nrData={nrData}
						onNrMiCommit={handleNrMiCommit}
						onNrPatiCommit={handleNrPatiCommit}
						onNrViCommit={handleNrViCommit}
						onNrFuelCommit={handleNrFuelCommit}
						onNrNpCommit={handleNrNpCommit}
					/>
				);
			case "EOSP":
				return (
					<NoonEospRF
						curCospDateTime={props.curCospDateTime}
						eospData={eospData}
						onEospMiCommit={handleEospMiCommit}
						onEospPatiCommit={handleEospPatiCommit}
						onEospViCommit={handleEospViCommit}
						onEospFuelCommit={handleEospFuelCommit}
						onEospNpCommit={handleEospNpCommit}
					/>
				);
			case "Stoppage":
				return (
					<NoonSpRF
						spData={spData}
						onSpMiCommit={handleSpMiCommit}
						onSpSiCommit={handleSpSiCommit}
						onSpFuelCommit={handleSpFuelCommit}
					/>
				);
			case "Speed Change":
				return (
					<NoonScRF
						scData={scData}
						onScMiCommit={handleScMiCommit}
						onScSciCommit={handleScSciCommit}
						onScFuelCommit={handleScFuelCommit}
					/>
				);
		}
	}, [reportType, cospData, nrData, eospData, spData, scData]);

	const handleSaveClick = () => {
		switch (reportType) {
			case "COSP":
				handleCospDataCommit(cospData);
				break;
			case "Noon Report":
				handleNrDataCommit(nrData);
				break;
			case "EOSP":
				handleEospDataCommit(eospData);
				break;
			case "Stoppage":
				handleSpDataCommit(spData);
				break;
			case "Speed Change":
				handleScDataCommit(scData);
				break;
		}
	};

	const handleClearAllClick = () => {
		switch (reportType) {
			case "COSP":
				handleClearCospData();
				break;
			case "Noon Report":
				handleClearNrData();
				break;
			case "EOSP":
				handleClearEospData();
				break;
			case "Stoppage":
				handleClearSpData();
				break;
			case "Speed Change":
				handleClearScData();
				break;
		}
	};

	const handleReportDataInit = (type: ReportType, data: any) => {
		const realData = isCopy ? { ...data, id: null } : data;
		switch (type) {
			case "COSP":
				handleCospNetDataInit(realData);
				break;
			case "Noon Report":
				handleNrNetDataInit(realData);
				break;
			case "EOSP":
				handleEospNetDataInit(realData);
				break;
			case "Stoppage":
				handleSpNetDataInit(realData);
				break;
			case "Speed Change":
				handleScNetDataInit(realData);
				break;
		}
	};

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleGetLegReportSuccess = (res) => {
		handleReportTypeChange(res.data.type);
		handleReportDataInit(res.data.type, res.data);
	};
	const handleGetEospReportSuccess = (res) => {
		handleReportDataInit("EOSP", res.data);
	};
	const handleGetNrReportSuccess = (res) => {
		handleReportDataInit("Noon Report", res.data);
	};
	const handleGetCospReportSuccess = (res) => {
		handleReportDataInit("COSP", res.data);
	};
	const handleGetSpReportSuccess = (res) => {
		handleReportDataInit("Stoppage", res.data);
	};
	const handleGetScReportSuccess = (res) => {
		handleReportDataInit("Speed Change", res.data);
	};

	const handleGetVoyVesselSuccess = (res) => {
		const { vesselName, mmsi, imo, callSign } = res.data;
		handleCospMiCommit({ vesselName, mmsi, imo, callSign });
		handleNrMiCommit({ vesselName, mmsi, imo, callSign });
		handleEospMiCommit({ vesselName, mmsi, imo, callSign });
		handleSpMiCommit({ vesselName, mmsi, imo, callSign });
		handleScMiCommit({ vesselName, mmsi, imo, callSign });
	};

	const handleIdentifyClick = () => {
		onRequest(
			"getLegReportApi",
			{
				id: searchParams.get("id"),
				report: fillText
			},
			handleRequestFront,
			handleGetLegReportSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const handleGetVoyVessel = () => {
		onRequest(
			"getVoyVesselApi",
			{ id: legData.voyage.id },
			handleRequestFront,
			handleGetVoyVesselSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	const handleFillTextClear = () => {
		handleFillTextChange("");
	};

	useEffect(() => {
		if (!hide && legData) {
			// 请求船的数据
			handleGetVoyVessel();
			if (localData) {
				switch (localData.type) {
					case "EOSP":
						handleReportTypeChange("EOSP");
						onRequest(
							"getEospReportApi",
							{ id: localData.id },
							handleRequestFront,
							handleGetEospReportSuccess,
							handleRequestError,
							dispatch,
							navigate
						);
						break;
					case "NOON":
						handleReportTypeChange("Noon Report");
						onRequest(
							"getNrReportApi",
							{ id: localData.id },
							handleRequestFront,
							handleGetNrReportSuccess,
							handleRequestError,
							dispatch,
							navigate
						);
						break;
					case "COSP":
						handleReportTypeChange("COSP");
						onRequest(
							"getCospReportApi",
							{ id: localData.id },
							handleRequestFront,
							handleGetCospReportSuccess,
							handleRequestError,
							dispatch,
							navigate
						);
						break;
					case "STOP":
						handleReportTypeChange("Stoppage");
						onRequest(
							"getSpReportApi",
							{ id: localData.id },
							handleRequestFront,
							handleGetSpReportSuccess,
							handleRequestError,
							dispatch,
							navigate
						);
						break;
					case "RESUME":
						handleReportTypeChange("Stoppage");
						onRequest(
							"getSpReportApi",
							{ id: localData.id },
							handleRequestFront,
							handleGetSpReportSuccess,
							handleRequestError,
							dispatch,
							navigate
						);
						break;
					case "Speed Change":
						handleReportTypeChange("Speed Change");
						onRequest(
							"getScReportApi",
							{ id: localData.id },
							handleRequestFront,
							handleGetScReportSuccess,
							handleRequestError,
							dispatch,
							navigate
						);
						break;
				}
			}
		}
	}, [hide]);

	return (
		<Dialog
			mode="fixed"
			hide={hide}
			closeable={true}
			drawer={true}
			onClose={() => {
				onClose();
				handleClearAllClick();
				handleFillTextChange("");
			}}
		>
			<div className={style["add-noon-report"]}>
				<div className="report-header">
					<Icon
						type="icon-close2"
						onClick={() => {
							onClose();
							handleClearAllClick();
							handleFillTextChange("");
						}}
					/>
				</div>
				<div className="report-body">
					<div className="report-fill-area">
						<div className="fill-area-header">
							<p className="fill-area-title">Auto-Fill Report Data</p>
							<Button
								suffixIcon={<Icon type="icon-double_arrows_right" />}
								onClick={handleIdentifyClick}
								type="secondary"
							>
								Identify
							</Button>
						</div>
						<FleetTextarea
							allowClear
							placeholder=""
							value={fillText}
							onChange={(val) => handleFillTextChange(val)}
							className="fill-area"
						/>
					</div>
					<div className="report-edit-area">
						<div className="report-edit-header">
							<NoonReportTypeForm
								value={reportType}
								onChange={(val) => {
									handleReportTypeChange(val);
								}}
							/>
							<div className="report-edit-ctrl">
								<Button onClick={handleSaveClick}>Save</Button>
								<Button onClick={handleClearAllClick} type="secondary">
									Clear All
								</Button>
							</div>
						</div>
						<div className="report-edit-body">{renderReportForm}</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default AddNoonReport;
