import { onRequest } from "@/api";
import { useAppDispatch, useAppSelector, useReminder } from "@/hooks";
import { rememberIn } from "@/store/userSlice";
import { CommonChangeEvent } from "@/types/event";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const remember = useAppSelector((state) => state.UserStore.remember);
	const { username: userName, password } = useAppSelector(
		(state) => state.UserStore.userInfo
	);
	const loginRef = useRef<HTMLDivElement>(null);

	const [dataSource, setDataSource] = useState<{
		username: string;
		password: string;
		checked: boolean;
	}>({
		username: userName || "fltins",
		password: password || "76f!rryi$7xsTA",
		checked: remember
	});

	const [loading, setLoading] = useState(false);

	const change: CommonChangeEvent<typeof dataSource> = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }));
	};

	const loginFront = () => {
		setLoading(true);
	};

	const loginSuccess = ({ data, msg }) => {
		dispatch(
			rememberIn({
				remember: dataSource?.checked,
				username: dataSource?.username,
				password: dataSource?.checked ? dataSource?.password : ""
			})
		);
		reminder("success", msg);
		setLoading(false);
		navigate("/");
	};

	const loginError = (error) => {
		console.log("err", error);
		setLoading(false);
		// reminder("error", "登录失败")
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const login = () => {
		if (
			dataSource?.username?.length === 0 ||
			dataSource?.password?.length === 0
		) {
			reminder("error", "username or password is empty!");
			return;
		}
		onRequest(
			"userLoginApi",
			{
				loginName: dataSource?.username,
				pwd: CryptoJS.MD5(dataSource?.password)?.toString()
			},
			loginFront,
			loginSuccess,
			loginError
		);
	};

	const handleEnter: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
		if (e.key === "Enter") {
			login();
		}
	};

	return {
		loginRef,
		loading,
		dataSource,
		change,
		handleEnter,
		login
	};
};

export default useIndex;
