import { CommonOptionType } from "@/types/common";
import { LegDetailsMenuItemType } from "./type";

export const menuOptions: CommonOptionType<
	"Basic" | "Route" | "Plan" | "Noon" | "AIS" | "Per.",
	LegDetailsMenuItemType
>[] = [
	{ label: "Basic", value: "basic" },
	{ label: "Route", value: "route" },
	{ label: "Plan", value: "plan" },
	{ label: "AIS", value: "ais" },
	{ label: "Noon", value: "noon" },
	{ label: "Per.", value: "report" }
];
