import React from "react";
import style from "./index.module.less";
import useIndex from "./hook/useIndex";
import { Radio } from "antd";
import { Button, FormItem, FleetInput } from "@/components";
import { BasicProps } from "./type";

const Basic: React.FC<BasicProps> = ({ data, onReset, onChange }) => {
	const { saveBasicInfo } = useIndex(data, onReset);
	return (
		<div className={style["fleet-basic"]}>
			<FormItem
				containerClassname="basic-item"
				label="Nick Name"
				type="row"
				needVerity
			>
				<FleetInput
					className="basic-item-inner"
					value={data?.nickName}
					onChange={(v: string) => onChange("nickName", v)}
				/>
			</FormItem>
			<FormItem
				containerClassname="basic-item"
				label="Phone Number"
				type="row"
				needVerity
			>
				<FleetInput
					className="basic-item-inner"
					value={data?.phonenumber}
					onChange={(v: string) => onChange("phonenumber", v)}
				/>
			</FormItem>
			<FormItem
				containerClassname="basic-item"
				label="Email"
				type="row"
				needVerity
			>
				<FleetInput
					className="basic-item-inner"
					value={data?.email}
					onChange={(v: string) => onChange("email", v)}
				/>
			</FormItem>
			<FormItem
				containerClassname="basic-item"
				label="Sex"
				type="row"
				needVerity
			>
				<Radio.Group
					className="basic-item-inner"
					onChange={(e) => onChange("sex", e.target.value)}
					value={data?.sex}
				>
					<Radio value={"0"}>man</Radio>
					<Radio value={"1"}>female</Radio>
				</Radio.Group>
			</FormItem>
			<FormItem containerClassname="basic-item" label=" " type="row">
				<div className="basic-item-inner">
					<Button type="primary" onClick={saveBasicInfo}>
						Save
					</Button>
				</div>
			</FormItem>
		</div>
	);
};

export default Basic;
