import { useState } from "react";
import { CommonCommitEvent, CommonColumnCommitEvent } from "@/types/event";
import { NoonMainInfoFormType } from "../../../common/noonMainInfoForm/type";
import {
	FuelTableDataType,
	FuelTableDataItemType
} from "../../../common/noonFuelForm/type";
import { NoonNextPortFormType } from "../../../common/noonNextPortForm/type";
import {
	CospPatiFormType,
	CospDiFormType,
	CospNetDataType,
	NoonCospDataType,
	CospBasicDataType
} from "../type";
import {
	initialCospFuelData,
	initialCospMiData,
	initialCospDiData,
	initialCospNpData,
	initialCospPatiData,
	initialCospBasicData
} from "../source";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { AddNoonReportProps } from "../../addNoonReport/type";

const useIndex = (props: AddNoonReportProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	// Main Information data
	const [miData, setMiData] = useState<NoonMainInfoFormType>(initialCospMiData);

	// Position and Time Information data
	const [patiData, setPatiData] =
		useState<CospPatiFormType>(initialCospPatiData);

	// Departure Information data
	const [diData, setDiData] = useState<CospDiFormType>(initialCospDiData);

	// Fuel data
	const [fuelData, setFuelData] =
		useState<FuelTableDataType>(initialCospFuelData);

	// Next Port
	const [npData, setNpData] = useState<NoonNextPortFormType>(initialCospNpData);

	// basic data
	const [basicData, setBasicData] =
		useState<CospBasicDataType>(initialCospBasicData);

	const handleCospMiCommit: CommonCommitEvent<NoonMainInfoFormType> = (
		item
	) => {
		setMiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleCospPatiCommit: CommonCommitEvent<CospPatiFormType> = (item) => {
		setPatiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleCospDiCommit: CommonCommitEvent<CospDiFormType> = (item) => {
		setDiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleCospFuelCommit: CommonColumnCommitEvent<FuelTableDataItemType> = (
		index,
		item
	) => {
		setFuelData((prev) => {
			prev.splice(index, 1, item as FuelTableDataItemType);
			return [...prev];
		});
	};
	const handleCospNpCommit: CommonCommitEvent<NoonNextPortFormType> = (
		item
	) => {
		setNpData((prev) => {
			return { ...prev, ...item };
		});
	};

	const handleClearCospData = () => {
		// setMiData(initialCospMiData);
		setPatiData(initialCospPatiData);
		setDiData(initialCospDiData);
		setFuelData(initialCospFuelData);
		setNpData(initialCospNpData);
		setBasicData(initialCospBasicData);
	};

	// 接口数据赋值
	const handleCospNetDataInit = (data: CospNetDataType) => {
		// setMiData({
		// 	vesselName: data.shipName ?? "",
		// 	imo: data.imo ?? "",
		// 	mmsi: data.mmsi ?? "",
		// 	callSign: data.callSign ?? ""
		// });

		setPatiData({
			departurePort: data.depPortName ?? "",
			unlocode: data.depPortCode ?? "",
			cospDate: data.cospDateTime ?? "",
			latitude: data.positionCospLat ?? 0,
			longitude: data.positionCospLon ?? 0
		});

		setDiData({
			foreDraft: data.draftFwd ?? "",
			aftDraft: data.draftAft ?? "",
			midDraft: data.meanDraft ?? "",
			draftType: data.draftType,
			load:
				data.loadingCond !== "BALLAST" && data.loadingCond !== "LADEN"
					? ""
					: data.loadingCond,
			rollPeriod: data.rollPeriod ?? "",
			gm: data.gmHeight ?? "",
			cargo: data.cargoName ?? "",
			cargoWeight: data.cargoWeight ?? "",
			displacement: data.displacement ?? "",
			distanceToGo: data.distArrPilot ?? "",
			orderedSpeed: data.intdSpeed ?? "",
			course: data.course ?? "",
			setRpm: data.setRpm ?? ""
		});

		setFuelData((prev) => {
			return [
				{
					...prev[0],
					hfo: data.brobHfo ?? "",
					lsfo: data.brobLsfo ?? "",
					mdo: data.brobMdo ?? "",
					mgo: data.brobMgo ?? ""
				},
				{
					...prev[1],
					hfo: data.isoClassHfo ?? "",
					lsfo: data.isoClassLsfo ?? "",
					mdo: data.isoClassMdo ?? "",
					mgo: data.isoClassMgo ?? ""
				}
			];
		});

		setNpData({
			nextPort: data.arrPortName ?? "",
			unlocode: data.arrPortCode ?? "",
			eta: data.eta ?? ""
		});

		setBasicData({
			id: data.id
		});
	};

	// 提交数据
	const handleCospDataCommit = (data: NoonCospDataType) => {
		const commitData: Partial<CospNetDataType> = {
			id: data.basicData.id,
			legId: props.legId,
			type: "COSP",
			// heading: "W",
			imo: data.miData.imo,
			mmsi: data.miData.mmsi,
			callSign: data.miData.callSign,
			shipName: data.miData.vesselName,
			// reportDateTimeStr: "20230917/0900 UTC",
			// reportDateTime: data.patiData.cospDate,
			// positionStr: "26°53.000S 038°46.000E",
			// positionLon: data.patiData.longitude,
			// positionLat: data.patiData.latitude,
			depPortName: data.patiData.departurePort,
			depPortCode: data.patiData.unlocode,
			// positionCospStr: "23°32.007N 117°05.006E",
			positionCospLat: data.patiData.latitude,
			positionCospLon: data.patiData.longitude,
			// cospDateTimeStr: "20230910/0512 UTC",
			cospDateTime: data.patiData.cospDate,
			arrPortName: data.npData.nextPort,
			arrPortCode: data.npData.unlocode,
			// posArrPilotStr: "01°57.006N 118°07.007E",
			// posArrPilotLat: 1.9501,
			// posArrPilotLon: 118.11678,
			distArrPilot: data.diData.distanceToGo,
			// reqEtaStr: "20230914/1700 UTC",
			eta: data.npData.eta,
			intdSpeed: data.diData.orderedSpeed,
			loadingCond: data.diData.load,
			cargoName: data.diData.cargo,
			cargoWeight: data.diData.cargoWeight,
			draftFwd: data.diData.foreDraft,
			draftAft: data.diData.aftDraft,
			draftType: data.diData.draftType,
			rollPeriod: data.diData.rollPeriod,
			meanDraft: data.diData.midDraft,
			course: data.diData.course,
			setRpm: data.diData.setRpm,
			displacement: data.diData.displacement,
			gmHeight: data.diData.gm,
			brobHfo: data.fuelData[0].hfo,
			brobLsfo: data.fuelData[0].lsfo,
			brobMdo: data.fuelData[0].mdo,
			brobMgo: data.fuelData[0].mgo,
			isoClassHfo: data.fuelData[1].hfo,
			isoClassLsfo: data.fuelData[1].lsfo,
			isoClassMdo: data.fuelData[1].mdo,
			isoClassMgo: data.fuelData[1].mgo
		};

		onRequest(
			"saveLegCospReportApi",
			commitData,
			handleCospDataCommitFront,
			handleCospDataCommitSuccess,
			handleCospDataCommitError,
			dispatch,
			navigate
		);
	};
	const handleCospDataCommitFront = () => {};
	const handleCospDataCommitSuccess = () => {
		props.onAddSuccess();
		handleClearCospData();
	};
	const handleCospDataCommitError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	return {
		cospData: { miData, patiData, diData, fuelData, npData, basicData },
		handleCospMiCommit,
		handleCospPatiCommit,
		handleCospDiCommit,
		handleCospFuelCommit,
		handleCospNpCommit,
		handleClearCospData,
		handleCospNetDataInit,
		handleCospDataCommit
	};
};

export default useIndex;
