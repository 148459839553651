import React, { FC } from "react";
import style from "./index.module.less";
import { ReportTitleWithIconProps } from "./type";

const ReportTitleWithIcon: FC<ReportTitleWithIconProps> = (props) => {
	const { icon, title } = props;

	return (
		<div className={style["report-title"]}>
			{icon && <span className="icon">{icon}</span>}
			<span className="title">{title}</span>
		</div>
	);
};

export default ReportTitleWithIcon;
