export const isThreDimensionalArr = (
	arr: GeoJSON.Position[] | GeoJSON.Position[][]
) => {
	if (!Array.isArray(arr)) {
		return false;
	}

	if (!arr.every((item) => Array.isArray(item))) {
		return false;
	}

	if (!arr.every((subArr) => subArr.every((item) => Array.isArray(item)))) {
		return false;
	}

	return true;
};
