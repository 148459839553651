import React from "react";
import Select, { SelectProps as AntdSelectProps, } from "antd/es/select/index";
import classnames from "classnames";
import style from "./index.module.less";

export type SelectOption = {
  label: string;
  value: string | number;
}

type SelectProps = {
  value?: (string | number)[]|string|number;
  options?: SelectOption[];
  onChange?: (value: string | number) => void;
  defaultValue?: AntdSelectProps['value'];
  fieldNames?: AntdSelectProps["fieldNames"];
  disabled?: boolean;
  mode?: "multiple" | "tags";
  className?: string;
  popupClassName?:string;
  placeholder?: string;
  dropdownRender?: (menu?: React.ReactElement) => React.ReactElement;
  popupMatchSelectWidth?: boolean;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  open?: boolean;
  searchValue?: string;
  onSearch?: (value: string) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  onInputKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  tagRender?: (props) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  showSearch?: boolean;
  showCount?: number;
  onSelect?: (value:string) => void;
  loading?:boolean;
  allowClear?:boolean;
  onClear?:() => void;
  autoFocus?:boolean;
  suffixIcon?:React.ReactNode;
  optionRender?:AntdSelectProps["optionRender"];
}

const FleetSelect: React.FC<SelectProps> = ({
  value,
  options,
  onChange,
  className,
  disabled,
  mode,
  defaultValue,
  placeholder,
  dropdownRender,
  popupMatchSelectWidth = true,
  onBlur,
  popupClassName,
  open,
  onFocus,
  searchValue,
  loading,
  onSearch,
  onDropdownVisibleChange,
  onInputKeyDown,
  tagRender,
  fieldNames,
  showCount = 1,
  showSearch,
  allowClear,
  autoFocus,
  suffixIcon,
  onClear,
  onSelect,
  optionRender
}) => {
  return <div className={classnames(style["fleet-select"], className)}>
    <Select
      showSearch={showSearch}
      value={value}
      fieldNames={fieldNames}
      options={options}
      suffixIcon={suffixIcon}
      onChange={onChange}
      onInputKeyDown={onInputKeyDown}
      autoFocus={autoFocus}
      optionRender={optionRender}
      disabled={disabled}
      onBlur={onBlur}
      loading={loading}
      allowClear={allowClear}
      tagRender={tagRender}
      mode={mode}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onFocus={onFocus}
      onClear={onClear}
      open={open}
      searchValue={searchValue}
      onSearch={onSearch}
      popupMatchSelectWidth={popupMatchSelectWidth}
      defaultValue={defaultValue}
      popupClassName={classnames(style["fleet-select-popup"],popupClassName)}
      className="select"
      placeholder={placeholder}
      dropdownRender={dropdownRender}
      maxTagCount={showCount}
      onSelect={onSelect}
      maxTagPlaceholder={(omittedValues) => {
        return `+${omittedValues.length}`
      }}
    />
  </div>
}

export default FleetSelect