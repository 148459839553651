import useCommonIndex from "../../../hooks/useIndex";
import { CommonPortItemType } from "../type";
import { useMemo, useState } from "react";
import debounce from "lodash/debounce";

const useIndex = (
	activeItems: Partial<CommonPortItemType> | Partial<CommonPortItemType>[],
	showKey: keyof CommonPortItemType,
	onFinish: (newItem: CommonPortItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonPortItemType = "id",
	disabled?: boolean
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		setOpen,
		setList,
		focusPopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonPortItemType, keyof CommonPortItemType>(
		activeItems,
		showKey,
		onFinish,
		"getPortsApi",
		primaryKey,
		disabled
	);

	const [status, setStatus] = useState<"num" | "str">("str");

	const closePopup = (open: boolean) => {
		if (!open) {
			// setSearchVal("");
			page.current.pageNum = 1;
		}
		setOpen(open);
	};

	const handleSearch = useMemo(() => {
		if (status === "num") {
			return (val: string) => {
				// setSearchVal(val)
			};
		}

		const loadOptions = (val: string) => {
			page.current.pageNum = 1;
			setList([]);
			focusPopup(false, val);
		};
		return debounce(loadOptions, 800);
	}, [focusPopup, status]);

	const handleEnter = (e) => {
		if (/^\d{1,}$/.test(e.key)) {
			setStatus("num");
		} else {
			setStatus("str");
		}
	};
	const handleLatitudeClose = (portCode: string) => {
		console.log("portCode", portCode);
		handleItemSelect?.({
			portCode: "(" + portCode + ")",
			portName: portCode
		} as any);
		setOpen(false);
	};

	return {
		status,
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		handleLatitudeClose,
		tagRender,
		handleEnter
	};
};

export default useIndex;
