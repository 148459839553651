import React, { FC } from "react";
import style from "./index.module.less";
import { PlanTitleProps } from "./type";

const PlanTitle: FC<PlanTitleProps> = (props) => {
	const { showDot, title, iconChildren } = props;

	return (
		<div className={style["plan-title"]}>
			{iconChildren}
			<div className="plan-title-inner">
				{showDot && <span className="plan-title-dot" />}
				<p>{title}</p>
			</div>
		</div>
	);
};

export default PlanTitle;
