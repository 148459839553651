import React, { FC } from "react";
import styles from "./index.module.less";
import { Popover, Toast, Icon, Loading, NoData } from "@/components";
import useIndex from "./hooks/useIndex";
import { analysisListColumns } from "./source";
import { HeaderIconsType, SimulationTableProps } from "./type";
import { PreviewDialog, SimulationDialog } from "../../../index";

const SimulationTable: FC<SimulationTableProps> = ({
	planInfo,
	simulationTitle,
	simulationSelecedInfo,
	handleCloseSimulationList,
	setSimulationSelecedInfo,
	onSelectForecast,
	onOpenForecast,
	onCloseForecast,
	routeId
}) => {
	const {
		simulationList,
		simulationDialogVisible,
		loading,
		previewDialogVisible,
		handleOpenSimulationDialog,
		handleCloseSimulationDialog,
		handleSimulationAgain,
		initSimulationList,
		handleOpenPreviewDialog,
		handleClosePreviewDialog,
		handleSelectSimulationItem,
		handleDelete,
		handleShowForecast
	} = useIndex(
		planInfo,
		simulationSelecedInfo,
		setSimulationSelecedInfo,
		onSelectForecast,
		onOpenForecast,
		onCloseForecast
	);

	const headerIcons: HeaderIconsType = [
		{
			type: "icon-add",
			className: "canClick",
			onClick: handleOpenSimulationDialog
		},
		{
			type: "icon-new",
			className: !simulationSelecedInfo?.length ? "voy-disabled" : "canClick",
			onClick: handleOpenPreviewDialog
		},
		{
			type: "icon-refresh",
			className: "canClick",
			onClick: initSimulationList
		},
		{
			type: "icon-cancel",
			className: "canClick",
			onClick: handleCloseSimulationList
		}
	];
	return (
		<div className={styles.simalation}>
			<Loading loading={loading} />

			<div className="customTable">
				<div className="customTable-title">
					<div className="customTable-title-left">Speed Analysis</div>
					<div className="customTable-title-right">
						{headerIcons.map((icon, index) => (
							<Icon
								key={index}
								type={icon.type}
								className={icon.className}
								onClick={icon.onClick}
							/>
						))}
					</div>
				</div>
				<div className="customTable-content fleet-nonScrollBar">
					<div className="customTable-header">
						{analysisListColumns.map((item, index) => (
							<div
								key={index}
								style={{
									maxWidth: item.width ?? 110,
									minWidth: item.width ?? 110
								}}
							>
								{item.title}
							</div>
						))}
						<div style={{ minWidth: "80px", maxWidth: "80px" }}></div>
						<div style={{ minWidth: "80px", maxWidth: "80px" }}></div>
					</div>
					<div className="customTable-body">
						{!simulationList?.list?.length && <NoData className="nodata" />}
						{simulationList?.list?.map((detailItem, detailIndex) => (
							<div
								className="customTable-body-row"
								key={detailItem.id}
								onClick={() => handleShowForecast(detailItem)}
							>
								{analysisListColumns.map((item, index) => (
									<div
										style={{
											maxWidth: item.width ?? 110,
											minWidth: item.width ?? 110
										}}
										key={index}
									>
										{item.render
											? item.render(
													detailItem[item.dataIndex],
													detailItem,
													detailIndex,
													{
														handleSelect: (checked, item) =>
															handleSelectSimulationItem(checked, item),
														simulationSelecedInfo
													}
												)
											: detailItem[item.dataIndex]}
									</div>
								))}
								<div style={{ minWidth: "80px", maxWidth: "80px" }}></div>
								<div
									className="row-operations"
									style={{ minWidth: "80px", maxWidth: "80px" }}
								>
									<Popover
										render={(setIsOpen) => (
											<Toast
												label="Are you sure want to reanalyze?"
												onCancel={() => setIsOpen(false)}
												onConfirm={() => {
													handleSimulationAgain(detailItem.id);
													setIsOpen(false);
												}}
											/>
										)}
									>
										<Icon type="icon-analyze" className="canClick" />
									</Popover>
									<Popover
										render={(setIsOpen) => (
											<Toast
												label="Are you sure want to delete?"
												onCancel={() => setIsOpen(false)}
												onConfirm={() => {
													handleDelete(detailItem.id);
													setIsOpen(false);
												}}
											/>
										)}
									>
										<Icon type="icon-delete" className="canClick" />
									</Popover>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			{simulationDialogVisible && (
				<SimulationDialog
					routeId={routeId}
					planId={planInfo.id}
					simulationTitle={simulationTitle}
					visible={simulationDialogVisible}
					onClose={handleCloseSimulationDialog}
					onFinish={() => initSimulationList()}
				/>
			)}
			{previewDialogVisible && (
				<PreviewDialog
					visible={previewDialogVisible}
					onClose={handleClosePreviewDialog}
					simulationSelecedInfo={simulationSelecedInfo}
				/>
			)}
		</div>
	);
};

export default SimulationTable;
