import { CommonOptionType, ExtendsKeys } from "@/types/common";
import {
	RouteCommonToolbarControllItemType,
	RouteCommonToolbarMeteoMap
} from "./type";
import { RouteCommonToolbarMap } from "./components";
import { IconProps } from "@/components/Icon/interface";

type MeteorologicalKeys = keyof typeof RouteCommonToolbarMeteoMap;

export const meteorologicalOptions: CommonOptionType<
	MeteorologicalKeys,
	MeteorologicalKeys
>[] = [
	{
		label: "Wind",
		value: "Wind"
	},
	{
		label: "Wave",
		value: "Wave"
	},
	{
		label: "Swell",
		value: "Swell"
	},
	{
		label: "Current",
		value: "Current"
	},
	{
		label: "Typhoon",
		value: "Typhoon"
	},
	{
		label: "Pressure",
		value: "Pressure"
	}
];

export const controllLayerMap: Record<
	ExtendsKeys<
		keyof typeof RouteCommonToolbarMap,
		"Ports" | "Loadline" | "Eca" | "War" | "ENC"
	>,
	| ["ports"]
	| ["load-line-point", "load-line-polygon"]
	| ["eca-zones-point", "eca-zones-polygon"]
	| ["war-zones-point", "war-zones-polygon"]
	| ["enc"]
> = {
	Ports: ["ports"],
	Loadline: ["load-line-point", "load-line-polygon"],
	Eca: ["eca-zones-point", "eca-zones-polygon"],
	War: ["war-zones-point", "war-zones-polygon"],
	ENC: ["enc"]
};

// export const controllOptions: RouteCommonToolbarControllItemType[] = [
// 	{ label: "Ports", value: "Ports", layerIds: ["ports"] },
// 	{
// 		label: "Load Line",
// 		value: "Loadline",
// 		layerIds: ["load-line-point", "load-line-polygon"]
// 	},
// 	{
// 		label: "ECA",
// 		value: "Eca",
// 		layerIds: ["eca-zones-point", "eca-zones-polygon"]
// 	},
// 	{
// 		label: "Zones",
// 		value: "War",
// 		layerIds: ["war-zones-point", "war-zones-polygon"]
// 	}
// ];

export const rangingControllOptions: (CommonOptionType<
	"Rhumb Line" | "Great Circle",
	"straight" | "circle"
> & {
	icon: IconProps["type"];
})[] = [
	{ label: "Rhumb Line", value: "straight", icon: "icon-line" },
	{ label: "Great Circle", value: "circle", icon: "icon-circle1" }
];

export const legendVisibleOptions: (CommonOptionType<
	"Legend" | "Point",
	"legend" | "point"
> & { icon: IconProps["type"] })[] = [
	{ label: "Legend", value: "legend", icon: "icon-legend_hide" },
	{ label: "Point", value: "point", icon: "icon-point_local" }
];
