import React from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { Outlet } from "react-router-dom";
const Leg: React.FC = () => {
	return (
		<div className={classNames("fleet-layout-common", style["fleet-leg"])}>
			<Outlet />
		</div>
	);
};

export default Leg;
