import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import debounce from "lodash/debounce";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { Toast, Popover } from "@/components";

const useIndex = (voyageId) => {
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [legAddDialogVisible, setLegAddDialogVisible] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });
	const cacheSource = useRef({});
	const dataSource = useMemo(() => {
		return cacheSource.current[pager.pageNum];
	}, [pager.pageNum, pager.total]);

	const handleCloseLegAdd = () => {
		setLegAddDialogVisible(false);
	};
	const handleOpenLegAdd = () => {
		setLegAddDialogVisible(true);
	};
	const onSelectChange = (newSelectedRowKeys: number[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const onPageChange = (page: number, size: number) => {
		onRequest(
			"getLegPageApi",
			{
				pageSize: size,
				pageNum: page
			},
			getLegPageFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.records;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getLegPageFailed,
			dispatch,
			navigate
		);
	};

	const handleRowDblClick = (item) => {
		const query = `?id=${item?.id}&legType=${item?.legType}&voyageId=${item?.voyageId}&imo=${item?.imo}`;
		window.open("/layout/legDetails" + query);
	};

	const getLegPageFront = () => {
		setLoading(true);
	};
	const getLegPageSuccess = (response) => {
		let total = 0;
		if (!Array.isArray(response?.data)) {
			cacheSource.current[1] = response?.data?.records;
			total = response?.data?.total;
		} else {
			cacheSource.current = {};
		}
		setPager((prev) => ({ ...prev, total, pageNum: pager.pageNum }));
		setLoading(false);
	};
	const getLegPageFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getLegPage = () => {
		onRequest(
			"getLegPageApi",
			{
				voyageId: voyageId,
				pageSize: pager.pageSize,
				pageNum: pager.pageNum
			},
			getLegPageFront,
			getLegPageSuccess,
			getLegPageFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		getLegPage();
	}, []);

	return {
		loading,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		handleRowDblClick,
		handleCloseLegAdd,
		handleOpenLegAdd,
		legAddDialogVisible,
		getLegPage
	};
};
export default useIndex;
