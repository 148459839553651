import React from "react";
import { Icon } from "@/components";
import { DataType } from "@/components/Table";
import { ColumnsType } from "antd/es/table";
import { timestampToUtcMdhm } from "@/tools/date";

export const cloumns: ColumnsType<DataType> = [
	{
		title: " ",
		dataIndex: "status",
		key: "status",
		width: 30,
		render(value, record, index) {
			return (
				<div>
					{value === 0 ? (
						<Icon type="icon-inprogress" />
					) : value === 1 ? (
						<Icon type="icon-complete" />
					) : (
						"fail"
					)}
				</div>
			);
		}
	},
	{
		title: "Type",
		dataIndex: "reportType",
		key: "reportType",
		width: 60
	},
	{
		title: "Create Time",
		dataIndex: "createTime",
		width: 150,
		key: "createTime",
		render(value, record, index) {
			return <div>{timestampToUtcMdhm(value)}</div>;
		}
	}
];
