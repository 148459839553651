import React, { FC, useState } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { Filter } from "./components";
import useIndex from "./hooks/useIndex";
import { Loading, Table } from "@/components";
import { columns } from "./source";
// import { VoyInfoManager } from "../../components/voyInfoManager";
import { AddNewVoy } from "../../components";

import { ColumnsType } from "antd/es/table";
import { DataType } from "../details/components/leg";

const VoyList: FC<{}> = (props) => {
	const {
		loading,
		filter,
		handleFilterCommit,
		handleRowDblClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		onSearch,
		// opereteColumns,
		isAddHide,
		handleAddClick,
		handleCloseAdd,
		handleAddSuccess
	} = useIndex();

	return (
		<div className={classnames("voy-common", style["voy-list"])}>
			<AddNewVoy
				hide={isAddHide}
				onClose={handleCloseAdd}
				onAddSuccess={handleAddSuccess}
			/>
			<Loading loading={loading} />
			<Filter
				item={filter}
				onCommit={handleFilterCommit}
				onAdd={handleAddClick}
				onSearch={onSearch}
			/>
			<Table
				className="voy-list-container"
				columns={columns as ColumnsType<DataType>}
				id="id"
				dataSource={dataSource}
				// rowSelection={{
				// 	selectedRowKeys,
				// 	onChange: onSelectChange
				// }}
				onRow={(item) => {
					return {
						onDoubleClick: () => handleRowDblClick("edit", item)
					};
				}}
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
			/>
		</div>
	);
};

export default VoyList;
