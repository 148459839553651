import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import {
	CommonIdType,
	DelAisApiParamsType,
	getImoParamsType
} from "@/api/leg/type";

const useIndex = (handleSuccess: () => void) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const voyageId = queryParams.get("voyageId");
	const handleDelete = (id: string) => {
		if (!id) {
			reminder("error", "Cannot delete current ais");
			return;
		}
		const params: DelAisApiParamsType = {
			aisId: id,
			voyageId: voyageId,
			operationType: "DELETE"
		};
		delAis(params);
	};
	// API
	// API - delAis
	const delAisSuccess = () => {
		handleSuccess?.();
	};
	const delAisFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const delAis = (params: DelAisApiParamsType) => {
		onRequest(
			"delAisApi",
			params,
			() => {},
			delAisSuccess,
			delAisFailed,
			dispatch,
			navigate
		);
	};

	return { handleDelete };
};

export default useIndex;
