import { useState } from "react";
import { CommonCommitEvent, CommonColumnCommitEvent } from "@/types/event";
import { NoonMainInfoFormType } from "../../../common/noonMainInfoForm/type";
import {
	FuelTableDataType,
	FuelTableDataItemType
} from "../../../common/noonFuelForm/type";
import { NoonNextPortFormType } from "../../../common/noonNextPortForm/type";
import {
	EospPatiFormType,
	EospViFormType,
	EospNetDataType,
	NoonEospDataType,
	EospBasicDataType
} from "../type";
import {
	initialEospFuelData,
	initialEospMiData,
	initialEospNpData,
	initialEospPatiData,
	initialEospViData,
	initialEospBasicData
} from "../source";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { AddNoonReportProps } from "../../addNoonReport/type";
import { getHoursByTimeStampRange } from "@/tools/date";
import { formatNumber } from "@/tools/amount";

const useIndex = (props: AddNoonReportProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	// Main Information data
	const [miData, setMiData] = useState<NoonMainInfoFormType>(initialEospMiData);

	// Position and Time Information data
	const [patiData, setPatiData] =
		useState<EospPatiFormType>(initialEospPatiData);

	// Voyage information data
	const [viData, setViData] = useState<EospViFormType>(initialEospViData);

	// Fuel data
	const [fuelData, setFuelData] =
		useState<FuelTableDataType>(initialEospFuelData);

	// Next Port
	const [npData, setNpData] = useState<NoonNextPortFormType>(initialEospNpData);

	// basic data
	const [basicData, setBasicData] =
		useState<EospBasicDataType>(initialEospBasicData);

	const handleEospMiCommit: CommonCommitEvent<NoonMainInfoFormType> = (
		item
	) => {
		setMiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleEospPatiCommit: CommonCommitEvent<EospPatiFormType> = (item) => {
		setPatiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleEospViCommit: CommonCommitEvent<EospViFormType> = (item) => {
		setViData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleEospFuelCommit: CommonColumnCommitEvent<FuelTableDataItemType> = (
		index,
		item
	) => {
		setFuelData((prev) => {
			prev.splice(index, 1, item as FuelTableDataItemType);
			return [...prev];
		});
	};
	const handleEospNpCommit: CommonCommitEvent<NoonNextPortFormType> = (
		item
	) => {
		setNpData((prev) => {
			return { ...prev, ...item };
		});
	};

	const handleClearEospData = () => {
		// setMiData(initialEospMiData);
		setPatiData(initialEospPatiData);
		setViData(initialEospViData);
		setFuelData(initialEospFuelData);
		setNpData(initialEospNpData);
		setBasicData(initialEospBasicData);
	};

	// 接口数据赋值
	const handleEospNetDataInit = (data: EospNetDataType) => {
		// setMiData({
		// 	vesselName: data.shipName ?? "",
		// 	imo: data.imo ?? "",
		// 	mmsi: data.mmsi ?? "",
		// 	callSign: data.callSign ?? ""
		// });

		setPatiData({
			arrivalPort: data.arrPortName ?? "",
			unlocode: data.arrPortCode ?? "",
			eospDate: data.eospDateTime ?? "",
			latitude: data.positionEospLat ?? 0,
			longitude: data.positionEospLon ?? 0
		});

		setViData((prev) => {
			return {
				foreDraft: data.draftFwd ?? "",
				aftDraft: data.draftAft ?? "",
				midDraft: data.meanDraft ?? "",
				slipSlr: data.slip ?? "",
				timeSlr: data.steamTimeSlr ?? "",
				distanceSlr: data.distSlr ?? "",
				avgSogSlr: data.avgSpeed ?? "",
				// timeSinceCosp: getHoursByTimeStampRange(
				// 	props.curCospDateTime,
				// 	Number(data.eospDateTime)
				// ),
				timeSinceCosp: data.timeSince ?? "",
				distanceSinceCosp: data.distTotal ?? "",
				avgRpmSlr: data.avgRpm ?? "",
				orderedSpeed: data.orderedSpeed ?? "",
				mcr: data.meLoadSlr ?? "",
				timeInEca: data.ecaTimeTotal ?? "",
				distanceInEca: data.ecaDistTotal ?? "",
				windBeaufort: data.windBf ?? "",
				windDirection: data.windDir,
				seasHeight: data.sea ?? "",
				swellHeight: data.swellHeight ?? "",
				swellDirection: data.swellDir
			};
		});

		setFuelData((prev) => {
			return [
				{
					...prev[0],
					hfo: data.brobHfo ?? "",
					lsfo: data.brobLsfo ?? "",
					mdo: data.brobMdo ?? "",
					mgo: data.brobMgo ?? ""
				},
				{
					...prev[1],
					hfo: data.meHfoSlr ?? "",
					lsfo: data.meLsfoSlr ?? "",
					mdo: data.meMdoSlr ?? "",
					mgo: data.meMgoSlr ?? ""
				},
				{
					...prev[2],
					hfo: data.auxHfoSlr ?? "",
					lsfo: data.auxLsfoSlr ?? "",
					mdo: data.auxMdoSlr ?? "",
					mgo: data.auxMgoSlr ?? ""
				},
				{
					...prev[3],
					hfo: data.otherHfoSlr ?? "",
					lsfo: data.otherLsfoSlr ?? "",
					mdo: data.otherMdoSlr ?? "",
					mgo: data.otherMgoSlr ?? ""
				},
				{
					...prev[4],
					hfo: formatNumber(
						Number(data.meHfoSlr) +
							Number(data.auxHfoSlr) +
							Number(data.otherHfoSlr),
						3
					),
					lsfo: formatNumber(
						Number(data.meLsfoSlr) +
							Number(data.auxLsfoSlr) +
							Number(data.otherLsfoSlr),
						3
					),
					mdo: formatNumber(
						Number(data.meMdoSlr) +
							Number(data.auxMdoSlr) +
							Number(data.otherMdoSlr),
						3
					),
					mgo: formatNumber(
						Number(data.meMgoSlr) +
							Number(data.auxMgoSlr) +
							Number(data.otherMgoSlr),
						3
					)
				},
				{
					...prev[5],
					hfo: data.meConsHfoCosp ?? "",
					lsfo: data.meConsLsfoCosp ?? "",
					mdo: data.meConsMdoCosp ?? "",
					mgo: data.meConsMgoCosp ?? ""
				},
				{
					...prev[6],
					hfo: data.aeConsHfoCosp ?? "",
					lsfo: data.aeConsLsfoCosp ?? "",
					mdo: data.aeConsMdoCosp ?? "",
					mgo: data.aeConsMgoCosp ?? ""
				},
				{
					...prev[7],
					hfo: data.otherConsHfoCosp ?? "",
					lsfo: data.otherConsLsfoCosp ?? "",
					mdo: data.otherConsMdoCosp ?? "",
					mgo: data.otherConsMgoCosp ?? ""
				},
				{
					...prev[8],
					hfo: formatNumber(
						Number(data.meConsHfoCosp) +
							Number(data.aeConsHfoCosp) +
							Number(data.otherConsHfoCosp),
						3
					),
					lsfo: formatNumber(
						Number(data.meConsLsfoCosp) +
							Number(data.aeConsLsfoCosp) +
							Number(data.otherConsLsfoCosp),
						3
					),
					mdo: formatNumber(
						Number(data.meConsMdoCosp) +
							Number(data.aeConsMdoCosp) +
							Number(data.otherConsMdoCosp),
						3
					),
					mgo: formatNumber(
						Number(data.meConsMgoCosp) +
							Number(data.aeConsMgoCosp) +
							Number(data.otherConsMgoCosp),
						3
					)
				}
			];
		});

		setNpData({
			nextPort: data.nextPortName ?? "",
			unlocode: data.nextPortCode ?? "",
			eta: data.eta ?? ""
		});

		setBasicData({
			id: data.id
		});
	};

	// 提交数据
	const handleEospDataCommit = (data: NoonEospDataType) => {
		const commitData: Partial<EospNetDataType> = {
			id: data.basicData.id,
			legId: props.legId,
			type: "EOSP",
			// heading: "W",
			mmsi: data.miData.mmsi,
			shipName: data.miData.vesselName,
			imo: data.miData.imo,
			callSign: data.miData.callSign,
			// reportDateTimeStr: "20230917/0900 UTC",
			// reportDateTime: 1694941200000,
			// positionStr: "26°53.000S 038°46.000E",
			// positionLon: 38.76667,
			// positionLat: -26.88333,
			// course: 267.0,
			arrPortName: data.patiData.arrivalPort,
			arrPortCode: data.patiData.unlocode,
			// eospDateTimeStr: "20230914/1748 UTC",
			eospDateTime: data.patiData.eospDate,
			// positionEospStr: "01°59.500N 118°23.300E",
			positionEospLat: data.patiData.latitude,
			positionEospLon: data.patiData.longitude,
			avgSpeed: data.viData.avgSogSlr,
			// avgWaterSpeed: 1071.0,
			steamTimeSlr: data.viData.timeSlr,
			distSlr: data.viData.distanceSlr,
			distTotal: data.viData.distanceSinceCosp,
			// ecaDistSlr: ,
			ecaDistTotal: data.viData.distanceInEca,
			ecaTimeTotal: data.viData.timeInEca,
			brobHfo: data.fuelData[0].hfo,
			brobLsfo: data.fuelData[0].lsfo,
			brobMdo: data.fuelData[0].mdo,
			brobMgo: data.fuelData[0].mgo,
			meHfoSlr: data.fuelData[1].hfo,
			meLsfoSlr: data.fuelData[1].lsfo,
			meMdoSlr: data.fuelData[1].mdo,
			meMgoSlr: data.fuelData[1].mgo,
			auxHfoSlr: data.fuelData[2].hfo,
			auxLsfoSlr: data.fuelData[2].lsfo,
			auxMdoSlr: data.fuelData[2].mdo,
			auxMgoSlr: data.fuelData[2].mgo,
			otherHfoSlr: data.fuelData[3].hfo,
			otherLsfoSlr: data.fuelData[3].lsfo,
			otherMdoSlr: data.fuelData[3].mdo,
			otherMgoSlr: data.fuelData[3].mgo,
			totalHfoSlr: data.fuelData[4].hfo,
			totalLsfoSlr: data.fuelData[4].lsfo,
			totalMdoSlr: data.fuelData[4].mdo,
			totalMgoSlr: data.fuelData[4].mgo,
			meConsHfoCosp: data.fuelData[5].hfo,
			meConsLsfoCosp: data.fuelData[5].lsfo,
			meConsMdoCosp: data.fuelData[5].mdo,
			meConsMgoCosp: data.fuelData[5].mgo,
			aeConsHfoCosp: data.fuelData[6].hfo,
			aeConsLsfoCosp: data.fuelData[6].lsfo,
			aeConsMdoCosp: data.fuelData[6].mdo,
			aeConsMgoCosp: data.fuelData[6].mgo,
			otherConsHfoCosp: data.fuelData[7].hfo,
			otherConsLsfoCosp: data.fuelData[7].lsfo,
			otherConsMdoCosp: data.fuelData[7].mdo,
			otherConsMgoCosp: data.fuelData[7].mgo,
			// totalConsCosp: null,
			draftFwd: data.viData.foreDraft,
			draftAft: data.viData.aftDraft,
			meanDraft: data.viData.midDraft,
			avgRpm: data.viData.avgRpmSlr,
			// mePower: 4990.0,
			meLoadSlr: data.viData.mcr,
			slip: data.viData.slipSlr,
			timeSince: data.viData.timeSinceCosp,
			orderedSpeed: data.viData.orderedSpeed,
			windBf: data.viData.windBeaufort,
			windDir: data.viData.windDirection,
			sea: data.viData.seasHeight,
			swellHeight: data.viData.swellHeight,
			swellDir: data.viData.swellDirection,
			nextPortName: data.npData.nextPort,
			nextPortCode: data.npData.unlocode,
			eta: data.npData.eta
		};

		onRequest(
			"saveLegEospReportApi",
			commitData,
			handleEospDataCommitFront,
			handleEospDataCommitSuccess,
			handleEospDataCommitError,
			dispatch,
			navigate
		);
	};

	const handleEospDataCommitFront = () => {};
	const handleEospDataCommitSuccess = () => {
		props.onAddSuccess();
		handleClearEospData();
	};
	const handleEospDataCommitError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	return {
		eospData: { miData, patiData, viData, fuelData, npData, basicData },
		handleEospMiCommit,
		handleEospPatiCommit,
		handleEospViCommit,
		handleEospFuelCommit,
		handleEospNpCommit,
		handleClearEospData,
		handleEospNetDataInit,
		handleEospDataCommit
	};
};

export default useIndex;
