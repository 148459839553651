import React from "react";
import styles from "./index.module.less";
import { FleetDatePicker, Icon } from "@/components";
import useIndex from "./hooks/useIndex";
import dayjs from "dayjs";
import { TimeItemProps } from "./type";
import { FleetUtcTimePicker } from "@/pages/leg/business/details/components";

const TimeItem: React.FC<TimeItemProps> = ({ value, type, index, onSave }) => {
	const { timeValue, setTimeValue, isEdit, setIsEdit } = useIndex(value);

	return (
		<div
			className={styles["timeItem"]}
			onDoubleClick={() => {
				if (type === "ATD" || type === "ATA") return;
				setIsEdit(true);
			}}
		>
			<div className="timeType">{type}:</div>
			<div className="time">
				{isEdit ? (
					<span>
						<FleetUtcTimePicker
							value={timeValue ?? null}
							onChange={(value) => {
								setTimeValue(value);
							}}
						/>

						<Icon
							className="icon-canClick"
							type="icon-right"
							onClick={() => {
								onSave(timeValue);
								setIsEdit(false);
							}}
						/>

						<Icon
							type="icon-close2"
							className="icon-canClick"
							onClick={() => {
								setTimeValue(value);
								setIsEdit(false);
							}}
						/>
					</span>
				) : !!timeValue ? (
					dayjs(timeValue)?.utc()?.format("YYYY-MM-DD HH:mm")
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default TimeItem;
