import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";
import { AddRoutePlanByFileType, DownloadPlanType } from "@/api/leg/type";
import { DownloadType } from "../type";
const useIndex = (closeDialog, finishCB, planId) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	// DidMount

	// state

	// hanlde
	const hanleCloseDialog = (closeDialog) => {
		closeDialog && closeDialog();
	};

	// State
	const [downloadType, setDownloadType] = useState<DownloadType>("A");

	const handleChangeTypeRadio = (type: DownloadType) => {
		setDownloadType(type);
	};
	const onConfirm = () => {
		downloadPlan({ planId, type: downloadType });
		hanleCloseDialog(closeDialog);
	};

	//API
	// API - addRoutePlan
	const addRoutePlanFront = () => {};
	const addRoutePlanSuccess = () => {
		finishCB && finishCB();
		closeDialog && closeDialog();
	};
	const addRoutePlanFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const addRoutePlan = (params: { id: string }) => {
		onRequest(
			"addRoutePlanApi",
			params,
			addRoutePlanFront,
			addRoutePlanSuccess,
			addRoutePlanFailed,
			dispatch,
			navigate
		);
	};

	// API - downloadPlanApi
	const downloadPlanFront = () => {
		setLoading(true);
	};
	const downloadPlanSuccess = () => {
		// reminder("success", "下载成功");
		setLoading(false);
	};
	const downloadPlanFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const downloadPlan = (params: DownloadPlanType) => {
		onRequest(
			"downloadPlanApi",
			params,
			downloadPlanFront,
			downloadPlanSuccess,
			downloadPlanFailed,
			dispatch,
			navigate
		);
	};

	return {
		loading,
		downloadType,
		hanleCloseDialog,
		onConfirm,
		handleChangeTypeRadio
	};
};

export default useIndex;
