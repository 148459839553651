import { useState } from "react";
import { CommonCommitEvent, CommonColumnCommitEvent } from "@/types/event";
import { NoonMainInfoFormType } from "../../../common/noonMainInfoForm/type";
import {
	FuelTableDataType,
	FuelTableDataItemType
} from "../../../common/noonFuelForm/type";
import {
	ScSciFormType,
	NoonScDataType,
	ScNetDataType,
	ScBasicDataType
} from "../type";
import {
	initialScFuelData,
	initialScMiData,
	initialScSciData,
	initialScBasicData
} from "../source";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { AddNoonReportProps } from "../../addNoonReport/type";
import { formatNumber } from "@/tools/amount";

const useIndex = (props: AddNoonReportProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	// Main Information data
	const [miData, setMiData] = useState<NoonMainInfoFormType>(initialScMiData);

	// Speed Change Information data
	const [sciData, setSciData] = useState<ScSciFormType>(initialScSciData);

	// Fuel data
	const [fuelData, setFuelData] =
		useState<FuelTableDataType>(initialScFuelData);

	// basic data
	const [basicData, setBasicData] =
		useState<ScBasicDataType>(initialScBasicData);

	const handleScMiCommit: CommonCommitEvent<NoonMainInfoFormType> = (item) => {
		setMiData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleScSciCommit: CommonCommitEvent<ScSciFormType> = (item) => {
		setSciData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleScFuelCommit: CommonColumnCommitEvent<FuelTableDataItemType> = (
		index,
		item
	) => {
		setFuelData((prev) => {
			prev.splice(index, 1, item as FuelTableDataItemType);
			return [...prev];
		});
	};

	const handleClearScData = () => {
		// setMiData(initialScMiData);
		setSciData(initialScSciData);
		setFuelData(initialScFuelData);
		setBasicData(initialScBasicData);
	};

	// 接口数据赋值
	const handleScNetDataInit = (data: ScNetDataType) => {
		// setMiData({
		// 	vesselName: data.shipName ?? "",
		// 	imo: data.imo ?? "",
		// 	mmsi: data.mmsi ?? "",
		// 	callSign: data.callSign ?? ""
		// });

		setSciData({
			latitude: data.speedChangePositionLat,
			longitude: data.speedChangePositionLon,
			speedChangeDate: data.speedChangeTime ?? "",
			orderedSpeed: data.orderedSpeed ?? "",
			distanceSlr: data.distSlr ?? "",
			timeSlr: data.steamTimeSlr ?? "",
			avgSpeedSlr: formatNumber(
				Number(data.distSlr) / Number(data.steamTimeSlr),
				2
			),
			// avgSpeedSlr: data.avgSpeedSlr,
			steamTimeSlr: data.steamTimeSlr ?? ""
		});

		setFuelData((prev) => {
			return [
				{
					...prev[0],
					hfo: data.brobHfo ?? "",
					lsfo: data.brobLsfo ?? "",
					mdo: data.brobMdo ?? "",
					mgo: data.brobMgo ?? ""
				},
				{
					...prev[1],
					hfo: data.meHfoSlr ?? "",
					lsfo: data.meLsfoSlr ?? "",
					mdo: data.meMdoSlr ?? "",
					mgo: data.meMgoSlr ?? ""
				},
				{
					...prev[2],
					hfo: data.aeHfoSlr ?? "",
					lsfo: data.aeLsfoSlr ?? "",
					mdo: data.aeMdoSlr ?? "",
					mgo: data.aeMgoSlr ?? ""
				},
				{
					...prev[3],
					hfo: data.otherHfoSlr ?? "",
					lsfo: data.otherLsfoSlr ?? "",
					mdo: data.otherMdoSlr ?? "",
					mgo: data.otherMgoSlr ?? ""
				},
				{
					...prev[4],
					hfo:
						Number(data.meHfoSlr) +
						Number(data.aeHfoSlr) +
						Number(data.otherHfoSlr),
					lsfo:
						Number(data.meLsfoSlr) +
						Number(data.aeLsfoSlr) +
						Number(data.otherLsfoSlr),
					mdo:
						Number(data.meMdoSlr) +
						Number(data.aeMdoSlr) +
						Number(data.otherMdoSlr),
					mgo:
						Number(data.meMgoSlr) +
						Number(data.aeMgoSlr) +
						Number(data.otherMgoSlr)
				}
			];
		});

		setBasicData({ id: data.id });
	};

	// 提交数据
	const handleScDataCommit = (data: NoonScDataType) => {
		const commitData: Partial<ScNetDataType> = {
			id: data.basicData.id,
			legId: props.legId,
			type: "Speed Change",
			// heading: "W",
			mmsi: data.miData.mmsi,
			shipName: data.miData.vesselName,
			imo: data.miData.imo,
			callSign: data.miData.callSign,
			// speedChangeTimeStr: "20230918/0900 UTC",
			speedChangeTime: data.sciData.speedChangeDate,
			// speedChangePositionStr: "26°53.000S 038°46.000E",
			speedChangePositionLon: data.sciData.longitude,
			speedChangePositionLat: data.sciData.latitude,
			// course: ,
			orderedSpeed: data.sciData.orderedSpeed,
			timeSlr: data.sciData.timeSlr,
			avgSpeedSlr: data.sciData.avgSpeedSlr,
			// avgWaterSpeed: 1271.0,
			steamTimeSlr: data.sciData.steamTimeSlr,
			distSlr: data.sciData.distanceSlr,
			// intdSpeed: 1250.0,
			brobHfo: data.fuelData[0].hfo,
			brobLsfo: data.fuelData[0].lsfo,
			brobMdo: data.fuelData[0].mdo,
			brobMgo: data.fuelData[0].mgo,
			meHfoSlr: data.fuelData[1].hfo,
			meLsfoSlr: data.fuelData[1].lsfo,
			meMdoSlr: data.fuelData[1].mdo,
			meMgoSlr: data.fuelData[1].mgo,
			aeHfoSlr: data.fuelData[2].hfo,
			aeLsfoSlr: data.fuelData[2].lsfo,
			aeMdoSlr: data.fuelData[2].mdo,
			aeMgoSlr: data.fuelData[2].mgo,
			otherHfoSlr: data.fuelData[3].hfo,
			otherLsfoSlr: data.fuelData[3].lsfo,
			otherMdoSlr: data.fuelData[3].mdo,
			otherMgoSlr: data.fuelData[3].mgo,
			totalHfoSlr: data.fuelData[4].hfo,
			totalLsfoSlr: data.fuelData[4].lsfo,
			totalMdoSlr: data.fuelData[4].mdo,
			totalMgoSlr: data.fuelData[4].mgo
			// auxHfoSlr: 0.0,
			// auxLsfoSlr: 18.0,
			// auxMdoSlr: 2.0,
			// auxMgoSlr: 0.0
		};

		onRequest(
			"saveLegScReportApi",
			commitData,
			handleScDataCommitFront,
			handleScDataCommitSuccess,
			handleScDataCommitError,
			dispatch,
			navigate
		);
	};
	const handleScDataCommitFront = () => {};
	const handleScDataCommitSuccess = (res) => {
		console.log("success", res);
		props.onAddSuccess();
		handleClearScData();
	};
	const handleScDataCommitError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	return {
		scData: { miData, sciData, fuelData, basicData },
		handleScMiCommit,
		handleScSciCommit,
		handleScFuelCommit,
		handleClearScData,
		handleScNetDataInit,
		handleScDataCommit
	};
};

export default useIndex;
