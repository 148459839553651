import React, { FC } from "react";
import { CommonNumType } from "@/types/common";
import { NoonMainInfoFormProps, NoonMainInfoFormType } from "./type";
import { Select, FormItem, FleetInput } from "@/components";
import style from "./index.module.less";
import { CommonVesselSelect } from "@/common";
import { NoonLabel } from "..";

const NoonMainInfoForm: FC<NoonMainInfoFormProps> = (props) => {
	const { data, onChange, disabled = false } = props;

	return (
		<div className={style["noon-mi-form-container"]}>
			<div className="noon-mi-title">Main Information</div>
			<div className="noon-mi-form">
				<NoonLabel title="Vessel Name" isNecessary={true}>
					<CommonVesselSelect
						disabled={disabled}
						className="noon-mi-input"
						activeItems={{
							vesselName: data.vesselName,
							imo: data.imo as string
						}}
						showKey={"vesselName"}
						primaryKey={"imo"}
						onFinish={(newItem, operateType) => {
							onChange({
								vesselName: newItem.vesselName,
								imo: newItem.imo as CommonNumType,
								mmsi: newItem.mmsi as CommonNumType,
								callSign: newItem.callSign
							});
						}}
					/>
				</NoonLabel>
				<NoonLabel title="IMO Num" isNecessary={true}>
					<FleetInput
						disabled={disabled}
						className="noon-mi-input"
						value={data.imo}
						onChange={(val: NoonMainInfoFormType["imo"]) => {
							onChange({ ...props.data, imo: val });
						}}
					/>
				</NoonLabel>
				<NoonLabel title="Call Sign" isNecessary={true}>
					<FleetInput
						disabled={disabled}
						className="noon-mi-input"
						value={data.callSign}
						onChange={(val: NoonMainInfoFormType["callSign"]) => {
							onChange({ ...props.data, callSign: val });
						}}
					/>
				</NoonLabel>
			</div>
		</div>
	);
};

export default NoonMainInfoForm;
