import { onRequest } from "@/api";
import { CommonIdType } from "@/api/leg/type";
import { useEffect, useState } from "react";
import { PlanType } from "../type";
import { useReminder } from "@/hooks";

const useIndex = (
	planInfo: PlanType,
	simulationSelecedInfo = [],
	setSimulationSelecedInfo,
	handleSelectForecast,
	handleOpenForecast,
	handleCloseForecast
) => {
	const { reminder } = useReminder();
	// state
	const [loading, setLoading] = useState(false);
	const [simulationList, setSimulationList] = useState(null);
	const [simulationDialogVisible, setSimulationDialogVisible] =
		useState<boolean>(false);
	const [previewDialogVisible, sePreviewDialogVisible] =
		useState<boolean>(false);

	// handle
	const initSimulationList = () => {
		getSimulationList({ id: planInfo?.id });
	};
	const handleSimulationAgain = (id: string) => {
		routeSimulationAgain({ id });
	};

	const handleCloseSimulationDialog = () => {
		setSimulationDialogVisible(false);
	};
	const handleOpenSimulationDialog = () => {
		setSimulationDialogVisible(true);
	};
	const handleOpenPreviewDialog = () => {
		if (simulationSelecedInfo?.length === 0) {
			return;
		}
		sePreviewDialogVisible(true);
	};
	const handleClosePreviewDialog = () => {
		sePreviewDialogVisible(false);
	};
	const handleSelectSimulationItem = (checked, simulationInfo) => {
		if (checked) {
			// 选择
			if (
				simulationSelecedInfo?.length > 0 &&
				simulationSelecedInfo[0].routeId !== planInfo?.routeId
			) {
				// 限制同一个routeId
				setSimulationSelecedInfo([
					{
						planId: planInfo?.id,
						routeId: planInfo?.routeId,
						routeName: planInfo?.routeName,
						...simulationInfo
					}
				]);
				return;
			}
			setSimulationSelecedInfo([
				...simulationSelecedInfo.filter((item) => item.planId !== planInfo?.id), // 限制非同一个planId
				{
					planId: planInfo?.id,
					routeId: planInfo?.routeId,
					routeName: planInfo?.routeName,
					...simulationInfo
				}
			]);
		} else {
			// 取消选择
			setSimulationSelecedInfo(
				simulationSelecedInfo.filter((item) => item.id !== simulationInfo.id)
			);
		}
	};
	const handleDelete = (id) => {
		deleteSimulation({ id });
	};
	const handleShowForecast = (item) => {
		// 打开forecast
		handleSelectForecast(item);
		handleOpenForecast();
		// // 关闭forecast
		// handleCloseForecast();
	};

	// API - getSimulationList
	const getSimulationListFront = () => {
		setLoading(true);
	};
	const getSimulationListSuccess = (data, planId) => {
		setSimulationList({ id: planId, list: data?.data });
		setLoading(false);
	};
	const getSimulationListFailed = (error) => {
		setLoading(false);
	};
	const getSimulationList = (params: CommonIdType) => {
		onRequest(
			"simulationListApi",
			params,
			getSimulationListFront,
			(data) => {
				getSimulationListSuccess(data, params.id);
			},
			getSimulationListFailed
		);
	};

	// Api - routeSimulationAgain
	const routeSimulationAgainFront = () => {
		setLoading(true);
	};
	const routeSimulationAgainSuccess = () => {
		setLoading(false);
		initSimulationList();
	};
	const routeSimulationAgainFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const routeSimulationAgain = (params: CommonIdType) => {
		onRequest(
			"routeSimulationAgainApi",
			params,
			routeSimulationAgainFront,
			routeSimulationAgainSuccess,
			routeSimulationAgainFailed
		);
	};

	// Api - deleteSimulation
	const deleteSimulationFront = () => {
		setLoading(true);
	};
	const deleteSimulationSuccess = () => {
		setLoading(false);
		initSimulationList();
	};
	const deleteSimulationFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const deleteSimulation = (params: CommonIdType) => {
		onRequest(
			"delRouteSimulationApi",
			params,
			deleteSimulationFront,
			deleteSimulationSuccess,
			deleteSimulationFailed
		);
	};

	useEffect(() => {
		initSimulationList();
	}, []);

	return {
		simulationList,
		simulationDialogVisible,
		loading,
		previewDialogVisible,
		handleOpenSimulationDialog,
		handleCloseSimulationDialog,
		handleSimulationAgain,
		initSimulationList,
		handleOpenPreviewDialog,
		handleClosePreviewDialog,
		handleSelectSimulationItem,
		handleDelete,
		handleShowForecast
	};
};

export default useIndex;
