import { ColumnsType } from "antd/es/table";
import { VoyDataType, LegDataType } from "./type";
import dayjs, { Dayjs } from "dayjs";
import { DataType } from "@/components/Table";
export const columns: ColumnsType<DataType> = [
	// {
	// 	title: " ",
	// 	dataIndex: "legType",
	// 	key: "legType",
	// 	width: "60",
	// 	fixed: "left",
	// 	align: "center",
	// 	ellipsis: true
	// },
	{
		title: "Condition",
		dataIndex: "legType",
		key: "legType",
		width: "120",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		title: "Vessel",
		dataIndex: "vesselName",
		key: "vesselName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "IMO",
		dataIndex: "imo",
		key: "imo",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Departure",
		dataIndex: "departurePortName",
		key: "departurePortName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "ETD",
		dataIndex: "etd",
		key: "etd",
		width: "220",
		align: "center",
		ellipsis: true,
		render: (value) => {
			return value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "";
		}
	},
	{
		title: "ATD",
		dataIndex: "atd",
		key: "atd",
		width: "220",
		align: "center",
		ellipsis: true,
		render: (value) => {
			return value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "";
		}
	},
	{
		title: "Arrival",
		dataIndex: "arrivalPortName",
		key: "arrivalPortName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Company",
		dataIndex: "companyName",
		key: "companyName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Role",
		dataIndex: "customerRole",
		key: "customerRole",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Service",
		dataIndex: "service",
		key: "service",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		width: "120",
		align: "center",
		ellipsis: true
	}
];
