import { CommonOptionType } from "@/types/common";
import { ReportTypeSelectProps } from "./type";

export const reportTypeOptions: CommonOptionType<
	ReportTypeSelectProps["value"],
	ReportTypeSelectProps["value"]
>[] = [
	{ label: "EOSP", value: "EOSP" },
	{ label: "NOON", value: "NOON" },
	{ label: "COSP", value: "COSP" },
	{ label: "STOP", value: "STOP" },
	{ label: "RESUME", value: "RESUME" },
	{ label: "Speed Change", value: "Speed Change" }
];
