import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { RouteWayPointItemType, WayPointMapRefType } from "../type";
import { routeSource, SigWaveColor } from "../source";
import { loadImage } from "@/pages/leg/business/details/components";
import RouteWayPointIcon from "static/images/leg/route/leg_waypoint_icon.png";
import RouteWayPointSelectedIcon from "static/images/leg/route/leg_waypoint_selected_icon.png";
import RouteVesselSelectIcon from "static/images/leg/ais/leg_ais_vessel_selected.png";
import { generateWayTrackPoints, RouteWayTrackLineGeoJson } from "../tools";

const useIndex = () => {
	const mapContainer = useRef<HTMLDivElement>(null);
	const mapboxGl = useRef<mapboxgl.Map>(null);
	const renderQueue = useRef<(() => void)[]>([]);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const mapboxglInit = () => {
		mapboxGl.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/voyagecentury2018/cln1634sd003g01rc1alr5qmp",
			accessToken:
				"pk.eyJ1Ijoidm95YWdlY2VudHVyeTIwMTgiLCJhIjoiY2xmOTM0NDJyMXV1MjQwb2NrNzFvZWJ3dSJ9.zyBT0Gd6HYyeywftD_XKtg",
			dragPan: true,
			center: { lng: 105.78, lat: 32.99 },
			attributionControl: false,
			preserveDrawingBuffer: true
		});
		mapboxGl?.current?.on("load", () => {
			loadImage(mapboxGl?.current, {
				imageName: "vesselIcon",
				imagePath: RouteVesselSelectIcon
			});
			loadImage(mapboxGl?.current, {
				imageName: "routeWayPointIcon",
				imagePath: RouteWayPointIcon
			});
			loadImage(mapboxGl?.current, {
				imageName: "routeWayPointSelectedIcon",
				imagePath: RouteWayPointSelectedIcon
			});
			loadSource();
			loadlayers();
			setIsLoaded(true);
			// getCurrentMapInfo();
		});
	};

	const loadSource = () => {
		mapboxGl?.current?.addSource("route-vessel-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
		mapboxGl?.current?.addSource("route-line-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
		mapboxGl?.current?.addSource("route-edit-line-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
		mapboxGl?.current?.addSource("route-point-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
		mapboxGl?.current?.addSource("route-point-current-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
	};

	const loadlayers = () => {
		mapboxGl?.current?.addLayer({
			id: routeSource["route-line-source"],
			source: "route-line-source",
			type: "line",
			layout: {
				"line-join": "round"
			},
			paint: {
				"line-color": "#8186FE",
				"line-width": 3,
				"line-dasharray": [4, 2]
			}
		});
		mapboxGl?.current?.addLayer({
			id: routeSource["route-edit-line-source"],
			source: "route-edit-line-source",
			type: "line",
			layout: {
				"line-join": "round"
			},
			paint: {
				"line-color": [
					"match",
					["get", "seaStatus"],
					"light",
					SigWaveColor.light,
					"moderate",
					SigWaveColor.moderate,
					"heavy",
					SigWaveColor.heavy,
					"severe",
					SigWaveColor.severe,
					"#333"
				],
				"line-width": 3
				// "line-dasharray": [4, 2]
			}
		});
		mapboxGl?.current?.addLayer({
			id: routeSource["route-point-source"],
			source: "route-point-source",
			type: "symbol",
			layout: {
				"icon-image": "routeWayPointIcon",
				"icon-size": 0.2,
				"icon-allow-overlap": true
			}
		});
		mapboxGl?.current?.addLayer({
			id: routeSource["route-point-current-source"],
			source: "route-point-current-source",
			type: "symbol",
			layout: {
				"icon-image": "routeWayPointSelectedIcon",
				"icon-size": 0.2,
				"icon-allow-overlap": true
			}
		});
		mapboxGl?.current?.addLayer({
			id: routeSource["route-vessel-source"],
			source: "route-vessel-source",
			type: "symbol",
			layout: {
				"icon-image": "vesselIcon",
				"icon-size": 0.6,
				"icon-rotate": ["get", "course"]
			}
		});
	};

	const handleVesselLoad = (item: RouteWayPointItemType) => {
		const currentSource = mapboxGl?.current?.getSource<GeoJSONSource>(
			"route-vessel-source"
		);
		currentSource.setData({
			type: "FeatureCollection",
			features: [
				{
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [item?.lon, item?.lat]
					},
					properties: { ...item }
				}
			]
		});
	};

	const handleTrackPointCommit = (
		items: RouteWayTrackLineGeoJson<GeoJSON.Point>[],
		lineItems: RouteWayTrackLineGeoJson<GeoJSON.LineString>[]
	) => {
		const currentSource = mapboxGl?.current?.getSource<GeoJSONSource>(
			"route-point-source"
		) as GeoJSONSource;
		const currentLineSource = mapboxGl?.current?.getSource<GeoJSONSource>(
			"route-edit-line-source"
		);
		currentSource?.setData({
			type: "FeatureCollection",
			features: items
		});
		currentLineSource?.setData({
			type: "FeatureCollection",
			features: lineItems
		});
		// mapboxGl?.current?.setFilter(routeSource["route-point-source"], null);
		handleWayPointFlyTo(
			items?.filter((item) => item?.geometry?.type === "Point")
		);
	};

	const handleTrackLineCommit = (
		items: RouteWayTrackLineGeoJson<GeoJSON.MultiLineString>[]
	) => {
		const currentSource = mapboxGl?.current?.getSource(
			"route-line-source"
		) as GeoJSONSource;
		currentSource?.setData({
			type: "FeatureCollection",
			features: items
		});
	};

	const handleWayPointFlyTo = (
		items: RouteWayTrackLineGeoJson<GeoJSON.Point | GeoJSON.LineString>[]
	) => {
		const bounds = new mapboxgl.LngLatBounds();
		items.forEach((feature) => {
			bounds.extend(feature.geometry.coordinates as [number, number]);
		});

		mapboxGl?.current?.fitBounds(bounds, {
			padding: 20, // 可选：设置边距
			zoom: 2.5
		});
	};

	const handleWayPointsFocus: WayPointMapRefType["onWayPointFocus"] = (
		item
	) => {
		const feature = mapboxGl?.current?.querySourceFeatures(
			"route-point-source",
			{
				filter: ["==", "id", item?.id]
			}
		)?.[0] as RouteWayTrackLineGeoJson<GeoJSON.Point>;
		if (feature) {
			mapboxGl?.current?.flyTo(
				{
					center: [item?.lon, item?.lat],
					duration: 300
				},
				{
					moveend: "FLY_END"
				}
			);
			const currentPointSource = mapboxGl?.current?.getSource<GeoJSONSource>(
				"route-point-current-source"
			);
			currentPointSource?.setData({
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: [item?.lon, item?.lat]
						},
						properties: {}
					}
				]
			});
			setTimeout(() => {
				currentPointSource?.setData({
					type: "FeatureCollection",
					features: []
				});
			}, 2000);
		}
	};

	const handleWayPointsLoad: WayPointMapRefType["onWayPointsLoad"] = (
		selectItem,
		otherItems
	) => {
		const { trackLines, trackPoints, trackPointLines } = generateWayTrackPoints(
			selectItem,
			otherItems
		);
		console.log("selectItem", {
			selectItem,
			otherItems,
			trackLines,
			trackPoints,
			trackPointLines
		});
		if (!isLoaded) {
			renderQueue.current?.push(() => {
				handleTrackPointCommit(trackPoints, trackPointLines);
				handleTrackLineCommit(trackLines);
				handleVesselLoad(selectItem?.[0] ?? otherItems?.[0]?.[0]);
			});
			return;
		}
		handleTrackPointCommit(trackPoints, trackPointLines);
		handleTrackLineCommit(trackLines);
		handleVesselLoad(selectItem?.[0] ?? otherItems?.[0]?.[0]);
	};

	const handleWayPointsVisible: WayPointMapRefType["onWayPointsVisible"] = (
		ids
	) => {
		console.log("ids", ids);
		mapboxGl?.current?.setFilter(routeSource["route-point-source"], [
			"any",
			...ids?.map((id) => ["in", ["get", "id"], id])
		]);
	};

	const handleWayPointsLayoutVisible: WayPointMapRefType["onWayPointsLayoutVisible"] =
		(value) => {
			mapboxGl?.current?.setLayoutProperty(
				routeSource["route-point-source"],
				"visibility",
				value
			);
		};

	const getCurrentMapInfo: WayPointMapRefType["getMapInfo"] = () => {
		return new Promise((resolve, reject) => {
			handleWayPointsLayoutVisible("none");
			requestAnimationFrame(() => {
				const zoom = mapboxGl?.current?.getZoom(),
					center = mapboxGl?.current?.getCenter(),
					canvas = mapboxGl?.current?.getCanvas();
				const dataURL = canvas.toDataURL("image/png");

				// 将Data URL转换为文件对象
				const byteString = atob(dataURL.split(",")[1]);
				const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

				const arrayBuffer = new ArrayBuffer(byteString.length);
				const uint8Array = new Uint8Array(arrayBuffer);
				for (let i = 0; i < byteString.length; i++) {
					uint8Array[i] = byteString.charCodeAt(i);
				}

				const blob = new Blob([arrayBuffer], { type: mimeString });
				const file = new File([blob], "canvas-image.png", {
					type: mimeString
				});

				resolve({
					zoom,
					center,
					mapImage: file
				});
			});
		});
	};

	useEffect(() => {
		if (!mapboxGl.current) {
			mapboxglInit();
		}
		return () => {
			mapboxGl?.current?.remove();
			mapboxGl.current = null;
		};
	}, []);

	useEffect(() => {
		if (!isLoaded) return;
		while (renderQueue?.current?.length > 0) {
			const callback = renderQueue?.current?.shift();
			callback?.();
		}
	}, [isLoaded]);

	return {
		mapContainer,
		handleWayPointsLoad,
		handleWayPointsVisible,
		handleWayPointsFocus,
		getCurrentMapInfo,
		handleWayPointsLayoutVisible
	};
};

export default useIndex;
