import { useReminder } from "@/hooks";
import { convertToDms, getLastDate, transform2DM } from "../tools";
import { useEffect, useRef, useState } from "react";
import { createRangingControll } from "../tools";
import { routeCommonVesselIconMap, vesselIconNames } from "../source";
import { loadImage } from "../utils";
import {
	RouteCommonRangingControllTabType,
	RouteCommonTimelineLegendPanelProps,
	RouteCommonToolbarProps
} from "../components";
import useDiscrete from "./useDiscrete";
import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
import useControlIndex from "./useControlIndex";
import useRoute from "./useRoute";
import { RouteCommonMapProps, RouteCommonMapRefType } from "../type";
import useNoon from "./useNoon";
import useAisTrack from "./useAisTrack";
import usePort from "./usePort";
import useHifleet from "./useHifleet";
const L = window?.L;

// dayjs.extend(utc);
// dayjs.extend(timezone);

const useIndex = (
	onWayPointFocus?: RouteCommonMapProps["onWayPointFocus"],
	onTyhoonSelect?: RouteCommonMapProps["onTyhoonSelect"],
	onResetDistance?: RouteCommonMapProps["onResetDistance"]
) => {
	const { reminder } = useReminder();
	const meteoCore = useRef(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const renderQueue = useRef<(() => void)[]>([]);
	const mapboxGl = useRef<mapboxgl.Map>(null);
	const rangingControll = useRef(null);
	const timerId = useRef<NodeJS.Timeout>(null);

	const leafletLayerGroup = useRef(new L.LayerGroup());

	const [legendVisible, setLegendVisible] = useState(true);
	const handleLegendVisibleChange = () => {
		setLegendVisible(!legendVisible);
	};

	const { meteoStatus, handleMeteoStatusChange } = useControlIndex(meteoCore);

	const { loadDiscreteLayers, onDiscreteListen, handlePointControlSelect } =
		useDiscrete(mapboxGl);

	const {
		loadSource: loadHifleetSource,
		loadLayers: loadHifleetLayers,
		enableLayers: handleEnableHifleetLayer
	} = useHifleet(mapboxGl);

	const handleMapDragingEnable = (type: "enable" | "disable") => {
		meteoCore?.current?.map.dragging[type]?.();
	};

	const handleMapFlyTo = (item: { lon: number; lat: number }) => {
		const bounds = L.geoJson({
			type: "FeatureCollection",
			features: [
				{
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [item?.lon, item?.lat]
					}
				}
			]
		}).getBounds();
		meteoCore?.current?.map.fitBounds(bounds, {
			maxZoom: 5
		});
	};

	const {
		mapRouteMenuRef,
		handleRouteListen,
		loadSource: loadRouteSource,
		loadlayers: loadRouteLayers,
		handlePointRemove,
		handleTrackLineCommit,
		handleTrackPointCommit,
		handleTrackPointFocus
	} = useRoute(
		mapboxGl,
		handleMapDragingEnable,
		onWayPointFocus,
		onResetDistance
	);

	const {
		loadSource: loadNoonSource,
		loadlayers: loadNoonLayers,
		handleNoonReportLoad,
		handleNoonReportVisible
	} = useNoon(mapboxGl);

	const {
		loadSource: loadAisSource,
		loadlayers: loadAisLayers,
		handleAisTrackLoad: _handleAisTrackLoad
	} = useAisTrack(mapboxGl, handleMapFlyTo);

	const {
		loadSource: loadPortSource,
		loadlayers: loadPortLayers,
		handleBasicPortLoad: _handleBasicPortLoad
	} = usePort(mapboxGl);

	const handleAisTrackLoad: RouteCommonMapRefType["loadAisTracks"] = (item) => {
		if (!isLoaded) {
			renderQueue.current?.push(() => {
				_handleAisTrackLoad(item);
			});
			return;
		}
		_handleAisTrackLoad(item);
	};

	const handleBasicPortLoad: RouteCommonMapRefType["loadBasicPorts"] = (
		items
	) => {
		if (!isLoaded) {
			renderQueue.current?.push(() => {
				_handleBasicPortLoad(items);
			});
			return;
		}
		_handleBasicPortLoad(items);
	};

	const handleLoadImages = () => {
		for (let imageName of vesselIconNames) {
			loadImage(mapboxGl.current, {
				imageName,
				imagePath: routeCommonVesselIconMap[imageName]
			});
		}
	};

	const handleRangingSelect = (type: RouteCommonRangingControllTabType) => {
		const isCircle = type === "circle";
		switch (true) {
			case !type:
			case rangingControll?.current?.options.greatCircle === isCircle:
				rangingControll.current.options.greatCircle = null;
				rangingControll.current._toggleMeasure?.(false);
				break;
			default:
				rangingControll.current.options.greatCircle = isCircle;
				!rangingControll.current._measuring &&
					rangingControll.current._toggleMeasure?.();
				break;
		}
	};

	const handleControllChange: RouteCommonToolbarProps["onControllChange"] = (
		layerIds,
		isShow
	) => {
		if ((layerIds as ["enc"])?.includes("enc")) {
			handleEnableHifleetLayer(isShow ? "visible" : "none");
			return;
		}
		for (let layerId of layerIds) {
			mapboxGl.current?.setLayoutProperty(
				layerId,
				"visibility",
				isShow ? "visible" : "none"
			);
		}
	};

	const handleMeteoTimeChange = (value: number) => {
		meteoCore?.current.store.set("timestamp", value);
	};

	const getMeteoCalendar = () => {
		console.log("getMeteoCalendar", meteoCore?.current.store.get("calendar"));
		return meteoCore?.current.store.get("calendar");
	};

	const getMeteoCalendarEndTime = () => {
		const meteoCalendar = getMeteoCalendar?.();
		// console.log(
		// 	"utc",
		// 	dayjs
		// 		.utc(meteoCalendar?.end)
		// 		// .utcOffset(new Date()?.getTimezoneOffset() / -60)
		// 		?.format("YYYY-MM-DD HH:MM"),
		// 	dayjs?.utc()?.add(10, "day")?.startOf("day")?.format("YYYY-MM-DD HH:MM")
		// );
		return dayjs(meteoCalendar?.end);
	};

	const handleTyhoonSelect: RouteCommonMapProps["onTyhoonSelect"] = (
		typhoonId,
		forecase
	) => {
		console.log("handleTyhoonSelect", typhoonId, forecase);
		onTyhoonSelect?.(typhoonId, forecase);
	};

	const handleRun: RouteCommonTimelineLegendPanelProps["onRun"] = (
		type,
		startTime,
		callback,
		interval
	) => {
		if (type === "stop") {
			clearInterval(timerId?.current);
			timerId.current = null;
			return;
		}
		const meteoCalendar = getMeteoCalendar();
		const endTime = dayjs(meteoCalendar.end);
		let currentTime = startTime;
		if (timerId.current) {
			clearInterval(timerId?.current);
			timerId.current = null;
		}
		timerId.current = setInterval(() => {
			const newTime = currentTime.add(interval, "h"),
				lastTime = getLastDate();
			console.log("ddd", {
				newTime,
				endTime
			});
			if (newTime?.isAfter(endTime) || newTime?.isSame(endTime)) {
				clearInterval(timerId?.current);
				timerId.current = null;
				callback?.(endTime);
				console.log("meteoTimeChange endtime", newTime, lastTime);
				handleMeteoTimeChange(endTime.valueOf());
				return;
			}
			if (
				newTime?.startOf("hour")?.isAfter(lastTime) ||
				newTime?.startOf("hour")?.isSame(lastTime)
			) {
				clearInterval(timerId?.current);
				timerId.current = null;
				callback?.(lastTime);
				console.log("meteoTimeChange", newTime, lastTime);
				handleMeteoTimeChange(lastTime.valueOf());
				return;
			}
			if (newTime.isBefore(endTime)) {
				currentTime = newTime;
			} else {
				currentTime = startTime;
			}
			typeof callback === "function" && callback(currentTime);
			handleMeteoTimeChange(currentTime.valueOf());
		}, 1000);
	};

	useEffect(() => {
		console.log("reload");
		delete require.cache[require.resolve("../lib/libBoot")];
		require("../lib/libBoot");
		window.meteoApi(
			{
				verbose: false,
				lat: 32.99,
				lon: 105.78,
				zoom: 3
				// minZoom: 2
			},
			(core) => {
				meteoCore.current = core;
				core.store.set("product", "ecmwf");
				// window.getMeteoCalendarEndTime = getMeteoCalendarEndTime;
				const mapboxMap = L.mapboxGL({
					accessToken:
						"pk.eyJ1IjoibGl0dGxlZml2ZTE5OTUiLCJhIjoiY2w1OWRtdTB3MmJqbTNjcXFpcWE4dDIxdSJ9.3ZH5BCZI085b4B9XVflZpg",
					style: "mapbox://styles/littlefive1995/clpgntuil00h201p97zby73r2",
					padding: 0,
					dragPan: true,
					pane: "mapboxgl-tile-pane",
					paneZindex: 399,
					// renderWorldCopies: false,
					minZoom: 0,
					maxZoom: 16,
					zoom: 3,
					// minZoom: 2,
					mouseEvent: true
				}).addTo(core.map);
				leafletLayerGroup?.current?.addTo(core.map);
				// core.map.dragging.disable();
				const mapboxGlMap = mapboxMap.getMapboxMap();
				mapboxGlMap.on("load", () => {
					// 单独设置symbol-spacing属性
					mapboxGlMap.setLayoutProperty("ports", "icon-padding", 48);
					mapboxGlMap.setLayoutProperty("ports", "text-size", 10);
					mapboxGlMap.on("click", "ports", (e) => {
						const properties = e.features[0].properties;
						const portInfoPopup = L.popup({
							closeButton: true,
							offset: [0, 0],
							autoClose: true,
							closeOnClick: false
						});
						portInfoPopup
							.setContent(
								'<div class="polyline-measure-tooltip leaflet-zoom-animated" tabindex="0" role="button" style="box-shadow: none;margin-left: 4px; margin-top: 4px; width: 12px; height: 12px; z-index: 210; padding: 0;padding-right: 20px;background: none">' +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Position:</div>' +
									'<div class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									`${transform2DM(properties.x)}${properties.x >= 0 ? "E" : "W"} ${transform2DM(properties.y)}${properties.y >= 0 ? "S" : "N"}` +
									"</div></div>" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Country/Area:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.country +
									"</span>" +
									"</div></div>" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Port Code:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.port_code +
									"</span>" +
									"</div></div>" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Port Name:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.port_name +
									"</span>" +
									"</div></div>" +
									"</div>"
							)
							.setLatLng(e.lngLat)
							.openOn(leafletLayerGroup?.current);
					});
					handleLoadImages();
					loadDiscreteLayers();
					loadPortSource();
					loadPortLayers();
					loadRouteSource();
					loadRouteLayers();
					handleRouteListen();
					loadNoonSource();
					loadNoonLayers();
					loadAisSource();
					loadAisLayers();
					loadHifleetSource();
					loadHifleetLayers();
					mapboxGlMap.on("click", onDiscreteListen);
					setIsLoaded(true);
				});
				L.control
					.mousePosition({
						position: "bottomleft",
						emptyString: "Lat/Lng: 暂无经纬度信息",
						lngFirst: true,
						numDigits: 6,
						prefix: "经度:",
						separator: " | ",
						suffix: " 纬度:",
						showDecimal: true,
						showDMS: false,
						wrapLng: true,
						wrapLat: false,
						lngFormatter: function (lng) {
							return lng.toFixed(6);
						},
						latFormatter: function (lat) {
							return lat.toFixed(6);
						},
						formatter: function (lng: number, lat: number) {
							return `Lat/Lng: ${convertToDms(lat, lng)}`;
						}
					})
					.addTo(core.map);
				mapboxGl.current = mapboxGlMap;
				core.map.on("zoom", () => {
					const zoom = core.map.getZoom();
					const iconPadding = Math.min(Math.max(0, 48 - (zoom - 2) * 12), 48);
					mapboxGlMap.setLayoutProperty("ports", "icon-padding", iconPadding);
				});
				core.map.createPane("measure");
				core.map.getPane("measure").style.zIndex = 9999;
				core.map.createPane("measure-line");
				core.map.getPane("measure-line").style.zIndex = 9998;
				window.onTyhoonSelect = handleTyhoonSelect;
				rangingControll.current = createRangingControll(L, core.map);
				core.store.on("hurricanes", (data) => {
					console.log("data", data);
					if (data === "no") {
						reminder("success", "No active typhoon");
					}
				});
			}
		);
	}, []);

	useEffect(() => {
		if (!isLoaded) return;
		while (renderQueue?.current?.length > 0) {
			const callback = renderQueue?.current?.shift();
			callback?.();
		}
	}, [isLoaded]);

	return {
		mapRouteMenuRef,
		handleControllChange,
		meteoStatus,
		legendVisible,
		handleLegendVisibleChange,
		handleMeteoStatusChange,
		getMeteoCalendar,
		handleMeteoTimeChange,
		handleRangingSelect,
		handlePointControlSelect,
		handleRun,
		getMeteoCalendarEndTime,
		handlePointRemove,
		handleTrackLineCommit,
		handleTrackPointCommit,
		handleTrackPointFocus,
		handleNoonReportLoad,
		handleAisTrackLoad,
		handleMapFlyTo,
		handleBasicPortLoad,
		handleNoonReportVisible
	};
};

export default useIndex;
