import React, { FC } from "react";
import style from "./index.module.less";
import { FleetInput, FleetTimePicker, Select } from "@/components";
import { LatLongInput } from "@/common";
import {
	NoonMainInfoForm,
	NoonNextPortForm,
	NoonLabel,
	NoonFuelForm
} from "../../common";
import { wsDirectOptions } from "./source";
import { NrPatiFormType, NrViFormType, NoonNrRFProps } from "./type";
import dayjs from "dayjs";
import { FleetUtcTimePicker } from "../../../../components";
import { formatNumber } from "@/tools/amount";

const NoonNrRF: FC<NoonNrRFProps> = (props) => {
	const {
		nrData,
		onNrMiCommit,
		onNrPatiCommit,
		onNrViCommit,
		onNrFuelCommit,
		onNrNpCommit
	} = props;

	const { miData, patiData, viData, fuelData, npData } = nrData;

	return (
		<div className={style["noon-nr-rf"]}>
			<NoonMainInfoForm
				disabled={true}
				data={miData}
				onChange={(data) => {
					onNrMiCommit(data);
				}}
			/>
			<div className="noon-pati-form-container">
				<p className="noon-pati-title">Position and Time Information</p>
				<div className="noon-pati-form">
					<div className="pati-form-row">
						<NoonLabel
							className="custom-label-content"
							title="Latitude"
							isNecessary
						>
							<LatLongInput
								className="nr-input"
								value={patiData.latitude}
								type="latitude"
								onChange={(val: NrPatiFormType["latitude"]) => {
									onNrPatiCommit({ ...patiData, latitude: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel
							className="custom-label-content"
							title="Longitude"
							isNecessary
						>
							<LatLongInput
								className="nr-input"
								value={patiData.longitude}
								type="longitude"
								onChange={(val: NrPatiFormType["longitude"]) => {
									onNrPatiCommit({ ...patiData, longitude: val });
								}}
							/>
						</NoonLabel>
					</div>
					<div className="pati-form-row">
						<NoonLabel title="Position Date" isNecessary>
							<FleetUtcTimePicker
								className="nr-input"
								value={Number(patiData.positionDate)}
								onChange={(val) => {
									onNrPatiCommit({
										...patiData,
										positionDate: val
									});
								}}
							/>
							{/* <FleetTimePicker
								className="nr-input"
								value={
									patiData.positionDate ? dayjs(patiData.positionDate) : null
								}
								onChange={(val) => {
									onNrPatiCommit({
										...patiData,
										positionDate: dayjs(val).valueOf()
									});
								}}
							/> */}
							<span className="nr-init">UTC</span>
						</NoonLabel>
					</div>
				</div>
			</div>

			<div className="noon-vi-form-container">
				<p className="noon-vi-title">Voyage Information</p>
				<div className="noon-vi-form">
					<div className="vi-form-row">
						<NoonLabel title="Fore Draft" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.foreDraft}
								onChange={(val: NrViFormType["foreDraft"]) => {
									onNrViCommit({ ...viData, foreDraft: val });
								}}
							/>
							<span className="nr-init">M</span>
						</NoonLabel>
						<NoonLabel title="Aft Draft" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.aftDraft}
								onChange={(val: NrViFormType["aftDraft"]) => {
									onNrViCommit({ ...viData, aftDraft: val });
								}}
							/>
							<span className="nr-init">M</span>
						</NoonLabel>
						<NoonLabel title="Mid Draft" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.midDraft}
								onChange={(val: NrViFormType["midDraft"]) => {
									onNrViCommit({ ...viData, midDraft: val });
								}}
							/>
							<span className="nr-init">M</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel
							className="custom-label-title"
							title="Avg Speed Over Ground SLR"
							isNecessary
						>
							<FleetInput
								className="nr-input"
								value={viData.avgSogSlr}
								onChange={(val: NrViFormType["avgSogSlr"]) => {
									onNrViCommit({ ...viData, avgSogSlr: val });
								}}
							/>
							<span className="nr-init">KTS</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Course" isNecessary>
							<FleetInput
								className="nr-input"
								value={viData.course}
								onChange={(val: NrViFormType["course"]) => {
									onNrViCommit({ ...viData, course: val });
								}}
							/>
							<span className="nr-init">DEG</span>
						</NoonLabel>
						<NoonLabel title="Distance SLR" isNecessary>
							<FleetInput
								className="nr-input"
								value={viData.distanceSlr}
								onChange={(val: NrViFormType["distanceSlr"]) => {
									onNrViCommit({ ...viData, distanceSlr: val });
								}}
							/>
							<span className="nr-init">NM</span>
						</NoonLabel>
						<NoonLabel title="Distance To Go" isNecessary>
							<FleetInput
								className="nr-input"
								value={viData.distanceToGo}
								onChange={(val: NrViFormType["distanceToGo"]) => {
									onNrViCommit({ ...viData, distanceToGo: val });
								}}
							/>
							<span className="nr-init">NM</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="ME Power SLR" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.mePowerSlr}
								onChange={(val: NrViFormType["mePowerSlr"]) => {
									onNrViCommit({ ...viData, mePowerSlr: val });
								}}
							/>
							<span className="nr-init">KW</span>
						</NoonLabel>
						<NoonLabel title="Avg RPM SLR" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.avgRpmSlr}
								onChange={(val: NrViFormType["avgRpmSlr"]) => {
									onNrViCommit({ ...viData, avgRpmSlr: val });
								}}
							/>
							<span className="nr-init">RPM</span>
						</NoonLabel>
						<NoonLabel title="Slip SLR" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.slipSlr}
								onChange={(val: NrViFormType["slipSlr"]) => {
									onNrViCommit({ ...viData, slipSlr: val });
								}}
							/>
							<span className="nr-init">%</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Time SLR" isNecessary>
							<FleetInput
								className="nr-input"
								value={viData.timeSlr}
								onChange={(val: NrViFormType["timeSlr"]) => {
									onNrViCommit({ ...viData, timeSlr: val });
								}}
							/>
							<span className="nr-init">HRS</span>
						</NoonLabel>
						<NoonLabel title="MCR" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.mcr}
								onChange={(val: NrViFormType["mcr"]) => {
									onNrViCommit({ ...viData, mcr: val });
								}}
							/>
							<span className="nr-init">%</span>
						</NoonLabel>
						<NoonLabel title="Ordered Speed" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.orderedSpeed}
								onChange={(val: NrViFormType["orderedSpeed"]) => {
									onNrViCommit({ ...viData, orderedSpeed: val });
								}}
							/>
							<span className="nr-init">KTS</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Wind Beaufort" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.windBeaufort}
								onChange={(val: NrViFormType["windBeaufort"]) => {
									onNrViCommit({ ...viData, windBeaufort: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Wind Direction" isNecessary={false}>
							<Select
								className="nr-input"
								value={viData.windDirection}
								options={wsDirectOptions}
								onChange={(val: NrViFormType["windDirection"]) => {
									onNrViCommit({ ...viData, windDirection: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Sea Height" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.seasHeight}
								onChange={(val: NrViFormType["seasHeight"]) => {
									onNrViCommit({ ...viData, seasHeight: val });
								}}
							/>
							<span className="nr-init">M</span>
						</NoonLabel>
					</div>
					<div className="vi-form-row">
						<NoonLabel title="Swell Height" isNecessary={false}>
							<FleetInput
								className="nr-input"
								value={viData.swellHeight}
								onChange={(val: NrViFormType["swellHeight"]) => {
									onNrViCommit({ ...viData, swellHeight: val });
								}}
							/>
							<span className="nr-init">M</span>
						</NoonLabel>
						<NoonLabel title="Swell Direction" isNecessary={false}>
							<Select
								className="nr-input"
								value={viData.swellDirection}
								options={wsDirectOptions}
								onChange={(val: NrViFormType["swellDirection"]) => {
									onNrViCommit({ ...viData, swellDirection: val });
								}}
							/>
						</NoonLabel>
					</div>
				</div>
			</div>

			<NoonFuelForm
				dataSource={fuelData}
				onCommit={(index, item) => {
					onNrFuelCommit(index, item);
					if (index === 1 || index === 2 || index === 3) {
						const totalConsSlr = {
							...fuelData[4],
							hfo: formatNumber(
								Number(fuelData[1].hfo) +
									Number(fuelData[2].hfo) +
									Number(fuelData[3].hfo),
								3
							),
							lsfo: formatNumber(
								Number(fuelData[1].lsfo) +
									Number(fuelData[2].lsfo) +
									Number(fuelData[3].lsfo),
								3
							),
							mdo: formatNumber(
								Number(fuelData[1].mdo) +
									Number(fuelData[2].mdo) +
									Number(fuelData[3].mdo),
								3
							),
							mgo: formatNumber(
								Number(fuelData[1].mgo) +
									Number(fuelData[2].mgo) +
									Number(fuelData[3].mgo),
								3
							)
						};
						onNrFuelCommit(4, totalConsSlr);
					}
				}}
			/>

			<NoonNextPortForm
				data={npData}
				onChange={(data) => {
					onNrNpCommit(data);
				}}
			/>
		</div>
	);
};

export default NoonNrRF;
