import { useRef, useEffect, useState } from "react";

const useIndex = () => {
	const imgRef = useRef<HTMLImageElement>(null);

	// 是否宽度更大
	const [isWidthGreater, setIsWidthGreater] = useState<boolean>(false);

	useEffect(() => {
		imgRef.current.onload = () => {
			setIsWidthGreater(imgRef.current.width > imgRef.current.height);
		};
	});

	return { imgRef, isWidthGreater };
};

export default useIndex;
