import React from "react";
import { FleetTextarea } from "@/components";
import style from "./index.module.less";
import { PlanTitle } from "../../components";
import { RouteDetailsProps } from "./type";
import useIndex from "./hooks/useIndex";
import classNames from "classnames";

const RouteDetails: React.FC<RouteDetailsProps> = (props) => {
	const { data, summaryData } = props;
	const routeNameList = summaryData?.map((item) => item.routeName);
	const {
		remarks,
		setRemarks,
		isEditable,
		setIsEditable,
		handleTextareaBlur
		// imgBase
	} = useIndex(props);

	return (
		<div className={classNames(["flag-no-break", style["vpr-route-details"]])}>
			<PlanTitle
				title="Route Details"
				showDot={false}
				iconChildren={
					<img
						crossOrigin="anonymous"
						className="vp-title-icon"
						src="/images/leg/plan/leg_plan_terms_icon.svg"
					/>
				}
			/>
			<div className="route-details-content">
				<div className="route-details-map">
					<img src={data?.imgUrl} crossOrigin="anonymous" />
					{/* <img src={imgBase} /> */}
				</div>
				<div className="route-details-remark">
					<div className="route-details-header">
						<p className="route-title">Routes Remarks</p>

						{isEditable ? (
							<FleetTextarea
								className="remark-content"
								placeholder=""
								value={remarks}
								onChange={(val) => setRemarks(val)}
								onBlur={handleTextareaBlur}
							/>
						) : (
							<div
								className="remark-content"
								onDoubleClick={() => setIsEditable(true)}
							>
								<p className="remark-inner">{remarks}</p>
							</div>
						)}
					</div>

					<div className="route-marker">
						<div className="primary-route-marker">
							<span className="marker-title">{routeNameList?.[0]}</span>
							<div className="marker-tag">
								<span className="marker-icon mt-light"></span>
								<span>Light</span>
							</div>
							<div className="marker-tag">
								<span className="marker-icon mt-moderate"></span>
								<span>Moderate</span>
							</div>
							<div className="marker-tag">
								<span className="marker-icon mt-heavy"></span>
								<span>Heavy</span>
							</div>
							<div className="marker-tag">
								<span className="marker-icon mt-severe"></span>
								<span>Severe</span>
							</div>
						</div>
						<div className="other-route-marker">
							{routeNameList?.slice(1).map((item, index) => {
								return (
									<div className="other-route-item" key={index}>
										<span>{item}</span>
										<span
											className={classNames({
												"solid-line-marker": index === 0,
												"dashed-line-marker": index === 1,
												"dotted-line-marker": index === 2
											})}
										></span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RouteDetails;
