import { cacheStorage } from "@/storage";
import { StorageKeyMap } from "@/storage/types";
export interface UserStore {
	access_token: string;
	userInfo?: Record<keyof any, any>;
	remember: boolean;
}

const userInfo = cacheStorage.get(StorageKeyMap["user_info"]);
const access_token = cacheStorage.get(StorageKeyMap["access_token"]);
const remember = cacheStorage.get(StorageKeyMap["remember"]);

export const initialUserState: UserStore = {
	access_token: access_token || "",
	userInfo: userInfo || {},
	remember: remember || false
};
