import React, { FC, useState } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { VoyLegTable } from "./components";
import { VoyBasicInfo, VoyCpSpdTable, VoyGwClause } from "@/pages/voy/common";
import useIndex from "./hooks/useIndex";
import LegAdd from "@/pages/leg/common/add";
import { Button, Toast, Popover } from "@/components";
import { EditVoy, CharterPartyDialog } from "../../components";
import { onRequest } from "@/api";

const VoyDetails = () => {
	const {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,

		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess,

		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,

		voyLegTableData,
		voyLegHandleDoubleClick,
		voyLegSelectedRowKeys,
		onVoyLegSelectChange,
		voyLegPager,
		onVoyLegPageChange,
		voyLegDataSource,
		voyLegOpereteColumns,

		handleDeleteClick,
		isEditHide,
		handleCloseAdd,
		handleEditSuccess,
		handleEditClick,
		handleOpenLegAdd,
		LegAddDialogVisible,
		handleCloseLegAdd,
		voyId,

		isDisabled,
		handleSaveClick,
		handleCancelClick,
		voyLegCacheSource
	} = useIndex();

	return (
		<div className={classnames("voy-common", style["voy-details"])}>
			{/* <EditVoy
				hide={isEditHide}
				onClose={handleCloseAdd}
				onEditSuccess={handleEditSuccess}
			/> */}

			<CharterPartyDialog
				hide={isHideCpDialog}
				onCpClose={onCpClose}
				onCpPickSuccess={onCpPickSuccess}
				vesselName={voyBasicInfoData.vesselName}
				imo={voyBasicInfoData.imo}
			/>

			<div className="total-ctrl">
				<Popover
					render={(setIsOpen) => {
						return (
							<Toast
								label="Are you sure delete this item?"
								onCancel={() => {
									setIsOpen(false);
								}}
								onConfirm={() => {
									setIsOpen(false);
									handleDeleteClick();
								}}
							/>
						);
					}}
				>
					<Button
						disabled={!isDisabled}
						prefixIcon={<span className="iconfont icon-delete"></span>}
						type="text"
					>
						Delete
					</Button>
				</Popover>
			</div>
			<div className="voy-info">
				<VoyBasicInfo
					item={voyBasicInfoData}
					onChange={handleChangeBasicInfoItem}
					onCommit={handleBasicInfoItemCommit}
					isDisabled={isDisabled}
					disabledField={["vesselName", "companyName"]}
				/>
				<VoyCpSpdTable
					fixWidth={1250}
					voyCpSpdTableData={voyCpSpdTableData}
					handleDoubleClick={handleDoubleClick}
					selectedRowKeys={selectedRowKeys}
					onSelectChange={onSelectChange}
					pager={pager}
					onPageChange={onPageChange}
					dataSource={dataSource}
					opereteColumns={opereteColumns}
					onChange={handleChangeVoyCpSpdTableItem}
					add={addVoyCpSpd}
					isDisabled={isDisabled}
					// isHideCpDialog={isHideCpDialog}
					// onCpClose={onCpClose}
					// onCpPickSuccess={onCpPickSuccess}
					// vesselName={voyBasicInfoData.vesselName}
					// imo={voyBasicInfoData.imo}
				/>
				<VoyGwClause
					item={voyGwClauseData}
					onChange={handleChangeVoyGwClauseItem}
					onCommit={handleVoyGwClauseItemCommit}
					isDisabled={isDisabled}
				/>

				{isDisabled ? (
					<div className="voy-ctrl">
						<Button
							prefixIcon={<span className="iconfont icon-edite"></span>}
							type="text"
							onClick={handleEditClick}
						>
							Edit
						</Button>
					</div>
				) : (
					<div className="voy-ctrl">
						<Button onClick={handleSaveClick}>Save</Button>
						<Button type="secondary" onClick={handleCancelClick}>
							Cancel
						</Button>
					</div>
				)}
			</div>
			<div className="leg-table">
				<VoyLegTable voyageId={voyId} />
			</div>
		</div>
	);
};

export default VoyDetails;
