import { loginOutAction } from "@/store/userSlice";
import { ParameterActionType, RequestApiMapType } from "./type";
import { LoginParamsType, LoginApiType, loginApiMap } from "./login";
import {
	PersonalCenterParamsType,
	personalApiMap,
	PersonalCenterApiType
} from "./personalCenter";
import {
	VoyagePageApiType,
	voyagePageApiMap,
	VoyagePageParamsType
} from "./voy";
import { LegPageApiType, LegPageParamsType, LegPageApiMap } from "./leg";
import { CommonApiType, CommonParamsType, CommonApiMap } from "./common";

export type RequestApiType =
	| LoginApiType
	| PersonalCenterApiType
	| VoyagePageApiType
	| LegPageApiType
	| CommonApiType;
type RequestParamsType =
	| LoginParamsType
	| PersonalCenterParamsType
	| VoyagePageParamsType
	| LegPageParamsType
	| CommonParamsType;

const requestActionMap: RequestApiMapType<RequestApiType, RequestParamsType> = {
	...loginApiMap,
	...personalApiMap,
	...voyagePageApiMap,
	...LegPageApiMap,
	...CommonApiMap
};

export const onRequest: ParameterActionType<
	RequestApiType,
	RequestParamsType
> = (
	type,
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	requestActionMap[type]?.(params)
		?.then((response) => {
			successCallback?.(response);
		})
		?.catch((err) => {
			errorCallback?.(err);
			console.log("err", err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};
