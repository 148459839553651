import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { VoyCpSpdTableItemType, VoyCpSpdTableProps } from "./type";
import { swellWaveAppliedOptions } from "./source";
import { Button, FleetInput, Table, Select, Dialog } from "@/components";
import { CharterPartyDialog } from "../../components";

const VoyCpSpdTable: React.FC<VoyCpSpdTableProps> = (props) => {
	// console.log("------------------------", props);
	const {
		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		onChange,
		add,
		isDisabled = false,
		fixWidth,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess,
		vesselName,
		imo
	} = props;

	return (
		<div
			className={classNames(style["fleet-voyage-cpspd"])}
			style={{ width: fixWidth !== undefined ? `${fixWidth}px` : "auto" }}
		>
			<div className="title-box">
				<div className="title">CP Terms</div>

				{!isDisabled && (
					<Button
						prefixIcon={<span className="iconfont icon-new"></span>}
						type="text"
						onClick={add}
					>
						Add
					</Button>
				)}
			</div>
			<Table
				columns={opereteColumns}
				id="id"
				className="cpspd-table"
				dataSource={dataSource}
				// rowSelection={{
				// 	selectedRowKeys,
				// 	onChange: onSelectChange
				// }}
				// pagination={{
				// 	current: pager.pageNum,
				// 	pageSize: pager.pageSize,
				// 	total: pager.total,
				// 	onChange: onPageChange,
				// 	showSizeChanger: false
				// }}
				scroll={null}
				pagination={false}
			/>
		</div>
	);
};

export default VoyCpSpdTable;
