import React from "react";
import { NoDataProps } from "./type";
import style from "./index.module.less";
import classNames from "classnames";

const NoData: React.FC<NoDataProps> = ({ message, className }) => {
	return (
		<div className={classNames(style["no-data-container"], className)}>
			{message ? message : "No data"}
		</div>
	);
};

export default NoData;
