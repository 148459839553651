import React, { FC } from "react";
import styles from "./index.module.less";
import { Button, Popover, Toast, Table } from "@/components";
import useIndex from "./hooks/useIndex";
import { CPTermsColumns } from "./source";
import LegAdd from "@/pages/leg/common/add";
import { FleetTitle, SplitPanel } from "../../common";
import { LegBasicInfo } from "../../components";
import classNames from "classnames";
import { LegDetailsBasicProp } from "./type";
import { PortRotation, GridTable } from "./components";
const LegDetailsBasic: FC<LegDetailsBasicProp> = ({
	activeTab,
	onLoadBasicPorts
}) => {
	const {
		details,
		voyageId,
		legAddDialogVisible,
		id,
		GWClauseData,
		editLegParams,
		handleDeleteLeg,
		handleCloseLegAdd,
		handleOpenLegAdd,
		initPageData,
		handlePortTimeSave
	} = useIndex(activeTab, onLoadBasicPorts);

	return (
		<div
			className={classNames(styles["leg-basic"], {
				[styles["leg-basic-activity"]]: activeTab === "basic"
			})}
		>
			<div className="leg-basic-info">
				<LegBasicInfo
					children={
						<div className="ves-button">
							<Button
								onClick={() => {
									handleOpenLegAdd();
								}}
							>
								Edit
							</Button>
							<Popover
								render={(setIsOpen) => {
									return (
										<Toast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												handleDeleteLeg();
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<Button>Delete</Button>
							</Popover>
						</div>
					}
				/>
				<div className="leg-basic-info-portRotation">
					<FleetTitle text="Port Rotation" />
					<PortRotation
						dataSource={details?.portRotation}
						onTimeSave={handlePortTimeSave}
					/>
				</div>
				<div className="leg-basic-info-CPTerms">
					<FleetTitle text="CP Terms" />
					<div className="leg-basic-info-CPTerms-table">
						<Table
							dataSource={details?.cp}
							columns={CPTermsColumns}
							bordered={true}
							id="id"
							scroll={{ x: 350 }}
							pagination={false}
							className="CPTerms-table"
						/>
					</div>
				</div>
				<div className="leg-basic-info-GWClause">
					<FleetTitle text="GW Clause" />
					<div className="leg-basic-info-GWClause-descriptions">
						<GridTable dataSource={GWClauseData} />
					</div>
				</div>
			</div>
			<LegAdd
				onClose={handleCloseLegAdd}
				visible={legAddDialogVisible}
				voyageId={voyageId}
				legId={id}
				portRotationList={details?.portRotation}
				basicInfoData={{
					...editLegParams?.voyageLegFO,
					condition: editLegParams?.voyageLegFO?.legType
				}}
				afterConfirm={initPageData}
			/>
		</div>
	);
};

export default LegDetailsBasic;
