import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "@/layout";
import { useAppSelector } from "@/hooks";
import { cacheStorage } from "@/storage";
import { LegDetails } from "@/pages";
// import { findRouter } from "@/tools/menus";

const PrivateRoute: React.FC<{
	type: "layout" | "legDetails";
}> = (props) => {
	const location = useLocation();
	const access_token = cacheStorage.get("access_token");
	const _access_token = useAppSelector((state) => state.UserStore.access_token);
	const { userInfo } = useAppSelector((state) => state.UserStore);

	if (!(access_token || _access_token)) {
		return <Navigate to="/login" />;
	}

	if (props?.type === "legDetails") {
		return <LegDetails />;
	}

	// if (!findRouter(location?.pathname, userInfo?.menus)) {
	//   return <Navigate to="/404" />
	// }

	// if (location?.pathname === "/layout/voyagemanage" || location?.pathname === "/layout/database") {
	//   return
	// }

	return <Layout />;
};

export default PrivateRoute;
