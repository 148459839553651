import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonToolbarSelectGroupProps } from "./type";
import { controllLayerOptions, metoeLayerOptions } from "./source";
import { Button, Radio } from "@/components";

const RouteCommonToolbarSelectGroup: FC<RouteCommonToolbarSelectGroupProps> = ({
	onSelect,
	onConfirm,
	selectRows
}) => {
	return (
		<div className={style["routeCommon-toolbar-selectGroup"]}>
			<div className="toolbar-selectGroup-wrap">
				<span className="toolbar-selectGroup-header">Weather Layers </span>
				<ul className="toolbar-selectGroup-container">
					{metoeLayerOptions?.map((layerItem, layerIndex) => {
						return (
							<Radio
								className="toolbar-selectGroup-radio"
								label={layerItem?.label}
								key={layerItem?.value}
								value={selectRows?.includes(layerItem?.value)}
								onChange={(checked) => {
									onSelect?.(layerItem?.value);
								}}
							/>
						);
					})}
				</ul>
			</div>
			<div className="toolbar-selectGroup-wrap">
				<span className="toolbar-selectGroup-header">Other Layers</span>
				<ul className="toolbar-selectGroup-container">
					{controllLayerOptions?.map((layerItem, layerIndex) => {
						return (
							<Radio
								className="toolbar-selectGroup-radio"
								label={layerItem?.label}
								key={layerItem?.value}
								value={selectRows?.includes(layerItem?.value)}
								onChange={(checked) => {
									onSelect?.(layerItem?.value);
								}}
							/>
						);
					})}
				</ul>
			</div>
			<div className="toolbar-selectGroup-footer">
				<Button size="small" onClick={onConfirm}>
					Confirm
				</Button>
			</div>
		</div>
	);
};

export default RouteCommonToolbarSelectGroup;
