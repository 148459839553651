import { Dialog, Icon } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { CpaDialogProps } from "./type";
const CpaDialog: FC<CpaDialogProps> = ({
	visible,
	simulationId,
	simulationForecastList,
	typhoonId,
	typhoonRoute,
	onClose
}) => {
	const { cpaRef, loading } = useIndex(
		simulationId,
		simulationForecastList,
		typhoonId,
		typhoonRoute
	);
	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={onClose}
			loading={loading}
		>
			<div className={styles["cpaDialog"]}>
				<div className="cpaDialog-header">
					<div>
						{typhoonId
							? `Distance from Center of ${typhoonId}`
							: "Please select typhoon"}
					</div>
					<div></div>
					<Icon type="icon-close2" className="close-btn" onClick={onClose} />
				</div>
				<div className="cpaDialog-main fleet-nonScrollBar">
					<div className={"graph"} ref={cpaRef}></div>
				</div>
				{/* <div className="cpaDialog-footer">
					<Button type="secondary" size="large" onClick={onClose}>
						Close
					</Button>
				</div> */}
			</div>
		</Dialog>
	);
};

export default CpaDialog;
