import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import {
	Button,
	FleetInput,
	FormItem,
	Select,
	FleetRangePicker,
	FleetDatePicker
} from "@/components";
import { CommonPortSelect } from "@/common/Select";
import {
	LegPortRotationListItemProps,
	LegPortRotationListItemType,
	LegPortRotationProps
} from "./type";
import dayjs, { type Dayjs } from "dayjs";
import { FleetUtcTimePicker } from "@/pages/leg/business/details/components";

const LegPortRotationListItem: React.FC<LegPortRotationListItemProps> = ({
	item,
	onChange,
	index
	// onCommit
}) => {
	return (
		<div className={classNames(style["fleetLeg-PortRotationItem"])}>
			<FormItem label={index === 0 ? "Dept Port:" : "Next Port:"} type="row">
				<CommonPortSelect
					activeItems={item?.port || {}}
					showKey={"portName"}
					primaryKey="portCode"
					onFinish={(newItem, operateType) => {
						console.log("port", newItem);
						onChange?.("port", {
							...item?.port,
							portCode: newItem.portCode,
							portName: newItem.portName
						});
					}}
					className="portName"
					disabled={item?.disable}
				/>
			</FormItem>

			{index === 0 && (
				<FormItem label="ETD:" type="row">
					{/* <FleetDatePicker
						onChange={(val, timeStramp) => {
							onChange?.("ETD", new Date(val).getTime());
						}}
						value={item?.ETD ? dayjs(item?.ETD) : null}
						disabled={item?.disable}
						showTime={true}
						showHour={true}
						showMinute={true}
						allowClear
					/> */}
					<FleetUtcTimePicker
						disabled={item?.disable}
						value={Number(item?.ETD)}
						onChange={(val) => {
							onChange?.("ETD", val);
						}}
					/>
				</FormItem>
			)}
		</div>
	);
};

const LegPortRotation: React.FC<LegPortRotationProps> = ({
	list,
	onChangeList
	// onCommit
}) => {
	return (
		<div className={classNames(style["fleetLeg-PortRotation"])}>
			<div>Port Rotation</div>
			<div className="portRotation-container fleet-nonScrollBar">
				{list.map((item, index) => {
					return (
						<div key={index} className="portRotation-item">
							<div className="portRotation-item-content">
								<span className="commonText">{index + 1}</span>
								<LegPortRotationListItem
									item={item}
									onChange={(key, value) => {
										onChangeList(index, "update", key, value);
									}}
									index={index}
								/>
							</div>
							<div className="portRotation-item-opration">
								{item.disable && (
									<Button
										onClick={() => {
											onChangeList(index, "update", "disable", !item.disable);
										}}
									>
										🖊
									</Button>
								)}
								{!item.disable && (
									<Button
										onClick={() => {
											onChangeList(index, "update", "disable", !item.disable);
										}}
									>
										✓
									</Button>
								)}
								{list.length === index + 1 && (
									<Button
										onClick={() => {
											onChangeList(index, "create");
										}}
									>
										+
									</Button>
								)}
								{list.length > 1 && (
									<Button
										onClick={() => {
											onChangeList(index, "delete");
										}}
									>
										-
									</Button>
								)}
								<div className="portRotation-item-opration-moveButton">
									{index > 1 && (
										<div
											onClick={() => {
												onChangeList(index, "move_up");
											}}
										>
											▲
										</div>
									)}
									{index > 0 && index < list.length - 1 && (
										<div
											onClick={() => {
												onChangeList(index, "move_down");
											}}
										>
											▼
										</div>
									)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default LegPortRotation;
