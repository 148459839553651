import { FileType } from "../type";

export const getFileType = (url: string): FileType => {
	const ext = url.substring(url.lastIndexOf(".")).toLowerCase();
	const imageExtensions = [
		".jpg",
		".jpeg",
		".png",
		".gif",
		".bmp",
		".svg",
		".webp"
	];
	const emailExtensions = [".eml"];
	const excelExtensions = [".xls", ".xlsx"];
	const wordExtensions = [".doc", ".docx"];
	const pdfExtensions = [".pdf"];

	if (imageExtensions.includes(ext)) {
		return "image";
	}
	if (emailExtensions.includes(ext)) {
		return "email";
	}
	if (excelExtensions.includes(ext)) {
		return "excel";
	}
	if (wordExtensions.includes(ext)) {
		return "word";
	}
	if (pdfExtensions.includes(ext)) {
		return "pdf";
	}

	return "unknown";
};
