import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const htmlToA4Pdf = async (element: HTMLElement, filename: string) => {
	// 获取元素的实际高度（包括滚动部分）
	// const elementHeight = element.scrollHeight;

	// // 创建一个足够大的画布
	// const canvas = document.createElement("canvas");
	// const context = canvas.getContext("2d");

	// // 设置画布的宽度和高度
	// canvas.width = element.clientWidth;
	// canvas.height = elementHeight;

	// 将元素绘制到画布上
	// context.drawImage(
	// 	await html2canvas(element, {
	// 		// scrollY: element.scrollTop,
	// 		scale: 4
	// 	}),
	// 	0,
	// 	0,
	// 	canvas.width,
	// 	canvas.height
	// );

	// canvas
	const canvas = await html2canvas(element, {
		scale: 2
	});
	const imgData = canvas.toDataURL("image/png");

	const pdf = new jsPDF({
		orientation: "p", // 纵向
		unit: "mm", // 单位为毫米
		format: "a4" // A4 纸大小
	});
	const pdfWidth = pdf.internal.pageSize.getWidth();
	const pdfHeight = pdf.internal.pageSize.getHeight();

	// 计算图像的缩放比例
	const imgProps = pdf.getImageProperties(imgData);
	const imgWidth = imgProps.width;
	const imgHeight = imgProps.height;
	const ratio = pdfWidth / imgWidth;
	const scaledImgWidth = imgWidth * ratio;
	const scaledImgHeight = imgHeight * ratio;

	// 计算图像的位置，使其居中
	const posX = (pdfWidth - scaledImgWidth) / 2;
	const posY = 0;

	// 添加图像到 PDF 中
	let currentPosY = 0;
	while (currentPosY < scaledImgHeight) {
		// 添加新页面
		if (currentPosY > 0) {
			pdf.addPage();
		}

		// 当前页面的图像高度
		const currentPageHeight = Math.min(
			scaledImgHeight - currentPosY,
			pdfHeight
		);

		// 截取当前页面的图像部分
		const croppedCanvas = document.createElement("canvas");
		croppedCanvas.width = canvas.width;
		croppedCanvas.height = currentPageHeight / ratio;
		const croppedContext = croppedCanvas.getContext("2d")!;
		croppedContext.drawImage(
			canvas,
			0,
			currentPosY / ratio,
			canvas.width,
			currentPageHeight / ratio,
			0,
			0,
			canvas.width,
			currentPageHeight / ratio
		);

		// 将截取的图像转换为数据 URL
		const croppedImageData = croppedCanvas.toDataURL("image/png");

		// 添加截取的图像到当前页面
		pdf.addImage(
			croppedImageData,
			"PNG",
			posX,
			posY,
			scaledImgWidth,
			currentPageHeight
		);

		// 更新当前位置
		currentPosY += currentPageHeight;

		// 清除临时画布
		croppedCanvas.remove();
	}

	// 保存 PDF 文件
	pdf.save(`${filename}.pdf`);

	// 移除原始画布
	canvas.remove();
};

export const htmlToPdf = (
	element: HTMLElement,
	filename: string,
	splitClassName: string
) => {
	const A4_WIDTH = 595;
	const A4_HEIGHT = 842;
	let pageHeight = 0;
	let pageNum = 1;

	const _domEach = (element: Element) => {
		[...element.children].forEach((childElement) => {
			if (childElement.className.includes(splitClassName)) {
				const elementRect = element.getBoundingClientRect();
				const childElementRect = childElement.getBoundingClientRect();
				const distanceTop = childElementRect.top - elementRect.top;

				// 当前元素应该在哪一页
				const currentPage = Math.ceil(
					(childElementRect.bottom - elementRect.top) / pageHeight
				);

				if (pageNum < currentPage) {
					pageNum++;
					const newDivElement = document.createElement("div");
					newDivElement.className = "EMPTY_DIV";
					newDivElement.style.background = "#fff";
					newDivElement.style.height =
						pageHeight * (pageNum - 1) - distanceTop + "px";
					newDivElement.style.width = "100%";

					// 判断是否存在下一个同级节点
					// if (childElement.nextSibling) {
					// 	element.appendChild(newDivElement);
					// } else {
					// 	element.insertBefore(newDivElement, childElement);
					// }

					element.insertBefore(newDivElement, childElement);
				}
			}
			if (childElement.childNodes.length) {
				_domEach(childElement);
			}
		});
	};

	return new Promise((resolve) => {
		pageHeight = (A4_HEIGHT / A4_WIDTH) * element.scrollWidth; // ph / pw = ah / aw
		element.style.height = "max-content";
		_domEach(element);
		const canvasElement = document.createElement("canvas");
		let abs = 0;
		// 获取当前窗口的宽度(不包括滚动条)
		const vpWidth =
			document.documentElement.clientWidth || document.body.clientWidth;
		// 获取当前窗口的宽度(包括滚动条)
		const winWidth = window.innerWidth;
		// 判断是否存在滚动条
		if (winWidth - vpWidth > 0) {
			abs = (winWidth - vpWidth) / 2;
		}

		// 画布 * 2
		canvasElement.width = element.offsetWidth * 2;
		canvasElement.height = element.scrollHeight * 2;

		const ctx = canvasElement.getContext("2d");
		ctx.scale(2, 2); // 增强图片清晰度
		ctx.translate(-element.offsetLeft - abs, -element.offsetTop);

		debugger;
		html2canvas(element, { useCORS: true }).then((canvas) => {
			debugger;
			pageHeight = (A4_HEIGHT / A4_WIDTH) * canvas.width;
			// 定义未生成pdf的html页面高度
			let htmlHeight = canvas.height;
			// 定义页面偏移
			let position = 0;
			// 定义html页面生成的canvas在pdf中图片的宽高
			const imgWidth = A4_WIDTH;
			// ih / iw = ch / cw
			const imgHeight = (canvas.height / canvas.width) * imgWidth;
			const pageData = canvas.toDataURL("image/jpeg", 1.0);
			const pdf = new jsPDF({
				unit: "pt",
				format: "a4"
			});
			// 判断是否需要分页
			if (htmlHeight < pageHeight) {
				pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
			} else {
				while (htmlHeight > 0) {
					pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
					htmlHeight -= pageHeight;
					position -= A4_HEIGHT;
					// 避免添加空白页
					if (htmlHeight > 0) {
						pdf.addPage();
					}
				}
			}
			pdf.save(filename, { returnPromise: true }).then(() => {
				const emptyDivElList = document.querySelectorAll(".EMPTY_DIV");
				for (let i = 0; i < emptyDivElList.length; i++) {
					emptyDivElList[i].remove();
				}
			});
			element.style.height = "";
			resolve(true);
		});
	});
};

export const htmlToMultiPagePdf = (printDom: HTMLElement, filename: string) => {
	console.log("htmlToMultiPagePdf===");
	const A4_WIDTH = 592.28;
	const A4_HEIGHT = 841.89;
	// 根据A4的宽高计算DOM页面一页应该对应的高度
	let pageHeight = (printDom.offsetWidth / A4_WIDTH) * A4_HEIGHT;
	// 将所有不允许被截断的元素进行处理
	let wholeNodes: NodeListOf<HTMLElement> =
		document.querySelectorAll(".flag-no-break");
	for (let i = 0; i < wholeNodes.length; i++) {
		// 注意： offsetTop是相对于其最近的定位祖先元素的高度，需要保证该元素为printDom
		const nodeTopHeight = wholeNodes[i].offsetTop;

		const topPageNum = Math.ceil(nodeTopHeight / pageHeight);
		const bottomPageNum = Math.ceil(
			(nodeTopHeight + wholeNodes[i].offsetHeight) / pageHeight
		);
		// 元素在截断在两页显示
		if (topPageNum !== bottomPageNum) {
			// 插入空白块，将元素挤压到下一页展示
			const divParent = wholeNodes[i].parentNode;
			const newBlock = document.createElement("div");
			newBlock.className = "emptyDiv";
			newBlock.style.background = "#fff";
			const needOffsetHeight = topPageNum * pageHeight - nodeTopHeight;
			const safeHeight = 20; // 避免元素太靠边
			newBlock.style.height = needOffsetHeight + safeHeight + "px";
			divParent.insertBefore(newBlock, wholeNodes[i]);
		}
	}
	html2canvas(printDom, {
		scale: 2
	}).then((canvas) => {
		//删除空白块
		let emptyDivs = document.querySelectorAll(".emptyDiv");
		for (let i = 0; i < emptyDivs.length; i++) {
			emptyDivs[i].parentNode.removeChild(emptyDivs[i]);
		}
		// 生成PDF需要的参数
		let pageData = canvas.toDataURL("image/jpeg", 1.0);
		let contentWidth = canvas.width;
		let contentHeight = canvas.height;
		let pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT;
		let leftHeight = contentHeight;
		let position = 0;
		let imgWidth = A4_WIDTH;
		let imgHeight = (A4_WIDTH / contentWidth) * contentHeight;

		let PDF = new jsPDF("p", "pt", "a4");
		// 单页
		if (leftHeight <= pageHeight) {
			PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
		} else {
			// 多页
			while (leftHeight > 0) {
				PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
				leftHeight -= pageHeight;
				position -= A4_HEIGHT;
				if (leftHeight > 0) {
					PDF.addPage();
				}
			}
		}
		PDF.save(filename + ".pdf");
	});
};

export const htmlsToMultiPagePdf = async (
	printDoms: HTMLElement[],
	filename: string
) => {
	console.log("htmlsToMultiPagePdf===");
	const A4_WIDTH = 592.28;
	const A4_HEIGHT = 841.89;
	const PDF = new jsPDF("p", "pt", "a4");

	for (const printDom of printDoms) {
		// 根据 A4 的宽高计算 DOM 页面一页应该对应的高度
		let pageHeight = (printDom.offsetWidth / A4_WIDTH) * A4_HEIGHT;

		// 将所有不允许被截断的元素进行处理
		let wholeNodes: NodeListOf<HTMLElement> =
			printDom.querySelectorAll(".flag-no-break");

		for (let i = 0; i < wholeNodes.length; i++) {
			const nodeTopHeight = wholeNodes[i].offsetTop;

			const topPageNum = Math.ceil(nodeTopHeight / pageHeight);
			const bottomPageNum = Math.ceil(
				(nodeTopHeight + wholeNodes[i].offsetHeight) / pageHeight
			);

			if (topPageNum !== bottomPageNum) {
				const divParent = wholeNodes[i].parentNode;
				const newBlock = document.createElement("div");
				newBlock.className = "emptyDiv";
				newBlock.style.background = "#fff";
				const needOffsetHeight = topPageNum * pageHeight - nodeTopHeight;
				const safeHeight = 20; // 避免元素太靠边
				newBlock.style.height = `${needOffsetHeight + safeHeight}px`;
				divParent.insertBefore(newBlock, wholeNodes[i]);
			}
		}

		const canvas = await html2canvas(printDom, { scale: 2 });
		// 删除空白块
		let emptyDivs = document.querySelectorAll(".emptyDiv");
		for (let i = 0; i < emptyDivs.length; i++) {
			emptyDivs[i].parentNode.removeChild(emptyDivs[i]);
		}

		// 生成 PDF 需要的参数
		let pageData = canvas.toDataURL("image/jpeg", 1.0);
		let contentWidth = canvas.width;
		let contentHeight = canvas.height;
		pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT;
		let leftHeight = contentHeight;
		let position = 0;
		let imgWidth = A4_WIDTH;
		let imgHeight = (A4_WIDTH / contentWidth) * contentHeight;

		// 单页
		if (leftHeight <= pageHeight) {
			PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
		} else {
			// 多页
			while (leftHeight > 0) {
				PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
				leftHeight -= pageHeight;
				position -= A4_HEIGHT;
				if (leftHeight > 0) {
					PDF.addPage();
				}
			}
		}

		// 如果不是最后一个元素，则创建新的页面以准备添加下一个元素
		if (printDoms.indexOf(printDom) < printDoms.length - 1) {
			PDF.addPage();
		}
	}

	// 所有元素处理完毕后保存 PDF
	PDF.save(filename + ".pdf");
};
