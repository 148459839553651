import React, { FC } from "react";
import classnames from "classnames";
import commonStyle from "../../../../index.module.less";
import style from "./index.module.less";
import { CommonVesselItemType, CommonVesselPopupProps } from "./type";
import { Loading } from "@/components";

const VesselItem: FC<CommonVesselPopupProps> = ({
	list,
	loading,
	onSelect,
	loadMore
}) => {
	const handleSelect = (
		e: React.MouseEvent<HTMLUListElement, MouseEvent>,
		item: CommonVesselPopupProps["list"][number]
	) => {
		e?.stopPropagation();
		onSelect?.(item);
	};

	return (
		<div
			className={classnames(
				commonStyle["common-select--Popup"],
				style["common-contactSelect--Popup"]
			)}
		>
			<Loading loading={loading} />
			<div className="select--popup--container fleet-nonScrollBar">
				<div className="select--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="select--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item);
								}}
							>
								<li>{`${item?.vesselName}(${item?.imo})`}</li>
							</ul>
						);
					})}
				</div>
			</div>
			<div className="select--popup--loadMore" onClick={loadMore}>
				<span>Load More</span>
				<span className="font_family fleet-back"></span>
			</div>
		</div>
	);
};

export default VesselItem;
