export enum ArrivalShipStatusMap {
	// 0 未知
	Unknown,
	// 1（在海）重载运行
	Laden,
	// 2（在海）空载运行
	Ballast,
	// 3（在港）装货
	Loading,
	// 4（在港）卸货
	Discharging,
	// 5（在港）等待装货
	"Waiting Loading",
	// 6（在港）等待卸货
	"Waiting Discharging",
	// 7（在港）修船
	Dockyard
}

// 状态
export enum ArrivalStatusMap {
	Orderbook = 1,
	"In Service",
	Repair,
	Storage,
	"Laid Up",
	Abnormal,
	FPSO,
	Decommisioned
}

export type AisCurrentStateItemType = {
	course: number;
	desAisPort: string;
	desPortCode: string;
	draft: number;
	eta: number;
	heading: number;
	imo: string;
	lat: number;
	lon: number;
	mmsi: string;
	positionStatus: number;
	rot: number;
	shipStatus: ArrivalShipStatusMap;
	speed: number;
	status: ArrivalStatusMap;
	updateTime: number;
	vesselName: string;
};

export type AisTrackPointItemType = {
	id: string;
	cog: number;
	isWarning: number;
	lat: number;
	lon: number;
	sog: number;
	speed: number;
	time: number;
};

export type RouteCommonAisDataSourceType = {
	current: AisCurrentStateItemType;
	route: {
		ais: GeoJSON.FeatureCollection<
			GeoJSON.MultiLineString | GeoJSON.LineString,
			{}
		>;
		trackPoints: AisTrackPointItemType[];
	};
};
