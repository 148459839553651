import React from "react";
import style from "./index.module.less";
import { ReportFormulaProps } from "./type";

const ReportFormula: React.FC<ReportFormulaProps> = ({
	label,
	value1,
	operator1,
	value2,
	operator2,
	result
}) => {
	return (
		<div className={style["reportFormula"]}>
			{label && <span className="label">{label}</span>}
			<span className="equal">=</span>
			{value1 && <span className="value1">{value1}</span>}
			{operator1 && <span className="operator1">{operator1}</span>}
			{value2 && <span className="value2">{value2}</span>}
			<span className="operator2">=</span>
			{result && <span className="result">{result}</span>}
		</div>
	);
};

export default ReportFormula;
