import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import debounce from "lodash/debounce";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonIdType } from "@/api/leg/type";
import { LegDetailsMenuItemType } from "../../../components/siderMenu/type";
import { DevReportParamsType } from "../components/addDevReportDialog/type";
import { ReportItemType, DistType, CfType, PortType } from "../type";
import { DataType } from "@/components/Table";
import { ColumnsType } from "antd/es/table";
import { Icon } from "@/components";

const useIndex = (activeTab: LegDetailsMenuItemType) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const location = useLocation();

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");
	const legType = queryParams.get("legType");
	// state
	const [pdfDialogVisible, setReportPdfDialogVisible] =
		useState<boolean>(false);
	const [deviationVisible, setDeviationVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [reportList, setReportList] = useState<ReportItemType[]>([]);

	const [distType, setDistType] = useState<DistType>("Reported");
	const [cfType, setCfType] = useState<CfType>("Daily");
	const [fromList, setFromList] = useState<PortType[]>([]);
	const [fromPortId, setFromPortId] = useState<string>("");
	const [toList, setToList] = useState<PortType[]>([]);
	const [toPortId, setToPortId] = useState<string>("");
	const [addDevDialogVisible, setAddDevDialogVisible] =
		useState<boolean>(false);

	const fromListPortOptions = useMemo(() => {
		return fromList.map((item) => ({ label: item.portName, value: item.id }));
	}, [fromList]);
	const toListPortOptions = useMemo(() => {
		return toList.map((item) => ({ label: item.portName, value: item.id }));
	}, [toList]);

	const opColumns: ColumnsType<DataType> = [
		{
			title: "",
			dataIndex: "index",
			key: "index",
			fixed: "right",
			width: 50,
			render(value, record, index) {
				return (
					<span className={"table-row-operation"}>
						<Icon
							type="icon-report"
							onClick={() => {
								console.log("看看record", record);
								handleReportPreview(
									record.status,
									record.id,
									record.reportType
								);
							}}
						></Icon>
						<Icon
							type="icon-refresh"
							onClick={() => handleReportRefresh(index, record.id)}
						></Icon>
					</span>
				);
			}
		}
	];

	// 当前选择报告id
	const [curReportId, setCurReportId] = useState<string>("");

	const handleDistTypeChange = (val: DistType) => {
		setDistType(val);
	};
	const handleCfTypeChange = (val: CfType) => {
		setCfType(val);
	};

	// handle
	const handleReportPdfDialogVisible = () => {
		setReportPdfDialogVisible(!pdfDialogVisible);
	};
	const handleDeviationVisibleChange = () => {
		setDeviationVisible(!deviationVisible);
	};
	const initPage = () => {
		getReportListApi({
			id: id
		});
		getReportCondition();
	};

	// api
	// api - getReportListApi
	const getReportListApiFront = () => {
		setLoading(true);
	};
	const getReportListApiSuccess = (data) => {
		setReportList(data.data);
		setLoading(false);
	};
	const getReportListApiFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getReportListApi = (params: CommonIdType) => {
		onRequest(
			"getReportListApi",
			params,
			getReportListApiFront,
			getReportListApiSuccess,
			getReportListApiFailed,
			dispatch,
			navigate
		);
	};

	const generateAnalysisReportFront = () => {
		setLoading(true);
	};
	const generateAnalysisReportSuccess = (data) => {
		setLoading(false);
		reminder("success", "开始创建报告");
		initPage();
	};
	const generateAnalysisReportError = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		initPage();
	};

	// 创建报告
	const handleReportCreate = () => {
		console.log("distType", distType);
		console.log("cfType", cfType);

		onRequest(
			"generateAnalysisReportApi",
			{
				legId: id,
				cfType: cfType === "Daily" ? 0 : 1,
				distType: distType === "Reported" ? 0 : 1,
				fromPort: fromPortId,
				toPort: toPortId
			},
			generateAnalysisReportFront,
			generateAnalysisReportSuccess,
			generateAnalysisReportError,
			dispatch,
			navigate
		);
	};

	// 刷新报告
	const handleReportRefresh = (index: number, id: string) => {
		onRequest(
			"updateReportStatusApi",
			{ id },
			updateReportStatusFront,
			(data) => updateReportStatusSuccess(index, data),
			updateReportStatusError,
			dispatch,
			navigate
		);
	};
	const updateReportStatusFront = () => {
		setLoading(true);
	};
	const updateReportStatusSuccess = (index: number, { data }) => {
		setLoading(false);
		setReportList((prev) => {
			prev[index].status = data;
			return prev;
		});
	};
	const updateReportStatusError = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	// 预览报告
	const handleReportPreview = (status: number, id: string, type: string) => {
		switch (status) {
			case -1:
				reminder("error", "无法预览创建失败的报告");
				break;
			case 0:
				reminder("error", "无法预览正在创建中的报告");
				break;
			case 1:
				setCurReportId(id);
				type === "Perf."
					? handleReportPdfDialogVisible()
					: handleDeviationVisibleChange();
				break;
		}
	};

	const getReportConditionFront = () => {};
	const getReportConditionSuccess = (data) => {
		console.log("报文段条件", data);
		setFromList(data.data.fromList);
		setToList(data.data.toList);
	};
	const getReportConditionError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	// 获取报告报文段条件
	const getReportCondition = () => {
		onRequest(
			"getReportConditionApi",
			{ id },
			getReportConditionFront,
			getReportConditionSuccess,
			getReportConditionError,
			dispatch,
			navigate
		);
	};

	const handleAddDevVisibleChange = (visible: boolean) => {
		setAddDevDialogVisible(visible);
	};

	const generateDevReportFront = () => {
		setLoading(true);
	};
	const generateDevReportSuccess = (data) => {
		handleAddDevVisibleChange(false);
		setLoading(false);
		reminder("success", "开始创建报告");
		initPage();
	};
	const generateDevReportError = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		initPage();
	};

	const handleAddDevReport = (data: DevReportParamsType) => {
		onRequest(
			"generateDevReportApi",
			data,
			generateDevReportFront,
			generateDevReportSuccess,
			generateDevReportError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeTab !== "report") {
			return;
		}
		initPage();
	}, [activeTab]);

	// useEffect(() => {
	// 	if (curReportId) {
	// 		handleReportPdfDialogVisible();
	// 	}
	// }, [curReportId]);

	return {
		loading,
		reportList,
		pdfDialogVisible,
		deviationVisible,
		handleReportPdfDialogVisible,
		handleDeviationVisibleChange,
		distType,
		cfType,
		handleDistTypeChange,
		handleCfTypeChange,
		handleReportCreate,
		opColumns,
		curReportId,
		fromPortId,
		setFromPortId,
		toPortId,
		setToPortId,
		fromListPortOptions,
		toListPortOptions,
		addDevDialogVisible,
		handleAddDevVisibleChange,
		handleAddDevReport
	};
};

export default useIndex;
