import { useEffect, useState } from "react";
import {
	RouteCommonRangingControllTabType,
	RouteCommonToolbarControllItemType,
	RouteCommonToolbarControllKeys,
	RouteCommonToolbarMeteoSwitchMap,
	RouteCommonToolbarProps
} from "../type";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { onRequest } from "@/api";
import {
	initialToolbalItems,
	RouteCommonToolbarListProps,
	RouteCommonToolbarMap
} from "../components";
import { controllLayerMap } from "../source";
import { ExcludeKeys } from "@/types/common";

const useIndex = (
	meteoStatus: RouteCommonToolbarProps["meteoStatus"],
	onControllChange: RouteCommonToolbarProps["onControllChange"],
	onRangeSelect: RouteCommonToolbarProps["onRangeSelect"],
	onPointSelect: RouteCommonToolbarProps["onPointSelect"],
	onMeteoStatusChange: RouteCommonToolbarProps["onMeteoStatusChange"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<{
		meteoItems: RouteCommonToolbarListProps["items"];
		controllItems: RouteCommonToolbarListProps["items"];
	}>({
		meteoItems: [],
		controllItems: []
	});

	const [selectRows, setSelectRows] = useState<
		(keyof typeof RouteCommonToolbarMap)[]
	>([]);

	const [controllStatus, setControllStatus] = useState<
		(keyof typeof RouteCommonToolbarMap)[]
	>([]);

	const [activeRangeIndex, setActiveRangeIndex] =
		useState<RouteCommonRangingControllTabType>(null);

	const [pointStatus, setPointStatus] = useState<boolean>(false);

	const getMapLayersFront = () => {
		setLoading(true);
	};

	const getMapLayersSuccess = (response) => {
		setDataSource(initialToolbalItems(response?.data?.layer ?? []));
		setSelectRows(response?.data?.layer ?? []);
		setLoading(false);
	};

	const getMapLayersFailed = (error) => {
		console.log("error", error);
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const getMapLayers = () => {
		onRequest(
			"getMapLayersApi",
			null,
			getMapLayersFront,
			getMapLayersSuccess,
			getMapLayersFailed,
			dispatch,
			navigate
		);
	};

	const handleResetLayers = () => {
		const tempControllStatus: (keyof typeof RouteCommonToolbarMap)[] = [];
		for (let layer of controllStatus) {
			if (!selectRows?.includes(layer)) {
				handleSelect(layer);
			} else {
				tempControllStatus?.push(layer);
			}
		}
		for (let [layerName, leyerStatus] of Object?.entries(meteoStatus)) {
			if (
				leyerStatus === RouteCommonToolbarMeteoSwitchMap.On &&
				!selectRows?.includes(layerName as keyof typeof RouteCommonToolbarMap)
			) {
				handleSelect?.(layerName as keyof typeof RouteCommonToolbarMap);
			}
		}
		setControllStatus(tempControllStatus);
	};

	const updateMapLayersSuccess = (response) => {
		handleResetLayers();
		setOpen(false);
		getMapLayers();
	};

	const updateMapLayers = () => {
		onRequest(
			"updateMapLayersApi",
			{ layer: selectRows },
			getMapLayersFront,
			updateMapLayersSuccess,
			getMapLayersFailed,
			dispatch,
			navigate
		);
	};

	const handleOpen = (val: boolean) => {
		if (!val) {
			setSelectRows([...dataSource?.meteoItems, ...dataSource?.controllItems]);
		}
		setOpen(val);
	};

	const handleGroupSelect = (value: keyof typeof RouteCommonToolbarMap) => {
		setSelectRows((prev) => {
			const index = prev?.findIndex((item) => item === value);
			if (index === -1) {
				prev?.push(value);
			} else {
				prev?.splice(index, 1);
			}
			return [...prev];
		});
	};

	const handleControllChange: RouteCommonToolbarListProps["onSelect"] = (
		value
	) => {
		setControllStatus((prev) => {
			const index = prev?.findIndex((selectItem) => selectItem === value);
			if (index === -1) {
				prev?.push(value);
			} else {
				prev?.splice(index, 1);
			}
			onControllChange?.(controllLayerMap[value], index === -1);
			return [...prev];
		});
	};

	const handleRangeSelect: RouteCommonToolbarProps["onRangeSelect"] = (
		type
	) => {
		switch (true) {
			case !type:
			case activeRangeIndex === type:
				setActiveRangeIndex(null);
				break;
			default:
				setActiveRangeIndex(type);
				break;
		}
		onRangeSelect?.(activeRangeIndex === type ? null : type);
	};

	const handleSelect: RouteCommonToolbarListProps["onSelect"] = (value) => {
		if (
			["Wind", "Wave", "Swell", "Current", "Typhoon", "Pressure"]?.includes(
				value
			)
		) {
			onMeteoStatusChange?.(
				value as ExcludeKeys<
					keyof typeof RouteCommonToolbarMap,
					"Loadline" | "Ports" | "War" | "Eca" | "ENC"
				>
			);
			return;
		}
		handleControllChange?.(value);
	};

	const handlePointSelect: RouteCommonToolbarProps["onPointSelect"] = (
		value
	) => {
		setPointStatus(value);
		onPointSelect?.(value);
	};

	const handleKeyUp = (event: KeyboardEvent) => {
		console.log("event", event);
		if (event.key === "Escape") {
			debugger;
			handleRangeSelect?.(null);
			event?.preventDefault();
		}
	};

	useEffect(() => {
		document.addEventListener("keyup", handleKeyUp, false);
		getMapLayers();
		return () => {
			document.removeEventListener("keyup", handleKeyUp, false);
		};
	}, []);

	return {
		open,
		selectRows,
		dataSource,
		activeRangeIndex,
		controllStatus,
		handleControllChange,
		handleRangeSelect,
		pointStatus,
		handlePointSelect,
		handleSelect,
		handleOpen,
		handleGroupSelect,
		updateMapLayers
	};
};

export default useIndex;
