import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import {
	cpItemType,
	TabKeyType,
	VesselCurrentState,
	PilotDataType,
	VesselPositionDataType
} from "../type";
import { SimulationDataType } from "../type";
import { CommonChangeEvent } from "@/types/event";
import { SelectOption } from "@/components/Select";
import { CommonIdType, RouteSimulationParamsType } from "@/api/leg/type";
const useIndex = (closeDialog, resetSimulationList, routeId: string) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const voyageId = queryParams.get("voyageId");
	const legType = queryParams.get("legType");
	const imo = queryParams.get("imo");

	// state
	const [loading, setLoading] = useState(false);
	const [pilotData, setPilotData] = useState<PilotDataType>({
		instructedSpeed: null,
		etd: null,
		cpId: null,
		isRouteParameters: false
	});
	const [vesselPositionData, setVesselPositionData] =
		useState<VesselPositionDataType>({
			lon: 0,
			lat: 0,
			instructedSpeed: null,
			time: null,
			cpId: null,
			isRouteParameters: false
		});
	// const [simulationData, setSimulationData] = useState<SimulationDataType>();
	const [tabActiveKey, setTabActiveKey] = useState<TabKeyType>("Pilot");
	const [cpList, setCpList] = useState<SelectOption[]>([]);
	const [etd, setEtd] = useState<number>(null);
	const [vesselCurrentState, setVesselCurrentState] =
		useState<VesselCurrentState>();

	// handle
	const hanleCloseDialog = () => {
		closeDialog && closeDialog();
	};
	const handleConfirm = (planId: string) => {
		// const type = tabActiveKey === "Pilot" ? "Pilot" : "Vessel Position";
		const params =
			tabActiveKey === "Pilot"
				? {
						type: tabActiveKey,
						instructedSpeed: pilotData.instructedSpeed,
						etd: pilotData.etd,
						cpId: pilotData.cpId,
						planId,
						isRouteParameters: pilotData.isRouteParameters
					}
				: {
						type: tabActiveKey,
						instructedSpeed: vesselPositionData.instructedSpeed,
						etd: vesselPositionData.time,
						cpId: vesselPositionData.cpId,
						planId,
						lon: vesselPositionData.lon,
						lat: vesselPositionData.lat,
						isRouteParameters: vesselPositionData.isRouteParameters
					};

		routeSimulation(params);
	};

	// const handleChangeSimulation: CommonChangeEvent<SimulationDataType> = (
	// 	key,
	// 	value
	// ) => {
	// 	const newSimulationData = { ...simulationData, [key]: value };
	// 	setSimulationData(newSimulationData);
	// };

	const handlePilotDataChange: CommonChangeEvent<PilotDataType> = (
		key,
		value
	) => {
		setPilotData((prev) => {
			return { ...prev, [key]: value };
		});
	};

	const handleVesselPositionDataChange: CommonChangeEvent<
		VesselPositionDataType
	> = (key, value) => {
		setVesselPositionData((prev) => {
			return { ...prev, [key]: value };
		});
	};

	const handleClickTab = (activeKey: TabKeyType) => {
		setTabActiveKey(activeKey);
	};

	// Api
	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	// Api - getCpListApi
	// const getCpListFront = () => {
	// 	setLoading(true);
	// };
	const getCpListSuccess = (data) => {
		const cpList = data?.data?.map((item: cpItemType) => {
			return {
				label: `${item?.loadCondition}/${item?.speedCategory}[SPD ${item?.speed} | FO ${item?.foConsumption} | DGO ${item?.dgoConsumption}]`,
				value: item.id
			};
		});
		setCpList(cpList);
		handlePilotDataChange("cpId", cpList[0]?.value);
		handleVesselPositionDataChange("cpId", cpList[0]?.value);
		setLoading(false);
	};
	// const getCpListFailed = (error) => {
	// 	reminder(
	// 		"error",
	// 		error?.msg ? error?.msg + ": " + error?.data : error?.data
	// 	);
	// 	setLoading(false);
	// };

	const getCpList = () => {
		onRequest(
			"getCpListApi",
			{ voyageId: voyageId, legType: legType },
			handleRequestFront,
			getCpListSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};
	// Api - getLocationByImoApi
	// const getLocationByImoFront = () => {
	// 	setLoading(true);
	// };
	const getLocationByImoSuccess = (data) => {
		setVesselCurrentState(data?.data);
		setVesselPositionData((prev) => {
			return {
				...prev,
				lon: data?.data?.lon ?? 0,
				lat: data?.data?.lat ?? 0,
				time: data?.data?.updateTime
			};
		});

		// setSimulationData({
		// 	...simulationData,
		// 	lon: data?.data?.lon,
		// 	lat: data?.data?.lat,
		// 	etd: data?.data?.updateTime
		// });
		// setLoading(false);
	};
	// const getLocationByImoFailed = (error) => {
	// 	reminder(
	// 		"error",
	// 		error?.msg ? error?.msg + ": " + error?.data : error?.data
	// 	);
	// 	setLoading(false);
	// };
	const getLocationByImo = (params: CommonIdType) => {
		onRequest(
			"getLocationByImoApi",
			params,
			handleRequestFront,
			getLocationByImoSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};
	// Api - routeSimulationApi
	const routeSimulationFront = () => {
		setLoading(true);
	};
	const routeSimulationSuccess = () => {
		closeDialog && closeDialog();
		resetSimulationList && resetSimulationList();
		setLoading(false);
	};
	const routeSimulationFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const routeSimulation = (params) => {
		onRequest(
			"routeSimulationApi",
			params,
			routeSimulationFront,
			routeSimulationSuccess,
			routeSimulationFailed,
			dispatch,
			navigate
		);
	};

	// APi - getEtdByRouteIdApi
	const getEtdByRouteIdSuccess = (data) => {
		handlePilotDataChange("etd", data.data);
	};

	const getEtdByRouteId = () => {
		onRequest(
			"getEtdByRouteIdApi",
			{ id: routeId },
			handleRequestFront,
			getEtdByRouteIdSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	console.log("看一下这是什么数据", vesselCurrentState);

	useEffect(() => {
		getCpList();
		getLocationByImo({ id: imo });
		getEtdByRouteId();
	}, []);
	return {
		loading,
		tabActiveKey,
		cpList,
		// simulationData,
		pilotData,
		vesselPositionData,
		vesselCurrentState,
		hanleCloseDialog,
		handleClickTab,
		// handleChangeSimulation,
		handlePilotDataChange,
		handleVesselPositionDataChange,

		handleConfirm
	};
};

export default useIndex;
