import React from "react";
import style from "./index.module.less";
import useIndex from "./hook/useIndex";
import { FormItem, FleetInput, Button } from "@/components";

const Password: React.FC<{}> = () => {
	const { passwordForm, changeForm, changePassword } = useIndex();
	return (
		<div className={style["fleet-password"]}>
			<FormItem
				containerClassname="password-item"
				label="Old Password"
				type="row"
				needVerity
			>
				<FleetInput
					className="password-item-inner"
					value={passwordForm?.oldPassword}
					onChange={(v) => changeForm("oldPassword", v)}
				/>
			</FormItem>
			<FormItem
				containerClassname="password-item"
				label="New Password"
				type="row"
				needVerity
			>
				<FleetInput
					className="password-item-inner"
					value={passwordForm?.newPassword}
					onChange={(v) => changeForm("newPassword", v)}
				/>
			</FormItem>
			<FormItem
				containerClassname="password-item"
				label="Confirm Password"
				type="row"
				needVerity
			>
				<FleetInput
					className="password-item-inner"
					value={passwordForm?.confirmPassword}
					onChange={(v) => changeForm("confirmPassword", v)}
				/>
			</FormItem>
			<FormItem containerClassname="password-item" label="" type="row">
				<div className="password-item-inner">
					<Button type="primary" onClick={changePassword}>
						Save
					</Button>
				</div>
			</FormItem>
		</div>
	);
};

export default Password;
