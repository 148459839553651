import { convertDdToDm } from "@/tools/latLong";
import { RouteListColumnsType } from "./type";
import React from "react";
import dayjs from "dayjs";
import { Icon } from "@/components";
import { useRouteContext } from "../../../../store";
export const routeListColumns: RouteListColumnsType = [
	{
		title: "",
		dataIndex: "statusIcon",
		width: 40
	},
	{
		title: "Route",
		dataIndex: "routeName",
		render: (value, row, index) => {
			return value ?? `Route${index + 1}`;
		}
	},
	{
		title: "Distance",
		dataIndex: "distance"
	}
];
export const routeDetailListColumns: RouteListColumnsType = [
	{
		title: "NO",
		dataIndex: "index",
		width: 40,
		render: (value, row, index) => {
			return <div>{index + 1}</div>;
		}
	},
	{
		title: "Position",
		dataIndex: "lat",
		width: 225,
		render: (value, row, index) => {
			if (!row?.lat || !row?.lon) {
				return <span>-</span>;
			}
			const {
				degree: latDegree,
				minute: latMinute,
				unit: latUnit
			} = convertDdToDm(row?.lat, true);
			const {
				degree: lonDegree,
				minute: lonMinute,
				unit: lonUnit
			} = convertDdToDm(row?.lon, false);
			const latStr = `${latDegree}°${latMinute}${latUnit}`;
			const lonStr = `${lonDegree}°${lonMinute}${lonUnit}`;
			return <span>{`${latStr} / ${lonStr}`}</span>;
		}
	},
	{
		title: "Nav",
		dataIndex: "nav"
	},
	{
		title: "Spd (kts)",
		dataIndex: "speed"
	},

	{
		title: "Stoppage(hr)",
		dataIndex: "stopHours"
	},
	{
		title: "Resume Time",
		dataIndex: "resumeTime",
		width: 180,
		render: (value, row, index) => {
			return (
				<div>
					{value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "-"}
				</div>
			);
		}
	}
];

export const analysisListColumns: RouteListColumnsType = [
	{
		title: "",
		dataIndex: "status",
		width: 40,
		render(value, row, index, other) {
			const { simulationSelecedInfo = [] } = other;
			let isSelected = false;
			simulationSelecedInfo?.map((item) => {
				if (item?.id === row?.id) {
					isSelected = true;
				}
			});
			const selectedLength = simulationSelecedInfo?.length;
			return (
				<input
					type="checkbox"
					name="simulationCheckBox"
					checked={isSelected}
					disabled={selectedLength >= 4 && !isSelected}
					onChange={(e) => {
						other?.handleSelect && other?.handleSelect(e.target.checked, row);
					}}
				/>
			);
		}
	},
	{
		title: "",
		dataIndex: "status",
		width: 40,
		render(value, row, index) {
			// -1:失败 0:进行中 1:已完成
			return (
				<div>
					{value === 0 ? (
						<Icon type="icon-inprogress" />
					) : value === 1 ? (
						<Icon type="icon-complete" />
					) : (
						"fail"
					)}
				</div>
			);
		}
	},
	{
		title: "ETD",
		dataIndex: "etd",
		width: 160,
		render(value, row, index) {
			return (
				<span>
					{value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : "-"}
				</span>
			);
		}
	},
	{
		title: "ETA",
		dataIndex: "eta",
		width: 160,
		render(value, row, index) {
			return (
				<span>
					{value ? dayjs(value)?.utc()?.format("YYYY-MM-DD HH:mm") : ""}
				</span>
			);
		}
	},
	{
		title: "Distance",
		dataIndex: "distance"
	},

	{
		title: "Speed",
		dataIndex: "avgSpeed",
		render(value, row, index) {
			return <span>{`${row?.avgSpeed} / ${row?.cpSpeed}`}</span>;
		}
	},
	{
		title: "FO/DGO",
		dataIndex: "consFo",
		render(value, row, index) {
			return <span>{`${row?.consFo || "-"}/${row?.consDgo || "-"}`}</span>;
		},
		width: 160
	}
];
