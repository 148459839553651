import React, { FC, forwardRef } from "react";
import styles from "./index.module.less";
import { Icon, FleetInput, Loading, NoData } from "@/components";
import { WaypointEditDialog } from "@/pages/leg/business/details/business/route/components";
import useIndex from "./hooks/useIndex";
import { waypointTableColumns } from "./source";
import { WaypointTableProps } from "./type";
import classNames from "classnames";

const WaypointTable: FC<WaypointTableProps> = ({
	isFirstTable,
	planInfo,
	activeWaypointRowId,
	resetPlanList,
	handleChangePlanName,
	onClickWaypoint
}) => {
	const {
		waypointEditDialogVisible,
		curSelcetedWaypointId,
		loading,
		activeWaypointList,
		cancelEditRoute,
		handleSavePlanName,
		handleChangeWaypointNav,
		handleOpenWaypointEditDialog,
		handleCloseWaypointEditDialog,
		initWaypointList
	} = useIndex(isFirstTable, planInfo, resetPlanList);
	return (
		<div className={styles.waypoint}>
			<Loading loading={loading} />
			<div className="customTable">
				<div className="customTable-title">
					<div className="customTable-title-left">
						<FleetInput
							value={planInfo?.routeName || ""}
							placeholder={"please input name"}
							onChange={(val: string) => {
								handleChangePlanName(val);
							}}
							onBlur={(e) => {
								handleSavePlanName(e.target.value, planInfo?.id);
							}}
						/>
					</div>
					<div className="customTable-title-right">
						<Icon
							type={"icon-cancel"}
							className="canClick"
							onClick={() => {
								cancelEditRoute(planInfo?.id);
							}}
						/>
					</div>
				</div>
				<div className="customTable-content fleet-nonScrollBar">
					<div className="customTable-header">
						{waypointTableColumns.map((item, index) => {
							return (
								<div
									key={index}
									style={{
										maxWidth: item.width ?? 110,
										minWidth: item.width ?? 110
									}}
								>
									{item.title}
								</div>
							);
						})}
						<div
							style={{
								maxWidth: 35,
								minWidth: 35
							}}
						></div>
						<div
							style={{
								maxWidth: 35,
								minWidth: 35
							}}
						></div>
					</div>
					<div className="customTable-body">
						{activeWaypointList?.length === 0 && <NoData className="nodata" />}
						{activeWaypointList?.map((waypointItem, waypointIndex) => {
							return (
								<div
									className={classNames("customTable-body-row", {
										"customTable-body-row-active":
											waypointItem?.id === activeWaypointRowId
									})}
									key={waypointItem?.id}
									id={waypointItem?.id}
									onClick={() => {
										console.log("clickIt", waypointItem);
										onClickWaypoint(waypointItem);
									}}
								>
									{waypointTableColumns.map((columsItem, columnsIndex) => {
										if (columsItem.render) {
											return (
												<div
													style={{
														maxWidth: columsItem.width ?? 110,
														minWidth: columsItem.width ?? 110
													}}
													key={columnsIndex}
												>
													{columsItem.render(
														waypointItem?.[columsItem.dataIndex],
														waypointItem,
														waypointIndex
													)}
												</div>
											);
										}
										if (columsItem.title === "Nav") {
											return (
												<div
													style={{
														maxWidth: columsItem.width ?? 110,
														minWidth: columsItem.width ?? 110
													}}
													key={columnsIndex}
													className="canClick"
													onDoubleClick={() => {
														waypointIndex !== 0 &&
															handleChangeWaypointNav(
																waypointItem?.id,
																waypointItem?.nav === "RL" ? "GC" : "RL",
																waypointItem
															);
													}}
												>
													{waypointIndex === 0 ? "-" : waypointItem?.nav}
												</div>
											);
										}
										return (
											<div
												style={{
													maxWidth: columsItem.width ?? 110,
													minWidth: columsItem.width ?? 110
												}}
												key={columnsIndex}
											>
												{waypointItem?.[columsItem.dataIndex]}
											</div>
										);
									})}
									<div
										style={{
											maxWidth: 35,
											minWidth: 35
										}}
									></div>
									<div
										style={{
											maxWidth: 35,
											minWidth: 35
										}}
										onClick={() => {
											curSelcetedWaypointId.current = waypointItem?.id;
											handleOpenWaypointEditDialog();
										}}
									>
										<Icon type={"icon-edite"} className="canClick" />
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>

			{waypointEditDialogVisible && (
				<WaypointEditDialog
					waypointId={curSelcetedWaypointId.current}
					visible={waypointEditDialogVisible}
					onClose={handleCloseWaypointEditDialog}
					onFinish={(data) => {
						resetPlanList(data);
					}}
				/>
			)}
		</div>
	);
};

export default WaypointTable;
