import {
	Button,
	Dialog,
	Table,
	FleetDatePicker,
	FormItem,
	FleetInput,
	Icon
} from "@/components";
import React, { FC, Fragment } from "react";
import { timestampToUtcMdhm, timestampToUtcObj } from "@/tools/date";
import { formatNumber } from "@/tools/amount";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import { ReportPdfDialogProps } from "./type";
import {
	ReportTitleWithIcon,
	ReportDescriptions,
	ReportSingleRowDescriptions,
	ReportTable,
	ReportBanner,
	ReportTag,
	ReportDescTag,
	ReportSummaryTag,
	ReportRemarks,
	ReportFormula,
	Fraction,
	PhaseCard
} from "./components";

import { LegReportMap } from "..";

import {
	transformSummaryData,
	summaryTableColums,
	transformGwOverData,
	transformPhaseResultData,
	getPhaseStatus
} from "./source";

const ReportPdfDialog: FC<ReportPdfDialogProps> = ({
	visible,
	onClose,
	reportId
}) => {
	const {
		loading,
		handleConfirm,
		reportContent,
		resultTableColums,
		mapRef,
		reportImgUrl
	} = useIndex(reportId);

	console.log(JSON.stringify(reportContent));

	const weatherData = {
		location: "Bunati, Indonesia",
		date: "October 1, 0300Z",
		coordinates: "20°10.3'N, 115°5'E",
		wind: "ECO: 11.5kts/24.5mt/0.07mt"
	};
	const Methods = () => {
		const timeMethods = [
			{
				label: "Total Time at Good Weather Performance Speed",
				numerator: "Good Weather Distance",
				denominator: "Good Weather Performance Speed",
				sign: "(a)"
			},
			{
				label: "Total Time at (Warranted Speed   -   Speed under)",
				numerator: "Good Weather Distance",
				denominator: "Warranted Speed    -    Speed under",
				sign: "(b)"
			},
			{
				label: "Total Time at (Warranted Speed    +   Speed Over)",
				numerator: "Good Weather Distance",
				denominator: "Warranted Speed    +   Speed Over",
				sign: "(c)"
			}
		];
		const fuelMethods = [
			{
				label:
					"Entire Voyage Consumption using vessel Good Weather Consumption",
				leftValue: (
					<Fraction
						numerator={"Good Weather Distance"}
						denominator={"Good Weather Performance Speed"}
					/>
				),
				rightValue: (
					<Fraction
						numerator={"Good Weather Consumption"}
						denominator={"Good Weather Time"}
					/>
				),
				sign: "(d)"
			},
			{
				label: "Maximum Warranted Consumption for over-consunption",
				leftValue: (
					<Fraction
						numerator={"Good Weather Distance"}
						denominator={"Warranted Speed  -  Speed under"}
					/>
				),
				rightValue: (
					<Fraction
						numerator={"Warranted Consumption  +  Tolerance"}
						denominator={"24 hours"}
					/>
				),
				sign: "(e)"
			},
			{
				label: "Minimum Warranted Consumption for fuel saving",
				leftValue: (
					<Fraction
						numerator={"Good Weather Distance"}
						denominator={"Warranted Speed  -  Speed under"}
					/>
				),
				rightValue: (
					<Fraction
						numerator={"Warranted Consumption  -  Tolerance"}
						denominator={"24 hours"}
					/>
				),
				sign: "(f)"
			}
		];
		return (
			<div className="methods flag-no-break">
				<div className="methods-time">
					<ReportTitleWithIcon
						title={
							<span>
								<Icon type="icon-voy" />
								Time Lost/Saved Calculation Method
							</span>
						}
					/>
					<div className="time-formula">
						{timeMethods.map((item, index) => (
							<div className="formula" key={index}>
								<span className="label">{item.label}</span>
								<span className="equal">=</span>
								<span className="result">
									<Fraction
										numerator={item.numerator}
										denominator={item.denominator}
									/>
								</span>
								<span className="sign">{item.sign}</span>
							</div>
						))}

						<div className="time-formula-summary">
							<span>{`Time Lost = (a) - (b)`}</span>
							<span>{`Time Gained = (c) - (a)`}</span>
						</div>
					</div>
				</div>
				<div className="methods-fuel">
					<ReportTitleWithIcon
						title={
							<span>
								<Icon type="icon-voy" />
								Fuel Over/Under Consumption Calculation Method
							</span>
						}
					/>
					<div className="fuel-formula">
						{fuelMethods.map((item, index) => (
							<div className="formula" key={index}>
								<span className="label">{item.label}</span>
								<span className="equal">=</span>
								<span className="value1">{item.leftValue}</span>
								<span className="operation">x</span>
								<span className="value2">{item.rightValue}</span>
								<span className="sign">{item.sign}</span>
							</div>
						))}
						<div className="fuel-formula-summary">
							<span>{`Time Lost = (a) - (b)`}</span>
							<span>{`Time Lost = (a) - (b)`}</span>
						</div>
						<p>{`Fuel Over-consumption: Entire Voyage Consumption using Good Weather Consumption > Max CP Consumption`}</p>
						<p>{`Fuel Saving: Entire Voyage Consumption using Good Weather Consumption < Min CP Consumption`}</p>
						<p>{`No Fuel Over-consumption or Saving: Min CP Consumption ≤ Entire Voyage Consumption using Good Weather Consumption ≤ Max CP Consumption`}</p>
					</div>
				</div>
			</div>
		);
	};

	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={onClose}
			loading={loading}
		>
			<div className={styles["reportPdfDialog"]}>
				<div className="reportPdfDialog-header">
					<Icon type="icon-close2" className="close-btn" onClick={onClose} />
				</div>
				<div className="reportPdfDialog-main fleet-nonScrollBar">
					<div id="main-homepage">
						<div className="logo">
							<img
								className="logo-bg"
								src="/images/leg/plan/leg_plan_report_bg.svg"
							/>
							<img
								className="logo-title"
								src="/images/leg/report/leg_analysis_report_title.svg"
							/>
						</div>
						<ReportTitleWithIcon
							title="Voyage Basics"
							icon={<Icon type="icon-voy" />}
						/>
						<ReportDescriptions
							data={[
								{
									label: "Company Name",
									data: reportContent?.voyageBasics?.companyName
								},
								{
									label: "Departure Port",
									data: reportContent?.voyageBasics?.depPort
								},
								{
									label: "",
									data: reportContent?.voyageBasics?.atd
										? timestampToUtcMdhm(reportContent?.voyageBasics?.atd)
										: "---"
								},
								{
									label: "Vessel Name",
									data: reportContent?.voyageBasics?.vesselName
								},
								{
									label: "Arrival Port",
									data: reportContent?.voyageBasics?.arrPort
								},
								{
									label: "",
									data: reportContent?.voyageBasics?.ata
										? timestampToUtcMdhm(reportContent?.voyageBasics?.ata)
										: "---"
								},
								{
									label: "Report Created",
									data: reportContent?.voyageBasics?.createTime
										? timestampToUtcMdhm(
												reportContent?.voyageBasics?.createTime
											)
										: "---"
								},
								{
									label: "Voyage Condition",
									data: reportContent?.voyageBasics?.voyageCondition
								}
							]}
						/>
						<ReportTitleWithIcon
							title="Charter Party Clause"
							icon={<Icon type="icon-report" />}
						/>
						<div className="charterPartyClause">
							<ReportSingleRowDescriptions
								data={[
									{
										label: "Beaufort Wind Scale",
										data: `BF ${reportContent?.charterParty?.gwClause?.bf}`,
										isShow: true
									},
									{
										label: "Significant Wave Height",
										data: `${reportContent?.charterParty?.gwClause?.signWaveValue} Meter`,
										isShow: true
									},
									{
										label: "No Adverse Current",
										data: reportContent?.charterParty?.gwClause
											?.noAdverseCurrent
											? "Applied"
											: "Not Applied",
										isShow:
											reportContent?.charterParty?.gwClause?.noAdverseCurrent
									},
									{
										label: "Favorable Current",
										data: reportContent?.charterParty?.gwClause
											?.noFavourableCurrent
											? "Applied"
											: "Not Applied",
										isShow:
											reportContent?.charterParty?.gwClause?.noFavourableCurrent
									},
									{
										label: "No Extrapolation",
										data: reportContent?.charterParty?.gwClause?.noExtrapolation
											? "Applied"
											: "Not Applied",
										isShow:
											reportContent?.charterParty?.gwClause?.noExtrapolation
									}
								]}
							/>
							<ReportTable
								columns={[
									{ label: "CP", index: "cp" },
									{ label: "Speed(kts)", index: "speed", fixed: 2 },
									{ label: "FO(mt)", index: "fo", fixed: 3 },
									{ label: "DO/GO(mt)", index: "dogo", fixed: 3 }
								]}
								dataSource={
									reportContent?.charterParty?.cpTerms
										? reportContent?.charterParty?.cpTerms.map((item) => {
												return {
													cp: item.speedCategory,
													speed: item.speed,
													fo: item.foConsumption,
													dogo: item.dgoConsumption
												};
											})
										: []
								}
							/>
						</div>
						<div className="divider"></div>
						<ReportBanner
							icon={<Icon type="icon-report" />}
							title="Analysis Summary"
						/>

						{reportContent?.phases.length > 0 ? (
							<>
								<div className="overall">Overall</div>
								<div className="overallTags">
									<ReportTag
										title="GW Avg Speed :"
										value={
											reportContent?.overAll?.gwAvgSpeed ||
											reportContent?.overAll?.gwAvgSpeed === 0
												? formatNumber(reportContent?.overAll?.gwAvgSpeed, 2)
												: "---"
										}
										unit=" kts"
									/>
									<ReportTag
										title="GW Avg Daily Cons. FO :"
										value={
											reportContent?.overAll?.gwConsFo ||
											reportContent?.overAll?.gwConsFo === 0
												? formatNumber(reportContent?.overAll?.gwConsFo, 2)
												: "---"
										}
										unit=" mt"
									/>
									<ReportTag
										title="GW Ave Daily Cons. DO/GO :"
										value={
											reportContent?.overAll?.gwConsDgo ||
											reportContent?.overAll?.gwConsDgo === 0
												? formatNumber(reportContent?.overAll?.gwConsDgo, 2)
												: "---"
										}
										unit=" mt"
									/>
									<ReportTag
										title="Overall Avg Speed :"
										value={
											reportContent?.overAll?.overallSpeed ||
											reportContent?.overAll?.overallSpeed === 0
												? formatNumber(reportContent?.overAll?.overallSpeed, 2)
												: "---"
										}
										unit=" kts"
									/>
									<ReportTag
										title="Overall Avg Daily Cons. FO :"
										value={
											reportContent?.overAll?.overallConsFO ||
											reportContent?.overAll?.overallConsFO === 0
												? formatNumber(reportContent?.overAll?.overallConsFO, 2)
												: "---"
										}
										unit=" mt"
									/>
									<ReportTag
										title="Overall Avg Daily Cons. DO/GO :"
										value={
											reportContent?.overAll?.overallConsDgo ||
											reportContent?.overAll?.overallConsDgo === 0
												? formatNumber(
														reportContent?.overAll?.overallConsDgo,
														2
													)
												: "---"
										}
										unit=" mt"
									/>
								</div>
								<div className="annotation">
									<span>* For FO: FO Cons. + M/E DO/GO Cons. in ECA</span>
									<span>* For DO/GO: DO/GO Cons. - M/E DO/GO Cons. in ECA</span>
								</div>
							</>
						) : (
							""
						)}

						<div className="divider" />
						<div className="phaseTags">
							{reportContent?.phases
								? reportContent?.phases.map((phase, index) => {
										return (
											<Fragment key={index}>
												<ReportDescTag
													phase={phase.name}
													appliedCp={phase.appliedCp}
													phaseParty={phase.phaseParty}
												/>
												<PhaseCard
													gw={phase.gw}
													type="Time"
													value={phase.timeLostGained}
												/>
												<PhaseCard
													gw={phase.gw}
													type="FO Over"
													value={phase.foOverUnder}
												/>
												<PhaseCard
													gw={phase.gw}
													type="DO/GO"
													value={phase.dgoOverUnder}
												/>
												{/* <ReportTag
													title="Time Lost/Gained :"
													value={phase.timeLostGained}
													valueIcon={
														<Icon
															type={
																getPhaseStatus(phase.timeLostGained)
																	.iconClass as any
															}
															color={getPhaseStatus(phase.timeLostGained).color}
														/>
													}
													unit=" hrs"
													type="Time Lost"
													typeIcon={
														<Icon
															type="icon-alarm"
															color={getPhaseStatus(phase.timeLostGained).color}
														/>
													}
												/>
												<ReportTag
													title="FO Over/Under :"
													value={Math.abs(phase.foOverUnder)}
													valueIcon={
														<Icon
															type={
																getPhaseStatus(phase.foOverUnder)
																	.iconClass as any
															}
															color={getPhaseStatus(phase.foOverUnder).color}
														/>
													}
													unit=" mt"
													type="FO Over"
													typeIcon={
														<Icon
															type="icon-a-fuelmanagement"
															color={getPhaseStatus(phase.foOverUnder).color}
														/>
													}
												/>
												<ReportTag
													title="DO/GO Over/Under :"
													value={phase.dgoOverUnder}
													valueIcon={
														<Icon
															type={
																getPhaseStatus(phase.dgoOverUnder)
																	.iconClass as any
															}
															color={getPhaseStatus(phase.dgoOverUnder).color}
														/>
													}
													unit=" mt"
													type="DO/GO Under"
													typeIcon={
														<Icon
															type="icon-a-fuelmanagement"
															color={getPhaseStatus(phase.dgoOverUnder).color}
														/>
													}
												/> */}
											</Fragment>
										);
									})
								: []}
						</div>
						<div className="divider" />

						<ReportTitleWithIcon
							title="Voyage Remarks"
							icon={<Icon type="icon-voy" />}
						/>
						<ReportRemarks
							title="Remarks"
							list={
								reportContent?.remark?.speedAdjustment
									? reportContent?.remark?.speedAdjustment?.map((item) => {
											return { label: item.title, values: item.content };
										})
									: []
							}
						/>
						<div className="flag-no-break">
							<ReportRemarks
								title="Excluded Period"
								list={
									reportContent?.remark?.excludedPeriod
										? reportContent?.remark?.excludedPeriod?.map((item) => {
												return { label: item, values: [] };
											})
										: []
								}
							/>
						</div>
						{/* <ReportSummaryTag
							title="Summary"
							list={[
								{ title: "Total Time Lost:", value: "9.3", unit: "hrs" },
								{ title: "Total Time Gained:", value: "0.0", unit: "hrs" },
								{ title: "Total Time Lost:", value: "0.0", unit: "hrs" }
							]}
						/> */}
					</div>
					<div id="main-analysis-page">
						{reportContent?.phases
							? reportContent?.phases.map((phase, index) => {
									return (
										<Fragment key={index}>
											<div className="headerTag">
												<ReportDescTag
													phase={phase.name}
													appliedCp={phase.appliedCp}
													phaseParty={phase.phaseParty}
												/>
											</div>
											<div className="divider" />
											<ReportBanner
												icon={<Icon type="icon-report" />}
												title="Calculation Summary"
											/>
											<div className="calculations">
												<ReportSummaryTag
													title="Avg Daily Cons. FO"
													list={[
														{
															title: "Good Weather:",
															value: phase.summary.gwAvgConsFo,
															unit: "mt",
															fixed: 3
														},
														{
															title: "Overall:",
															value: phase.summary.overallAvgConsFO,
															unit: "mt",
															fixed: 3
														}
													]}
												/>
												<ReportSummaryTag
													title="Avg Daily Cons. DO/GO"
													list={[
														{
															title: "Good Weather:",
															value: phase.summary.gwAvgConsDgo,
															unit: "mt",
															fixed: 3
														},
														{
															title: "Overall:",
															value: phase.summary.overallAvgConsDgo,
															unit: "mt",
															fixed: 3
														}
													]}
												/>
												<ReportSummaryTag
													title="Distance"
													list={[
														{
															title: "Good Weather:",
															value: phase.summary.gwDistance,
															unit: "nm",
															fixed: 1
														},
														{
															title: "Overall:",
															value: phase.summary.overallDistance,
															unit: "nm",
															fixed: 1
														}
													]}
												/>
												<ReportSummaryTag
													title="Time"
													list={[
														{
															title: "Good Weather:",
															value: phase.summary.gwTime,
															unit: "hrs",
															fixed: 2
														},
														{
															title: "Overall:",
															value: phase.summary.overallTime,
															unit: "hrs",
															fixed: 2
														}
													]}
												/>
												<ReportSummaryTag
													title="Cons. FO"
													list={[
														{
															title: "Good Weather:",
															value: phase.summary.gwConsFo,
															unit: "mt",
															fixed: 3
														},
														{
															title: "Overall:",
															value: phase.summary.overallConsFo,
															unit: "mt",
															fixed: 3
														}
													]}
												/>
												<ReportSummaryTag
													title="Cons. DO/GO"
													list={[
														{
															title: "Good Weather:",
															value: phase.summary.gwConsDgo,
															unit: "mt",
															fixed: 3
														},
														{
															title: "Overall:",
															value: phase.summary.overallConsDgo,
															unit: "mt",
															fixed: 3
														}
													]}
												/>
												<ReportSummaryTag
													title="Avg Speed"
													list={[
														{
															title: "Good Weather:",
															value: phase.summary.gwAvgSpeed,
															unit: "kts",
															fixed: 2
														},
														{
															title: "Overall:",
															value: phase.summary.overallAvgSpeed,
															unit: "kts",
															fixed: 2
														}
													]}
												/>
												<ReportSummaryTag
													list={[
														{
															title: "GW Performance Speed:",
															value: phase.summary.gwPerSpeed,
															unit: "kts",
															fixed: 2
														},
														{
															title: "GW Current Factor:",
															value: phase.summary.gwCf,
															unit: "kts",
															fixed: 2
														}
													]}
												/>
											</div>
											<div className="annotation">
												<span>* For FO: FO Cons. + M/E DO/GO Cons. in ECA</span>
												<span>
													* For DO/GO: DO/GO Cons. - M/E DO/GO Cons. in ECA
												</span>
											</div>
											<Table
												dataSource={transformGwOverData(
													phase.goodWeatherOverallTable
												)}
												columns={summaryTableColums}
												bordered={true}
												id="id"
												scroll={{ x: 0 }}
												pagination={false}
												className="weatherTable flag-no-break"
											/>

											<div className="tagFormulas flag-no-break">
												<PhaseCard
													gw={phase.gw}
													type="Time"
													value={phase.timeLostGained}
												/>
												{phase.gw ? (
													<div className="formulas">
														<ReportFormula
															label="Total Time at Good Weather Performance Speed"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.summary.gwPerSpeed,
																		2
																	)}
																/>
															}
															result={`${formatNumber(transformPhaseResultData(phase).timeRes.ttagwps, 2)} HRS  (a)`}
														/>
														<ReportFormula
															label="Total Time at (Warranted Speed - Speed under)"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.warrSpeed +
																			phase.phaseExpression.aboutSpeedFrom,
																		2
																	)}
																/>
															}
															result={`${formatNumber(transformPhaseResultData(phase).timeRes.ttawssu, 2)} HRS  (b)`}
														/>
														<ReportFormula
															label="Total Time at (Warranted Speed + Speed Over)"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.warrSpeed +
																			phase.phaseExpression.aboutSpeedTo,
																		2
																	)}
																/>
															}
															result={`${formatNumber(transformPhaseResultData(phase).timeRes.ttawssso, 2)} HRS  (c)`}
														/>
													</div>
												) : (
													<div className="formulas-hidden"></div>
												)}
											</div>
											<div className="tagFormulas flag-no-break">
												<PhaseCard
													gw={phase.gw}
													type="FO Over"
													value={phase.foOverUnder}
												/>
												{phase.gw ? (
													<div className="formulas">
														<ReportFormula
															label="Entire Voyage Consumption using Good Weather Consumption"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.summary.gwPerSpeed,
																		2
																	)}
																/>
															}
															operator1="x"
															value2={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.gwFoCons,
																		3
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.gwFoTime,
																		2
																	)}
																/>
															}
															operator2=""
															result={`${formatNumber(transformPhaseResultData(phase).foRes.evc, 3)} MT  (d)`}
														/>
														<ReportFormula
															label="Maximum Warranted Consumption for Over-Consunption"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.warrSpeed +
																			phase.phaseExpression.aboutSpeedFrom,
																		2
																	)}
																/>
															}
															operator1="x"
															value2={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.warrFoCons +
																			phase.phaseExpression.toleranceFoTo,
																		3
																	)}
																	denominator={"24"}
																/>
															}
															operator2=""
															result={`${formatNumber(transformPhaseResultData(phase).foRes.mwcfoc, 3)} MT  (e)`}
														/>
														<ReportFormula
															label="Minimum Warranted Consumption for Fuel-Saving"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.warrSpeed +
																			phase.phaseExpression.aboutSpeedFrom,
																		2
																	)}
																/>
															}
															operator1="x"
															value2={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.warrFoCons +
																			phase.phaseExpression.toleranceFoFrom,
																		3
																	)}
																	denominator={"24"}
																/>
															}
															operator2=""
															result={`${formatNumber(transformPhaseResultData(phase).foRes.mwcffs, 3)} MT  (d)`}
														/>
													</div>
												) : (
													<div className="formulas-hidden"></div>
												)}
											</div>
											<div className="tagFormulas flag-no-break">
												<PhaseCard
													gw={phase.gw}
													type="DO/GO"
													value={phase.dgoOverUnder}
												/>
												{phase.gw ? (
													<div className="formulas">
														<ReportFormula
															label="Entire Voyage Consumption using Good Weather Consumption"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.summary.gwPerSpeed,
																		2
																	)}
																/>
															}
															operator1="x"
															value2={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.gwDgoCons,
																		3
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.gwDgoTime,
																		2
																	)}
																/>
															}
															operator2=""
															result={`${formatNumber(transformPhaseResultData(phase).dgoRes.evc, 3)} MT  (d)`}
														/>
														<ReportFormula
															label="Maximum Warranted Consumption for Over-Consunption"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.warrSpeed +
																			phase.phaseExpression.aboutSpeedFrom,
																		2
																	)}
																/>
															}
															operator1="x"
															value2={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.warrDgoCons +
																			phase.phaseExpression.toleranceDgoTo,
																		3
																	)}
																	denominator={"24"}
																/>
															}
															operator2=""
															result={`${formatNumber(transformPhaseResultData(phase).dgoRes.mwcfoc, 3)} MT  (e)`}
														/>
														<ReportFormula
															label="Minimum Warranted Consumption for Fuel-Saving"
															value1={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.distance,
																		1
																	)}
																	denominator={formatNumber(
																		phase.phaseExpression.warrSpeed +
																			phase.phaseExpression.aboutSpeedFrom,
																		2
																	)}
																/>
															}
															operator1="x"
															value2={
																<Fraction
																	numerator={formatNumber(
																		phase.phaseExpression.warrDgoCons +
																			phase.phaseExpression.toleranceDgoFrom,
																		3
																	)}
																	denominator={"24"}
																/>
															}
															operator2=""
															result={`${formatNumber(transformPhaseResultData(phase).dgoRes.mwcffs, 3)} MT  (f)`}
														/>
													</div>
												) : (
													<div className="formulas-hidden"></div>
												)}
											</div>
										</Fragment>
									);
								})
							: []}
					</div>
					<div id="main-calc-page">
						{Methods()}
						<div className="map">
							{reportImgUrl ? (
								<img src={reportImgUrl} alt="" crossOrigin="anonymous" />
							) : (
								<LegReportMap ref={mapRef} />
							)}
						</div>
					</div>
					<div id="main-last-page">
						<div className="logo">
							<img
								className="logo-bg"
								src="/images/leg/plan/leg_plan_report_bg.svg"
							/>
							<img
								className="logo-title"
								src="/images/leg/report/leg_analysis_report_title.svg"
							/>
						</div>
						<Table
							dataSource={reportContent?.reportDetail}
							columns={resultTableColums}
							rowClassName={(record) => {
								return record.gw ? "report-gw-item" : "";
							}}
							bordered={true}
							id="id"
							scroll={{ x: 0 }}
							pagination={false}
							className="resultTable"
						/>
						<div className="divider" />
						<div className="annotation">
							<span>Fleet Insight Limited ops@fltins.com</span>
						</div>
					</div>
				</div>
				<div className="reportPdfDialog-footer">
					<Button
						onClick={() => {
							handleConfirm();
						}}
					>
						Export PDF
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default ReportPdfDialog;
