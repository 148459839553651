// export enum CurrentDirective {
// 	North,
// 	Northeast = 45,
// 	East = 90,
// 	Southeast = 135,
// 	South = 180,
// 	Southwest = 225,
// 	West = 270,
// 	Northwest = 315
// }

export enum Directive {
	North,
	Northeast = 45,
	East = 90,
	Southeast = 135,
	South = 180,
	Southwest = 225,
	West = 270,
	Northwest = 315
}
