import React from "react";
import Table from "antd/es/table/index";
import type { PaginationProps } from "antd/es/pagination";
import type { ColumnsType } from "antd/es/table/interface";
import { type GetComponentProps } from "rc-table/lib/interface";
import classnames from "classnames";
import style from "./index.module.less";

export type DataType = {
	key: React.Key;
} & Record<string, any>;

type TableProps = {
	bordered?: boolean;
	columns: ColumnsType<DataType>;
	summary?: (data: readonly any[]) => React.ReactNode;
	className?: string;
	loading?: boolean;
	dataSource: any[];
	id: string;
	scroll?: { x?: number; y?: number };
	pagination?:
		| {
				current?: number;
				pageSize?: number;
				showSizeChanger?: boolean;
				onShowSizeChange?: PaginationProps["onShowSizeChange"];
				onChange?: (page: number, size: number) => void;
				defaultCurrent?: number;
				total?: number;
		  }
		| false;
	rowSelection?: {
		selectedRowKeys: React.Key[];
		onChange: (newSelectedRowKeys: React.Key[]) => void;
	};
	onRow?: GetComponentProps<any>;
	onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
	rowClassName?: (record: DataType, index: number) => string;
};

const FleetTable: React.FC<TableProps> = ({
	bordered = false,
	columns,
	className,
	summary,
	loading,
	dataSource,
	id,
	scroll = { x: 1420, y: 650 },
	pagination = {
		current: 1,
		pageSize: 20,
		// showSizeChanger: true,
		defaultCurrent: 1
	},
	rowSelection,
	onRow,
	onScroll,
	rowClassName
}) => {
	return (
		<Table
			columns={columns}
			summary={summary}
			onRow={onRow}
			onScroll={onScroll}
			dataSource={dataSource}
			loading={loading}
			sticky
			scroll={scroll}
			rowKey={(record) => record?.[id]}
			className={classnames(
				style["fleet-table"],
				{
					[style["fleet-table-empty"]]: dataSource?.length === 0,
					[style["fleet-table-sumary"]]: !!summary
				},
				className
			)}
			pagination={pagination}
			rowSelection={rowSelection}
			bordered={bordered}
			rowClassName={rowClassName}
		/>
	);
};

export default FleetTable;
