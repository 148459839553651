import React, { FC } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { Image } from "@/components";
import { LegDetailsMenuProps } from "./type";
import { menuOptions } from "./source";

const LegDetailsMenu: FC<LegDetailsMenuProps> = ({ activeTab, onSelect }) => {
	return (
		<div className={style["leg-details-siderMenu"]}>
			<Image src="/images/logo.png" className="siderMenu-logo" />
			<ul className="siderMenu-container">
				{menuOptions?.map((item, index) => {
					return (
						<li
							key={index}
							className={classNames({
								active: activeTab === item?.value
							})}
							onClick={() => onSelect?.(item?.value)}
						>
							{item?.label}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default LegDetailsMenu;
