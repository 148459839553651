import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { AddRouteType } from "@/api/leg/type";
const useIndex = (addFinishCB, handleCloseDialog) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");
	// DidMount
	useEffect(() => {
		initPageData();
	}, []);
	const initPageData = () => {
		getLegDetailById({ id });
	};

	// state
	const [tableData, setTableData] = useState({
		dataSource: [],
		selectedRowKeys: []
	});

	// hanlde
	const hanleCloseDialog = (closeDialog) => {
		closeDialog && closeDialog();
	};

	const handleSelectPort = (keys) => {
		const newTableData = {
			...tableData,
			selectedRowKeys: keys
		};
		setTableData(newTableData);
	};
	const handleChangeStopHours = (val, record) => {
		// 查到record在tableData中的对应的数据，并修改stopHours值
		const newDataSource = tableData.dataSource.map((item) => {
			if (item.id === record.id) {
				item.stopHours = val;
			}
			return item;
		});
		setTableData({
			...tableData,
			dataSource: newDataSource
		});
	};
	const handleConfirm = () => {
		const params = generateAddRouteApiParams();
		addRoute(params);
	};

	// generate
	const generateAddRouteApiParams = () => {
		const portCode = [];
		const portName = [];
		tableData.dataSource.map((item) => {
			if (tableData?.selectedRowKeys?.includes(item.key)) {
				portCode.push(item.portCode);
				portName.push(item.portName);
			}
		});

		const params = {
			portCode,
			portName,
			legId: id,
			voyageId: voyageId
		};
		console.log(params);
		return params;
	};

	// Api
	// Api - getLegDetailById
	const getLegDetailByIdFront = () => {};
	const getLegDetailByIdSuccess = (data) => {
		const portList = data?.data?.portRotations;
		const dataSource = portList?.map((item) => {
			return {
				...item,
				key: item.id,
				stopHours: null,
				check: false
			};
		});
		const tableData = {
			dataSource,
			selectedRowKeys: []
		};
		setTableData(tableData);
	};
	const getLegDetailByIdFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getLegDetailById = (params) => {
		onRequest(
			"getLegDetailByIdApi",
			params,
			getLegDetailByIdFront,
			getLegDetailByIdSuccess,
			getLegDetailByIdFailed,
			dispatch,
			navigate
		);
	};
	// Api - addRoute
	const addRouteFront = () => {};
	const addRouteSuccess = () => {
		addFinishCB && addFinishCB();
		handleCloseDialog && handleCloseDialog();
	};
	const addRouteFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const addRoute = (params: AddRouteType) => {
		onRequest(
			"addRouteApi",
			params,
			addRouteFront,
			addRouteSuccess,
			addRouteFailed,
			dispatch,
			navigate
		);
	};

	return {
		loading,
		hanleCloseDialog,
		tableData,
		handleSelectPort,
		handleChangeStopHours,
		handleConfirm
	};
};

export default useIndex;
