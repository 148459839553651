import React from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { VoyageFilterItemType, VoyageFilterProps } from "./type";
import {
	Button,
	FleetInput,
	FormItem,
	Select,
	FleetRangePicker
} from "@/components";
import {
	voyTypeOptions,
	statusOptions,
	serviceOptions,
	roleOptions
} from "./source";
import dayjs, { Dayjs } from "dayjs";
import {
	CommonPortSelect,
	CommonPortItemType,
	CommonVesselSelect,
	CommonVesselItemType,
	CommonCompanySelect,
	CommonCompanyItemType
} from "@/common";
import useIndex from "./hooks/useIndex";

const VoyageFilter: React.FC<VoyageFilterProps> = ({
	item,
	onCommit,
	onAdd,
	onSearch
}) => {
	// const {
	// 	selectVesselItem,
	// 	selectCompanyItem,
	// 	setSelectVesselItem,
	// 	setSelectCompanyItem
	// } = useIndex();

	return (
		<div className={classNames(style["voy-list-filter"])}>
			<div className="filter-container">
				<FormItem label="Vessel:" type="row">
					{/* <FleetInput
						value={undefined}
						onChange={(val: VoyageFilterItemType["imo"]) => {
							onSearch?.("imo", val);
						}}
					/> */}

					<CommonVesselSelect
						allowClear
						activeItems={{ imo: item.imo, vesselName: item.vesselName }}
						showKey={"vesselName"}
						primaryKey={"imo"}
						onFinish={(newItem, operateType) => {
							// setSelectVesselItem(newItem);
							onCommit?.({
								imo: newItem?.imo ?? null,
								vesselName: newItem?.vesselName ?? ""
							});
						}}
						className="select_common"
					/>
				</FormItem>

				<FormItem label="Company:" type="row">
					{/* <FleetInput
						value={undefined}
						onChange={(val: VoyageFilterItemType["companyId"]) => {
							onSearch?.("companyId", val);
						}}
					/> */}
					<CommonCompanySelect
						allowClear
						activeItems={{
							// code: item.companyCode,
							name: item.companyName,
							id: String(item.id)
						}}
						showKey={"name"}
						primaryKey={"id"}
						onFinish={(newItem, operateType) => {
							onCommit?.({
								companyId: newItem?.id ?? "",
								companyName: newItem?.name ?? ""
							});
							// setSelectCompanyItem(newItem);
							// onSearch?.("companyId", newItem?.id);
						}}
						className="select_common"
					/>
				</FormItem>

				<FormItem label="Voy Type:" type="row">
					<Select
						allowClear
						onChange={(val: VoyageFilterItemType["voyageType"]) => {
							onCommit?.({
								voyageType: val
							});
						}}
						options={voyTypeOptions}
						value={item?.voyageType}
						className="voy-type"
					/>
				</FormItem>

				<FormItem label="Status:" type="row">
					<Select
						allowClear
						onChange={(val: VoyageFilterItemType["status"]) => {
							onCommit?.({
								status: val
							});
						}}
						options={statusOptions}
						value={item?.status}
						className="status"
					/>
				</FormItem>

				<FormItem label="Service:" type="row">
					<Select
						allowClear
						onChange={(val: VoyageFilterItemType["service"]) => {
							onCommit?.({
								service: val
							});
						}}
						options={serviceOptions}
						value={item?.service}
						className="service"
					/>
				</FormItem>

				<FormItem label="Role:" type="row">
					<Select
						allowClear
						onChange={(val: VoyageFilterItemType["role"]) => {
							onCommit?.({
								role: val
							});
						}}
						options={roleOptions}
						value={item?.role}
						className="role"
					/>
				</FormItem>

				<FormItem label="Order Time:" type="row" className="order-time">
					<FleetRangePicker
						onChange={(vals: [Dayjs, Dayjs]) => {
							onCommit?.({
								startOrderDate: vals?.[0]?.format("YYYY-MM-DD") ?? "",
								endOrderDate: vals?.[1]?.format("YYYY-MM-DD") ?? ""
							});
						}}
						value={[
							item?.startOrderDate ? dayjs(item?.startOrderDate) : null,
							item?.endOrderDate ? dayjs(item?.endOrderDate) : null
						]}
						className="order-time"
						allowClear
					/>
				</FormItem>
			</div>
			<Button
				type="default"
				onClick={() => {
					onAdd?.("add");
				}}
			>
				Add New Voyage
			</Button>
		</div>
	);
};

export default VoyageFilter;
