import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { RouteDetailsProps } from "../type";

const useIndex = (props: RouteDetailsProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();

	const [remarks, setRemarks] = useState<string>("");
	// 是否处于编辑状态
	const [isEditable, setIsEditable] = useState<boolean>(false);
	// const [imgBase, setImgBase] = useState<string>("");
	// const downloadImage = (imgSrc: string) => {
	// 	const xhr = new XMLHttpRequest();
	// 	xhr.open("get", imgSrc, true);
	// 	xhr.responseType = "blob";
	// 	xhr.onload = function () {
	// 		if (this.status == 200) {
	// 			setImgBase(URL.createObjectURL(this.response));
	// 		}
	// 	};
	// 	xhr.send();
	// };
	// const downloadIamge2 = (imgSrc: string) => {
	// 	//下载图片地址和图片名
	// 	let image = new Image();
	// 	// 解决跨域 Canvas 污染问题
	// 	image.setAttribute("crossOrigin", "anonymous");
	// 	image.onload = () => {
	// 		debugger;
	// 		let canvas = document.createElement("canvas");
	// 		canvas.width = image.width;
	// 		canvas.height = image.height;
	// 		let context = canvas.getContext("2d");
	// 		context.drawImage(image, 0, 0, image.width, image.height);
	// 		let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
	// 		setImgBase(url);
	// 	};

	// 	image.onerror = (ev) => {
	// 		console.error(ev);
	// 	};
	// 	image.src = imgSrc;
	// };

	// 失去焦点时间处理
	const handleTextareaBlur = () => {
		setIsEditable(false);
		onRequest(
			"saveReportPlanRemarkApi",
			{ id: props.data.id, remark: remarks },
			handleRequestFront,
			handleSaveReportPlanRemarkSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};
	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const handleSaveReportPlanRemarkSuccess = () => {};

	useEffect(() => {
		setRemarks(
			props.data?.remark ||
				"Please find the following major waypoints for your safe navigation."
		);
		// if (props.data?.imgUrl) {
		// 	downloadImage(props.data.imgUrl);
		// }
	}, [props.data]);

	return {
		remarks,
		setRemarks,
		isEditable,
		setIsEditable,
		handleTextareaBlur
		// imgBase
	};
};

export default useIndex;
