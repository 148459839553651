import React from "react";
import { Icon } from "@/components";
import { GoodWeatherOverallTable, Phase, Summary, ReportDetail } from "./type";
import { DataType } from "@/components/Table";
import { ColumnsType } from "antd/es/table";
import { timestampToUtcObj } from "@/tools/date";
import { convertDdToDm } from "@/tools/latLong";
import { formatNumber } from "@/tools/amount";

export const transformGwOverData = (data: GoodWeatherOverallTable) => {
	return [
		{
			title: "Distance(nm)",
			fixed: 1,
			gwOut: data.goodWeatherElem.outDistance,
			gwIn: data.goodWeatherElem.inDistance,
			overOut: data.overallElem.outDistance,
			overIn: data.overallElem.inDistance
		},
		{
			title: "Time(hrs)",
			fixed: 1,
			gwOut: data.goodWeatherElem.outTime,
			gwIn: data.goodWeatherElem.inTime,
			overOut: data.overallElem.outTime,
			overIn: data.overallElem.inTime
		},
		{
			title: "Average Speed(kts)",
			fixed: 2,
			gwOut: data.goodWeatherElem.outAvgSpeed,
			gwIn: data.goodWeatherElem.inAvgSpeed,
			overOut: data.overallElem.outAvgSpeed,
			overIn: data.overallElem.inAvgSpeed
		},
		{
			title: "FO Consumption(mt)",
			fixed: 3,
			gwOut: data.goodWeatherElem.outConsFo,
			gwIn: data.goodWeatherElem.inConsFo,
			overOut: data.overallElem.outConsFo,
			overIn: data.overallElem.inConsFo
		},
		{
			title: "Averaged Daily FO Consumption(mt)",
			fixed: 3,
			gwOut: data.goodWeatherElem.outAvgDailyConsFo,
			gwIn: data.goodWeatherElem.inAvgDailyConsFo,
			overOut: data.overallElem.outAvgDailyConsFo,
			overIn: data.overallElem.inAvgDailyConsFo
		},
		{
			title: "DO/GO Consumption(mt)",
			fixed: 3,
			gwOut: data.goodWeatherElem.outConsDgo,
			gwIn: data.goodWeatherElem.inConsDgo,
			overOut: data.overallElem.outConsDgo,
			overIn: data.overallElem.inConsDgo
		},
		{
			title: "Averaged Daily DO/GO Consumption(mt)",
			fixed: 3,
			gwOut: data.goodWeatherElem.outAvgDailyConsDgo,
			gwIn: data.goodWeatherElem.inAvgDailyConsDgo,
			overOut: data.overallElem.outAvgDailyConsDgo,
			overIn: data.overallElem.inAvgDailyConsDgo
		}
	];
};

export const summaryTableColums: ColumnsType<DataType> = [
	{
		title: "  ",
		children: [
			{
				title: "  ",
				dataIndex: "title"
			}
		]
	},
	{
		title: "GOOD WEATHER",
		children: [
			{
				title: "out of ECA",
				dataIndex: "gwOut",
				render(value, record, index) {
					const isValid = value || value === 0;
					return (
						<div>
							{isValid
								? record.fixed
									? formatNumber(value, record.fixed)
									: value
								: "---"}
						</div>
					);
				}
			},
			{
				title: "in ECA",
				dataIndex: "gwIn",
				render(value, record, index) {
					const isValid = value || value === 0;
					return (
						<div>
							{isValid
								? record.fixed
									? formatNumber(value, record.fixed)
									: value
								: "---"}
						</div>
					);
				}
			}
		]
	},
	{
		title: "OVERALL",
		children: [
			{
				title: "out of ECA",
				dataIndex: "overOut",
				render(value, record, index) {
					const isValid = value || value === 0;
					return (
						<div>
							{isValid
								? record.fixed
									? formatNumber(value, record.fixed)
									: value
								: "---"}
						</div>
					);
				}
			},
			{
				title: "in ECA",
				dataIndex: "overIn",
				render(value, record, index) {
					const isValid = value || value === 0;
					return (
						<div>
							{isValid
								? record.fixed
									? formatNumber(value, record.fixed)
									: value
								: "---"}
						</div>
					);
				}
			}
		]
	}
];

export const transformSummaryData = (data: Summary) => {};

export const transformPhaseResultData = (data: Phase) => {
	return {
		foRes: {
			evc:
				(data.phaseExpression.distance / data.summary.gwPerSpeed) *
				(data.phaseExpression.gwFoCons / data.phaseExpression.gwFoTime),
			mwcfoc:
				(data.phaseExpression.distance /
					(data.phaseExpression.warrSpeed +
						data.phaseExpression.aboutSpeedFrom)) *
				((data.phaseExpression.warrFoCons +
					data.phaseExpression.toleranceFoTo) /
					24),
			mwcffs:
				(data.phaseExpression.distance /
					(data.phaseExpression.warrSpeed +
						data.phaseExpression.aboutSpeedFrom)) *
				((data.phaseExpression.warrFoCons +
					data.phaseExpression.toleranceFoFrom) /
					24)
		},
		timeRes: {
			ttagwps: data.phaseExpression.distance / data.summary.gwPerSpeed,
			ttawssu:
				data.phaseExpression.distance /
				(data.phaseExpression.warrSpeed + data.phaseExpression.aboutSpeedFrom),
			ttawssso:
				data.phaseExpression.distance /
				(data.phaseExpression.warrSpeed + data.phaseExpression.aboutSpeedTo)
		},
		dgoRes: {
			evc:
				(data.phaseExpression.distance / data.summary.gwPerSpeed) *
				(data.phaseExpression.gwDgoCons / data.phaseExpression.gwDgoTime),
			mwcfoc:
				(data.phaseExpression.distance /
					(data.phaseExpression.warrSpeed +
						data.phaseExpression.aboutSpeedFrom)) *
				((data.phaseExpression.warrDgoCons +
					data.phaseExpression.toleranceDgoTo) /
					24),
			mwcffs:
				(data.phaseExpression.distance /
					(data.phaseExpression.warrSpeed +
						data.phaseExpression.aboutSpeedFrom)) *
				((data.phaseExpression.warrDgoCons +
					data.phaseExpression.toleranceDgoFrom) /
					24)
		}
	};

	// return [
	// 	{
	// 		title: "FO Over/Under :",
	// 		value: data.dgoOverUnder,
	// 		valueIcon: <Icon type="icon-voy" />,
	// 		unit: " mt",
	// 		type: "FO Over",
	// 		typeIcon: <Icon type="icon-voy" />,
	// 		gwd: data.summary.gwDistance,
	// 		gwps: data.summary.gwPerSpeed,
	// 		gwc: data.phaseExpression.gwFoCons,
	// 		gwt: data.phaseExpression.gwFoTime,
	// 		ws: data.phaseExpression.warrSpeed,
	// 		su: data.phaseExpression.aboutSpeedFrom,
	// 		so: data.phaseExpression.aboutSpeedTo,
	// 		wc: data.phaseExpression.warrFoCons,
	// 		t1: data.phaseExpression.toleranceFoTo,
	// 		t2: data.phaseExpression.toleranceFoFrom
	// 	},
	// 	{
	// 		title: "Time Lost/Gained :",
	// 		value: data.timeLostGained,
	// 		valueIcon: <Icon type="icon-voy" />,
	// 		unit: " hrs",
	// 		type: "Time Lost",
	// 		typeIcon: <Icon type="icon-voy" />,
	// 		gwd: data.summary.gwDistance,
	// 		gwps: data.summary.gwPerSpeed,
	// 		gwc: null,
	// 		gwt: null,
	// 		ws: data.phaseExpression.warrSpeed,
	// 		su: data.phaseExpression.aboutSpeedFrom,
	// 		so: data.phaseExpression.aboutSpeedTo,
	// 		wc: null,
	// 		t1: null,
	// 		t2: null
	// 	},
	// 	{
	// 		title: "DO/GO Over/Under :",
	// 		value: data.dgoOverUnder,
	// 		valueIcon: <Icon type="icon-voy" />,
	// 		unit: " mt",
	// 		type: "DO/GO Under",
	// 		typeIcon: <Icon type="icon-voy" />,
	// 		gwd: data.summary.gwDistance,
	// 		gwps: data.summary.gwPerSpeed,
	// 		gwc: data.phaseExpression.gwDgoCons,
	// 		gwt: data.phaseExpression.gwDgoTime,
	// 		ws: data.phaseExpression.warrSpeed,
	// 		su: data.phaseExpression.aboutSpeedFrom,
	// 		so: data.phaseExpression.aboutSpeedTo,
	// 		wc: data.phaseExpression.warrDgoCons,
	// 		t1: data.phaseExpression.toleranceDgoTo,
	// 		t2: data.phaseExpression.toleranceDgoFrom
	// 	}
	// ];
};

export const getPhaseStatus = (val: number) => {
	if (val && val > 0) {
		return {
			color: "green",
			iconClass: "icon-rise"
		};
	} else {
		return {
			color: "red",
			iconClass: "icon-decline"
		};
	}
};
