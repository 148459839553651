import React, { FC } from "react";
import { NoonLabelProps } from "./type";
import style from "./index.module.less";
import classNames from "classnames";

const NoonLabel: FC<NoonLabelProps> = (props) => {
	const { isNecessary, title, children, className } = props;

	return (
		<div className={classNames(className, style["noon-label"])}>
			<div
				className={classNames(
					"noon-label-title",
					isNecessary && "noon-necessary"
				)}
			>
				{title}
			</div>
			<div className="noon-label-content">{children}</div>
		</div>
	);
};

export default NoonLabel;
