import React from "react";
import { ColumnsType } from "antd/es/table";
import { timestampToUtcMdhm } from "@/tools/date";
import { VoyPlanTableItemType } from "./type";

export const vpTableColumns: ColumnsType<VoyPlanTableItemType> = [
	{
		title: "ETD",
		dataIndex: "etd",
		key: "etd",
		align: "left",
		render(value, record, index) {
			return <div>{timestampToUtcMdhm(value)}</div>;
		}
	},
	{
		title: "ISSUED",
		dataIndex: "createTime",
		key: "createTime",
		align: "left",
		render(value, record, index) {
			return <div>{timestampToUtcMdhm(value)}</div>;
		}
	}
];
