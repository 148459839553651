import React, { FC } from "react";
import { TitleProps } from "./type";
import style from "./index.module.less";
import classNames from "classnames";

const Title: FC<TitleProps> = (props) => {
	const { text, suffixIcon, className } = props;

	return (
		<div className={classNames(style["fleet-title"], className)}>
			<p className="fleet-title-text">{text}</p>
			{suffixIcon ? suffixIcon : null}
		</div>
	);
};

export default Title;
