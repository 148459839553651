import React from "react";

import style from "./index.module.less";
import useIndex from "./hooks/useIndex";

const WordContainer: React.FC<{ url: string }> = ({ url }) => {
	const { previewRef } = useIndex(url);

	return <div className={style["fp-word-container"]} ref={previewRef}></div>;
};

export default WordContainer;
