import React, { FC } from "react";
import { NoonNextPortFormProps, NoonNextPortFormType } from "./type";
import { FleetInput, FleetTimePicker } from "@/components";
import { CommonPortSelect } from "@/common";
import style from "./index.module.less";
import { NoonLabel } from "..";
import dayjs from "dayjs";
import { FleetUtcTimePicker } from "../../../../components";

const NoonNextPortForm: FC<NoonNextPortFormProps> = (props) => {
	const { data, onChange } = props;

	return (
		<div className={style["noon-np-form-container"]}>
			<div className="noon-np-title">Next Port</div>
			<div className="noon-np-form">
				<div className="np-form-item">
					<NoonLabel title="Next Port" isNecessary={false}>
						<CommonPortSelect
							className="noon-np-input"
							activeItems={{ portCode: data.nextPort }}
							showKey={"portCode"}
							onFinish={(newItem, operateType) => {
								onChange({
									...props.data,
									nextPort: newItem.portName,
									unlocode: newItem.portCode
								});
							}}
						/>
					</NoonLabel>

					<NoonLabel title="UNLOCODE" isNecessary={false}>
						<FleetInput
							className="noon-np-input"
							value={data.unlocode}
							onChange={(val: NoonNextPortFormType["unlocode"]) => {
								onChange({ ...props.data, unlocode: val });
							}}
						/>
					</NoonLabel>
				</div>

				<NoonLabel title="ETA" isNecessary={false}>
					<FleetUtcTimePicker
						className="noon-np-input"
						value={Number(data.eta)}
						onChange={(val) => {
							onChange({ ...props.data, eta: val });
						}}
					/>
					<span className="eta-init">UTC</span>
				</NoonLabel>
			</div>
		</div>
	);
};

export default NoonNextPortForm;
