import { useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { passwordList } from "../source";
import { onRequest } from "@/api";

const useIndex = () => {
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const [passwordForm, setForm] = useState<{
		confirmPassword: string;
		oldPassword: string;
		newPassword: string;
	}>({
		oldPassword: "",
		newPassword: "",
		confirmPassword: ""
	});
	const changeForm = (prop, val) => {
		setForm({ ...passwordForm, [prop]: val });
	};
	const changePassword = () => {
		let text = "";
		for (let item of passwordList) {
			if (!Boolean(passwordForm[item.prop])) {
				text = `${item.En} is Empty!`;
				break;
			}
		}
		if (text != "") {
			reminder("error", text);
			return;
		}
		if (passwordForm.newPassword != passwordForm.confirmPassword) {
			reminder("error", "Failed to confirm password!");
			return;
		}
		onRequest(
			"updatePersonalPwdApi",
			{
				newPassword: passwordForm.newPassword,
				oldPassword: passwordForm.oldPassword
			},
			() => {},
			changePasswordSuccess,
			changePasswordError,
			dispatch,
			navigate
		);
	};
	const changePasswordError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const changePasswordSuccess = () => {
		reminder("success", "Save Success");
	};
	return {
		passwordForm,
		changeForm,
		changePassword
	};
};
export default useIndex;
