import { useAppSelector } from "@/hooks";
import { cacheStorage } from "@/storage";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import { Page404, PersonalCenter, Login } from "@/pages";
import { LayoutChildren } from "@/layout/router";
import { IconProps } from "@/components/Icon/interface";

export interface PageRouterProps {
	path?: string;
	element?: JSX.Element;
	key?: string | number;
	index?: boolean;
	children?: PageRouterProps[];
	label?: string;
	disabled?: boolean;
	hidden?: boolean;
	icon?: IconProps["type"];
}

export const routers: PageRouterProps[] = [].concat(LayoutChildren);

const RouteAuthFun = (routeList: PageRouterProps[]) => {
	return routeList.map((item) => {
		return (
			// @ts-ignore
			<Route
				path={item.path}
				key={item.key}
				element={item?.element}
				index={item?.index}
			>
				{item?.children && RouteAuthFun(item.children)}
			</Route>
		);
	});
};

export default function Router() {
	const { firstSelected } = useAppSelector((state) => state.GlobalStore);
	const access_token = cacheStorage.get("access_token");
	const _access_token = useAppSelector((state) => state.UserStore.access_token);
	const selectRouter = routers?.findIndex(
		(route) => route.key === firstSelected
	);
	const currentTo =
		selectRouter !== -1 ? firstSelected : (routers?.[0]?.key as string);
	console.log("currentTo", {
		currentTo,
		firstSelected,
		key: routers?.[0]?.key
	});
	return (
		<Routes>
			<Route path="/" element={<Navigate to={currentTo} />} />
			<Route
				path="/layout/legDetails"
				element={<PrivateRoute type="legDetails" />}
			></Route>
			<Route path="/layout" element={<PrivateRoute type="layout" />}>
				{RouteAuthFun(routers)}
			</Route>
			<Route path="/personal" element={<PersonalCenter />}></Route>
			<Route path="/login" element={<PublicRoute />}>
				<Route index={true} element={<Login />} />
			</Route>
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}
