import { Select } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";

import { CommonCompanySelectProps } from "./type";
import { CompanyItem } from "./components";

const CommonPortSelect: FC<CommonCompanySelectProps> = ({
	onFinish,
	activeItems,
	primaryKey,
	showKey,
	showCount,
	className,
	disabled,
	placeholder,
	allowClear
}) => {
	const {
		loading,
		list,
		open,
		loadMore,
		closePopup,
		focusPopup,
		searchValue,
		tagRender,
		handleSearch,
		handleItemSelect,
		handleLatitudeClose,
		selectValue
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled);

	return (
		<Select
			showSearch
			allowClear={allowClear}
			onClear={() => {
				onFinish?.(null, "del");
			}}
			disabled={disabled}
			className={className}
			placeholder={placeholder}
			value={selectValue}
			showCount={showCount}
			popupMatchSelectWidth={false}
			onDropdownVisibleChange={closePopup}
			tagRender={tagRender}
			mode={Array?.isArray(activeItems) ? "multiple" : undefined}
			open={open}
			// searchValue={searchValue}
			onSearch={handleSearch}
			onFocus={() => focusPopup()}
			searchValue={status === "num" ? searchValue : undefined}
			dropdownRender={() => (
				<CompanyItem
					loadMore={loadMore}
					list={list}
					onSelect={handleItemSelect}
					loading={loading}
				/>
			)}
		/>
	);
};

export default CommonPortSelect;
