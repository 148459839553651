import React, { forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { TimeAxisTimeLineProps, TimeAxisTimeLineRef } from "./type";
import { Dayjs } from "dayjs";
import useIndex from "./hooks/useIndex";

const TimeAxisTimeLine = forwardRef<TimeAxisTimeLineRef, TimeAxisTimeLineProps>(
	({ currentTime, onChange }, ref) => {
		const {
			currentDates,
			timeLineRef,
			scheduleRef,
			currentRef,
			timeLineInfoRef,
			currentTimeInfoRef,
			resetScheduleWidth,
			handleScheduleClick,
			handleDateSelect,
			handleMouseMove,
			handleMouseEnter,
			handleMouseLeave
		} = useIndex(currentTime, onChange);
		useImperativeHandle(ref, () => {
			return {
				onReset: (time: Dayjs) => resetScheduleWidth("reset", time)
			};
		});
		return (
			<div className={style["timeAxis-timeLine"]}>
				<div
					className="timeLine-container"
					ref={timeLineRef}
					onMouseOver={handleMouseEnter}
					onMouseOut={handleMouseLeave}
				>
					<ul className="timeLine-area">
						{currentDates?.current?.map((dateItem, dateIndex) => {
							return (
								<li
									key={dateIndex}
									onClick={(event) => {
										handleScheduleClick(event, dateItem, dateIndex);
									}}
								></li>
							);
						})}
					</ul>
					<div className="timeLine-schedule" ref={scheduleRef}></div>
					<span className="timeLine-current" ref={currentRef}></span>
					{/* <div className="timeLine-disabled" ref={disabledRef}></div> */}
				</div>
				<ul className="timeLine-date-container">
					{currentDates?.current?.map((dateItem, dateIndex) => {
						return (
							<li
								key={dateIndex}
								onClick={() => {
									handleDateSelect?.(dateItem, dateIndex);
								}}
							>{`${dateItem?.format("MMM")} ${dateItem?.get("date")}`}</li>
						);
					})}
				</ul>
				<div ref={timeLineInfoRef} className="timeLine-info">
					<div className="timeLine-info-container">18:00</div>
				</div>
				<div ref={currentTimeInfoRef} className="timeLine-current-info">
					<div className="current-info-container"></div>
				</div>
			</div>
		);
	}
);

export default TimeAxisTimeLine;
