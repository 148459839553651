import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { LegBasicInfoItemType, LegBasicInfoProps } from "./type";
import {
	Button,
	FleetInput,
	FormItem,
	Select,
	FleetRangePicker
} from "@/components";
import { conditionOptions } from "./source";

const LegBasicInfo: React.FC<LegBasicInfoProps> = ({
	item,
	onChange
	// onCommit
}) => {
	return (
		<div className={classNames(style["fleetLeg-basicInfo"])}>
			<div>Basic Info</div>
			<div className="basicInfo-container">
				<FormItem label="Condition:" type="row">
					<Select
						onChange={(val: LegBasicInfoItemType["condition"]) => {
							onChange?.("condition", val);
						}}
						options={conditionOptions}
						value={item?.condition}
						className="conditon"
					/>
				</FormItem>
				<FormItem label="Quotation:" type="row">
					<FleetInput
						value={item.quotation || ""}
						onChange={(val: LegBasicInfoItemType["quotation"]) => {
							onChange?.("quotation", val);
						}}
						className="quotation"
					/>
					<span className="commonText">USD</span>
				</FormItem>
			</div>
		</div>
	);
};

export default LegBasicInfo;
