export const routeSource = {
	"route-line-source": "route-line-layer",
	"route-point-source": "route-point-layer",
	"route-edit-line-source": "route-edit-line-layer",
	"route-point-current-source": "route-point-current-layer",
	"route-vessel-source": "route-vessel-layer"
};

export enum SigWaveColor {
	light = "#7AF4D8",
	moderate = "#CEFF85",
	heavy = "#FFE37F",
	severe = "#FF7F7F"
}
