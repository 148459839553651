import React, { FC, useCallback, useState } from "react";
import style from "./index.module.less";
import { Button, Image, Popover } from "@/components";
import { Breadcrumb } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useRouteChange } from "@/hooks";
import { LayoutNavMenu } from "./components";
import { loginOutAction } from "@/store/userSlice";

const LayoutNav: FC<{}> = (props) => {
	const dispatch = useAppDispatch();
	const searchParams = useLocation();
	const navigate = useNavigate();
	const [pathArray, setPathArray] = useState<string[]>([]);

	useRouteChange((location, pathList) => {
		pathList.shift();
		setPathArray(pathList);
	});

	const handleBackClick = () => {
		navigate(-1);
	};

	const handleExit = useCallback(() => {
		dispatch(loginOutAction(() => navigate("/login")));
	}, []);

	const handleJumpPersonal = useCallback(() => {
		navigate(`/personal${searchParams.search}`);
	}, [searchParams]);

	return (
		<div className={style["fleet-layout-nav"]}>
			<div className="layout-nav-breadcrumb">
				{pathArray.length >= 2 && (
					<Button
						type="default"
						className="breadcrumb-back-btn"
						onClick={handleBackClick}
					>
						Back
					</Button>
				)}

				<Breadcrumb
					items={pathArray.map((path) => {
						return { title: path };
					})}
				/>
			</div>
			<Popover
				render={(setIsOpen) => (
					<LayoutNavMenu
						onClose={() => {
							setIsOpen(false);
							handleJumpPersonal();
						}}
						onExit={handleExit}
					/>
				)}
			>
				<Image className="layout-nav-logo" src="/images/logo.png" />
			</Popover>
		</div>
	);
};

export default LayoutNav;
