import React, { ChangeEventHandler } from "react";
import classnames from "classnames";
import Textarea from "antd/es/input/TextArea";
import style from "./index.module.less";
import FleetIcon from "../Icon";
import { IconKeys } from "../Icon/icons";

const FleetTextarea: React.FC<{
	className?: string;
	onChange?: (val: string) => void;
	onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
	maxLength?: number;
	showCount?: boolean;
	label?: string;
	resize?: boolean;
	icon?: IconKeys;
	placeholder?: string;
	value?: string;
	disabled?: boolean;
	suffixIcon?: string;
	renderSuffixIcon?: () => React.ReactNode;
	allowClear?: boolean;
}> = ({
	className,
	onChange,
	onBlur,
	maxLength,
	showCount,
	label,
	value,
	resize = false,
	icon,
	placeholder = "disable resize",
	disabled,
	suffixIcon,
	renderSuffixIcon,
	allowClear = true
}) => {
	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
		onChange?.(event.target.value);
	};
	return (
		<div className={classnames(style["fleet-textarea"], className)}>
			{label && <span className="textarea-label">{label}</span>}
			<Textarea
				className={classnames("textarea", {
					"exist-label": label,
					"exist-icon": icon || suffixIcon
				})}
				disabled={disabled}
				onBlur={onBlur}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				showCount={showCount}
				maxLength={maxLength}
				style={{
					resize: resize ? "inline" : "none"
				}}
				allowClear={
					allowClear && {
						clearIcon: (
							<FleetIcon
								onClick={() => {
									onChange?.("");
								}}
								type="FleetClearIcon"
								outerLayerClassname="textarea-clear"
							/>
						)
					}
				}
			></Textarea>
			{renderSuffixIcon ? (
				renderSuffixIcon()
			) : suffixIcon ? (
				<span className={`font_family ${suffixIcon}`}></span>
			) : (
				icon && <FleetIcon type={icon} outerLayerClassname="textarea-prefix" />
			)}
		</div>
	);
};

export default FleetTextarea;
