import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// 布局测试使用, 随时删除
const MapboxExample = () => {
	const mapContainerRef = useRef();

	useEffect(() => {
		mapboxgl.accessToken =
			"pk.eyJ1IjoibGl0dGxlZml2ZTE5OTUiLCJhIjoiY2w1OWRtdTB3MmJqbTNjcXFpcWE4dDIxdSJ9.3ZH5BCZI085b4B9XVflZpg";

		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			zoom: 3,
			center: [7.5, 58],
			style: "mapbox://styles/mapbox/light-v11",
			antialias: true,
			projection: "mercator",
			trackResize: true
		});

		return () => {
			map.remove();
		};
	}, []);

	return (
		<div ref={mapContainerRef} style={{ height: "100%", width: "100%" }}></div>
	);
};

export default MapboxExample;
