import React, { FC } from "react";
import { LegBasicInfoProps } from "./type";
import styles from "./index.module.less";
import useIndex from "./hook/useIndex";
const LegBasicInfo: FC<LegBasicInfoProps> = ({ children }) => {
	const { basicInfo } = useIndex();
	return (
		<div className={styles.legBasicInfo}>
			<div className="ves-name">
				<div className="ves-label">{basicInfo?.vesselName}</div>
				<div className="ves-value">{basicInfo?.legTypeName}</div>
			</div>
			<div className="ves-no">
				<span>VN</span>
				<span style={{ margin: "0 2px" }}>:</span>
				<span className="vn">{basicInfo?.voyageNo}</span>
			</div>
			<div className="ves-button">{children}</div>
		</div>
	);
};

export default LegBasicInfo;