import { MutableRefObject, useEffect, useRef } from "react";
import { addSourceToMap } from "../utils";
import { GeoJSONSource, MapEventOf } from "mapbox-gl";
import { RouteCommonMapProps, RouteCommonMapRefType } from "../type";
import { onRequest } from "@/api";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { CommonCommitEvent } from "@/types/event";
import { getShortesPoints } from "../utils/calc";
import { useLegContext } from "../../../store";
import { generateWayLineString } from "../utils/route";
import {
	RouteWayTrackPointGeoJson,
	RouteWayPointItemType
} from "../types/route";
import { normalizeLongitude } from "../utils/lngLat";

const routeSource = {
	"route-line-source": "route-line-layer",
	"route-point-source": "route-point-layer",
	"route-edit-line-source": "route-edit-line-layer"
};

const useRoute = (
	mapboxGl: MutableRefObject<mapboxgl.Map>,
	onMapDragEnable: (type: "enable" | "disable") => void,
	onWayPointFocus?: RouteCommonMapProps["onWayPointFocus"],
	onResetDistance?: RouteCommonMapProps["onResetDistance"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const {
		route: {
			activeEditPlanId,
			dataSource,
			handleRoutePlanCrud,
			handleWayPointCrud,
			handleWayPointsReset
		}
	} = useLegContext();

	const mapRouteMenuRef = useRef<HTMLDivElement>(null);
	const activePoint = useRef<RouteWayPointItemType>(null);

	const operaterOption = useRef<{
		status: "newly" | "move" | "delete";
		updateing: boolean;
		isMove: boolean;
	}>({
		status: null,
		updateing: false,
		isMove: false
	});

	const handleOperaterOptioCommit: CommonCommitEvent<
		typeof operaterOption.current
	> = (item) => {
		operaterOption.current = {
			...operaterOption.current,
			...item
		};
	};

	const updateMapWayPointFront = () => {
		handleOperaterOptioCommit({ updateing: true });
	};

	const updateMapWayPointSuccess = (response) => {
		console.log("response", response);
		handleOperaterOptioCommit({ updateing: false });
		handleWayPointCrud?.("update", activePoint?.current);
		activePoint.current = null;
		onResetDistance?.({
			planId: activeEditPlanId?.current,
			distance: response?.data?.toFixed(2)
		});
	};

	const updateMapWayPointFailed = (error) => {
		handleOperaterOptioCommit({ updateing: false, status: null });
		handleWayPointsReset?.("trackPoint");
		activePoint.current = null;
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const updateMapWayPoint = () => {
		if (operaterOption?.current?.updateing) return;
		onRequest(
			"updateMapWayPointApi",
			{
				id: activePoint?.current?.id,
				seq: activePoint.current?.seq,
				lat: activePoint.current?.lat,
				lon: normalizeLongitude(activePoint?.current?.lon, "EPR"),
				planId: activePoint?.current?.planId
			},
			updateMapWayPointFront,
			updateMapWayPointSuccess,
			updateMapWayPointFailed,
			dispatch,
			navigate
		);
	};

	const loadSource = () => {
		addSourceToMap(mapboxGl.current, {
			sourceName: "route-line-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: dataSource?.current?.trackLines
				}
			}
		});
		addSourceToMap(mapboxGl.current, {
			sourceName: "route-point-source",
			source: {
				type: "geojson",
				data: {
					type: "FeatureCollection",
					features: dataSource?.current?.trackPoints
				}
			}
		});
	};

	const loadlayers = () => {
		mapboxGl?.current?.addLayer({
			id: routeSource["route-line-source"],
			source: "route-line-source",
			type: "line",
			layout: {
				"line-join": "round"
			},
			paint: {
				"line-color": "#8186FE",
				"line-width": 4,
				"line-dasharray": [4, 2]
			}
		});
		mapboxGl?.current?.addLayer({
			id: routeSource["route-edit-line-source"],
			source: "route-point-source",
			type: "line",
			filter: ["!=", "$type", "Point"],
			layout: {
				"line-join": "round"
			},
			paint: {
				"line-color": "#8186FE",
				"line-width": 4,
				"line-dasharray": [4, 2]
			}
		});
		mapboxGl?.current?.addLayer({
			id: routeSource["route-point-source"],
			source: "route-point-source",
			type: "symbol",
			filter: ["==", "$type", "Point"],
			paint: {
				// "circle-radius": 4,
				// "circle-color": "#FFF",
				// "circle-stroke-width": 3,
				// "circle-stroke-color": "#676DFF"
			},
			layout: {
				"icon-image": "routeWayPointIcon",
				"icon-size": 0.3,
				"icon-allow-overlap": true
			}
		});
	};

	const handlePointMouseMove = (event: MapEventOf<"mousemove">) => {
		const canvas = mapboxGl?.current?.getCanvas();
		const { trackPoints } = dataSource?.current,
			origin = dataSource?.current?.origin?.slice(),
			currentLineIndex = trackPoints?.findIndex(
				(selectItem) =>
					selectItem?.properties?.planId === activeEditPlanId?.current &&
					selectItem?.geometry?.type === "MultiLineString"
			),
			currentPointIndex = trackPoints?.findIndex(
				(selectPoint) =>
					selectPoint?.properties?.id === activePoint?.current?.id
			),
			originIndex = origin?.findIndex(
				(selectPoint) => selectPoint?.id === activePoint?.current?.id
			);

		trackPoints?.splice(currentPointIndex, 1, {
			...trackPoints?.[currentPointIndex],
			geometry: {
				type: "Point",
				coordinates: [
					normalizeLongitude(event?.lngLat?.lng),
					event?.lngLat?.lat
				]
			}
		});
		origin?.splice(originIndex, 1, {
			...origin[originIndex],
			lat: event?.lngLat?.lat,
			lon: normalizeLongitude(event?.lngLat?.lng, "EPR")
		});
		trackPoints?.splice(
			currentLineIndex,
			1,
			generateWayLineString(activeEditPlanId?.current, origin)
		);

		activePoint.current = {
			...activePoint.current,
			lat: event?.lngLat?.lat,
			lon:
				event?.lngLat?.lng < 0 ? 360 + event?.lngLat?.lng : event?.lngLat?.lng
		};
		handleTrackPointCommit(trackPoints);
		handleOperaterOptioCommit({ isMove: true });
	};

	const deleteMapWayPointSuccess = (response) => {
		handleOperaterOptioCommit({ updateing: false, status: null });
		handleWayPointCrud?.("delete", activePoint?.current);
		onResetDistance?.({
			planId: activeEditPlanId?.current,
			distance: response?.data?.toFixed(2)
		});
		activePoint.current = null;
		mapRouteMenuRef.current.style.display = "none";
		reminder("success", "Remove Successful!");
	};

	const deleteMapWayPointFailed = (error) => {
		handleOperaterOptioCommit({ updateing: false, status: null });

		mapRouteMenuRef.current.style.display = "none";
		activePoint.current = null;
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const addMapWayPointSuccess = (response) => {
		handleWayPointCrud?.("newly", response?.data?.wayPoint);
		handleOperaterOptioCommit({ updateing: false, status: null });
		activePoint.current = null;
		mapRouteMenuRef.current.style.display = "none";
		reminder("success", "Add Successful!");
		onResetDistance?.({
			planId: activeEditPlanId?.current,
			distance: response?.data?.totalDist?.toFixed(2)
		});
	};

	const handlePointRemove = (pointItem?: RouteWayPointItemType) => {
		if (pointItem) {
			return;
		}
		console.log("activePoint", activePoint?.current);
		switch (operaterOption?.current?.status) {
			case "delete":
				onRequest(
					"deleteMapWayPointApi",
					{ id: activePoint?.current?.id },
					updateMapWayPointFront,
					deleteMapWayPointSuccess,
					deleteMapWayPointFailed,
					dispatch,
					navigate
				);
				break;
			case "newly":
				onRequest(
					"addMapWayPointApi",
					{
						lat: activePoint?.current?.lat,
						lon: normalizeLongitude(activePoint?.current?.lon, "EPR"),
						planId: activeEditPlanId.current,
						nav: "RL",
						seq:
							getShortesPoints(
								handleRoutePlanCrud("get", activeEditPlanId?.current),
								activePoint?.current
							) + 1
					},
					updateMapWayPointFront,
					addMapWayPointSuccess,
					deleteMapWayPointFailed,
					dispatch,
					navigate
				);
				break;
			default:
				break;
		}
	};

	const handlePointMouseDown = (event: MapEventOf<"mousedown">) => {
		console.log("handlePointMouseDown", event, event?.features);
		event?.originalEvent?.stopImmediatePropagation();
		if (!activeEditPlanId?.current || operaterOption?.current?.updateing)
			return;
		// event?.originalEvent?.stopImmediatePropagation();
		activePoint.current = event?.features?.[0]
			?.properties as RouteWayPointItemType;
		if (event?.originalEvent?.button === 0) {
			mapRouteMenuRef.current.style.display = "none";
			const canvas = mapboxGl?.current?.getCanvas();
			canvas.style.cursor = "grabbing";
			onMapDragEnable?.("disable");
			handleOperaterOptioCommit({ status: "move", isMove: false });
			handleTrackPointFocus("fromMap", activePoint?.current);
			mapboxGl?.current?.on("mousemove", handlePointMouseMove);
			return;
		}

		if (event?.originalEvent?.button === 2) {
			mapRouteMenuRef.current.style.display = "block";
			mapRouteMenuRef.current.style.left = `${event.originalEvent.offsetX}px`;
			mapRouteMenuRef.current.style.top = `${event.originalEvent.offsetY}px`;
			mapRouteMenuRef.current.innerText = "remove";
			handleOperaterOptioCommit({ status: "delete", isMove: false });
		}
	};

	const handlePointMouseUp = (event: MapEventOf<"mouseup">) => {
		if (operaterOption?.current?.status !== "move") return;
		const canvas = mapboxGl?.current?.getCanvas();
		mapboxGl?.current?.off("mousemove", handlePointMouseMove);
		onMapDragEnable?.("enable");
		canvas.style.cursor = "grab";
		if (operaterOption.current.isMove) {
			updateMapWayPoint?.();
		}
		handleOperaterOptioCommit({ status: null, isMove: false });
	};

	const handlePointContextMenu = (event: MapEventOf<"contextmenu">) => {
		debugger;
		if (
			!activeEditPlanId?.current ||
			operaterOption?.current?.status === "move"
		) {
			return;
		}
		const features = mapboxGl?.current?.queryRenderedFeatures(event?.point),
			groupFeatures = features?.filter(
				(feature) => feature?.layer?.id === routeSource["route-point-source"]
			);
		console.log("handlePointContextMenu", {
			features,
			groupFeatures
		});
		if (groupFeatures?.length > 0) return;
		mapRouteMenuRef.current.style.display = "block";
		mapRouteMenuRef.current.style.left = `${event.originalEvent.offsetX}px`;
		mapRouteMenuRef.current.style.top = `${event.originalEvent.offsetY}px`;
		mapRouteMenuRef.current.innerText = "add";
		activePoint.current = {
			...activePoint.current,
			lat: event?.lngLat?.lat,
			lon: event?.lngLat?.lng
		};
		handleOperaterOptioCommit({ status: "newly", isMove: false });
	};

	const handleTrackPointFocus: RouteCommonMapRefType["focusWayTrackPoint"] = (
		type,
		item
	) => {
		const feature = mapboxGl?.current?.querySourceFeatures(
			"route-point-source",
			{
				filter: ["==", "id", item?.id]
			}
		)?.[0] as RouteWayTrackPointGeoJson;
		if (feature) {
			// mapboxGl?.current?.flyTo(
			// 	{
			// 		center: [item?.lon, item?.lat],
			// 		duration: 500
			// 	},
			// 	{
			// 		moveend: "FLY_END"
			// 	}
			// );
			type === "fromMap" && onWayPointFocus?.(item);
			mapboxGl?.current?.setLayoutProperty(
				routeSource["route-point-source"],
				"icon-image",
				[
					"case",
					["==", ["get", "id"], feature?.properties?.id],
					"routeWayPointSelectedIcon",
					"routeWayPointIcon"
				]
			);
			setTimeout(() => {
				mapboxGl?.current?.setLayoutProperty(
					routeSource["route-point-source"],
					"icon-image",
					"routeWayPointIcon"
				);
			}, 2000);
		}
	};

	const handleRouteListen = () => {
		mapboxGl?.current?.on(
			"mousedown",
			routeSource["route-point-source"],
			handlePointMouseDown
		);
		mapboxGl?.current?.on(
			"mouseup",
			routeSource["route-point-source"],
			handlePointMouseUp
		);
		// );normalized = ((longitude % 360) + 360) % 360;
		mapboxGl?.current?.on("contextmenu", handlePointContextMenu);
		mapboxGl?.current?.on("click", (event) => {
			console.log("click event", {
				lng: event?.lngLat?.lng,
				transLng: ((event?.lngLat?.lng % 360) + 360) % 360
			});
		});
	};

	const handleTrackPointCommit = (items: RouteWayTrackPointGeoJson[]) => {
		const currentSource = mapboxGl?.current?.getSource(
			"route-point-source"
		) as GeoJSONSource;
		console.log("items", items);
		currentSource?.setData({
			type: "FeatureCollection",
			features: items
		});
	};

	const handleTrackLineCommit = (items: RouteWayTrackPointGeoJson[]) => {
		const currentSource = mapboxGl?.current?.getSource(
			"route-line-source"
		) as GeoJSONSource;
		currentSource?.setData({
			type: "FeatureCollection",
			features: items
		});
	};

	const handleDocumentClick = (event: MouseEvent) => {
		if (["delete", "newly"]?.includes(operaterOption?.current?.status)) {
			mapRouteMenuRef.current.style.display = "none";
			handleOperaterOptioCommit({ status: null });
		}
	};

	useEffect(() => {
		document?.addEventListener("click", handleDocumentClick);
		return () => {
			document?.removeEventListener("click", handleDocumentClick);
		};
	}, []);

	return {
		mapRouteMenuRef,
		loadSource,
		loadlayers,
		handleRouteListen,
		handlePointRemove,
		handleTrackLineCommit,
		handleTrackPointCommit,
		handleTrackPointFocus
	};
};

export default useRoute;
