import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";
import { FavoritesList, RouteImportDialogStateType } from "../type";
import {
	AddRoutePlanByFileType,
	CommonIdType,
	importByFavoriteParamsType
} from "@/api/leg/type";
const useIndex = (closeDialog, finishCB, routeInfo) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	// DidMount
	useEffect(() => {
		initPageData();
	}, []);
	const initPageData = () => {
		// getReferList();
	};

	// state
	const [referList, setReferList] = useState<FavoritesList>();

	const [routeImportDialogState, setRouteImportDialogState] =
		useState<RouteImportDialogStateType>({
			importType: "algorithm"
		});

	// hanlde
	const hanleCloseDialog = (closeDialog) => {
		closeDialog && closeDialog();
	};
	const onConfirm = () => {
		switch (routeImportDialogState?.importType) {
			case "algorithm":
				addRoutePlan({ id: routeInfo?.id });
				break;
			case "custom":
				if (!routeImportDialogState.file) {
					return reminder("error", "请先导入文件");
				}
				addRoutePlanByFile({
					routeId: routeInfo?.id,
					file: routeImportDialogState?.file
				});

				break;
			case "refer":
				console.log(
					"refer~~~~~~~~~~~~~",
					routeImportDialogState?.referId,
					routeInfo?.id
				);

				importByFavorite({
					favoriteId: routeImportDialogState?.referId,
					routeId: routeInfo?.id
				});
				break;
		}
	};
	const handleAddFile = (file) => {
		console.log("1234567", file);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file2", file, formData.get("file"));
		setRouteImportDialogState({
			...routeImportDialogState,
			file: formData
		});
	};
	const handleClickTab = (activeKey) => {
		setRouteImportDialogState({
			// ...routeImportDialogState,
			importType: activeKey
		});
		if (activeKey === "refer") {
			getRouteFavoritesList({ id: routeInfo?.id });
		}
	};
	const handleSelectRefer = (item) => {
		setRouteImportDialogState({
			...routeImportDialogState,
			referId: item
		});
	};
	const handleCancelCollectPlan = (id: string) => {
		cancelCollectPlan({ id });
	};

	//API
	// API - addRoutePlan
	const addRoutePlanFront = () => {
		setLoading(true);
	};
	const addRoutePlanSuccess = () => {
		finishCB && finishCB();
		closeDialog && closeDialog();
		setLoading(false);
	};
	const addRoutePlanFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const addRoutePlan = (params: { id: string }) => {
		onRequest(
			"addRoutePlanApi",
			params,
			addRoutePlanFront,
			addRoutePlanSuccess,
			addRoutePlanFailed,
			dispatch,
			navigate
		);
	};

	// API - addRoutePlanByFile
	const addRoutePlanByFileFront = () => {
		setLoading(true);
	};
	const addRoutePlanByFileSuccess = () => {
		finishCB && finishCB();
		closeDialog && closeDialog();
		setLoading(false);
	};
	const addRoutePlanByFileFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const addRoutePlanByFile = (params: AddRoutePlanByFileType) => {
		onRequest(
			"addRoutePlanByFileApi",
			params,
			addRoutePlanByFileFront,
			addRoutePlanByFileSuccess,
			addRoutePlanByFileFailed,
			dispatch,
			navigate
		);
	};

	// API - getRouteFavoritesList
	const getRouteFavoritesListFront = () => {
		setLoading(true);
	};
	const getRouteFavoritesListSuccess = (data) => {
		console.log("看看数据啊啊啊啊！！！！！", data);

		setLoading(false);
		setReferList(data?.data);
	};
	const getRouteFavoritesListFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getRouteFavoritesList = (params: CommonIdType) => {
		onRequest(
			"getRouteFavoritesListApi",
			params,
			getRouteFavoritesListFront,
			getRouteFavoritesListSuccess,
			getRouteFavoritesListFailed,
			dispatch,
			navigate
		);
	};
	// API - cancelCollectPlan
	const cancelCollectPlanFront = () => {
		setLoading(true);
	};
	const cancelCollectPlanSuccess = () => {
		getRouteFavoritesList({ id: routeInfo?.id });
		setLoading(false);
		finishCB && finishCB();
	};
	const cancelCollectPlanFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const cancelCollectPlan = (params?: { id: string }) => {
		onRequest(
			"cancelCollectPlanApi",
			params,
			cancelCollectPlanFront,
			cancelCollectPlanSuccess,
			cancelCollectPlanFailed,
			dispatch,
			navigate
		);
	};

	// API - importByFavoriteApi
	const importByFavoriteFront = () => {
		setLoading(true);
	};
	const importByFavoriteSuccess = () => {
		setLoading(false);
		finishCB && finishCB();
		closeDialog && closeDialog();
	};
	const importByFavoriteFailed = (error) => {
		setLoading(false);
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const importByFavorite = (params: importByFavoriteParamsType) => {
		onRequest(
			"importByFavoriteApi",
			params,
			importByFavoriteFront,
			importByFavoriteSuccess,
			importByFavoriteFailed,
			dispatch,
			navigate
		);
	};

	return {
		loading,
		hanleCloseDialog,
		onConfirm,
		handleClickTab,
		routeImportDialogState,
		handleAddFile,
		referList,
		handleSelectRefer,
		handleCancelCollectPlan
	};
};

export default useIndex;
