import { Button, Dialog, Icon } from "@/components";
import React, { FC } from "react";
import { timestampToUtcMdhm, timestampToUtcObj } from "@/tools/date";
import { formatNumber } from "@/tools/amount";
import styles from "./index.module.less";
import { VoyageDeviationReportProps } from "./type";
import {
	ReportTitleWithIcon,
	ReportDescriptions,
	Fraction
} from "../../common";
import useIndex from "./hooks/useIndex";
import { DistanceTable, TwoColTable } from "./components";

const VoyageDeviationReport: FC<VoyageDeviationReportProps> = ({
	visible,
	onClose,
	reportId
}) => {
	const { loading, handleConfirm, reportContent } = useIndex(reportId);

	console.log("reportContent: ", reportContent);

	return (
		<Dialog
			mode="fixed"
			closeable={true}
			drawer={false}
			hide={!visible}
			onClose={onClose}
			loading={loading}
		>
			<div className={styles["voyageDeviationReport"]}>
				<div className="voyageDeviationReport-header">
					<Icon type="icon-close2" className="close-btn" onClick={onClose} />
				</div>
				<div className="voyageDeviationReport-main fleet-nonScrollBar">
					<div id="main-voyageDeviationReportPdf">
						<div className="logo">
							<img
								className="logo-bg"
								src="/images/leg/plan/leg_plan_report_bg.svg"
							/>
							<img
								className="logo-title"
								src="/images/leg/report/leg_deviation_report_title.svg"
							/>
						</div>
						<ReportTitleWithIcon
							title="Voyage Basics"
							icon={<Icon type="icon-voy" />}
						/>
						<ReportDescriptions
							data={[
								{
									label: "Vessel Name",
									data: reportContent?.voyageBasics?.vesselName
								},
								{
									label: "Deviation From",
									data: reportContent?.voyageBasics?.depPort
								},
								{
									label: "",
									data: reportContent?.voyageBasics?.atd
										? timestampToUtcMdhm(reportContent?.voyageBasics?.atd)
										: "---"
								},
								{
									label: "Prepared For",
									data: reportContent?.voyageBasics?.companyName
								},
								{
									label: "Deviation To",
									data: reportContent?.voyageBasics?.arrPort
								},
								{
									label: "",
									data: reportContent?.voyageBasics?.ata
										? timestampToUtcMdhm(reportContent?.voyageBasics?.ata)
										: "---"
								}
							]}
						/>
						<div className="mutilTable">
							<div className="table">
								<ReportTitleWithIcon
									title="CP Terms"
									icon={<Icon type="icon-report" />}
								/>
								<TwoColTable
									data={[
										{
											label: "Speed (kts)",
											value:
												reportContent?.cp?.speed ||
												reportContent?.cp?.speed === 0
													? formatNumber(reportContent?.cp?.speed, 2)
													: "---"
										},
										{
											label: "FO Consumption (mt)",
											value:
												reportContent?.cp?.foConsumption ||
												reportContent?.cp?.foConsumption === 0
													? formatNumber(reportContent?.cp?.foConsumption, 3)
													: "---"
										},
										{
											label: "DO/GO Consumption (mt)",
											value:
												reportContent?.cp?.dgoConsumption ||
												reportContent?.cp?.dgoConsumption === 0
													? formatNumber(reportContent?.cp?.dgoConsumption, 3)
													: "---"
										}
									]}
								/>
							</div>
							{/* <div className="table">
								<ReportTitleWithIcon
									title="Report Summary"
									icon={<Icon type="icon-report" />}
								/>
								<TwoColTable
									data={[
										{ label: "Extra Distance (nm)", value: "69.66" },
										{ label: "Total extra Time", value: "69.66" },
										{
											label: "FO Total extra Consumption (mt)",
											value: "69.66"
										},
										{
											label: "DO/GO Total extra Consumption (mt)",
											value: "69.66"
										}
									]}
								/>
							</div> */}
						</div>
						<div className="divider"></div>
						<ReportTitleWithIcon
							title="Distance Analysis"
							icon={<Icon type="icon-report" />}
						/>
						<DistanceTable
							data={{
								actualRouteDistance: reportContent?.detail?.actualDist,
								recommendedRouteDistance: reportContent?.detail?.recDist,
								extraDistance: reportContent?.detail?.extraDist
							}}
						/>
						<div className="mutilTable">
							<div className="table">
								<ReportTitleWithIcon
									title="Time Analysis"
									icon={<Icon type="icon-report" />}
								/>
								<div className="border-box">
									<table className="time-table">
										<tr>
											<td className="first-col">{`Extra Time (hrs)`}</td>
											<td className="hot-text">
												{reportContent?.detail?.extraTime ||
												reportContent?.detail?.extraTime === 0
													? formatNumber(reportContent?.detail?.extraTime, 2)
													: "---"}
											</td>
										</tr>
									</table>
								</div>
							</div>
							<div className="table">
								<ReportTitleWithIcon
									title="Consumption Analysis"
									icon={<Icon type="icon-report" />}
								/>
								<div className="border-box">
									<table className="consumption-table">
										<tr>
											<td className="first-col" rowSpan={2}>
												{`Extra Consumption (mt)`}
											</td>
											<td>FO</td>
											<td>DO/GO</td>
										</tr>
										<tr>
											<td className="hot-text">
												{reportContent?.detail?.foCons ||
												reportContent?.detail?.foCons === 0
													? formatNumber(reportContent?.detail?.foCons, 3)
													: "---"}
											</td>
											<td className="hot-text">
												{reportContent?.detail?.dogoCons ||
												reportContent?.detail?.dogoCons === 0
													? formatNumber(reportContent?.detail?.dogoCons, 3)
													: "---"}
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
						<div className="formulas">
							<div className="formula">
								<span>{`Extra Time (hrs)`}</span>
								<span>=</span>
								<span>
									<Fraction
										numerator={"Deviation Distance"}
										denominator={"CP Speed"}
									/>
								</span>
								<span>=</span>
								<span>
									<Fraction
										numerator={
											reportContent?.detail?.extraDist ||
											reportContent?.detail?.extraDist === 0
												? formatNumber(reportContent?.detail?.extraDist, 1)
												: ""
										}
										denominator={
											reportContent?.cp?.speed || reportContent?.cp?.speed === 0
												? formatNumber(reportContent?.cp?.speed, 2)
												: ""
										}
									/>
								</span>
								<span>=</span>
								<span>
									{reportContent?.detail?.extraTime ||
									reportContent?.detail?.extraTime === 0
										? formatNumber(reportContent?.detail?.extraTime, 2)
										: "---"}
								</span>
								<span>(hrs)</span>
							</div>
							<div className="formula">
								<span>{`FO Extra Consumption (mt)`}</span>
								<span>=</span>
								<Fraction
									numerator={"Deviation Distance"}
									denominator={"CP Speed"}
								/>
								<span>×</span>
								<Fraction numerator={"Daily FO Consumption"} denominator={24} />
								<span>=</span>
								<Fraction
									numerator={
										reportContent?.detail?.extraDist ||
										reportContent?.detail?.extraDist === 0
											? formatNumber(reportContent?.detail?.extraDist, 1)
											: ""
									}
									denominator={
										reportContent?.cp?.speed || reportContent?.cp?.speed === 0
											? formatNumber(reportContent?.cp?.speed, 2)
											: ""
									}
								/>
								<span>×</span>
								<Fraction
									numerator={
										reportContent?.cp?.foConsumption ||
										reportContent?.cp?.foConsumption === 0
											? formatNumber(reportContent?.cp?.foConsumption, 3)
											: ""
									}
									denominator={24}
								/>
								<span>=</span>
								<span>
									{reportContent?.detail?.foCons ||
									reportContent?.detail?.foCons === 0
										? formatNumber(reportContent?.detail?.foCons, 3)
										: "---"}
								</span>
								<span>(mt)</span>
							</div>
							<div className="formula">
								<span>{`DO/GO Extra Consumption (mt)`}</span>
								<span>=</span>
								<Fraction
									numerator={"Deviation Distance"}
									denominator={"CP Speed"}
								/>
								<span>×</span>
								<Fraction numerator={"Daily DO Consumption"} denominator={24} />
								<span>=</span>
								<Fraction
									numerator={
										reportContent?.detail?.extraDist ||
										reportContent?.detail?.extraDist === 0
											? formatNumber(reportContent?.detail?.extraDist, 1)
											: ""
									}
									denominator={
										reportContent?.cp?.speed || reportContent?.cp?.speed === 0
											? formatNumber(reportContent?.cp?.speed, 2)
											: ""
									}
								/>
								<span>×</span>
								<Fraction
									numerator={
										reportContent?.cp?.dgoConsumption ||
										reportContent?.cp?.dgoConsumption === 0
											? formatNumber(reportContent?.cp?.dgoConsumption, 3)
											: ""
									}
									denominator={24}
								/>
								<span>=</span>
								<span>
									{reportContent?.detail?.dogoCons ||
									reportContent?.detail?.dogoCons === 0
										? formatNumber(reportContent?.detail?.dogoCons, 3)
										: "---"}
								</span>
								<span>(mt)</span>
							</div>
						</div>
						<div className="map"></div>
						<div className="map-tip">
							<span>{`（红线图例）Actual Route`}</span>
							<span>{`（虚线图例）Recommended Route`}</span>
						</div>
					</div>
				</div>
				<div className="voyageDeviationReport-footer">
					<Button
						onClick={() => {
							handleConfirm();
						}}
					>
						Export PDF
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default VoyageDeviationReport;
