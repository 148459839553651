import toast from "react-hot-toast";
export const message = {
	success: (text: string) => {
		toast.success(text, {
			position: "top-right",
			duration: 2000
		});
	},
	error: (text: string) => {
		toast.error(text, {
			position: "top-right",
			duration: 2000
		});
	},
	loading: (text: string) => {
		toast.loading(text, {
			position: "top-right"
		});
	},
	dismiss: (messageId: any) => {
		toast.dismiss(messageId);
	}
};
