import { ColumnsType } from "antd/es/table";
import { VoyDataType } from "./type";

export const columns: ColumnsType<VoyDataType> = [
	{
		title: "Voy No.",
		dataIndex: "voyageNo",
		key: "voyageNo",
		width: "120",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		title: "Vessel",
		dataIndex: "vesselName",
		key: "vesselName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "IMO",
		dataIndex: "imo",
		key: "imo",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Company",
		dataIndex: "companyName",
		key: "companyName",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Role",
		dataIndex: "customerRole",
		key: "customerRole",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Create Time",
		dataIndex: "orderDate",
		key: "orderDate",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Voy Type",
		dataIndex: "voyageType",
		key: "voyageType",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Service",
		dataIndex: "service",
		key: "service",
		width: "120",
		align: "center",
		ellipsis: true
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		width: "120",
		align: "center",
		ellipsis: true
	}
];
