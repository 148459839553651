import { CommonOptionType } from "@/types/common";
import { latitudeUnitType, longitudeUnitType, LatLngRangeType } from "./type";

export const latitudeUnitOptions: CommonOptionType<
	latitudeUnitType,
	latitudeUnitType
>[] = [
	{ label: "N", value: "N" },
	{ label: "S", value: "S" }
];

export const longitudeUnitOptions: CommonOptionType<
	longitudeUnitType,
	longitudeUnitType
>[] = [
	{ label: "E", value: "E" },
	{ label: "W", value: "W" }
];

export const latLngRange: LatLngRangeType = {
	latitude: {
		min: 0,
		max: 90
	},
	longitude: {
		min: 0,
		max: 180
	}
};
