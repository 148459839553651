import React, { FC } from "react";
import { ReportTypeSelectProps } from "./type";
import { Select, FormItem } from "@/components";
import { reportTypeOptions } from "./source";
import style from "./index.module.less";
import classNames from "classnames";

const ReportTypeSelect: FC<ReportTypeSelectProps> = (props) => {
	const { value, onChange, className } = props;

	return (
		<div className={classNames(className, style["report-type-select"])}>
			<Select
				className="rt-select"
				onChange={(val: ReportTypeSelectProps["value"]) => {
					onChange(val);
				}}
				options={reportTypeOptions}
				value={value}
			/>
		</div>
	);
};

export default ReportTypeSelect;
