import React from "react";
import classnames from "classnames";
import { setFirstSelected } from "@/store/globalSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, useReminder } from "@/hooks";
import { Icon } from "@/components";
import { MenuItemProps } from "./type";
import style from "./index.module.less";

const SiderMenuItem: React.FC<MenuItemProps> = ({
	keyPath,
	disabled,
	label,
	icon,
	isCarryParameter
}) => {
	const { firstSelected } = useAppSelector((state) => state.GlobalStore);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const isSelected = firstSelected?.startsWith(keyPath);

	const handleJump = () => {
		if (disabled) {
			reminder("warning", "Not yet open, please stay tuned");
			return;
		}

		if (isSelected) return;
		dispatch(setFirstSelected(keyPath));
		const currentPath = keyPath + (isCarryParameter ? location?.search : "");
		navigate(currentPath);
	};

	return (
		<div
			onClick={handleJump}
			className={classnames(style["layout-siderMenu-item"], {
				[style["layout-siderMenu-item--active"]]: isSelected,
				[style["layout-siderMenu-item--disabled"]]: disabled
			})}
		>
			<Icon type={icon} className="menu-icon" />
			<span className="menu-label">{label}</span>
		</div>
	);
};

export default SiderMenuItem;
