import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { AisType } from "./type";
import { convertDdToDm } from "@/tools/latLong";
import { DataType } from "@/components/Table";

export const AISTableColumns: ColumnsType<DataType> = [
	{
		title: "NO",
		dataIndex: "index",
		key: "index",
		width: 80,
		render: (text, record, index) => {
			return index + 1;
		}
	},
	{
		title: "Date",
		dataIndex: "time",
		key: "time",
		width: 130,
		render: (text, record) => {
			return dayjs(text)?.utc()?.format("YYYY-MM-DD HH:mm:ss");
		}
	},
	{
		title: "Position",
		dataIndex: "lat",
		key: "lat",
		width: 150,
		render: (text, record) => {
			if (!record?.lat || !record?.lon) {
				return "-";
			}
			const {
				degree: latDegree,
				minute: latMinute,
				unit: latUnit
			} = convertDdToDm(record?.lat, true);
			const {
				degree: lonDegree,
				minute: lonMinute,
				unit: lonUnit
			} = convertDdToDm(record?.lon, false);
			const latStr = `${latDegree}°${latMinute}${latUnit}`;
			const lonStr = `${lonDegree}°${lonMinute}${lonUnit}`;
			return `${latStr} / ${lonStr}`;
		}
	},
	{
		title: "Speed (kts)",
		dataIndex: "speed",
		key: "speed",
		width: 80
	},

	{
		title: "Course",
		dataIndex: "cog",
		key: "cog"
	}
];
