import React, { forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { LegReportMapRefProps, LegReportMapRefType } from "./type";
import useIndex from "./hooks/useIndex";
import classNames from "classnames";

const LegReportMap = forwardRef<LegReportMapRefType, LegReportMapRefProps>(
	({ className }, ref) => {
		const { mapContainer, getCurrentMapInfo, handleAisItemsLoad } = useIndex();

		useImperativeHandle(ref, () => {
			return {
				onAisItemsLoad: handleAisItemsLoad,
				getMapInfo: getCurrentMapInfo
			};
		});

		return (
			<div
				className={classNames(style["legReport-map"], className)}
				ref={mapContainer}
			></div>
		);
	}
);

export default LegReportMap;
