import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { VoyLegTableItemType, VoyLegTableProps } from "./type";
import { voyLegTableColumn } from "./source";
import { Button, FleetInput, Table } from "@/components";
import useIndex from "./hook/useHook";
import LegAdd from "@/pages/leg/common/add";
const VoyLegTable: React.FC<VoyLegTableProps> = ({ voyageId }) => {
	const {
		loading,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		handleRowDblClick,
		handleCloseLegAdd,
		handleOpenLegAdd,
		legAddDialogVisible,
		getLegPage
	} = useIndex(voyageId);
	return (
		<div className={classNames(style["fleet-voyage-leg"])}>
			<div className="leg-header">
				<div className="leg-title">Leg</div>
				<Button
					onClick={() => {
						handleOpenLegAdd();
					}}
				>
					Add New Leg
				</Button>
			</div>
			<div className="leg-body">
				<Table
					columns={[...voyLegTableColumn]}
					id="id"
					dataSource={dataSource}
					rowSelection={{
						selectedRowKeys,
						onChange: onSelectChange
					}}
					pagination={{
						current: pager.pageNum,
						pageSize: pager.pageSize,
						total: pager.total,
						onChange: onPageChange,
						showSizeChanger: false
					}}
					onRow={(item) => {
						return {
							onDoubleClick: () => {
								handleRowDblClick(item);
							}
						};
					}}
				/>
			</div>
			<LegAdd
				onClose={handleCloseLegAdd}
				visible={legAddDialogVisible}
				voyageId={voyageId}
				afterConfirm={() => {
					getLegPage();
				}}
			/>
		</div>
	);
};

export default VoyLegTable;
