import mapboxgl from "mapbox-gl";
import style from "./index.module.less";
import { forwardRef, useEffect, useRef } from "react";
import { MapProps } from "./type";
import React from "react";

console.log("style", style);

const Map = forwardRef<{}, MapProps>(({ className }, ref) => {
	const mapContainer = useRef<HTMLDivElement>(null);
	const mapbox = useRef<mapboxgl.Map>(null);
	const init = () => {
		mapbox.current = new mapboxgl.Map({
			container: mapContainer.current,
			accessToken:
				"pk.eyJ1IjoibGl0dGxlZml2ZTE5OTUiLCJhIjoiY2w1OWRtdTB3MmJqbTNjcXFpcWE4dDIxdSJ9.3ZH5BCZI085b4B9XVflZpg",
			style: "mapbox://styles/littlefive1995/clpgntuil00h201p97zby73r2",
			dragPan: true,
			center: { lng: 105.78, lat: 32.99 }
		});
	};

	useEffect(() => {
		if (!mapbox.current) {
			init();
		}
		return () => {
			mapbox.current?.remove();
		};
	}, []);

	return <div ref={mapContainer} className={style["base-map"]}></div>;
});

export default Map;
