import React, { FC, memo } from "react";
import style from "./index.module.less";
import { typhoonOptions } from "./source";
import classnames from "classnames";

const LegendTyphoons: FC<{}> = (props) => {
	return (
		<div className={classnames("routeCommon-legend-item", style["legend-typhoons"])}>
			<span className="routeCommon-legend-label ">Typhoon(kts):</span>
			<ul className="legend-typhoons-container">
				{typhoonOptions?.map((item, index) => {
					return (
						<li className="legend-typhoons-item" key={item}>
							{index === 0 ? (
								<div className="legend-typhoons-zero"></div>
							) : (
								<img className="legend-typhoons-icon" src={`/image/cat${index}.png`} />
							)}
							<span>{item}</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default memo(LegendTyphoons);
