import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { VoyGwClauseItemType, VoyGwClauseProps } from "./type";
import {
	Button,
	FleetInput,
	FormItem,
	Select,
	FleetRangePicker
} from "@/components";
import {
	overallGWOptions,
	proportionUnitOptions,
	signWaveAppliedOptions,
	noAdverseCurrentOptions,
	noFavourableCurrentOptions,
	swellWaveAppliedOptions,
	noExtrapolationOptions,
	windWaveAppliedOptions,
	aboutSpeedUnitOptions
} from "./source";
import dayjs, { Dayjs } from "dayjs";
import { CommonOptionType } from "@/types/common";

const VoyGwClause: React.FC<VoyGwClauseProps> = ({
	item,
	onChange,
	onCommit,
	isDisabled = false
}) => {
	function getLabelByValue<K, V>(
		options: CommonOptionType<K, V>[],
		value: V
	): K | undefined {
		const option = options.find((opt) => opt.value === value);
		return option ? option.label : undefined;
	}

	return (
		<div className={classNames(style["fleet-voyage-gw"])}>
			<div className="gw-title">GW Clause</div>
			<div className="gw-row">
				<FormItem type="row" label="Overall GW:">
					{isDisabled ? (
						<span className="gw-item-value common-input">
							{getLabelByValue(overallGWOptions, item.overallGw)}
						</span>
					) : (
						<Select
							className="common-input"
							onChange={(val: VoyGwClauseItemType["overallGw"]) => {
								onChange?.("overallGw", val);
							}}
							options={overallGWOptions}
							value={item?.overallGw}
						/>
					)}
				</FormItem>
			</div>
			<div className="gw-row">
				<FormItem type="row" label="Day Hours:">
					{isDisabled ? (
						<span className="gw-item-value common-input">{item.dayHours}</span>
					) : (
						<FleetInput
							className="common-input"
							type="number"
							value={item?.dayHours}
							max={item?.proportionUnit === "hrs" ? 24 : undefined}
							onChange={(val: VoyGwClauseItemType["dayHours"]) => {
								onChange?.("dayHours", val);
							}}
						/>
					)}
				</FormItem>
				<FormItem type="row" label="Consecutive:">
					{isDisabled ? (
						<span className="gw-item-value common-input">
							{item.consecutive}
						</span>
					) : (
						<FleetInput
							type="number"
							className="common-input"
							max={item?.proportionUnit === "hrs" ? 24 : undefined}
							value={item?.consecutive}
							onChange={(val: VoyGwClauseItemType["consecutive"]) => {
								onChange?.("consecutive", val);
							}}
						/>
					)}
				</FormItem>
				<FormItem type="row" label="Proportion:">
					{isDisabled ? (
						<div className="proportion common-input">
							<span className="gw-item-value">{item.proportion}</span>
							<span className="gw-item-value">{item.proportionUnit}</span>
						</div>
					) : (
						<div className="proportion">
							<FleetInput
								max={item?.proportionUnit === "hrs" ? 24 : undefined}
								type="number"
								className="common-input"
								value={item?.proportion}
								onChange={(val: VoyGwClauseItemType["proportion"]) => {
									onChange?.("proportion", val);
								}}
							/>
							<Select
								className="common-unit"
								onChange={(val: VoyGwClauseItemType["proportionUnit"]) => {
									console.log(val);
									if (val === "hrs") {
										if (Number(item?.dayHours) > 24) {
											onChange?.("dayHours", 24);
										}
										if (Number(item?.consecutive) > 24) {
											onChange?.("consecutive", 24);
										}
										if (Number(item?.proportion) > 24) {
											onChange?.("proportion", 24);
										}
									}
									onChange?.("proportionUnit", val);
								}}
								options={proportionUnitOptions}
								value={item?.proportionUnit}
							/>
						</div>
					)}
				</FormItem>
			</div>
			<div className="gw-row">
				<FormItem type="row" label="BF:">
					{isDisabled ? (
						<span className="gw-item-value common-input">{item.bf}</span>
					) : (
						<FleetInput
							type="number"
							className="common-input"
							value={item?.bf}
							onChange={(val: VoyGwClauseItemType["bf"]) => {
								onChange?.("bf", val);
							}}
						/>
					)}
				</FormItem>
				<FormItem type="row" label="DSS:">
					{isDisabled ? (
						<span className="gw-item-value common-input">{item.dss}</span>
					) : (
						<FleetInput
							type="number"
							className="common-input"
							value={item?.dss}
							onChange={(val: VoyGwClauseItemType["dss"]) => {
								onChange?.("dss", val);
							}}
						/>
					)}
				</FormItem>
				<FormItem type="row" label="Sign Wave:">
					{isDisabled ? (
						<div className="signWaveValue common-input">
							<span className="gw-item-value">{item.signWaveValue}</span>
							<span className="gw-item-value">
								{getLabelByValue(signWaveAppliedOptions, item.signWaveApplied)}
							</span>
						</div>
					) : (
						<div className="signWaveValue">
							<FleetInput
								type="number"
								className="common-input"
								value={item?.signWaveValue}
								onChange={(val: VoyGwClauseItemType["signWaveValue"]) => {
									onChange?.("signWaveValue", val);
								}}
							/>
							<Select
								className="common-unit"
								onChange={(val: VoyGwClauseItemType["signWaveApplied"]) => {
									onChange?.("signWaveApplied", val);
								}}
								options={signWaveAppliedOptions}
								value={item?.signWaveApplied}
							/>
						</div>
					)}
				</FormItem>
			</div>
			<div className="gw-row">
				<FormItem type="row" label="No Adverse Current:">
					{isDisabled ? (
						<span className="gw-item-value common-input">
							{getLabelByValue(noAdverseCurrentOptions, item.noAdverseCurrent)}
						</span>
					) : (
						<Select
							className="common-input"
							onChange={(val: VoyGwClauseItemType["noAdverseCurrent"]) => {
								onChange?.("noAdverseCurrent", val);
							}}
							options={noAdverseCurrentOptions}
							value={item?.noAdverseCurrent}
						/>
					)}
				</FormItem>
				<FormItem type="row" label="No Favourable Current:">
					{isDisabled ? (
						<span className="gw-item-value common-input">
							{getLabelByValue(
								noFavourableCurrentOptions,
								item.noFavourableCurrent
							)}
						</span>
					) : (
						<Select
							className="common-input"
							onChange={(val: VoyGwClauseItemType["noFavourableCurrent"]) => {
								onChange?.("noFavourableCurrent", val);
							}}
							options={noFavourableCurrentOptions}
							value={item?.noFavourableCurrent}
						/>
					)}
				</FormItem>
				<FormItem type="row" label="Swell Wave:">
					{isDisabled ? (
						<div className="swellWaveValue common-input">
							<span className="gw-item-value">{item.swellWaveValue}</span>
							<span className="gw-item-value">
								{getLabelByValue(
									swellWaveAppliedOptions,
									item.swellWaveApplied
								)}
							</span>
						</div>
					) : (
						<div className="swellWaveValue">
							<FleetInput
								type="number"
								className="common-input"
								value={item?.swellWaveValue}
								onChange={(val: VoyGwClauseItemType["swellWaveValue"]) => {
									onChange?.("swellWaveValue", val);
								}}
							/>
							<Select
								className="common-unit"
								onChange={(val: VoyGwClauseItemType["swellWaveApplied"]) => {
									onChange?.("swellWaveApplied", val);
								}}
								options={swellWaveAppliedOptions}
								value={item?.swellWaveApplied}
							/>
						</div>
					)}
				</FormItem>
			</div>
			<div className="gw-row">
				<FormItem type="row" label="No Extrapolation:">
					{isDisabled ? (
						<span className="gw-item-value common-input">
							{getLabelByValue(noExtrapolationOptions, item.noExtrapolation)}
						</span>
					) : (
						<Select
							className="common-input"
							onChange={(val: VoyGwClauseItemType["noExtrapolation"]) => {
								onChange?.("noExtrapolation", val);
							}}
							options={noExtrapolationOptions}
							value={item?.noExtrapolation}
						/>
					)}
				</FormItem>
				<div style={{ width: "296px", height: "22px" }}></div>
				<FormItem type="row" label="Wind Wave:">
					{isDisabled ? (
						<div className="windWaveValue common-input">
							<span className="gw-item-value">{item.windWaveValue}</span>
							<span className="gw-item-value">
								{getLabelByValue(windWaveAppliedOptions, item.windWaveApplied)}
							</span>
						</div>
					) : (
						<div className="windWaveValue">
							<FleetInput
								type="number"
								className="common-input"
								value={item?.windWaveValue}
								onChange={(val: VoyGwClauseItemType["windWaveValue"]) => {
									onChange?.("windWaveValue", val);
								}}
							/>
							<Select
								className="common-unit"
								onChange={(val: VoyGwClauseItemType["windWaveApplied"]) => {
									onChange?.("windWaveApplied", val);
								}}
								options={windWaveAppliedOptions}
								value={item?.windWaveApplied}
							/>
						</div>
					)}
				</FormItem>
			</div>
			<div className="gw-row">
				<FormItem type="row" label="Abt Speed:">
					{isDisabled ? (
						<div className="aboutSpeed common-input">
							<span className="gw-item-value">{item.aboutSpeedFrom},</span>
							<span className="gw-item-value">{item.aboutSpeedTo}</span>
							<span className="gw-item-value">{item.aboutSpeedUnit}</span>
						</div>
					) : (
						<div className="aboutSpeed">
							<FleetInput
								type="number"
								className="common-input"
								value={item?.aboutSpeedFrom}
								onChange={(val: VoyGwClauseItemType["aboutSpeedFrom"]) => {
									if (val !== "" && val > 0) return;
									onChange?.("aboutSpeedFrom", val);
								}}
							/>
							<FleetInput
								type="number"
								className="common-input"
								value={item?.aboutSpeedTo}
								onChange={(val: VoyGwClauseItemType["aboutSpeedTo"]) => {
									if (val !== "" && val < 0) return;
									onChange?.("aboutSpeedTo", val);
								}}
							/>

							<Select
								className="common-unit"
								onChange={(val: VoyGwClauseItemType["aboutSpeedUnit"]) => {
									onChange?.("aboutSpeedUnit", val);
								}}
								options={aboutSpeedUnitOptions}
								value={item?.aboutSpeedUnit}
							/>
						</div>
					)}
				</FormItem>

				<FormItem type="row" label="Abt Cons:">
					{isDisabled ? (
						<div className="aboutCons common-input">
							<span className="gw-item-value">{item.aboutConsFrom},</span>
							<span className="gw-item-value">{item.aboutConsTo}</span>
							<span className="gw-item-value">%</span>
						</div>
					) : (
						<div className="aboutCons">
							<FleetInput
								type="number"
								className="common-input"
								value={item?.aboutConsFrom}
								onChange={(val: VoyGwClauseItemType["aboutConsFrom"]) => {
									onChange?.("aboutConsFrom", val);
								}}
							/>
							<FleetInput
								type="number"
								className="common-input"
								value={item?.aboutConsTo}
								onChange={(val: VoyGwClauseItemType["aboutConsTo"]) => {
									onChange?.("aboutConsTo", val);
								}}
							/>
							<span>%</span>
						</div>
					)}
				</FormItem>
			</div>
		</div>
	);
};

export default VoyGwClause;
