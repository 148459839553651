import { CommonOptionType } from "@/types/common";
import { LegFilterItemType } from "./type";

export const statusOptions: CommonOptionType<
	LegFilterItemType["status"],
	LegFilterItemType["status"]
>[] = [
	{ label: "Open", value: "Open" },
	{ label: "Close", value: "Close" }
	// { label: "All", value: "All" }
];

export const serviceOptions: CommonOptionType<
	LegFilterItemType["service"],
	LegFilterItemType["service"]
>[] = [
	{ label: "Monitoring", value: "Monitoring" },
	{ label: "Routing", value: "Routing" },
	{ label: "Test", value: "Test" }
];

export const roleOptions: CommonOptionType<
	LegFilterItemType["role"],
	LegFilterItemType["role"]
>[] = [
	{ label: "Charterer", value: "Charterer" },
	{ label: "Owner", value: "Owner" }
];

export const conditionOptions: CommonOptionType<
	LegFilterItemType["condition"],
	LegFilterItemType["condition"]
>[] = [
	{ label: "B", value: "B" },
	{ label: "L", value: "L" }
];

export const tagsOptions: CommonOptionType<string, string>[] = [
	{ label: "Plan To Do", value: "p" },
	{ label: "Adverse Wx", value: "a" },
	{ label: "Low Speed", value: "l" },
	{ label: "Deviation", value: "d" }
];
