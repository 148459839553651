import React, { FC, useMemo } from "react";
import style from "./index.module.less";
import { RouteCommonPointPopupProps } from "./type";
import { Icon } from "@/components";
import { getCurrentDir, getWindLevel } from "./tools";
import { Directive } from "./source";
import { getRetainDecimalsNumber } from "@/tools/amount";
import { convertToDms } from "../../tools";

const RouteCommonPointPopup: FC<RouteCommonPointPopupProps> = ({ item }) => {
	const dirOption = useMemo(() => {
		return {
			winDir: getCurrentDir(item?.windDir),
			swellDir: getCurrentDir(item?.swellWaveDir),
			currentDir: getCurrentDir(item?.currentDir),
			windLevel: getWindLevel(item?.windSpeed)
		};
	}, [item]);
	return (
		<div className={style["routeCommon-pointPopup"]}>
			<div className="pointPopup-header">
				<span className="pointPopup-latLng">
					Lat/Lng: {convertToDms(item?.lat, item?.lng)}
				</span>
				{/* <div className="pointPopup-close" onClick={onClose}>
					<Icon type="fleet-close" color="white" size={12} />
				</div> */}
			</div>
			<div className="pointPopup-container">
				<li className="pointPopup-item">
					<span className="label">WIND</span>
					<div className="value">
						<span>BF{dirOption?.windLevel}</span>
						<span>{getRetainDecimalsNumber(item?.windSpeed, 2)} Knts</span>
						<div className="pointPopup-wind-directive">
							<Icon
								style={{
									transform: `rotateZ(${Directive[dirOption?.winDir]}deg)`
								}}
								color="#A864FF"
								type="icon-directions"
								size={12}
							/>
							<span>{dirOption?.winDir}</span>
						</div>
					</div>
				</li>
				<li className="pointPopup-item">
					<span className="label">Sig.Wave</span>
					<div className="value">
						{item?.sigWaveHeight ? `${item?.sigWaveHeight} M` : "--"}
					</div>
				</li>
				<li className="pointPopup-item">
					<span className="label">Swell</span>
					<div className="value">
						<span>
							{item?.swellWaveHeight ? `${item?.swellWaveHeight} M` : "--"}
						</span>
						<div className="pointPopup-wind-directive">
							<Icon
								style={{
									transform: `rotateZ(${Directive[dirOption?.swellDir]}deg)`
								}}
								color="#A864FF"
								type="icon-directions"
								size={12}
							/>
							<span>{dirOption?.swellDir}</span>
						</div>
					</div>
				</li>
				<li className="pointPopup-item">
					<span className="label">Current</span>
					<div className="value">
						<span>{getRetainDecimalsNumber(item?.currentSpeed, 2)} Knts</span>
						<div className="pointPopup-wind-directive">
							<Icon
								style={{
									transform: `rotateZ(${Directive[dirOption?.currentDir]}deg)`
								}}
								color="#A864FF"
								type="icon-directions"
								size={12}
							/>
							<span>{dirOption?.currentDir}</span>
						</div>
					</div>
				</li>
			</div>
		</div>
	);
};

export default RouteCommonPointPopup;
