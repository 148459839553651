import { useMemo, useState, useEffect } from "react";
import { NoonPropsType } from "../type";
import { onRequest } from "@/api";
import { Details as LegDetailsType } from "../../basic/type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReminder, useAppDispatch } from "@/hooks";
import { CommonNumType } from "@/types/common";

const useIndex = (props: NoonPropsType) => {
	const {
		handleChangeReportTableItem,
		activeTab,
		basicReportTableData,
		detailsReportTableData,
		updateDataReportList,
		visibleAddReportDialog,
		handleArDialogVisibleChange
	} = props;

	const { reminder } = useReminder();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();

	const handleAddReportClose = () => {
		props.handleDrIndexChange(-1);
		handleArDialogVisibleChange(false);
	};
	const handleAddReportClick = () => {
		handleArDialogVisibleChange(true);
	};
	const handleAddReportSuccess = () => {
		handleArDialogVisibleChange(false);
		updateDataReportList();
	};

	// 添加segment
	const [isHideAddSegmentDialog, setIsHideAddSegmentDialog] =
		useState<boolean>(true);
	const handleAddSegmentClose = () => {
		setIsHideAddSegmentDialog(true);
	};
	const handleAddSegmentClick = () => {
		setIsHideAddSegmentDialog(false);
	};
	const handleAddSegmentSuccess = () => {
		setIsHideAddSegmentDialog(true);
		// liliang刷新数据
		// getBasicReportList();
		// getDetailReportList();
		updateDataReportList();
	};

	const [legDetailsData, setLegDetailsData] = useState<LegDetailsType>(null);

	const queryData = {
		id: searchParams.get("id"),
		legType: searchParams.get("legType"),
		voyageId: searchParams.get("voyageId")
	};

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	// const [basicReportTableData, setBasicReportTableData] =
	// 	useState<NoonBasicReportTableType>([]);

	const basicDatasource = useMemo(() => {
		return basicReportTableData?.map((item) => {
			return { ...item, key: item.id + item.type };
		});
	}, [basicReportTableData]);

	// 记录最近一次cosp报的时间
	const curCospDateTime = useMemo(() => {
		const cospReports = basicReportTableData?.filter(
			(item) => item.type === "C"
		);
		if (cospReports?.length === 0) {
			return null;
		} else {
			return cospReports?.[0].dateTime === ""
				? null
				: cospReports?.[0].dateTime;
		}
	}, [basicReportTableData]);

	// const [detailsReportTableData, setDetailsReportTableData] =
	// 	useState<NoonDetailsReportTableType>([]);

	const scReportDateList = useMemo<CommonNumType[]>(() => {
		const list: CommonNumType[] = [];
		basicReportTableData?.forEach((item) => {
			if (item.type === "SC") {
				return list.push(item.dateTime);
			}
		});
		return list;
	}, [basicReportTableData]);

	const handleGetLegBasicDetail = () => {
		onRequest(
			"getLegBasicDetailApi",
			queryData,
			handleRequestFront,
			handleGetLegBasicDetailSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeTab === "noon") {
			// 页面数据(leg basic)
			handleGetLegBasicDetail();
		}
	}, [activeTab]);

	const handleGetLegBasicDetailSuccess = (res) => {
		console.log("handleGetLegBasicDetailSuccess", res);
		setLegDetailsData(res.data);
		updateDataReportList();
	};

	return {
		handleAddReportClose,
		handleAddReportClick,
		handleAddReportSuccess,

		isHideAddSegmentDialog,
		handleAddSegmentClose,
		handleAddSegmentClick,
		handleAddSegmentSuccess,
		legDetailsData,
		queryData,
		scReportDateList,
		basicDatasource,
		curCospDateTime
	};
};

export default useIndex;
