import React from "react";
import style from "./index.module.less";
import { ReportRemarksProps } from "./type";

const ReportRemarks: React.FC<ReportRemarksProps> = ({ title, list }) => {
	return (
		<div className={style["ReportRemarks"]}>
			<div className="title">{title}</div>
			<div className="info">
				{list.map((remarkItem, index) => {
					return (
						<div className="info-item" key={index}>
							<div>{remarkItem.label}</div>
							<div>
								{remarkItem.values.map((item, indey) => {
									return <p key={indey}>{item}</p>;
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ReportRemarks;
