import { APP_VERSION_SYS, APP_VERSION_V1 } from "@/api/constant";
import { ApiParameter, RequestApiMapType } from "@/api/type";
import request from "@/service";
import {
	FuzzySearchType,
	LegPageParamsType as ParamsType,
	AddLegPortParameterType,
	getLegBasicDetailParamsType,
	deleteLegParamsType,
	getLegDetailByIdParamsType,
	getLegReportParamsType,
	saveLegCospReportParamsType,
	saveLegEospReportParamsType,
	saveLegNrReportParamsType,
	saveLegSpReportParamsType,
	saveLegScReportParamsType,
	getReportBasicByIdParamsType,
	getReportDetailByIdParamsType,
	GetCpListParamsType,
	GetSegmentByIdParamsType,
	UpdateSegmentListParamsType,
	UpdateLegCospReportParamsType,
	UpdateLegEospReportParamsType,
	UpdateLegNoonReportParamsType,
	UpdateLegResumeReportParamsType,
	UpdateLegStopReportParamsType,
	LegIdType,
	AddRouteType,
	AddRoutePlanByFileType,
	UpdateLegScReportParamsType,
	DeleteReportParamsType,
	GetReportParamsType,
	DownloadPlanType,
	CommonIdType,
	importByFavoriteParamsType,
	RouteSimulationParamsType,
	getSimulationSelectionsByIdsParamsType,
	GetReportPlanListParamsType,
	SaveReportPlanRemarkParamsType,
	GetReportPlanPreviewParamsType,
	generateReportParamsType,
	getImoParamsType,
	DownloadAisApiParamsType,
	DelAisApiParamsType,
	GenerateAnalysisReportApiParamsType,
	UploadReportImageParamsType,
	GetDevReportPositionParamsType,
	GenerateDevReportParamsType
} from "./type";

import {
	meteorologicalApiMap,
	RouteCommonMeteorologicalApiType,
	RouteCommonMeteorologicalParamsType
} from "./map";

const enum ApiMap {
	getLegPageParameter,
	getLegPortsParameter,
	getLegVesselsParameter,
	getLegCompaniesParameter,
	addLegPortParameter,
	getLegBasicDetailParameter,
	deleteLegParameter,
	getLegDetailByIdParameter,
	getLegReportParameter,
	saveLegCospReportParameter,
	saveLegEospReportParameter,
	saveLegNrReportParameter,
	saveLegSpReportParameter,
	saveLegScReportParameter,
	getReportBasicByIdParameter,
	getReportDetailByIdParameter,
	getCpListParameter,
	getSegmentByIdParameter,
	updateSegmentListParameter,
	updateLegCospReportParameter,
	updateLegEospReportParameter,
	updateLegNoonReportParameter,
	updateLegResumeReportParameter,
	updateLegStopReportParameter,
	updateLegScReportParameter,
	deleteLegCospReportParameter,
	deleteLegEospReportParameter,
	deleteLegNoonReportParameter,
	deleteLegResumeReportParameter,
	deleteLegStopReportParameter,
	getRouteInfoParameter,
	addRouteParameter,
	delRouteParameter,
	getRouteDetailListParameter,
	addRoutePlanParameter,
	addRoutePlanByFileParameter,
	deleteLegScReportParameter,
	getCospReportParameter,
	getEospReportParameter,
	getNrReportParameter,
	getSpReportParameter,
	getScReportParameter,
	getWaypointListParameter,
	updateWaypointParameter,
	updatePlanNameParameter,
	delPlanParameter,
	updateWaypointNavParameter,
	getWaypointByIdParameter,
	collectPlanParameter,
	cancelCollectPlanParameter,
	downloadPlanParameter,
	getRouteFavoritesListParameter,
	importByFavoriteParameter,
	getLocationByImoParameter,
	routeSimulationParameter,
	simulationListParameter,
	routeSimulationAgainParameter,
	delRouteSimulationParameter,
	getSimulationSelectionsByIdsParameter,
	getWaypointBySimulationIdParameter,
	getReportPlanListParameter,
	saveReportPlanRemarkParameter,
	getReportPlanPreviewParameter,
	copyPlanParameter,
	getSimulationForecastParameter,
	getSimulationGraphParameter,
	generateReportParameter,
	getImoParameter,
	getCospByLegIdParameter,
	delAisParameter,
	downloadAisParameter,
	getAisListParameter,
	getReportListParameter,
	generateAnalysisReportParameter,
	delReportPlanParameter,
	getReportDataParameter,
	updateReportStatusParameter,
	uploadReportImageParameter,
	getReportConditionParameter,
	updatePlanSyncStatusParameter,
	getDevReportPositionStartParameter,
	getDevReportPositionEndParameter,
	generateDevReportParameter,
	getEtdByRouteIdParameter,
	getDevTimeRangeParameter
}
export type ApiUrl = keyof typeof ApiMap;

const legApi: ApiParameter<ApiUrl> = {
	getLegPageParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/voyageleg/page"
	},
	getLegPortsParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/port/selection"
	},
	getLegVesselsParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/vessel/selection"
	},
	getLegCompaniesParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/company/selection"
	},
	addLegPortParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/voyageleg"
	},
	getLegBasicDetailParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/movement/leg/basic"
	},
	deleteLegParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/voyageleg/batch"
	},
	getLegDetailByIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/voyageleg"
	},
	getLegReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/report/parsing/"
	},
	saveLegCospReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/report/cosp"
	},
	saveLegEospReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/report/eosp"
	},
	saveLegNrReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/report/noon"
	},
	saveLegSpReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/report/stoppage"
	},
	saveLegScReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/report/speed-change"
	},
	getReportBasicByIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/list/"
	},
	getReportDetailByIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/list/detail/"
	},
	getCpListParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/cp/list"
	},
	getSegmentByIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/segment/"
	},
	updateSegmentListParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/segment/list"
	},
	updateLegCospReportParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report/cosp/detail"
	},
	updateLegEospReportParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report/eosp/detail"
	},
	updateLegNoonReportParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report/noon/detail"
	},
	updateLegResumeReportParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report/stoppage/resume/detail"
	},
	updateLegStopReportParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report/stoppage/stop/detail"
	},
	updateLegScReportParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report/speed-change/detail"
	},
	deleteLegCospReportParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/report/cosp/"
	},
	deleteLegEospReportParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/report/eosp/"
	},
	deleteLegNoonReportParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/report/noon/"
	},
	deleteLegResumeReportParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/report/stoppage/"
	},
	deleteLegStopReportParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/report/stoppage/"
	},
	getRouteInfoParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/"
	},
	addRouteParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/route"
	},
	delRouteParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/route/"
	},
	getRouteDetailListParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/plan/"
	},
	addRoutePlanParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/route/algorithm/"
	},
	addRoutePlanByFileParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/route/plan/custom/"
	},
	deleteLegScReportParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/report/speed-change/"
	},
	getCospReportParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/cosp/"
	},
	getEospReportParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/eosp/"
	},
	getNrReportParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/noon/"
	},
	getSpReportParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/stoppage/"
	},
	getScReportParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/speed-change/"
	},
	getWaypointListParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/way-point/plan/"
	},
	updateWaypointParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/way-point"
	},
	updatePlanNameParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/route/plan/"
	},
	delPlanParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/route/plan/"
	},
	updateWaypointNavParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/way-point/"
	},
	getWaypointByIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/way-point/"
	},
	collectPlanParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/refer/"
	},
	cancelCollectPlanParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/refer/"
	},
	downloadPlanParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/plan/custom/"
	},
	getRouteFavoritesListParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/refer/"
	},
	importByFavoriteParameter: {
		method: "post",
		url: APP_VERSION_V1 + `/refer/plan/`
	},
	copyPlanParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/route/plan/copy/"
	},
	getLocationByImoParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/ais/current/"
	},
	routeSimulationParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/route/simulation"
	},
	simulationListParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/route/simulation/plan/"
	},
	routeSimulationAgainParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/route/simulation/"
	},
	delRouteSimulationParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/route/simulation/"
	},
	getSimulationSelectionsByIdsParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/simulation/selection"
	},
	getWaypointBySimulationIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/simulation/way-point/"
	},
	getReportPlanListParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report-plan/list/"
	},
	saveReportPlanRemarkParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report-plan/remakrt/save"
	},
	getReportPlanPreviewParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report-plan/preview/pdf/"
	},
	getSimulationForecastParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/simulation/forecast/"
	},
	getSimulationGraphParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/simulation/graph/"
	},
	generateReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/report-plan/report"
	},
	getImoParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/ais/data/imo/latest"
	},
	getCospByLegIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/report/cosp/leg/"
	},
	delAisParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/ais/point/manager"
	},
	downloadAisParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/ais/data/download"
	},
	getAisListParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/ais/data/imo/latest/"
	},
	getReportListParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/per-report/leg/"
	},
	generateAnalysisReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/per-report/perf"
	},
	delReportPlanParameter: {
		method: "delete",
		url: APP_VERSION_V1 + "/report-plan/report/"
	},
	getReportDataParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/per-report/"
	},
	updateReportStatusParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/per-report/status/"
	},
	uploadReportImageParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/per-report/file"
	},
	getReportConditionParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/per-report/condition/"
	},
	updatePlanSyncStatusParameter: {
		method: "put",
		url: APP_VERSION_V1 + "/report-plan/status/sync"
	},
	getDevReportPositionStartParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/per-report/dev/position/start"
	},
	getDevReportPositionEndParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/per-report/dev/position/end"
	},
	generateDevReportParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/per-report/dev"
	},
	getEtdByRouteIdParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/route/etd/"
	},
	getDevTimeRangeParameter: {
		method: "get",
		url: APP_VERSION_V1 + "/per-report/dev/time/"
	}
};

export const getLegPageApi = (params: LegPageParamsType) => {
	return request.request({
		...legApi.getLegPageParameter,
		params
	});
};

export const getLegPortsApi = (params: FuzzySearchType) => {
	return request.request({
		...legApi.getLegPortsParameter,
		params
	});
};
export const getLegVesselsApi = (params: FuzzySearchType) => {
	return request.request({
		...legApi.getLegVesselsParameter,
		params
	});
};
export const getLegCompaniesApi = (params: FuzzySearchType) => {
	return request.request({
		...legApi.getLegCompaniesParameter,
		params
	});
};

export const addLegPortApi = (params: AddLegPortParameterType) => {
	return request.request({
		...legApi.addLegPortParameter,
		data: params
	});
};

export const getLegBasicDetailApi = (params: getLegBasicDetailParamsType) => {
	return request.request({
		...legApi.getLegBasicDetailParameter,
		params
	});
};

export const deleteLegApi = (params: deleteLegParamsType) => {
	return request.request({
		...legApi.deleteLegParameter,
		data: params
	});
};

const getLegDetailByIdApi = (params: getLegDetailByIdParamsType) => {
	return request.request({
		...legApi.getLegDetailByIdParameter,
		url: `${legApi.getLegDetailByIdParameter.url}/${params.id}`
	});
};
export const getLegReportApi = (params: getLegReportParamsType) => {
	return request.request({
		...legApi.getLegReportParameter,
		headers: { "content-type": "application/x-www-form-urlencoded" },
		url: legApi.getLegReportParameter.url + params.id,
		data: { report: params.report }
	});
};

export const saveLegCospReportApi = (params: saveLegCospReportParamsType) => {
	return request.request({
		...legApi.saveLegCospReportParameter,
		data: params
	});
};

export const saveLegEospReportApi = (params: saveLegEospReportParamsType) => {
	return request.request({
		...legApi.saveLegEospReportParameter,
		data: params
	});
};
export const saveLegNrReportApi = (params: saveLegNrReportParamsType) => {
	return request.request({
		...legApi.saveLegNrReportParameter,
		data: params
	});
};
export const saveLegSpReportApi = (params: saveLegSpReportParamsType) => {
	return request.request({
		...legApi.saveLegSpReportParameter,
		data: params
	});
};
export const saveLegScReportApi = (params: saveLegScReportParamsType) => {
	return request.request({
		...legApi.saveLegScReportParameter,
		data: params
	});
};

export const getReportBasicByIdApi = (params: getReportBasicByIdParamsType) => {
	return request.request({
		...legApi.getReportBasicByIdParameter,
		url: legApi.getReportBasicByIdParameter.url + params.id
	});
};

export const getReportDetailByIdApi = (
	params: getReportDetailByIdParamsType
) => {
	return request.request({
		...legApi.getReportDetailByIdParameter,
		url: legApi.getReportDetailByIdParameter.url + params.id
	});
};

export const getCpListApi = (params: GetCpListParamsType) => {
	return request.request({
		...legApi.getCpListParameter,
		params
	});
};

export const getSegmentByIdApi = (params: GetSegmentByIdParamsType) => {
	return request.request({
		...legApi.getSegmentByIdParameter,
		url: legApi.getSegmentByIdParameter.url + params.legId
	});
};

export const updateSegmentListApi = (params: UpdateSegmentListParamsType) => {
	return request.request({
		...legApi.updateSegmentListParameter,
		data: params
	});
};

export const updateLegCospReportApi = (
	params: UpdateLegCospReportParamsType
) => {
	return request.request({
		...legApi.updateLegCospReportParameter,
		data: params
	});
};
export const updateLegEospReportApi = (
	params: UpdateLegEospReportParamsType
) => {
	return request.request({
		...legApi.updateLegEospReportParameter,
		data: params
	});
};
export const updateLegNoonReportApi = (
	params: UpdateLegNoonReportParamsType
) => {
	return request.request({
		...legApi.updateLegNoonReportParameter,
		data: params
	});
};
export const updateLegResumeReportApi = (
	params: UpdateLegResumeReportParamsType
) => {
	return request.request({
		...legApi.updateLegResumeReportParameter,
		data: params
	});
};
export const updateLegStopReportApi = (
	params: UpdateLegStopReportParamsType
) => {
	return request.request({
		...legApi.updateLegStopReportParameter,
		data: params
	});
};
export const updateLegScReportApi = (params: UpdateLegScReportParamsType) => {
	return request.request({
		...legApi.updateLegScReportParameter,
		data: params
	});
};

export const deleteLegCospReportApi = (params: DeleteReportParamsType) => {
	return request.request({
		...legApi.deleteLegCospReportParameter,
		url: legApi.deleteLegCospReportParameter.url + params.id
	});
};
export const deleteLegEospReportApi = (params: DeleteReportParamsType) => {
	return request.request({
		...legApi.deleteLegEospReportParameter,
		url: legApi.deleteLegEospReportParameter.url + params.id
	});
};
export const deleteLegNoonReportApi = (params: DeleteReportParamsType) => {
	return request.request({
		...legApi.deleteLegNoonReportParameter,
		url: legApi.deleteLegNoonReportParameter.url + params.id
	});
};
export const deleteLegResumeReportApi = (params: DeleteReportParamsType) => {
	return request.request({
		...legApi.deleteLegResumeReportParameter,
		url: legApi.deleteLegResumeReportParameter.url + params.id
	});
};
export const deleteLegStopReportApi = (params: DeleteReportParamsType) => {
	return request.request({
		...legApi.deleteLegStopReportParameter,
		url: legApi.deleteLegStopReportParameter.url + params.id
	});
};
export const deleteLegScReportApi = (params: DeleteReportParamsType) => {
	return request.request({
		...legApi.deleteLegScReportParameter,
		url: legApi.deleteLegScReportParameter.url + params.id
	});
};

export const getCospReportApi = (params: GetReportParamsType) => {
	return request.request({
		...legApi.getCospReportParameter,
		url: legApi.getCospReportParameter.url + params.id
	});
};
export const getEospReportApi = (params: GetReportParamsType) => {
	return request.request({
		...legApi.getEospReportParameter,
		url: legApi.getEospReportParameter.url + params.id
	});
};
export const getNrReportApi = (params: GetReportParamsType) => {
	return request.request({
		...legApi.getNrReportParameter,
		url: legApi.getNrReportParameter.url + params.id
	});
};
export const getSpReportApi = (params: GetReportParamsType) => {
	return request.request({
		...legApi.getSpReportParameter,
		url: legApi.getSpReportParameter.url + params.id
	});
};
export const getScReportApi = (params: GetReportParamsType) => {
	return request.request({
		...legApi.getScReportParameter,
		url: legApi.getScReportParameter.url + params.id
	});
};

export const getRouteInfoApi = (params: LegIdType) => {
	return request.request({
		...legApi.getRouteInfoParameter,
		url: legApi.getRouteInfoParameter.url + params.legId
	});
};
export const addRouteApi = (params: AddRouteType) => {
	return request.request({
		...legApi.addRouteParameter,
		data: params
	});
};
export const delRouteApi = (params: CommonIdType) => {
	return request.request({
		...legApi.delRouteParameter,
		url: legApi.delRouteParameter.url + params.id
	});
};

export const getRouteDetailListApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getRouteDetailListParameter,
		url: legApi.getRouteDetailListParameter.url + params.id
	});
};
export const addRoutePlanApi = (params: CommonIdType) => {
	return request.request({
		...legApi.addRoutePlanParameter,
		url: legApi.addRoutePlanParameter.url + params.id
	});
};

export const addRoutePlanByFileApi = (params: AddRoutePlanByFileType) => {
	console.log(params, params.file, params.file.get("file"));
	return request.request({
		...legApi.addRoutePlanByFileParameter,
		url: legApi.addRoutePlanByFileParameter.url + params.routeId,
		data: params?.file
	});
};

export const getWaypointListApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getWaypointListParameter,
		url: legApi.getWaypointListParameter.url + params.id
	});
};

export const updateWaypointApi = (params) => {
	return request.request({
		...legApi.updateWaypointParameter,
		data: params
	});
};

export const updatePlanNameApi = (params: { name: string; id: string }) => {
	return request.request({
		...legApi.updatePlanNameParameter,
		url: legApi.updatePlanNameParameter.url + params.id + `?name=${params.name}`
	});
};

export const delPlanApi = (params: CommonIdType) => {
	return request.request({
		...legApi.delPlanParameter,
		url: legApi.delPlanParameter.url + params.id
	});
};

export const updateWaypointNavApi = (params: { id: string; nav: string }) => {
	return request.request({
		...legApi.updateWaypointNavParameter,
		url:
			legApi.updateWaypointNavParameter.url + params.id + `?nav=${params.nav}`
	});
};

export const getWaypointByIdApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getWaypointByIdParameter,
		url: legApi.getWaypointByIdParameter.url + params.id
	});
};

export const collectPlanApi = (params: CommonIdType) => {
	return request.request({
		...legApi.collectPlanParameter,
		url: legApi.collectPlanParameter.url + params.id
	});
};

export const cancelCollectPlanApi = (params: CommonIdType) => {
	return request.request({
		...legApi.cancelCollectPlanParameter,
		url: legApi.cancelCollectPlanParameter.url + params.id
	});
};

export const downloadPlanApi = (params: DownloadPlanType) => {
	return request.request({
		...legApi.downloadPlanParameter,
		url:
			legApi.downloadPlanParameter.url +
			params.type +
			`?planId=${params.planId}`,
		responseType: "blob"
	});
};

export const getRouteFavoritesListApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getRouteFavoritesListParameter,
		url: legApi.getRouteFavoritesListParameter.url + params.id
	});
};

export const importByFavoriteApi = (params: importByFavoriteParamsType) => {
	return request.request({
		...legApi.importByFavoriteParameter,
		url: `${legApi.importByFavoriteParameter.url}/${params?.favoriteId}/${params?.routeId}`
	});
};
export const copyPlanApi = (params: CommonIdType) => {
	return request.request({
		...legApi.copyPlanParameter,
		url: legApi.copyPlanParameter.url + params.id
	});
};

export const getLocationByImoApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getLocationByImoParameter,
		url: legApi.getLocationByImoParameter.url + params.id
	});
};

export const getReportPlanListApi = (params: GetReportPlanListParamsType) => {
	return request.request({
		...legApi.getReportPlanListParameter,
		url: legApi.getReportPlanListParameter.url + params.id
	});
};
export const saveReportPlanRemarkApi = (
	params: SaveReportPlanRemarkParamsType
) => {
	return request.request({
		...legApi.saveReportPlanRemarkParameter,
		data: params
	});
};

export const routeSimulationApi = (params: RouteSimulationParamsType) => {
	return request.request({
		...legApi.routeSimulationParameter,
		data: params
	});
};

export const simulationListApi = (params: CommonIdType) => {
	return request.request({
		...legApi.simulationListParameter,
		url: legApi.simulationListParameter.url + params.id
	});
};
export const routeSimulationAgainApi = (params: CommonIdType) => {
	return request.request({
		...legApi.routeSimulationAgainParameter,
		url: legApi.routeSimulationAgainParameter.url + params.id
	});
};
export const delRouteSimulationApi = (params: CommonIdType) => {
	return request.request({
		...legApi.delRouteSimulationParameter,
		url: legApi.delRouteSimulationParameter.url + params.id
	});
};

export const getSimulationSelectionsByIdsApi = (
	params: getSimulationSelectionsByIdsParamsType
) => {
	return request.request({
		...legApi.getSimulationSelectionsByIdsParameter,
		params
	});
};

export const getWaypointBySimulationIdApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getWaypointBySimulationIdParameter,
		url: legApi.getWaypointBySimulationIdParameter.url + params.id
	});
};

export const getReportPlanPreviewApi = (
	params: GetReportPlanPreviewParamsType
) => {
	return request.request({
		...legApi.getReportPlanPreviewParameter,
		url: legApi.getReportPlanPreviewParameter.url + params.id
	});
};

export const getSimulationForecastApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getSimulationForecastParameter,
		url: legApi.getSimulationForecastParameter.url + params.id
	});
};

export const getSimulationGraphApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getSimulationGraphParameter,
		url: legApi.getSimulationGraphParameter.url + params.id
	});
};
export const generateReportApi = (params: generateReportParamsType) => {
	return request.request({
		...legApi.generateReportParameter,
		data: params
	});
};

export const getImoApi = (params: getImoParamsType) => {
	return request.request({
		...legApi.getImoParameter,
		url: `${legApi.getImoParameter.url}?startDate=${params?.startDate}&endDate=${params?.endDate}&imo=${params?.imo}&legId=${params?.legId}&type=${params?.type}`
	});
};

export const getCospByLegIdApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getCospByLegIdParameter,
		url: legApi.getCospByLegIdParameter.url + params.id
	});
};

export const delAisApi = (params: DelAisApiParamsType) => {
	return request.request({
		...legApi.delAisParameter,
		data: params
	});
};

export const downloadAisApi = (params: DownloadAisApiParamsType) => {
	return request.request({
		...legApi.downloadAisParameter,
		data: params,
		responseType: "blob"
	});
};

export const getAisListApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getAisListParameter,
		url: legApi.getAisListParameter.url + params.id
	});
};

export const getReportListApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getReportListParameter,
		url: legApi.getReportListParameter.url + params.id
	});
};

export const generateAnalysisReportApi = (
	params: GenerateAnalysisReportApiParamsType
) => {
	return request.request({
		...legApi.generateAnalysisReportParameter,
		data: params
	});
};

export const delReportPlanApi = (params: CommonIdType) => {
	return request.request({
		...legApi.delReportPlanParameter,
		url: legApi.delReportPlanParameter.url + params.id
	});
};

export const getReportDataApi = (params: { reportId: string }) => {
	return request.request({
		...legApi.getReportDataParameter,
		url: legApi.getReportDataParameter.url + params.reportId
	});
};

export const updateReportStatusApi = (params: CommonIdType) => {
	return request.request({
		...legApi.updateReportStatusParameter,
		url: legApi.updateReportStatusParameter.url + params.id
	});
};

export const uploadReportImageApi = (params: UploadReportImageParamsType) => {
	return request.request({
		...legApi.uploadReportImageParameter,
		headers: { "Content-Type": "multipart/form-data" },
		data: params
	});
};

export const getReportConditionApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getReportConditionParameter,
		url: legApi.getReportConditionParameter.url + params.id
	});
};

export const updatePlanSyncStatusApi = (params: CommonIdType) => {
	return request.request({
		...legApi.updatePlanSyncStatusParameter,
		params
	});
};

export const getDevReportPositionStartApi = (
	params: GetDevReportPositionParamsType
) => {
	return request.request({
		...legApi.getDevReportPositionStartParameter,
		params
	});
};

export const getDevReportPositionEndApi = (
	params: GetDevReportPositionParamsType
) => {
	return request.request({
		...legApi.getDevReportPositionEndParameter,
		params
	});
};

export const generateDevReportApi = (params: GenerateDevReportParamsType) => {
	return request.request({
		...legApi.generateDevReportParameter,
		data: params
	});
};

export const getEtdByRouteIdApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getEtdByRouteIdParameter,
		url: legApi.getEtdByRouteIdParameter.url + params.id
	});
};

export const getDevTimeRangeApi = (params: CommonIdType) => {
	return request.request({
		...legApi.getDevTimeRangeParameter,
		url: legApi.getDevTimeRangeParameter.url + params.id
	});
};

export type LegPageApiType = ApiUrl | RouteCommonMeteorologicalApiType;

export type LegPageParamsType =
	| ParamsType
	| RouteCommonMeteorologicalParamsType;

export const LegPageApiMap: RequestApiMapType<
	LegPageApiType,
	LegPageParamsType
> = {
	...meteorologicalApiMap,
	getLegPageApi,
	getLegPortsApi,
	getLegVesselsApi,
	getLegCompaniesApi,
	addLegPortApi,
	getLegBasicDetailApi,
	deleteLegApi,
	getLegDetailByIdApi,
	getLegReportApi,
	saveLegCospReportApi,
	saveLegEospReportApi,
	saveLegNrReportApi,
	saveLegSpReportApi,
	saveLegScReportApi,
	getReportBasicByIdApi,
	getReportDetailByIdApi,
	getCpListApi,
	getSegmentByIdApi,
	updateSegmentListApi,
	updateLegCospReportApi,
	updateLegEospReportApi,
	updateLegNoonReportApi,
	updateLegResumeReportApi,
	updateLegStopReportApi,
	updateLegScReportApi,
	deleteLegCospReportApi,
	deleteLegEospReportApi,
	deleteLegNoonReportApi,
	deleteLegResumeReportApi,
	deleteLegStopReportApi,
	getRouteInfoApi,
	addRouteApi,
	delRouteApi,
	getRouteDetailListApi,
	addRoutePlanApi,
	addRoutePlanByFileApi,
	deleteLegScReportApi,
	getCospReportApi,
	getEospReportApi,
	getNrReportApi,
	getSpReportApi,
	getScReportApi,
	getWaypointListApi,
	updateWaypointApi,
	updatePlanNameApi,
	delPlanApi,
	updateWaypointNavApi,
	getWaypointByIdApi,
	collectPlanApi,
	cancelCollectPlanApi,
	downloadPlanApi,
	getRouteFavoritesListApi,
	importByFavoriteApi,
	copyPlanApi,
	getLocationByImoApi,
	routeSimulationApi,
	simulationListApi,
	routeSimulationAgainApi,
	delRouteSimulationApi,
	getSimulationSelectionsByIdsApi,
	getWaypointBySimulationIdApi,
	getReportPlanListApi,
	saveReportPlanRemarkApi,
	getReportPlanPreviewApi,
	getSimulationForecastApi,
	getSimulationGraphApi,
	generateReportApi,
	getImoApi,
	getCospByLegIdApi,
	delAisApi,
	downloadAisApi,
	getAisListApi,
	getReportListApi,
	generateAnalysisReportApi,
	delReportPlanApi,
	getReportDataApi,
	updateReportStatusApi,
	uploadReportImageApi,
	getReportConditionApi,
	updatePlanSyncStatusApi,
	getDevReportPositionStartApi,
	getDevReportPositionEndApi,
	generateDevReportApi,
	getEtdByRouteIdApi,
	getDevTimeRangeApi
};
