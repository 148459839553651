import { FuelTableDataType } from "../../common/noonFuelForm";
import { NoonMainInfoFormType } from "../../common/noonMainInfoForm/type";
import { ScSciFormType, ScBasicDataType } from "./type";

export const initialScFuelData: FuelTableDataType = [
	{
		id: 1,
		mt: "BROB",
		isNecessary: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 2,
		mt: "ME Cons SLR",
		isNecessary: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 3,
		mt: "AE Cons SLR",
		isNecessary: false,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 4,
		mt: "Other Cons SLR",
		isNecessary: false,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 5,
		mt: "Total Consumption SLR",
		isNecessary: true,
		disabled: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	}
];

export const initialScMiData: NoonMainInfoFormType = {
	vesselName: "",
	imo: "",
	callSign: ""
};

export const initialScSciData: ScSciFormType = {
	latitude: 0,
	longitude: 0,
	steamTimeSlr: "",
	speedChangeDate: "",
	orderedSpeed: "",
	distanceSlr: "",
	timeSlr: "",
	avgSpeedSlr: ""
};

export const initialScBasicData: ScBasicDataType = {
	id: null
};
