import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { WaypointDetailType, WaypointEditDialogProps } from "../type";
import { CommonChangeEvent } from "@/types/event";
import { WaypointType } from "../../customTable/type";
import { useLegContext } from "@/pages/leg/business/details/store";
const useIndex = (
	waypointId: WaypointEditDialogProps["waypointId"],
	closeDialog: WaypointEditDialogProps["onClose"],
	editSuccessCb: WaypointEditDialogProps["onFinish"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	useEffect(() => {
		getWaypointById({ id: waypointId });
	}, []);
	const {
		route: { handleWayPointCrud }
	} = useLegContext();

	const [waypointDetail, setWaypointDetail] =
		useState<WaypointDetailType>(null);
	const [loading, setLoading] = useState(false);
	const [checkList, setCheckList] = useState([false, false]);
	const [tabActiveKey, setTabActiveKey] = useState("algorithm");

	const handleChangeWaypoint: CommonChangeEvent<WaypointDetailType> = (
		key,
		value
	) => {
		setWaypointDetail((prevState) => {
			return {
				...prevState,
				[key]: value
			};
		});
	};

	const handleClickCheckBox = (index) => {
		setCheckList((prevCheckList) => {
			return prevCheckList.map((item, i) => {
				if (i === index) {
					return !item;
				} else {
					return false;
				}
			});
		});
	};
	const handleClickTab = (activeKey) => {
		setTabActiveKey(activeKey);
	};
	const hanleCloseDialog = (closeDialog) => {
		closeDialog && closeDialog();
	};

	const onConfirm = () => {
		const params = {
			...waypointDetail,
			stopHours: checkList[0] ? waypointDetail?.stopHours : null,
			resumeTime: checkList[1] ? waypointDetail?.resumeTime : null
		};
		updateWaypoint(params);
	};

	// API
	// API - getWaypointByIdApi
	const getWaypointByIdFront = () => {};
	const getWaypointByIdSuccess = (data) => {
		setWaypointDetail(data?.data || []);
	};
	const getWaypointByIdFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getWaypointById = (params: { id: string }) => {
		onRequest(
			"getWaypointByIdApi",
			params,
			getWaypointByIdFront,
			getWaypointByIdSuccess,
			getWaypointByIdFailed,
			dispatch,
			navigate
		);
	};
	// API - updateWaypointApi
	const updateWaypointFront = () => {};

	const updateWaypointSuccess = (data, params) => {
		console.log("测试， 巴拉巴拉", params, data);

		// editSuccessCb && editSuccessCb();
		handleWayPointCrud("update", params);
		closeDialog && closeDialog();
		editSuccessCb &&
			editSuccessCb({ planId: params.planId, distance: data.data });
		setLoading(false);
	};
	const updateWaypointFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const updateWaypoint = (params: WaypointType) => {
		onRequest(
			"updateWaypointApi",
			params,
			updateWaypointFront,
			(data) => {
				updateWaypointSuccess(data, params);
			},
			updateWaypointFailed,
			dispatch,
			navigate
		);
	};

	return {
		loading,
		checkList,
		tabActiveKey,
		waypointDetail,
		handleChangeWaypoint,
		hanleCloseDialog,
		onConfirm,
		handleClickTab,
		handleClickCheckBox,
		getWaypointById
	};
};

export default useIndex;
