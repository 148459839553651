import React from "react";
import { Table } from "@/components";
import style from "./index.module.less";
import { PlanTitle } from "../../components";
import { columns } from "./source";
import { WaypointProps } from "./type";
import classNames from "classnames";

const Waypoint: React.FC<WaypointProps> = (props) => {
	const { data } = props;

	return (
		<div className={classNames(["flag-no-break", style["vpr-waypoint"]])}>
			<PlanTitle title="WAYPOINT" showDot={true} />
			<Table
				id="id"
				className="waypoint-table"
				columns={columns}
				dataSource={data}
				scroll={null}
				pagination={false}
				bordered
			/>
		</div>
	);
};

export default Waypoint;
