import React from "react";
import type { ColumnsType } from "antd/es/table/interface";
import { convertDdToDm } from "@/tools/latLong";
import { timestampToUtcMdhm } from "@/tools/date";
import { formatNumber } from "@/tools/amount";
import { WaypointTableItemType } from "./type";

export const columns: ColumnsType<WaypointTableItemType> = [
	{
		title: "Major waypoint",
		align: "center",
		render(value, record, index) {
			const {
				degree: latDegree,
				minute: latMinute,
				unit: latUnit
			} = convertDdToDm(record.lat, true);
			const {
				degree: lonDegree,
				minute: lonMinute,
				unit: lonUnit
			} = convertDdToDm(record.lon, false);

			const waypointStr = `${latDegree}°${formatNumber(latMinute, 2)}′${latUnit},${lonDegree}°${formatNumber(lonMinute, 2)}′${lonUnit}`;
			return <div>{waypointStr}</div>;
		}
	},
	{
		title: "Time",
		key: "dataTime",
		dataIndex: "dataTime",
		align: "center",
		render(value, record, index) {
			let timeStr = timestampToUtcMdhm(value);
			if (record.stopHours) {
				timeStr += `(+${formatNumber(record.stopHours, 2)})`;
			}
			return <div>{timeStr}</div>;
		}
	},
	{
		title: "Nav. Type",
		key: "nav",
		dataIndex: "nav",
		align: "center"
	},
	{
		title: "Distance",
		key: "distToStart",
		dataIndex: "distToStart",
		align: "center",
		render(value, record, index) {
			return <div>{value || value === 0 ? formatNumber(value, 1) : "--"}</div>;
		}
	},
	{
		title: "SOG",
		key: "sog",
		dataIndex: "sog",
		align: "center",
		render(value, record, index) {
			return <div>{value || value === 0 ? formatNumber(value, 2) : "--"}</div>;
		}
	}
];
