import {
	Button,
	Dialog,
	Table,
	FleetDatePicker,
	FormItem,
	FleetInput
} from "@/components";
import React, { FC, forwardRef, useImperativeHandle } from "react";
import useIndex from "./hooks/useIndex";
import styles from "./index.module.less";
import {
	SimulationForecastTableProps,
	SimulationForecastTableRefType
} from "./type";
import { detailTablecolumns } from "./source";
import { GraphDialog } from "../graphDialog";
import { CpaDialog } from "../cpaDialog";
import dayjs from "dayjs";
import classNames from "classnames";
const SimulationForecastTable = forwardRef<
	SimulationForecastTableRefType,
	SimulationForecastTableProps
>(({ visible, onClose, simulationInfo }, ref) => {
	const {
		simulationForecastList,
		graphDialogVisible,
		cpaDialogVisible,
		typhoonId,
		typhoonRoute,
		height,
		scrollList,
		handleMouseDown,
		handleOpenGraphDialog,
		handleCloseGraphDialog,
		handleOpenCpaDialog,
		handleCloseCpaDialog,
		generationTyphoonRoute,
		handleScroll
	} = useIndex(simulationInfo);
	useImperativeHandle(ref, () => ({
		generationTyphoonRoute: generationTyphoonRoute
	}));
	return (
		visible && (
			<div
				className={styles.simulationForecastTable}
				style={{ height: `${height}px` }}
			>
				<div
					className="simulationForecastTable-dragbar"
					onMouseDown={handleMouseDown}
				></div>
				<div className="simulationForecastTable-header">
					<div className="title">
						{/* Other Distance: 1166.04 nm ETA: Jun-07/1608Z Speed: 11.25/11.23 */}
						<span>Distance: {simulationInfo?.distance ?? "-"}</span>
						<span>
							ETA:{" "}
							{dayjs(simulationInfo?.eta)?.utc()?.format("YYYY-MM-DD HH:mm") ??
								"-"}
						</span>
						<span>Speed: {simulationInfo?.avgSpeed ?? "-"}</span>
					</div>
					<div className="operation">
						<span onClick={handleOpenGraphDialog}>Graph</span>
						<span
							onClick={handleOpenCpaDialog}
							className={classNames({
								"voy-disabled": !typhoonId
							})}
						>
							CPA
						</span>
						<span onClick={onClose}>X</span>
					</div>
				</div>
				<div className="simulationForecastTable-main fleet-nonScrollBar">
					<Table
						className="detail-table"
						dataSource={scrollList}
						columns={detailTablecolumns}
						bordered={true}
						id="id"
						scroll={{ x: 1050 }}
						onScroll={(e) => {
							handleScroll(e);
						}}
						pagination={false}
					/>
				</div>
				{graphDialogVisible && (
					<GraphDialog
						visible={graphDialogVisible}
						onClose={handleCloseGraphDialog}
						simulationId={simulationInfo?.id}
					/>
				)}
				{cpaDialogVisible && (
					<CpaDialog
						visible={cpaDialogVisible}
						onClose={handleCloseCpaDialog}
						simulationId={simulationInfo?.id}
						typhoonId={typhoonId}
						typhoonRoute={typhoonRoute}
						simulationForecastList={simulationForecastList}
					/>
				)}
			</div>
		)
	);
});

export default SimulationForecastTable;
