import XLSX from "xlsx";
import { useState, useEffect } from "react";

const useIndex = (url: string) => {
	const [sheets, setSheets] = useState<{ [sheetName: string]: any[][] }>({});

	useEffect(() => {
		const fetchExcelFile = async () => {
			try {
				const response = await fetch(url);
				const arrayBuffer = await response.arrayBuffer();
				const data = new Uint8Array(arrayBuffer);
				const workbook = XLSX.read(data, { type: "array" });

				const sheetsData: { [sheetName: string]: any[][] } = {};
				workbook.SheetNames.forEach((sheetName) => {
					const worksheet = workbook.Sheets[sheetName];
					sheetsData[sheetName] = XLSX.utils.sheet_to_json(worksheet, {
						header: 1
					});
				});

				setSheets(sheetsData);
			} catch (error) {
				console.error("Error fetching or parsing Excel file:", error);
			}
		};

		fetchExcelFile();
	}, [url]);

	return { sheets };
};

export default useIndex;
