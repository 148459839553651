import React from "react";
import { timestampToUtcMdhm } from "@/tools/date";
import type { ColumnsType } from "antd/es/table/interface";
import { RouteSummaryTableItemType } from "./type";

export const columns: ColumnsType<RouteSummaryTableItemType> = [
	{
		title: "ROUTE",
		key: "routeName",
		dataIndex: "routeName",
		align: "center",
		width: 100
	},
	{
		title: `Distance
		nm`,
		key: "distance",
		dataIndex: "distance",
		align: "center",
		width: 100
	},
	{
		title: "CP Speed",
		key: "cpSpeed",
		dataIndex: "cpSpeed",
		align: "center",
		width: 100
	},
	{
		title: "CP Consumption",
		align: "center",
		width: 150,
		render(value, record, index) {
			const cpConsStr = `${record.cpConsFo} FO / ${record.cpConsDgo} DO`;
			return <div>{cpConsStr}</div>;
		}
	},
	{
		title: `Avg. Speed
		kts`,
		key: "avgSpeed",
		dataIndex: "avgSpeed",
		align: "center"
	},
	{
		title: `WF/ CF
		kts`,
		align: "center",
		width: 100,
		render(value, record, index) {
			const wcStr = `${record.wf} / ${record.cf}`;
			return <div>{wcStr}</div>;
		}
	},
	{
		title: `Fuel
		Consumption
		mt`,
		align: "center",
		width: 150,
		render(value, record, index) {
			const fuelConsStr = `${record.consFo} FO / ${record.consDgo} DO`;
			return <div>{fuelConsStr}</div>;
		}
	},
	{
		title: "ETA (UTC)",
		key: "eta",
		dataIndex: "eta",
		align: "center",
		width: 120,
		render(value, record, index) {
			return <div>{timestampToUtcMdhm(value)}</div>;
		}
	}
];
