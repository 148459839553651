import { CommonOptionType } from "@/types/common";
import { VoyageFilterItemType } from "./type";

export const voyTypeOptions: CommonOptionType<
	VoyageFilterItemType["voyageType"],
	VoyageFilterItemType["voyageType"]
>[] = [
	{ label: "TCT", value: "TCT" },
	{ label: "TC", value: "TC" }
];

export const statusOptions: CommonOptionType<
	VoyageFilterItemType["status"],
	VoyageFilterItemType["status"]
>[] = [
	{ label: "Open", value: "Open" },
	{ label: "Close", value: "Close" }
];

export const serviceOptions: CommonOptionType<
	VoyageFilterItemType["service"],
	VoyageFilterItemType["service"]
>[] = [
	{ label: "Monitoring", value: "Monitoring" },
	{ label: "Routing", value: "Routing" },
	{ label: "Test", value: "Test" }
];

export const roleOptions: CommonOptionType<
	VoyageFilterItemType["role"],
	VoyageFilterItemType["role"]
>[] = [
	{ label: "Charterer", value: "Charterer" },
	{ label: "Owner", value: "Owner" }
];
