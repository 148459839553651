import React, { useEffect, useRef } from "react";
import style from "./index.module.less";
import { VoyBasicInfo, VoyCpSpdTable, VoyGwClause } from "@/pages/voy/common";
import { Dialog, Button, Loading } from "@/components";
import useIndex from "./hooks/useIndex";
import { AddNewVoyProps } from "./type";
import { useReminder } from "@/hooks";
import { CharterPartyDialog } from "..";

const AddNewVoy: React.FC<AddNewVoyProps> = (props) => {
	const { hide, onClose } = props;

	const {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,

		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess,

		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,

		handleConfirmClick,
		loading
	} = useIndex(props);

	const { reminder } = useReminder();

	const handleCpAdd = () => {
		if (!voyBasicInfoData.imo) {
			reminder("error", "请先选择船");
			return;
		}
		addVoyCpSpd();
	};

	return (
		<>
			<Loading loading={loading} />
			<Dialog
				mode="fixed"
				hide={hide}
				closeable={true}
				drawer={true}
				onClose={onClose}
			>
				<div className={style["voy-add-dialog"]}>
					<div className="voy-add-header">
						<div>Add New Voy</div>
						<div className="close-btn" onClick={onClose}>
							×
						</div>
					</div>
					{/* <div className="prompt-header--container">
						<div className="header-title">Add New Voy</div>
						<div className="header-close" onClick={onClose}>
							×
						</div>
					</div> */}

					<div className="voy-add-main">
						<VoyBasicInfo
							item={voyBasicInfoData}
							onCommit={handleBasicInfoItemCommit}
							onChange={handleChangeBasicInfoItem}
						/>
						<VoyCpSpdTable
							fixWidth={1250}
							voyCpSpdTableData={voyCpSpdTableData}
							handleDoubleClick={handleDoubleClick}
							selectedRowKeys={selectedRowKeys}
							onSelectChange={onSelectChange}
							pager={pager}
							onPageChange={onPageChange}
							dataSource={dataSource}
							opereteColumns={opereteColumns}
							onChange={handleChangeVoyCpSpdTableItem}
							add={handleCpAdd}
							// isHideCpDialog={isHideCpDialog}
							// onCpClose={onCpClose}
							// onCpPickSuccess={onCpPickSuccess}
							// vesselName={voyBasicInfoData.vesselName}
							// imo={voyBasicInfoData.imo}
						/>
						<VoyGwClause
							item={voyGwClauseData}
							onChange={handleChangeVoyGwClauseItem}
							onCommit={handleVoyGwClauseItemCommit}
						/>
					</div>

					<div className="voy-add-footer">
						<Button type="secondary" size="large" onClick={onClose}>
							Cancel
						</Button>
						<Button size="large" onClick={handleConfirmClick}>
							Confirm
						</Button>
					</div>
				</div>
			</Dialog>

			<CharterPartyDialog
				hide={isHideCpDialog}
				onCpClose={onCpClose}
				onCpPickSuccess={onCpPickSuccess}
				vesselName={voyBasicInfoData.vesselName}
				imo={voyBasicInfoData.imo}
			/>
		</>
	);
};

export default AddNewVoy;
