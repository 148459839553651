import { createAxiosByinterceptors } from "./service";

const request = createAxiosByinterceptors({
	baseURL: process.env.API_BASE_HOST,
	headers: {
		// Authorization: cacheStorage.get('access_token')
		// "User-Agent": "http://localhost:3333"
	}
});

export default request;
