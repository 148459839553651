import React, { FC, memo } from "react";
import style from "./index.module.less";
import { RouteCommonLegendProps } from "./type";
import { LegendCurrent, LegendTyphoons, LegendWaves } from "./components";
import classnames from "classnames";
import { RouteCommonToolbarMeteoSwitchMap } from "../../../toolbar";

const RouteCommonLegend: FC<RouteCommonLegendProps> = ({
	meteoStatus,
	visible
}) => {
	return (
		<div
			id="routeCommon-legend"
			className={classnames(style["routeCommon-legend"], {
				[style["routeCommon-legend--active"]]: visible
			})}
		>
			{[meteoStatus.Wave, meteoStatus.Swell]?.includes(
				RouteCommonToolbarMeteoSwitchMap.On
			) && <LegendWaves />}
			{meteoStatus.Current === RouteCommonToolbarMeteoSwitchMap.On && (
				<LegendCurrent />
			)}
			{meteoStatus.Typhoon === RouteCommonToolbarMeteoSwitchMap.On && (
				<LegendTyphoons />
			)}
		</div>
	);
};

export default memo(RouteCommonLegend);
