import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { VoyBasicInfoItemType, VoyBasicInfoProps } from "./type";
import {
	Button,
	FleetInput,
	FormItem,
	Select,
	FleetRangePicker,
	FleetDatePicker
} from "@/components";
import { voyTypeOptions, serviceOptions, roleOptions } from "./source";
import dayjs, { Dayjs } from "dayjs";
import {
	CommonPortSelect,
	CommonPortItemType,
	CommonVesselSelect,
	CommonVesselItemType,
	CommonCompanySelect,
	CommonCompanyItemType
} from "@/common";

const VoyBasicInfo: React.FC<VoyBasicInfoProps> = ({
	item,
	onChange,
	onCommit,
	isDisabled = false,
	disabledField = []
}) => {
	return (
		<div className={classNames(style["fleet-voyage-basic"])}>
			<div className="title">Basic Info</div>

			<div className="basic-info-row1">
				<FormItem type="row" label="Vessel:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-select">
							{item.vesselName}
						</span>
					) : (
						<CommonVesselSelect
							className="common-select"
							disabled={disabledField.includes("vesselName")}
							activeItems={item as Partial<CommonVesselItemType>}
							showKey={"vesselName"}
							primaryKey={"imo"}
							onFinish={(newItem, operateType) => {
								onCommit?.({
									imo: newItem?.imo,
									id: newItem?.id,
									vesselName: newItem?.vesselName
								});
							}}
						/>
					)}
				</FormItem>
				<FormItem type="row" label="Company:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-select">
							{item.companyName}
						</span>
					) : (
						<CommonCompanySelect
							className="common-select"
							disabled={disabledField.includes("companyName")}
							activeItems={{
								code: item.companyCode,
								name: item.companyName,
								id: String(item.id)
							}}
							showKey={"name"}
							onFinish={(newItem, operateType) => {
								onCommit?.({
									companyId: newItem.id,
									companyName: newItem.name,
									companyCode: newItem.code
								});
							}}
						/>
					)}
				</FormItem>
			</div>
			<div className="basic-info-row2">
				<FormItem type="row" label="Voy Type:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-select">
							{item.voyageType}
						</span>
					) : (
						<Select
							className="common-select"
							onChange={(val: VoyBasicInfoItemType["voyageType"]) => {
								onChange?.("voyageType", val);
							}}
							options={voyTypeOptions}
							value={item?.voyageType}
						/>
					)}
				</FormItem>

				<FormItem type="row" label="Service:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-select">
							{item.service}
						</span>
					) : (
						<Select
							className="common-select"
							onChange={(val: VoyBasicInfoItemType["service"]) => {
								onChange?.("service", val);
							}}
							options={serviceOptions}
							value={item?.service}
						/>
					)}
				</FormItem>

				<FormItem type="row" label="Role:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-select">
							{item.customerRole}
						</span>
					) : (
						<Select
							className="common-select"
							onChange={(val: VoyBasicInfoItemType["customerRole"]) => {
								onChange?.("customerRole", val);
							}}
							options={roleOptions}
							value={item?.customerRole}
						/>
					)}
				</FormItem>

				<FormItem type="row" label="Order Time:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-select">
							{item.orderDate}
						</span>
					) : (
						<FleetDatePicker
							className="common-select"
							onChange={(val, timeStramp) => {
								onCommit?.({
									orderDate: val
								});
							}}
							value={item?.orderDate ? dayjs(item?.orderDate) : null}
							allowClear
						/>
					)}
				</FormItem>
			</div>
			<div className="basic-info-row3">
				<FormItem type="row" label="Master Email:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-email-input">
							{item.masterEmail}
						</span>
					) : (
						<FleetInput
							className="common-email-input"
							value={item?.masterEmail || ""}
							onChange={(val: VoyBasicInfoItemType["masterEmail"]) => {
								onChange?.("masterEmail", val);
							}}
						/>
					)}
				</FormItem>
			</div>
			<div className="basic-info-row4">
				<FormItem type="row" label="Customer Email:" needVerity={!isDisabled}>
					{isDisabled ? (
						<span className="basic-item-value common-email-input">
							{item.customerEmail}
						</span>
					) : (
						<FleetInput
							className="common-email-input"
							value={item?.customerEmail || ""}
							onChange={(val: VoyBasicInfoItemType["customerEmail"]) => {
								onChange?.("customerEmail", val);
							}}
						/>
					)}
				</FormItem>
			</div>
		</div>
	);
};

export default VoyBasicInfo;
