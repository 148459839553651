import React, { useMemo, useState } from "react";
import debounce from "lodash/debounce";
import {
	IomVesInfoItemType,
	VoyBasicInfoItemType,
	CompanyInfoItemType
} from "../type";
import { CommonChangeEvent, CommonCommitEvent } from "@/types/event";
import { onRequest } from "@/api";
import { useReminder, useAppDispatch } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "@/tools/email";
import { initialData } from "../source";

const useIndex = () => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [voyBasicInfoData, setVoyBasicInfoData] =
		useState<VoyBasicInfoItemType>(initialData);

	const [iomVesInfoData, setIomVesInfoData] = useState<IomVesInfoItemType[]>(
		[]
	);
	const [companyInfoData, setCompanyInfoData] = useState<CompanyInfoItemType[]>(
		[]
	);

	console.log("iomVesInfoData", iomVesInfoData);
	console.log("companyInfoData", companyInfoData);

	const checkImoVes = useMemo(() => {
		const loadOptions = (imoVes: string) => {
			console.log("检索imoVes", imoVes);
			onRequest(
				"getVesselPageApi",
				{ key: imoVes, pageNum: 1, pageSize: 20 },
				handleCheckImoVesFront,
				handleCheckImoVesSuccess,
				handleCheckImoVesError,
				dispatch,
				navigate
			);
		};
		return debounce(loadOptions, 500);
	}, []);
	const handleCheckImoVesFront = () => {};
	const handleCheckImoVesSuccess = (res) => {
		console.log("检索imoVes成功", res.data);
		setIomVesInfoData(res.data);
	};
	const handleCheckImoVesError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const checkCompanyName = useMemo(() => {
		const loadOptions = (companyName: string) => {
			console.log("检索companyName", companyName);
			onRequest(
				"getCompanyPageApi",
				{ key: companyName, pageNum: 1, pageSize: 20 },
				handleCheckCompanyNameFront,
				handleCheckCompanyNameSuccess,
				handleCheckCompanyNameError,
				dispatch,
				navigate
			);
		};
		return debounce(loadOptions, 500);
	}, []);

	const handleCheckCompanyNameFront = () => {};
	const handleCheckCompanyNameSuccess = (res) => {
		setCompanyInfoData(res.data);
	};
	const handleCheckCompanyNameError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleChangeBasicInfoItem: CommonChangeEvent<VoyBasicInfoItemType> = (
		key,
		value
	) => {
		setVoyBasicInfoData((prev) => {
			if (key === "vesselName") {
				checkImoVes(value);
			}

			if (key === "companyName") {
				checkCompanyName(value);
			}

			return { ...prev, [key]: value };
		});
	};

	const handleBasicInfoItemCommit: CommonCommitEvent<VoyBasicInfoItemType> = (
		item
	) => {
		setVoyBasicInfoData((prev) => {
			return { ...prev, ...item };
		});
	};

	const validateBasicInfo = () => {
		const { masterEmail, customerEmail } = voyBasicInfoData;
		if (!validateEmail(masterEmail)) {
			reminder("error", "masterEmail: Invalid email address");
			return false;
		}
		if (!validateEmail(customerEmail)) {
			reminder("error", "customerEmail: Invalid email address");
			return false;
		}

		return true;
	};

	const clearVoyBasicInfoData = () => {
		setVoyBasicInfoData(initialData);
	};

	return {
		voyBasicInfoData,
		handleChangeBasicInfoItem,
		handleBasicInfoItemCommit,
		setVoyBasicInfoData,
		validateBasicInfo,
		clearVoyBasicInfoData
	};
};

export default useIndex;
