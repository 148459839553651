import { useRef, useState } from "react";
import {
	RouteWayPointItemType,
	RouteWayTrackPointGeoJson,
	RouteWayTrackLineGeoJson,
	generateWayLineString,
	generateWayPoints,
	normalizeLongitude,
	generateWayTrackPoints
} from "../components";

const useRouteCenter = (
	onWayTrackPointLoad: (items: RouteWayTrackPointGeoJson[]) => void,
	onWayTrackLineLoad: (items: RouteWayTrackLineGeoJson[]) => void
) => {
	const cacheSource = useRef<Map<string, RouteWayPointItemType[]>>(new Map());
	const [activePlan, setActivePlan] = useState<string>(null);
	const [isUpdateing, setIsUpdateing] = useState<number>(-1);
	const activeEditPlanId = useRef<string>(null);

	const handlePlanChange = (planId: string) => {
		activeEditPlanId.current = planId;
		setActivePlan(planId);
	};

	const dataSource = useRef<{
		trackLines: RouteWayTrackLineGeoJson[];
		trackPoints: RouteWayTrackPointGeoJson[];
		origin: RouteWayPointItemType[];
	}>({
		trackLines: [],
		trackPoints: [],
		origin: []
	});

	const handleWayPointsReset = (type: "trackPoint" | "trackLine" | "all") => {
		if (type === "all") {
			const { trackLines, trackPoints, origin } = generateWayTrackPoints(
				activeEditPlanId.current,
				cacheSource?.current?.entries()
			);
			dataSource.current = {
				trackLines,
				trackPoints,
				origin
			};
			onWayTrackPointLoad?.(trackPoints);
			onWayTrackLineLoad?.(trackLines);
			return;
		}

		if (type === "trackPoint") {
			const trackItems = cacheSource?.current?.get(activeEditPlanId?.current);
			const pointItems = generateWayPoints(trackItems),
				pointLines = generateWayLineString(
					activeEditPlanId?.current,
					trackItems
				),
				trackPoints = [...pointItems, pointLines];
			dataSource.current = {
				...dataSource.current,
				trackPoints: trackPoints,
				origin: trackItems
			};
			onWayTrackPointLoad?.(trackPoints);
			return;
		}

		if (type === "trackLine") {
			const trackList = cacheSource?.current?.entries(),
				trackLines: RouteWayTrackLineGeoJson[] = [];
			for (let [planId, planItems] of trackList) {
				if (planId === activeEditPlanId?.current) continue;
				trackLines?.push(generateWayLineString(planId, planItems));
			}
			dataSource.current = {
				...dataSource.current,
				trackLines
			};
			onWayTrackLineLoad?.(trackLines);
			return;
		}
	};

	const handleRoutePlanCrud = (
		type: "get" | "visible" | "hidden" | "edit",
		planId: string,
		items?: RouteWayPointItemType[]
	) => {
		switch (true) {
			case type === "get":
				return cacheSource?.current?.get(planId) ?? [];
			case type === "hidden":
				cacheSource?.current?.delete(planId);
				if (activeEditPlanId?.current === planId) {
					activeEditPlanId.current = null;
					setActivePlan(null);
				}
				break;
			case type === "visible" && !!planId:
				cacheSource?.current?.set(planId, items);
				break;
			case type === "edit":
				if (!cacheSource?.current?.has(planId) || !!items) {
					cacheSource?.current?.set(planId, items);
				}
				activeEditPlanId.current = planId;
				setActivePlan(planId);
				break;
			default:
				break;
		}
		handleWayPointsReset("all");
	};

	const handleWayPointCrud = (
		type: "update" | "newly" | "delete",
		item: RouteWayPointItemType
	) => {
		const currentItems = cacheSource?.current?.get(activeEditPlanId?.current);
		const currentIndex = currentItems?.findIndex(
			(pointItem) => pointItem?.id === item?.id
		);
		switch (true) {
			case type === "newly":
				currentItems?.splice(item?.seq - 1, 0, item);
				cacheSource?.current?.set(
					activeEditPlanId?.current,
					currentItems?.map((selectItem, index) => ({
						...selectItem,
						seq: index + 1
					}))
				);
				break;
			case type === "delete":
				currentItems?.splice(currentIndex, 1);
				cacheSource?.current?.set(
					activeEditPlanId?.current,
					currentItems?.map((selectItem, index) => ({
						...selectItem,
						seq: index + 1
					}))
				);
				break;
			case type === "update":
				const currentPointItem = currentItems?.[currentIndex];
				currentItems?.splice(currentIndex, 1, {
					...currentPointItem,
					...item,
					lat: item?.lat,
					lon: normalizeLongitude(item?.lon, "EPR")
				});
				cacheSource?.current?.set(activeEditPlanId?.current, [...currentItems]);
				break;
			default:
				break;
		}
		handleWayPointsReset?.("trackPoint");
		setIsUpdateing((prev) => prev + 1);
	};

	const handleRouteRemove = (planIds: string[]) => {
		for (let planId of planIds) {
			cacheSource?.current?.delete(planId);
			if (activeEditPlanId?.current === planId) {
				activeEditPlanId.current = null;
				setActivePlan(null);
			}
		}
		handleWayPointsReset("all");
	};

	return {
		isUpdateing,
		activePlan,
		activeEditPlanId,
		dataSource,
		handleWayPointsReset,
		handleRoutePlanCrud,
		handleWayPointCrud,
		handlePlanChange,
		handleRouteRemove
	};
};

export default useRouteCenter;
