import React, { FC } from "react";
import style from "./index.module.less";
import { PlanTitle } from "../../components";
import { VoyBasicsProps } from "./type";
import { timestampToUtcMdhm } from "@/tools/date";
import classNames from "classnames";

const VoyBasics: FC<VoyBasicsProps> = (props) => {
	const { data } = props;

	return (
		<div className={classNames(["flag-no-break", style["vpr-voy-basics"]])}>
			<PlanTitle
				title="Voyage Basics"
				showDot={false}
				iconChildren={
					<img
						className="vp-title-icon"
						src="/images/leg/plan/leg_plan_ship_icon.svg"
					/>
				}
			/>
			<div className="voy-basic-content">
				<div className="voy-basic-item">
					<span className="voy-basic-label">Company Name:</span>
					<span className="voy-basic-value">{data?.companyName}</span>
				</div>
				<div className="voy-basic-item">
					<span className="voy-basic-label">Departure Port:</span>
					<span className="voy-basic-value">{data?.depPort}</span>
				</div>
				<div className="voy-basic-item">
					<span className="voy-basic-value">
						{data?.etd ? timestampToUtcMdhm(data?.etd) : "---"}
					</span>
				</div>
				<div className="voy-basic-item">
					<span className="voy-basic-label">Vessel Name:</span>
					<span className="voy-basic-value">{data?.vesselName}</span>
				</div>
				<div className="voy-basic-item">
					<span className="voy-basic-label">Arrival Port:</span>
					<span className="voy-basic-value">{data?.arrPort}</span>
				</div>
				<div className="voy-basic-item">
					<span className="voy-basic-value">
						{data?.eta ? timestampToUtcMdhm(data?.eta) : "---"}
					</span>
				</div>
				<div className="voy-basic-item">
					<span className="voy-basic-label">Report Created:</span>
					<span className="voy-basic-value">
						{timestampToUtcMdhm(data?.createTime)}
					</span>
				</div>
				<div className="voy-basic-item">
					<span className="voy-basic-label">Voyage Condition:</span>
					<span className="voy-basic-value">
						{data?.legType === "B" ? "Ballast" : "Laden"}
					</span>
				</div>
				<div className="voy-basic-item"></div>
			</div>
		</div>
	);
};

export default VoyBasics;
