import React, { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "@/store";
import { Toaster } from "react-hot-toast";
import { SnackbarProvider } from "notistack";
import i18n from "./locale";
import Router from "@/router";

const App: React.FC<{}> = (props) => {
	const notistackRef = useRef(null);
	return (
		<Provider store={store}>
			<SnackbarProvider maxSnack={3} ref={notistackRef}>
				<I18nextProvider i18n={i18n}>
					<BrowserRouter>
						<Router></Router>
					</BrowserRouter>
				</I18nextProvider>
			</SnackbarProvider>
			<Toaster />
		</Provider>
	);
};

export default App;
