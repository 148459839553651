import React, { FC, forwardRef, useEffect, useImperativeHandle } from "react";
import style from "./index.module.less";
import classNames from "classnames";
import { Button, Table, Icon } from "@/components";
import { noonBasicReportColumns } from "./source";
import useIndex from "./hooks/useIndex";
import { AddNoonReport, AddNoonSegment } from "./components";
import { NoonPropsType, NoonRefType } from "./type";
import { FleetTitle } from "../../common";
import { LegBasicInfo } from "../../components";

const Noon = forwardRef<NoonRefType, NoonPropsType>((props, ref) => {
	const {
		handleAddReportClose,
		handleAddReportClick,
		handleAddReportSuccess,

		isHideAddSegmentDialog,
		handleAddSegmentClose,
		handleAddSegmentClick,
		handleAddSegmentSuccess,

		legDetailsData,
		queryData,
		scReportDateList,

		basicDatasource,
		curCospDateTime
	} = useIndex(props);

	const { noorReportsVisible, handleNoorReportsVisibleChange } = props;

	return (
		<div
			className={classNames("fleet-layout-common", style["fleet-leg-noon"], {
				"noon-active-tab": props.activeTab === "noon"
			})}
		>
			{/* 添加Report弹框 */}
			<AddNoonReport
				legData={legDetailsData}
				isCopy={props.isCopyReport}
				localData={props.detailReportData}
				legId={queryData.id}
				hide={!props.visibleAddReportDialog}
				onClose={handleAddReportClose}
				onAddSuccess={handleAddReportSuccess}
				curCospDateTime={curCospDateTime}
			/>
			{/* 添加Segment弹框 */}
			<AddNoonSegment
				scReportDateList={scReportDateList}
				queryData={queryData}
				hide={isHideAddSegmentDialog}
				onClose={handleAddSegmentClose}
				onAddSuccess={handleAddSegmentSuccess}
			/>

			<div className="leg-noon-left">
				<LegBasicInfo
					vesselName={legDetailsData?.voyage?.vesselName}
					voyageNo={legDetailsData?.voyage?.voyageNo}
					legTypeName={queryData.legType === "B" ? "Ballast" : "Laden"}
				/>
				<div className="noon-info">
					<div className="noon-header">
						<FleetTitle
							text="Noon"
							suffixIcon={
								<Icon
									type={
										noorReportsVisible ? "icon-legend_show" : "icon-legend_hide"
									}
									onClick={() =>
										handleNoorReportsVisibleChange(!noorReportsVisible)
									}
									size={20}
									color="#2686f6"
								/>
							}
						/>
						<div className="noon-ctrl">
							<Button onClick={handleAddSegmentClick}>Segment</Button>
							<Button
								prefixIcon={<Icon type="icon-add" />}
								onClick={handleAddReportClick}
							>
								Report
							</Button>
						</div>
					</div>
					<div className="noon-body">
						<Table
							id="key"
							dataSource={basicDatasource}
							className="noon-table-desc"
							scroll={{ x: 800, y: 650 }}
							columns={noonBasicReportColumns}
							onRow={(data, index) => {
								return {
									onDoubleClick: (index) => {
										props.handleNoonListOpen();
									}
								};
							}}
							pagination={false}
						/>
					</div>
				</div>
			</div>
		</div>
	);
});

export default Noon;
