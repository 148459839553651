import { CommonOptionType } from "@/types/common";
import { NRDirItemType } from "../../type";
import { FuelTableDataType } from "../../common/noonFuelForm";
import { NoonMainInfoFormType } from "../../common/noonMainInfoForm/type";
import { NoonNextPortFormType } from "../../common/noonNextPortForm/type";
import { NrPatiFormType, NrViFormType, NrBasicDataType } from "./type";

export const initialNrFuelData: FuelTableDataType = [
	{
		id: 1,
		mt: "BROB",
		isNecessary: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 2,
		mt: "ME Cons SLR",
		isNecessary: false,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 3,
		mt: "AE Cons SLR",
		isNecessary: false,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 4,
		mt: "Other Cons SLR",
		isNecessary: false,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	},
	{
		id: 5,
		mt: "Total Cons SLR",
		isNecessary: false,
		disabled: true,
		hfo: "",
		lsfo: "",
		mdo: "",
		mgo: ""
	}
];

export const initialNrMiData: NoonMainInfoFormType = {
	vesselName: "",
	imo: "",
	callSign: ""
};

export const initialNrPatiData: NrPatiFormType = {
	latitude: 0,
	longitude: 0,
	positionDate: ""
};

export const initialNrViData: NrViFormType = {
	foreDraft: "",
	aftDraft: "",
	midDraft: "",
	avgSogSlr: "",
	course: "",
	distanceSlr: "",
	distanceToGo: "",
	mePowerSlr: "",
	avgRpmSlr: "",
	slipSlr: "",
	timeSlr: "",
	mcr: "",
	orderedSpeed: "",
	windBeaufort: "",
	windDirection: "NE",
	seasHeight: "",
	swellHeight: "",
	swellDirection: "E"
};

export const initialNrNpData: NoonNextPortFormType = {
	nextPort: "",
	unlocode: "",
	eta: ""
};

export const initialNrBasicData: NrBasicDataType = {
	id: null
};

export const wsDirectOptions: CommonOptionType<NRDirItemType, NRDirItemType>[] =
	[
		{ label: "N", value: "N" },
		{ label: "NNE", value: "NNE" },
		{ label: "NE", value: "NE" },
		{ label: "ENE", value: "ENE" },
		{ label: "E", value: "E" },
		{ label: "ESE", value: "ESE" },
		{ label: "SE", value: "SE" },
		{ label: "SSE", value: "SSE" },
		{ label: "S", value: "S" },
		{ label: "SSW", value: "SSW" },
		{ label: "SW", value: "SW" },
		{ label: "WSW", value: "WSW" },
		{ label: "W", value: "W" },
		{ label: "WNW", value: "WNW" },
		{ label: "NW", value: "NW" },
		{ label: "NNW", value: "NNW" }
	];
