import { useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { onRequest } from "@/api";

const useIndex = () => {
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const [imageUrl, setUrl] = useState<string>();

	const setImageUrl = (url) => {
		console.log(url);
	};
	const uploadSuccess = (res) => {
		reminder("success", "Upload Avatar Success");
		setLoading(false);
		setImageUrl(res);
	};
	const uploadError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const handleUpload = (files) => {
		const formData = new FormData();
		formData.append("avatarfile", files);
		onRequest(
			"updatePersonalAvatarApi",
			{ avatarfile: formData },
			() => {
				setLoading(true);
			},
			uploadSuccess,
			uploadError,
			dispatch,
			navigate
		);
	};
	const handleChange = (info) => {
		setLoading(false);
		handleUpload(info);
		return false;
	};
	return {
		loading,
		handleChange,
		imageUrl,
		handleUpload
	};
};
export default useIndex;
