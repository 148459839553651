import React from "react";
import "./index.module.less";
import { FractionProps } from "./type";

const Fraction: React.FC<FractionProps> = ({ numerator, denominator }) => {
	return (
		<div className="fraction-container">
			<span className="numerator">{numerator}</span>
			<div className="fraction-line"></div>
			<span className="denominator">{denominator}</span>
		</div>
	);
};

export default Fraction;
