import { useRef, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonColumnChangeEvent } from "@/types/event";
import { onRequest } from "@/api";
import { useReminder, useAppDispatch } from "@/hooks";
import { replaceNullWithEmptyString } from "@/tools/transfromData";
import {
	NoonBasicReportTableType,
	NoonBasicReportItemType,
	NoonDetailsReportItemType,
	NoonDetailsReportTableType,
	NRDirItemType,
	DlReportType,
	NoonPropsType
} from "../business/noon/type";
import { NoonRefType } from "../business/noon/type";
import { RouteCommonMapRefType } from "../components/map/type";

const useNoonIndex = (
	loadNoonReports: RouteCommonMapRefType["loadNoonReports"],
	onNoorReportsVisible: RouteCommonMapRefType["onNoorReportsVisible"]
) => {
	const { reminder } = useReminder();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();

	const queryData = {
		id: searchParams.get("id"),
		legType: searchParams.get("legType"),
		voyageId: searchParams.get("voyageId")
	};

	/**
	 * ------------------------Noon详情页-------------------------------------
	 */
	const noonRef = useRef<NoonRefType>(null);
	const [noonListVisible, setNoonListVisible] = useState<boolean>(false);
	const handleNoonListClose = () => {
		setNoonListVisible(false);
	};
	const handleNoonListOpen = () => {
		setNoonListVisible(true);
	};

	const [basicReportTableData, setBasicReportTableData] =
		useState<NoonBasicReportTableType>([]);

	const [detailsReportTableData, setDetailsReportTableData] =
		useState<NoonDetailsReportTableType>([]);

	const handleRequestFront = () => {};
	const handleRequestError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};

	const handleGetReportBasicSuccess = (res) => {
		setBasicReportTableData(res.data);

		console.log("啦啦啦", res.data);

		const noonReportData = res.data.map((item) => {
			return {
				lat: item.lat,
				lon: item.lon,
				id: item.id,
				type: item.type,
				datetime: item.dateTime
			};
		});

		loadNoonReports(noonReportData);
	};

	const handleGetReportDetailSuccess = (res) => {
		setDetailsReportTableData(
			res.data.map((item) => {
				return replaceNullWithEmptyString(item);
			})
		);
	};

	// 获取基础报文列表数据
	const getBasicReportList = () => {
		onRequest(
			"getReportBasicByIdApi",
			{ id: queryData.id },
			handleRequestFront,
			handleGetReportBasicSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	// 获取详情报文列表数据
	const getDetailReportList = () => {
		onRequest(
			"getReportDetailByIdApi",
			{ id: queryData.id },
			handleRequestFront,
			handleGetReportDetailSuccess,
			handleRequestError,
			dispatch,
			navigate
		);
	};

	// 刷新数据列表
	const updateDataReportList = () => {
		getBasicReportList();
		getDetailReportList();
	};

	// 更新报文列表中某行的某一字段值
	const handleChangeReportTableItem: CommonColumnChangeEvent<
		NoonDetailsReportItemType
	> = (currentIndex, key, value) => {
		setDetailsReportTableData((prev) => {
			const curItem = prev[currentIndex];
			prev.splice(currentIndex, 1, { ...curItem, [key]: value });
			return [...prev];
		});
	};

	// 当前进入详情页编辑的数据在列表中的索引
	const [detailReportIndex, setDetailReportIndex] = useState<number>(-1);
	const handleDrIndexChange = (val: number) => {
		setDetailReportIndex(val);
	};

	// 是否是复制操作
	const [isCopyReport, setIsCopyReport] = useState<boolean>(false);
	const handleIsCopyChange = (val: boolean) => {
		setIsCopyReport(val);
	};

	const detailReportData = useMemo<NoonDetailsReportItemType>(() => {
		if (detailReportIndex < 0) {
			return null;
		}
		return detailsReportTableData[detailReportIndex];
	}, [detailReportIndex]);

	// 添加report弹框
	const [visibleAddReportDialog, setVisibleAddReportDialog] =
		useState<boolean>(false);

	const handleArDialogVisibleChange = (val: boolean) => {
		setVisibleAddReportDialog(val);
	};

	const [noorReportsVisible, setNoorReportsVisible] = useState<boolean>(true);
	const handleNoorReportsVisibleChange = (val: boolean) => {
		setNoorReportsVisible(val);
		onNoorReportsVisible(val ? "visible" : "none");
	};

	return {
		noonRef,
		noonListVisible,
		handleNoonListClose,
		handleNoonListOpen,
		basicReportTableData,
		detailsReportTableData,
		updateDataReportList,
		handleChangeReportTableItem,
		isCopyReport,
		handleIsCopyChange,
		detailReportData,
		handleDrIndexChange,
		visibleAddReportDialog,
		handleArDialogVisibleChange,
		noorReportsVisible,
		handleNoorReportsVisibleChange
	};
};

export default useNoonIndex;
