import React, { FC } from "react";
import { Table } from "@/components";
import style from "./index.module.less";
import { VoyPlanTableProps } from "./type";
import { vpTableColumns } from "./source";
import useIndex from "./hooks/useIndex";

const VoyPlanTable: FC<VoyPlanTableProps> = (props) => {
	const { vpTableOpColumns } = useIndex(props);

	// 计算port路径
	let portPathStr = "";
	const portNameList = props.data.dataList[0].portName;
	portNameList.forEach((portName, index, array) => {
		portPathStr += `[${index + 1}] ${portName}`;
		if (index !== array.length - 1) {
			portPathStr += " ~ ";
		}
	});

	return (
		<div className={style["voy-plan-table"]}>
			<p className="vp-table-header">{portPathStr}</p>
			<Table
				id="id"
				dataSource={props?.data?.dataList}
				bordered={true}
				columns={[...vpTableColumns, ...vpTableOpColumns]}
				scroll={null}
				className="vp-table-body"
				pagination={false}
			/>
		</div>
	);
};

export default VoyPlanTable;
