import { greatCircle } from "@turf/turf";
import {
	RouteWayPointItemType,
	RouteWayTrackLineGeoJson,
	RouteWayTrackPointGeoJson
} from "../../types";
import { normalizeLongitude } from "../lngLat";
import { isThreDimensionalArr } from "./is";

export const generateWayLineString = (
	planId: string,
	items: RouteWayPointItemType[]
): RouteWayTrackLineGeoJson => {
	const trackLines: RouteWayTrackLineGeoJson = {
		type: "Feature",
		geometry: {
			type: "MultiLineString",
			coordinates: []
		},
		properties: {
			planId: planId
		}
	};
	let lineType: RouteWayPointItemType["nav"] = null,
		coordinates: GeoJSON.Position[] = [],
		prevCoordinate: GeoJSON.Position = null;

	for (let index = 0; index <= items?.length - 1; index++) {
		const currentItem = items?.[index];
		debugger;
		switch (true) {
			case index === 0:
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				break;
			case currentItem?.nav === "GC":
				coordinates?.length > 0 &&
					trackLines?.geometry?.coordinates?.push(coordinates);
				let tempCoordinates = greatCircle(
					prevCoordinate,
					[normalizeLongitude(currentItem?.lon), currentItem?.lat],
					{
						npoints: 30
					}
				)?.geometry?.coordinates;
				const isAcross180 =
					tempCoordinates?.every((selectItem) => selectItem?.length > 2) ||
					isThreDimensionalArr(tempCoordinates);
				if (isAcross180) {
					tempCoordinates = (
						tempCoordinates as unknown as GeoJSON.Position[][]
					)?.flat();
				}
				coordinates = (tempCoordinates as unknown as GeoJSON.Position[])?.map(
					(coordinate) => [normalizeLongitude(coordinate?.[0]), coordinate?.[1]]
				);
				trackLines?.geometry?.coordinates?.push(coordinates);
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				lineType = currentItem?.nav;
				coordinates = [];
				break;
			case lineType !== currentItem?.nav && currentItem?.nav === "RL":
				coordinates?.push(prevCoordinate);
			case currentItem?.nav === "RL":
				coordinates?.push([
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				]);
				prevCoordinate = [
					normalizeLongitude(currentItem?.lon),
					currentItem?.lat
				];
				lineType = currentItem?.nav;
				break;
			default:
				break;
		}
	}

	if (coordinates?.length > 0) {
		trackLines?.geometry?.coordinates?.push(coordinates);
	}
	console.log("trackLines", trackLines);
	return trackLines;
};

export const generateWayPoints = (
	items: RouteWayPointItemType[]
): RouteWayTrackPointGeoJson[] => {
	return items?.map((pointItem) => {
		return {
			type: "Feature",
			geometry: {
				type: "Point",
				coordinates: [normalizeLongitude(pointItem?.lon), pointItem?.lat]
			},
			properties: {
				...pointItem
			}
		};
	});
};

export const generateWayTrackPoints = (
	planId: string,
	items: IterableIterator<[string, RouteWayPointItemType[]]>
): {
	trackLines: RouteWayTrackLineGeoJson[];
	trackPoints: RouteWayTrackPointGeoJson[];
	origin: RouteWayPointItemType[];
} => {
	const trackLines: RouteWayTrackLineGeoJson[] = [],
		trackPoints: RouteWayTrackPointGeoJson[] = [],
		origin: RouteWayPointItemType[] = [];

	for (let [currentPlanId, pointItems] of items) {
		if (planId && planId === currentPlanId) {
			origin?.push(...pointItems);
			trackPoints?.push(...generateWayPoints(pointItems));
			trackPoints?.push(generateWayLineString(currentPlanId, pointItems));
		} else {
			trackLines?.push(generateWayLineString(currentPlanId, pointItems));
		}
	}
	return {
		trackLines,
		trackPoints,
		origin
	};
};
