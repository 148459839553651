import { MouseEventHandler, useRef, useState } from "react";
import { LegDetailsMenuProps } from "../components";
import { RouteCommonMapRefType } from "../components/map";
import { RouteRefType } from "../business/route/type";

const useIndex = () => {
	const mapRef = useRef<RouteCommonMapRefType>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const routeRef = useRef<RouteRefType>(null);

	const [activeTab, setActiveTab] =
		useState<LegDetailsMenuProps["activeTab"]>("basic");

	const handleTabSelect: LegDetailsMenuProps["onSelect"] = (value) => {
		if (activeTab === value) return;
		setActiveTab(value);
	};

	const handleDragMove = (event: MouseEvent) => {
		let width =
			event?.clientX - containerRef?.current?.getBoundingClientRect()?.left;
		(containerRef.current.firstElementChild as HTMLDivElement).style.width =
			width + "px";
		(containerRef.current.lastElementChild as HTMLDivElement).style.width =
			`calc(100% - ${width - 3}px)`;
	};

	const handleDragUp = (event: MouseEvent) => {
		document.removeEventListener("mousemove", handleDragMove);
		document.removeEventListener("mouseup", handleDragUp);
		document.onselectstart = null;
		// 允许用户拖动元素
		document.ondragstart = null;
	};

	const handleDragDown: MouseEventHandler<HTMLSpanElement> = (event) => {
		// 禁止用户选择网页中文字
		document.onselectstart = () => false;
		// 禁止用户拖动元素
		document.ondragstart = () => false;
		document.addEventListener("mousemove", handleDragMove);
		document.addEventListener("mouseup", handleDragUp);
	};

	return {
		activeTab,
		containerRef,
		handleTabSelect,
		handleDragDown,
		handleDragUp,
		mapRef,
		routeRef
	};
};

export default useIndex;
