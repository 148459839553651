import React, { useState, useRef, useMemo, useEffect } from "react";
import useBasicInfoIndex from "../../../common/basicInfo/hooks/useIndex";
import useCpSpdIndex from "../../../common/cpSpd/hooks/useIndex";
import useGwClauseIndex from "../../../common/gwClause/hooks/useIndex";
import { useReminder, useAppDispatch } from "@/hooks";
import { VoyageFO, CpFO, GwClauseFO } from "@/api/voy/type";
import { onRequest } from "@/api";
import { useLocation, useNavigate } from "react-router-dom";
import {
	VoyLegTableItemType,
	DataType as VoyLegDataType
} from "../components/leg";
import { ColumnsType } from "antd/es/table";
import { VoyageParamsType } from "@/api/voy/type";
import { transformBooleanProperties } from "@/tools/amount";
import { VoyGwClauseItemType } from "../../../common/gwClause/type";
import { size } from "@floating-ui/react";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();
	const location = useLocation();
	const navigate = useNavigate();
	const voyId = location.search.split("=")[1];
	const [loading, setLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,
		setVoyBasicInfoData,
		validateBasicInfo
	} = useBasicInfoIndex();

	console.log("voyBasic信息", voyBasicInfoData);

	const {
		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		setVoyCpSpdTableData,
		delIds,
		handleClearDelIds,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess
	} = useCpSpdIndex(isDisabled);

	const {
		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,
		setVoyGwClauseData
	} = useGwClauseIndex();

	const getVoyageDetails = () => {
		onRequest(
			"getVoyageDetailsApi",
			voyId,
			() => {
				setLoading(true);
			},
			(res) => {
				setLoading(false);
				// 设置详情页的数据
				setVoyBasicInfoData(res.data.voyage);
				setVoyCpSpdTableData(res.data.cpList);
				setVoyGwClauseData(
					transformBooleanProperties({
						...res.data.gwClause
					}) as VoyGwClauseItemType
				);
			},
			(error) => {
				setLoading(false);
				reminder(
					"error",
					error?.msg ? error?.msg + ": " + error?.data : error?.data
				);
			},
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		getVoyageDetails();
		onVoyLegPageChange(1, 20);
	}, []);

	// console.log("1123123", navigate);
	// leg相关
	const [voyLegTableData, setVoyLegTableData] = useState<VoyLegTableItemType[]>(
		[]
	);
	const openDetailDialog = useRef<boolean>(false);
	const voyLegHandleDoubleClick = () => {
		openDetailDialog.current = true;
	};
	const [voyLegSelectedRowKeys, setVoyLegSelectedRowKeys] = useState<number[]>(
		[]
	);
	const onVoyLegSelectChange = (newSelectedRowKeys: number[]) => {
		setVoyLegSelectedRowKeys(newSelectedRowKeys);
	};
	const [voyLegPager, setVoyLegPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const voyLegCacheSource = useRef<{
		[index: number]: VoyLegTableItemType[];
	}>({});

	const onVoyLegPageChange = (page: number, size: number) => {
		onRequest(
			"getLegPageApi",
			{
				pageSize: size,
				pageNum: page,
				voyageId: voyId
			},
			null,
			(response) => {
				// if (!Array.isArray(response?.data)) {
				// 	voyLegCacheSource.current[page] = response?.data?.records;
				// } else {
				// 	voyLegCacheSource.current[page] = [];
				// }
				voyLegCacheSource.current[page] = response?.data?.records || [];
				setVoyLegPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			null,
			dispatch,
			navigate
		);
	};

	const voyLegDataSource = useMemo(() => {
		return voyLegCacheSource.current[voyLegPager.pageNum];
	}, [voyLegPager.pageNum, voyLegPager.total]);
	const voyLegOpereteColumns: ColumnsType<VoyLegDataType> = useMemo(() => {
		return [];
	}, []);

	const handleDeleteClick = () => {
		onRequest(
			"deleteVoyageApi",
			voyId,
			() => {
				setLoading(true);
			},
			(res) => {
				setLoading(false);
				console.log("handleDeleteClick", res);
				reminder("success", "delete voyage sucess");
				navigate(-1);
			},
			(error) => {
				setLoading(false);
				reminder(
					"error",
					error?.msg ? error?.msg + ": " + error?.data : error?.data
				);
			},
			dispatch,
			navigate
		);
	};

	const [isEditHide, setIsEditHide] = useState<boolean>(true);

	const handleEditClick = () => {
		setIsDisabled(false);
	};

	const handleCloseAdd = () => {
		setIsEditHide(true);
	};
	const handleEditSuccess = () => {
		// setIsEditHide(true);
		setIsDisabled(true);
		getVoyageDetails();
	};

	const [LegAddDialogVisible, setLegAddDialogVisible] =
		useState<boolean>(false);

	const handleOpenLegAdd = () => {
		setLegAddDialogVisible(true);
	};
	const handleCloseLegAdd = () => {
		setLegAddDialogVisible(false);
	};

	const handleSaveClick = () => {
		if (!validateBasicInfo()) return;

		const voyage: VoyageFO = {
			...voyBasicInfoData
		};

		const cp: CpFO = {
			cpList: voyCpSpdTableData,
			delIds
		};

		// proportion需要除100再发送给后端
		// const proportion =
		// 	voyGwClauseData.proportion !== ""
		// 		? voyGwClauseData.proportion / 100
		// 		: voyGwClauseData.proportion;
		const gwClause: GwClauseFO = {
			...voyGwClauseData
			// proportion
		};

		const updateVoyageFront = () => {
			setLoading(true);
		};

		const updateVoyageSuccess = (res) => {
			setLoading(false);
			handleClearDelIds();
			console.log("updateVoyageSuccess", res);
			// getVoyageDetails();
			handleEditSuccess();
			reminder("success", "update voyage success");
		};

		const updateVoyageError = (error) => {
			setLoading(false);
			handleClearDelIds();
			console.log("updateVoyageError", error);
			reminder(
				"error",
				error?.msg ? error?.msg + ": " + error?.data : error?.data
			);
		};

		onRequest(
			"updateVoyageApi",
			{ voyage, cp, gwClause },
			updateVoyageFront,
			updateVoyageSuccess,
			updateVoyageError,
			dispatch,
			navigate
		);

		console.log(voyage);
		console.log(cp);
		console.log(gwClause);
	};

	const handleCancelClick = () => {
		setIsDisabled(true);
	};

	return {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,

		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		isHideCpDialog,
		onCpClose,
		onCpPickSuccess,

		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,

		loading,

		voyLegTableData,
		voyLegHandleDoubleClick,
		voyLegSelectedRowKeys,
		onVoyLegSelectChange,
		voyLegPager,
		onVoyLegPageChange,
		voyLegDataSource,
		voyLegOpereteColumns,
		handleOpenLegAdd,
		LegAddDialogVisible,
		handleCloseLegAdd,

		handleDeleteClick,
		handleEditClick,
		handleCloseAdd,
		handleEditSuccess,
		isEditHide,
		voyId,

		isDisabled,
		handleSaveClick,
		handleCancelClick,
		voyLegCacheSource
	};
};

export default useIndex;
