export const CPTermsColumns = [
	{
		title: "CP",
		dataIndex: "speedCategory",
		key: "speedCategory"
	},
	{
		title: "Speed (kts)",
		dataIndex: "speed",
		key: "speed"
	},
	{
		title: "FO (mt)",
		dataIndex: "foConsumption",
		key: "foConsumption"
	},
	{
		title: "DGO (mt)",
		dataIndex: "dgoConsumption",
		key: "dgoConsumption"
	}
];
