import React, { FC, forwardRef, useImperativeHandle } from "react";
import styles from "./index.module.less";
import { Button, Loading, Table } from "@/components";
import { LegBasicInfo } from "@/pages/leg/business/details/components/legBasicInfo";
import { NewRouteDialogDialog, Graph, CustomTable } from "./components";
import useIndex from "./hooks/useIndex";
import { FleetTitle, SplitPanel } from "../../common";
import { RouteProps, RouteRefType } from "./type";
import classNames from "classnames";

const Route = forwardRef<RouteRefType, RouteProps>(
	(
		{
			activeTab,
			onWayPointFocus,
			onSelectForecast,
			onOpenForecast,
			onCloseForecast
		},
		ref
	) => {
		const {
			addVisible,
			routeList,
			loading,
			simulationSelecedInfo,
			activeWaypointRowId,
			setSimulationSelecedInfo,
			handleCloseNewRouteDialog,
			handleOpenNewRouteDialog,
			initPageData,
			handleFocusWaypoint,
			handleClickWaypoint,
			customTableRefs,
			handleDistanceUpdate,
			updateActiveRouteId
		} = useIndex(activeTab, onWayPointFocus);

		useImperativeHandle(ref, () => ({
			scrollToWayPoint: handleFocusWaypoint,
			moveWayPointAfter: handleDistanceUpdate
		}));
		return (
			<div
				className={classNames(styles["leg-route"], {
					[styles["leg-route-activity"]]: activeTab === "route"
				})}
			>
				<Loading loading={loading} />
				<div className="route-left">
					<LegBasicInfo />
					<div className="route-header">
						<FleetTitle text="Route" />
						<div className="operationArea">
							<Button onClick={handleOpenNewRouteDialog}>+ New Route</Button>
						</div>
					</div>
					{routeList.map((item, index) => {
						return (
							<CustomTable
								ref={(el) => (customTableRefs.current[item.id] = el)}
								key={item?.id}
								routeInfo={item}
								isFirstTable={index === 0}
								activeWaypointRowId={activeWaypointRowId}
								simulationSelecedInfo={simulationSelecedInfo}
								resetRouteList={initPageData}
								onClickWaypoint={(item) => {
									handleClickWaypoint(item);
								}}
								updateActiveRouteId={updateActiveRouteId}
								setSimulationSelecedInfo={setSimulationSelecedInfo}
								onSelectForecast={onSelectForecast}
								onOpenForecast={onOpenForecast}
								onCloseForecast={onCloseForecast}
							/>
						);
					})}
				</div>
				{addVisible && (
					<NewRouteDialogDialog
						visible={addVisible}
						onClose={handleCloseNewRouteDialog}
						onFinish={initPageData}
					/>
				)}
			</div>
		);
	}
);

export default Route;
