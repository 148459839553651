import { onRequest } from "@/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { CommonChangeEvent } from "@/types/event";
import { CommonIdType, getImoParamsType } from "@/api/leg/type";
import dayjs from "dayjs";
import { AisListType, AisType } from "../type";
import { LegDetailsMenuItemType } from "../../../components/siderMenu/type";
import { RouteCommonMapRefType } from "../../../components";
import { RouteCommonAisDataSourceType } from "../../../components/map/types";

const useIndex = (
	activeTab: LegDetailsMenuItemType,
	loadAisTracks: RouteCommonMapRefType["loadAisTracks"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const legId = queryParams.get("id");
	const imo = queryParams.get("imo");
	// state
	const [loading, setLoading] = useState(false);
	const [aisList, setAisList] = useState<AisListType>([]);
	const [cosp, setCosp] = useState(null);
	const [contextMenu, setContextMenu] = useState<boolean>(false);
	const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
	const [selectedAisItem, setSelectedAisItem] = useState<AisType>(null);
	const [aisDatasource, setAisDatasource] =
		useState<RouteCommonAisDataSourceType>(null);
	const [tracksVisible, setTracksVisible] = useState(true);

	const handleShowTracks = () => {
		const emptyTracks = {
			current: null,
			route: {
				ais: null,
				trackPoints: null
			}
		};
		loadAisTracks(tracksVisible ? emptyTracks : aisDatasource);
		setTracksVisible(!tracksVisible);
	};

	const handleContextMenu = (
		e: React.MouseEvent<any, MouseEvent>,
		data: AisType
	) => {
		console.log(e, data);
		e.preventDefault();
		setContextMenu(true);
		setSelectedAisItem(data);
		setMenuPosition({ top: e.clientY, left: e.clientX });
	};

	const handleCloseMenu = () => {
		setContextMenu(false);
	};
	const handleDownloadAis = () => {
		aisDatasource?.route?.trackPoints &&
			downloadAis(aisDatasource?.route?.trackPoints);
	};

	const initAisList = () => {
		const params: CommonIdType = {
			id: legId
		};
		getImo(params);
	};

	const handleScroll = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const total = aisDatasource?.route?.trackPoints?.length;
		const currentLength = aisList.length;
		if (
			scrollTop + clientHeight >= scrollHeight - 80 &&
			currentLength < total
		) {
			const newAisList = [
				...aisList,
				...aisDatasource?.route?.trackPoints?.slice(
					currentLength,
					currentLength + 50
				)
			];
			setAisList(newAisList);
		}
	};

	// API
	// API - getCospByLegId
	const getCospByLegIdFront = () => {
		setLoading(true);
	};
	const getCospByLegIdSuccess = (data) => {
		const cospTime = data?.data?.cospDateTime;
		setCosp(data?.data);
		initAisList();
		setLoading(false);
	};
	const getCospByLegIdFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getCospByLegId = (params: CommonIdType) => {
		onRequest(
			"getCospByLegIdApi",
			params,
			getCospByLegIdFront,
			getCospByLegIdSuccess,
			getCospByLegIdFailed,
			dispatch,
			navigate
		);
	};
	// API - getImo
	const getImoFront = () => {
		setLoading(true);
	};
	const getImoSuccess = (data) => {
		const aisDatasource = data?.data;
		const aisList = aisDatasource?.route?.trackPoints.slice(0, 50);
		setAisDatasource(aisDatasource);
		setAisList(aisList);
		loadAisTracks(data?.data);
		setLoading(false);
	};
	const getImoFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const getImo = (params: CommonIdType) => {
		onRequest(
			"getAisListApi",
			params,
			getImoFront,
			getImoSuccess,
			getImoFailed,
			dispatch,
			navigate
		);
	};
	// API - downloadAis
	const downloadAisFront = () => {
		setLoading(true);
	};
	const downloadAisSuccess = (data) => {
		setLoading(false);
	};
	const downloadAisFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
		setLoading(false);
	};
	const downloadAis = (params: AisListType) => {
		onRequest(
			"downloadAisApi",
			params,
			downloadAisFront,
			downloadAisSuccess,
			downloadAisFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeTab !== "ais") {
			return;
		}
		initAisList();
	}, [activeTab]);

	useEffect(() => {
		initAisList();
	}, []);

	return {
		loading,
		aisList,
		contextMenu,
		menuPosition,
		selectedAisItem,
		initAisList,
		handleContextMenu,
		handleCloseMenu,
		handleDownloadAis,
		handleShowTracks,
		handleScroll
	};
};

export default useIndex;
