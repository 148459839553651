import { APP_VERSION_SYS, APP_VERSION_V1 } from "@/api/constant";
import { ApiParameter, RequestApiMapType } from "@/api/type";
import { userLoginParams, LoginParamsType } from "./type";
import request from "@/service";
import { ExcludeKeys } from "@/types/common";

const enum ApiMap {
	userLoginParameter,
	userLogoutParameter
}

type ApiUrl = keyof typeof ApiMap;

export type LoginApiType = ExcludeKeys<ApiUrl, "userLogoutParameter">;

const loginApi: ApiParameter<ApiUrl> = {
	userLoginParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/user/login"
	},
	userLogoutParameter: {
		method: "post",
		url: APP_VERSION_V1 + "/user/logout"
	}
};

export const userLoginApi = (params: userLoginParams) => {
	return request.request({
		...loginApi.userLoginParameter,
		data: params
	});
};

export const userLogoutApi = () => {
	return request.request({
		...loginApi.userLogoutParameter
	});
};

export const loginApiMap: RequestApiMapType<LoginApiType, LoginParamsType> = {
	userLoginApi
};
