import React, { FC } from "react";
import style from "./index.module.less";
import { FleetInput, FleetTimePicker, Select } from "@/components";
import { LatLongInput } from "@/common";
import {
	NoonMainInfoForm,
	NoonNextPortForm,
	NoonLabel,
	NoonFuelForm
} from "../../common";
import { cospDiDtTypeOptions, cospDiLoadTypeOptions } from "./source";
import { CospPatiFormType, CospDiFormType, NoonCospRFProps } from "./type";
import dayjs from "dayjs";
import { FleetUtcTimePicker } from "../../../../components";

const NoonCospRF: FC<NoonCospRFProps> = (props) => {
	const {
		cospData,
		onCospMiCommit,
		onCospPatiCommit,
		onCospDiCommit,
		onCospFuelCommit,
		onCospNpCommit
	} = props;

	const { miData, patiData, diData, fuelData, npData } = cospData;

	return (
		<div className={style["noon-cosp-rf"]}>
			<NoonMainInfoForm
				disabled
				data={miData}
				onChange={(data) => {
					onCospMiCommit(data);
				}}
			/>
			<div className="noon-pati-form-container">
				<p className="noon-pati-title">Position and Time Information</p>
				<div className="noon-pati-form">
					<div className="pati-form-row">
						<NoonLabel title="Departure Port" isNecessary>
							<FleetInput
								className="cosp-input"
								value={patiData.departurePort}
								onChange={(val: CospPatiFormType["departurePort"]) => {
									onCospPatiCommit({ ...patiData, departurePort: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="UNLOCODE" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={patiData.unlocode}
								onChange={(val: CospPatiFormType["unlocode"]) => {
									onCospPatiCommit({ ...patiData, unlocode: val });
								}}
							/>
						</NoonLabel>
					</div>
					<div className="pati-form-row">
						<NoonLabel title="COSP Date" isNecessary>
							<FleetUtcTimePicker
								className="cosp-input"
								value={Number(patiData.cospDate)}
								onChange={(val) => {
									onCospPatiCommit({
										...patiData,
										cospDate: val
									});
								}}
							/>
							{/* <FleetTimePicker
								className="cosp-input"
								value={patiData.cospDate ? dayjs(patiData.cospDate) : null}
								onChange={(val) => {
									onCospPatiCommit({
										...patiData,
										cospDate: dayjs(val).valueOf()
									});
								}}
							/> */}
							<span className="cosp-init">UTC</span>
						</NoonLabel>

						<NoonLabel
							className="custom-label-content"
							title="Latitude"
							isNecessary
						>
							<LatLongInput
								className="cosp-input"
								value={patiData.latitude}
								type="latitude"
								onChange={(val: CospPatiFormType["latitude"]) => {
									console.log("看看", val);
									onCospPatiCommit({ ...diData, latitude: val });
								}}
							/>
						</NoonLabel>

						<NoonLabel
							className="custom-label-content"
							title="Longitude"
							isNecessary
						>
							<LatLongInput
								className="cosp-input"
								value={patiData.longitude}
								type="longitude"
								onChange={(val: CospPatiFormType["longitude"]) => {
									onCospPatiCommit({ ...diData, longitude: val });
								}}
							/>
						</NoonLabel>
					</div>
				</div>
			</div>
			<div className="noon-di-form-container">
				<p className="noon-di-title">Departure Information</p>
				<div className="noon-di-form">
					<div className="di-form-row">
						<NoonLabel title="Fore Draft" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.foreDraft}
								onChange={(val: CospDiFormType["foreDraft"]) => {
									onCospDiCommit({ ...diData, foreDraft: val });
								}}
							/>
							<span className="cosp-init">M</span>
						</NoonLabel>
						<NoonLabel title="Aft Draft" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.aftDraft}
								onChange={(val: CospDiFormType["aftDraft"]) => {
									onCospDiCommit({ ...diData, aftDraft: val });
								}}
							/>
							<span className="cosp-init">M</span>
						</NoonLabel>
						<NoonLabel title="Mid Draft" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.midDraft}
								onChange={(val: CospDiFormType["midDraft"]) => {
									onCospDiCommit({ ...diData, midDraft: val });
								}}
							/>
							<span className="cosp-init">M</span>
						</NoonLabel>
					</div>
					<div className="di-form-row">
						<NoonLabel title="Draft Type" isNecessary={false}>
							<Select
								className="cosp-input"
								value={diData.draftType}
								options={cospDiDtTypeOptions}
								onChange={(val: CospDiFormType["draftType"]) => {
									onCospDiCommit({ ...diData, draftType: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Load" isNecessary={false}>
							<Select
								className="cosp-input"
								value={diData.load}
								options={cospDiLoadTypeOptions}
								onChange={(val: CospDiFormType["load"]) => {
									onCospDiCommit({ ...diData, load: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Roll Period" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.rollPeriod}
								onChange={(val: CospDiFormType["rollPeriod"]) => {
									onCospDiCommit({ ...diData, rollPeriod: val });
								}}
							/>
							<span className="cosp-init">S</span>
						</NoonLabel>
					</div>
					<div className="di-form-row">
						<NoonLabel title="GM" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.gm}
								onChange={(val: CospDiFormType["gm"]) => {
									onCospDiCommit({ ...diData, gm: val });
								}}
							/>
							<span className="cosp-init">M</span>
						</NoonLabel>
						<NoonLabel title="Cargo" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.cargo}
								onChange={(val: CospDiFormType["cargo"]) => {
									onCospDiCommit({ ...diData, cargo: val });
								}}
							/>
						</NoonLabel>
						<NoonLabel title="Cargo Weight" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.cargoWeight}
								onChange={(val: CospDiFormType["cargoWeight"]) => {
									onCospDiCommit({ ...diData, cargoWeight: val });
								}}
							/>
							<span className="cosp-init">MT</span>
						</NoonLabel>
					</div>
					<div className="di-form-row">
						<NoonLabel title="Displacement" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.displacement}
								onChange={(val: CospDiFormType["displacement"]) => {
									onCospDiCommit({ ...diData, displacement: val });
								}}
							/>
							<span className="cosp-init">MT</span>
						</NoonLabel>
						<NoonLabel title="Disance To Go" isNecessary>
							<FleetInput
								className="cosp-input"
								value={diData.distanceToGo}
								onChange={(val: CospDiFormType["distanceToGo"]) => {
									onCospDiCommit({ ...diData, distanceToGo: val });
								}}
							/>
							<span className="cosp-init">NM</span>
						</NoonLabel>
					</div>
					<div className="di-form-row">
						<NoonLabel title="Ordered Speed" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.orderedSpeed}
								onChange={(val: CospDiFormType["orderedSpeed"]) => {
									onCospDiCommit({ ...diData, orderedSpeed: val });
								}}
							/>
							<span className="cosp-init">KTS</span>
						</NoonLabel>
					</div>
					<div className="di-form-row">
						<NoonLabel title="Course" isNecessary>
							<FleetInput
								className="cosp-input"
								value={diData.course}
								onChange={(val: CospDiFormType["course"]) => {
									onCospDiCommit({ ...diData, course: val });
								}}
							/>
							<span className="cosp-init">DEG</span>
						</NoonLabel>
					</div>
					<div className="di-form-row">
						<NoonLabel title="Set RPM" isNecessary={false}>
							<FleetInput
								className="cosp-input"
								value={diData.setRpm}
								onChange={(val: CospDiFormType["setRpm"]) => {
									onCospDiCommit({ ...diData, setRpm: val });
								}}
							/>
							<span className="cosp-init">RPM</span>
						</NoonLabel>
					</div>
				</div>
			</div>
			<NoonFuelForm
				dataSource={fuelData}
				onCommit={(index, item) => {
					onCospFuelCommit(index, item);
				}}
			/>
			<NoonNextPortForm
				data={npData}
				onChange={(data) => {
					onCospNpCommit(data);
				}}
			/>
		</div>
	);
};

export default NoonCospRF;
