import { savePersonalInfoParams } from "@/api/personalCenter/type";
import { useAppDispatch, useReminder } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { basicList } from "../source";
import { onRequest } from "@/api";

const useIndex = (basicData: savePersonalInfoParams, reset: () => void) => {
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const saveBasicInfo = () => {
		let text = "";
		for (let item of basicList) {
			if (!Boolean(basicData[item.prop])) {
				text = `${item.En} is Empty!`;
				break;
			}
		}
		if (text != "") {
			reminder("error", text);
			return;
		}
		onRequest(
			"savePersonalInfoApi",
			basicData,
			() => {},
			savePersonalInfoSuccess,
			savePersonalInfoError,
			dispatch,
			navigate
		);
	};
	const savePersonalInfoError = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const savePersonalInfoSuccess = () => {
		reset();
		reminder("success", "Save Success");
	};
	return {
		saveBasicInfo
	};
};
export default useIndex;
