import { useState } from "react";

const useIndex = (value: number) => {
	const [timeValue, setTimeValue] = useState<number>(value);
	const [isEdit, setIsEdit] = useState<boolean>(false);

	return {
		timeValue,
		isEdit,
		setTimeValue,
		setIsEdit
	};
};
export default useIndex;
