import { CommonCommitEvent } from "@/types/event";
import {
	TimeAxisControlProps,
	TimeAxisTimeLineProps,
	TimeAxisTimeLineRef
} from "../components";
import { useRef, useState } from "react";

import dayjs from "dayjs";
import { RouteCommonTimeAxisProps } from "../type";
import { getLastDate } from "../source";
import { useRouteCommonContext } from "../../../../../store";

const useIndex = (
	onMeteoTimeChange: RouteCommonTimeAxisProps["onMeteoTimeChange"],
	onRun?: RouteCommonTimeAxisProps["onRun"]
) => {
	const { getMeteoCalendarEndTime } = useRouteCommonContext();
	const [activeOption, setActiveOption] = useState<
		TimeAxisControlProps["item"]
	>({
		currentTime: dayjs(),
		hours: 3,
		runState: false
	});

	const commit: CommonCommitEvent<TimeAxisControlProps["item"]> = (item) => {
		setActiveOption((prev) => ({ ...prev, ...item }));
	};

	const timeLineRef = useRef<TimeAxisTimeLineRef>(null);

	const handleNext: TimeAxisControlProps["onNext"] = () => {
		const currentTime = activeOption?.currentTime?.add(
			activeOption?.hours,
			"h"
		);
		commit({ currentTime: currentTime });
		timeLineRef?.current?.onReset?.(currentTime);
		onMeteoTimeChange?.(currentTime?.valueOf());
	};

	const handleRun = () => {
		if (activeOption?.runState) {
			commit?.({ runState: false });
			onRun?.("stop");
			return;
		}
		commit?.({ runState: true });
		onRun?.(
			"start",
			activeOption?.currentTime,
			(value) => {
				debugger;
				const endTime = getMeteoCalendarEndTime();
				const lastDate = getLastDate();
				console.log("getMeteoCalendarEndTime", {
					endTime,
					value,
					lastDate
				});
				if (
					value?.startOf("hour")?.isAfter(endTime) ||
					value?.startOf("hour")?.isSame(endTime)
				) {
					timeLineRef?.current?.onReset?.(endTime);
					commit?.({ currentTime: endTime, runState: false });
					return;
				}
				if (
					value?.startOf("hour")?.isAfter(lastDate) ||
					value?.startOf("hour")?.isSame(lastDate)
				) {
					timeLineRef?.current?.onReset?.(lastDate);
					commit?.({ currentTime: lastDate, runState: false });
					return;
				}
				commit?.({ currentTime: value });
				timeLineRef?.current?.onReset?.(value);
			},
			activeOption?.hours
		);
	};

	const handleTimeLineChange: TimeAxisTimeLineProps["onChange"] = (value) => {
		if (activeOption?.runState) {
			handleRun?.();
		}
		commit({ currentTime: value });
		onMeteoTimeChange?.(value?.valueOf());
	};

	return {
		activeOption,
		commit,
		handleNext,
		handleRun,
		timeLineRef,
		handleTimeLineChange
	};
};

export default useIndex;
