import React from "react";
import { Icon, Table } from "@/components";
import style from "./index.module.less";
import { NoonDetailsReportItemType } from "../../type";
import { DetailReportListProps } from "./type";
import useIndex from "./hook/useIndex";

const DetailReportList: React.FC<DetailReportListProps> = (props) => {
	const {
		detailsDatasource,
		noonDetailReportColumns,
		noonReportOpColumns,
		handleRowDbClick
	} = useIndex(props);

	const detailReportListHideStyle: React.CSSProperties = {
		height: 0
	};

	return (
		<div
			className={style["noon-data"]}
			style={props.noonListVisible ? null : detailReportListHideStyle}
		>
			<div className="noon-data-header">
				<span>NooN</span>
				<Icon
					type="icon-close2"
					onClick={() => {
						props.handleNoonListClose();
					}}
				/>
			</div>
			<div className="noon-data-main">
				<Table
					id="key"
					dataSource={detailsDatasource}
					className="noon-table-details"
					scroll={{ x: 5000, y: 650 }}
					columns={[...noonDetailReportColumns, ...noonReportOpColumns]}
					onRow={(data: NoonDetailsReportItemType, index) => {
						return {
							onDoubleClick: () => {
								handleRowDbClick(data, index);
							}
						};
					}}
					pagination={false}
				/>
			</div>
		</div>
	);
};

export default DetailReportList;
