import { useState } from "react";
import { ReportType } from "../../../type";

const useIndex = () => {
	// 报文类型
	const [reportType, setReportType] = useState<ReportType>("COSP");
	const handleReportTypeChange = (val: ReportType) => {
		setReportType(val);
	};

	// 填充文本
	const [fillText, setFileText] = useState<string>("");
	const handleFillTextChange = (val: string) => {
		setFileText(val);
	};

	return {
		reportType,
		handleReportTypeChange,
		fillText,
		handleFillTextChange
	};
};

export default useIndex;
