import React from "react";
import { ColumnsType } from "antd/es/table";
import { NoonDetailsReportItemType, NoonBasicReportItemType } from "./type";
import dayjs from "dayjs";
import classNames from "classnames";
import { dateToUtcString } from "@/tools/date";
import { AlertFieldTip } from "./components";

export const noonBasicReportColumns: ColumnsType<NoonBasicReportItemType> = [
	{
		title: "Seg",
		dataIndex: "seg",
		key: "seg",
		width: 80,
		fixed: "left",
		align: "center",

		render(value, record, index) {
			return (
				<div className="basic-table-seg">
					{record.alert !== 0 && (
						<AlertFieldTip
							title="预警字段:"
							content={record.alertCols.join(", ")}
						>
							<span className="iconfont icon-warn" />
						</AlertFieldTip>
					)}
					<p>{value}</p>
				</div>
			);
		}
	},
	{
		title: "Date（UTC）",
		dataIndex: "dateTime",
		key: "dateTime",
		width: 160,
		align: "center",
		render(value, record, index) {
			return (
				<div className="basic-table-date">
					<p className="basic-table-type">{record.type}</p>
					<p className="basic-table-utc">
						{dateToUtcString(value).slice(0, -3)}
					</p>
				</div>
			);
		}
	},
	{
		title: "Time SLR",
		dataIndex: "timeSlr",
		key: "timeSlr",
		width: "120",
		align: "center",
		render(value, record, index) {
			const renderNode = reportBasicIgnore[record.type].includes("timeSlr")
				? "---"
				: value;
			return (
				<div
					className={classNames({
						"alert-field": record.alertCols.includes("timeSlr")
					})}
				>
					{renderNode}
				</div>
			);
		}
	},
	{
		title: "Dist SLR",
		dataIndex: "distSlr",
		key: "distSlr",
		align: "center",
		render(value, record, index) {
			const renderNode = reportBasicIgnore[record.type].includes("distSlr")
				? "---"
				: Number(value)?.toFixed(1);

			const isAlert = record.alertCols.includes("distSlr");

			const element = isAlert ? (
				<AlertFieldTip
					title="对比值:"
					content={String(record.tipDistSlr.toFixed(1))}
				>
					<div className={classNames({ "alert-field": isAlert })}>
						{renderNode}
					</div>
				</AlertFieldTip>
			) : (
				<div className={classNames({ "alert-field": isAlert })}>
					{renderNode}
				</div>
			);

			return element;
		}
	},
	{
		title: "Avg Speed SLR",
		dataIndex: "avgSpeedSlr",
		key: "avgSpeedSlr",
		align: "center",
		render(value, record, index) {
			const renderNode = reportBasicIgnore[record.type].includes("avgSpeedSlr")
				? "---"
				: Number(value)?.toFixed(2);

			const isAlert = record.alertCols.includes("avgSpeedSlr");

			const element = isAlert ? (
				<AlertFieldTip
					title="对比值:"
					content={String(record.tipSpeedSlr.toFixed(2))}
				>
					<div className={classNames({ "alert-field": isAlert })}>
						{renderNode}
					</div>
				</AlertFieldTip>
			) : (
				<div className={classNames({ "alert-field": isAlert })}>
					{renderNode}
				</div>
			);

			return element;
		}
	},
	{
		title: "Total FO Cons SLR",
		dataIndex: "totalFoConsSlr",
		key: "totalFoConsSlr",
		align: "center",
		render(value, record, index) {
			const renderNode = reportBasicIgnore[record.type].includes(
				"totalFoConsSlr"
			)
				? "---"
				: value;
			return (
				<div
					className={classNames({
						"alert-field": record.alertCols.includes("totalFoConsSlr")
					})}
				>
					{renderNode}
				</div>
			);
		}
	},
	{
		title: "Total DGO Cons SLR",
		dataIndex: "totalDgoConsSlr",
		key: "totalDgoConsSlr",
		align: "center",
		render(value, record, index) {
			const renderNode = reportBasicIgnore[record.type].includes(
				"totalDgoConsSlr"
			)
				? "---"
				: value;
			return (
				<div
					className={classNames({
						"alert-field": record.alertCols.includes("totalDgoConsSlr")
					})}
				>
					{renderNode}
				</div>
			);
		}
	}
];

export const reportBasicIgnore = {
	E: [],
	N: [],
	C: ["timeSlr", "distSlr", "avgSpeedSlr", "totalFoConsSlr", "totalDgoConsSlr"],
	R: ["distSlr", "avgSpeedSlr"],
	S: [],
	SC: []
};

export const reportDetailIgnore = {
	EOSP: ["distToGo"],
	NOON: [],
	COSP: [
		"timeSlr",
		"avgSpeedSlr",
		"distSlr",
		"distToGo",
		"totalConsHfo",
		"totalConsLsfo",
		"totalConsMdo",
		"totalConsMgo",
		"meMcr",
		"meSlipSlr",
		"mePowerSlr",
		"meAvgRpmSlr",
		"windDir",
		"windBf",
		"seaHt",
		"swellDir",
		"swellHt"
	],
	RESUME: [
		// "timeSlr",
		"avgSpeedSlr",
		"distSlr",
		"totalConsHfo",
		"totalConsLsfo",
		"totalConsMdo",
		"totalConsMgo",
		"draftMean",
		"draftAft",
		"draftFore",
		"meMcr",
		"meSlipSlr",
		"mePowerSlr",
		"meAvgRpmSlr",
		"windDir",
		"windBf",
		"seaHt",
		"swellDir",
		"swellHt",
		"eta",
		"nextPortCode",
		"nextPortName"
	],
	STOP: [
		// "timeSlr",
		// "avgSpeedSlr",
		// "distSlr",
		"distToGo",
		"totalConsHfo",
		"totalConsLsfo",
		"totalConsMdo",
		"totalConsMgo",
		"draftMean",
		"draftAft",
		"draftFore",
		"meMcr",
		"meSlipSlr",
		"mePowerSlr",
		"meAvgRpmSlr",
		"windDir",
		"windBf",
		"seaHt",
		"swellDir",
		"swellHt",
		"eta",
		"nextPortCode",
		"nextPortName"
	],
	"Speed Change": [
		"distToGo",
		"draftMean",
		"draftAft",
		"draftFore",
		"course",
		"meMcr",
		"meSlipSlr",
		"mePowerSlr",
		"meAvgRpmSlr",
		"windDir",
		"windBf",
		"seaHt",
		"swellDir",
		"swellHt",
		"eta",
		"nextPortCode",
		"nextPortName"
	]
};
