import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReminder, useAppDispatch } from "@/hooks";
import useBasicInfoIndex from "../../../common/basicInfo/hooks/useIndex";
import useCpSpdIndex from "../../../common/cpSpd/hooks/useIndex";
import useGwClauseIndex from "../../../common/gwClause/hooks/useIndex";
import { VoyageFO, CpFO, GwClauseFO } from "@/api/voy/type";
import { onRequest } from "@/api";
import { EditVoyProps } from "../type";
import { transformBooleanProperties } from "@/tools/amount";
import { VoyGwClauseItemType } from "../../../common/gwClause/type";

const useIndex = (props: EditVoyProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,
		setVoyBasicInfoData
	} = useBasicInfoIndex();

	const {
		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,
		setVoyCpSpdTableData,
		delIds,
		handleClearDelIds
	} = useCpSpdIndex();

	const {
		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,
		setVoyGwClauseData
	} = useGwClauseIndex();

	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const voyId = useLocation().search.split("=")[1];

	useMemo(() => {
		if (props.hide === false) {
			onRequest(
				"getVoyageDetailsApi",
				voyId,
				() => {
					setLoading(true);
				},
				(res) => {
					setLoading(false);
					// 设置详情页的数据
					setVoyBasicInfoData(res.data.voyage);
					setVoyCpSpdTableData(res.data.cpList);
					setVoyGwClauseData(
						transformBooleanProperties(res.data.gwClause) as VoyGwClauseItemType
					);
				},
				(error) => {
					setLoading(false);
					reminder(
						"error",
						error?.msg ? error?.msg + ": " + error?.data : error?.data
					);
				},
				dispatch,
				navigate
			);
		}
	}, [props.hide]);

	// useEffect(() => {
	// 	onRequest(
	// 		"getVoyageDetailsApi",
	// 		voyId,
	// 		() => {
	// 			setLoading(true);
	// 		},
	// 		(res) => {
	// 			setLoading(false);
	// 			// 设置详情页的数据
	// 			setVoyBasicInfoData(res.data.voyage);
	// 			setVoyCpSpdTableData(res.data.cpList);
	// 			setVoyGwClauseData(
	// 				transformBooleanProperties(res.data.gwClause) as VoyGwClauseItemType
	// 			);
	// 		},
	// 		(error) => {
	// 			setLoading(false);
	// 			reminder(
	// 				"error",
	// 				error?.msg ? error?.msg + ": " + error?.data : error?.data
	// 			);
	// 		}
	// 	);
	// }, [props.hide]);

	const handleConfirmClick = () => {
		// 过滤voyBasicInfoData
		// const voyBasicInfoDataFilter = {
		// 	orderData: voyBasicInfoData.startOrderDate
		// };
		// const filterKeys = Object.keys(voyBasicInfoData).map((key) => {
		// 	if (key === "endOrderDate" || key === "startOrderDate") {
		// 		return;
		// 	}
		// 	return key;
		// });
		// filterKeys.forEach((key) => {
		// 	voyBasicInfoDataFilter[key] = voyBasicInfoData[key];
		// });

		const voyage: VoyageFO = {
			...voyBasicInfoData
			// companyName: "甲骨文",
			// status: "Close",
			// vesselName: "泰坦尼克号",
			// voyageNo: "001",
			// imo: 123,
			// companyId: 234,
			// id: voyId
		};

		const cp: CpFO = {
			cpList: voyCpSpdTableData,
			delIds
		};

		// proportion需要除100再发送给后端
		const proportion =
			voyGwClauseData.proportion !== ""
				? voyGwClauseData.proportion / 100
				: voyGwClauseData.proportion;
		const gwClause: GwClauseFO = {
			...voyGwClauseData,
			proportion
		};

		const updateVoyageFront = () => {
			setLoading(true);
		};

		const updateVoyageSuccess = (res) => {
			setLoading(false);
			handleClearDelIds();
			console.log("addVoyageSuccess", res);
			props?.onEditSuccess();
			reminder("success", "update voyage success");
		};

		const updateVoyageError = (error) => {
			setLoading(false);
			handleClearDelIds();
			console.log("addVoyageError", error);
			reminder(
				"error",
				error?.msg ? error?.msg + ": " + error?.data : error?.data
			);
		};

		onRequest(
			"updateVoyageApi",
			{ voyage, cp, gwClause },
			updateVoyageFront,
			updateVoyageSuccess,
			updateVoyageError,
			dispatch,
			navigate
		);

		console.log(voyage);
		console.log(cp);
		console.log(gwClause);
	};

	return {
		voyBasicInfoData,
		handleBasicInfoItemCommit,
		handleChangeBasicInfoItem,

		voyCpSpdTableData,
		handleDoubleClick,
		selectedRowKeys,
		onSelectChange,
		pager,
		onPageChange,
		dataSource,
		opereteColumns,
		handleChangeVoyCpSpdTableItem,
		addVoyCpSpd,

		voyGwClauseData,
		handleChangeVoyGwClauseItem,
		handleVoyGwClauseItemCommit,

		handleConfirmClick,
		loading
	};
};

export default useIndex;
