import { onRequest } from "@/api";
import { useRef, useState, useMemo, useEffect } from "react";
import { useAppDispatch, useReminder } from "@/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { getLegBasicDetailParamsType } from "@/api/leg/type";
import { BasicInfoType } from "../type";
const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const location = useLocation();

	// URL Params
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get("id");
	const voyageId = queryParams.get("voyageId");
	const legType = queryParams.get("legType");

	// DidMount
	useEffect(() => {
		getLegBasicDetail({ id, legType, voyageId });
	}, []);

	const [basicInfo, setBasicInfo] = useState<BasicInfoType>();

	// API - getLegBasicDetail
	const getLegBasicDetailFront = () => {};
	const getLegBasicDetailSuccess = (data) => {
		const basicInfo: BasicInfoType = {
			vesselName: data?.data?.voyage?.vesselName,
			legTypeName: legType === "L" ? "Laden" : "Ballast",
			voyageNo: data?.data?.voyage?.voyageNo
		};
		setBasicInfo(basicInfo);
	};
	const getLegBasicDetailFailed = (error) => {
		reminder(
			"error",
			error?.msg ? error?.msg + ": " + error?.data : error?.data
		);
	};
	const getLegBasicDetail = (params?: getLegBasicDetailParamsType) => {
		onRequest(
			"getLegBasicDetailApi",
			{
				...params
			},
			getLegBasicDetailFront,
			getLegBasicDetailSuccess,
			getLegBasicDetailFailed,
			dispatch,
			navigate
		);
	};
	return { basicInfo };
};

export default useIndex;
